import React from 'react';

import IconPlus from '../../assets/icons/ListingPage/icon-plus.webp';
import blackBellIcon from '../../assets/icons/ListingPage/black-bell.webp';
import css from './FollowButtonSection.css';
import closeIcon from '../../assets/icons/ListingPage/close.webp';
import bellIcon from '../../assets/icons/ListingPage/bell.webp';
import FollowButton from '../../components/FollowButtons/FollowButton';
import UnfollowButton from '../../components/FollowButtons/UnfollowButton';

const FollowButtonSection = ({
  followingProperty,
  notificationsEnabled,
  unfollowProperty,
  notifyMeUpdate,
  followProperty,
}) => {
  const buttonStyle = followingProperty ? css.whiteFollowButtonStyles : css.followButtonStyles;

  const notificationIcon = notificationsEnabled ? blackBellIcon : bellIcon;

  const renderText = notificationsEnabled
    ? 'ListingPage.activityFeedTooltipFollowAndNotify'
    : 'ListingPage.activityFeedTooltipfollow';

  if (followingProperty) {
    return (
      <UnfollowButton
        buttonStyle={buttonStyle}
        notificationIcon={notificationIcon}
        renderText={renderText}
        unfollowProperty={unfollowProperty}
        closeIcon={closeIcon}
        notificationsEnabled={notificationsEnabled}
        bellIcon={bellIcon}
        blackBellIcon={blackBellIcon}
        notifyMeUpdate={notifyMeUpdate}
      />
    );
  }
  return <FollowButton buttonStyle={buttonStyle} Icon={IconPlus} onClick={followProperty} />;
};

export default FollowButtonSection;
