import React, { useState } from 'react';
import { node, arrayOf, object, func, string } from 'prop-types';
import config from '../../config';
import { FieldDateRangeController, OutsideClickHandler } from '..';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as CalendarFilterIcon } from '../../assets/icons/calendar-filter.svg';
import { propTypes } from '../../util/types';
import { ReactComponent as IconChevronDown } from '../../assets/icons/chevron-down.svg';
import css from './FieldDates.css';

const FieldDates = ({
  label,
  text,
  unitType,
  timeSlots,
  onChange,
  onReset,
  fieldDateProps,
  isPackage,
  placement,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleTooltipVisibility = isVisible => {
    setIsTooltipVisible(isVisible);
  };

  return (
    <>
      <h5 className={css.formFieldHeading}>{label}</h5>

      <Tooltip
        rootClassName={css.dateTooltip}
        tooltipShown={isTooltipVisible}
        onTooltipShown={isVisible => {
          handleTooltipVisibility(isVisible);
        }}
        hideArrow
        hideCloseButton
        placement={placement || (isPackage ? 'top-end' : 'bottom-end')}
        trigger="none"
        tooltip={
          <OutsideClickHandler
            rootClassName={css.outsideHandler}
            onOutsideClick={() => {
              handleTooltipVisibility(false);
            }}
          >
            <FieldDateRangeController
              name="dates"
              unitType={unitType}
              format={null}
              timeSlots={timeSlots}
              onChange={values => {
                if (onChange) onChange(values);

                handleTooltipVisibility(false);
              }}
              onReset={onReset}
              {...fieldDateProps}
            />
          </OutsideClickHandler>
        }
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: () => {
                return [0, 20];
              },
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom-end'],
            },
          },
        ]}
      >
        <button
          data-testId="date-chooser"
          type="button"
          onClick={() => {
            handleTooltipVisibility(!isTooltipVisible);
          }}
          className={css.datesButton}
        >
          <div className={css.datesButtonLabel}>
            <div className={css.iconContainer}>
              <CalendarFilterIcon width="18" height="20" />
              <span className={css.dateText}>{text}</span>
            </div>

            <span className={css.iconContainer}>
              <IconChevronDown className={css.iconArrow} />
            </span>
          </div>
        </button>
      </Tooltip>
    </>
  );
};

FieldDates.defaultProps = {
  unitType: config.bookingUnitType,
  timeSlots: null,
  placement: '',
};

FieldDates.propTypes = {
  unitType: propTypes.bookingUnitType,
  label: node.isRequired,
  text: node.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  onChange: func.isRequired,
  fieldDateProps: object.isRequired,
  placement: string,
};

export default FieldDates;
