import React from 'react';
import { NamedLink, InlineTextButton } from '..';

import css from './LinkWithIcon.css';

const LinkWithIcon = ({ name, params, icon: Icon, label, onClick }) => {
  return (
    <div className={css.root}>
      <Icon className={css.icon} />

      {name && (
        <NamedLink className={css.link} name={name} params={params} target="_blank">
          {label}
        </NamedLink>
      )}

      {onClick && (
        <InlineTextButton className={css.link} onClick={onClick}>
          {label}
        </InlineTextButton>
      )}
    </div>
  );
};

export default LinkWithIcon;
