import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionCustomerLateCancel = ({ transition, user, ownRole, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.ownCustomerLateCancel" />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionCustomerLateCancel"
            values={{ displayName }}
          />
        )
      }
      buttons={buttons}
    />
  );
};

export default InboxTransitionCustomerLateCancel;
