import * as props from '../prop-names';

import {
  formatEvent,
  getEventDate,
  getAbsoluteUrl,
  getDefaultUserProps,
  getCurrUserAttribute,
  getEmailProps,
} from '../helpers';

// Tracked when the user views a page
// NOTE: on the initial app load, this won't have user data
export const trackPageViewEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    ...getDefaultUserProps(state),
    ...getEmailProps(state),
    [props.FIRST_NAME]: getCurrUserAttribute(state, 'profile.firstName'),
    [props.LAST_NAME]: getCurrUserAttribute(state, 'profile.lastName'),
    [props.PHONE]: getCurrUserAttribute(state, 'profile.protectedData.phoneNumber'),
    [props.SECONDARY_PHONE]: getCurrUserAttribute(
      state,
      'profile.protectedData.secondaryPhoneNumber'
    ),
    [props.PAGE_URL]: getAbsoluteUrl(payload.location.pathname),
  };

  return event;
};

// Tracked search event
export const trackSearchEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.LAST_SEARCH_TERM]: payload.searchParams.address,
    [props.LAST_SEARCH_DATE]: getEventDate(),
    [props.SEARCH_TERM]: payload.searchParams.address,
    [props.TOTAL_SEARCHES_COUNT]: 1,
    ...getDefaultUserProps(state),
  };

  return event;
};

export const trackSignupClickedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(state.Routing.currentLocation.pathname),
  };

  return event;
};

export const trackAddPropertyClickedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(state.Routing.currentLocation.pathname),
    ...getDefaultUserProps(state),
  };

  return event;
};
