import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import css from '../../containers/ActivityFeed/FollowButtonSection.css';

const FollowButton = props => {
  const { Icon, onClick, buttonStyle } = props;
  return (
    <Button className={buttonStyle} onClick={onClick}>
      <img src={Icon} alt="Plus icon" className={css.iconStyles} />
      <span>
        <FormattedMessage id="ListingPage.activityFeedFollowButton" />
      </span>
    </Button>
  );
};

export default FollowButton;
