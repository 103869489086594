import React from 'react';
import { FormattedMessage } from 'react-intl';

import emptySectionImage from '../../assets/inbox-empty-state.png';
import { NamedLink, Page, PrimaryButton } from '../../components';
import { TopbarContainer } from '..';

import css from './InboxPageV2.css';

const InboxV2EmptyState = ({ title, params }) => {
  const isCustomer = params.transactionRole === 'customer';
  const isProvider = params.transactionRole === 'provider';

  return (
    <Page title={title}>
      <div className={css.root}>
        <div className={css.topbarContainer}>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxV2Page"
          />
        </div>

        <div className={css.emptyStateContainer}>
          <div className={css.emptyStateImageSection}>
            <img src={emptySectionImage} alt="Empty State" className={css.emptyStateImage} />
          </div>

          <div className={css.emptyStateTextSection}>
            <div className={css.emptyStateTitle}>
              <FormattedMessage id="InboxPage.emptyStateTitle" />
            </div>
            <div className={css.emptyStateText}>
              {isProvider && <FormattedMessage id="InboxPage.emptyStateTextProvider" />}
              {isCustomer && <FormattedMessage id="InboxPage.emptyStateTextCustomer" />}
            </div>
          </div>

          <div className={css.emptyStateButtonSection}>
            {isProvider && (
              <PrimaryButton component={NamedLink} name="DashboardPage">
                <FormattedMessage id="InboxPage.viewDashboard" />
              </PrimaryButton>
            )}

            {isCustomer && (
              <PrimaryButton component={NamedLink} name="SearchPage">
                <FormattedMessage id="InboxPage.browseProperties" />
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default InboxV2EmptyState;
