import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Promised } from '..';

import css from './ImageFromFile.css';

const resizeImage = (src, width) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = canvas.width * (img.height / img.width);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      resolve(canvas.toDataURL());
    };

    img.onerror = err => reject(err);

    img.src = src;
  });

// readImage returns a promise which is resolved
// when FileReader has loaded given file as dataURL
const readImage = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(resizeImage(e.target.result, 150));
    reader.onerror = e => {
      // eslint-disable-next-line
      console.error('Error (', e, `) happened while reading ${file.name}: ${e.target.result}`);
      reject(new Error(`Error reading ${file.name}: ${e.target.result}`));
    };

    // TODO: Using the full size image is awful for performance.
    // you can use a canvas to resize first which is much faster
    reader.readAsDataURL(file);
  });

// Create elements out of given thumbnail file
class ImageFromFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promisedImage: readImage(this.props.file),
    };
  }

  render() {
    const {
      className,
      rootClassName,
      aspectRatioClassName,
      file,
      id,
      justImage,
      children,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const aspectRatioClasses = aspectRatioClassName || css.aspectWrapper;
    return (
      <Promised
        key={id}
        promise={this.state.promisedImage}
        renderFulfilled={dataURL => {
          return justImage ? (
            <img src={dataURL} alt={file.name} className={classes} />
          ) : (
            <div className={classes}>
              <div className={css.threeToTwoWrapper}>
                <div className={aspectRatioClasses}>
                  <img src={dataURL} alt={file.name} className={css.rootForImage} />
                </div>
              </div>
              {children}
            </div>
          );
        }}
        renderRejected={() =>
          justImage ? (
            <FormattedMessage id="ImageFromFile.couldNotReadFile" />
          ) : (
            <div className={classes}>
              <FormattedMessage id="ImageFromFile.couldNotReadFile" />
            </div>
          )
        }
      />
    );
  }
}

ImageFromFile.defaultProps = {
  className: null,
  children: null,
  rootClassName: null,
  aspectRatioClassName: null,
  justImage: false,
};

const { any, bool, node, string } = PropTypes;

ImageFromFile.propTypes = {
  className: string,
  rootClassName: string,
  aspectRatioClassName: string,
  file: any.isRequired,
  id: string.isRequired,
  // I only came here for the image, keep the rest thanks
  justImage: bool,
  children: node,
};

export default ImageFromFile;
