import React from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import { formatTimeAgo } from '../../../util/dates';
import Avatar from '../../../components/Avatar/Avatar';
import { getPackage } from '../../../util/listing';
import { NamedLink } from '../../../components';
import { createSlug } from '../../../util/urlHelpers';
import css from './ListingUpdate.css';

const SectionPackageUpdate = props => {
  const { listing, activity, renderBellIcon, currentPackage, isListing } = props;

  const landownerName = listing.author.attributes.profile.displayName;
  const { packageId } = activity;
  const activityPackage = getPackage(listing, packageId);
  const packageName = activityPackage ? activityPackage.title : '';
  const slug = createSlug(packageName);
  const id = listing.id.uuid;

  const richTitle = (
    <NamedLink className={css.titleLink} name="PackagePage" params={{ id, slug, packageId }}>
      {packageName}
    </NamedLink>
  );

  if (!isListing && currentPackage.id !== packageId) {
    return null;
  }

  return (
    <div key={activity.createdAt} className={css.content}>
      <div className={css.info}>
        <div className={css.flexContainer}>
          <div className={css.avatarContainer}>
            <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
              <>
                <Avatar
                  user={listing.author}
                  disableProfileLink
                  className={css.customAvatarSize}
                  listing={listing}
                  useListingImage
                />
                {renderBellIcon}
              </>
            </NamedLink>
          </div>
          <div className={css.propertyNameContainer}>
            <div className={css.propertyName}>
              <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                <FormattedMessage
                  id="ListingPage.activityFeedPropertyName"
                  values={{
                    propertyName: listing.attributes.title,
                  }}
                />
              </NamedLink>
            </div>
            <div className={css.dateWrapper}>
              <span className={css.dateContainer}>
                <p className={css.landownerContainer}>
                  {' '}
                  <FormattedMessage
                    id="ListingPage.activityFeedPackageUpdate"
                    values={{ landownerName }}
                  />
                  {' • '}
                  {formatTimeAgo(activity?.createdAt)}
                </p>
              </span>
            </div>
          </div>
        </div>
        <div className={css.postWrapper}>
          <div className={css.descriptionStyles}>
            {packageName ? (
              <FormattedMessage
                id="ListingPage.activityFeedPackageUpdateDescription"
                values={{ richTitle }}
              />
            ) : (
              <FormattedMessage id="ListingPage.activityFeedDefaultDescription" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionPackageUpdate.defaultProps = {};

SectionPackageUpdate.propTypes = {
  listing: propTypes.object.isRequired,
};

export default SectionPackageUpdate;
