/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { shape, object } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';

import css from './ListingPage.css';

const SectionRules = props => {
  const { publicData } = props;

  const { policies, arrivalInstructions } = publicData;

  if (!policies && !arrivalInstructions) return null;

  const renderCampfires = () => {
    const campfires = [];

    if (policies.campfires.includes('firepit')) {
      campfires.push(<FormattedMessage id="ListingPage.rules.campfires.firepit" />);
    }

    if (policies.campfires.includes('fireplace')) {
      campfires.push(<FormattedMessage id="ListingPage.rules.campfires.fireplace" />);
    }

    if (policies.campfires.includes('firewood_provided')) {
      campfires.push(<FormattedMessage id="ListingPage.rules.campfires.firewoodProvided" />);
    }
    if (policies.campfiresInfo) {
      campfires.push(<FormattedMessage id={policies.campfiresInfo} />);
    }

    return campfires.length && campfires.reduce((prev, curr) => [prev, '. ', curr]);
  };

  let items = [];

  if (arrivalInstructions) {
    if (arrivalInstructions.checkInTime) {
      items.push({
        label: <FormattedMessage id="ListingPage.rules.checkIn" />,
        value: (
          <FormattedMessage
            id="ListingPage.rules.checkInAfter"
            values={{ time: arrivalInstructions.checkInTime }}
          />
        ),
      });
    }

    if (arrivalInstructions.checkOutTime) {
      items.push({
        label: <FormattedMessage id="ListingPage.rules.checkOut" />,
        value: (
          <FormattedMessage
            id="ListingPage.rules.checkOutBefore"
            values={{ time: arrivalInstructions.checkOutTime }}
          />
        ),
      });
    }

    if (arrivalInstructions.milesToInterstate) {
      items.push({
        label: <FormattedMessage id="ListingPage.rules.nearbyInterstate" />,
        value:
          arrivalInstructions.milesToInterstate !== 'N/A' ? (
            <FormattedMessage
              id="ListingPage.rules.miles"
              values={{ miles: arrivalInstructions.milesToInterstate }}
            />
          ) : (
            <FormattedMessage id="ListingPage.rules.no" />
          ),
      });
    }

    if (arrivalInstructions.milesToHighway) {
      items.push({
        label: <FormattedMessage id="ListingPage.rules.nearbyHighway" />,
        value:
          arrivalInstructions.milesToHighway !== 'N/A' ? (
            <FormattedMessage
              id="ListingPage.rules.miles"
              values={{ miles: arrivalInstructions.milesToHighway }}
            />
          ) : (
            <FormattedMessage id="ListingPage.rules.no" />
          ),
      });
    }

    if (arrivalInstructions.milesToAirport) {
      items.push({
        label: <FormattedMessage id="ListingPage.rules.nearbyAirport" />,
        value: arrivalInstructions.milesToAirport ? (
          <FormattedMessage
            id="ListingPage.rules.miles"
            values={{ miles: arrivalInstructions.milesToAirport }}
          />
        ) : (
          <FormattedMessage id="ListingPage.rules.no" />
        ),
        info: arrivalInstructions.milesToAirport && arrivalInstructions.milesToAirportInfo,
      });
    }
  }

  if (policies) {
    items = [
      ...items,
      {
        label: <FormattedMessage id="ListingPage.rules.campfires" />,
        value: policies.allowsCampfires ? (
          <span>
            <FormattedMessage id="ListingPage.rules.allowed" />
          </span>
        ) : (
          <FormattedMessage id="ListingPage.rules.notAllowed" />
        ),
        info:
          policies.allowsCampfires &&
          policies.campfires &&
          policies.campfires.length > 0 &&
          renderCampfires(),
      },
      {
        label: <FormattedMessage id="ListingPage.rules.pets" />,
        value: policies.allowsPets ? (
          <FormattedMessage id="ListingPage.rules.allowedOnProperty" />
        ) : (
          <FormattedMessage id="ListingPage.rules.notAllowedOnProperty" />
        ),
        info: policies.allowsPets && policies.petsInfo,
      },
      {
        label: <FormattedMessage id="ListingPage.rules.atv" />,
        value: policies.allowsATV ? (
          <FormattedMessage id="ListingPage.rules.allowed" />
        ) : (
          <FormattedMessage id="ListingPage.rules.notAllowed" />
        ),
        info: policies.allowsATV && policies.ATVInfo,
      },
      {
        label: <FormattedMessage id="ListingPage.rules.parking" />,
        value: policies.allowsParking ? (
          <FormattedMessage id="ListingPage.rules.yes" />
        ) : (
          <FormattedMessage id="ListingPage.rules.no" />
        ),
        info: policies.allowsParking && policies.parkingInfo,
      },
      policies && policies.vehicles
        ? {
            label: <FormattedMessage id="ListingPage.rules.vehicles" />,
            value: (
              <FormattedMessage
                id="ListingPage.rules.numberAllowed"
                values={{ number: policies.vehicles || 0 }}
              />
            ),
            info: policies.vehiclesInfo,
          }
        : null,
      {
        label: <FormattedMessage id="ListingPage.rules.driveInAccess" />,
        value: policies.hasDriveInAccess ? (
          <FormattedMessage id="ListingPage.rules.yes" />
        ) : (
          <FormattedMessage id="ListingPage.rules.no" />
        ),
        info: policies.hasDriveInAccess && policies.driveInAccessInfo,
      },
      {
        label: <FormattedMessage id="ListingPage.rules.walkInAccess" />,
        value: policies.hasWalkInAccess ? (
          <FormattedMessage id="ListingPage.rules.yes" />
        ) : (
          <FormattedMessage id="ListingPage.rules.no" />
        ),
        info: policies.hasWalkInAccess && policies.walkInAccessInfo,
      },
      {
        label: <FormattedMessage id="ListingPage.rules.sportsmen" />,
        value: (
          <FormattedMessage
            id="ListingPage.rules.numberAllowed"
            values={{ number: policies.sportsmen || 0 }}
          />
        ),
        info: policies.sportsmenInfo,
      },
    ];
  }

  return (
    <div className={css.genericListSection}>
      <h3 className={css.sectionHeader}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h3>

      <div>
        <ul className={css.rulesList}>
          {items.map(
            (item, index) =>
              item &&
              item.value &&
              !item.showMore && (
                <li className={css.rulesListItem} key={`ground-rules-${index}`}>
                  <strong>{item.label}: </strong>
                  {item.value}

                  {item.info && <InfoTooltip header={item.label} content={item.info} />}
                </li>
              )
          )}
        </ul>
        {policies && policies.sportsmenPolicies && (
          <p>
            <FormattedHTMLMessage
              id="ListingPage.rules.sportsmenPolicies"
              values={{ text: policies.sportsmenPolicies }}
            />
          </p>
        )}

        <span>
          <strong>
            <FormattedMessage id="ListingPage.rules.cancellationPolicyLabel" />{' '}
          </strong>
          <FormattedMessage id="ListingPage.rules.cancellationPolicy" />{' '}
        </span>
        <span>
          <a
            href="https://landtrust.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className={css.linkDecor}
          >
            <FormattedMessage id="ListingPage.rules.cancellationPolicyButton" />
          </a>
        </span>
      </div>
    </div>
  );
};

SectionRules.defaultProps = {
  publicData: {
    policies: null,
    arrivalInstructions: null,
  },
};

SectionRules.propTypes = {
  publicData: shape({
    policies: object,
    arrivalInstructions: object,
  }),
};

export default SectionRules;
