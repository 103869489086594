import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';

import css from './FAQSectionHelp.css';
import CustomerExperienceCard from '../../components/CustomerExperienceCard/CustomerExperienceCard';
import { withViewport } from '../../util/contextHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const FAQSectionHelpComponent = ({ viewport }) => {
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  return (
    <div className={css.root}>
      <h1 className={css.title}>
        <FormattedMessage id="FAQLandownersPage.help.title" />
      </h1>

      <div className={css.cardContainer}>
        <CustomerExperienceCard
          title={
            !isMobileLayout ? (
              <FormattedMessage id="FAQLandownersPage.help.componentTitle" />
            ) : (
              undefined
            )
          }
        />
      </div>
    </div>
  );
};

FAQSectionHelpComponent.propTypes = {
  viewport: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
};

const FAQSectionHelp = compose(withViewport)(FAQSectionHelpComponent);

export default FAQSectionHelp;
