import React from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import { get } from 'lodash';
import { formatTimeAgo } from '../../../util/dates';
import { NamedLink } from '../../../components';
import BytescaleImage from '../../../components/BytescaleImage/BytescaleImage';
import BytescaleVideo from '../../../components/BytescaleVideo/BytescaleVideo';
import Avatar from '../../../components/Avatar/Avatar';
import css from './ListingUpdate.css';
import { createSlug } from '../../../util/urlHelpers';
import { isVideo } from '../../../util/data';
import { ScrollingCarousel, Step } from '../../../components/ScrollingCarousel/ScrollingCarousel';

const SectionPropertyPostUpdate = props => {
  const { listing, activity, renderBellIcon } = props;

  const { title } = listing.attributes;
  const slug = createSlug(title);

  const activityPhotos = activity.photos || [];
  const hasImages = activityPhotos.length > 0;

  return (
    <>
      <div key={activity.createdAt} className={css.content}>
        <div>
          <div className={css.flexContainer}>
            <div className={css.avatarContainer}>
              <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                <>
                  <Avatar
                    user={listing.author}
                    disableProfileLink
                    className={css.customAvatarSize}
                    listing={listing}
                    useListingImage
                  />
                  {renderBellIcon}
                </>
              </NamedLink>
            </div>
            <div className={css.propertyNameContainer}>
              <div className={css.propertyName}>
                <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                  <FormattedMessage
                    id="ListingPage.activityFeedPropertyName"
                    values={{
                      propertyName: listing.attributes.title,
                    }}
                  />
                </NamedLink>
              </div>
              <div className={css.dateWrapper}>
                <span className={css.dateContainer}>
                  <p className={css.landownerContainer}>
                    <FormattedMessage
                      id="ListingPage.activityFeedLandownerUpdate"
                      values={{
                        landownerName: listing.author.attributes.profile.displayName,
                      }}
                    />
                    {' • '}
                    {formatTimeAgo(activity?.createdAt)}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className={css.postWrapper}>
            <div className={css.descriptionStyles}>{activity.description}</div>
            {hasImages && (
              <ScrollingCarousel showDots={activityPhotos.length > 1} showNavigationOnHover>
                {activityPhotos.map((image, index) => (
                  <Step key={get(image, index)} className={css.step}>
                    <div className={css.imageWrapper}>
                      {isVideo(image.path) ? (
                        <BytescaleVideo videoPath={image.path} autoPlay muted playsInline />
                      ) : (
                        <BytescaleImage imagePath={image.path} className={css.imageStyles} />
                      )}
                    </div>
                  </Step>
                ))}
              </ScrollingCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SectionPropertyPostUpdate.defaultProps = {};

SectionPropertyPostUpdate.propTypes = {
  listing: propTypes.object.isRequired,
};

export default SectionPropertyPostUpdate;
