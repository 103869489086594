import React from 'react';
import { bool, object, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';

import {
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
  PrimaryButton,
  Form,
} from '../../components';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';
import MultiPhotoUploadField from './EditListingPhotoLibraryMultiUploadField';

import css from './EditListingPhotoLibraryForm.css';

const EditListingPhotoLibraryForm = ({
  fetchErrors,
  saveActionMsg,
  updateInProgress: submitInProgress,
  requestImageUpload,
  backLink,
  activeTab,
  updateTags,
  updateImages,
  onManageDisableScrolling,
  isUntaggedChecked,
  onUntaggedClick,
  ...formProps
}) => {
  return (
    <FinalForm
      {...formProps}
      keepDirtyOnReinitialize // Important so we don't lose state on re-renders
      render={({ form, className, handleSubmit, intl, invalid, values }) => {
        const { uploadImageError } = fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        let uploadImageFailed = null;

        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        }

        const classes = classNames(css.root, className);
        const { tags, imageCaptions } = values;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection isFullWidth>
              <EditListingError fetchErrors={fetchErrors} />

              <EditListingFieldWrapper>
                <MultiPhotoUploadField
                  form={form}
                  requestImageUpload={requestImageUpload}
                  intl={intl}
                  categories={['add']}
                  tags={tags}
                  imageCaptions={imageCaptions}
                  activeTab={activeTab}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isUntaggedChecked={isUntaggedChecked}
                  onUntaggedClick={onUntaggedClick}
                  updateTags={updateTags}
                  updateImages={updateImages}
                  disableSorting
                />
              </EditListingFieldWrapper>

              {uploadImageFailed}
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={invalid}>
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotoLibraryForm.defaultProps = {
  fetchErrors: null,
  backLink: null,
};

EditListingPhotoLibraryForm.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  listing: object.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  backLink: node,

  // ...formProps
  onSubmit: func.isRequired,

  // From mapStateToProps
  page: object.isRequired,

  // From mapDispatchToProps
  requestImageUpload: func.isRequired,

  // From injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    page: state.EditListingPage,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

const ConnectedEditListingPhotoLibraryForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(EditListingPhotoLibraryForm));

export default ConnectedEditListingPhotoLibraryForm;
