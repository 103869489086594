import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  transitionIsReviewed,
  isCustomerReview,
  isProviderReview,
  theLastTransition,
  isRelevantPastTransition,
} from '../../util/transaction';
import { ensureUser, ensureListing } from '../../util/data';

import { PrimaryButton } from '../Button/Button';
import { NamedLink } from '..';

const Review = ({ user }) => {
  return (
    <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
      <PrimaryButton>
        <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
      </PrimaryButton>
    </NamedLink>
  );
};

const InboxReview = ({ currentTransaction, currentUser }) => {
  const { lastTransition } = currentTransaction.attributes;
  const transactionTransition = theLastTransition(currentTransaction);

  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  if (!isRelevantPastTransition(transactionTransition.transition)) {
    return null;
  }

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  if (!transitionsAvailable) {
    return null;
  }

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(transactionTransition)) {
      reviewComponent = <Review user={currentUser} />;
    } else if (isProviderReview(transactionTransition)) {
      reviewComponent = <Review user={currentUser} />;
    }
  }

  return reviewComponent;
};

export default injectIntl(InboxReview);
