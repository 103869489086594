import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { shape, string } from 'prop-types';
import moment from 'moment';
import { parseDateFromISO8601 } from '../../util/dates';
import { fieldRepInfo } from '../../marketplace-custom-config';

import { ReactComponent as CircleIcon } from '../../assets/icons/circle-check.svg';

import css from './SectionPropertyVisit.css';

const FieldRepCard = props => {
  const { propertyVisit } = props;
  const { fieldRep, propertyUpdate, visitDate } = propertyVisit;

  const fieldRepDate = `${moment(parseDateFromISO8601(visitDate)).format('M/D/YYYY')}`;

  const formattedDescription =
    propertyUpdate &&
    (/<\/?[a-z][\s\S]*>/i.test(propertyUpdate)
      ? propertyUpdate
      : propertyUpdate.trim().replace(/\n/g, '<br />'));

  return (
    <div className={css.content}>
      <div className={css.info}>
        <div className={css.visitHeading}>
          <CircleIcon className={css.verifictionIcon} />
          <div className={css.descriptionHeader}>
            <FormattedMessage id="SectionPropertyVisit.visitHeading" />
          </div>
        </div>
        <div className={css.descriptionWrapper}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: formattedDescription,
            }}
          />
        </div>
      </div>
      <div className={css.flexContainer}>
        <div className={css.avatarContainer}>
          <img className={css.avatarImage} alt={fieldRep} src={fieldRepInfo[fieldRep].repPhoto} />
        </div>
        <div className={css.fieldRepContainer}>
          <div className={css.visitDate}>
            <FormattedMessage
              id="SectionPropertyVisit.fieldVisitDate"
              values={{
                fieldRepDate,
              }}
            />
          </div>
          <span className={css.fieldRepInfoContainer}>
            <p className={css.fieldRepName}>{fieldRepInfo[fieldRep].label}</p>
            <p className={css.desktopPipe}>|</p>
            <p className={css.fieldRepName}>
              <FormattedMessage id="SectionPropertyVisit.fieldRepInfo" />
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

FieldRepCard.defaultProps = {};

FieldRepCard.propTypes = {};

const SectionPropertyVisit = props => {
  const { propertyVisit, rootClassName } = props;
  const classes = classNames(rootClassName, css.breakoutContainer);

  return (
    <div className={css.sectionContainer}>
      <div className={classes} />
      <FieldRepCard propertyVisit={propertyVisit} />
    </div>
  );
};

SectionPropertyVisit.defaultProps = {
  rootClassName: null,
};

SectionPropertyVisit.propTypes = {
  propertyVisit: shape({
    fieldRep: string,
    propertyUpdate: string,
    visitDate: string,
  }).isRequired,
  rootClassName: string,
};

export default SectionPropertyVisit;
