import { get } from 'lodash';
import { types as sdkTypes } from './sdkLoader';

export const CAMPSITES_TYPE = {
  TENT: 'tent',
  RV: 'rv',
};

export const tentEmptyState = {
  allowsTents: false,
  tent: {
    hasDrinkingWater: false,
    info: null,
    price: null,
  },
};

export const rvEmptyState = {
  allowsRvs: false,
  rv: {
    hasCampsite: false,
    campsiteName: null,
    parking: [],
    isSurfaceLevel: false,
    surfaceInfo: null,
    surfaceSizeLength: 0,
    surfaceSizeWidth: 0,
    vehicleTypes: [],
    maxLength: 0,
    access: [],
    roadQuality: [],
    is4WDRequired: false,
    accessInfo: null,
    hasElectricHookup: false,
    electricHookup: [],
    electricHookupInfo: null,
    hasWaterHookup: false,
    waterHookupInfo: null,
    hasSewageHookup: false,
    sewageHookup: [],
    sewageHookupInfo: null,
    allowsGenerators: false,
    generatorsInfo: null,
    hasCookingArea: false,
    cookingArea: [],
    cookingAreaInfo: null,
    hasGarbage: false,
    garbage: [],
    garbageInfo: null,
    areCampfiresAllowed: false,
    campfires: [],
    campfiresInfo: null,
    amenities: [],
    price: null,
    latLng: null,
    info: null,
  },
};

export const emptyState = {
  ...tentEmptyState,
  ...rvEmptyState,
};

export const getInitialValues = listing => {
  const { publicData } = listing.attributes;
  const campsites = get(publicData, 'campsites', []);

  return {
    campsites:
      campsites.length > 0
        ? campsites.map(campsite => {
            const newCampsite = {
              ...campsite,
              rv: {
                ...campsite.rv,
              },
              tent: {
                ...campsite.tent,
              },
            };

            if (campsite?.rv?.price) {
              newCampsite.rv.price = JSON.parse(campsite.rv.price, sdkTypes.reviver);
            }

            if (campsite?.tent?.price) {
              newCampsite.tent.price = JSON.parse(campsite.tent.price, sdkTypes.reviver);
            }

            return newCampsite;
          })
        : [emptyState],
  };
};

export const getSubmissionValues = (values, packages) => {
  const { campsites } = values;

  const updatedCampsites = campsites.map(campsite => {
    let newCampsite = { ...campsite };

    if (!campsite.allowsTents) {
      newCampsite = {
        ...newCampsite,
        ...tentEmptyState,
      };
    }

    if (!campsite.allowsRvs) {
      newCampsite = {
        ...newCampsite,
        ...rvEmptyState,
      };
    }

    if (!campsite?.rv?.hasCampsite) {
      newCampsite = {
        ...newCampsite,
        ...rvEmptyState,
        allowsRvs: campsite.allowsRvs,
      };
    }

    if (!newCampsite?.rv?.isSurfaceLevel) {
      newCampsite.rv.surfaceInfo = null;
    }

    if (!newCampsite?.rv?.hasElectricHookup) {
      newCampsite.rv.electricHookup = [];
      newCampsite.rv.electricHookupInfo = null;
    }

    if (!newCampsite?.rv?.hasWaterHookup) {
      newCampsite.rv.waterHookupInfo = null;
    }

    if (!newCampsite?.rv?.hasSewageHookup) {
      newCampsite.rv.sewageHookup = [];
      newCampsite.rv.sewageHookupInfo = null;
    }

    if (!newCampsite?.rv?.allowsGenerators) {
      newCampsite.rv.generatorsInfo = null;
    }

    if (!newCampsite?.rv?.hasCookingArea) {
      newCampsite.rv.cookingArea = [];
      newCampsite.rv.cookingAreaInfo = null;
    }

    if (!newCampsite?.rv?.hasGarbage) {
      newCampsite.rv.garbage = [];
      newCampsite.rv.garbageInfo = null;
    }

    if (!newCampsite?.rv?.areCampfiresAllowed) {
      newCampsite.rv.campfires = [];
      newCampsite.rv.campfiresInfo = null;
    }

    if (newCampsite?.rv?.price) {
      newCampsite.rv.price = JSON.stringify(newCampsite.rv.price);
    }

    if (!newCampsite?.rv?.campsiteName) {
      newCampsite.rv.campsiteName = '';
    }

    if (newCampsite?.tent?.price) {
      newCampsite.tent.price = JSON.stringify(newCampsite.tent.price);
    }

    return newCampsite;
  });

  // Update packages lodgingPrices
  const updatedPackages = packages.map(item => {
    const lodgingPrices = get(item, 'lodgingPrices', []);

    const updatedLodgingPrices = lodgingPrices.filter(lodgingPrice => {
      const campsite = updatedCampsites[lodgingPrice.index];

      if (lodgingPrice.type === CAMPSITES_TYPE.RV) {
        if (campsite?.allowsRvs) {
          return true;
        }

        return false;
      }

      if (lodgingPrice.type === CAMPSITES_TYPE.TENT) {
        if (campsite?.allowsTents) {
          return true;
        }

        return false;
      }

      return true;
    });

    return {
      ...item,
      lodgingPrices: updatedLodgingPrices,
    };
  });

  return {
    campsites: updatedCampsites,
    packages: updatedPackages,
  };
};
