import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { get } from 'lodash';
import { func, object } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { PrimaryButton } from '../../components';
import { PaymentForm } from '../../components/StripeCheckoutForm/StripeCheckoutForm';
import css from './PaymentMethodsForm.css';

const PaymentMethodsForm = ({ intl, currentUser, onSavePaymentMethod, onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFormSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsSubmitting(false);
        return;
      }

      setErrorMessage(null);

      const profile = get(currentUser, 'attributes.profile', {});
      const email = get(currentUser, 'attributes.email');
      const { stripeCustomer } = currentUser;
      const { firstName, lastName } = profile;
      const billingDetails = {};

      if (email) {
        billingDetails.email = email;
      }

      if (firstName && lastName) {
        billingDetails.name = `${firstName} ${lastName}`;
      }

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
        redirect: 'if_required',
      });

      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('An unexpected error occurred.');
        }

        setIsSubmitting(false);
      } else {
        const newPaymentMethod = setupIntent.payment_method;
        await onSavePaymentMethod(stripeCustomer, newPaymentMethod);
        onSubmit();
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={css.root}>
      <PaymentForm intl={intl} />

      {errorMessage ? <span className={css.errorMessage}>{errorMessage}</span> : null}

      <div className={css.submitContainer}>
        <PrimaryButton
          isFullWidth
          disabled={isSubmitting || !stripe || !elements}
          type="button"
          onClick={handleFormSubmit}
          className={css.button}
          inProgress={isSubmitting}
        >
          <FormattedMessage id="PaymentMethodsForm.saveChanges" />
        </PrimaryButton>
      </div>
    </div>
  );
};

PaymentMethodsForm.defaultProps = {};

PaymentMethodsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  currentUser: object.isRequired,
  onSavePaymentMethod: func.isRequired,
};

export default injectIntl(PaymentMethodsForm);
