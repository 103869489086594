import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { get, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { format } from '../../util/fiFormatter';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  AuthenticationPageReview,
  Page,
  NamedLink,
  NamedRedirect,
  LinkTabNavHorizontal,
  IconEmailSent,
  InlineTextButton,
  IconClose,
  Logo,
  LayoutWrapperMain,
  FacebookLoginButton,
  GoogleLoginButton,
  Modal,
  TermsOfService,
} from '../../components';
import { LoginForm, SignupForm, IdpSignupForm } from '../../forms';
import {
  login,
  authenticationInProgress,
  signup,
  signupStarted,
  signupWithIdp,
} from '../../ducks/Auth.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';

import css from './AuthenticationPage.css';

const { loginPageReviews } = config.custom;

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);

    const { reviewIndex } = props;

    this.state = {
      tosModalOpen: false,
      showPassword: false,
      isFromSignup: props.tab === 'signup',
      // eslint-disable-next-line react/no-unused-state
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      showTabs: true,
      review: loginPageReviews[reviewIndex || Math.floor(Math.random() * loginPageReviews.length)],
    };

    this.toggleShow = this.toggleShow.bind(this);

    const { signupStart, location } = this.props;
    if (props.tab === 'signup') {
      signupStart({ path: location.pathname });
    }
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  toggleShow() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      updateUserData,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      confirmError,
      submitSignupWithIdp,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const { showPassword, tosModalOpen, authInfo, showTabs, review, isFromSignup } = this.state;

    const isLogin = tab === 'login';
    const locationFrom = get(location, 'state.from', null);
    const locationSearch = get(location, 'search', null);
    const parsedLocationSearch = queryString.parse(locationSearch);
    const locationSearchFrom = get(parsedLocationSearch, 'from', null);
    const authinfoFrom = get(authInfo, 'from', null);
    const signupContact = get(location, 'state.signupContact', null);
    const signupBook = get(location, 'state.signupBook', null);

    let from = null;
    if (locationFrom) {
      from = locationFrom;
    }
    if (authinfoFrom) {
      from = authinfoFrom;
    }

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;
    const userIsGuest = user?.attributes?.profile?.protectedData?.role?.includes('sportsman');

    const showGuestForm =
      userIsGuest &&
      (!user?.attributes?.profile?.protectedData?.preferredHuntingStates?.length > 0 ||
        !user?.attributes?.profile?.protectedData?.preferredHuntingSpecies?.length > 0);

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification =
      !isLogin && currentUserLoaded && !user.attributes.emailVerified && !showGuestForm;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated) {
      if (from) {
        return <Redirect to={from} />;
      }

      // Hard reload, this is used from the NEW UI to redirect user to the correct page
      if (locationSearchFrom) {
        window.location.href = locationSearchFrom;
      }
    }

    const isLandowner = user?.attributes?.profile?.protectedData?.role?.includes('landowner');

    if (!isFromSignup) {
      if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
        // If landowner, redirect to landowner dashboard page
        if (isLandowner && !config.dev) {
          return <NamedRedirect name="LandownersPage" />;
        }

        return <NamedRedirect name="LandingPage" />;
      }
    }

    const loginErrorMessage = (
      <div className={css.error} data-testid="login-form-error">
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error} data-testid="signup-form-error">
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage
            id="AuthenticationPage.signupFailedEmailAlreadyTaken"
            values={{
              link: (
                <Link to="/login" style={{ textDecoration: 'underline' }} className={css.error}>
                  logging in
                </Link>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = { state: from ? { from } : null };

    const tabs = [
      {
        text: (
          <h4 className={css.tabContent}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h4>
        ),
        selected: !isLogin,
        linkProps: {
          name: 'SignupPage',
          to: fromState,
        },
      },
      {
        text: (
          <h4 className={css.tabContent}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h4>
        ),
        selected: isLogin,
        linkProps: {
          name: 'LoginPage',
          to: fromState,
        },
      },
    ];

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;
      const params = { firstName: fname?.trim(), lastName: lname?.trim(), ...rest };
      submitSignup(params);
    };

    const getSubmissionValues = values => {
      return {
        publicData: {
          usState:
            values.usState && values.usState.selectedPlace
              ? {
                  search: values.usState.search,
                  selectedPlace: {
                    address: values.usState.selectedPlace.address,
                    bounds: values.usState.selectedPlace.bounds,
                    origin: {
                      lat: values.usState.selectedPlace.origin.lat,
                      lng: values.usState.selectedPlace.origin.lng,
                    },
                    usCounty: values.usState.selectedPlace.usCounty,
                    usState: values.usState.selectedPlace.usState,
                  },
                }
              : null,
          interestedActivities: values.interestedActivities || [],
          preferredHuntingSpecies: values.preferredHuntingSpecies || [],
          preferredHuntingStates: Array.isArray(values.preferredHuntingStates)
            ? values.preferredHuntingStates.map(state => {
                return { address: state.address };
              })
            : [],
        },
        protectedData: {
          referralMethod: values.referralMethod || [],
        },
      };
    };

    const handleUpdateAccount = async data => {
      try {
        const updateParams = getSubmissionValues(data);
        await updateUserData(updateParams);
      } catch (error) {
        console.error('Error updating account:', error);
      }
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = authInfo;
      const {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        preferredHuntingStates = [],
        preferredHuntingSpecies = [],
        interestedActivities = [],
        usState,
        ...rest
      } = values;
      // Pass email, fistName or lastName to Flex API only if user has edited them
      // and they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
        usState,
        preferredHuntingStates,
        preferredHuntingSpecies,
        interestedActivities,
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      // Add formatted phone number for email purposes
      if (protectedData && protectedData.phoneNumber) {
        const phoneNumberFormatted = format(protectedData.phoneNumber);

        protectedData.phoneNumberFormatted = phoneNumberFormatted;
      }

      submitSignupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };
    const authWithFacebook = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const authWithGoogle = () => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

    // Form for confirming information from IdP (e.g. Facebook)
    // before new user is created to Flex
    const idpSignupForm = (
      <div className={css.content}>
        <IdpSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          isFromSignup
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={authInfo}
          idp={idp}
          confirmErrorMessage={confirmErrorMessage}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );
    const socialLoginButtonsMaybe =
      showSocialLogins && showTabs ? (
        <div className={css.idpButtons}>
          {showFacebookLogin ? (
            <div data-testId="login-facebook-button" className={css.socialButtonWrapper}>
              <FacebookLoginButton onClick={() => authWithFacebook()}>
                <span className={css.buttonIcon}>{FacebookLogo}</span>
                {facebookButtonText}
              </FacebookLoginButton>
            </div>
          ) : null}

          {showGoogleLogin ? (
            <div className={css.socialButtonWrapper}>
              <GoogleLoginButton onClick={() => authWithGoogle()}>
                <span className={css.buttonIcon}>{GoogleLogo}</span>
                {googleButtonText}
              </GoogleLoginButton>
            </div>
          ) : null}
          <div className={css.socialButtonsOr}>
            <span className={css.socialButtonsOrText}>
              {isLogin ? (
                <FormattedMessage id="AuthenticationPage.orLogin" />
              ) : (
                <FormattedMessage id="AuthenticationPage.orSignup" />
              )}
            </span>
          </div>
        </div>
      ) : null;

    // sign-up headear selector depending on where user comes from in the app
    const signupHeaderPicker = () => {
      let signupHeaderMaybe = 'AuthenticationPage.signupDefault';
      if (signupContact) {
        signupHeaderMaybe = 'AuthenticationPage.signupContact';
      }
      if (signupBook) {
        signupHeaderMaybe = 'AuthenticationPage.signupBook';
      }
      return signupHeaderMaybe;
    };

    // Tabs for SignupForm and LoginForm
    const authenticationForms = (
      <div data-testid="authentication-form" className={css.content}>
        {showTabs && (
          <>
            <div className={css.headerWrapper}>
              {isLogin ? (
                <FormattedHTMLMessage id="AuthenticationPage.loginDefault" />
              ) : (
                <FormattedHTMLMessage id={signupHeaderPicker()} />
              )}
            </div>

            <LinkTabNavHorizontal
              className={css.tabs}
              tabRootClassName={css.tab}
              tabClassName={css.tab}
              tabs={tabs}
            />
          </>
        )}

        {loginOrSignupError}
        {socialLoginButtonsMaybe}
        {isLogin ? (
          <LoginForm
            className={css.form}
            onSubmit={submitLogin}
            inProgress={authInProgress}
            showPassword={showPassword}
            toggleShow={this.toggleShow}
          />
        ) : (
          <SignupForm
            className={css.form}
            user={user}
            isFromSignup
            from={from}
            onSubmit={handleSubmitSignup}
            onUpdate={handleUpdateAccount}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
            showPassword={showPassword}
            toggleShow={this.toggleShow}
            onHideTabs={() => this.setState({ showTabs: false })}
            isAuthenticated={isAuthenticated}
            currentUserLoaded={currentUserLoaded}
            showEmailVerification={showEmailVerification}
            isLandowner={isLandowner}
            config={config}
          />
        )}
      </div>
    );
    const formContent = isConfirm ? idpSignupForm : authenticationForms;
    const name = user?.attributes?.profile?.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ProfileSettingsBasePage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="ProfileSettingsBasePage">
          <span data-testId="verify-close-button" className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink>
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );

    const { siteTitle } = config;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });
    const schemaDescription = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionSignup' }, { siteTitle });

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'AuthenticationPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
          description: schemaDescription,
        }}
      >
        {topbar}
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.colLeft}>
            <div className={css.colLeftWrapper}>
              <NamedLink className={css.home} name="LandingPage">
                <Logo
                  className={css.logoDesktop}
                  alt={intl.formatMessage({ id: 'AuthenticationPage.goToLandingPage' })}
                  format="desktop"
                  variant="light"
                />
              </NamedLink>

              <div className={css.reviewWrapper}>
                <AuthenticationPageReview {...review} />
              </div>
            </div>
          </div>

          <div className={isConfirm ? css.colRightConfirm : css.colRight}>
            <div className={css.root}>
              {showEmailVerification ? emailVerificationContent : formContent}
            </div>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.termsHeading" />
                </h2>
                <TermsOfService />
              </div>
            </Modal>
          </div>
        </LayoutWrapperMain>
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  reviewIndex: null,
};

const { bool, func, object, oneOf, shape, number } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  confirmError: propTypes.error,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  updateUserData: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),
  reviewIndex: number,

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  signupStart: params => dispatch(signupStarted(params)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  submitSignup: params => dispatch(signup(params)),
  updateUserData: data => dispatch(updateProfile(data)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
