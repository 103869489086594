import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ExternalLink, Button, IconClose } from '..';
import { PrimaryButton } from '../Button/Button';

import css from './CookieConsent.css';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');

      try {
        const decoded = { ...acc, [name]: decodeURIComponent(value) };
        return decoded;
      } catch (err) {
        return err;
      }
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  onAcceptCookies() {
    this.saveCookieConsent();
    this.setState({ show: false });
  }

  // eslint-disable-next-line class-methods-use-this
  saveCookieConsent() {
    // We create date object and modify it to show date 10 years into the future.
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = `euCookiesAccepted=1; path=/; expires=${expirationDate.toGMTString()}`;
  }

  render() {
    const { className, rootClassName } = this.props;
    const { show } = this.state;
    const isServer = typeof window === 'undefined';
    const closeModalMessage = <FormattedMessage id="Modal.closeModal" />;

    const closeBtn = (
      <Button onClick={this.onAcceptCookies} className={css.close} title={closeModalMessage}>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    );

    // Server side doesn't know about cookie consent
    if (isServer || !show) {
      return null;
    }
    const cookieLink = (
      <ExternalLink href="https://cookiesandyou.com" className={css.cookieLink}>
        <FormattedMessage id="CookieConsent.cookieLink" />
      </ExternalLink>
    );
    const classes = classNames(rootClassName || css.root, className, css.bannerOpacity);

    return (
      <div className={classes}>
        <div className={css.container}>
          {closeBtn}
          <div className={css.message}>
            <FormattedMessage id="CookieConsent.message" values={{ cookieLink }} />
          </div>
          <PrimaryButton type="button" className={css.continueBtn} onClick={this.onAcceptCookies}>
            <FormattedMessage id="CookieConsent.continue" />
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const { string } = PropTypes;

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,
};

export default CookieConsent;
