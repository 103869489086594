// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const ELEMENT_CLICKED = 'app/UI/ELEMENT_CLICKED';
export const MISSING_INFORMATION_REMINDER_SHOWN = 'app/UI/MISSING_INFORMATION_REMINDER_SHOWN';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  missingInformationReminderShown: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const { disableScrollRequests } = state;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case MISSING_INFORMATION_REMINDER_SHOWN: {
      return {
        ...state,
        missingInformationReminderShown: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const uiElementClicked = payload => ({
  type: ELEMENT_CLICKED,
  payload,
});

export const setMissingInformationReminderShown = payload => ({
  type: MISSING_INFORMATION_REMINDER_SHOWN,
  payload,
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.UI;
  return disableScrollRequests.some(r => r.disableScrolling);
};
