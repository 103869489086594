import { array } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import css from './SEOBreadcrumbs.css';

const SEOBreadcrumbs = ({ breadcrumbs }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.scrollWidth) {
      ref.current.scrollLeft = ref.current.scrollWidth;
    }
  }, [breadcrumbs]);

  return (
    <div className={css.root} ref={ref}>
      {breadcrumbs.map((item, index) => (
        <>
          <span className={css.breadcrumbs}>{item}</span>
          {breadcrumbs.length - 1 > index && '/'}{' '}
        </>
      ))}
    </div>
  );
};

SEOBreadcrumbs.propTypes = {
  breadcrumbs: array.isRequired,
};

export default SEOBreadcrumbs;
