import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';
import { formatMoney } from '../../util/currency';
import { PrimaryButton, SecondaryButton, DeclineModal } from '..';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    isSpecialOffer,
    currentTransaction,
    intl,
  } = props;
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);

  const totalPrice = get(currentTransaction, 'attributes.payoutTotal', 0);

  const earnings = totalPrice && formatMoney(intl, totalPrice);

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
          className={css.acceptButton}
        >
          <FormattedMessage
            id={isSpecialOffer ? 'TransactionPanel.specialOffer' : 'TransactionPanel.acceptButton'}
          />
        </PrimaryButton>

        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={() => setIsDeclineModalOpen(true)}
          className={css.declineButton}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
      </div>

      <DeclineModal
        isOpen={isDeclineModalOpen}
        onCloseModal={() => setIsDeclineModalOpen(false)}
        onDecline={onDeclineSale}
        inProgress={declineInProgress}
        disabled={buttonsDisabled}
        earnings={earnings}
      />
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
