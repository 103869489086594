import { get } from 'lodash';
import axios from 'axios';
import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
import { util as sdkUtil } from '../util/sdkLoader';
import * as log from '../util/log';
// eslint-disable-next-line import/no-cycle
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { getUserToken } from '../util/api';
import config from '../config';

// ================ Action types ================ //

export const CURRENT_USER_INIT = 'app/user/CURRENT_USER_INIT';
export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const CURRENT_USER_LISTINGS_CHECKER_REQUEST =
  'app/user/CURRENT_USER_LISTINGS_CHECKER_REQUEST';
export const CURRENT_USER_LISTINGS_CHECKER_SUCCESS =
  'app/user/CURRENT_USER_LISTINGS_CHECKER_SUCCESS';
export const CURRENT_USER_LISTINGS_CHECKER_ERROR = 'app/user/CURRENT_USER_LISTINGS_CHECKER_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const LISTING_FAVORED = 'app/user/LISTING_FAVORED';
export const LISTING_UNFAVORED = 'app/user/LISTING_UNFAVORED';

export const UPDATE_FAVORITE_LISTINGS_REQUEST = 'app/user/UPDATE_FAVORITE_LISTINGS_REQUEST';
export const UPDATE_FAVORITE_LISTINGS_SUCCESS = 'app/user/UPDATE_FAVORITE_LISTINGS_SUCCESS';
export const UPDATE_FAVORITE_LISTINGS_ERROR = 'app/user/UPDATE_FAVORITE_LISTINGS_ERROR';

export const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/user/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/user/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/user/FETCH_FAVORITE_LISTINGS_ERROR';

export const PACKAGE_FAVORED = 'app/user/PACKAGE_FAVORED';
export const PACKAGE_UNFAVORED = 'app/user/PACKAGE_UNFAVORED';

export const UPDATE_FAVORITE_PACKAGES_REQUEST = 'app/user/UPDATE_FAVORITE_PACKAGES_REQUEST';
export const UPDATE_FAVORITE_PACKAGES_SUCCESS = 'app/user/UPDATE_FAVORITE_PACKAGES_SUCCESS';
export const UPDATE_FAVORITE_PACKAGES_ERROR = 'app/user/UPDATE_FAVORITE_PACKAGES_ERROR';

export const FETCH_FAVORITE_PACKAGES_REQUEST = 'app/user/FETCH_FAVORITE_PACKAGES_REQUEST';
export const FETCH_FAVORITE_PACKAGES_SUCCESS = 'app/user/FETCH_FAVORITE_PACKAGES_SUCCESS';
export const FETCH_FAVORITE_PACKAGES_ERROR = 'app/user/FETCH_FAVORITE_PACKAGES_ERROR';

export const UPDATE_USER_PROMO_CODES_REQUEST = 'app/user/UPDATE_USER_PROMO_CODES_REQUEST';
export const UPDATE_USER_PROMO_CODES_SUCCESS = 'app/user/UPDATE_USER_PROMO_CODES_SUCCESS';
export const UPDATE_USER_PROMO_CODES_ERROR = 'app/user/UPDATE_USER_PROMO_CODES_ERROR';

export const UPDATE_USER_WAITING_LIST_REQUEST = 'app/user/UPDATE_USER_WAITING_LIST_REQUEST';
export const UPDATE_USER_WAITING_LIST_SUCCESS = 'app/user/UPDATE_USER_WAITING_LIST_SUCCESS';
export const UPDATE_USER_WAITING_LIST_ERROR = 'app/user/UPDATE_USER_WAITING_LIST_ERROR';

export const FETCH_CURRENT_USER_ACTIVITY_COUNT_REQUEST =
  '/app/user/FETCH_CURRENT_USER_ACTIVITY_COUNT_REQUEST';

export const FETCH_CURRENT_USER_ACTIVITY_COUNT_SUCCESS =
  '/app/user/FETCH_CURRENT_USER_ACTIVITY_COUNT_SUCCESS';

export const FETCH_FOLLOWED_SUBJECTS_REQUEST = '/app/user/FETCH_FOLLOWED_SUBJECTS_REQUEST';
export const FETCH_FOLLOWED_SUBJECTS_SUCCESS = '/app/user/FETCH_FOLLOWED_SUBJECTS_SUCCESS';

export const FOLLOW_SUBJECT_REQUEST = '/app/user/FOLLOW_SUBJECT_REQUEST';
export const FOLLOW_SUBJECT_SUCCESS = '/app/user/FOLLOW_SUBJECT_SUCCESS';

export const UNFOLLOW_SUBJECT_REQUEST = '/app/user/UNFOLLOW_SUBJECT_REQUEST';
export const UNFOLLOW_SUBJECT_SUCCESS = '/app/user/UNFOLLOW_SUBJECT_SUCCESS';

export const UPDATE_FOLLOWED_SUBJECT_REQUEST = '/app/user/UPDATE_FOLLOWED_SUBJECT_REQUEST';
export const UPDATE_FOLLOWED_SUBJECT_SUCCESS = '/app/user/UPDATE_FOLLOWED_SUBJECT_SUCCESS';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  // eslint-disable-next-line no-unused-vars
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  // eslint-disable-next-line no-nested-ternary
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  currentUserOrdersCount: 0,
  currentUserCompletedOrdersCount: 0,
  currentUserHasCompletedOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  fetchFavoriteListingsError: null,
  fetchingFavoriteListingsInProgress: false,
  fetchFavoritePackagesError: null,
  fetchingFavoritePackagesInProgress: false,
  updatingPromoCodesError: null,
  updatingPromoCodesInProgress: false,
  updatingWaitingListError: null,
  updatingWaitingListInProgress: false,
  favoriteListings: [],
  favoritePackages: [],
  currentUserActivityCount: 0,
  fetchingCurrentUserActivityCountInProgress: false,
  followedSubjects: [],
  fetchingFollowedSubjectsInProgress: false,
  currentUserInit: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_INIT:
      return { ...state, currentUserInit: true };
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case CURRENT_USER_LISTINGS_CHECKER_REQUEST:
      return { ...state, currentUserListingChecker: null };
    case CURRENT_USER_LISTINGS_CHECKER_SUCCESS:
      return { ...state, currentUserListingChecker: payload.hasListings };
    case CURRENT_USER_LISTINGS_CHECKER_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserListingCheckerError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return {
        ...state,
        currentUserHasOrders: payload.hasOrders,
        currentUserOrdersCount: payload.ordersCount || 0,
      };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_REQUEST:
      return { ...state, currentUserHasCompletedOrdersError: null };
    case FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_SUCCESS:
      return {
        ...state,
        currentUserCompletedOrdersCount: payload.ordersCount || 0,
      };
    case FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_ERROR:
      return { ...state, currentUserHasCompletedOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchingFavoriteListingsInProgress: true,
        fetchFavoriteListingsError: null,
      };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return { ...state, fetchingFavoriteListingsInProgress: false, favoriteListings: payload };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      return {
        ...state,
        fetchingFavoriteListingsInProgress: false,
        fetchFavoriteListingsError: payload,
      };

    case FETCH_FAVORITE_PACKAGES_REQUEST:
      return {
        ...state,
        fetchingFavoriteListingsInProgress: true,
        fetchFavoriteListingsError: null,
      };
    case FETCH_FAVORITE_PACKAGES_SUCCESS:
      return { ...state, fetchingFavoritePackagesInProgress: false, favoritePackages: payload };
    case FETCH_FAVORITE_PACKAGES_ERROR:
      return {
        ...state,
        fetchingFavoritePackagesInProgress: false,
        fetchFavoritePackagesError: payload,
      };

    case UPDATE_USER_PROMO_CODES_REQUEST:
      return {
        ...state,
        updatingPromoCodesInProgress: true,
        updatingPromoCodesError: null,
      };
    case UPDATE_USER_PROMO_CODES_SUCCESS:
      return { ...state, updatingPromoCodesInProgress: false };
    case UPDATE_USER_PROMO_CODES_ERROR:
      return {
        ...state,
        updatingPromoCodesInProgress: false,
        updatingPromoCodesError: payload,
      };
    case UPDATE_USER_WAITING_LIST_REQUEST:
      return {
        ...state,
        updatingWaitingListInProgress: true,
        updatingWaitingListError: null,
      };
    case UPDATE_USER_WAITING_LIST_SUCCESS:
      return { ...state, updatingWaitingListInProgress: false };
    case UPDATE_USER_WAITING_LIST_ERROR:
      return {
        ...state,
        updatingWaitingListInProgress: false,
        updatingWaitingListError: payload,
      };
    case FETCH_CURRENT_USER_ACTIVITY_COUNT_REQUEST:
      return {
        ...state,
        fetchingCurrentUserActivityCountInProgress: true,
      };
    case FETCH_CURRENT_USER_ACTIVITY_COUNT_SUCCESS:
      return {
        ...state,
        currentUserActivityCount: payload,
        fetchingCurrentUserActivityCountInProgress: false,
      };
    case FETCH_FOLLOWED_SUBJECTS_SUCCESS: {
      return {
        ...state,
        followedSubjects: payload,
        fetchingFollowedSubjectsInProgress: false,
      };
    }
    case FETCH_FOLLOWED_SUBJECTS_REQUEST:
      return {
        ...state,
        fetchingFollowedSubjectsInProgress: true,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserInit = () => ({
  type: CURRENT_USER_INIT,
});

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});
const currentUserListingsCheckerRequest = () => ({
  type: CURRENT_USER_LISTINGS_CHECKER_REQUEST,
});

export const currentUserListingsCheckerSuccess = hasListings => ({
  type: CURRENT_USER_LISTINGS_CHECKER_SUCCESS,
  payload: { hasListings },
});

const currentUserListingsCheckerError = e => ({
  type: CURRENT_USER_LISTINGS_CHECKER_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = (hasOrders, ordersCount) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders, ordersCount },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasCompletedOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_REQUEST,
});

export const fetchCurrentUserHasCompletedOrdersSuccess = ordersCount => ({
  type: FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_SUCCESS,
  payload: { ordersCount },
});

const fetchCurrentUserHasCompletedOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_COMPLETED_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const listingFavored = listing => ({
  type: LISTING_FAVORED,
  payload: { listing },
});

export const listingUnfavored = listing => ({
  type: LISTING_UNFAVORED,
  payload: { listing },
});

export const updateFavoriteListingsRequest = () => ({
  type: UPDATE_FAVORITE_LISTINGS_REQUEST,
});

export const updateFavoriteListingsSuccess = () => ({
  type: UPDATE_FAVORITE_LISTINGS_SUCCESS,
});

export const updateFavoriteListingsError = () => ({
  type: UPDATE_FAVORITE_LISTINGS_ERROR,
});

export const fetchFavoriteListingsRequest = () => ({ type: FETCH_FAVORITE_LISTINGS_REQUEST });

export const fetchFavoriteListingsSuccess = listings => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: listings,
});

export const fetchFavoriteListingsError = error => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: error,
});

export const packageFavored = (pkg, listing) => ({
  type: PACKAGE_FAVORED,
  payload: { package: pkg, listing },
});

export const packageUnfavored = (pkg, listing) => ({
  type: PACKAGE_UNFAVORED,
  payload: { package: pkg, listing },
});

export const updateFavoritePackagesRequest = () => ({ type: UPDATE_FAVORITE_PACKAGES_REQUEST });

export const updateFavoritePackagesSuccess = () => ({ type: UPDATE_FAVORITE_PACKAGES_SUCCESS });

export const updateFavoritePackagesError = () => ({ type: UPDATE_FAVORITE_PACKAGES_ERROR });

export const fetchFavoritePackagesRequest = () => ({ type: FETCH_FAVORITE_PACKAGES_REQUEST });

export const fetchFavoritePackagesSuccess = packages => ({
  type: FETCH_FAVORITE_PACKAGES_SUCCESS,
  payload: packages,
});

export const fetchFavoritePackagesError = error => ({
  type: FETCH_FAVORITE_PACKAGES_ERROR,
  error: true,
  payload: error,
});

export const updatePromoCodesRequest = () => ({ type: UPDATE_USER_PROMO_CODES_REQUEST });

export const updatePromoCodesSuccess = (transactionId, promoCode) => ({
  type: UPDATE_USER_PROMO_CODES_SUCCESS,
  payload: { id: transactionId, promoCode },
});

export const updatePromoCodesError = error => ({
  type: UPDATE_USER_PROMO_CODES_ERROR,
  payload: error,
});

export const updateWaitingListRequest = () => ({ type: UPDATE_USER_WAITING_LIST_REQUEST });

export const updateWaitingListSuccess = packageId => ({
  type: UPDATE_USER_WAITING_LIST_SUCCESS,
  payload: { packageId },
});

export const updateWaitingListError = error => ({
  type: UPDATE_USER_WAITING_LIST_ERROR,
  payload: error,
});

export const fetchCurrentUserActivityCountRequest = () => ({
  type: FETCH_CURRENT_USER_ACTIVITY_COUNT_REQUEST,
});

export const fetchCurrentUserActivityCountSuccess = count => ({
  type: FETCH_CURRENT_USER_ACTIVITY_COUNT_SUCCESS,
  payload: count,
});

export const fetchFollowedSubjectsRequest = () => ({
  type: FETCH_FOLLOWED_SUBJECTS_REQUEST,
});

export const fetchFollowedSubjectsSuccess = item => ({
  type: FETCH_FOLLOWED_SUBJECTS_SUCCESS,
  payload: item,
});

export const followSubjectRequest = () => ({
  type: FOLLOW_SUBJECT_REQUEST,
});

export const followSubjectSuccess = item => ({
  type: FOLLOW_SUBJECT_SUCCESS,
  payload: item,
});

export const unfollowSubjectRequest = () => ({
  type: UNFOLLOW_SUBJECT_REQUEST,
});

export const unfollowSubjectSuccess = item => ({
  type: UNFOLLOW_SUBJECT_SUCCESS,
  payload: item,
});

export const updateFollowedSubjectRequest = () => ({
  type: UPDATE_FOLLOWED_SUBJECT_REQUEST,
});

export const updateFollowedSubjectSuccess = item => ({
  type: UPDATE_FOLLOWED_SUBJECT_SUCCESS,
  payload: item,
});

// ================ Thunks ================ //

export const fetchCurrentUserActivityCount = () => async dispatch => {
  dispatch(fetchCurrentUserActivityCountRequest());

  const token = getUserToken();

  const { data } = await axios.post(
    config.graphQLapi,
    {
      query: `
        query {
          notifications {
            unreadCount
          }
        }
      `,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    }
  );

  dispatch(fetchCurrentUserActivityCountSuccess(data.data.notifications.unreadCount));
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const currentUserListingsChecker = () => (dispatch, getState, sdk) => {
  dispatch(currentUserListingsCheckerRequest());

  const params = {
    // Since we just created one draft listing we want to check for a second listing
    page: 1,
    per_page: 2,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 1;
      dispatch(currentUserListingsCheckerSuccess(hasListings));
      return hasListings;
    })
    .catch(e => dispatch(currentUserListingsCheckerError(storableError(e))));
};

export const fetchCurrentUserHasCompletedOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasCompletedOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasCompletedOrdersRequest(0));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
    lastTransitions: [
      'transition/complete',
      'transition/expire-review-period',
      'transition/expire-customer-review-period',
      'transition/review-1-by-customer',
      'transition/review-2-by-customer',
      'transition/expire-provider-review-period',
      'transition/review-1-by-provider',
      'transition/review-2-by-provider',
    ],
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const ordersCount = response.data.meta && response.data.meta.totalItems;

      dispatch(fetchCurrentUserHasCompletedOrdersSuccess(ordersCount));
    })
    .catch(e => dispatch(fetchCurrentUserHasCompletedOrdersError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false, 0));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      const ordersCount = response.data.meta && response.data.meta.totalItems;

      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders, ordersCount));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());

  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));

    // We created this state to show that the current user has been initialized.
    // Doesnt mean that it was fetched. This is then used in page components to show/hide components.
    if (params?.initial) {
      dispatch(currentUserInit());
    }

    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount', 'stripeCustomer.defaultPaymentMethod'],
    'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.default'],
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      dispatch(fetchCurrentUserHasOrders());
      dispatch(fetchCurrentUserHasCompletedOrders());
      dispatch(fetchCurrentUserActivityCount());

      // Make sure auth info is up to date
      dispatch(authInfo());

      dispatch(currentUserInit());

      return currentUser;
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      dispatch(currentUserInit());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const updateFavoriteListings = favoriteListings => (dispatch, getState, sdk) => {
  dispatch(updateFavoriteListingsRequest());

  return sdk.currentUser
    .updateProfile({ publicData: { favoriteListings } }, {})
    .then(() => dispatch(updateFavoriteListingsSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(() => updateFavoriteListingsError());
};

export const toggleFavoriteListings = listing => (dispatch, getState) => {
  const {
    user: { currentUser },
  } = getState();

  const favoriteListings = get(currentUser, 'attributes.profile.publicData.favoriteListings', []);
  const index = favoriteListings.indexOf(listing.id.uuid);

  if (index > -1) {
    favoriteListings.splice(index, 1);
    dispatch(listingUnfavored(listing));
  } else {
    favoriteListings.push(listing.id.uuid);
    dispatch(listingFavored(listing));
  }

  return dispatch(updateFavoriteListings(favoriteListings));
};

export const fetchFavoriteListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchFavoriteListingsRequest());

  const {
    user: { currentUser },
  } = getState();

  const favoriteListingIds = get(currentUser, 'attributes.profile.publicData.favoriteListings', []);

  if (favoriteListingIds.length === 0) {
    return dispatch(fetchFavoriteListingsSuccess([]));
  }

  const fetcher = id => {
    return sdk.listings
      .show({
        id,
        include: ['images', 'author'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'imageVariant.landscape-crop': sdkUtil.objectQueryString({
          w: 320,
          h: parseInt(320 * 0.666667, 10),
          fit: 'crop',
        }),
        'imageVariant.landscape-crop2x': sdkUtil.objectQueryString({
          w: 640,
          h: parseInt(640 * 0.666667, 10),
          fit: 'crop',
        }),
        per_page: 20,
      })
      .catch(err => {
        // Ignore the listings that no longer exist/resolve
        if (err.status === 404) {
          return;
        }

        dispatch(fetchFavoriteListingsError(storableError(err)));

        throw err;
      });
  };

  const promises = favoriteListingIds.map(id => fetcher(id));

  return Promise.all(promises).then(results => {
    const listings = results.reduce((acc, listing) => {
      if (!listing) {
        return acc;
      }

      const denormalised = denormalisedResponseEntities(listing).pop();

      acc.push(denormalised);

      return acc;
    }, []);

    dispatch(fetchFavoriteListingsSuccess(listings));
  });
};

export const updateFavoritePackages = favoritePackages => (dispatch, getState, sdk) => {
  dispatch(updateFavoritePackagesRequest());

  return sdk.currentUser
    .updateProfile({ publicData: { favoritePackages } }, {})
    .then(() => dispatch(updateFavoritePackagesSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(() => updateFavoritePackagesError());
};

export const toggleFavoritePackages = (pkg, listing) => (dispatch, getState) => {
  const {
    user: { currentUser },
  } = getState();

  const favoritePackages = get(currentUser, 'attributes.profile.publicData.favoritePackages', []);
  const pair = `${listing.id.uuid}:${pkg.id}`;
  const index = favoritePackages.indexOf(pair);

  if (index > -1) {
    favoritePackages.splice(index, 1);
    dispatch(packageFavored(pkg, listing));
  } else {
    favoritePackages.push(pair);
    dispatch(packageFavored(pkg, listing));
  }

  return dispatch(updateFavoritePackages(favoritePackages));
};

export const fetchFavoritePackages = () => (dispatch, getState, sdk) => {
  dispatch(fetchFavoritePackagesRequest());

  const {
    user: { currentUser },
  } = getState();

  const favoritePackages = get(currentUser, 'attributes.profile.publicData.favoritePackages', []);

  if (favoritePackages.length === 0) {
    return dispatch(fetchFavoritePackagesSuccess([]));
  }

  // group packages with listings
  const groupedPackages = favoritePackages.reduce((acc, pair) => {
    const [listingId, packageId] = pair.split(':');

    if (acc[listingId]) {
      acc[listingId].push(packageId);
    } else {
      acc[listingId] = [packageId];
    }

    return acc;
  }, {});

  const fetcher = (listingId, packageIds) => {
    return sdk.listings
      .show({ id: listingId })
      .then(listing => {
        const denormalized = denormalisedResponseEntities(listing).pop();
        return get(denormalized, 'attribute.publicData.packages', [])
          .filter(p => packageIds.includes(p.id))
          .map(p => ({ ...p, listingId })); // tag packages w/ associated listing id
      })
      .catch(err => {
        if (err.status === 404) {
          return;
        }

        dispatch(fetchFavoritePackagesError(storableError(err)));

        throw err;
      });
  };

  const promises = Object.entries(groupedPackages).map(entries => fetcher(...entries));

  return Promise.all(promises).then(packages => dispatch(fetchFavoritePackagesSuccess(packages)));
};

export const updateCurrentUserPromoCodes = (transactionId, promoCode) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    user: { currentUser },
  } = getState();

  const promoCodes = get(currentUser, 'attributes.profile.protectedData.promoCodes', []);
  promoCodes.push(promoCode);

  dispatch(updatePromoCodesRequest());

  return sdk.currentUser
    .updateProfile({ protectedData: { promoCodes } }, {})
    .then(() => dispatch(updatePromoCodesSuccess(transactionId, promoCode)))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(error => updatePromoCodesError(error));
};

export const updateWaitingList = (packageId, listingId) => (dispatch, getState, sdk) => {
  const {
    user: { currentUser },
  } = getState();
  const waitingList = get(currentUser, 'attributes.profile.publicData.waitingList', []);

  // Check if the package and listing are already in the waiting list
  const isAlreadyInWaitingList = waitingList.some(
    item => item.packageId === packageId && item.listingId === listingId
  );

  if (!isAlreadyInWaitingList) {
    waitingList.push({
      packageId,
      listingId,
      createdAt: new Date().toISOString(),
    });

    dispatch(updateWaitingListRequest());

    return sdk.currentUser
      .updateProfile({ publicData: { waitingList } }, {})
      .then(() => dispatch(updateWaitingListSuccess(packageId)))
      .then(() => dispatch(fetchCurrentUser()))
      .catch(error => updateWaitingListError(error));
  }

  return Promise.resolve();
};

export const fetchFollowedSubjects = userId => async dispatch => {
  dispatch(fetchFollowedSubjectsRequest());
  const token = getUserToken();
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FollowedSubjects($userId: String) {
          NotificationPreferences(input: { userId: $userId }) {
              id
              notifyBy {
                email
                sms
              }
              subjectId
              subjectType
          }
        }
      `,
        variables: { userId },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    dispatch(fetchFollowedSubjectsSuccess(response.data?.data?.NotificationPreferences));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};

export const followSubject = (userId, subjectId, notifyBy) => async dispatch => {
  dispatch(followSubjectRequest());
  const token = getUserToken();
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        mutation CreateNotificationPreferences(
          $subjectId: String!, 
          $userId: String!,
          $notifyBy: NotificationsEnabledInput!
        ) {
          CreateNotificationPreferences(
            input: {
              subjectId: $subjectId, 
              userId: $userId, 
              subjectType: LISTING,
              notifyBy: $notifyBy
            }) {
            id
          }
        }        
      `,
        variables: {
          userId,
          subjectId,
          notifyBy,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    dispatch(followSubjectSuccess(response.data?.data?.CreateNotificationPreferences));
    dispatch(fetchFollowedSubjects(userId));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};

export const unfollowSubject = (id, userId) => async dispatch => {
  dispatch(unfollowSubjectRequest());
  const token = getUserToken();
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
              mutation ($id: String!) {
                  DeleteNotificationPreferences(input: { id: $id })
              }
              `,
        variables: {
          id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    dispatch(unfollowSubjectSuccess(response.data?.data?.DeleteNotificationPreferences));
    dispatch(fetchFollowedSubjects(userId));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};

export const updateFollowedSubject = (id, notifyBy) => async dispatch => {
  dispatch(updateFollowedSubjectRequest());
  const token = getUserToken();
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        mutation ModifyNotificationPreferences(
          $id: String!
          $notifyBy: NotificationsEnabledInput!
        ) {
          ModifyNotificationPreferences(input: { id: $id, notifyBy: $notifyBy })
        }
      `,
        variables: {
          id,
          notifyBy,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    dispatch(updateFollowedSubjectSuccess(response.data?.data?.UpdateFollowedSubjects));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};
