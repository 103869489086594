import React from 'react';
import { node, func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { EditListingArrivalForm } from '../../forms';
import { timeOptions } from '../../marketplace-custom-config';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const arrivalInstructions = get(currentListing, 'attributes.publicData.arrivalInstructions', {});
  const publicData = get(currentListing, 'attributes.publicData', {});
  const imageCaptions = get(publicData, 'imageCaptions', {});
  const waypoints = get(publicData, 'waypoints', {});

  return {
    instructions: get(arrivalInstructions, 'instructions', null),
    checkInTime: get(arrivalInstructions, 'checkInTime', timeOptions[0].key),
    checkOutTime: get(arrivalInstructions, 'checkOutTime', timeOptions[0].key),
    hasCellService: get(arrivalInstructions, 'hasCellService', false),
    cellService: get(arrivalInstructions, 'cellService', []),
    cellServiceInfo: get(arrivalInstructions, 'cellServiceInfo', null),
    tipsTricks: get(arrivalInstructions, 'tipsTricks', null),
    imageCaptions,
    images: get(arrivalInstructions, 'images', []),
    milesToInterstate: get(arrivalInstructions, 'milesToInterstate', 'N/A'),
    milesToHighway: get(arrivalInstructions, 'milesToHighway', 'N/A'),
    milesToAirport: get(arrivalInstructions, 'milesToAirport', 'N/A'),
    milesToAirportInfo: get(arrivalInstructions, 'milesToAirportInfo', null),
    highVehicleRequired: get(arrivalInstructions, 'highVehicleRequired', false),
    highVehicleRequiredInfo: get(arrivalInstructions, 'highVehicleRequiredInfo', null),
    waypoints,
  };
};

const getSubmissionValues = (listing, formValues) => {
  const imagesIds = get(formValues, 'images', []);
  const imageCaptions = get(formValues, 'imageCaptions', {});
  const listingImages = get(listing, 'images', []);

  const arrivalImages = imagesIds
    .map(imageId => {
      return listingImages.find(listingImage => listingImage.id.uuid === imageId);
    })
    .filter(Boolean);

  const waypointImagesPresentationFlat = arrivalImages
    .map(image => {
      const squareImage = get(image, 'attributes.variants.square-small.url', null);
      const previewImage = get(image, 'attributes.variants.scaled-xlarge.url', null);

      if (!squareImage || !previewImage) {
        return null;
      }

      return {
        square: squareImage,
        preview: previewImage,
      };
    })
    .filter(Boolean);

  const waypointImagesPresentation = [];
  while (waypointImagesPresentationFlat.length)
    waypointImagesPresentation.push(waypointImagesPresentationFlat.splice(0, 2));

  return {
    publicData: {
      arrivalInstructions: {
        instructions: formValues.instructions,
        tipsTricks: formValues.tipsTricks,
        checkInTime: formValues.checkInTime,
        checkOutTime: formValues.checkOutTime,
        hasCellService: formValues.hasCellService,
        cellService: formValues.hasCellService ? formValues.cellService : [],
        cellServiceInfo: formValues.hasCellService ? formValues.cellServiceInfo : null,
        milesToInterstate: formValues.milesToInterstate,
        milesToHighway: formValues.milesToHighway,
        milesToAirport: formValues.milesToAirport,
        milesToAirportInfo:
          formValues.milesToAirport !== 'N/A' ? formValues.milesToAirportInfo : null,
        highVehicleRequired: formValues.highVehicleRequired,
        highVehicleRequiredInfo: formValues.highVehicleRequired
          ? formValues.highVehicleRequiredInfo
          : null,
        images: imagesIds,
      },
      imageCaptions,
      waypoints: formValues.waypoints,
      waypointImagesPresentation,
    },
  };
};

const EditListingArrivalPanel = ({
  rootClassName,
  className,
  listing,
  onSubmit,
  onChange,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  errors,
  backLink,
  skipLink,
}) => {
  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingArrivalPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingArrivalPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingArrivalForm
        initialValues={getInitialValues(listing)}
        onSubmit={values => {
          onSubmit(getSubmissionValues(listing, values));
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listing={listing}
        backLink={backLink}
        skipLink={skipLink}
      />
    </EditListingPanel>
  );
};

EditListingArrivalPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
  skipLink: node,
};

EditListingArrivalPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
  skipLink: null,
};

export default EditListingArrivalPanel;
