/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { bool, func, node, number, object, string, shape } from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { OutsideClickHandler, Modal, PrimaryButton, InlineTextButton, FilterButton } from '..';
import { searchFiltersModalBreakpoint } from '../../marketplace-custom-config';
import { FilterForm } from '../../forms';
import { withViewport } from '../../util/contextHelpers';
import css from './FilterPopup.css';

const KEY_CODE_ESCAPE = 27;

const FilterPopupDropdown = ({
  rootClassName,
  className,
  popupClassName,
  id,
  label,
  icon,
  labelmaxwidth,
  isSelected,
  children,
  initialValues,
  keepDirtyOnReinitialize,
  contentPlacementOffset,
  onSubmit,
  urlParam,
  onCancel,
  onClear,
  isOpen,
  setIsOpen,
}) => {
  const filterRef = useRef(null);
  const filterContentRef = useRef(null);

  const handleSubmit = values => {
    setIsOpen(false);
    onSubmit(urlParam, values);
  };

  const handleClear = () => {
    setIsOpen(false);

    if (onClear) {
      onClear();
    }

    onSubmit(urlParam, null);
  };

  const handleCancel = () => {
    setIsOpen(false);

    if (onCancel) {
      onCancel();
    }

    onSubmit(urlParam, initialValues);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const toggleOpen = enforcedState => {
    if (enforcedState) {
      setIsOpen(enforcedState);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleKeyDown = e => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      toggleOpen(false);
    }
  };

  const positionStyleForContent = () => {
    const filter = filterRef.current;
    const filterContent = filterContentRef.current;

    if (filter && filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - filter.getBoundingClientRect().right;
      const labelWidth = filter.offsetWidth;
      const contentWidth = filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  };

  const classes = classNames(rootClassName || css.root, className);
  const popupClasses = classNames(css.popup, { [css.isOpen]: isOpen });
  const popupSizeClasses = popupClassName || css.popupSize;
  const contentStyle = positionStyleForContent();

  return (
    <OutsideClickHandler onOutsideClick={handleBlur}>
      <div className={classes} onKeyDown={handleKeyDown} ref={filterRef}>
        <FilterButton
          onClick={() => toggleOpen()}
          labelmaxwidth={labelmaxwidth}
          isSelected={isSelected}
          icon={icon}
        >
          {label}
        </FilterButton>

        <div id={id} className={popupClasses} ref={filterContentRef} style={contentStyle}>
          {isOpen ? (
            <FilterForm
              id={`${id}.form`}
              paddingClasses={popupSizeClasses}
              showAsPopup
              contentPlacementOffset={contentPlacementOffset}
              initialValues={initialValues}
              keepDirtyOnReinitialize={keepDirtyOnReinitialize}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onClear={handleClear}
            >
              {children}
            </FilterForm>
          ) : null}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

const FilterPopupModal = ({
  rootClassName,
  className,
  id,
  label,
  icon,
  titles,
  labelmaxwidth,
  isSelected,
  children,
  initialValues,
  onSubmit,
  urlParam,
  onClear,
  isOpen,
  setIsOpen,
  onManageDisableScrolling,
  saveFilters,
  resetToSavedFilters,
  resultsCount,
  searchInProgress,
  intl,
}) => {
  const openModal = () => {
    saveFilters();
    setIsOpen(true);
  };

  const closeModal = () => {
    resetToSavedFilters();
    setIsOpen(false);
  };

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleClear = () => {
    onClear();
    setIsOpen(false);
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <FilterButton
        onClick={() => openModal()}
        labelmaxwidth={labelmaxwidth}
        isSelected={isSelected}
        icon={icon}
      >
        {label}
      </FilterButton>

      {isOpen ? (
        <Modal
          id="FilterPopupModal.filters"
          contentClassName={css.modalContent}
          isOpen
          onClose={closeModal}
          onManageDisableScrolling={onManageDisableScrolling}
          lightCloseButton
        >
          {titles}

          <FilterForm
            id={`${id}.form`}
            initialValues={initialValues}
            keepDirtyOnReinitialize={false}
            liveEdit
            onChange={values => onSubmit(urlParam, values)}
          >
            {children}
          </FilterForm>

          <PrimaryButton
            onClick={handleOk}
            className={css.showResultsButton}
            inProgress={searchInProgress}
            isFullWidth
            disabled={!resultsCount}
          >
            {resultsCount > 0
              ? intl.formatMessage(
                  { id: 'FilterPopupModal.showResultsText' },
                  { count: resultsCount }
                )
              : intl.formatMessage({ id: 'FilterPopupModal.noResultsText' })}
          </PrimaryButton>

          <InlineTextButton onClick={handleClear} className={css.clearButton} isFullWidth>
            {intl.formatMessage({ id: 'FilterPopupModal.clearFilter' })}
          </InlineTextButton>
        </Modal>
      ) : null}
    </div>
  );
};

const FilterPopup = ({
  rootClassName,
  className,
  popupClassName,
  id,
  label,
  icon,
  titles,
  labelmaxwidth,
  isSelected,
  children,
  initialValues,
  keepDirtyOnReinitialize,
  contentPlacementOffset,
  onSubmit,
  urlParam,
  onCancel,
  onClear,
  onManageDisableScrolling,
  saveFilters,
  resetToSavedFilters,
  resultsCount,
  searchInProgress,
  viewport,
  intl,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Decide which to show based on viewport
  return viewport.width < searchFiltersModalBreakpoint ? (
    <FilterPopupModal
      rootClassName={rootClassName}
      className={className}
      id={id}
      label={label}
      icon={icon}
      titles={titles}
      labelmaxwidth={labelmaxwidth}
      isSelected={isSelected}
      initialValues={initialValues}
      onSubmit={onSubmit}
      urlParam={urlParam}
      onClear={onClear}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      saveFilters={saveFilters}
      resetToSavedFilters={resetToSavedFilters}
      resultsCount={resultsCount}
      searchInProgress={searchInProgress}
      intl={intl}
    >
      {children}
    </FilterPopupModal>
  ) : (
    <FilterPopupDropdown
      rootClassName={rootClassName}
      className={className}
      popupClassName={popupClassName}
      id={id}
      label={label}
      icon={icon}
      labelmaxwidth={labelmaxwidth}
      isSelected={isSelected}
      initialValues={initialValues}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={onSubmit}
      urlParam={urlParam}
      onCancel={onCancel}
      onClear={onClear}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {children}
    </FilterPopupDropdown>
  );
};

FilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  popupClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  contentPlacementOffset: 0,
  icon: null,
  titles: null,
  labelmaxwidth: null,
  onManageDisableScrolling: null,
  saveFilters: null,
  resetToSavedFilters: null,
};

FilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  popupClassName: string,
  id: string.isRequired,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  contentPlacementOffset: number,
  label: string.isRequired,
  icon: node,
  titles: node,
  labelmaxwidth: number,
  isSelected: bool.isRequired,
  children: node.isRequired,
  onManageDisableScrolling: func,
  saveFilters: func,
  resetToSavedFilters: func,
  resultsCount: number.isRequired,
  searchInProgress: bool.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default withViewport(injectIntl(FilterPopup));
