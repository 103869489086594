/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import Tooltip from '../../components/Tooltip/Tooltip';
import { InlineTextButton } from '../../components/Button/Button';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as ShareDarkIcon } from '../../assets/icons/share-dark.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import css from './ShareButton.css';

const ShareBox = () => {
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopy = () => {
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  const utms = {
    medium: encodeURIComponent('Share'),
    source: encodeURIComponent('Sportsman'),
  };

  const listingUrl = `${window.location.href}?utm_medium=${utms.medium}&utm_source=${utms.source}`;

  const mailToDetails = {
    subject: 'Check out this property on LandTrust',
    body: `I just found this property on LandTrust, what do you think?\n\n${listingUrl}`,
  };

  return (
    <div data-testid="share-box">
      <h3 className={css.header}>
        <FormattedMessage id="ListingPage.shareHeader" />
      </h3>

      <ul>
        <li className={css.listItem}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${listingUrl}`}
            className={css.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={css.linkIcon} />
            <span className={css.linkLabel}>Facebook</span>
          </a>
        </li>

        <li className={css.listItem}>
          <a
            href={`mailto:?subject=${encodeURIComponent(
              mailToDetails.subject
            )}&body=${encodeURIComponent(mailToDetails.body)}`}
            className={css.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EmailIcon className={css.linkIcon} />
            <span className={css.linkLabel}>
              <FormattedMessage id="ListingPage.shareBoxEmailLabel" />
            </span>
          </a>
        </li>

        <li className={css.listItem}>
          <CopyToClipboard onCopy={() => handleCopy()} text={listingUrl}>
            <a
              href="#"
              className={css.link}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <LinkIcon className={css.linkIcon} />
              <span className={css.linkLabel}>
                <span className={hasCopied ? css.copiedLink : css.copyLink}>
                  <FormattedMessage id="ListingPage.shareBoxCopiedLabel" />
                </span>

                <span className={hasCopied ? css.copyLink : css.copiedLink}>
                  <FormattedMessage id="ListingPage.shareBoxCopyLabel" />
                </span>
              </span>
            </a>
          </CopyToClipboard>
        </li>
      </ul>
    </div>
  );
};

const ShareButton = ({ className, buttonClassName, shareDark }) => {
  const classes = classNames(css.root, className);

  return (
    <div>
      <Tooltip placement="bottom" trigger="click" tooltip={<ShareBox />} className={classes}>
        <InlineTextButton className={buttonClassName} data-testid="share-button">
          <span className={css.buttonBody}>
            {shareDark ? (
              <ShareDarkIcon className={css.icon} />
            ) : (
              <ShareIcon className={css.icon} />
            )}

            <span className={css.buttonLabel}>
              <FormattedMessage id="ListingPage.shareButton" />
            </span>
          </span>
        </InlineTextButton>
      </Tooltip>
    </div>
  );
};

ShareButton.defaultProps = {
  className: null,
  buttonClassName: null,
};

ShareButton.propTypes = {
  className: string,
  buttonClassName: string,
};

export default ShareButton;
