/* eslint-disable no-unused-vars */
import React from 'react';
import { string, oneOf, any, node } from 'prop-types';
import { Field } from 'react-final-form';

const FieldCondition = ({ when, operator, is, children }) => {
  // eslint-disable-next-line no-shadow
  const assert = (value, operator, is) => {
    switch (operator) {
      case 'gt':
        return value > is;
      case 'lt':
        return value < is;
      case 'ne':
        return value !== is;
      default:
        return value === is;
    }
  };

  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value }, ...rest }) => {
        return assert(value, operator, is) ? children : null;
      }}
    </Field>
  );
};

FieldCondition.propTypes = {
  when: string.isRequired,
  is: any.isRequired,
  operator: oneOf(['eq', 'gt', 'lt', 'ne']),
  children: node.isRequired,
};

FieldCondition.defaultProps = {
  operator: 'eq',
};

export default FieldCondition;
