import React from 'react';
import { FormattedMessage } from 'react-intl';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionOfferExpire = ({ transition, buttons }) => {
  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={<FormattedMessage id="BookingStatus.ownTransitionExpire" />}
      buttons={buttons}
    >
      <FormattedMessage id="BookingStatus.ownTransitionExpireSubtext" />
    </InboxTransitionBase>
  );
};

export default InboxTransitionOfferExpire;
