import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldCondition,
  EditListingActions,
  EditListingError,
  FieldToggle,
} from '../../components';

const EditListingFishMotFormComponent = ({ intl, backLink, listingCategories, ...props }) => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          disabled,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = fieldRenderProps;
        const classes = classNames(rootClassName, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishMotForm.step1Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="allowsFly" />
              </EditListingFieldWrapper>

              <FieldCondition when="allowsFly" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="flyInfo"
                    name="flyInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishMotForm.step2Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="allowsLure" />
              </EditListingFieldWrapper>

              <FieldCondition when="allowsLure" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="lureInfo"
                    name="lureInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishMotForm.step3Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="allowsLiveBait" />
              </EditListingFieldWrapper>

              <FieldCondition when="allowsLiveBait" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="liveBaitInfo"
                    name="liveBaitInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishMotForm.step4Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="allowsBow" />
              </EditListingFieldWrapper>

              <FieldCondition when="allowsBow" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="bowInfo"
                    name="bowInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingFishMotFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFishMotFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingFishMotFormComponent);
