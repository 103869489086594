import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import { formatTimeAgo } from '../../../util/dates';
import { createSlug } from '../../../util/urlHelpers';
import { NamedLink } from '../../../components';
import Avatar from '../../../components/Avatar/Avatar';
import css from './ListingUpdate.css';

const SectionReviewsUpdate = props => {
  const { listing, activity, renderBellIcon, reviews } = props;
  const host = listing.author.attributes.profile.displayName;
  const { title } = listing.attributes;
  const slug = createSlug(title);

  const review = useMemo(() => reviews.find(r => r.id.uuid === activity.review.id), [
    reviews,
    activity.review.id,
  ]);

  return (
    <div key={activity.id} className={css.content}>
      <div>
        <div className={css.flexContainer}>
          <div className={css.avatarContainer}>
            <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
              <>
                <Avatar
                  user={listing.author}
                  disableProfileLink
                  className={css.customAvatarSize}
                  listing={listing}
                  useListingImage
                />
                {renderBellIcon}
              </>
            </NamedLink>
          </div>
          <div className={css.propertyNameContainer}>
            <div className={css.propertyName}>
              <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                <FormattedMessage
                  id="ListingPage.activityFeedPropertyName"
                  values={{
                    propertyName: listing.attributes.title,
                  }}
                />
              </NamedLink>
            </div>
            <div className={css.dateWrapper}>
              <span className={css.dateContainer}>
                <p className={css.landownerContainer}>
                  <FormattedMessage
                    id="ListingPage.activityFeedReview"
                    values={{
                      host,
                      reviewRating: review.attributes.rating,
                    }}
                  />
                  {' • '}
                  {formatTimeAgo(review?.attributes?.createdAt)}
                </p>
              </span>
            </div>
          </div>
        </div>
        <div className={css.postWrapper}>
          <div className={css.descriptionStyles}>{review.attributes.content}</div>
        </div>
      </div>
      <hr className={css.sectionDivider} />
    </div>
  );
};

SectionReviewsUpdate.propTypes = {
  listing: propTypes.object.isRequired,
};

export default SectionReviewsUpdate;
