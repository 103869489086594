import React from 'react';
import { node } from 'prop-types';
import css from './EditListingSectionHeading.css';

const EditListingSectionHeading = ({ children, description }) => {
  return (
    <>
      <h2 className={css.root}>{children}</h2>

      {description && <p className={css.description}>{description}</p>}
    </>
  );
};

EditListingSectionHeading.defaultProps = {
  description: null,
};

EditListingSectionHeading.propTypes = {
  description: node,
};

export default EditListingSectionHeading;
