/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData, setInitialValues } from './LandingPage.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionBySpecies,
  SectionHero,
  SectionFeaturedListings,
  SectionListYourProperty,
  SectionFeaturedReviews,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionForLandowners,
} from '../../components';
import { TopbarContainer } from '..';

import facebookImage from '../../assets/images/deep-link/link-logo-row.jpg';
import css from './LandingPage.css';
import SectionHowItWorks from './SectionHowItWorks';
import { Container } from '../../components/Layout';
import SectionPopularStates from '../../components/SectionPopularStates/SectionPopularStates';
import { fetchFavoriteListings } from '../../ducks/user.duck';
import SectionFavoriteListings from '../../components/SectionFavoriteListings/SectionFavoriteListings';
import LandownerBanner from '../../components/LandownerBanner/LandownerBanner';

export const LandingPageComponent = ({
  history,
  intl,
  location,
  scrollingDisabled,
  favoriteListings,
  featuredListings,
  reviewedListings,
  currentUser,
  onFetchFavoriteListings,
}) => {
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const [landownerBannerVisible, setLandownerBannerVisible] = useState(true);

  useEffect(() => {
    if (currentUser) {
      onFetchFavoriteListings();
    }
  }, [currentUser]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer searchEnabled />
          {landownerBannerVisible && (
            <LandownerBanner onClose={() => setLandownerBannerVisible(false)} />
          )}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero history={history} location={location} />

          <Container>
            {currentUser && favoriteListings && favoriteListings.length > 0 && (
              <div className={css.section} id="favListings">
                <SectionFavoriteListings listings={favoriteListings} user={currentUser} />
              </div>
            )}
            <div className={css.section} id="popListings">
              <SectionFeaturedListings listings={featuredListings} />
            </div>
            <div className={css.section}>
              <SectionForLandowners />
            </div>
            <div className={classNames(css.section, css.sectionFullWidth)}>
              <SectionBySpecies />
            </div>
            <div className={css.section}>
              <SectionFeaturedReviews reviewedListings={reviewedListings} gold />
            </div>
          </Container>

          <div className={classNames(css.section, css.sectionFullWidth, css.howItWorksSection)}>
            <SectionHowItWorks />
          </div>

          <Container>
            <div className={classNames(css.section)}>
              <SectionPopularStates />
            </div>

            <div className={classNames(css.section, css.listYourPropertySection)}>
              <SectionListYourProperty />
            </div>
          </Container>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, array } = PropTypes;

LandingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,

  scrollingDisabled: bool.isRequired,
  featuredListings: array.isRequired,
  reviewedListings: array.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

LandingPageComponent.defaultProps = {
  currentUser: null,
};

const mapStateToProps = state => {
  const { currentUser, favoriteListings } = state.user;
  const { listings, reviewedListings } = state.LandingPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    featuredListings: listings,
    reviewedListings,
    favoriteListings,
  };
};

const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line no-shadow
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchFavoriteListings: () => dispatch(fetchFavoriteListings()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

LandingPage.setInitialValues = initialValues => setInitialValues(initialValues);
LandingPage.loadData = loadData;

export default LandingPage;
