/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { FieldTextInput, ImageFromFile, ResponsiveImage, IconClose, IconSpinner } from '..';
import { ReactComponent as BurgerIcon } from '../../assets/icons/burger.svg';
import { flatTags } from '../../util/photoLibrary';
import css from './EditListingPhotoPreviews.css';

const DragHandle = sortableHandle(() => (
  <button type="button">
    <BurgerIcon className={css.sortHandle} />
  </button>
));

const SortableContainer = sortableContainer(({ className, children }) => (
  <div className={className}>{children}</div>
));

const PhotoPreview = ({
  image,
  file,
  error,
  savedImageAltText,
  savedImageCaptionPlaceholderText,
  onRemoveImage,
  imageTags,
}) => {
  const handleRemoveClick = e => {
    e.stopPropagation();

    // Handle saved images and recently uploaded images by passing both ids
    onRemoveImage({ tmpId: image.tmpId, id: image.id });
  };

  const hasApiId = !!(image.id && image.id.uuid);
  const hasError = !!error;
  const isInProgress = !hasApiId && !hasError;
  const canDelete = hasError || hasApiId;

  const photoPreviewImageClassname = classNames(css.photoPreviewImage, {
    [css.photoPreviewImageFade]: hasError || isInProgress,
  });

  return (
    // Use this element to translate when dragging
    <div className={css.photoPreviewContainer}>
      {/* Use this element to scale when dragging */}
      <div className={css.photoPreviewContainerInner}>
        <div className={css.photoPreviewImageContainer}>
          {/* If the image has a `file` use it for the preview even if the image has finished uploading.
            This is because we don't ever hydrate the full image after it is uploaded so it's easier
            to just keep using the preview from `file` */}
          {file ? (
            <ImageFromFile
              id={image.tmpId}
              rootClassName={photoPreviewImageClassname}
              file={file}
              justImage
            />
          ) : (
            <ResponsiveImage
              rootClassName={photoPreviewImageClassname}
              image={image}
              alt={savedImageAltText}
              variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
            />
          )}

          {isInProgress && (
            <div className={css.photoPreviewSpinner}>
              <IconSpinner />
            </div>
          )}

          {canDelete && (
            <button
              type="button"
              onClick={handleRemoveClick}
              className={css.photoPreviewDeleteImage}
            >
              <IconClose className={css.photoPreviewDeleteImageIcon} />
            </button>
          )}
        </div>

        <div
          className={classNames(css.photoPreviewCaptionContainer, {
            [css.photoPreviewCaptionContainerWithTags]: imageTags.length,
          })}
        >
          <FieldTextInput
            id={`imageCaptions.${image.tmpId || image.id.uuid}`}
            name={`imageCaptions.${image.tmpId || image.id.uuid}`}
            placeholder={savedImageCaptionPlaceholderText}
            type="textarea"
            className={classNames(css.photoPreviewCaptionInputWrapper, {
              [css.photoPreviewCaptionInputWrapperWithTags]: imageTags.length,
            })}
            inputClassName={css.photoPreviewCaptionInput}
          />

          {imageTags.length > 0 && (
            <div className={css.photoPreviewTagsContainer}>
              {imageTags.map((tag, index) => (
                <div className={css.photoPreviewTagsItem} key={`${image.id.uuid}-${index}`}>
                  <span>{flatTags[tag]}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div
          className={classNames(css.sortHandleContainer, {
            [css.sortHandleContainerWithTags]: imageTags.length,
          })}
        >
          <DragHandle />
        </div>
      </div>

      {error && <p className={css.error}>{error}</p>}
    </div>
  );
};

const SortableItem = sortableElement(PhotoPreview);

PhotoPreview.defaultProps = { file: null, error: null };

const { array, func, string, object } = PropTypes;

PhotoPreview.propTypes = {
  image: object.isRequired,
  file: object,
  error: string,
  savedImageAltText: string.isRequired,
  savedImageCaptionPlaceholderText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const EditListingPhotoPreviews = ({
  className,
  images,
  files,
  errors,
  savedImageAltText,
  savedImageCaptionPlaceholderText,
  onRemoveImage,
  onUpdateImageOrder,
  tags,
}) => {
  const [isSorting, setIsSorting] = useState(false);
  const classes = classNames(
    css.root,
    {
      [css.rootIsSorting]: isSorting,
    },
    className
  );

  // Depending on the state of the upload the file maybe not be ready yet so
  // we won't try to use it until it is present
  const displayableImages =
    images && images.filter(image => image.id || (image.tmpId && files[image.tmpId]));

  return (
    <SortableContainer
      className={classes}
      onSortStart={() => {
        // Close the iOS software keyboard if it's open because react-sortable-hoc gets the position
        // wrong when the keyboard is open
        document.activeElement.blur();
        setIsSorting(true);
      }}
      onSortEnd={({ oldIndex, newIndex }) => {
        setIsSorting(false);

        onUpdateImageOrder({ oldIndex, newIndex });
      }}
      lockAxis="y"
      helperClass={css.photoPreviewContainerIsSorting}
      useWindowAsScrollContainer
      useDragHandle
    >
      {displayableImages &&
        displayableImages.map((image, index) => (
          <SortableItem
            index={index} // Used by react-sortable-hoc
            key={image.tmpId || image.id.uuid}
            image={image}
            imageTags={tags[(image?.id?.uuid)] || []}
            file={image.tmpId ? files[image.tmpId] : null}
            error={image.tmpId ? errors[image.tmpId] : null}
            savedImageAltText={savedImageAltText}
            savedImageCaptionPlaceholderText={savedImageCaptionPlaceholderText}
            onRemoveImage={onRemoveImage}
          />
        ))}
    </SortableContainer>
  );
};

EditListingPhotoPreviews.defaultProps = { className: null, images: [] };

EditListingPhotoPreviews.propTypes = {
  images: array,
  files: object.isRequired,
  errors: object.isRequired,
  className: string,
  savedImageAltText: string.isRequired,
  savedImageCaptionPlaceholderText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotoPreviews;
