import React from 'react';
import { intlShape } from 'react-intl';
import { get } from 'lodash';
import { getNonCommissionNonReversalLineItems } from '../../util/transaction';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemSpecialOfferMaybe = props => {
  const { transaction, intl } = props;
  // resolve unknown non-reversal line items
  const items = getNonCommissionNonReversalLineItems(transaction);

  return items.length > 0 ? (
    <>
      {items.map((item, i) => {
        const label = get(transaction, 'attributes.protectedData.offerMessage', null);
        const formattedTotal = formatMoney(intl, item.lineTotal);

        if (item.code === 'line-item/units') {
          // Dont render units subtotal as it is duplicate of total
          return null;
        }

        if (item.code === 'line-item/negotiation') {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>{label}</span>
              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        }

        return null;
      })}
    </>
  ) : null;
};

LineItemSpecialOfferMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSpecialOfferMaybe;
