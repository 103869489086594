/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { getListingStartingPrice, getListingPackageMinPrice } from '../../util/listing';
import { ReactComponent as Logo } from '../../assets/icons/logo-small.svg';

import css from './SearchMapPriceLabel.css';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const { listing, isActive, mapComponentRefreshToken } = this.props;

    const currentListing = ensureListing(listing);

    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = isActive === nextProps.isActive;

    const hasSameRefreshToken = mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      onListingClicked,
      isActive,
      hideListingPrice,
      activities,
      species,
    } = this.props;
    const currentListing = ensureListing(listing);
    // grab all of the possible prices for access/fish/hunt for a given listing and finds the lowest price to display on the mapbox marker
    const { publicData } = currentListing.attributes;
    const { gameTypes, packages } = publicData;

    const speciesMinPrice = getListingStartingPrice(gameTypes, false, false, species);
    const packagesMinPrice = getListingPackageMinPrice(packages, activities, species);
    const prices = [];

    if (packagesMinPrice) {
      prices.push(packagesMinPrice);
    }

    if (speciesMinPrice) {
      prices.push(speciesMinPrice);
    }

    if (!prices.length) {
      return null;
    }

    const minPrice = Math.min(...prices);
    const formattedMinimum = minPrice ? `$${minPrice}` : null;

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [css.priceLabelActive]: isActive });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>{hideListingPrice ? <Logo /> : formattedMinimum}</div>
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
  hideListingPrice: false,
  activities: [],
  species: [],
};

const { func, string, bool, array } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  hideListingPrice: bool,
  activities: array,
  species: array,
};

export default injectIntl(SearchMapPriceLabel);
