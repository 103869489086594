/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';

import css from './PackageQuantityBadge.css';

export const PACKAGE_QUANTITY_BADGE_VARIANTS = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
};

const PackageQuantityBadge = ({ text, variant, size }) => {
  const rootClassName = classNames(css[`${variant}Badge`], {
    [css.baseBadgeLarge]: size === 'large',
  });

  return (
    <div className={rootClassName}>
      <span>{text}</span>
    </div>
  );
};

export default PackageQuantityBadge;
