import React from 'react';
import { get } from 'lodash';
import { func, object } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { differenceInDays } from 'date-fns';
import { UserDisplayName, NamedLink } from '..';
import BookingStatusInquiry from './BookingStatusInquiry';
import BookingStatusInquiryExpired from './BookingStatusInquiryExpired';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_LATE_BOOKING,
  TRANSITION_REFUND_PERIOD_OVER,
  TRANSITION_BOOKING_START_GETTING_CLOSER,
  TRANSITION_BOOKING_START,
  TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
  TRANSITION_OPERATOR_EARLY_CANCEL,
  TRANSITION_OPERATOR_CANCEL,
  TRANSITION_OPERATOR_LATE_CANCEL,
  TRANSITION_CUSTOMER_CANCEL_WITH_REFUND,
  TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND,
  TRANSITION_CUSTOMER_LATE_CANCEL,
  TRANSITION_PROVIDER_EARLY_CANCEL,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_PROVIDER_LATE_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE_GATED,
  TRANSITION_ENQUIRE_EXPIRE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY,
  TRANSITION_CUSTOMER_ACCEPT_OFFER,
  TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
  TRANSITION_CUSTOMER_DECLINE_OFFER,
  TRANSITION_OFFER_EXPIRE,
  TRANSITION_PROVIDER_CANCEL_OFFER,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_1,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_2,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_3,
  TRANSITION_REQUEST_EXPIRES_SOON,
  TRANSITION_REQUESTED_CHANGES,
  TRANSITION_ACCEPTED_CHANGES,
  TRANSITION_DECLINED_CHANGES,
  TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES,
  transitionIsReviewed,
  txIsProviderCancelledLate,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  theLastTransition,
  TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';
import { formatMoney } from '../../util/currency';

import css from './BookingStatus.css';
import { PrimaryButton } from '../Button/Button';

const Review = props => {
  const { user } = props;
  return (
    <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
      <PrimaryButton className={css.reviewButton}>
        <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
      </PrimaryButton>
    </NamedLink>
  );
};

Review.propTypes = {
  user: object.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer instead of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  onGoToReview,
  authorDisplayName,
  intl
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const isCustomer = txRoleIsCustomer(ownRole);
  const bookNextTrip = (
    <NamedLink name="LandingPage" className={css.reviewButton}>
      <PrimaryButton className={css.reviewButton}>
        <FormattedMessage id="BookingStatus.bookNextTrip" />
      </PrimaryButton>
    </NamedLink>
  );

  const totalPrice = get(transaction, 'attributes.payoutTotal', 0);

  const earnings = totalPrice && formatMoney(intl, totalPrice);
  const daysUntilStart = differenceInDays(transaction.booking.attributes.start, new Date());

  switch (currentTransition) {
    case TRANSITION_PROVIDER_SPECIAL_OFFER:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionSpecialOfferSent"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionSpecialOfferSentSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionSpecialOfferSent"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionSpecialOfferSentSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionSpecialOfferSent"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionSpecialOfferSentSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionSpecialOfferSent"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionSpecialOfferSentSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_ENQUIRE_GATED:
      return (
        <BookingStatusInquiry
          isOwnTransition={isOwnTransition}
          transaction={transaction}
          otherUsersName={otherUsersName}
        />
      );
    case TRANSITION_ENQUIRE_EXPIRE:
      return (
        <BookingStatusInquiryExpired
          transaction={transaction}
          ownRole={ownRole}
          otherUsersName={otherUsersName}
        />
      );
    case TRANSITION_CUSTOMER_ACCEPT_OFFER:
      return isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.ownTransitionSpecialOfferRequiresPayment"
          values={{ listingTitle }}
        />
      ) : (
        <FormattedMessage id="BookingStatus.transitionRequiresPayment" values={{ displayName }} />
      );
    case TRANSITION_SPECIAL_CUSTOMER_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.ownTransitionSpecialOfferPaid"
          values={{ listingTitle }}
        />
      ) : (
        <FormattedMessage id="BookingStatus.transitionSpecialOfferPaid" values={{ displayName }} />
      );
    case TRANSITION_CUSTOMER_DECLINE_OFFER:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionDeclineOffer" values={{ displayName }} />
      ) : (
        <FormattedMessage id="BookingStatus.transitionDeclineOffer" values={{ displayName }} />
      );
    case TRANSITION_OFFER_EXPIRE:
      return (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionOfferExpire"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionExpireSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_PROVIDER_CANCEL_OFFER:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionCanceledOffer" values={{ listingTitle }} />
      ) : (
        <FormattedMessage id="BookingStatus.transitionCanceledOffer" values={{ displayName }} />
      );
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequest"
              values={{ authorDisplayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequestSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionRequest"
              values={{ displayName, listingTitle }}
            />
          </div>
          {earnings && (
            <div className={css.subtext}>
              <FormattedMessage id="BookingStatus.transitionRequestSubtext" values={{ earnings }} />
            </div>
          )}
        </div>
      );
    case TRANSITION_PARTY_MEMBERS_SUBMITTED_1:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequest"
              values={{ authorDisplayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequestSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionRequest"
              values={{ displayName, listingTitle }}
            />
          </div>
          {earnings && (
            <div className={css.subtext}>
              <FormattedMessage id="BookingStatus.transitionRequestSubtext" values={{ earnings }} />
            </div>
          )}
        </div>
      );
    case TRANSITION_REQUEST_EXPIRES_SOON:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequest"
              values={{ authorDisplayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionRequestSubtext"
              values={{ authorDisplayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionRequest"
              values={{ displayName, listingTitle }}
            />
          </div>
          {earnings && (
            <div className={css.subtext}>
              <FormattedMessage id="BookingStatus.transitionRequestSubtext" values={{ earnings }} />
            </div>
          )}
        </div>
      );
    case TRANSITION_ACCEPT:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownTransitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionAcceptSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.transitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionAcceptSubtext" />
          </div>
        </div>
      );
    case TRANSITION_REQUESTED_CHANGES:
    case TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionCustomerRequestedChanges"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionCustomerRequestedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerRequestedChanges"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerRequestedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );

    case TRANSITION_ACCEPTED_CHANGES:
    case TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionAcceptedChanges"
              values={{ displayName, days: daysUntilStart }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionAcceptedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionAcceptedChanges"
              values={{ displayName, days: daysUntilStart }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionAcceptedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );

    case TRANSITION_DECLINED_CHANGES:
    case TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.ownTransitionDeclinedChanges"
              values={{ displayName, days: daysUntilStart }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionDeclinedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionDeclinedChanges"
              values={{ displayName, days: daysUntilStart }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionDeclinedChangesSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );

    case TRANSITION_PARTY_MEMBERS_SUBMITTED_2:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.transitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionAcceptSubtext" />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownTransitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionAcceptSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownTransitionDecline" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionDeclineSubtext" />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.transitionDecline" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionDeclineSubtext" />
          </div>
        </div>
      );
    case TRANSITION_EXPIRE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="BookingStatus.ownTransitionExpire" />
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.transitionExpire" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionExpireSubtext"
              values={{ authorDisplayName, bookNextTrip }}
            />
          </div>
        </div>
      );
    case TRANSITION_LATE_BOOKING:
      return <FormattedMessage id="BookingStatus.transitionLateBooking" />;
    case TRANSITION_PARTY_MEMBERS_SUBMITTED_3:
      return <FormattedMessage id="BookingStatus.transitionBookingStartGettingCloser" />;
    case TRANSITION_REFUND_PERIOD_OVER:
      return isCustomer ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.transitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionAcceptSubtext" />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownTransitionAccept" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownTransitionAcceptSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_BOOKING_START_GETTING_CLOSER:
      return <FormattedMessage id="BookingStatus.transitionBookingStartGettingCloser" />;
    case TRANSITION_BOOKING_START:
      return <FormattedMessage id="BookingStatus.transitionBookingStart" />;
    case TRANSITION_OPERATOR_EARLY_CANCEL:
      return <FormattedMessage id="BookingStatus.transitionCancel" />;
    case TRANSITION_OPERATOR_CANCEL:
    case TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES:
      return <FormattedMessage id="BookingStatus.transitionCancel" />;
    case TRANSITION_OPERATOR_LATE_CANCEL:
      return <FormattedMessage id="BookingStatus.transitionCancel" />;
    case TRANSITION_CUSTOMER_CANCEL_WITH_REFUND:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownCustomerCancelWithRefund" />
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerCancelRefund"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerCancelRefundSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownCustomerCancelWithoutRefund" />
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerCancelWithoutRefund"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerCancelWithoutRefundSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_CUSTOMER_LATE_CANCEL:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownCustomerLateCancel" />
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionCustomerLateCancel"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_PROVIDER_EARLY_CANCEL:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownProviderCancelEarly" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownProviderCancelEarlySubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionProviderCancelEarly"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionProviderCancelEarlySubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_PROVIDER_CANCEL:
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownProviderCancel" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionProviderCancelSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionProviderCancel"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionProviderCancelSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      );
    case TRANSITION_PROVIDER_LATE_CANCEL: {
      const reviewPeriodLate = txIsProviderCancelledLate(transaction);
      const reviewLateLink = reviewPeriodLate ? (
        <PrimaryButton onClick={onGoToReview} className={css.reviewButton}>
          <FormattedMessage id="BookingStatus.leaveAReview" />
        </PrimaryButton>
      ) : null;
      return isOwnTransition ? (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.ownProviderLateCancel" values={{ displayName }} />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.ownProviderLateCancelSubtext"
              values={{ displayName }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionProviderLateCancel"
              values={{ displayName }}
            />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.transitionProviderLateCancelSubtext"
              values={{ reviewLink: reviewLateLink, displayName }}
            />
          </div>
        </div>
      );
    }
    case TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL:
      return isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.transitionReviewByCustomerLateCancel"
          values={{ displayName }}
        />
      ) : (
        <FormattedMessage
          id="BookingStatus.saleReviewByCustomerLateCancel"
          values={{ displayName }}
        />
      );
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL:
      return isOwnTransition ? (
        <FormattedMessage id="BookingStatus.transitionExpireCustomerReviewPeriodLateCancel" />
      ) : (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage id="BookingStatus.saleExpireCustomerReviewPeriodLateCancel" />
          </div>
          <div className={css.subtext}>
            <FormattedMessage
              id="BookingStatus.saleExpireCustomerReviewPeriodLateCancelSubtext"
              values={{ bookNextTrip }}
            />
          </div>
        </div>
      );
    case TRANSITION_COMPLETE: {
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsDelivered(transaction);

      const reviewAsFirstLink = reviewPeriodJustStarted ? (
        <PrimaryButton onClick={onGoToReview} className={css.reviewButton}>
          <FormattedMessage id="BookingStatus.leaveAReview" />
        </PrimaryButton>
      ) : null;

      return (
        <div>
          <div className={css.mainStatus}>
            <FormattedMessage
              id="BookingStatus.transitionComplete"
              values={{ reviewLink: reviewAsFirstLink }}
            />{' '}
          </div>
          <div className={css.subtext}>
            <FormattedMessage id="BookingStatus.transitionCompleteSubtext" />
          </div>
        </div>
      );
    }
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER: {
      if (isOwnTransition) {
        return <FormattedMessage id="BookingStatus.ownTransitionReview" values={{ displayName }} />;
      }
      // show the leave a review link if current user is not the first
      // one to leave a review
      const reviewPeriodIsOver = txIsReviewed(transaction);
      const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
      const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
        <PrimaryButton onClick={onGoToReview} className={css.reviewButton}>
          <FormattedMessage id="BookingStatus.leaveAReviewSecond" />
        </PrimaryButton>
      ) : null;
      return (
        <FormattedMessage
          id="BookingStatus.transitionReview"
          values={{ displayName, reviewLink: reviewAsSecondLink }}
        />
      );
    }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="BookingStatus.ownTransitionReview" values={{ displayName }} />;
      }
      return (
        <FormattedMessage
          id="BookingStatus.transitionReview"
          values={{ displayName, reviewLink: null }}
        />
      );
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
      return <FormattedMessage id="BookingStatus.bookingComplete" />;

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const Transition = props => {
  const {
    transition,
    transaction,
    currentUser,
    intl,
    onGoToReview,
    inquiryGated,
    authorDisplayName,
  } = props;
  const currentTransaction = ensureTransaction(transaction);
  const { customer } = currentTransaction;
  const { provider } = currentTransaction;

  const deletedListing = intl.formatMessage({
    id: 'BookingStatus.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const { lastTransition } = currentTransaction.attributes;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} useOnlyFirstName />
  ) : (
    <UserDisplayName user={provider} intl={intl} useOnlyFirstName />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    onGoToReview,
    authorDisplayName,
    intl
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      reviewComponent = <Review user={currentUser} />;
    } else if (isProviderReview(currentTransition)) {
      reviewComponent = <Review user={currentUser} />;
    }
  }
  const todayString = intl.formatMessage({ id: 'BookingStatus.today' });
  return (
    <div className={css.transition}>
      {inquiryGated ? (
        <div>
          <div className={css.transitionDateRight}>
            {formatDate(intl, todayString, transition.createdAt)}
          </div>
          <div className={css.transitionContentInquiry}>{transitionMessage}</div>
        </div>
      ) : null}

      {!inquiryGated ? (
        <div>
          <div className={css.transitionContent}>{transitionMessage}</div>

          {reviewComponent}
        </div>
      ) : null}
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

export const BookingStatusComponent = props => {
  const {
    transaction,
    currentUser,
    intl,
    onGoToReview,
    stateData,
    viewRequestButton,
    saleButtons,
    specialOfferCustomerButtons,
    inquiryGated,
    inquiryExpired,
    authorDisplayName,
  } = props;
  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onGoToReview={onGoToReview}
          inquiryGated={inquiryGated}
          inquiryExpired={inquiryExpired}
          authorDisplayName={authorDisplayName}
        />
      );
    }
    return <EmptyTransition />;
  };

  const bookingStatusItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return <div className={css.bookingStatusItem}>{transitionComponent(transition)}</div>;
    }
    return null;
  };
  const transactionTransition = theLastTransition(transaction);

  return (
    <div>
      {bookingStatusItem(transactionTransition)}

      {stateData.showCustomerAcceptSpecialOffer ? (
        <div className={css.actionButtons}>{specialOfferCustomerButtons}</div>
      ) : null}
      {stateData.showSaleButtons ? <div className={css.actionButtons}>{saleButtons}</div> : null}
      {stateData.showViewRequestButton ? (
        <div className={css.actionButtons}>{viewRequestButton}</div>
      ) : null}
    </div>
  );
};

BookingStatusComponent.defaultProps = {
  currentUser: null,
  transaction: null,
};

BookingStatusComponent.propTypes = {
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  onGoToReview: func.isRequired,
  stateData: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingStatus = injectIntl(BookingStatusComponent);

export default BookingStatus;
