import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FieldSelect from '../FieldSelect/FieldSelect';
import { timeOptions } from '../../marketplace-custom-config';

import css from './FieldTimeInput.css';

const FieldTimeInput = ({ rootClassName, className, ...props }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <FieldSelect className={classes} {...props} selectClassName={css.select}>
      {timeOptions.map(option => (
        <option key={option.key} value={option.key}>
          {option.label}
        </option>
      ))}
    </FieldSelect>
  );
};

FieldTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

FieldTimeInput.propTypes = {
  rootClassName: string,
  className: string,
};

export default FieldTimeInput;
