import React from 'react';
import { get } from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as InsuranceIcon } from '../../assets/icons/insurance.svg';
import { ReactComponent as PolicyNote } from '../../assets/icons/policy-note.svg';
import { NamedLink } from '..';
import css from './TripInsuranceDetails.css';
import { propTypes } from '../../util/types';

const TripInsuranceDetails = ({ transaction }) => {
  const tripInsurance = get(transaction, 'attributes.metadata.tripInsurance', false);
  const insurancePolicy = get(transaction, 'attributes.metadata.insurance.policyNumber', null);

  return (
    <div className={css.root}>
      <div className={css.titleContainer}>
        <FormattedMessage id="TripInsuranceDetails.headerGuest" />
      </div>
      <div className={css.subtitleContainer}>
        <FormattedMessage
          id={tripInsurance ? 'TripInsuranceDetails.providedBy' : 'TripInsuranceDetails.notInsured'}
        />
      </div>

      {tripInsurance ? (
        <>
          <div className={css.insuranceItemWrapper}>
            <InsuranceIcon className={css.insuranceIcon} />

            <div className={css.insuranceItem}>
              <div className={css.insuranceItemValue}>
                <FormattedMessage id="TripInsuranceDetails.insured" />
              </div>
            </div>
          </div>
          <div className={css.insuranceItemWrapper}>
            <PolicyNote className={css.insuranceIcon} />

            <div className={css.insuranceItem}>
              <div className={css.insuranceItemValue}>
                <FormattedMessage
                  id={
                    insurancePolicy
                      ? 'TripInsuranceDetails.policyNumber'
                      : 'TripInsuranceDetails.awaitingPolicyNumber'
                  }
                  values={{ policyNumber: insurancePolicy }}
                />
              </div>
            </div>
          </div>
          <div className={css.insuranceItemWrapper}>
            <PhoneIcon className={css.insuranceIcon} />

            <div className={css.insuranceItem}>
              <div className={css.insuranceItemValue}>
                <FormattedHTMLMessage id="TripInsuranceDetails.generaliPhone" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={css.declinedWrapper}>
          <FormattedMessage id="TripInsuranceDetails.declinedInsurance" />
          <NamedLink
            className={css.cancellationLink}
            name="TermsOfServicePage"
            to={{ hash: '#cancellation-policy' }}
          >
            <FormattedMessage id="TripInsuranceDetails.cancellationPolicy" />
          </NamedLink>
        </div>
      )}
    </div>
  );
};

TripInsuranceDetails.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default TripInsuranceDetails;
