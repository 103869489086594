/* eslint-disable consistent-return */
import * as props from '../prop-names';

import {
  formatEvent,
  getEventDate,
  getBookingProps,
  getLandownerFee,
  isLandowner,
  isSportsman,
  isLateCancelBySportsman,
  getSportsmanTotal,
  getpartyMembers,
} from '../helpers';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

export const trackTransactionCanceledEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return;
  }

  event.mp_properties = {
    ...getBookingProps(tx),
    [props.FIRST_TRANSACTION_CANCELED_DATE]: getEventDate(),
    [props.LAST_TRANSACTION_CANCELED_DATE]: getEventDate(),
    [props.TRANSACTION_CANCELED_COUNT]: 1,
    [props.LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.TOTAL_LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.SPORTSMAN_REFUND]: getSportsmanTotal(tx),
    [props.TOTAL_SPORTSMAN_REFUND]: getSportsmanTotal(tx),
  };

  return event;
};

export const trackTransactionCanceledByLandownerEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return;
  }

  event.mp_properties = {
    ...getBookingProps(tx),
    [props.FIRST_TRANSACTION_CANCELED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.LAST_TRANSACTION_CANCELED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.TRANSACTION_CANCELED_BY_LANDOWNER_COUNT]: 1,
    [props.LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.TOTAL_LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.SPORTSMAN_REFUND]: getSportsmanTotal(tx),
    [props.TOTAL_SPORTSMAN_REFUND]: getSportsmanTotal(tx),
  };

  return event;
};

export const trackSportsmanCanceledTransactionEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  if (!isSportsman(state)) {
    return;
  }

  event.mp_properties = {
    ...getBookingProps(tx),
    [props.FIRST_TRANSACTION_CANCELED_DATE]: getEventDate(),
    [props.LAST_TRANSACTION_CANCELED_DATE]: getEventDate(),
    [props.TRANSACTION_CANCELED_COUNT]: 1,
    [props.LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.TOTAL_LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.SPORTSMAN_REFUND]: isLateCancelBySportsman(tx) ? 0 : getSportsmanTotal(tx),
    [props.TOTAL_SPORTSMAN_REFUND]: isLateCancelBySportsman(tx) ? 0 : getSportsmanTotal(tx),
  };

  return event;
};

export const trackTransactionCanceledBySportsmanEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  if (!isSportsman(state)) {
    return;
  }

  event.mp_properties = {
    ...getBookingProps(tx),
    [props.LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.TOTAL_LANDOWNER_REFUND]: getLandownerFee(tx),
    [props.SPORTSMAN_REFUND]: isLateCancelBySportsman(tx) ? 0 : getSportsmanTotal(tx),
    [props.TOTAL_SPORTSMAN_REFUND]: isLateCancelBySportsman(tx) ? 0 : getSportsmanTotal(tx),
    [props.FIRST_TRANSACTION_CANCELED_BY_SPORTSMAN_DATE]: getEventDate(),
    [props.LAST_TRANSACTION_CANCELED_BY_SPORTSMAN_DATE]: getEventDate(),
    [props.TRANSACTION_CANCELED_BY_SPORTSMAN_COUNT]: 1,
  };

  return event;
};

export const trackPartyMembersFormSubmitted = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    [props.PARTY_MEMBERS_FORM_SUBMITTED]: getpartyMembers(tx),
    ...getBookingProps(tx),
  };

  return event;
};

export const trackPromoCodeApplied = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    [props.PROMO_CODE]: payload.promoCode,
    ...getBookingProps(tx),
  };

  return event;
};
