import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Modal, TertiaryButton, PackageAvailabilityNotifyButton } from '..';

import css from './PackageAvailabilityModal.css';

const PackageAvailabilityModal = ({
  id,
  isOpen,
  onClose,
  onManageDisableScrolling,
  isSoldOut,
  isOutOfBookingRange,
  packageAvailableDate,
  landownerName,
  onNotify,
  isFetching,
  isOnWaitingList,
  isInquiry,
}) => {
  const renderContent = () => {
    if (isOnWaitingList) {
      return (
        <div className={css.content}>
          <div className={css.title}>
            <FormattedMessage id="PackageAvailabilityModal.isOnWaitingListTitle" />
          </div>

          <div className={css.subtitle}>
            <FormattedMessage
              id="PackageAvailabilityModal.isOnWaitingListSubtitle"
              values={{
                landowner: landownerName,
              }}
            />
          </div>

          <TertiaryButton
            className={css.notifyButton}
            type="button"
            isFullWidth
            onClick={() => {
              onClose();
            }}
          >
            {isInquiry ? (
              <FormattedMessage id="PackageAvailabilityModal.isOnWaitingListReturnToInquiry" />
            ) : (
              <FormattedMessage id="PackageAvailabilityModal.isOnWaitingListReturnToPackage" />
            )}
          </TertiaryButton>
        </div>
      );
    }

    const notifyButton = (
      <PackageAvailabilityNotifyButton onClick={onNotify} isLoading={isFetching} />
    );

    if (isSoldOut) {
      return (
        <div className={css.content}>
          <div className={css.title}>
            <FormattedMessage id="PackageAvailabilityModal.soldOutTitle" />
          </div>

          <div className={css.subtitle}>
            <FormattedMessage
              id="PackageAvailabilityModal.soldOutSubtitle"
              values={{
                landowner: landownerName,
              }}
            />
          </div>

          <div className={css.buttonWrapper}>{notifyButton}</div>

          <div className={css.footer}>
            <FormattedHTMLMessage id="PackageAvailabilityModal.soldOutFooter" />
          </div>
        </div>
      );
    }

    if (isOutOfBookingRange) {
      return (
        <div className={css.content}>
          <div className={css.title}>
            <FormattedMessage
              id="PackageAvailabilityModal.outOfBookingRangeTitle"
              values={{
                date: packageAvailableDate,
              }}
            />
          </div>

          <div className={css.subtitle}>
            <FormattedMessage
              id="PackageAvailabilityModal.outOfBookingRangeSubtitle"
              values={{
                landowner: landownerName,
                date: packageAvailableDate,
              }}
            />
          </div>

          {notifyButton}

          <div className={css.footer}>
            <FormattedHTMLMessage id="PackageAvailabilityModal.outOfBookingRangeFooter" />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      id={id}
      className={css.root}
      containerClassName={css.container}
      contentClassName={css.content}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      {renderContent()}
    </Modal>
  );
};

export default injectIntl(PackageAvailabilityModal);
