/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes, { node } from 'prop-types';
import {
  DayPickerRangeController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';
import { START_DATE, END_DATE, getDaysFromTimeslots } from '../../util/dates';
import config from '../../config';
import Tooltip from '../Tooltip/Tooltip';

import { IconArrowHead } from '..';
import css from './DateRangeController.css';
import { isOutsideRangeFn } from '../FieldDateRangeInput/DateRangeInput.helpers';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  startDateOffset: undefined,
  endDateOffset: undefined,

  // calendar presentation and interaction related props

  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  isRTL: false,
  initialVisibleMonth: null,
  firstDayOfWeek: config.i18n.firstDayOfWeek,
  numberOfMonths: 1,
  daySize: 38,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  minimumNights: config.bookingUnitType === 'line-item/night' ? 1 : 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  // displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
  phrases: {}, // Add overwrites to default phrases used by react-dates

  timeSlots: null,
};

const DateRangeController = ({
  rootClassName,
  className,
  value,
  onChange,
  onReset,
  timeSlots,
  unitType,
  isResponsive,
  calendarInfo,
  showClearDates,
  endDateOffset,
  showPreviousDays,
  ...controllerProps
}) => {
  const [startDate, setStartDate] = useState(
    value && value.startDate ? moment(value.startDate) : null
  );
  const [endDate, setEndDate] = useState(value && value.endDate ? moment(value.endDate) : null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [tooltipText, setTooltipText] = useState(null);
  const [dayTooltip, setDayTooltip] = useState(null);
  const availableDays = getDaysFromTimeslots(timeSlots);

  const onDatesChange = values => {
    const { startDate: startDateFromValues } = values;
    let { endDate: endDateFromValues } = values;

    if (startDate && startDateFromValues && !endDateOffset) {
      if (!moment(startDate.toDate()).isSame(startDateFromValues.toDate())) {
        endDateFromValues = null;
      }
    }

    const start = startDateFromValues ? startDateFromValues.toDate() : null;
    const end = endDateFromValues ? endDateFromValues.toDate() : null;

    setStartDate(startDateFromValues);
    setEndDate(endDateFromValues);

    if (startDateFromValues && endDateFromValues) {
      onChange({ startDate: start, endDate: end });
    }
  };

  const onFocusChange = newFocusedInput => {
    // Force the focusedInput to always be truthy so that dates are always selectable
    setFocusedInput(!newFocusedInput ? START_DATE : newFocusedInput);
  };

  const handleReset = (newStartDate, newEndDate) => {
    if (newStartDate && newEndDate) {
      setStartDate(moment(newStartDate));
      setEndDate(moment(newEndDate));
      setFocusedInput(START_DATE);
    } else {
      setStartDate(null);
      setEndDate(null);
      setFocusedInput(START_DATE);
    }
  };

  const classes = classNames(rootClassName || css.inputRoot, className, {
    [css.inputRootResponsive]: isResponsive,
  });

  const startDateFromForm = value && value.startDate ? moment(value.startDate) : null;
  const endDateFromForm = value && value.endDate ? moment(value.endDate) : null;

  const isSelected = startDate && endDate;

  // Value given by Final Form reflects url params and is valid if both dates are set.
  // If only one date is selected state should be used to get the correct date.
  const startDateUpdated = isSelected ? startDateFromForm : startDate;
  const endDateUpdated = isSelected ? endDateFromForm : endDate;

  const isDayBlocked = day => {
    if (!timeSlots) return false;

    const startOfMonth = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    const formattedDay = day.format('YYYY-MM-DD');

    if (moment(formattedDay).isBefore(startOfMonth)) {
      return true;
    }

    return !availableDays.includes(formattedDay);
  };

  const isOutsideRange = showPreviousDays
    ? () => false
    : isOutsideRangeFn(
        timeSlots,
        startDateUpdated,
        endDateUpdated,
        focusedInput,
        unitType,
        controllerProps?.maximumNights
      );

  const handleDayClick = day => {
    const { minimumNights, maximumNights } = controllerProps;

    if (focusedInput === END_DATE && minimumNights !== maximumNights) {
      const maximumEndDate = startDateUpdated.clone().add(maximumNights + 1, 'days');
      const minimumEndDate = startDateUpdated.clone().add(minimumNights - 1, 'days');

      // Show min day tooltip
      if (isInclusivelyBeforeDay(day, minimumEndDate)) {
        setDayTooltip(day);
        setTooltipText(`${minimumNights + 1}-day minimum`);
      } else if (isInclusivelyAfterDay(day, maximumEndDate)) {
        setDayTooltip(day);
        setTooltipText(`${maximumNights + 1}-day maximum`);
      } else {
        setDayTooltip(null);
        setTooltipText(null);
      }
    }
  };

  const calendarTips = (
    <div className={css.calendarTips}>
      {calendarInfo && calendarInfo}
      {showClearDates && (
        <a
          className={css.clearDatesLink}
          href="#"
          onClick={e => {
            e.preventDefault();
            handleReset();

            if (onReset) {
              onReset();
            }
          }}
        >
          <FormattedMessage id="DateRange.clearDates" />
        </a>
      )}
    </div>
  );

  return (
    <div className={classes}>
      <DayPickerRangeController
        {...controllerProps}
        horizontalMonthPadding={isResponsive ? 0 : controllerProps.horizontalMonthPadding}
        startDate={startDateUpdated}
        endDate={endDateUpdated}
        endDateOffset={endDateOffset}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        renderDayContents={day => {
          return (
            <span
              data-testId={`calendar-day-${day.format('D')}`}
              className="renderedDay"
              onMouseLeave={() => setDayTooltip(null)}
              onClick={() => handleDayClick(day)}
            >
              {moment(day).isSame(dayTooltip) ? (
                <Tooltip
                  placement="top"
                  trigger="none"
                  tooltipShown
                  tooltip={<div>{tooltipText}</div>}
                  hideCloseButton
                  rootClassName={css.tooltipRoot}
                >
                  {day.format('D')}
                </Tooltip>
              ) : (
                day.format('D')
              )}
            </span>
          );
        }}
        renderCalendarInfo={() => calendarTips}
      />
    </div>
  );
};

DateRangeController.defaultProps = {
  rootClassName: null,
  className: null,

  // I wanted to make this the default but react-dates sometimes
  // renders the month with classes that hide everything for a reason
  // that is not documented so for now it's opt-in
  isResponsive: false,
  calendarInfo: null,
  showClearDates: true,
  ...defaultProps,
};

const { string, bool } = PropTypes;

DateRangeController.propTypes = {
  rootClassName: string,
  className: string,
  calendarInfo: node,
  isResponsive: bool.isRequired,
  showClearDates: bool,
};

export default DateRangeController;
