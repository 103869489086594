import * as props from '../prop-names';

import { formatEvent, getEventDate, getDefaultUserProps, getBookingProps } from '../helpers';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertMoneyToNumber } from '../../util/currency';

// Tracked when user starts the booking process
export const trackBookingRequestStartedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [
    { id: payload.transaction.id, type: 'transaction' },
  ]).pop();

  tx.listing.author = tx.provider;

  event.mp_properties = {
    [props.FIRST_BOOKING_STARTED_DATE]: getEventDate(),
    [props.LAST_BOOKING_STARTED_DATE]: getEventDate(),
    [props.BOOKING_STARTED_COUNT]: 1,
    ...getBookingProps(tx),
    ...getDefaultUserProps(state),
  };

  return event;
};

// Event formatted for Booking Request Submitted.
// It is used by both origin and mirrored event trackers.
export const formatBookingRequestSubmittedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [
    { id: payload.transaction.id, type: 'transaction' },
  ]).pop();

  event.mp_properties = {
    ...getBookingProps(tx),
    ...getDefaultUserProps(state),
  };

  return event;
};

// Tracked when new booking request is submitted
export const trackBookingRequestSubmittedEvent = (type, payload, state) => {
  const event = formatBookingRequestSubmittedEvent(type, payload, state);

  event.mp_properties = {
    ...event.mp_properties,
    [props.FIRST_BOOKING_SUBMITTED_DATE]: getEventDate(),
    [props.LAST_BOOKING_SUBMITTED_DATE]: getEventDate(),
    [props.BOOKING_SUBMITTED_COUNT]: 1,
  };

  return event;
};

// Tracked when new booking request is submitted (mirrored)
export const trackBookingRequestReceivedEvent = (type, payload, state) => {
  const event = formatBookingRequestSubmittedEvent(type, payload, state);
  const tx = getMarketplaceEntities(state, [
    { id: payload.transaction.id, type: 'transaction' },
  ]).pop();

  event.mp_properties = {
    ...event.mp_properties,
    [props.USER_ID]: tx.listing.author.id.uuid,
    [props.FIRST_BOOKING_RECEIVED_DATE]: getEventDate(),
    [props.LAST_BOOKING_RECEIVED_DATE]: getEventDate(),
    [props.BOOKING_RECEIVED_COUNT]: 1,
  };

  return event;
};

// Formatter for booking acceptance
export const formatBookingRequestAcceptanceEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...getBookingProps(tx),
  };

  return event;
};

// Tracked when landowner accepts the booking
export const trackBookingRequestAcceptedEvent = (type, payload, state) => {
  const event = formatBookingRequestAcceptanceEvent(type, payload, state);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...event.mp_properties,
    [props.FIRST_BOOKING_REQUEST_ACCEPTED_DATE]: getEventDate(),
    [props.LAST_BOOKING_REQUEST_ACCEPTED_DATE]: getEventDate(),
    [props.BOOKING_REQUESTED_ACCEPTED_COUNT]: 1,
    [props.TOTAL_EARNING]: convertMoneyToNumber(tx.attributes.payoutTotal),
  };

  return event;
};

// Tacked when sportsman receives acceptance (mirror of the above event)
export const trackBookingAcceptedByLandownerEvent = (type, payload, state) => {
  const event = formatBookingRequestAcceptanceEvent(type, payload, state);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...event.mp_properties,
    [props.FIRST_BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.LAST_BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_COUNT]: 1,
    [props.REVENUE]: event.mp_properties[props.TOTAL_PRICE],
    [props.LTV_REVENUE]: event.mp_properties[props.TOTAL_PRICE],
  };

  return event;
};

// Formatter for booking rejections
export const formatBookingRequestRejectionEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...getBookingProps(tx),
  };

  return event;
};

// Tracked when landowner declines the booking
export const trackBookingRequestDeclinedEvent = (type, payload, state) => {
  const event = formatBookingRequestRejectionEvent(type, payload, state);

  event.mp_properties = {
    ...event.mp_properties,
    [props.FIRST_BOOKING_REQUEST_DECLINED_DATE]: getEventDate(),
    [props.LAST_BOOKING_REQUEST_DECLINED_DATE]: getEventDate(),
    [props.BOOKING_REQUEST_DECLINED_COUNT]: 1,
  };

  return event;
};

// Tacked when sportsman receives rejection (mirror of the above event)
export const trackBookingDeclinedByLandownerEvent = (type, payload, state) => {
  const event = formatBookingRequestRejectionEvent(type, payload, state);

  event.mp_properties = {
    ...event.mp_properties,
    [props.FIRST_BOOKING_REQUEST_DECLINED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.LAST_BOOKING_REQUEST_DECLINED_BY_LANDOWNER_DATE]: getEventDate(),
    [props.BOOKING_REQUEST_DECLINED_BY_LANDOWNER_COUNT]: 1,
  };

  return event;
};
