import React from 'react';
import { string, bool, node } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingSection.css';

const EditListingSection = ({
  className,
  rootClassName,
  isFullWidth,
  hasBackground,
  hasTopMargin,
  hasBottomMargin,
  title,
  children,
}) => {
  const classes = classNames(
    rootClassName || css.root,
    {
      [css.rootWithMaxWidth]: !isFullWidth,
      [css.hasBackground]: hasBackground,
      [css.hasTopMargin]: hasTopMargin,
      [css.hasBottomMargin]: hasBottomMargin,
    },
    className
  );

  return (
    <div className={classes}>
      {title && <h4 className={css.title}>{title}</h4>}
      {children}
    </div>
  );
};

EditListingSection.defaultProps = {
  className: null,
  rootClassName: null,
  isFullWidth: false,
  hasBackground: false,
  hasTopMargin: false,
  hasBottomMargin: false,
  title: null,
};

EditListingSection.propTypes = {
  className: string,
  rootClassName: string,
  isFullWidth: bool,
  hasBackground: bool,
  hasTopMargin: bool,
  hasBottomMargin: bool,
  title: node,
};

export default EditListingSection;
