/* eslint-disable react/no-array-index-key */
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import {
  EditListingFieldWrapper,
  EditListingSection,
  FieldRadioButton,
  FieldLabel,
} from '../../components';
import { LODGING_INFO } from '../../util/package';
import css from './EditListingCreatePackageForm.css';

const LodgingAvailability = ({ intl, options }) => {
  return (
    <>
      <div className={css.fieldHeader}>
        <FormattedMessage id="EditListingCreatePackageForm.lodgingSectionHeader" />
      </div>
      <p className={css.subheader}>
        <FormattedHTMLMessage id="EditListingCreatePackageForm.lodgingSectionHelp" />
      </p>

      <EditListingSection
        hasBottomMargin
        hasBackground
        isFullWidth
        rootClassName={css.listingSectionRoot}
      >
        {options.map((option, index) => (
          <EditListingFieldWrapper key={`lodging-price-${index}`}>
            <FieldLabel>
              {option.label}
              {option?.priceLabel ? `| ${option.priceLabel}` : null}
            </FieldLabel>

            <div className={css.radioWrapper}>
              {option?.price && (
                <FieldRadioButton
                  className={css.radioButtonLodging}
                  name={`lodgingPrices[${index}]`}
                  id={`lodgingPricesOptional.${index}`}
                  value={LODGING_INFO.OPTIONAL}
                  label={intl.formatMessage({
                    id: 'EditListingCreatePackageForm.lodgingOptional',
                  })}
                />
              )}

              <FieldRadioButton
                className={css.radioButtonLodging}
                name={`lodgingPrices[${index}]`}
                id={`lodgingPricesRequired.${index}`}
                value={LODGING_INFO.REQUIRED}
                label={intl.formatMessage({
                  id: 'EditListingCreatePackageForm.lodgingRequired',
                })}
              />

              <FieldRadioButton
                className={css.radioButtonLodging}
                name={`lodgingPrices[${index}]`}
                id={`lodgingPricesNoLodging.${index}`}
                value={LODGING_INFO.NOT_OFFERED}
                label={intl.formatMessage({
                  id: 'EditListingCreatePackageForm.noLodging',
                })}
              />
            </div>
          </EditListingFieldWrapper>
        ))}
      </EditListingSection>
    </>
  );
};

const FieldLodgingAvailability = props => <FieldArray component={LodgingAvailability} {...props} />;

export default injectIntl(FieldLodgingAvailability);
