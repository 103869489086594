import React, { useState, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import arrayMove from 'array-move';
import {
  EditListingFieldWrapper,
  EditListingPhotoPreviews,
  PrimaryButton,
  Modal,
  PhotoLibrary,
} from '..';
import { requestUpdateListing } from '../../containers/EditListingPage/EditListingPage.duck';
import css from './EditListingAccessPhotoLibrary.css';

const EditListingAccessPhotoLibrary = ({
  intl,
  currentListing,
  title,
  imagesIds,
  onUpdateImagesIds,
  fieldHeader,
  requestImageUpload,
  onUpdateListing,
  form,
}) => {
  const [isPhotoLibraryModalOpened, setIsPhotoLibraryModalOpened] = useState(false);

  const photoLibraryTags = get(currentListing, 'attributes.publicData.photoLibraryTags', {});
  const listingImages = get(currentListing, 'images', []);

  const currentImages = useMemo(
    () =>
      imagesIds
        .map(imageId => {
          return listingImages.find(listingImage => listingImage.id.uuid === imageId);
        })
        .filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imagesIds]
  );

  return (
    <EditListingFieldWrapper>
      {fieldHeader}

      <PrimaryButton
        type="button"
        onClick={() => {
          setIsPhotoLibraryModalOpened(true);
        }}
      >
        <FormattedMessage id="EditListingCreatePackageForm.photoSectionButton" />
      </PrimaryButton>

      <Modal
        lightCloseButton
        isOpen={isPhotoLibraryModalOpened}
        onClose={() => {
          setIsPhotoLibraryModalOpened(false);
        }}
        containerClassName={css.photoLibraryContainer}
        dontCloseOnOutsideClick
      >
        <PhotoLibrary
          form={form}
          intl={intl}
          listing={currentListing}
          packageName={title}
          imagesIds={imagesIds}
          requestImageUpload={requestImageUpload}
          onUpdateListing={onUpdateListing}
          onAddImagesIds={items => {
            onUpdateImagesIds(items);
            setIsPhotoLibraryModalOpened(false);
          }}
        />
      </Modal>

      <EditListingPhotoPreviews
        className={css.photoPreviews}
        images={currentImages}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        savedImageCaptionPlaceholderText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageCaptionPlaceholderText',
        })}
        onRemoveImage={({ id }) => {
          onUpdateImagesIds(
            imagesIds.filter(imageId => {
              if (id && imageId === id.uuid) return false;
              return true;
            })
          );
        }}
        onUpdateImageOrder={({ oldIndex, newIndex }) => {
          onUpdateImagesIds(arrayMove(imagesIds, oldIndex, newIndex));
        }}
        tags={photoLibraryTags}
      />
    </EditListingFieldWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: values => dispatch(requestUpdateListing(null, values)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingAccessPhotoLibrary));
