import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldCheckbox,
  FieldSelect,
} from '../../../components';
import { referralMethodMap, profileActivities } from '../../../marketplace-custom-config';
import * as validators from '../../../util/validators';
import css from '../IdpSignupForm.css';

const Step2 = props => {
  const { intl, values, formId } = props;

  const phonePlaceholder = intl.formatMessage({
    id: 'ConfirmSignupForm.phonePlaceholder',
  });
  const phoneLabel = intl.formatMessage({ id: 'ConfirmSignupForm.phoneLabel' });
  const phoneNumberRequiredMessage = intl.formatMessage({
    id: 'SignupForm.phoneNumberRequired',
  });
  const phoneNumberRequired = validators.minLength(phoneNumberRequiredMessage, 10);

  // role
  const roleRequiredMessage = intl.formatMessage({
    id: 'SignupForm.roleRequired',
  });
  const rolesRequired = validators.nonEmptyArray(roleRequiredMessage);

  // referral method
  const referralMethodRequiredMessage = intl.formatMessage({
    id: 'SignupForm.referralMethodRequired',
  });
  const referralMethodRequired = validators.required(referralMethodRequiredMessage);

  const referralMethodOtherRequiredMessage = intl.formatMessage({
    id: 'ContactDetailsForm.otherErrorMessage',
  });

  const referralMethodOtherRequired = validators.required(referralMethodOtherRequiredMessage);

  const otherOption = values.referralMethod === 'other';

  const otherLabel = intl.formatMessage({
    id: 'ContactDetailsForm.otherLabel',
  });
  const otherPlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.otherPlaceholder',
  });

  return (
    <div>
      <h1 className={css.signupWithIdpTitle}>
        <FormattedMessage id="IdpSignupForm.step2Header" />
      </h1>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="IdpSignupForm.step2SubHeader" />
      </p>

      <div>
        <div className={css.formSection}>
          <FieldPhoneNumberInput
            className={css.phone}
            name="phoneNumber"
            id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
            label={phoneLabel}
            placeholder={phonePlaceholder}
            validate={phoneNumberRequired}
          />
        </div>
        <div className={css.formSection}>
          <div className={css.fieldLabel}>
            <FormattedMessage id="ContactDetailsForm.hearAboutUs" />
          </div>
          <FieldSelect
            selectClassName={css.referralSelect}
            id="referral-select"
            name="referralMethod"
            validate={referralMethodRequired}
          >
            <option className={css.referralSelectOption} disabled value="">
              {intl.formatMessage({
                id: 'ContactDetailsForm.referralSelect',
              })}
            </option>
            {referralMethodMap.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          {otherOption ? (
            <FieldTextInput
              type="textarea"
              className={css.referralOther}
              id="referral-input"
              name="referralOther"
              label={otherLabel}
              placeholder={otherPlaceholder}
              validate={referralMethodOtherRequired}
              rows={3}
            />
          ) : null}
        </div>
        <div className={css.formSection}>
          <div className={css.fieldLabel}>
            <FormattedMessage id="ContactDetailsForm.roleLabel" />
          </div>

          <FieldCheckbox
            labelClassName={css.rolesSportsman}
            id="role-sportsman"
            name="role"
            value="sportsman"
            label={intl.formatMessage({
              id: 'ContactDetailsForm.sportsmanLabelExtended',
            })}
            validate={rolesRequired}
          />
          <FieldCheckbox
            id="role-landowner"
            name="role"
            value="landowner"
            label={intl.formatMessage({
              id: 'ContactDetailsForm.landownerLabelExtended',
            })}
            validate={rolesRequired}
          />
        </div>
        {values?.role?.includes('sportsman') && (
          <div className={css.activitiesSection}>
            <div className={css.fieldLabel}>
              <FormattedMessage id="MyInterestsForm.interestedActivitiesLabel" />
            </div>

            <div className={css.activityCheckboxes}>
              {Object.keys(profileActivities).map(key => (
                <FieldCheckbox
                  key={`activity.${key}`}
                  id={`activity.${key}`}
                  name="interestedActivities"
                  value={key}
                  label={profileActivities[key].label}
                  labelClassName={css.fieldCheckboxLabel}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
