import React from 'react';
import { string, oneOfType, bool, object } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ReactComponent as IconPlus } from '../../assets/icons/lt-badge.svg';
import { hasPremiumMembership } from '../../util/user';

import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '..';

import css from './Avatar.css';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_SMALL = '24px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',

  'default',
];

export const AvatarComponent = props => {
  const {
    rootClassName,
    badgeClassName,
    className,
    user,
    renderSizes,
    disableProfileLink,
    intl,
    useListingImage,
    listing,
    showBadge,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const badgeClasses = classNames(badgeClassName || css.plusIcon);

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const userHasPremiumMembership = hasPremiumMembership(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  let defaultUserDisplayName = '';
  if (isBannedUser) {
    defaultUserDisplayName = bannedUserDisplayName;
  } else if (isDeletedUser) {
    defaultUserDisplayName = deletedUserDisplayName;
  }

  const defaultUserAbbreviatedName = '';

  let imageToUse = avatarUser.profileImage;
  if (useListingImage && listing && listing.images && listing.images.length > 0) {
    [imageToUse] = listing.images;
  }

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };
  const linkProps = avatarUser.id
    ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;
  const hasImage = imageToUse && imageToUse.id;

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  }
  if (hasImage) {
    const imageElement = (
      <ResponsiveImage
        rootClassName={css.avatarImage}
        alt={displayName}
        image={imageToUse}
        variants={AVATAR_IMAGE_VARIANTS}
        sizes={renderSizes}
      />
    );
    return profileLinkEnabled ? (
      <NamedLink {...rootProps} {...linkProps}>
        {imageElement}
        {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
      </NamedLink>
    ) : (
      <div {...rootProps}>
        {imageElement}{' '}
        {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
      </div>
    );
  }
  if (hasProfileImage && profileLinkEnabled) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
        {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
      </NamedLink>
    );
  }
  if (hasProfileImage) {
    return (
      <div {...rootProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
        {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
      </div>
    );
  }
  if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <span className={css.initials}>{abbreviatedName}</span>
        {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
      </NamedLink>
    );
  }
  // Placeholder avatar (initials)
  return (
    <div {...rootProps}>
      <span className={css.initials}>{abbreviatedName}</span>
      {userHasPremiumMembership && showBadge && <IconPlus className={badgeClasses} />}
    </div>
  );
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
  useListingImage: false,
  listing: null,
  showBadge: true,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  showBadge: bool,
  renderSizes: string,
  disableProfileLink: bool,
  useListingImage: bool,
  listing: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarSmall = props => (
  <Avatar
    rootClassName={css.smallAvatar}
    badgeClassName={css.smallBadge}
    renderSizes={AVATAR_SIZES_SMALL}
    {...props}
  />
);
AvatarSmall.displayName = 'AvatarSmall';
export const AvatarMedium = props => (
  <Avatar
    rootClassName={css.mediumAvatar}
    badgeClassName={css.mediumBadge}
    renderSizes={AVATAR_SIZES_MEDIUM}
    {...props}
  />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => (
  <Avatar
    rootClassName={css.largeAvatar}
    badgeClassName={css.largeBadge}
    renderSizes={AVATAR_SIZES_LARGE}
    {...props}
  />
);
AvatarLarge.displayName = 'AvatarLarge';

export const AvatarXL = props => (
  <Avatar rootClassName={css.extraLargeAvatar} badgeClassName={css.extraLargeBadge} {...props} />
);
AvatarXL.displayName = 'AvatarXL';
