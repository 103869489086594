import { usStateOptions } from '../marketplace-custom-config';

export const getStateAbbreviationFromName = usStateName => {
  const stateOption = usStateOptions.find(option => option.name === usStateName);
  return stateOption ? stateOption.abbreviation : null;
};

export const getAllStates = () => {
  return usStateOptions.map(option => option.name);
};
