import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import airBnbLogo from './images/airbnb.png';
import vrboLogo from './images/vrbo.png';
import homeawayLogo from './images/homeaway.png';
import phoneImage from './images/iphone.png';

import css from './FAQSectionPlatforms.css';
import { Container } from '../../components/Layout';

const FAQSectionPlatforms = props => {
  return (
    <div className={css.root}>
      <Container>
        <div className={css.wrapper}>
          <div className={classNames(css.column, css.contentColumn)}>
            <div className={css.platformLogos}>
              <img src={airBnbLogo} className={classNames(css.platformLogo, css.platformAirBnB)} />
              <img src={vrboLogo} className={classNames(css.platformLogo, css.platformVrbo)} />
              <img
                src={homeawayLogo}
                className={classNames(css.platformLogo, css.platformHomeAway)}
              />
            </div>

            <div>
              <h1 className={css.title}>
                <FormattedMessage id="FAQLandownersPage.platformsTitle" />
              </h1>

              <p className={css.content}>
                <FormattedMessage id="FAQLandownersPage.platformsContent" />
              </p>
            </div>
          </div>

          <div className={classNames(css.column, css.phoneColumn)}>
            <img src={phoneImage} className={css.phoneImage} />
          </div>
        </div>
      </Container>
    </div>
  );
};

FAQSectionPlatforms.propTypes = {};

export default FAQSectionPlatforms;
