// eslint-disable-next-line import/prefer-default-export
export const formatList = (data, conjunction = '&') => {
  if ((data && data.length === 0) || (data && data.length === 1)) {
    return data[0];
  }

  if (data && data.length === 2) {
    return data.join(` ${conjunction} `);
  }

  return data && `${data.slice(0, -1).join(', ')} ${conjunction} ${data.slice(-1).pop()}`;
};
