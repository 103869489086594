import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_LODGING_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemCustomerLodgingTaxMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const customerLodgingTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_LODGING_TAX
  );

  let lodgingTaxItem = null;

  if (isCustomer && customerLodgingTaxLineItem) {
    const tax = customerLodgingTaxLineItem.lineTotal;
    const formattedTax = tax ? formatMoney(intl, tax) : null;

    lodgingTaxItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.lodgingTax" />
        </span>
        <span className={css.itemValue}>+{formattedTax}</span>
      </div>
    );
  }

  return lodgingTaxItem;
};

LineItemCustomerLodgingTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerLodgingTaxMaybe;
