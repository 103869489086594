import axios from 'axios';
import { pick } from 'lodash';
import moment from 'moment';
import config from '../config';
import { denormalisedResponseEntities } from '../util/data';
import { getUserToken } from '../util/api';

export const ID_STATUSES = {
  UNVERIFIED: 'unverified',
  PROCESSING: 'processing',
  VERIFIED: 'verified',
  ERROR: 'error',
};

export const ID_PROVIDERS = {
  STRIPE: 'stripe',
};

export const SET_INITIAL_VALUES = 'app/IDVerification/SET_INITIAL_VALUES';

export const ID_VERIFICATION_REQUEST = 'app/IDVerification/ID_VERIFICATION_REQUEST';
export const ID_VERIFICATION_SUCCESS = 'app/IDVerification/ID_VERIFICATION_SUCCESS';
export const ID_VERIFICATION_ERROR = 'app/IDVerification/ID_VERIFICATION_ERROR';

export const ID_VERIFICATION_DATA_REQUEST = 'app/IDVerification/ID_VERIFICATION_DATA_REQUEST';
export const ID_VERIFICATION_DATA_SUCCESS = 'app/IDVerification/ID_VERIFICATION_DATA_SUCCESS';
export const ID_VERIFICATION_CANCELLED = 'app/IDVerification/ID_VERIFICATION_CANCELLED';
export const ID_VERIFICATION_DATA_ERROR = 'app/IDVerification/ID_VERIFICATION_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  verificationInProgress: false,
  verificationError: null,
  isLoading: false,
  data: null,
  dataError: null,
};

const IDVerificationReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case ID_VERIFICATION_DATA_REQUEST:
      return { ...state, data: null, isLoading: true, dataError: null };

    case ID_VERIFICATION_DATA_ERROR:
      return { ...state, data: payload, isLoading: false, dataError: payload };

    case ID_VERIFICATION_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };

    case ID_VERIFICATION_REQUEST:
      return { ...state, verificationInProgress: true, verificationError: null };

    case ID_VERIFICATION_SUCCESS:
      return { ...state, verificationInProgress: false, data: payload };

    case ID_VERIFICATION_CANCELLED:
      return { ...state, verificationInProgress: false, dataError: payload };

    case ID_VERIFICATION_ERROR:
      return {
        ...state,
        verificationInProgress: false,
        verificationError: payload,
      };

    default:
      return state;
  }
};

export default IDVerificationReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const idVerificationRequest = payload => ({
  type: ID_VERIFICATION_REQUEST,
  payload,
});

export const idVerificationSuccess = payload => ({
  type: ID_VERIFICATION_SUCCESS,
  payload,
});

export const idVerificationCancelled = payload => ({ type: ID_VERIFICATION_CANCELLED, payload });

export const idVerificationError = payload => ({
  type: ID_VERIFICATION_ERROR,
  payload,
});

export const idVerificationDataRequest = payload => ({
  type: ID_VERIFICATION_DATA_REQUEST,
  payload,
});

export const idVerificationDataSuccess = payload => ({
  type: ID_VERIFICATION_DATA_SUCCESS,
  payload,
});

export const idVerificationDataError = payload => ({
  type: ID_VERIFICATION_DATA_ERROR,
  payload,
});

// ================ Thunks ================ //
export const fetchVerificationData = () => async (dispatch, getState, sdk) => {
  dispatch(idVerificationDataRequest());

  try {
    const res = await sdk.currentUser.show();
    const currentUser = denormalisedResponseEntities(res)[0];
    const idVerificationData = currentUser?.attributes?.profile?.protectedData?.idVerification ?? {
      status: ID_STATUSES.UNVERIFIED,
      provider: ID_PROVIDERS.STRIPE,
      metadata: {},
    };

    dispatch(idVerificationDataSuccess(idVerificationData));
  } catch (error) {
    dispatch(idVerificationDataError(error));
  }
};

export const verifyIdentity = () => async (dispatch, getState, sdk) => {
  dispatch(idVerificationRequest());

  const token = getUserToken();

  const stripe = new window.Stripe(config.stripe.publishableKey);
  const { data: session } = await axios.post(`${config.stripe.sessionServer}/sessions`, null, {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });

  try {
    const generatePayload = status => ({
      protectedData: {
        idVerification: {
          status,
          provider: ID_PROVIDERS.STRIPE,
          metadata: {
            sessionId: session.id,
          },
          createdAt: moment.utc().toISOString(),
        },
      },
    });

    const res = await sdk.currentUser.show();
    const currentUser = denormalisedResponseEntities(res)[0];
    const idVerificationData = currentUser?.attributes?.profile?.protectedData?.idVerification;
    if (idVerificationData?.status === ID_STATUSES.UNVERIFIED || !idVerificationData?.status) {
      const payload = generatePayload(ID_STATUSES.PROCESSING);
      await sdk.currentUser.updateProfile(payload);

      const { error } = await stripe.verifyIdentity(session.client_secret);

      if (error) {
        await sdk.currentUser.updateProfile(generatePayload(ID_STATUSES.UNVERIFIED));
        dispatch(idVerificationCancelled(error));
        return;
      }

      dispatch(idVerificationSuccess(payload.protectedData.idVerification));
      return;
    }

    dispatch(idVerificationSuccess(idVerificationData));
  } catch (error) {
    dispatch(idVerificationError(error));
  }
};
