/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './LandownerReferral.css';

const LandownerReferral = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        <strong>Landowner Referral Program Terms and Conditions - Referring User</strong>
      </p>
      <p>
        PLEASE NOTE THAT THE LANDTRUST TERMS USE OF AND PRIVACY POLICY AGREED TO BY YOU APPLY TO
        YOUR PARTICIPATION IN THE LANDOWNER REFERRAL PROGRAM AND YOUR USE OF THE TOOLS AND WEBSITES
        PROVIDED BY LANDTRUST. THESE PROGRAM TERMS AND CONDITIONS (&ldquo;TERMS&rdquo;) SUPPLEMENT
        THE LANDTRUST TERMS OF SERVICE AND PRIVACY POLICY. PLEASE CONSULT THE LANDTRUST TERMS OF
        SERVICE AND PRIVACY POLICY BEFORE AGREEING TO THESE ADDITIONAL TERMS AND CONDITIONS.
        CAPITALIZED TERMS USED IN THESE TERMS AND CONDITIONS, BUT NOT DEFINED, SHALL HAVE THE
        DEFINITION SET FORTH IN THE LANDTRUST TERMS OF USE.
      </p>
      <p>
        <strong>Description of the Program</strong>
      </p>
      <p>
        The LandTrust Landowner Referral Program (&ldquo;Program&rdquo;) permits select, invited
        eligible Users to earn money (see Payment section below) by referring to LandTrust first
        time, new Landowners (&ldquo;Referred Landowners&rdquo;) who create listings or activate
        existing listings for the first time and successfully complete bookings. A
        &ldquo;Completed&rdquo; booking is a booking that is accepted by the Referred Landowner and
        paid for by a Sportsman and which is not cancelled by either the Sportsman or the Referred
        Landowner at any time before or after the scheduled Activity.
      </p>
      <p>
        Referred Landowners will be attributed upon Referring User&rsquo;s invite. Referring Users
        can only get Payment for the first 25 Referred Landowners (i.e., the first 25 Landowners
        they Referred under this Referral Program) who Complete their first booking in line with
        these Terms.
      </p>
      <p>
        To participate, Referring Users must agree to these Terms. When you refer a Referred
        Landowner, the Landowner will see your LandTrust listing and profile on the landing page for
        your Referral Link.
      </p>
      <p>
        <strong>Your Referrals</strong>
      </p>
      <p>
        You agree to (i) use reasonable efforts to refer Referred Landowners to create and activate
        listings; and (ii) educate Referred Landowners on how to use the LandTrust platform,
        including how to accept bookings through the LandTrust platform. You must state that you are
        a Referring User who is encouraging Landowners to list on LandTrust, but that you are not an
        employee, intermediary or agent of LandTrust.
      </p>
      <p>
        <strong>Sharing Referral Links</strong>
      </p>
      <p>
        Referrals should only be used for personal and non-commercial purposes, and only shared with
        personal connections that will appreciate receiving these invitations. You agree and
        represent that you will use referral methods and techniques that people will appreciate and
        that are legal. You should only contact people who have given you their consent to be
        contacted. You agree and represent that you will always be mindful to respect the privacy of
        others (i.e. do not spam), be honest, open, and transparent about who you are and what you
        are offering (i.e. do not mislead).{' '}
      </p>
      <p>
        You will not be eligible for payments if you are in breach of these Landowner Referral
        Program Terms.
      </p>
      <p>
        {' '}
        <strong>Payment</strong>
      </p>
      <p>
        To qualify for any payouts, the Referred Landowner&rsquo;s first received booking must have
        a value (excluding taxes) of at least US$100. The Referred Landowner has 150 days from the
        date they are invited to Complete their first qualifying booking in order to earn the
        Payment.
      </p>
      <p>
        All Referred Landowners must be new, first time Landowners. No payment will be made for
        listings created by current or former LandTrust Landowners, or for bookings made by family
        or friends of the Referred Landowner, or for bookings where either the Referred Landowner or
        the Referred Landowner's listing has the same address as you. The referral fee will be
        US$________. Payouts will be made within 30 days of each qualifying event, to the payout
        address you designate on your LandTrust account.
      </p>
      <p>
        Whether a payout is due shall be a decision made in the sole discretion of LandTrust.
        LandTrust reserves the right to any remedy, including denial of payout or cancellation of
        your account, if fraud, tampering or other violations of LandTrust's Terms of Service,
        Privacy Policy, or these Terms are suspected.
      </p>
      <p>
        <strong>Taxes</strong>
      </p>
      <p>
        Tax regulations may require us to collect appropriate tax information from Referring Users,
        or to withhold taxes from payouts, or both. For instance, IRS regulations stipulate that we
        must collect an IRS Form W-9 from US persons who receive payment. You are solely responsible
        for keeping the information in your tax forms current, complete and accurate. If you fail to
        provide us with documentation that we determine to be sufficient to alleviate our obligation
        (if any) to withhold payments to you, we reserve the right in our sole discretion to freeze
        all payouts to you until resolution, to withhold such amounts as required by law, or to do
        both.
      </p>
      <p>
        You understand and agree that you are solely responsible for determining (i) your applicable
        Tax reporting requirements, and (ii) the Taxes that should be included, and for including
        Taxes to be collected or obligations relating to applicable Taxes. You are also solely
        responsible for remitting to the relevant authority any Taxes included or received by you.
        LandTrust cannot and does not offer Tax-related advice to you.
      </p>
      <p>
        <strong>Nature of Relationship</strong>
      </p>
      <p>
        You acknowledge and agree that it is a fundamental term and condition precedent of your
        participation in the Program that you are not an employee, intermediary or agent of the
        Company, and you will not hold yourself out as, or give any person reason to believe, that
        you are an employee, intermediary or agent of the Company. You agree nothing in your
        participation will be construed as establishing an employment or agency relationship between
        Company and you. Accordingly, you have not at any time been and are not entitled to, and
        hereby irrevocably waive any right or claim to, the benefits provided by the Company to its
        employees from time to time, including vacation time, benefits, salary, bonus, stock or
        stock options, profit sharing, insurance, or medical and health benefits. The Company will
        not carry any liability or other insurance on behalf of you or in your favor.
      </p>
      <p>
        You must comply at all times with all applicable laws and you will not infringe on any
        third-party rights.
      </p>
      <p>
        <strong>Indemnity</strong>
      </p>
      <p>
        You will defend, indemnify and hold LandTrust harmless from and against all claims, damages,
        liabilities, losses, expenses and costs (including reasonable fees and expenses of attorneys
        and other professionals) arising out of or resulting from your negligence or intentional
        misconduct with respect to your participation in the Program.
      </p>
      <p>
        <strong>Termination</strong>
      </p>
      <p>
        LandTrust may terminate your participation in the Program at any time, for any reason or no
        reason. The rights and obligations of the parties under these Terms will survive the
        expiration or termination of this Agreement.
      </p>
      <p>
        <strong>Exclusion and Limitation of Liability</strong>
      </p>
      <p>
        In no event will LandTrust be liable for any special, incidental, punitive, exemplary or
        consequential damages of any kind in connection with this Agreement, even if the Company has
        been informed in advance of the possibility of such damages. In no event will the
        Company&rsquo;s aggregate liability to you in connection with this Agreement exceed the
        aggregate amount of the payments made by the Company to you in accordance with this
        Agreement.
      </p>
      <p>
        <strong>Modification</strong>
      </p>
      <p>
        LandTrust reserves the right, at its sole discretion, to modify the Program at any time by
        posting an update on this page. By continuing to recruit Referred Landowners, you agree that
        the Terms are acceptable to you and binding on you. If the modified Terms are not acceptable
        to you, your only recourse is to terminate your Program participation.
      </p>
    </div>
  );
};

LandownerReferral.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

LandownerReferral.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandownerReferral;
