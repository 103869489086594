import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ListingCard from '../ListingCard/ListingCard';
import css from './SectionFeaturedListings.css';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';
import { NamedLink } from '..';
import { PrimaryButton } from '../Button/Button';

const SectionFeaturedListings = ({ listings }) => {
  return (
    <div className={css.root} data-testid="featured-listings-section">
      <div className={css.header}>
        <h1>
          <FormattedMessage id="SectionFeaturedListings.title" />
        </h1>

        <NamedLink
          name="SearchPage"
          to={{ search: '?bounds=63.88180842%2C-60.37668482%2C-9.94663466%2C-135.20877883' }}
          className={css.showAllLink}
        >
          <FormattedMessage id="SectionFeaturedListings.showAll" />
        </NamedLink>
      </div>

      <ScrollingCarousel className={css.carousel}>
        {listings.map(l => (
          <Step className={css.step} key={l.id.uuid}>
            <ListingCard listing={l} />
          </Step>
        ))}
      </ScrollingCarousel>

      <div className={css.showAllMobileButtonContainer}>
        <PrimaryButton
          component={NamedLink}
          name="SearchPage"
          to={{ search: '?bounds=63.88180842%2C-60.37668482%2C-9.94663466%2C-135.20877883' }}
          className={css.showAllMobileButton}
        >
          Show all
        </PrimaryButton>
      </div>
    </div>
  );
};

const { array } = PropTypes;

SectionFeaturedListings.propTypes = {
  listings: array.isRequired,
};

export default SectionFeaturedListings;
