import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const FacebookLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M12.65 0C19.636 0 25.3 5.664 25.3 12.65c0 6.986-5.664 12.65-12.65 12.65C5.664 25.3 0 19.636 0 12.65 0 5.664 5.664 0 12.65 0z"
      />
      <path
        fill="#3B5998"
        d="M16.274 5.36v2.618h-1.6c-1.252 0-1.487.585-1.487 1.428v1.874h2.985l-.398 2.935h-2.587v7.526h-3.116v-7.526H7.474V11.28h2.597V9.118c0-2.508 1.578-3.877 3.88-3.877 1.1 0 2.048.08 2.323.12z"
      />
    </g>
  </svg>
);

export const GoogleLogo = (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M19.6 10.227c0-.709-.064-1.39-.182-2.045H10v3.868h5.382a4.6 4.6 0 01-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35z"
        fill="#4285F4"
      />
      <path
        d="M10 20c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123H1.064v2.59A9.996 9.996 0 0010 20z"
        fill="#34A853"
      />
      <path
        d="M4.405 11.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9V5.51H1.064A9.996 9.996 0 000 10c0 1.614.386 3.14 1.064 4.49l3.34-2.59z"
        fill="#FBBC05"
      />
      <path
        d="M10 3.977c1.468 0 2.786.505 3.823 1.496l2.868-2.868C14.959.99 12.695 0 10 0 6.09 0 2.71 2.24 1.064 5.51l3.34 2.59C5.192 5.736 7.396 3.977 10 3.977z"
        fill="#EA4335"
      />
      <path d="M0 0h20v20H0z" />
    </g>
  </svg>
);
