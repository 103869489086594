import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '..';
import css from './SearchResultsPanel.css';
import { NotifyForAddedStateForm } from '../../forms';

const SearchResultsPanel = props => {
  const {
    children,
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    activities,
    species,
    urlQueryParams: { address },
    hideListingPrice,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
        textIntlId="SearchResultsPanel.paginationText"
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const activityChecker = activities && activities.length === 1;

  return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.listingCards}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              activityChecker={activityChecker}
              activity={activityChecker && activities[0]}
              activities={activities}
              species={species}
              hideListingPrice={hideListingPrice}
              isResponsive
              openListingInNewTab
            />
          ))}
          {children}
          {listings.length > 0 && <NotifyForAddedStateForm address={address} />}
        </div>
        {paginationLinks}
      </div>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  activities: [],
  species: [],
  hideListingPrice: false,
};

const { array, node, object, string, bool } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  activities: array,
  species: array,
  hideListingPrice: bool,
};

export default SearchResultsPanel;
