import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { PrimaryButton, SecondaryButton } from '../../components/Button/Button';
import NamedLink from '../../components/NamedLink/NamedLink';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import css from './NotFoundPage.css';
import NotFoundImage from '../../assets/404.webp';

export const NotFoundPageComponent = ({ intl, scrollingDisabled, staticContext }) => {
  const title = intl.formatMessage({
    id: 'NotFoundPage.title',
  });

  useEffect(() => {
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    staticContext.notfound = true;
  }, []);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <div className={css.content}>
              <img src={NotFoundImage} alt={title} className={css.notFoundImage} />

              <h1 className={css.heading}>
                <FormattedMessage id="NotFoundPage.heading" />
              </h1>
              <p className={css.description}>
                <FormattedMessage id="NotFoundPage.description" />
              </p>

              <div className={css.actions}>
                <PrimaryButton
                  component={NamedLink}
                  name="SearchPage"
                  className={classNames(css.action, css.actionPrimary)}
                >
                  <FormattedMessage id="NotFoundPage.primaryCTA" />
                </PrimaryButton>

                <SecondaryButton
                  component={NamedLink}
                  name="LandingPage"
                  className={classNames(css.action, css.actionSecondary)}
                >
                  <FormattedMessage id="NotFoundPage.secondaryCTA" />
                </SecondaryButton>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, object } = PropTypes;

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(NotFoundPageComponent);

export default NotFoundPage;
