import React from 'react';
import { FormattedMessage } from 'react-intl';
import { txIsDelivered } from '../../util/transaction';
import { PrimaryButton } from '../Button/Button';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionComplete = ({ transaction, transition, onGoToReview, buttons }) => {
  // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
  const reviewPeriodJustStarted = txIsDelivered(transaction);

  const reviewAsFirstLink = reviewPeriodJustStarted ? (
    <PrimaryButton onClick={onGoToReview}>
      <FormattedMessage id="BookingStatus.leaveAReview" />
    </PrimaryButton>
  ) : null;

  if (reviewAsFirstLink) {
    buttons.push(reviewAsFirstLink);
  }

  return (
    <InboxTransitionBase
      header={
        <FormattedMessage id="BookingStatus.transitionComplete" values={{ reviewLink: '' }} />
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    >
      <FormattedMessage id="BookingStatus.transitionCompleteSubtext" />
    </InboxTransitionBase>
  );
};

export default InboxTransitionComplete;
