import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { bool, node, object } from 'prop-types';

import { species } from '../../marketplace-custom-config';
import { daysAdded } from '../../util/dates';

import css from './BookingStatusInquiry.css';
import { formatList } from '../../util/array';

const getFormattedMessageId = (
  hasHunting,
  hasFishing,
  hasPackage,
  isOwnTransition,
  hasInquiryExpired
) => {
  switch (true) {
    case hasPackage:
      return isOwnTransition || hasInquiryExpired
        ? 'BookingStatus.ownTransitionHasPackage'
        : 'BookingStatus.transitionHasPackage';
    case hasHunting && !hasFishing:
      return isOwnTransition || hasInquiryExpired
        ? 'BookingStatus.ownTransitionHuntingInquiry'
        : 'BookingStatus.transitionHuntingInquiry';
    case hasFishing && !hasHunting:
      return isOwnTransition || hasInquiryExpired
        ? 'BookingStatus.ownTransitionFishingInquiry'
        : 'BookingStatus.transitionFishingInquiry';
    case hasFishing && hasHunting:
      return isOwnTransition || hasInquiryExpired
        ? 'BookingStatus.ownTransitionHuntingAndFishingInquiry'
        : 'BookingStatus.transitionHuntingAndFishingInquiry';
    default:
      return isOwnTransition || hasInquiryExpired
        ? 'BookingStatus.ownTransitionNoSpecies'
        : 'BookingStatus.transitionNoSpecies';
  }
};

const BookingStatusInquiry = ({
  isOwnTransition,
  transaction,
  otherUsersName,
  hasInquiryExpired,
}) => {
  const speciesKeys = get(transaction, 'attributes.protectedData.species', []);
  const startDate = get(transaction, 'attributes.protectedData.startDate', '');
  const endDate = get(transaction, 'attributes.protectedData.endDate', '');
  const partySize = get(transaction, 'attributes.protectedData.partySize', null);
  const selectedPackage = get(transaction, 'attributes.protectedData.selectedPackage', null);
  const message = get(transaction, 'attributes.protectedData.message', '');

  const hasFishing = !!speciesKeys.includes('fishing');
  const hasHunting = (!hasFishing && speciesKeys.length) || speciesKeys.length > 1;
  const hasPackage = !isEmpty(selectedPackage);

  const huntSpecies = speciesKeys
    .filter(key => key !== 'fishing')
    .map(key => species?.hunt[key]?.label);

  const packageTitle = get(selectedPackage, 'packageTitle', null);
  const speciesFormat = formatList(huntSpecies);
  const dayCount = daysAdded(moment(startDate), moment(endDate));
  const isSingleDay = dayCount === 1;
  const startDateFormat = isSingleDay
    ? moment(startDate).format('ddd. MMMM Do, YYYY')
    : moment(startDate).format('ddd. MMMM Do');
  const endDateFormat = moment(endDate).format('ddd. MMMM Do, YYYY');

  const dateFormat = isSingleDay ? startDateFormat : `${startDateFormat} - ${endDateFormat}`;

  const inquiryDisplayName = get(otherUsersName, 'props.user.attributes.profile.displayName', '');
  const formattedMessageId = getFormattedMessageId(
    hasHunting,
    hasFishing,
    hasPackage,
    isOwnTransition,
    hasInquiryExpired
  );

  return (
    formattedMessageId && (
      <div
        data-testId="booking-status-inquiry"
        className={classNames(css.inquiryContainer, {
          [css.ownInquiry]: isOwnTransition,
          [css.inquiry]: !isOwnTransition,
        })}
      >
        <FormattedHTMLMessage
          id={formattedMessageId}
          values={{
            inquiryDisplayName,
            speciesFormat,
            dateFormat,
            partySize,
            packageTitle,
            message,
          }}
        />
      </div>
    )
  );
};

BookingStatusInquiry.defaultProps = {
  hasInquiryExpired: false,
};

BookingStatusInquiry.propTypes = {
  isOwnTransition: bool.isRequired,
  transaction: object.isRequired,
  otherUsersName: node.isRequired,
  hasInquiryExpired: bool,
};

export default BookingStatusInquiry;
