/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { array, arrayOf, func, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';
import { categories } from '../../marketplace-custom-config';
import { propTypes } from '../../util/types';
import { ensureListing, formatNumber } from '../../util/data';
import { formatList } from '../../util/array';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock-solid.svg';
import {
  getListingStartingPrice,
  getListingPackageMinPrice,
  getActiveActivityImages,
  getCategoryLabels,
  getListingLocation,
  getListingImages,
  LISTING_TITLE_MIN_LENGTH_FOR_LONG_WORDS,
} from '../../util/listing';
import { richText } from '../../util/richText';
import { ResponsiveImage, Badge, ReviewRating } from '..';

import css from './SearchMapInfoCard.css';

const ListingImage = ({ ...props }) => <ResponsiveImage {...props} />;

// Panel width relative to the viewport
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');

// ListingCard is the listing info without overlayview or carousel controls
// TODO: this is almost a copy of ListingCard component with some fixes for the portals.
// Ideally we would create a pure component and reuse it in different places.
const ListingCard = props => {
  const { className, intl, listing, urlToListing, hideListingPrice, activities, species } = props;

  const classes = classNames(css.root, className);
  const currentListing = ensureListing(listing);

  const { title = '', publicData, metadata } = currentListing.attributes;

  // Displays the lowest price available, from each category
  const { gameTypes, location, packages } = publicData;
  const speciesMinPrice = getListingStartingPrice(gameTypes, false, false, species);
  const packagesMinPrice = getListingPackageMinPrice(packages, activities, species);
  const prices = [];

  if (packagesMinPrice) {
    prices.push(packagesMinPrice);
  }

  if (speciesMinPrice) {
    prices.push(speciesMinPrice);
  }

  const minPrice = prices.length ? Math.min(...prices) : null;

  // get the first activity based image if an activity is passed (i.e. search page filter)
  const activityChecker = activities && activities.length === 1;
  const activity = activityChecker && activities[0];
  const activityImage = activity && getActiveActivityImages(listing, activity);

  const firstImage =
    getListingImages(currentListing).length > 0
      ? getListingImages(currentListing)[0]
      : currentListing.images[0];

  const formattedCategories = formatList(getCategoryLabels(categories, publicData.categories));
  const formattedActivity = activity && getCategoryLabels(categories, activity.split(','));

  const listingLocation = getListingLocation(currentListing);
  const maxSportsmen = get(publicData, 'policies.sportsmen', null);
  const acres = location && location.acreage ? formatNumber(location.acreage) : null;
  const hasLodging = get(publicData, 'lodging.hasLodging', false);
  const rating = get(metadata, 'rating', 0);

  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={css.imageWrapper}>
        <div className={css.imageInner}>
          <ListingImage
            rootClassName={css.imageRoot}
            alt={title}
            image={activityImage || firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={cardRenderSizes}
          />
        </div>

        {hasLodging && (
          <Badge>
            <FormattedMessage id="ListingCard.lodgingBadge" />
          </Badge>
        )}
      </div>

      <div className={css.infoContainer}>
        <div className={css.infoMain}>
          <div className={css.infoHeader}>
            {maxSportsmen && (
              <FormattedMessage id="ListingCard.sportsman" values={{ count: maxSportsmen }} />
            )}
            {formattedActivity && <span> | {formattedActivity}</span>}
            {!formattedActivity && formattedCategories && <span> | {formattedCategories}</span>}
          </div>

          <div className={css.infoTitle}>
            {richText(title, {
              longWordMinLength: LISTING_TITLE_MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          <div className={css.infoFooter}>
            {acres ? `${acres} Acres` : null}
            {` • ${listingLocation}`}
          </div>

          <div className={css.ratingWrapper}>
            {rating ? (
              <div>
                <ReviewRating
                  rating={rating}
                  className={css.reviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                  gold
                />
              </div>
            ) : (
              <div className={css.firstToBook}>
                <ClockIcon className={css.clockIcon} />
                <FormattedMessage id="ListingCard.firstToBook" />
              </div>
            )}
          </div>
        </div>

        {minPrice && !hideListingPrice && (
          <div className={css.priceInfo}>
            <span className={css.priceInfoLabel}>
              <FormattedMessage id="ListingCard.startingAt" />
              &nbsp;
            </span>

            <div className={css.priceAmount}>
              <span>${minPrice}</span>
              {minPrice === speciesMinPrice && (
                <span> / {intl.formatMessage({ id: 'ListingCard.day' })}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  intl: intlShape.isRequired,
};

class SearchMapInfoCard extends Component {
  constructor(props) {
    super(props);

    this.state = { currentListingIndex: 0 };
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listings,
      createURLToListing,
      activities,
      species,
      hideListingPrice,
    } = this.props;

    const { currentListingIndex } = this.state;
    const currentListing = ensureListing(listings[currentListingIndex]);

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <ListingCard
          urlToListing={createURLToListing(currentListing)}
          listing={currentListing}
          intl={intl}
          activities={activities}
          species={species}
          hideListingPrice={hideListingPrice}
        />
      </div>
    );
  }
}

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
  activities: [],
  species: [],
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  createURLToListing: func.isRequired,
  activities: array,
  species: array,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
