import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { TertiaryButton } from '..';

import css from './TransactionPanel.css';

const CancelButton = ({ inProgress, disabled, onClick, messageId }) => (
  <div className={css.cancelButtonWrapper}>
    <TertiaryButton
      inProgress={inProgress}
      disabled={disabled}
      onClick={onClick}
      isFullWidth
      className={css.cancelButton}
    >
      <FormattedMessage id={messageId} />
    </TertiaryButton>
  </div>
);

const CancelActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    onCancelSaleCustomerWithRefund,
    onCancelSaleCustomerWithoutRefund,
    onCancelSaleCustomerLate,
    onCancelSaleProviderEarly,
    onCancelSaleProvider,
    onCancelSaleProviderLate,
    onCancelOffer,
    cancelInProgress,
    cancelSaleError,
    showCancelOffer,
    showCancelButtonCustomerWithRefund,
    showCancelButtonCustomerWithoutRefund,
    showCancelButtonProviderEarlyCancel,
    showCancelButtonProviderCancel,
    showCancelButtonCustomerLateCancel,
    showCancelButtonProviderLateCancel,
  } = props;

  const buttonsDisabled = cancelInProgress;
  const cancelErrorMessage = cancelSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.cancelButtons, className);

  const renderCancelButton = (condition, onClickHandler, messageId) => {
    if (condition) {
      return (
        <div className={classes}>
          <div className={css.actionErrors}>{cancelErrorMessage}</div>
          <CancelButton
            inProgress={cancelInProgress}
            disabled={buttonsDisabled}
            onClick={onClickHandler}
            messageId={messageId}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderCancelButton(
        showCancelButtonCustomerWithRefund,
        onCancelSaleCustomerWithRefund,
        'TransactionPanel.cancelTripButton'
      )}
      {renderCancelButton(showCancelOffer, onCancelOffer, 'TransactionPanel.cancelOfferButton')}
      {renderCancelButton(
        showCancelButtonCustomerWithoutRefund,
        onCancelSaleCustomerWithoutRefund,
        'TransactionPanel.cancelTripButton'
      )}
      {renderCancelButton(
        showCancelButtonCustomerLateCancel,
        onCancelSaleCustomerLate,
        'TransactionPanel.cancelTripButton'
      )}
      {renderCancelButton(
        showCancelButtonProviderEarlyCancel,
        onCancelSaleProviderEarly,
        'TransactionPanel.cancelTripButton'
      )}
      {renderCancelButton(
        showCancelButtonProviderCancel,
        onCancelSaleProvider,
        'TransactionPanel.cancelTripButton'
      )}
      {renderCancelButton(
        showCancelButtonProviderLateCancel,
        onCancelSaleProviderLate,
        'TransactionPanel.cancelTripButton'
      )}
    </>
  );
};

export default CancelActionButtonsMaybe;
