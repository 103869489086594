import * as props from '../prop-names';

import {
  formatEvent,
  getEventDate,
  getAbsoluteUrl,
  getDefaultUserProps,
  getFullListingProps,
  getSubTotal,
  getEmailProps,
} from '../helpers';

import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Tracked when listing is viewed
export const trackListingViewEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const listing = getListingsById(state, [payload.id]).pop();

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(state.Routing.currentLocation.pathname),
    [props.VIEWED_LISTING_COUNT]: 1,
    ...getDefaultUserProps(state),
    ...getFullListingProps(listing),
  };

  return event;
};

// Tracked when new listing is started
export const trackNewListingStartedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const listing = getMarketplaceEntities(state, [
    { id: payload.data.id, type: 'ownListing' },
  ]).pop();
  event.mp_properties = {
    ...getDefaultUserProps(state),
    ...getEmailProps(state),
    [props.FIRST_NEW_LISTING_STARTED_DATE]: getEventDate(),
    [props.LAST_NEW_LISTING_STARTED_DATE]: getEventDate(),
    [props.NEW_LISTING_STARTED_COUNT]: 1,
    [props.LISTING_ID]: payload.data.id.uuid,
    ...getFullListingProps(listing),
  };

  return event;
};

// Tracked when new listing is completed
export const trackNewListingCompletedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const listing = getMarketplaceEntities(state, [
    { id: payload.data.id, type: 'ownListing' },
  ]).pop();

  event.mp_properties = {
    [props.FIRST_NEW_LISTING_COMPLETED_DATE]: getEventDate(),
    [props.LAST_NEW_LISTING_COMPLETED_DATE]: getEventDate(),
    [props.LISTING_ID]: payload.data.id.uuid,
    ...getDefaultUserProps(state),
    ...getFullListingProps(listing),
    ...getEmailProps(state),
  };
  return event;
};

// Tracked when user clicks on Availability & Pricing button within listing page
export const trackPricingViewMobileOnlyEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const { listing, location } = payload;

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(location.pathname),
    ...getDefaultUserProps(state),
    ...getFullListingProps(listing),
  };

  return event;
};

export const trackListingClickEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const { listing } = payload;

  event.mp_properties = {
    [props.FIRST_LISTING_CLICK_DATE]: getEventDate(),
    [props.LAST_LISTING_CLICK_DATE]: getEventDate(),
    [props.LISTING_CLICK_COUNT]: 1,
    ...getDefaultUserProps(state),
    ...getFullListingProps(listing),
  };

  return event;
};

export const trackReserveClickedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [
    { id: payload.transaction.id, type: 'transaction' },
  ]).pop();

  tx.listing.author = tx.provider;

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(state.Routing.currentLocation.pathname),
    ...getFullListingProps(tx.listing),
    [props.SUBTOTAL]: getSubTotal(tx),
  };

  return event;
};

export const trackListingFavored = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.FIRST_LISTING_FAVORED_DATE]: getEventDate(),
    [props.LAST_LISTING_FAVORED_DATE]: getEventDate(),
    [props.LISTING_FAVORED_COUNT]: 1,
    ...getDefaultUserProps(state),
    ...getFullListingProps(payload.listing),
  };

  return event;
};

export const trackListingUnfavored = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.FIRST_LISTING_UNFAVORED_DATE]: getEventDate(),
    [props.LAST_LISTING_UNFAVORED_DATE]: getEventDate(),
    [props.LISTING_UNFAVORED_COUNT]: 1,
    ...getDefaultUserProps(state),
    ...getFullListingProps(payload.listing),
  };

  return event;
};
