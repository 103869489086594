import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { SendMessageForm } from '../../forms';
import { ensureUser, userDisplayNameAsString } from '../../util/data';
import css from './InboxDetailsSendMessage.css';

const InboxDetailsSendMessage = ({
  currentTransaction,
  currentUser,
  intl,
  onSendMessage,
  sendMessageInProgress,
  sendMessageError,
}) => {
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const iscustomerLoaded = !!currentCustomer.id;
  const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
  const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
  const isProviderLoaded = !!currentProvider.id;
  const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
  const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  let otherUserDisplayNameString;

  if (currentUserIsCustomer) {
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  const showSendMessageForm =
    !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

  const handleMessageSubmit = async (values, form) => {
    const message = values.message ? values.message.trim() : null;

    if (!message) {
      return;
    }

    try {
      await onSendMessage(currentTransaction.id, message);
    } finally {
      form.reset();
    }
  };

  return (
    <div>
      {showSendMessageForm ? (
        <SendMessageForm
          currentUser={currentUser}
          form="TransactionPanel.SendMessageForm"
          rootClassName={css.sendMessageForm}
          textAreaClassName={css.textAreaMessageForm}
          messagePlaceholder={intl.formatMessage(
            { id: 'TransactionPanel.sendMessagePlaceholder' },
            { name: otherUserDisplayNameString }
          )}
          inProgress={sendMessageInProgress}
          sendMessageError={sendMessageError}
          onSubmit={handleMessageSubmit}
        />
      ) : (
        <div className={css.sendingMessageNotAllowed}>
          <FormattedMessage id="TransactionPanel.sendingMessageNotAllowed" />
        </div>
      )}
    </div>
  );
};

export default injectIntl(InboxDetailsSendMessage);
