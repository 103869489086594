/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { FieldTextInput, ImageFromFile, ResponsiveImage, IconClose, IconSpinner, Tags } from '..';
import Tooltip from '../Tooltip/Tooltip';
import { InlineTextButton } from '../Button/Button';
import { ReactComponent as BurgerIcon } from '../../assets/icons/burger.svg';
import { ReactComponent as TagIcon } from '../../assets/icons/tag-light.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-regular.svg';
import { flatTags, getPhotos } from '../../util/photoLibrary';
import css from './EditListingPhotoLibraryPreviews.css';

const DragHandle = sortableHandle(() => (
  <button type="button">
    <BurgerIcon className={css.sortHandle} />
  </button>
));

const SortableContainer = sortableContainer(({ className, children }) => (
  <div className={className}>{children}</div>
));

const PhotoPreview = ({
  image,
  file,
  error,
  savedImageAltText,
  savedImageCaptionPlaceholderText,
  onRemoveImage,
  onUpdateTags,
  imageTags,
  disableSorting,
}) => {
  const handleRemoveClick = e => {
    e.stopPropagation();

    // Handle saved images and recently uploaded images by passing both ids
    onRemoveImage({ tmpId: image.tmpId, id: image.id });
  };

  const handleDeleteTag = tag => {
    onUpdateTags(tag);
  };

  const hasApiId = !!(image.id && image.id.uuid);
  const hasError = !!error;
  const isInProgress = !hasApiId && !hasError;
  const canDelete = hasError || hasApiId;

  const photoPreviewImageClassname = classNames(css.photoPreviewImage, {
    [css.photoPreviewImageFade]: hasError || isInProgress,
  });

  return (
    // Use this element to translate when dragging
    <div className={css.photoPreviewContainer}>
      {/* Use this element to scale when dragging */}
      <div className={css.photoPreviewContainerInner}>
        <div className={css.photoPreviewImageContainer}>
          {/* If the image has a `file` use it for the preview even if the image has finished uploading.
            This is because we don't ever hydrate the full image after it is uploaded so it's easier
            to just keep using the preview from `file` */}
          {file ? (
            <ImageFromFile
              id={image.tmpId}
              rootClassName={photoPreviewImageClassname}
              file={file}
              justImage
            />
          ) : (
            <a
              href={image.attributes.variants['scaled-xlarge'].url}
              css={css.photoPreviewImageLink}
              target="_blank"
              rel="noreferrer"
            >
              <ResponsiveImage
                rootClassName={photoPreviewImageClassname}
                image={image}
                alt={savedImageAltText}
                variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
              />
            </a>
          )}

          {isInProgress && (
            <div className={css.photoPreviewSpinner}>
              <IconSpinner />
            </div>
          )}

          {canDelete && (
            <button
              type="button"
              onClick={handleRemoveClick}
              className={css.photoPreviewDeleteImage}
            >
              <IconClose className={css.photoPreviewDeleteImageIcon} />
            </button>
          )}
        </div>

        <div className={css.photoPreviewCaptionContainer}>
          <FieldTextInput
            id={`imageCaptions.${image.tmpId || image.id.uuid}`}
            name={`imageCaptions.${image.tmpId || image.id.uuid}`}
            placeholder={savedImageCaptionPlaceholderText}
            type="textarea"
            className={css.photoPreviewCaptionInputWrapper}
            inputClassName={css.photoPreviewCaptionInput}
          />

          {image?.id?.uuid && (
            <div className={css.photoPreviewTagsContainer}>
              <div className={css.photoPreviewTagsLinkContainer}>
                <TagIcon className={css.photoPreviewTagsIcon} />

                <Tooltip
                  hideArrow
                  hideCloseButton
                  placement="bottom-start"
                  trigger="click"
                  tooltip={<Tags tags={imageTags} onUpdateTags={onUpdateTags} />}
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: () => {
                          return [-15, 10];
                        },
                      },
                    },
                  ]}
                >
                  <InlineTextButton className={css.photoPreviewTagsLink}>
                    <FormattedMessage id="EditListingPhotoLibraryPreviews.tag" />
                  </InlineTextButton>
                </Tooltip>
              </div>

              {imageTags.map((tag, index) => (
                <div className={css.photoPreviewTagsItem} key={`${image.id.uuid}-${index}`}>
                  <span>{flatTags[tag]}</span>

                  <button
                    type="button"
                    className={css.photoPreviewTagsItemClose}
                    onClick={() => handleDeleteTag(tag)}
                  >
                    <CloseIcon className={css.photoPreviewTagsCloseIcon} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {!disableSorting && (
          <div className={css.sortHandleContainer}>
            <DragHandle />
          </div>
        )}
      </div>

      {error && <p className={css.error}>{error}</p>}
    </div>
  );
};

const SortableItem = sortableElement(PhotoPreview);

PhotoPreview.defaultProps = { file: null, error: null };

const { array, func, string, object } = PropTypes;

PhotoPreview.propTypes = {
  image: object.isRequired,
  file: object,
  error: string,
  savedImageAltText: string.isRequired,
  savedImageCaptionPlaceholderText: string.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateTags: func.isRequired,
};

const EditListingPhotoLibraryPreviews = ({
  className,
  images,
  files,
  errors,
  savedImageAltText,
  savedImageCaptionPlaceholderText,
  onRemoveImage,
  onUpdateImageOrder,
  tags,
  onUpdateTags,
  activeTab,
  isUntaggedChecked,
  disableSorting,
}) => {
  const [isSorting, setIsSorting] = useState(false);

  const classes = classNames(
    css.root,
    {
      [css.rootIsSorting]: isSorting,
    },
    className
  );

  // Depending on the state of the upload the file maybe not be ready yet so
  // we won't try to use it until it is present
  const displayableImages = (images || []).filter(
    image => image.id || (image.tmpId && files[image.tmpId])
  );
  const filteredImages = getPhotos(displayableImages, tags, isUntaggedChecked ? null : activeTab);

  return (
    <SortableContainer
      className={classes}
      onSortStart={() => {
        // Close the iOS software keyboard if it's open because react-sortable-hoc gets the position
        // wrong when the keyboard is open
        document.activeElement.blur();
        setIsSorting(true);
      }}
      onSortEnd={({ oldIndex, newIndex }) => {
        setIsSorting(false);

        onUpdateImageOrder({ oldIndex, newIndex });
      }}
      lockAxis="y"
      helperClass={css.photoPreviewContainerIsSorting}
      useWindowAsScrollContainer
      useDragHandle
    >
      {filteredImages.map((image, index) => (
        <SortableItem
          index={index} // Used by react-sortable-hoc
          key={image.tmpId || image.id.uuid}
          image={image}
          imageTags={tags[(image?.id?.uuid)] || []}
          file={image.tmpId ? files[image.tmpId] : null}
          error={image.tmpId ? errors[image.tmpId] : null}
          savedImageAltText={savedImageAltText}
          savedImageCaptionPlaceholderText={savedImageCaptionPlaceholderText}
          onRemoveImage={onRemoveImage}
          onUpdateTags={tag => {
            onUpdateTags(image.id.uuid, tag);
          }}
          disableSorting={disableSorting}
        />
      ))}
    </SortableContainer>
  );
};

EditListingPhotoLibraryPreviews.defaultProps = { className: null, images: [] };

EditListingPhotoLibraryPreviews.propTypes = {
  images: array,
  files: object.isRequired,
  errors: object.isRequired,
  className: string,
  savedImageAltText: string.isRequired,
  savedImageCaptionPlaceholderText: string.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateTags: func.isRequired,
};

export default EditListingPhotoLibraryPreviews;
