import React from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { updateImageCaptions } from '../../util/listing';
import {
  ListingLink,
  EditListingPanel,
  EditListingPanelHeading,
  EditListingPanelSubheading,
  EditListingSection,
} from '..';
import css from './EditListingPhotosPanel.css';

const getInitialValues = listing => {
  const images = get(listing, 'images', []);
  const publicData = get(listing, 'attributes.publicData', {});
  const imageCaptions = get(publicData, 'imageCaptions', {});
  const imageTags = get(publicData, 'imageTags', {});

  const generalImages = images.filter(image => {
    if (Object.keys(imageTags).some(otherImage => otherImage === image.id.uuid)) {
      return false;
    }

    return true;
  });

  return {
    images: generalImages,
    imageCaptions,
  };
};

const getSubmissionValues = (listing, { images, imageCaptions }) => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentImages = get(listing, 'images', []);
  const currentImageCaptions = get(publicData, 'imageCaptions', {});
  const currentImageTags = get(publicData, 'imageTags', {});

  // Build a new map from `images` so that deleted images have their captions removed
  const newImageCaptions = images.reduce((acc, image) => {
    // Was this image captioned using it's tmpId or api id?
    if (image.tmpId && imageCaptions[image.tmpId]) {
      // tmpId used so swap to the api id
      acc[image.id.uuid] = imageCaptions[image.tmpId];
    } else if (imageCaptions[image.id.uuid]) {
      // api id used so stick with that
      acc[image.id.uuid] = imageCaptions[image.id.uuid];
    }

    return acc;
  }, {});

  const currentNonTagsImages = currentImages.filter(image => {
    const imageTagsIds = Object.keys(currentImageTags);

    if (!imageTagsIds.includes(image.id.uuid)) {
      return true;
    }

    return false;
  });

  const allImages = Object.keys(currentImageTags)
    .filter(otherImage => {
      const image = currentImages.find(currentImage => currentImage.id.uuid === otherImage);

      return !!image;
    })
    .map(otherImage => {
      const image = currentImages.find(currentImage => currentImage.id.uuid === otherImage);

      return image;
    })
    .concat(images);

  const updatedImageCaptions = updateImageCaptions(
    currentImageCaptions,
    newImageCaptions,
    currentNonTagsImages.map(image => image.id.uuid)
  );

  return {
    images: allImages,
    publicData: {
      imageCaptions: {
        ...updatedImageCaptions,
      },
    },
  };
};

const EditListingPhotosPanel = ({
  className,
  rootClassName,
  errors,
  listing,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  onChange,
  onSubmit,
  backLink,
}) => {
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPhotosPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth hasBottomMargin={false}>
        <EditListingPanelHeading withSubheading>{panelTitle}</EditListingPanelHeading>

        <EditListingPanelSubheading>
          <FormattedMessage id="EditListingPhotosPanel.subHeading" />
        </EditListingPanelSubheading>
      </EditListingSection>

      <EditListingPhotosForm
        initialValues={getInitialValues(listing)}
        className={css.form}
        fetchErrors={errors}
        listing={listing}
        onSubmit={values => onSubmit(getSubmissionValues(listing, values))}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        backLink={backLink}
      />
    </EditListingPanel>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  backLink: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  backLink: node,
};

export default EditListingPhotosPanel;
