/* eslint-disable no-param-reassign */
import React, { useMemo, useRef } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { ensureOwnListing, overrideGet } from '../../util/data';
import { FISH_ACTIVITY } from '../../marketplace-custom-config';
import { getInitialPackage } from '../../util/package';
import { EditListingPackagesSection, EditListingBaseExperiencePanel } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingFishExperienceForm, EditListingCreatePackageForm } from '../../forms';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const publicData = get(currentListing, 'attributes.publicData', {});
  const fish = get(publicData, 'activities.fish', {});
  const fishTypes = get(publicData, 'fishTypes', null);
  const landDetails = get(fish, 'landDetails', {});
  const methods = get(fish, 'methods', {});
  const amenities = get(fish, 'amenities', {});

  const fishBucket = {};

  if (fishTypes) {
    Object.keys(fishTypes).forEach(key => {
      if (typeof fishTypes[key] === 'object') {
        fishBucket[key] = {
          isActive: fishTypes[key].isActive,
        };

        if (key === 'other') {
          fishBucket[key].name = fishTypes[key].name;
        }
      }
    });
  }

  const initialValues = {
    description: get(fish, 'description', ''),
    waterSources: get(landDetails, 'waterSources', []),
    waterSourcesInfo: get(landDetails, 'waterSourcesInfo', null),
    allowsFly: get(methods, 'allowsFly', false),
    flyInfo: get(methods, 'flyInfo', null),
    allowsLure: get(methods, 'allowsLure', false),
    lureInfo: get(methods, 'lureInfo', null),
    allowsLiveBait: get(methods, 'allowsLiveBait', false),
    liveBaitInfo: get(methods, 'liveBaitInfo', null),
    allowsBow: get(methods, 'allowsBow', false),
    bowInfo: get(methods, 'bowInfo', null),
    hasKayaks: get(amenities, 'hasKayaks', false),
    kayaks: overrideGet(amenities, 'kayaks', 0),
    kayaksInfo: get(amenities, 'kayaksInfo', null),
    hasCanoes: get(amenities, 'hasCanoes', false),
    canoes: overrideGet(amenities, 'kayaks', 0),
    canoesInfo: get(amenities, 'canoesInfo', null),
    hasTackle: get(amenities, 'hasTackle', false),
    tackle: overrideGet(amenities, 'tackle', 0),
    tackleInfo: get(amenities, 'tackleInfo', null),
    hasFishingPoles: get(amenities, 'hasFishingPoles', false),
    fishingPoles: overrideGet(amenities, 'fishingPoles', 0),
    fishingPolesInfo: get(amenities, 'fishingPolesInfo', null),
    fish: fishBucket,
    images: [],
  };

  return initialValues;
};

const getSubmissionValues = (listing, values) => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentActivities = get(publicData, 'activities', {});

  // Land details
  const landDetails = {
    hasWaterSources: true,
    waterSources: values.waterSources || [],
    waterSourcesInfo: values.waterSourcesInfo,
  };

  // Methods
  const methods = {
    allowsFly: values.allowsFly,
    flyInfo: values.allowsFly ? values.flyInfo : null,
    allowsLure: values.allowsLure,
    lureInfo: values.allowsLure ? values.lureInfo : null,
    allowsLiveBait: values.allowsLiveBait,
    liveBaitInfo: values.allowsLiveBait ? values.liveBaitInfo : null,
    allowsBow: values.allowsBow,
    bowInfo: values.allowsBow ? values.bowInfo : null,
  };

  // Amenities
  const amenities = {
    hasKayaks: values.hasKayaks,
    kayaks: values.hasKayaks ? values.kayaks : 0,
    kayaksInfo: values.hasKayaks ? values.kayaksInfo : null,
    hasCanoes: values.hasCanoes,
    canoes: values.hasCanoes ? values.canoes : 0,
    canoesInfo: values.hasCanoes ? values.canoesInfo : null,
    hasTackle: values.hasTackle,
    tackle: values.hasTackle ? values.tackle : 0,
    tackleInfo: values.hasTackle ? values.tackleInfo : null,
    hasFishingPoles: values.hasFishingPoles,
    fishingPoles: values.hasFishingPoles ? values.fishingPoles : 0,
    fishingPolesInfo: values.hasFishingPoles ? values.fishingPolesInfo : null,
  };

  // Fishing
  const fishBucket = {};

  if (values.fish) {
    fishBucket.fishTypes = {};

    Object.keys(values.fish).forEach(key => {
      fishBucket.fishTypes[key] = {
        isActive: values.fish[key].isActive,
      };

      if (key === 'other') {
        fishBucket.fishTypes[key].name = values.fish[key].name;
      }
    });
  }

  const submissionValues = {
    // sending Base-Price to Flex Console as $0.00 (if landowner sends hunt/fish/access prices)
    price: { _sdkType: 'Money', amount: 0, currency: 'USD' },
    publicData: {
      ...fishBucket,
      activities: {
        ...currentActivities,
        [FISH_ACTIVITY]: {
          ...currentActivities[FISH_ACTIVITY],
          landDetails,
          methods,
          amenities,
        },
      },
    },
  };

  return submissionValues;
};

const EditListingFishExperiencePanel = props => {
  const {
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    intl,
  } = props;
  const updatedValuesRef = useRef({});
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingFishExperiencePanel.title" />
  ) : (
    <FormattedMessage id="EditListingFishExperiencePanel.createListingTitle" />
  );

  const initialValues = useMemo(() => {
    const values = getInitialValues(listing);

    return {
      ...values,
      ...updatedValuesRef.current,
    };
  }, [listing]);

  return (
    <EditListingBaseExperiencePanel
      title={panelTitle}
      listing={currentListing}
      activity={FISH_ACTIVITY}
    >
      {baseProps => {
        return (
          <>
            <EditListingFishExperienceForm
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                onSubmit(getSubmissionValues(currentListing, values, baseProps.packages));
              }}
              onUpdate={values => {
                // Keep the values of the child form. I had to use ref here to avoid too many state updates
                updatedValuesRef.current = values;
              }}
              onChange={onChange}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              backLink={backLink}
              listing={currentListing}
              packages={baseProps.packages}
              packageSection={
                <EditListingPackagesSection
                  onCreatePackageMode={baseProps.handleCreatePackage}
                  onEditPackage={baseProps.handleEditPackage}
                  onTogglePublishPackage={baseProps.handleTogglePublishPackage}
                  onDuplicatePackage={baseProps.handleUpdatePackage}
                  onUpdatePackage={baseProps.handleUpdatePackage}
                  onRemovePackage={baseProps.handleRemovePackage}
                  packages={baseProps.packages}
                  selectedPackage={baseProps.selectedPackage}
                  currentListing={currentListing}
                />
              }
            />

            <EditListingCreatePackageForm
              activity={FISH_ACTIVITY}
              initialValues={getInitialPackage(baseProps.selectedPackage, FISH_ACTIVITY)}
              onClose={() => {
                baseProps.setCreatePackageMode(false);
              }}
              currentListing={currentListing}
              active={baseProps.createPackageMode}
              onUpdatePackage={async values => {
                await baseProps.handleUpdatePackage(values);
              }}
              titlePlaceholder={intl.formatMessage({
                id: 'EditListingFishExperiencePanel.packageTitlePlaceholder',
              })}
              descriptionPlaceholder={intl.formatMessage({
                id: 'EditListingFishExperiencePanel.packageDescriptionPlaceholder',
              })}
              imageCategories={['wildlife', 'water-sources', 'more']}
              intl={intl}
              isOpen={baseProps.createPackageMode}
            />
          </>
        );
      }}
    </EditListingBaseExperiencePanel>
  );
};

EditListingFishExperiencePanel.defaultProps = {
  listing: null,
  backLink: null,
};

EditListingFishExperiencePanel.propTypes = {
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default injectIntl(EditListingFishExperiencePanel);
