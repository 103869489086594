/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { formatList } from '../../util/array';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LODGING_INFO } from '../../util/package';
import { ReactComponent as IconGarbage } from '../../assets/icons/ListingPage/garbage.svg';
import { ReactComponent as IconGenerator } from '../../assets/icons/ListingPage/generator.svg';
import { ReactComponent as IconSewage } from '../../assets/icons/ListingPage/sewage.svg';
import { ReactComponent as IconWater } from '../../assets/icons/ListingPage/water.svg';
import { ReactComponent as IconElectricity } from '../../assets/icons/ListingPage/electricity.svg';
import { ReactComponent as IconLevel } from '../../assets/icons/ListingPage/level.svg';
import { ReactComponent as IconParkingSurface } from '../../assets/icons/ListingPage/parking-surface.svg';
import { ReactComponent as IconRoadFilled } from '../../assets/icons/ListingPage/road-filled.svg';
import { ReactComponent as IconRuller } from '../../assets/icons/ListingPage/ruler.svg';
import { ReactComponent as IconRVFriendly } from '../../assets/icons/ListingPage/rv-friendly-solid.svg';
import { ReactComponent as IconSize } from '../../assets/icons/ListingPage/size.svg';
import { ReactComponent as IconCooking } from '../../assets/icons/ListingPage/cooking.svg';
import { ReactComponent as IconCampfire } from '../../assets/icons/ListingPage/campfire.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/ListingPage/icon-info.svg';
import { ReactComponent as IconRvAmenity } from '../../assets/icons/ListingPage/icon-rv-amenity-picnic-table.svg';
import HasTruncation from '../../components/Truncate/HasTruncation';
import {
  rvParkingOptions,
  rvElectricHookupOptions,
  rvSewageHookupOptions,
  garbageAreaOptions,
  rvRoadQualityOptions,
  rvAccessOptions,
  rvTypesOptions,
  tentCookingAreaOptions,
  rvAmenitiesOptions,
} from '../../marketplace-custom-config';

import css from './SectionLodgingOptions.css';

const getLabels = (key, options) => {
  const option = options.find(item => item.key === key);
  return option ? option.label : null;
};

const RVCampsite = ({ details, lodgingPrice, intl, index, hasSingleSite }) => {
  const [showMore, setShowMore] = useState(false);

  const price = lodgingPrice.price
    ? formatMoney(intl, JSON.parse(lodgingPrice.price, sdkTypes.reviver))
    : null;

  const parkingLabels = useMemo(
    () => formatList(details?.rv?.parking.map(item => getLabels(item, rvParkingOptions))),
    [details]
  );

  const roadQualityLabels = useMemo(
    () => formatList(details?.rv?.roadQuality.map(item => getLabels(item, rvRoadQualityOptions))),
    [details]
  );

  const accessLabels = useMemo(
    () => formatList(details?.rv?.access.map(item => getLabels(item, rvAccessOptions))),
    [details]
  );

  const typesLabels = useMemo(
    () => formatList(details?.rv?.vehicleTypes.map(item => getLabels(item, rvTypesOptions))),
    [details]
  );

  const electricLabels = useMemo(
    () =>
      formatList(details?.rv?.electricHookup.map(item => getLabels(item, rvElectricHookupOptions))),
    [details]
  );

  const sewageLabels = useMemo(
    () => formatList(details?.rv?.sewageHookup.map(item => getLabels(item, rvSewageHookupOptions))),
    [details]
  );

  const garbageLabels = useMemo(
    () => formatList(details?.rv?.garbage.map(item => getLabels(item, garbageAreaOptions))),
    [details]
  );

  const cookingAreaLabels = useMemo(
    () => formatList(details?.rv?.cookingArea.map(item => getLabels(item, tentCookingAreaOptions))),
    [details]
  );

  const amenities = useMemo(() => {
    const list = details?.rv?.amenities.map(item => getLabels(item, rvAmenitiesOptions));
    return list.length > 0 ? list : [];
  }, [details]);

  return (
    <div className={css.sectionDetails} id={`rv-${lodgingPrice.index}`}>
      <h3 className={css.sectionHeader}>
        <FormattedMessage id="ListingPage.lodgingRVSite" />

        {!hasSingleSite && <span> #{index}</span>}
        {details?.rv?.campsiteName && <span> {details?.rv?.campsiteName}</span>}

        {lodgingPrice.option === LODGING_INFO.OPTIONAL && (
          <span>
            {' '}
            | {price} <FormattedMessage id="ListingPage.lodgingPriceFieldPostfix" />
          </span>
        )}
      </h3>
      {/* Parking Surface */}
      {details?.rv?.parking.length > 0 && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconParkingSurface className={css.icon} />
            </div>

            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage
                  id="ListingPage.rvDetailsParkingTitle"
                  values={{ option: parkingLabels }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Surface Leveled */}
      {details?.rv?.isSurfaceLevel && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconLevel className={css.icon} />
            </div>

            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage
                  id="ListingPage.rvDetailsSurfaceLeveledTitle"
                  values={{ option: 'Yes' }}
                />
              </div>
            </div>
          </div>

          {details?.rv?.surfaceInfo && (
            <div className={css.sectionDetailsInfo}>{details?.rv?.surfaceInfo}</div>
          )}
        </div>
      )}
      {showMore && (
        <>
          {/* Road Quality */}
          {details?.rv?.roadQuality.length > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconRoadFilled className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsRoadQualityTitle"
                      values={{ option: roadQualityLabels }}
                    />
                    .{' '}
                    {details?.rv?.is4WDRequired ? (
                      <FormattedMessage id="ListingPage.rvDetails4WDRequired" />
                    ) : (
                      <FormattedMessage id="ListingPage.rvDetails4WDNotRequired" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* RV Slab Surface */}
          {details?.rv?.surfaceSizeWidth > 0 && details?.rv?.surfaceSizeLength > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconRuller className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsSlubSurfaceSize"
                      values={{
                        width: details?.rv?.surfaceSizeWidth,
                        length: details?.rv?.surfaceSizeLength,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* RVs Allowed */}
          {details?.rv?.roadQuality.length > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconRVFriendly className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsTypes"
                      values={{ option: typesLabels }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Vehicle Under */}
          {details?.rv?.maxLength > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconSize className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsVehicleLength"
                      values={{ length: details?.rv?.maxLength }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Site Accessed */}
          {details?.rv?.access.length > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconSize className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsSiteAccessed"
                      values={{ option: accessLabels }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Electric Hookup */}
          {details?.rv?.hasElectricHookup && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconElectricity className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsElectricHookupTitle"
                      values={{ option: electricLabels || 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.electricHookupInfo && (
                <div className={css.sectionDetailsInfo}>{details?.rv?.electricHookupInfo}</div>
              )}
            </div>
          )}

          {/* Water Hookup */}
          {details?.rv?.hasWaterHookup && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconWater className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsWaterHookupTitle"
                      values={{ option: details?.rv?.hasWaterHookup ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.waterHookupInfo && (
                <div className={css.sectionDetailsInfo}>{details?.rv?.waterHookupInfo}</div>
              )}
            </div>
          )}

          {/* Sewage Hookup */}
          {details?.rv?.hasSewageHookup && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconSewage className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsSewageHookupTitle"
                      values={{ option: details?.rv?.hasSewageHookup ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.hasSewageHookup.length > 0 && (
                <div className={css.sectionDetailsInfoOptions}>{sewageLabels}</div>
              )}

              {details?.rv?.sewageHookupInfo && (
                <div className={css.sectionDetailsInfo}>{details?.rv?.sewageHookupInfo}</div>
              )}
            </div>
          )}

          {/* Generators Allowed */}
          {details?.rv?.allowsGenerators && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconGenerator className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsGeneratorsTitle"
                      values={{ option: details?.rv?.allowsGenerators ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.generatorsInfo && (
                <div className={css.sectionDetailsInfo}>{details?.rv?.generatorsInfo}</div>
              )}
            </div>
          )}

          {/* Garbage Area */}
          {details?.rv?.hasGarbage && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconGarbage className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsGarbageTitle"
                      values={{ option: details?.rv?.hasGarbage ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.garbage.length > 0 && (
                <div className={css.sectionDetailsInfoOptions}>{garbageLabels}</div>
              )}

              {details?.rv?.garbageInfo && (
                <div className={css.sectionDetailsInfo}>{details?.rv?.garbageInfo}</div>
              )}
            </div>
          )}

          {/* Cooking Area */}
          {details?.rv?.hasCookingArea && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconCooking className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsCookingArea"
                      values={{ option: details?.rv?.hasCookingArea ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>

              {details?.rv?.cookingArea.length > 0 && (
                <div className={css.sectionDetailsInfoOptions}>{cookingAreaLabels}</div>
              )}
            </div>
          )}

          {/* Campfires Allowed */}
          {details?.rv?.areCampfiresAllowed && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconCampfire className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage
                      id="ListingPage.rvDetailsCampfiresAllowed"
                      values={{ option: details?.rv?.areCampfiresAllowed ? 'Yes' : 'No' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* rv Amenities */}
          {amenities.length > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconRvAmenity className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage id="ListingPage.rvDetailsSiteAmenities" />
                  </div>
                </div>
              </div>
              <div className={css.sectionListWrapper}>
                {amenities.map((item, amenitiesIndex) => (
                  <div key={`amenities-${index}-${amenitiesIndex}`} className={css.siteListItem}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {/* Additional Details */}
      {details?.rv?.info && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconInfo className={css.icon} />
            </div>
            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage id="ListingPage.rvDetailsAdditionalTitle" />
              </div>
            </div>
          </div>
          <div className={css.sectionInfoWrapper}>
            <HasTruncation onExpand={expanded => setShowMore(expanded)}>
              {details?.rv?.info}
            </HasTruncation>
          </div>
        </div>
      )}
    </div>
  );
};

export default RVCampsite;
