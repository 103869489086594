import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import { getLineItemsForSubtotal, getLineItemsTotal } from '../../util/transaction';

const LineItemSubTotalMaybe = props => {
  const { transaction, intl } = props;

  // all non-reversal, non-commission line items
  const subTotalLineItems = getLineItemsForSubtotal(transaction);
  // line totals of those line items combined
  const subTotal = getLineItemsTotal(subTotalLineItems, config.currency);

  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  return formattedSubTotal ? (
    <div className={css.subTotalLineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.subTotal" />
      </span>
      <span className={css.itemValue}>{formattedSubTotal}</span>
    </div>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
