import React from 'react';
import { func, bool } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import config from '../../config';
import { LandtrustPlusModal } from '..';

const EarlyAccessModal = ({ isOpen, onClose }) => {
  return (
    <LandtrustPlusModal
      isOpen={isOpen}
      onCloseModal={onClose}
      header={<FormattedMessage id="EarlyAccessModal.header" />}
      description={<FormattedMessage id="EarlyAccessModal.description" />}
      items={[
        <FormattedMessage id="EarlyAccessModal.item1" />,
        <FormattedMessage id="EarlyAccessModal.item2" />,
        <FormattedMessage id="EarlyAccessModal.item3" />,
      ]}
      onSuccess={() => {
        window.location.href = config.landtrustPlusUrl;
      }}
    />
  );
};

EarlyAccessModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default injectIntl(EarlyAccessModal);
