import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CountdownWithProgressBar } from '..';

import css from './EarlyAccessBar.css';

const EarlyAccessBar = ({ isEarlyAccess, userHasPremiumMembership, expirationTimestamp }) => {
  return (
    <div className={css.root}>
      {isEarlyAccess ? (
        <CountdownWithProgressBar
          label={
            userHasPremiumMembership ? (
              <>
                <span className={css.notificationLabelDesktop}>
                  <FormattedMessage id="PackagePage.earlyAccessPremium" />
                </span>
                <span className={css.notificationLabelMobile}>
                  <FormattedMessage id="PackagePage.earlyAccessPremiumShort" />
                </span>
              </>
            ) : (
              <FormattedMessage id="PackagePage.earlyAccessNonPremium" />
            )
          }
          expirationTimestamp={expirationTimestamp}
          containerClassName={css.container}
          contentClassName={css.content}
          progressBarClassName={css.progressBar}
        />
      ) : null}
    </div>
  );
};

export default EarlyAccessBar;
