/** This file is used by the node server and the browser code */

const LINE_ITEM_NIGHT = 'line-item/night';
const LINE_ITEM_DAY = 'line-item/day';
const LINE_ITEM_UNITS = 'line-item/units';
const LINE_ITEM_CUSTOMER_COMMISSION = 'line-item/customer-commission';
const LINE_ITEM_PROVIDER_COMMISSION = 'line-item/provider-commission';
const LINE_ITEM_PARTY_SIZE = 'line-item/party-size';
const LINE_ITEM_NEGOTIATION = 'line-item/negotiation';
const LINE_ITEM_SPECIAL_OFFER = 'line-item/special-offer';
const LINE_ITEM_LODGING_TAX = 'line-item/lodging-tax';
const LINE_ITEM_LODGING_FEE = 'line-item/lodging-fee';
const LINE_ITEM_CONSERVATION_DONATION = 'line-item/conservation-donation';
const LINE_ITEM_PROMO_CODE = 'line-item/promo-code';
const LINE_ITEM_INSURANCE = 'line-item/insurance';
const LINE_ITEMS = [LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION];
const CUSTOMER_COMMISSION_PERCENTAGE = 15;
// fallback default percentage
const PROVIDER_COMMISSION_PERCENTAGE = -20;
const INSURANCE_PERCENTAGE = 6.95;

// depracated but still needed for old transaction line-items
const LINE_ITEM_ACCESS_FEE = 'line-item/access-fee';
const LINE_ITEM_FISHING_FEE = 'line-item/fishing-fee';

const PAYMENT_INTENT_TYPES = {
  DEFAULT: 'default',
  BOOKING_CHANGES: 'bookingChanges',
};

const shouldExcludeFromSubtotal = lineItemCode => {
  return [
    LINE_ITEM_CUSTOMER_COMMISSION,
    LINE_ITEM_PROVIDER_COMMISSION,
    LINE_ITEM_CONSERVATION_DONATION,
    LINE_ITEM_LODGING_TAX,
    LINE_ITEM_PROMO_CODE,
    LINE_ITEM_INSURANCE,
  ].includes(lineItemCode);
};

module.exports = {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_NEGOTIATION,
  LINE_ITEM_PARTY_SIZE,
  LINE_ITEM_SPECIAL_OFFER,
  LINE_ITEM_LODGING_TAX,
  LINE_ITEM_LODGING_FEE,
  LINE_ITEM_CONSERVATION_DONATION,
  LINE_ITEM_PROMO_CODE,
  LINE_ITEM_INSURANCE,
  LINE_ITEM_ACCESS_FEE,
  LINE_ITEM_FISHING_FEE,
  LINE_ITEMS,
  CUSTOMER_COMMISSION_PERCENTAGE,
  PROVIDER_COMMISSION_PERCENTAGE,
  INSURANCE_PERCENTAGE,
  PAYMENT_INTENT_TYPES,
  shouldExcludeFromSubtotal,
};
