import React from 'react';

import Quotes from '../Quotes/Quotes';
import Rating from '../Rating/Rating';

import css from './TestimonialCard.css';

const TestimonialCard = testimonial => {
  const { quote, sportsman, memberStatus, image, stars } = testimonial.testimonial;

  return (
    <div className={css.containerStyle}>
      <div display="flex" flexDirection="column">
        <Quotes className={css.quotesStyles} variant="dark" />
        <div display="flex">
          <div className={css.reviewTextStyles}>{quote}</div>
        </div>
      </div>

      <div className={css.contentWrapper}>
        {image && (
          <div>
            <img src={image} alt="sportsman" className={css.sportsmanImage} />
          </div>
        )}

        <div className={css.sportsmanContainer}>
          <div classNames={css.sportsmanStyles}>
            <strong>{sportsman}</strong>
          </div>
          <div className={css.statusStyles}>{memberStatus}</div>
          <div>
            <Rating value={stars} size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
