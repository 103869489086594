import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
// TODO: move this logic to ProfileSettingsPage duck
import {
  createStripeSetupIntent,
  stripeCustomer as getStripeCustomer,
  loadData,
} from '../../containers/PaymentMethodsPage/PaymentMethodsPage.duck';
import { handleCardSetup, handleCancelMembershipSubscription } from '../../ducks/stripe.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { savePaymentMethod, deletePaymentMethod } from '../../ducks/paymentMethods.duck';
import { PaymentMethodsForm } from '../../forms';
import { SavedCardDetails, YourMembership } from '..';
import { ensureStripeCustomer, ensurePaymentMethodCard } from '../../util/data';
import { propTypes } from '../../util/types';
import css from './PaymentMethods.css';
import config from '../../config';

const stripePromise = loadStripe(config.stripe.publishableKey);

const PaymentMethods = ({
  currentUser,
  deletePaymentMethodInProgress,
  onCreateSetupIntent,
  onSavePaymentMethod,
  onDeletePaymentMethod,
  onHandleCancelMembershipSubscription,
  cancelMembershipSubscriptionInProgress,
  cancelMembershipSubscriptionError,
  fetchStripeCustomer,
  onManageDisableScrolling,
  stripeCustomerFetched,
  initData,
}) => {
  const [cardState, setCardState] = useState(null);
  const [setupIntent, setSetupIntent] = useState(null);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    fetchStripeCustomer();
    setCardState('default');
  };

  const handleRemovePaymentMethod = () => {
    onDeletePaymentMethod().then(() => {
      fetchStripeCustomer();
    });
  };

  const hasDefaultPaymentMethod =
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

  const card = hasDefaultPaymentMethod
    ? ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card
    : null;

  const showForm = cardState === 'replaceCard' || !hasDefaultPaymentMethod;
  const showCardDetails = !!hasDefaultPaymentMethod;

  useEffect(() => {
    const fetchSetupIntent = async () => {
      try {
        const response = await onCreateSetupIntent();
        setSetupIntent(response.attributes);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if ((!hasDefaultPaymentMethod || cardState === 'replaceCard') && !setupIntent) {
      fetchSetupIntent();
    }
  }, [hasDefaultPaymentMethod, setupIntent, onCreateSetupIntent, cardState]);

  const stripeOptions = {
    clientSecret: setupIntent ? setupIntent.clientSecret : null,
    appearance: config.stripe.appearance,
  };

  return (
    <div className={css.root}>
      <>
        <YourMembership
          onCancel={onHandleCancelMembershipSubscription}
          cancelInProgress={cancelMembershipSubscriptionInProgress}
          cancelError={cancelMembershipSubscriptionError}
          currentUser={currentUser}
        />

        <div className={css.divider} />
      </>

      {!stripeCustomerFetched ? null : (
        <>
          {showCardDetails ? (
            <SavedCardDetails
              card={card}
              onManageDisableScrolling={onManageDisableScrolling}
              onChange={setCardState}
              onDeleteCard={handleRemovePaymentMethod}
              deletePaymentMethodInProgress={deletePaymentMethodInProgress}
            />
          ) : null}
          {showForm && stripeOptions.clientSecret ? (
            <div className={css.paymentMethodFormWrapper}>
              <h3 className={css.cardHeading}>
                <FormattedMessage id="PaymentMethodsForm.billingHeading" />
              </h3>

              <Elements stripe={stripePromise} options={stripeOptions}>
                <PaymentMethodsForm
                  onSubmit={handleSubmit}
                  currentUser={currentUser}
                  onSavePaymentMethod={onSavePaymentMethod}
                />
              </Elements>
            </div>
          ) : null}
        </>
      )}

      <div className={css.helpWrapper}>
        <h2 className={css.helpHeader}>
          <FormattedMessage id="PaymentMethods.needHelp" />
        </h2>
        <div className={css.helpText}>
          <FormattedHTMLMessage id="PaymentMethods.callUs" />
        </div>
      </div>
    </div>
  );
};

PaymentMethods.defaultProps = {
  currentUser: null,
  deletePaymentMethodInProgress: false,
  onCreateSetupIntent: null,
  onSavePaymentMethod: null,
  onDeletePaymentMethod: null,
  onHandleCancelMembershipSubscription: null,
  fetchStripeCustomer: null,
  onManageDisableScrolling: null,
  stripeCustomerFetched: false,
  initData: null,
};
PaymentMethods.propTypes = {
  currentUser: propTypes.currentUser,
  deletePaymentMethodInProgress: bool,
  onCreateSetupIntent: func,
  onSavePaymentMethod: func,
  onDeletePaymentMethod: func,
  onHandleCancelMembershipSubscription: func,
  fetchStripeCustomer: func,
  onManageDisableScrolling: func,
  stripeCustomerFetched: bool,
  initData: func,
};
PaymentMethods.displayName = 'PaymentMethods';

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { stripeCustomerFetched } = state.PaymentMethodsPage;

  const {
    handleCardSetupError,
    cancelMembershipSubscriptionInProgress,
    cancelMembershipSubscriptionError,
  } = state.stripe;
  return {
    currentUser,
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
    cancelMembershipSubscriptionInProgress,
    cancelMembershipSubscriptionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  initData: () => dispatch(loadData()),
  fetchStripeCustomer: () => dispatch(getStripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  onHandleCancelMembershipSubscription: params =>
    dispatch(handleCancelMembershipSubscription(params)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(PaymentMethods));
