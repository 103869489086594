import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { parse, format } from 'date-fns';
import {
  getTransactionFromInquiry,
  checkPackageAvailabilty,
  isPackageOnWaitingList,
  isEarlyAccessAvailable,
} from '../../../util/package';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { updateWaitingList } from '../../../ducks/user.duck';
import { SectionPackages, PackageAvailabilityModal, EarlyAccessModal } from '../..';
import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';

import css from './InquiryWizardStepPackages.css';
import { hasPremiumMembership } from '../../../util/user';

const InquiryWizardStepPackages = ({
  listing,
  activity,
  form,
  onNext,
  currentUser,
  history,
  onUpdateWaitingList,
  updatingWaitingListInProgress,
  waitingList,
}) => {
  const userHasPremiumMembership = hasPremiumMembership(currentUser);

  const [isPackageAvailabilityModalOpen, setIsPackageAvailabilityModalOpen] = useState(false);
  const [isEarlyAccessModalOpen, setIsEarlyAccessModalOpen] = useState(false);
  const [availabilityDetails, setAvailabilityDetails] = useState(null);

  const landownerName = get(listing, 'author.attributes.profile.displayName', 'Landowner');
  const firstName = landownerName ? landownerName.split(' ')[0] : '';

  const handleSubmit = values => {
    const { selectedPackage } = values;

    const { availableDateFrom, id, earlyAccessExpiration } = selectedPackage;
    const isEarlyAccess = isEarlyAccessAvailable(earlyAccessExpiration);
    const isBlockedByEarlyAccess = !userHasPremiumMembership && isEarlyAccess;

    const { bookingRangeIsFuture, bookingRangeIsPast, isAvailable } = checkPackageAvailabilty(
      selectedPackage
    );

    if (!isAvailable || bookingRangeIsFuture || bookingRangeIsPast) {
      const isOnWaitingList = isPackageOnWaitingList(currentUser, id);
      const date = parse(availableDateFrom);
      const formattedPackageAvailableDate = format(date, 'MMMM D, YYYY');

      const outOfBookingRange = bookingRangeIsFuture || bookingRangeIsPast;

      setAvailabilityDetails({
        isOnWaitingList,
        packageId: id,
        isAvailable,
        outOfBookingRange,
        formattedPackageAvailableDate,
      });

      setIsPackageAvailabilityModalOpen(true);
    } else if (isBlockedByEarlyAccess) {
      setIsEarlyAccessModalOpen(true);
    } else {
      const transactionId = getTransactionFromInquiry(currentUser, values?.packageId);

      if (transactionId) {
        history.push(
          createResourceLocatorString('OrderPage', routeConfiguration(), {
            id: transactionId,
          })
        );
      } else {
        if (values?.selectedPackage) {
          form.change('selectedPackage', values.selectedPackage);

          // Reset species and dates, dont need it for the package
          form.change('species', undefined);
          form.change('bookingDates', undefined);
        }

        onNext(form.getState().values);
      }
    }
  };

  return (
    <InquiryWizardPanel>
      <EarlyAccessModal
        isOpen={isEarlyAccessModalOpen}
        onClose={() => {
          setIsEarlyAccessModalOpen(false);
        }}
      />

      <PackageAvailabilityModal
        id="package-pricing-availability-modal"
        isOpen={isPackageAvailabilityModalOpen}
        onClose={() => setIsPackageAvailabilityModalOpen(false)}
        isSoldOut={!availabilityDetails?.isAvailable}
        isOutOfBookingRange={availabilityDetails?.outOfBookingRange}
        packageAvailableDate={availabilityDetails?.formattedPackageAvailableDate}
        landownerName={landownerName}
        onNotify={async () => {
          try {
            await onUpdateWaitingList(availabilityDetails?.packageId, listing.id.uuid);
          } finally {
            setIsPackageAvailabilityModalOpen(true);
          }
        }}
        isFetching={updatingWaitingListInProgress}
        isOnWaitingList={waitingList.includes(availabilityDetails?.packageId)}
        isInquiry
      />

      <InquiryWizardSection>
        <div className={css.titleStyles}>
          <FormattedMessage id="InquiryWizard.packagesTitle" values={{ firstName }} />
        </div>
        <SectionPackages
          rootClassName={css.sectionPackages}
          listing={listing}
          onSubmit={handleSubmit}
          showDefaultPackages={Infinity}
          currentActivity={activity}
          customButtonLabel={<FormattedMessage id="InquiryWizard.choosePackage" />}
        />
      </InquiryWizardSection>
    </InquiryWizardPanel>
  );
};

InquiryWizardStepPackages.propTypes = {};

const mapStateToProps = state => {
  const { currentUser, updatingWaitingListInProgress } = state.user;

  const waitingList = get(currentUser, 'attributes.profile.publicData.waitingList', []).map(
    item => item.packageId
  );

  return {
    currentUser,
    updatingWaitingListInProgress,
    waitingList,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateWaitingList: (packageId, listingId) => dispatch(updateWaitingList(packageId, listingId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InquiryWizardStepPackages);
