/* eslint-disable no-nested-ternary */
/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemUnknownItemsMaybe = props => {
  const { transaction, intl } = props;

  // resolve unknown non-reversal line items

  const items = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );
  const unitsItem = items.find(({ code }) => code === 'line-item/units');
  const unitQuantityCheck = unitsItem && unitsItem.quantity && unitsItem.quantity.d;
  const numberOfDays = unitQuantityCheck ? unitsItem.quantity.d[0] : null;

  return items.length > 0 ? (
    <>
      {items.map(item => {
        const label = humanizeLineItemCode(item.code);
        const sizeFreeLabel = label.replace('size', '');
        const formattedTotal = formatMoney(intl, item.lineTotal);

        const partyItemLabel = unitQuantityCheck
          ? formattedTotal === '$0.00' && item.quantity.d[0] !== 0
            ? `Total ${sizeFreeLabel} Size`
            : formattedTotal === '$0.00' && item.quantity.d[0] === 0
            ? null
            : `Additional ${sizeFreeLabel} x ${item.quantity.d[0] / numberOfDays}`
          : null;
        const partyItemValue = unitQuantityCheck
          ? formattedTotal === '$0.00' && item.quantity.d[0] !== 0
            ? `x ${item.quantity.d[0]}`
            : formattedTotal === '$0.00' && item.quantity.d[0] === 0
            ? null
            : `+ ${formattedTotal}`
          : null;

        if (!partyItemLabel && !partyItemValue) {
          return null;
        }

        if (item.code === 'line-item/units') {
          // Dont render units subtotal as it is duplicate of total
          return null;
        }

        if (item.code === 'line-item/party-size') {
          return (
            <div key={`${item.code}`} className={css.lineItem}>
              <span className={css.itemLabel}>{partyItemLabel}</span>
              <span className={css.itemValue}>{partyItemValue}</span>
            </div>
          );
        }

        return null;
      })}
    </>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
