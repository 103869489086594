import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import { PrimaryButton, NamedLink } from '../../components';

import heroImage from './images/hero.jpg';
import css from './FAQSectionHero.css';
import { Container } from '../../components/Layout';

const FAQSectionHero = () => {
  return (
    <div className={css.root}>
      <Container>
        <div className={css.wrapper}>
          <div
            className={classNames(css.column, css.heroImageColumn)}
            style={{ backgroundImage: `url(${heroImage})` }}
          />
          <div className={classNames(css.column, css.contentColumn)}>
            <h1 className={css.title}>
              <FormattedMessage id="FAQLandownersPage.heroTitle" />
            </h1>

            <p>
              <FormattedHTMLMessage id="FAQLandownersPage.heroContent" />
            </p>

            <div className={css.cta}>
              <PrimaryButton component={NamedLink} name="NewListingPage" isSmall>
                <FormattedMessage id="FAQLandownersPage.heroCta" />
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

FAQSectionHero.propTypes = {};

export default FAQSectionHero;
