import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconClose, NamedLink } from '..';
import css from './LandownerBanner.css';

const LandownerBanner = ({ onClose }) => {
  return (
    <div className={css.root}>
      <FormattedMessage id="LandingPage.landownerBannerText" />{' '}
      <NamedLink name="LandownersPage" className={css.cta}>
        <FormattedMessage id="LandingPage.landownerBannerCTA" />
      </NamedLink>
      <button type="button" className={css.close} onClick={onClose}>
        <IconClose />
      </button>
    </div>
  );
};

export default LandownerBanner;
