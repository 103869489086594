import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton, NamedLink, SectionSteps, Step } from '../../components';

import { ReactComponent as IconMouseClick } from '../../assets/icons/mouse-click.svg';
import { ReactComponent as IconSchedule } from '../../assets/icons/schedule.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/check.svg';

const FAQSectionHowItWorks = () => {
  return (
    <SectionSteps
      bg="brown"
      title={<FormattedMessage id="FAQLandownersPage.howItWorks.title" />}
      description={<FormattedMessage id="FAQLandownersPage.howItWorks.body" />}
      cta={
        <PrimaryButton component={NamedLink} name="NewListingPage">
          <FormattedMessage id="FAQLandownersPage.howItWorks.cta" />
        </PrimaryButton>
      }
    >
      <>
        <Step
          icon={<IconMouseClick />}
          title={<FormattedMessage id="FAQLandownersPage.howItWorks.list.title" />}
        >
          <FormattedMessage id="FAQLandownersPage.howItWorks.list.body" />
        </Step>

        <Step
          icon={<IconSchedule width={48} height={47} />}
          title={<FormattedMessage id="FAQLandownersPage.howItWorks.decide.title" />}
        >
          <FormattedMessage id="FAQLandownersPage.howItWorks.decide.body" />
        </Step>

        <Step
          icon={<IconCheck />}
          title={<FormattedMessage id="FAQLandownersPage.howItWorks.welcome.title" />}
        >
          <FormattedMessage id="FAQLandownersPage.howItWorks.welcome.body" />
        </Step>
      </>
    </SectionSteps>
  );
};

FAQSectionHowItWorks.propTypes = {};

export default FAQSectionHowItWorks;
