import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '..';

import css from './ModalMissingInformation.css';
import barnImage from './images/barn.webp';
import StripeLogo from '../../assets/powered_by_stripe@2x.png';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield-light.svg';

const StripeAccountReminder = props => {
  const { className, onAction } = props;
  const classes = classNames(css.twoColumnContainer, className);

  return (
    <div className={classes}>
      <div
        className={classNames(css.firstColumn)}
        style={{ backgroundImage: `url(${barnImage})` }}
      />

      <div className={css.secondColumn}>
        <div className={css.innerContentContainer}>
          <div className={css.modalTitle}>
            <FormattedMessage id="ModalMissingInformation.missingStripeAccountTitle" />
          </div>

          <p className={css.modalMessage}>
            <FormattedMessage id="ModalMissingInformation.missingStripeAccountText" />
          </p>
          {/* TODO: updates this with copy */}
          <p>
            <a
              href="https://landtrust.com/contact"
              target="_blank"
              rel="noreferrer"
              className={css.ctaLink}
            >
              <FormattedMessage id="ModalMissingInformation.learnMoreAboutStripe" />
            </a>
          </p>

          <div className={css.bottomWrapper}>
            <div className={css.stripeCTAWrapper}>
              <div className={css.stripeCTA}>
                <NamedLink
                  className={css.reminderModalLinkButton}
                  name="ProfileSettingsPage"
                  params={{ tab: 'payout-details' }}
                  onClick={onAction}
                >
                  <ShieldIcon />
                  <FormattedMessage id="ModalMissingInformation.gotoPaymentSettings" />
                </NamedLink>
              </div>

              <div className={css.stripeLogoWrapper}>
                <img src={StripeLogo} alt="Powered by Stripe" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeAccountReminder;
