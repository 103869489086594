import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './BookingChanges.css';

const Header = ({
  hasCustomerRequestedChanges,
  isProvider,
  isCustomer,
  providedDisplayName,
  customerDisplayName,
}) => {
  if (isCustomer) {
    return (
      <div className={css.headerContainer}>
        <div className={css.headerText}>
          {hasCustomerRequestedChanges ? (
            <FormattedMessage
              id="BookingChanges.headerCustomerRequestedChanges"
              values={{
                name: providedDisplayName,
              }}
            />
          ) : (
            <FormattedMessage id="BookingChanges.headerCustomerRequestChanges" />
          )}
        </div>

        <div className={css.subheaderText}>
          {hasCustomerRequestedChanges ? (
            <FormattedMessage
              id="BookingChanges.subheaderCustomerRequestedChanges"
              values={{
                name: providedDisplayName,
              }}
            />
          ) : (
            <FormattedMessage
              id="BookingChanges.subheaderCustomerRequestChanges"
              values={{
                name: providedDisplayName,
              }}
            />
          )}
        </div>
      </div>
    );
  }

  if (isProvider) {
    return (
      <div className={css.headerContainer}>
        <div className={css.headerText}>
          <FormattedMessage
            id="BookingChanges.headerProviderRequestedChanges"
            values={{
              name: customerDisplayName,
            }}
          />
        </div>

        <div className={css.subheaderText}>
          <FormattedMessage
            id="BookingChanges.subheaderProviderRequestedChanges"
            values={{
              name: customerDisplayName,
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default Header;
