/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { node, bool } from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';
import css from './InfoTooltip.css';

const InfoTooltip = ({ header, content, showIcon, showCloseButton }) => {
  return (
    <Tooltip
      placement="auto"
      trigger="click"
      hideCloseButton={!showCloseButton}
      tooltip={
        <div>
          {header && (
            <h3 className={css.tooltipHeader}>
              {showIcon && <InfoIcon className={css.tooltipHeaderIcon} />}
              {header}
            </h3>
          )}

          <div className={css.tooltipContent}>{content}</div>
        </div>
      }
    >
      <a href="#" className={css.tooltipLink} onClick={e => e.preventDefault()}>
        <InfoIcon />
      </a>
    </Tooltip>
  );
};

InfoTooltip.defaultProps = {
  header: null,
  content: null,
  showIcon: true,
  showCloseButton: true,
};

InfoTooltip.propTypes = {
  header: node,
  content: node,
  showIcon: bool,
  showCloseButton: bool,
};

export default InfoTooltip;
