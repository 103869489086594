import React from 'react';
import { ForLandownersCard } from '..';

import css from './SectionForLandowners.css';

const SectionForLandowners = () => {
  return (
    <div className={css.root}>
      <ForLandownersCard />
    </div>
  );
};

SectionForLandowners.propTypes = {};

export default SectionForLandowners;
