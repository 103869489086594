import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import deerImage from './images/whitetail.png';
import turkeyImage from './images/turkey.png';

import css from './FAQSectionSpecies.css';
import { Container } from '../../components/Layout';

const FAQSectionSpecies = props => {
  return (
    <div className={css.root}>
      <Container>
        <div className={css.wrapper}>
          <div className={classNames(css.column, css.contentColumn)}>
            <h1 className={css.title}>
              <FormattedMessage id="FAQLandownersPage.speciesTitle" />
            </h1>

            <p className={css.description}>
              <FormattedMessage id="FAQLandownersPage.speciesContent" />
            </p>
          </div>
          <div className={classNames(css.column, css.imageColumn)}>
            <div className={css.imageInner}>
              <img src={turkeyImage} alt="" className={css.turkeyImage} />
              <img src={deerImage} alt="" className={css.deerImage} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

FAQSectionSpecies.propTypes = {};

export default FAQSectionSpecies;
