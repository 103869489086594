/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';
import {
  species,
  activitiesTypeMap,
  HUNT_ACTIVITY,
  OUTDOOR_RECREATION_ACTIVITY,
} from '../marketplace-custom-config';
import config from '../config';
import { ARRIVAL, PROPERTY } from './editListing';

export const PHOTO_LIBRARY_TABS = {
  ALL: 'all',
  PROPERTY: 'property',
  HUNT: 'hunt',
  FISH: 'fish',
  BIRD: 'bird',
  FARM: 'farm',
  ARRIVAL: 'arrival',
  OUTDOOR_RECREATION: 'outdoor_recreation',
};

export const tagsGroups = [
  {
    header: 'Categories',
    tags: [
      {
        key: PROPERTY,
        label: 'Property Landscape',
      },
      {
        key: HUNT_ACTIVITY,
        label: 'Hunt',
      },
      {
        key: OUTDOOR_RECREATION_ACTIVITY,
        label: 'Outdoor Recreation',
      },
      {
        key: ARRIVAL,
        label: 'Arrival Instructions',
      },
    ],
  },
  {
    header: 'Popular Species',
    requiredTags: [HUNT_ACTIVITY],
    tags: Object.keys(species.hunt)
      .filter(item => species.hunt[item].popular)
      .map(item => ({
        key: item,
        label: species.hunt[item].label,
      })),
  },
  {
    header: 'Other Species',
    requiredTags: [HUNT_ACTIVITY],
    tags: Object.keys(species.hunt)
      .filter(item => !species.hunt[item].popular)
      .map(item => ({
        key: item,
        label: species.hunt[item].label,
      })),
  },
  {
    header: 'Popular Activities',
    requiredTags: [OUTDOOR_RECREATION_ACTIVITY],
    tags: activitiesTypeMap.filter(activity => activity.popular),
  },
  {
    header: 'Other Activities',
    requiredTags: [OUTDOOR_RECREATION_ACTIVITY],
    tags: activitiesTypeMap.filter(activity => !activity.popular),
  },
];

export const flatTags = tagsGroups
  .reduce((acc, item) => {
    return acc.concat(item.tags);
  }, [])
  .reduce((acc, item) => {
    acc[item.key] = item.label;
    return acc;
  }, {});

export const sortImagesByOrder = (allImages, order) => {
  const orderMap = {};

  order.forEach((id, index) => {
    orderMap[id] = index;
  });

  // Sort the images based on the map.
  // If an image is not in order, it will get an index of Infinity,
  // which means it will be placed at the end of the sorted array.
  const sortedImages = [...allImages].sort((a, b) => {
    const indexA = orderMap[(a?.id?.uuid)] !== undefined ? orderMap[a.id.uuid] : Infinity;
    const indexB = orderMap[(b?.id?.uuid)] !== undefined ? orderMap[b.id.uuid] : Infinity;
    return indexA - indexB;
  });

  return sortedImages;
};

export const getImagesByTag = (currentListing, tag) => {
  const publicData = get(currentListing, 'attributes.publicData', {});
  const photoLibrary = get(publicData, 'photoLibraryTags', {});
  const images = get(currentListing, 'images', []);
  let sortedImages;

  switch (tag) {
    case PHOTO_LIBRARY_TABS.PROPERTY:
      {
        const propertyImagesOrder = get(publicData, 'propertyImagesOrder', []);

        sortedImages = sortImagesByOrder(images, propertyImagesOrder);
      }
      break;

    default:
      sortedImages = images;
  }

  return sortedImages.filter(image => {
    const { uuid } = image?.id || {};

    return photoLibrary[uuid]?.includes(tag);
  });
};

export const getPhotos = (images, tags, tab) => {
  switch (tab) {
    case PHOTO_LIBRARY_TABS.PROPERTY:
      return images.filter(image => {
        if (image.id && tags[image.id.uuid] && tags[image.id.uuid].includes(PROPERTY)) {
          return true;
        }

        return false;
      });

    case PHOTO_LIBRARY_TABS.HUNT:
      return images.filter(image => {
        if (
          image.id &&
          tags[image.id.uuid] &&
          tags[image.id.uuid].includes(config.custom.HUNT_ACTIVITY)
        ) {
          return true;
        }

        if (
          image.id &&
          tags[image.id.uuid] &&
          tags[image.id.uuid].some(tag => {
            return Object.keys(config.custom.species.hunt).includes(tag);
          })
        ) {
          return true;
        }

        return false;
      });

    case PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION:
      return images.filter(image => {
        if (
          image.id &&
          tags[image.id.uuid] &&
          tags[image.id.uuid].includes(config.custom.OUTDOOR_RECREATION_ACTIVITY)
        ) {
          return true;
        }

        return false;
      });

    case PHOTO_LIBRARY_TABS.ARRIVAL:
      return images.filter(image => {
        if (image.id && tags[image.id.uuid] && tags[image.id.uuid].includes(ARRIVAL)) {
          return true;
        }

        return false;
      });

    case PHOTO_LIBRARY_TABS.ALL:
      return images;

    default:
      return images.filter(image => {
        if (image.id && !tags[image.id.uuid]) {
          return true;
        }

        return false;
      });
  }
};

export const syncPhotoLibraryForSubmission = (listing, images, imageCaptions) => {
  // Build a new map from `images` so that deleted images have their captions removed
  const updatedImageCaptions = images.reduce((acc, image) => {
    // Was this image captioned using it's tmpId or api id?
    if (image.tmpId && imageCaptions[image.tmpId]) {
      // tmpId used so swap to the api id
      acc[image.id.uuid] = imageCaptions[image.tmpId];
    } else if (imageCaptions[image.id.uuid]) {
      // api id used so stick with that
      acc[image.id.uuid] = imageCaptions[image.id.uuid];
    }

    return acc;
  }, {});

  const imagesIds = images.map(image => image.id.uuid);

  // Sync packages images - deleted images from photo library will be removed from packages
  const updatePackages = get(listing, 'attributes.publicData.packages', []).map(packageInfo => {
    const packageImages = get(packageInfo, 'images', []);
    const filteredImages = packageImages.filter(imageId => imagesIds.includes(imageId));

    return {
      ...packageInfo,
      images: filteredImages,
    };
  });

  const arrivalInstructions = get(listing, 'attributes.publicData.arrivalInstructions', {});
  const arrivalInstructionsImages = get(arrivalInstructions, 'images', []).filter(imageId =>
    imagesIds.includes(imageId)
  );

  const updatedImages = imagesIds.map(imageId => ({
    id: imageId,
  }));

  return {
    updatedImages,
    updatePackages,
    updatedArrivalInstructions: {
      ...arrivalInstructions,
      images: arrivalInstructionsImages,
    },
    updatedImageCaptions,
  };
};
