import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './InquiryWizardPanel.css';

const InquiryWizardPanel = ({ rootClassName, className, children }) => {
  const classes = classNames(rootClassName || css.root, className);

  return <div className={classes}>{children}</div>;
};

InquiryWizardPanel.defaultProps = {
  className: null,
  rootClassName: null,
};

InquiryWizardPanel.propTypes = {
  className: string,
  rootClassName: string,
};

export default InquiryWizardPanel;
