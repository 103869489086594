import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { ReactComponent as IconBadge } from '../../assets/badge.svg';
import { ReactComponent as IconMoney } from '../../assets/icons/money.svg';
import { ReactComponent as IconShield } from '../../assets/icons/shield.svg';

import css from './FAQSectionBenefits.css';
import { Container } from '../../components/Layout';

const BenefitsCard = ({ icon, title, children }) => (
  <div className={css.cardRoot}>
    <div className={css.cardIconContainer}>{icon}</div>
    <div className={css.cardTitle}>{title}</div>
    <div className={css.cardBody}>{children}</div>
  </div>
);

const FAQSectionBenefits = props => {
  return (
    <div className={css.root}>
      <Container>
        <h1 className={css.title}>
          <FormattedMessage id="FAQLandownersPage.benefitsTitle" />
        </h1>

        <div className={css.cardsWrapper}>
          <div className={css.cardsInner}>
            <BenefitsCard
              icon={<IconBadge className={css.cardIcon} />}
              title={<FormattedMessage id="FAQLandownersPage.controlBenefit.title" />}
            >
              <FormattedHTMLMessage id="FAQLandownersPage.controlBenefit.body" />
            </BenefitsCard>

            <BenefitsCard
              icon={<IconMoney className={css.cardIcon} />}
              title={<FormattedMessage id="FAQLandownersPage.incomeBenefit.title" />}
            >
              <FormattedHTMLMessage id="FAQLandownersPage.incomeBenefit.body" />
            </BenefitsCard>

            <BenefitsCard
              icon={<IconShield className={css.cardIcon} />}
              title={<FormattedMessage id="FAQLandownersPage.insuranceBenefit.title" />}
            >
              <FormattedHTMLMessage id="FAQLandownersPage.insuranceBenefit.body" />
            </BenefitsCard>
          </div>
        </div>
      </Container>
    </div>
  );
};

FAQSectionBenefits.propTypes = {};

export default FAQSectionBenefits;
