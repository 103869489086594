import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { searchDesktopLayoutBreakpoint } from '../../marketplace-custom-config';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { SearchMap, Page } from '../../components';
import { TopbarContainer } from '..';
import { searchListings, searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      // const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  filters() {
    const {
      categoryFilterConfig,
      motFilterConfig,
      priceFilterConfig,
      dateRangeFilterConfig,
      keywordFilterConfig,
      gameTypeFilterConfig,
    } = this.props;

    // Note: "category" and "amenities" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      categoryFilter: {
        paramName: 'pub_categories',
        config: categoryFilterConfig,
      },
      motFilter: {
        paramName: 'pubMotTypes',
        config: motFilterConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeFilter: {
        paramName: 'dates',
        config: dateRangeFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      gameTypeFilter: {
        paramName: 'pub_gameTypeKeys',
        config: gameTypeFilterConfig,
      },
    };
  }

  render() {
    const {
      intl,
      listings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      isAuthenticated,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const { isSearchMapOpenOnMobile } = this.state;
    const filters = this.filters();

    const { pub_categories: pubCategories, pub_gameTypeKeys: gameTypeKeys } = searchParams || {};
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);
    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const categories = pubCategories ? pubCategories.split(',') : [];

    const gameTypeKeysParts = gameTypeKeys ? gameTypeKeys.split(':') : [];
    let species = [];

    if (gameTypeKeysParts.length) {
      species = gameTypeKeysParts[1].split(',');
    }

    // Hide prices for more than 1 activity, or for hunt activity with no species selected
    const hideListingPrice =
      (categories.length === 1 && categories[0] === 'hunt' && !gameTypeKeys) ||
      categories.length > 1 ||
      !categories.length;

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < searchDesktopLayoutBreakpoint;
    const shouldShowSearchList = !isMobileLayout || (isMobileLayout && !isSearchMapOpenOnMobile);
    const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

    const onToggleMap = () => {
      if (isSearchMapOpenOnMobile) {
        // If the user has been moving the map around they could be seeing new results. If so
        // when closing the map the lazy loaded images for the new results will not display. We
        // can trick them in to loading by firing an event we know they will be listening for
        try {
          window.dispatchEvent(new Event('resize'));
        } catch (e) {
          // ...
        }

        this.setState({ isSearchMapOpenOnMobile: false });
      } else {
        this.useLocationSearchBounds = true;
        this.setState({ isSearchMapOpenOnMobile: true });
      }
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div data-testid="search-page">
        <Page
          scrollingDisabled={scrollingDisabled}
          description={description}
          title={title}
          schema={schema}
          className={css.page}
        >
          <TopbarContainer
            className={css.topbar}
            currentPage="SearchPage"
            currentSearchParams={urlQueryParams}
            isFullWidth
            searchEnabled
            searchAlwaysVisible
            mapToggleVisible
            mapActive={shouldShowSearchMap}
            onToggleMap={onToggleMap}
          />
          <div className={css.container}>
            <MainPanel
              activities={categories}
              species={species}
              urlQueryParams={validQueryParams}
              hideListingPrice={hideListingPrice}
              listings={listings}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              searchParamsAreInSync={searchParamsAreInSync}
              onActivateListing={onActivateListing}
              onManageDisableScrolling={onManageDisableScrolling}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
              primaryFilters={{
                categoryFilter: filters.categoryFilter,
                motFilter: filters.motFilter,
                priceFilter: filters.priceFilter,
                dateRangeFilter: filters.dateRangeFilter,
                keywordFilter: filters.keywordFilter,
                gameTypeFilter: filters.gameTypeFilter,
              }}
              isHidden={!shouldShowSearchList}
              isAuthenticated={isAuthenticated}
            />
            <div className={css.mapPanel}>
              <div className={css.mapWrapper}>
                {shouldShowSearchMap ? (
                  <SearchMap
                    activities={categories}
                    species={species}
                    hideListingPrice={hideListingPrice}
                    reusableContainerClassName={css.map}
                    activeListingId={activeListingId}
                    bounds={bounds}
                    center={origin}
                    isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                    location={location}
                    listings={mapListings || []}
                    onMapMoveEnd={this.onMapMoveEnd}
                    onCloseAsModal={() => {
                      onManageDisableScrolling('SearchPage.map', false);
                    }}
                    messages={intl.messages}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Page>
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  motFilterConfig: config.custom.motFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  dateRangeFilterConfig: config.custom.dateRangeFilterConfig,
  categoryFilterConfig: config.custom.categoryFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  gameTypeFilterConfig: config.custom.gameTypeFilterConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']),
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
    active: bool.isRequired,
  }),
  motFilterConfig: shape({ active: bool.isRequired, motMap: array.isRequired }),
  categoryFilterConfig: shape({ active: bool.isRequired, categories: array }),
  dateRangeFilterConfig: shape({ active: bool.isRequired }),
  gameTypeFilterConfig: shape({ active: bool.isRequired }),
  keywordFilterConfig: shape({ active: bool.isRequired }),
  isAuthenticated: bool.isRequired,
  activeListingId: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;

  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    address,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  });
};

export default SearchPage;
