/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import { getBookingDates } from '../../util/transaction';
import { daysAdded } from '../../util/dates';
import { ReactComponent as CalendarFilterIcon } from '../../assets/icons/calendar-filter.svg';
import { SectionConservationDonation } from '..';

import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemPackageItem from './LineItemPackageItem';
import LineItemPartySizeMaybe from './LineItemPartySizeMaybe';
import LineItemSpecialOfferMaybe from './LineItemSpecialOfferMaybe';
import LineItemCustomerLodgingTaxMaybe from './LineItemCustomerLodgingTaxMaybe';
import LineItemConservationDonationMaybe from './LineItemConservationDonationMaybe';
import LineItemPromoCode from './LineItemPromoCode';

import css from './BookingBreakdown.css';

export const BookingBreakdownComponent = props => {
  const { rootClassName, className, userRole, unitType, transaction, intl, isSpecialOffer } = props;
  const protectedData = get(transaction, 'attributes.protectedData', null);
  const hasPackage = protectedData && protectedData.packageLineItem ? true : false;
  const packageTitle = get(transaction, 'attributes.protectedData.packageLineItem.title', null);

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;

    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const { bookingStart, bookingEnd } = getBookingDates(transaction);
  let bookingDateText = '';
  const isSingleDay = daysAdded(bookingStart, bookingEnd) === 1;

  if (bookingStart && bookingEnd) {
    bookingDateText = `${bookingStart.format('MMM DD')} - ${bookingEnd.format('MMM DD, YYYY')}`;
  }
  if (isSingleDay) {
    bookingDateText = bookingStart.format('MMM DD');
  }

  const subtitleSelector = () => {
    switch (true) {
      case hasPackage:
        return <FormattedMessage id="BookingBreakdown.pricingBreakdownPackageSmallText" />;

      case isSpecialOffer:
        return <FormattedMessage id="BookingBreakdown.pricingBreakdownPackageSmallText" />;

      default:
        return <FormattedMessage id="BookingBreakdown.pricingBreakdownSmallText" />;
    }
  };
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {hasPackage && (
        <>
          <div className={css.title}>
            <FormattedMessage id="BookingBreakdown.tripDates" />
          </div>

          <div className={css.datesWrapper}>
            <CalendarFilterIcon width="18" height="20" className={css.calendarIcon} />
            {bookingDateText}
          </div>
        </>
      )}
      <div className={css.title}>
        <FormattedMessage id="BookingBreakdown.pricingBreakdown" />
      </div>
      <div className={css.subtitle}>{subtitleSelector()}</div>
      {hasPackage && <div className={css.lineItem}>{packageTitle}</div>}
      <LineItemUnitPriceMaybe transaction={transaction} unitType={unitType} intl={intl} />
      {!hasPackage && <LineItemBookingPeriod transaction={transaction} />}
      {hasPackage ? (
        <LineItemPackageItem transaction={transaction} intl={intl} />
      ) : (
        <LineItemUnknownItemsMaybe transaction={transaction} intl={intl} />
      )}
      {!hasPackage && <LineItemPartySizeMaybe transaction={transaction} intl={intl} />}
      <LineItemSpecialOfferMaybe transaction={transaction} intl={intl} />
      <LineItemSubTotalMaybe
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <hr className={css.totalDivider} />
      <LineItemCustomerLodgingTaxMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />

      <LineItemRefundMaybe transaction={transaction} intl={intl} />
      <LineItemCustomerCommissionMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemProviderCommissionMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemConservationDonationMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemPromoCode transaction={transaction} isCustomer={isCustomer} intl={intl} />
      {hasCommissionLineItem ? (
        <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />
      ) : null}
      {hasCommissionLineItem && isProvider ? (
        <div className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNoteProvider" />
        </div>
      ) : null}

      {isCustomer && (
        <div className={css.conservationDonationContainer}>
          <SectionConservationDonation transaction={transaction} intl={intl} />
        </div>
      )}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
