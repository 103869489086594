/* eslint-disable prefer-destructuring */
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

export const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_ORDER_OR_SALE_SUCCESS = 'app/InboxPageV2/FETCH_ORDER_OR_SALE_SUCCESS';

// ================ Reducer ================ //

const mergeTransactionRefs = (existingRefs, newRefs) => {
  const refMap = new Map();

  existingRefs.forEach(ref => {
    const id = ref.id.uuid;
    refMap.set(id, ref);
  });

  newRefs.forEach(ref => {
    const id = ref.id.uuid;
    refMap.set(id, ref);
  });

  return Array.from(refMap.values());
};

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  transactionSalesRefs: [],
  transactionOrdersRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ORDER_OR_SALE_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };

      // Initialize sales and orders refs
      let transactionSalesRefs = state.transactionSalesRefs;
      let transactionOrdersRefs = state.transactionOrdersRefs;

      // Depending on the type, add the transaction to the appropriate refs
      if (payload.type === 'sales') {
        transactionSalesRefs = mergeTransactionRefs(
          state.transactionSalesRefs,
          [transactionRef] // Passing the single transactionRef as an array
        );
      } else if (payload.type === 'orders') {
        transactionOrdersRefs = mergeTransactionRefs(
          state.transactionOrdersRefs,
          [transactionRef] // Passing the single transactionRef as an array
        );
      }

      return {
        ...state,
        transactionSalesRefs,
        transactionOrdersRefs,
      };
    }

    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      const newTransactionRefs = entityRefs(transactions);

      // Deduplicate transactionRefs
      const transactionRefs = mergeTransactionRefs(state.transactionRefs, newTransactionRefs);

      // Initialize sales and orders refs
      let transactionSalesRefs = state.transactionSalesRefs;
      let transactionOrdersRefs = state.transactionOrdersRefs;

      if (payload.type === 'sales') {
        transactionSalesRefs = mergeTransactionRefs(state.transactionSalesRefs, newTransactionRefs);
      } else if (payload.type === 'orders') {
        transactionOrdersRefs = mergeTransactionRefs(
          state.transactionOrdersRefs,
          newTransactionRefs
        );
      }

      return {
        ...state,
        fetchInProgress: false,
        transactionRefs,
        transactionSalesRefs,
        transactionOrdersRefs,
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
export const initialFetch = () => (dispatch, getState, sdk) => {
  const salesPromise = sdk.transactions.query({
    only: 'sale',
    lastTransitions: TRANSITIONS,
  });

  const ordersPromise = sdk.transactions.query({
    only: 'order',
    lastTransitions: TRANSITIONS,
  });

  const responses = Promise.all([salesPromise, ordersPromise]);

  return responses.then(
    ([salesResponse, ordersResponse]) => {
      return {
        hasSales: salesResponse.data.meta.totalItems > 0,
        hasOrders: ordersResponse.data.meta.totalItems > 0,
      };
    },
    e => {
      throw e;
    }
  );
};
const INBOX_PAGE_SIZE = 20;

export const loadData = (params, page = 1) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: TRANSITIONS,
    include: [
      'customer',
      'customer.profileImage',
      'provider',
      'provider.profileImage',
      'booking',
      'listing',
      'listing.images',
      'reviews',
      'reviews.author',
      'reviews.subject',
      'messages.sender',
    ],

    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',
      'variants.square-small',
      'variants.square-small2x',
      'variants.facebook',
      'variants.twitter',
    ],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess({ ...response, type: tab }));

      const transactions = sortedTransactions(response.data.data);
      const transactionRefs = entityRefs(transactions);
      const { meta } = response.data;

      return {
        transactions: getMarketplaceEntities(getState(), transactionRefs),
        meta,
      };
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
