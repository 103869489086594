import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import joshImage from './images/josh.png';
import css from './CustomerExperienceCard.css';

const CustomerExperienceCard = ({
  rootClassName,
  className,
  isHorizontal,
  name,
  jobTitle,
  avatar,
  title,
  cta,
  children,
}) => {
  const classes = classNames(
    rootClassName || css.root,
    {
      [css.rootHorizontal]: isHorizontal,
    },
    className
  );

  return (
    <div className={classes}>
      <div className={css.leftColumn}>
        <div className={css.imageContainer}>
          <img src={avatar} alt={`${name}, ${jobTitle}`} className={css.image} />
        </div>
        <div className={css.name}>{name}</div>
        <div className={css.jobTitle}>{jobTitle}</div>
      </div>

      <div className={css.rightColumn}>
        <div className={css.title}>{title}</div>
        <div className={css.cta}>{cta}</div>
      </div>
      {children}
    </div>
  );
};

CustomerExperienceCard.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  /**
   * Adds an alternative view for Desktop screens. See Landowner page for details.
   */
  isHorizontal: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  jobTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  avatar: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cta: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CustomerExperienceCard.defaultProps = {
  className: null,
  rootClassName: null,
  isHorizontal: true,
  name: <FormattedMessage id="CustomerExperienceCard.name" />,
  jobTitle: <FormattedMessage id="CustomerExperienceCard.jobTitle" />,
  avatar: joshImage,
  title: <FormattedMessage id="CustomerExperienceCard.title" />,
  cta: <FormattedHTMLMessage id="CustomerExperienceCard.cta" />,
};

const MiniCustomerExperienceCardComponent = ({ rootClassName, className, cta }) => {
  const classes = classNames(rootClassName || css.miniRoot, className);

  return (
    <div className={classes}>
      <div className={css.cta}>{cta}</div>
    </div>
  );
};

MiniCustomerExperienceCardComponent.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  cta: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

MiniCustomerExperienceCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  cta: <FormattedHTMLMessage id="CustomerExperienceCard.miniCta" />,
};

CustomerExperienceCard.mini = MiniCustomerExperienceCardComponent;

export default CustomerExperienceCard;
