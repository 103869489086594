import React from 'react';
import { func, object, bool } from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { MyInterestsForm } from '../../forms';

const MyInterests = ({ onSubmit, updateInProgress, user }) => {
  const getInitialValues = () => {
    const publicData = user.attributes.profile.publicData || {};

    const currentUsState =
      !publicData.usState ||
      typeof publicData.usState === 'string' ||
      !publicData.usState.selectedPlace
        ? { search: '', selectedPlace: { origin: {} } }
        : publicData.usState;

    return {
      bio: user.attributes.profile.bio || '',
      usState: {
        search: currentUsState.search,
        selectedPlace: {
          ...currentUsState.selectedPlace,
          origin: new sdkTypes.LatLng(
            currentUsState.selectedPlace.origin.lat,
            currentUsState.selectedPlace.origin.lng
          ),
        },
      },
      interestedActivities: publicData.interestedActivities || [],
      preferredHuntingWeapons: publicData.preferredHuntingWeapons || [],
      preferredHuntingStates: publicData.preferredHuntingStates || [],
      preferredHuntingSpecies: publicData.preferredHuntingSpecies || [],
    };
  };

  const getSubmissionValues = values => {
    return {
      bio: values.bio,
      publicData: {
        usState: values.usState && values.usState.selectedPlace ? values.usState : null,
        interestedActivities: values.interestedActivities,
        preferredHuntingWeapons: values.preferredHuntingWeapons,
        preferredHuntingSpecies: values.preferredHuntingSpecies,
        preferredHuntingStates: values.preferredHuntingStates.map(state => {
          return {
            address: state.address,
          };
        }),
      },
    };
  };

  return (
    <MyInterestsForm
      initialValues={getInitialValues()}
      updateInProgress={updateInProgress}
      onSubmit={values => {
        onSubmit(getSubmissionValues(values));
      }}
    />
  );
};

MyInterests.defaultProps = {
  onSubmit: null,
  updateInProgress: false,
  user: null,
};
MyInterests.propTypes = {
  onSubmit: func,
  updateInProgress: bool,
  user: object,
};
MyInterests.displayName = 'MyInterests';

export default MyInterests;
