import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl } from 'react-intl';
import { NamedLink } from '..';
import { ReactComponent as IconChevronLeft } from '../../assets/icons/chevron-left.svg';
import css from './NamedBackLink.css';

const { string } = PropTypes;

export const NamedBackLink = ({ className, rootClassName, intl, ...namedLinkProps }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <NamedLink className={classes} {...namedLinkProps}>
      <IconChevronLeft className={css.icon} />
      {intl.formatMessage({ id: 'NamedBackLink.backLink' })}
    </NamedLink>
  );
};

NamedBackLink.defaultProps = {
  className: null,
  rootClassName: null,
};

NamedBackLink.propTypes = {
  className: string,
  rootClassName: string,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

export default injectIntl(NamedBackLink);
