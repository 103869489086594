import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { get } from 'lodash';
import InquirySuccessModal from '../../components/SuccessModals/InquirySuccessModal';
import BookingSuccessModal from '../../components/SuccessModals/BookingSuccessModal';
import Modal from '../../components/Modal/Modal';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction } from '../../util/data';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { txIsPaymentPending, checkUpdatedTransaction } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData, handleCardPayment } from '../../ducks/stripe.duck';
import { fetchCurrentUser, updateWaitingList, followSubject } from '../../ducks/user.duck';
import {
  NamedRedirect,
  TransactionPanel,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import {
  specialOfferOrder,
  acceptSpecialOffer,
  confirmSpecialOfferPayment,
  acceptSale,
  cancelSaleCustomerWithRefund,
  cancelSaleCustomerWithoutRefund,
  cancelSaleCustomerLate,
  cancelSaleProviderEarly,
  cancelSaleProvider,
  cancelSaleProviderLate,
  declineSale,
  declineSpecialOffer,
  cancelSpecialOffer,
  loadData,
  setInitialValues,
  sendMessage,
  fetchMoreMessages,
  updatePartyMemberDetails,
  fetchTransactionsTogether,
  requestChanges,
  acceptChanges,
  declineChanges,
} from './TransactionPage.duck';
import css from './TransactionPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    currentUserInit,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    cancelInProgress,
    cancelSaleError,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    declineSaleError,
    onSpecialOffer,
    onAcceptSale,
    onAcceptSpecialOffer,
    onCancelOffer,
    onConfirmSpecialOfferPayment,
    onCancelSaleCustomerWithRefund,
    onCancelSaleCustomerWithoutRefund,
    onCancelSaleCustomerLate,
    onCancelSaleProviderEarly,
    onCancelSaleProvider,
    onCancelSaleProviderLate,
    onDeclineSale,
    onDeclineSpecialOffer,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onHandleCardPayment,
    paymentIntent,
    handleCardPaymentError,
    initiateOrderError,
    confirmPaymentError,
    onUpdatePartyMemberDetails,
    followSubjectMutation,
    fetchUser,
    onFetchTransactionsTogether,
    currentUsersHaveOrdersTogether,
    updatingWaitingListInProgress,
    onUpdateWaitingList,
    onRequestChanges,
    requestedChangesInProgress,
    onAcceptChanges,
    acceptChangesInProgress,
    onDeclineChanges,
    declineChangesInProgress,
  } = props;
  const location = useLocation();
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);

  useEffect(() => {
    if (location.state?.showInquiryModal) {
      setIsInquiryModalOpen(true);
      history.replace({ ...location, state: { ...location.state, showInquiryModal: false } });
    }

    if (location.state?.showBookingModal) {
      setIsBookingModalOpen(true);
      history.replace({ ...location, state: { ...location.state, showBookingModal: false } });
    }
  }, [location, history]);

  const handleInquiryModalClose = () => {
    setIsInquiryModalOpen(false);
  };

  const handleBookingModalClose = () => {
    setIsBookingModalOpen(false);
  };
  const currentTransaction = useMemo(
    () => checkUpdatedTransaction(ensureTransaction(transaction)),
    [transaction]
  );
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  // eslint-disable-next-line no-unused-vars
  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    // eslint-disable-next-line no-shadow
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(currentTransaction) &&
    isCustomerRole &&
    currentTransaction.attributes.lineItems
  ) {
    const currentBooking = ensureListing(currentTransaction.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: currentTransaction,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };
    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  const handlePackageSubmit = values => {
    const { packageId } = values;
    const routes = routeConfiguration();

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    history.push(
      createResourceLocatorString(
        'PricingPackagePage',
        routes,
        {
          id: currentListing.id.uuid,
          slug: createSlug(currentListing.attributes.title),
          packageId,
        },
        {}
      ),
      { currentTransaction }
    );
  };

  const handleDiySubmit = () => {
    const routes = routeConfiguration();
    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    history.push(
      createResourceLocatorString(
        'PricingPage',
        routes,
        {
          id: currentListing.id.uuid,
          slug: createSlug(currentListing.attributes.title),
        },
        {}
      ),
      { currentTransaction }
    );
  };

  // TODO: combine with the logic in ListingPage util?
  const handleBookingSubmit = values => {
    const packageId = get(values, 'packageId', null);

    if (packageId) {
      handlePackageSubmit(values);
    } else {
      handleDiySubmit(values);
    }
  };

  // Provider can create a Special Offer booking,
  // if the tx is in "enquiry" state.
  const handleSubmitSpecialOfferRequest = values => {
    const { specialOfferCalendar, ...bookingData } = values;

    const initialValues = {
      listing: currentListing,
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: specialOfferCalendar.startDate,
        bookingEnd: specialOfferCalendar.endDate,
      },
      confirmPaymentError: null,
    };

    onSpecialOffer(initialValues, currentTransaction.id, currentTransaction);
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  }
  if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const onGoToReview = () => {
    const { id } = currentTransaction;
    const { uuid } = id;
    params.slug = createSlug(currentListing.attributes.title);
    const isSportsman = transactionRole === 'customer';
    const state = isSportsman
      ? {
          from: createResourceLocatorString('OrderPage', routeConfiguration(), params, {}),
        }
      : {
          from: createResourceLocatorString('SalePage', routeConfiguration(), params, {}),
        };
    return history.push(
      createResourceLocatorString(
        'ReviewPage',
        routeConfiguration(),
        { transactionRole, id: uuid },
        {}
      ),
      state
    );
  };
  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TransactionPanel
      className={detailsClassName}
      followSubjectMutation={followSubjectMutation}
      currentUser={currentUser}
      currentUserInit={currentUserInit}
      transaction={currentTransaction}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onAcceptSpecialOffer={onAcceptSpecialOffer}
      onConfirmSpecialOfferPayment={onConfirmSpecialOfferPayment}
      onHandleCardPayment={onHandleCardPayment}
      onDeclineSale={onDeclineSale}
      onDeclineSpecialOffer={onDeclineSpecialOffer}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      cancelInProgress={cancelInProgress}
      acceptSaleError={acceptSaleError}
      declineSaleError={declineSaleError}
      cancelSaleError={cancelSaleError}
      onCancelOffer={onCancelOffer}
      onCancelSaleCustomerWithRefund={onCancelSaleCustomerWithRefund}
      onCancelSaleCustomerWithoutRefund={onCancelSaleCustomerWithoutRefund}
      onCancelSaleCustomerLate={onCancelSaleCustomerLate}
      onCancelSaleProviderEarly={onCancelSaleProviderEarly}
      onCancelSaleProvider={onCancelSaleProvider}
      onCancelSaleProviderLate={onCancelSaleProviderLate}
      nextTransitions={processTransitions}
      onSubmitSpecialOffer={handleSubmitSpecialOfferRequest}
      onSubmitBookingRequest={handleBookingSubmit}
      handleCardPaymentError={handleCardPaymentError}
      paymentIntent={paymentIntent}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      initiateOrderError={initiateOrderError}
      confirmPaymentError={confirmPaymentError}
      onGoToReview={onGoToReview}
      onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
      handleBookingSubmit={handleBookingSubmit}
      fetchUser={fetchUser}
      onFetchTransactionsTogether={onFetchTransactionsTogether}
      currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
      onUpdateWaitingList={onUpdateWaitingList}
      updatingWaitingListInProgress={updatingWaitingListInProgress}
      onRequestChanges={onRequestChanges}
      requestedChangesInProgress={requestedChangesInProgress}
      onAcceptChanges={onAcceptChanges}
      acceptChangesInProgress={acceptChangesInProgress}
      onDeclineChanges={onDeclineChanges}
      declineChangesInProgress={declineChangesInProgress}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <div className={css.topbarContainer}>
              <TopbarContainer showMissingInfoModal={false} />
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>{panel}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter className={css.footer}>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        {isBookingModalOpen && (
          <Modal
            id="BookingSuccessModal"
            containerClassName={css.customModal}
            customCloseButton={css.customCloseButton}
            isOpen={isBookingModalOpen}
            onClose={handleBookingModalClose}
          >
            <div className={css.modalContent}>
              <BookingSuccessModal user={currentUser} />
            </div>
          </Modal>
        )}
        {isInquiryModalOpen && (
          <Modal
            id="InquirySuccessModal"
            containerClassName={css.customModal}
            customCloseButton={css.customCloseButton}
            isOpen={isInquiryModalOpen}
            onClose={handleInquiryModalClose}
          >
            <div className={css.modalContent}>
              <InquirySuccessModal user={currentUser} />
            </div>
          </Modal>
        )}
      </>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  initiateOrderError: null,
  confirmPaymentError: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number, oneOfType, object } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string,
  }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  cancelInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onUpdatePartyMemberDetails: func.isRequired,
  onAcceptSpecialOffer: func.isRequired,
  onConfirmSpecialOfferPayment: func.isRequired,
  onCancelOffer: func.isRequired,
  onDeclineSale: func.isRequired,
  onDeclineSpecialOffer: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onHandleCardPayment: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  onRequestChanges: func.isRequired,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  // eslint-disable-next-line react/require-default-props
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    cancelInProgress,
    cancelSaleError,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    paymentIntent,
    initiateOrderError,
    confirmPaymentError,
    fetchTransactionsTogetherError,
    currentUsersHaveOrdersTogether,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
  } = state.TransactionPage;

  const { currentUser, updatingWaitingListInProgress, currentUserInit } = state.user;
  const { handleCardPaymentError } = state.stripe;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  return {
    currentUser,
    currentUserInit,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    cancelSaleError,
    cancelInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    paymentIntent,
    handleCardPaymentError,
    initiateOrderError,
    confirmPaymentError,
    fetchTransactionsTogetherError,
    currentUsersHaveOrdersTogether,
    updatingWaitingListInProgress,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSpecialOffer: (params, transactionId, currentTransaction) =>
      dispatch(specialOfferOrder(params, transactionId, currentTransaction)),
    onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
    onAcceptSpecialOffer: (params, transactionId) =>
      dispatch(acceptSpecialOffer(params, transactionId)),
    onConfirmSpecialOfferPayment: transactionId =>
      dispatch(confirmSpecialOfferPayment(transactionId)),
    onHandleCardPayment: params => dispatch(handleCardPayment(params)),
    followSubjectMutation: (userId, subjectId, notifyBy) =>
      dispatch(followSubject(userId, subjectId, notifyBy)),
    onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
    onDeclineSpecialOffer: transactionId => dispatch(declineSpecialOffer(transactionId)),
    onCancelOffer: transactionId => dispatch(cancelSpecialOffer(transactionId)),
    onCancelSaleCustomerWithRefund: transactionId =>
      dispatch(cancelSaleCustomerWithRefund(transactionId)),
    onCancelSaleCustomerWithoutRefund: transactionId =>
      dispatch(cancelSaleCustomerWithoutRefund(transactionId)),
    onCancelSaleCustomerLate: transactionId => dispatch(cancelSaleCustomerLate(transactionId)),
    onCancelSaleProviderEarly: transactionId => dispatch(cancelSaleProviderEarly(transactionId)),
    onCancelSaleProvider: transactionId => dispatch(cancelSaleProvider(transactionId)),
    onCancelSaleProviderLate: transactionId => dispatch(cancelSaleProviderLate(transactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    // eslint-disable-next-line no-shadow
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onUpdatePartyMemberDetails: (values, transaction) =>
      dispatch(updatePartyMemberDetails(values, transaction)),
    fetchUser: () => dispatch(fetchCurrentUser()),
    onFetchTransactionsTogether: id => dispatch(fetchTransactionsTogether(id)),
    onUpdateWaitingList: (packageId, listingId) =>
      dispatch(updateWaitingList(packageId, listingId)),
    onRequestChanges: (params, transactionId) => dispatch(requestChanges(params, transactionId)),
    onAcceptChanges: transactionId => dispatch(acceptChanges(transactionId)),
    onDeclineChanges: transactionId => dispatch(declineChanges(transactionId)),
  };
};

const TransactionPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
