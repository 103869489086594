/* eslint-disable no-shadow */
import React from 'react';
import { bool, func, shape, string, node, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { required, atLeast, composeValidators } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingFieldWrapper,
  EditListingSection,
  EditListingSectionHeading,
  FieldTextInput,
  FieldNumberInput,
  FieldToggle,
  FieldCondition,
  FieldPillInputGroup,
  FormValidationTooltip,
} from '../../components';
import { wildlifeWaterSourcesOptions } from '../../marketplace-custom-config';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingFishExperienceForm.css';

const EditListingFishExperienceFormComponent = ({ backLink, listing, onUpdate, ...formProps }) => {
  return (
    <FinalForm
      {...formProps}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          packageSection,
          errors,
          packages,
        } = fieldRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || (packages && !packages.length);

        const allErrors = {
          ...errors,
        };

        if (packages && !packages.length) {
          allErrors.packages = intl.formatMessage({
            id: 'EditListingFishExperienceForm.packagesRequired',
          });
        }

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={props => {
                onUpdate(props.values);
              }}
            />

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingFishExperienceForm.waterSourcesLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldPillInputGroup
                  name="waterSources"
                  id="waterSources"
                  options={wildlifeWaterSourcesOptions}
                />
              </EditListingFieldWrapper>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="waterSourcesInfo"
                  name="waterSourcesInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingFishExperienceForm.tellMoreWaterSources',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection>
              <EditListingSectionHeading>
                <FormattedMessage id="EditListingFishExperienceForm.amenitiesLabel" />
              </EditListingSectionHeading>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishExperienceForm.kayaksLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasKayaks" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasKayaks" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="kayaks"
                    id="kayaks"
                    label={intl.formatMessage({
                      id: 'EditListingFishExperienceForm.kayaksCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="kayaksInfo"
                    name="kayaksInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishExperienceForm.canoesLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasCanoes" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasCanoes" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="canoes"
                    id="canoes"
                    label={intl.formatMessage({
                      id: 'EditListingFishExperienceForm.canoesCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="canoesInfo"
                    name="canoesInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishExperienceForm.tackleLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasTackle" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasTackle" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="tackle"
                    id="tackle"
                    label={intl.formatMessage({
                      id: 'EditListingFishExperienceForm.tackleCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="tackleInfo"
                    name="tackleInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingFishExperienceForm.fishingPolesLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasFishingPoles" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasFishingPoles" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="fishingPoles"
                    id="fishingPoles"
                    label={intl.formatMessage({
                      id: 'EditListingFishExperienceForm.fishingPolesCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="fishingPolesInfo"
                    name="fishingPolesInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            {packageSection}

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                {Object.keys(allErrors).length > 0 ? (
                  <FormValidationTooltip
                    placement="top"
                    body={
                      <ul>
                        {Object.keys(allErrors).map(field => (
                          <li key={`tooltip-field-${field}`}>{allErrors[field]}</li>
                        ))}
                      </ul>
                    }
                    title={
                      <FormattedMessage id="EditListingHuntExperienceForm.validationTooltipTitle" />
                    }
                  >
                    <PrimaryButton type="button" disabled>
                      {saveActionMsg}
                    </PrimaryButton>
                  </FormValidationTooltip>
                ) : (
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </PrimaryButton>
                )}
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingFishExperienceFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingFishExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  listing: object.isRequired,
  packageSection: node.isRequired,
  packages: array.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingFishExperienceFormComponent));
