import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createSlug } from '../../util/urlHelpers';
import { Breadcrumbs, NamedLink } from '..';

import css from './PackagesBreadcrumbs.css';

const PackagesBreadcrumbs = ({ params, listing, step, isDesktop }) => {
  const packageId = params?.packageId && params?.packageId;

  const breadcrumbs = [
    <NamedLink
      className={css.breadcrumbLink}
      name="PackagePage"
      params={{
        id: listing?.id?.uuid,
        slug: createSlug(listing?.attributes?.title),
        packageId: packageId && packageId,
      }}
    >
      <FormattedMessage id="PackagesBreadcrumbs.Package" />
    </NamedLink>,
  ];

  switch (step) {
    case 'pricing':
      breadcrumbs.push(<FormattedMessage id="PackagesBreadcrumbs.Guests" />);
      break;

    case 'checkout':
      if (!isDesktop) {
        breadcrumbs.push(
          <NamedLink
            className={css.breadcrumbLink}
            name="PricingPackagePage"
            params={{
              id: listing?.id?.uuid,
              slug: createSlug(listing?.attributes?.title),
              packageId,
            }}
          >
            <FormattedMessage id="PackagesBreadcrumbs.Guests" />
          </NamedLink>
        );
      }
      breadcrumbs.push(<FormattedMessage id="PackagesBreadcrumbs.Review" />);
      break;

    default:
  }

  return (
    <div className={css.statusTitle}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </div>
  );
};

PackagesBreadcrumbs.propTypes = {
  params: PropTypes.object.isRequired,
  listing: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
};

export default PackagesBreadcrumbs;
