import React from 'react';
import { bool, number, node } from 'prop-types';
import classNames from 'classnames';
import css from './FilterButton.css';

const FilterButton = ({ maxWidth, isSelected, icon, children, ...buttonProps }) => {
  const styles = maxWidth ? { maxWidth: `${maxWidth}px` } : {};
  const classes = classNames(css.button, {
    [css.buttonEllipsis]: !!maxWidth,
    [css.buttonSelected]: isSelected,
  });

  const styledIcon = icon
    ? React.cloneElement(icon, {
        className: classNames(css.icon, icon.props.className),
      })
    : null;

  return (
    <button type="button" className={classes} style={styles} {...buttonProps}>
      {styledIcon}
      {children}
    </button>
  );
};

FilterButton.defaultProps = {
  maxWidth: null,
  isSelected: false,
  icon: null,
};

FilterButton.propTypes = {
  maxWidth: number,
  isSelected: bool,
  icon: node,
};

export default FilterButton;
