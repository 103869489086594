import React from 'react';
import { FormattedMessage } from 'react-intl';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionBookingStart = ({ transition, buttons }) => {
  return (
    <InboxTransitionBase
      header={<FormattedMessage id="BookingStatus.transitionBookingStart" />}
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    />
  );
};

export default InboxTransitionBookingStart;
