// ================ Action types ================ //

export const UPDATE_LISTING_REQUEST = 'app/user/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/user/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/user/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  updatingListingError: null,
  updatingListingInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updatingListingInProgress: true,
        updatingListingError: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updatingListingInProgress: false };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        updatingListingInProgress: false,
        updatingListingError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const updateListingRequest = () => ({ type: UPDATE_LISTING_REQUEST });

export const updateListingSuccess = (listingId, data) => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: { id: listingId, data },
});

export const updateListingError = error => ({
  type: UPDATE_LISTING_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const updateListing = (listingId, data) => (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());

  return sdk.ownListings
    .update({ id: listingId, ...data }, {})
    .then(() => dispatch(updateListingSuccess(listingId, data)))
    .catch(error => updateListingError(error));
};
