import React from 'react';
import { bool, node } from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../../util/currency';

import {
  txIsCancelled,
  txIsDelivered,
  txIsDeclined,
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_GATED,
} from '../../../util/transaction';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import css from './LineItems.css';

const { Money } = sdkTypes;

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl, totalLabel } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCancelled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCancelled';
  }

  const label =
    totalLabel ||
    (isProvider ? (
      <FormattedMessage id={providerTotalMessageId} />
    ) : (
      <FormattedMessage id="BookingBreakdown.total" />
    ));

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = totalPrice && formatMoney(intl, totalPrice);

  const specialOfferTotalPrice = transaction.attributes.lineItems.find(item => {
    return item.code === LINE_ITEM_PROVIDER_COMMISSION;
  });

  const specialOfferAmountMoneyTotal = specialOfferTotalPrice
    ? formatMoney(
        intl,
        new Money(
          transaction.attributes.payoutTotal.amount + specialOfferTotalPrice.lineTotal.amount,
          'USD'
        )
      )
    : formatMoney(intl, new Money(0, 'USD'));

  const specialOfferCommission =
    get(transaction, 'attributes.lastTransition') ===
    (TRANSITION_ENQUIRE || TRANSITION_ENQUIRE_GATED)
      ? specialOfferAmountMoneyTotal
      : formattedTotalPrice;

  return (
    <div className={css.lineItem}>
      <div className={css.totalLabel}>{label}</div>
      <div className={css.totalPrice}>{specialOfferCommission}</div>
    </div>
  );
};

LineItemUnitPrice.defaultProps = {
  totalLabel: null,
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  totalLabel: node,
};

export default LineItemUnitPrice;
