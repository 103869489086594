/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import css from './ListingPage.css';
import Modal from '../../components/Modal/Modal';
import HasTruncation from '../../components/Truncate/HasTruncation';

const SectionDescriptionMaybe = ({ description, descriptionTitle }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const formattedDescription =
    description &&
    (/<\/?[a-z][\s\S]*>/i.test(description)
      ? description
      : description.trim().replace(/\n/g, '<br />'));

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return description ? (
    <>
      <div>
        <div className={css.sectionDescription}>
          {descriptionTitle && <div className={css.descriptionHeader}>{descriptionTitle}</div>}

          <div className={css.descriptionWrapper}>
            <HasTruncation onShowMore={handleOpenModal}>
              <div
                dangerouslySetInnerHTML={{
                  __html: formattedDescription,
                }}
              />
            </HasTruncation>
          </div>
        </div>
      </div>
      <Modal
        id="ListingPage.huntModalss"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(!isModalOpen)}
        contentClassName={css.modalContent}
        containerClassName={css.containerStyles}
      >
        <div className={css.contentWrapper}>
          <div className={css.contentHeader}>
            <FormattedMessage id="PackagePage.descriptionTitle" />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </Modal>
    </>
  ) : null;
};

export default SectionDescriptionMaybe;
