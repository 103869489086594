import React, { useState, useEffect } from 'react';
import { string, node, bool, func } from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';
import classNames from 'classnames';
import css from './FavoritePackageTooltip.css';

const FavoritePackageTooltip = ({
  children,
  tooltip,
  className,
  tooltipShown,
  onTooltipShown,
  modifiers = [],
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipReady, setTooltipReady] = useState(false);

  useEffect(() => {
    if (tooltipShown !== undefined) {
      setIsVisible(tooltipShown);
    }
  }, [tooltipShown]);
  // wait to calculate the position of the tooltip :: Update in future
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setTooltipReady(true);
      });
      return () => clearTimeout(timer);
    }

    setTooltipReady(false);
    return () => {};
  }, [isVisible]);

  return (
    <TooltipTrigger
      {...props}
      strategy="fixed"
      tooltipShown={isVisible}
      onVisibilityChange={visible => {
        setIsVisible(visible);

        if (onTooltipShown) {
          onTooltipShown(visible);
        }
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        ...modifiers,
      ]}
      trigger=""
      tooltip={({ tooltipRef, getTooltipProps }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(css.root, tooltipReady ? css.tooltipVisible : ''),
          })}
        >
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          style={{ cursor: 'pointer' }}
          {...getTriggerProps({
            ref: triggerRef,
            className,
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

FavoritePackageTooltip.defaultProps = {
  children: null,
  tooltip: null,
  className: null,
  rootClassName: null,
  hideCloseButton: false,
  onGotItClick: null,
  hideArrow: false,
};

FavoritePackageTooltip.propTypes = {
  children: node,
  tooltip: node,
  className: string,
  onGotItClick: func,
  rootClassName: string,
  hideCloseButton: bool,
  hideArrow: bool,
};

export default FavoritePackageTooltip;
