import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ProgressBar.css';

const { string, number } = PropTypes;

export const ProgressBar = props => {
  const { className, rootClassName, progress } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.bar} style={{ transform: `translateX(-${100 - progress}%)` }} />
    </div>
  );
};

ProgressBar.defaultProps = {
  className: '',
  rootClassName: '',
  progress: 0,
};

ProgressBar.propTypes = {
  className: string,
  rootClassName: string,
  progress: number,
};

export default ProgressBar;
