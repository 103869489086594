/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import { LINE_ITEM_LODGING_TAX, propTypes } from '../../../util/types';

import css from './LineItems.css';

const LineItemCustomerLodgingTaxMaybe = props => {
  const { transaction, isCustomer, intl, onClick } = props;

  const customerLodgingTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_LODGING_TAX
  );
  let lodgingTaxItem = null;

  if (isCustomer && customerLodgingTaxLineItem) {
    const tax = customerLodgingTaxLineItem.lineTotal;
    const formattedTax = tax ? formatMoney(intl, tax) : null;

    lodgingTaxItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
          <FormattedMessage id="LineItemCustomerLodgingTax.lodgingTax" />
        </span>
        <span className={css.itemValue}>+{formattedTax}</span>
      </div>
    );
  }

  return lodgingTaxItem;
};

LineItemCustomerLodgingTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
  onClick: func.isRequired,
};

export default LineItemCustomerLodgingTaxMaybe;
