import React from 'react';
import { injectIntl } from 'react-intl';
import SectionLodgingItems from './SectionLodgingItems';

const SectionAddons = ({ currentListing, params }) => {
  return (
    <div>
      <SectionLodgingItems params={params} currentListing={currentListing} />
    </div>
  );
};

export default injectIntl(SectionAddons);
