import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as ConservationIcon } from '../../assets/icons/conservation.svg';

import css from './FAQSectionConservation.css';
import { Container } from '../../components/Layout';

const FAQSectionConservation = props => {
  return (
    <Container>
      <div className={css.root}>
        <div className={css.iconContainer}>
          <ConservationIcon />
        </div>

        <h1 className={css.title}>
          <FormattedMessage id="FAQLandownersPage.conservation.title" />
        </h1>

        <p className={css.content}>
          <FormattedMessage id="FAQLandownersPage.conservation.body" />
        </p>
      </div>
    </Container>
  );
};

FAQSectionConservation.propTypes = {};

export default FAQSectionConservation;
