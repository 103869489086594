/* eslint-disable no-nested-ternary */
import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bool, func, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { scrollWithOffset } from '../../util/listing';
import { SectionPackages, PrimaryButton } from '..';
import css from './BookingPanel.css';

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    isOwnListing,
    onSubmit,
    currentActivity,
    isDraft,
  } = props;

  const handleSubmit = values => {
    onSubmit(values);
  };

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const sectionPackagesTitle = <FormattedMessage id="SectionPackages.sectionTitle" />;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.packagesContainer}>
        <SectionPackages
          listing={listing}
          onSubmit={onSubmit && handleSubmit}
          sectionPackagesTitle={sectionPackagesTitle}
          listingBookingPanel
          showDefaultPackages={Infinity}
          currentActivity={currentActivity}
          isDraft={isDraft}
        />
      </div>

      <div className={css.openBookingForm}>
        {showBookingDatesForm ? (
          <Link
            className={css.availLink}
            onClick={e => e.preventDefault()}
            to="#packagesMobile"
            scroll={scrollWithOffset}
          >
            <PrimaryButton className={css.bookButton} isFullWidth onClick={e => e.preventDefault()}>
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            </PrimaryButton>
          </Link>
        ) : isOwnListing ? null : !isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.loadingText" />
          </div>
        ) : (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        )}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
  isDraft: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  isOwnListing: bool,
  onSubmit: func.isRequired,
  isDraft: bool,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
