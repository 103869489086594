import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import { daysAdded } from '../../util/dates';
import { getBookingDates } from '../../util/transaction';

import css from './BookingBreakdown.css';

export const BookingPeriod = props => {
  const { isSingleDay, bookingStart, bookingEnd } = props;

  if (isSingleDay) {
    return bookingStart.format('MMM DD');
  }

  return (
    <FormattedMessage
      id="BookingBreakdown.bookingPeriod"
      values={{
        bookingStart: <span className={css.nowrap}>{bookingStart.format('MMM DD')}</span>,
        bookingEnd: <span className={css.nowrap}>{bookingEnd.format('MMM DD')}</span>,
      }}
    />
  );
};

const LineItemBookingPeriod = props => {
  const { transaction } = props;

  const { bookingStart, bookingEnd } = getBookingDates(transaction);

  const dayCount = daysAdded(bookingStart, bookingEnd);

  const unitCountMessage = (
    <FormattedHTMLMessage id="BookingBreakdown.dayCount" values={{ dayCount }} />
  );

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <BookingPeriod
          isSingleDay={dayCount === 1}
          bookingStart={bookingStart}
          bookingEnd={bookingEnd}
        />
      </span>
      <span className={css.itemValue}>{unitCountMessage}</span>
    </div>
  );
};

LineItemBookingPeriod.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemBookingPeriod;
