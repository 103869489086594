import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'lottie-react';
import animationData from '../../assets/lottie/success-animation.json';
import Plane from '../../assets/sent-plane.webp';
import css from './BookingSuccessModal.css';

const BookingSuccessModal = user => {
  const emailVerified = user?.user?.attributes?.emailVerified;
  const [showAnimation, setShowAnimation] = useState(false);

  setTimeout(() => {
    setShowAnimation(true);
  }, 1000);

  if (!user) {
    return null;
  }

  return (
    <div className={css.contentWrapper}>
      <div className={css.headingWrapper}>
        <img src={Plane} alt="Plane" className={css.imageStyles} />
        {showAnimation && (
          <div className={emailVerified ? css.verifiedLottieAnimation : css.normalLottieAnimation}>
            <Lottie animationData={animationData} loop={false} autoPlay />
          </div>
        )}
        <div className={css.heading}>
          <FormattedMessage id="BookingSuccessModal.heading" />
        </div>
        <div className={css.subHeading}>
          <FormattedMessage id="BookingSuccessModal.subHeading" />
        </div>
      </div>
      {!emailVerified && (
        <div className={css.dividerWrapper}>
          <hr className={css.divider} />
        </div>
      )}
      {!emailVerified && (
        <>
          <div className={css.tipsHeading}>
            <FormattedMessage id="BookingSuccessModal.tipsHeading" />
          </div>
          <div className={css.tipsWrapper}>
            <div className={css.tipsTextOne}>
              <FormattedMessage id="BookingSuccessModal.tipContent" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingSuccessModal;
