/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionRequestExpiresSoon = ({
  transition,
  user,
  provider,
  ownRole,
  listingTitle,
  buttons,
  earnings,
}) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));
  const authorDisplayName = getFirstName(get(provider, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage
            id="BookingStatus.ownTransitionRequest"
            values={{ authorDisplayName }}
          />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionRequest"
            values={{ displayName, listingTitle }}
          />
        )
      }
      buttons={buttons}
    >
      {isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionRequestSubtext" />
      ) : earnings ? (
        <FormattedMessage id="BookingStatus.transitionRequestSubtext" values={{ earnings }} />
      ) : null}
    </InboxTransitionBase>
  );
};

export default InboxTransitionRequestExpiresSoon;
