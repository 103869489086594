import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import css from './Badge.css';

const Badge = ({ children, className }) => {
  const classes = classNames(css.root, className);

  return <div className={classes}>{children}</div>;
};

Badge.defaultProps = {
  children: null,
  className: null,
};

Badge.propTypes = {
  children: node,
  className: string,
};

export default Badge;
