/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { oneOf, object, node } from 'prop-types';
import { get } from 'lodash';
import { useMediaLayout } from 'use-media';
import PricingToolTip from '../PricingToolTip/PricingToolTip';
import LineItemCustomerCommissionMaybe from './LineItems/LineItemCustomerCommissionMaybe';
import LineItemProviderCommissionMaybe from './LineItems/LineItemProviderCommissionMaybe';
import LineItemRefundMaybe from './LineItems/LineItemRefundMaybe';
import LineItemTotalPrice from './LineItems/LineItemTotalPrice';
import LineItemPackageItem from './LineItems/LineItemPackageItem';
import LineItemSpecialOfferMaybe from './LineItems/LineItemSpecialOfferMaybe';
import LineItemCustomerLodgingTaxMaybe from './LineItems/LineItemCustomerLodgingTaxMaybe';
import LineItemInsurance from './LineItems/LineItemInsurance';
import LineItemLodgingFee from './LineItems/LineItemLodgingFee';
import LineItemConservationDonationMaybe from './LineItems/LineItemConservationDonationMaybe';
import LineItemPromoCode from './LineItems/LineItemPromoCode';
import {
  LINE_ITEM_LODGING_TAX,
  LINE_ITEM_LODGING_FEE,
  LINE_ITEM_NEGOTIATION,
  LINE_ITEM_SPECIAL_OFFER,
} from '../../util/types';
import {
  findPackageOrDiyLineItem,
  getBookingDates,
  getLineItemByCode,
} from '../../util/transaction';
import { formatMoney } from '../../util/currency';
import { daysAdded } from '../../util/dates';

import { types as sdkTypes } from '../../util/sdkLoader';

import css from './PricingDetails.css';

const { Money } = sdkTypes;

const PricingDetailsComponent = props => {
  const [showTooltip, setShowTooltip] = useState(false);

  const isWide = useMediaLayout({ minWidth: '768px' });
  const { transaction, userRole, intl, isSpecialOffer: specialOfferEstimate, totalLabel } = props;

  const protectedData = get(transaction, 'attributes.protectedData', null);
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const isEstimate = transaction?.attributes?.protectedData?.isEstimate || false;
  const isPackage = !!(protectedData && protectedData.packageLineItem);
  const lodgingTaxInfo = get(protectedData, 'lodgingTaxInfo', null);
  const guestSize = get(transaction, 'attributes.protectedData.packageLineItem.guestSize', 1);
  const handleToggleClick = () => {
    setShowTooltip(prevTooltip => !prevTooltip);
  };

  const isSpecialOffer =
    getLineItemByCode(transaction, LINE_ITEM_NEGOTIATION).length > 0 ||
    getLineItemByCode(transaction, LINE_ITEM_SPECIAL_OFFER).length > 0 ||
    specialOfferEstimate;

  const isLodgingTax = getLineItemByCode(transaction, LINE_ITEM_LODGING_TAX).length > 0;

  const lodgingFeeLineItem =
    getLineItemByCode(transaction, LINE_ITEM_LODGING_FEE).length > 0 &&
    getLineItemByCode(transaction, LINE_ITEM_LODGING_FEE)[0];

  const { bookingStart, bookingEnd } = getBookingDates(transaction);

  const lodgingFeeLineItemInfo = lineItem => {
    const formattedLodgingFeeTotal = lineItem?.unitPrice && formatMoney(intl, lineItem?.unitPrice);
    const nights = daysAdded(bookingStart, bookingEnd) - 1;

    const lodgingAmount = get(lineItem, 'unitPrice.amount', 0);

    const dailyLodgingPrice =
      nights > 0 && formatMoney(intl, new Money(lodgingAmount / nights, 'USD'));

    const lodgingFeeLabel = formattedLodgingFeeTotal && dailyLodgingPrice && (
      <FormattedMessage
        id="LineItemLodgingFee.nightCount"
        values={{
          price: dailyLodgingPrice,
          nights,
        }}
      />
    );
    return { lineItem, lodgingFeeLabel, formattedLodgingFeeTotal };
  };

  const packageOrDiyLineItem = findPackageOrDiyLineItem(transaction);

  const packageOrDiyLineItemInfo = lineItems => {
    return lineItems
      .map(lineItem => {
        // Package Info
        try {
          const dayCount = daysAdded(bookingStart, bookingEnd);
          const estimateTotal = new Money(
            lineItem?.lineTotal?.amount * guestSize * dayCount,
            'USD'
          );
          const formattedPackageTotal =
            lineItem?.lineTotal &&
            formatMoney(intl, isEstimate ? estimateTotal : lineItem?.lineTotal);

          // Line Item info for packages
          const formattedlinePrice = lineItem?.unitPrice && formatMoney(intl, lineItem?.unitPrice);

          const packageLabel = formattedlinePrice && guestSize && (
            <FormattedMessage
              id="LineItemPackageItem.guestCount"
              values={{
                price: formattedlinePrice,
                guestSize,
                dayCount,
              }}
            />
          );

          return { lineItem, packageLabel, formattedPackageTotal };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return null;
        }
      })
      .filter(Boolean);
  };

  // Special Offer on mobile is already in a <Modal/>
  // Modals can't render the tooltip
  const modalOffer = !isWide && isSpecialOffer && isEstimate;
  const eachItem = packageOrDiyLineItemInfo(packageOrDiyLineItem);

  const pricingTitle = <FormattedMessage id="PricingDetails.pricingHeaderCustomer" />;

  return (
    <div className={css.root}>
      {isCustomer && (
        <div className={css.header}>
          <FormattedMessage id="PricingDetails.pricingHeaderCustomer" />
        </div>
      )}
      {isProvider && (
        <div className={css.header}>
          <FormattedMessage id="PricingDetails.pricingHeaderProvider" />
        </div>
      )}
      {/* package or special offer lineItems */}
      {isSpecialOffer && (
        <LineItemSpecialOfferMaybe
          onClick={handleToggleClick}
          transaction={transaction}
          intl={intl}
        />
      )}
      {isPackage && <LineItemPackageItem onClick={handleToggleClick} itemInfo={eachItem} />}

      {/* lodging fee lineItem */}
      {lodgingFeeLineItem && (
        <LineItemLodgingFee
          onClick={handleToggleClick}
          itemInfo={lodgingFeeLineItemInfo(lodgingFeeLineItem)}
        />
      )}

      {/* commission lineItems */}
      <LineItemCustomerCommissionMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
        onClick={handleToggleClick}
      />

      <LineItemProviderCommissionMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        onClick={handleToggleClick}
        modalOffer={modalOffer}
      />

      <PricingToolTip
        transaction={transaction}
        className={css.proTipTextArea}
        intl={intl}
        tooltipShown={showTooltip}
        title={pricingTitle}
        userRole={userRole}
        isPackage={isPackage}
        isSpecialOffer={isSpecialOffer}
        isLodgingTax={isLodgingTax}
        isEstimate={isEstimate}
        isWide={isWide}
        itemInfo={eachItem}
        lodgingInfo={lodgingFeeLineItemInfo(lodgingFeeLineItem)}
        usState={lodgingTaxInfo?.usState}
        taxRate={lodgingTaxInfo?.taxValue * 100}
      />
      {/* extra lineItems */}
      <LineItemCustomerLodgingTaxMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
        onClick={handleToggleClick}
      />
      <LineItemConservationDonationMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
        onClick={handleToggleClick}
      />
      <LineItemInsurance
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
        onClick={handleToggleClick}
      />
      <LineItemPromoCode
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
        onClick={handleToggleClick}
      />
      {/* refund lineItem */}
      <LineItemRefundMaybe
        transaction={transaction}
        intl={intl}
        userRole={userRole}
        onClick={handleToggleClick}
      />
      {/* total lineItem */}

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        totalLabel={totalLabel}
      />
    </div>
  );
};

PricingDetailsComponent.defaultProps = {
  totalLabel: null,
};

PricingDetailsComponent.propTypes = {
  transaction: object.isRequired,
  userRole: oneOf(['provider', 'customer']).isRequired,
  intl: intlShape.isRequired,
  totalLabel: node,
};

const PricingDetails = injectIntl(PricingDetailsComponent);

PricingDetails.displayName = 'PricingDetails';

export default PricingDetails;
