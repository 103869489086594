import sha1 from 'js-sha1';
import { get, isArray, memoize } from 'lodash';

import * as props from './prop-names';
import { daysBetween } from '../util/dates';
import { getListingStartingPrice } from '../util/listing';
import { humanizeLineItemCode } from '../util/data';
import {
  getLineItemsTotal,
  getNonCommissionNonReversalLineItems,
  TRANSITION_CUSTOMER_LATE_CANCEL,
} from '../util/transaction';
import config from '../config';
import {
  LINE_ITEM_PARTY_SIZE,
  LINE_ITEM_UNITS,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION,
} from '../util/types';
import { convertMoneyToNumber } from '../util/currency';

const huntMotMap = {
  archery: 'allowsArchery',
  firearms: 'allowsFirearms',
};

const fishMotMap = {
  bow: 'allowsBow',
  fly: 'allowsFly',
  liveBait: 'allowsLiveBait',
  lure: 'allowsLure',
};

const huntAmenitiesMap = {
  game_feeders: 'hasGameFeeders',
  ground_blinds: 'hasGroundBlinds',
  tree_stands: 'hasTreeStands',
  game_cameras: 'hasGameCameras',
};

export const getEventDate = (date = null) => new Date(date || Date.now()).toISOString();

export const formatEvent = (type, payload) => ({
  event: 'dl_push',
  mp_event: type,
  mp_properties: payload,
});

export const getAbsoluteUrl = path => `${process.env.REACT_APP_CANONICAL_ROOT_URL}${path}`;

export const getCurrUserStatus = state => (state.Auth.isAuthenticated ? 'Logged in' : 'Visitor');

export const getCurrUserAttribute = (state, path, defaultValue = '') =>
  get(state, `user.currentUser.attributes.${path}`, defaultValue);

export const getCurrUserId = state => get(state, 'user.currentUser.id.uuid', '');

export const getCurrUserRole = state =>
  getCurrUserAttribute(state, 'profile.protectedData.role', ['sportsman']);

export const getDefaultUserProps = state => ({
  [props.USER_ID]: getCurrUserId(state),
  [props.ROLE]: getCurrUserRole(state),
  [props.USER_STATUS]: getCurrUserStatus(state),
});

const sha1Hash = memoize(sha1);
export const getEmailProps = state => {
  const email = getCurrUserAttribute(state, 'email');

  return {
    [props.EMAIL]: email || '',
    [props.EMAIL_SHA1]: email ? sha1Hash(email) : '',
  };
};
export const getIdpSignupData = (state, path) => get(state, `user.currentUser.${path}`, []);
export const getFullListingProps = listing => {
  if (!listing) return [];

  const { title, publicData } = listing.attributes;
  const getMotTypes = data => {
    const hunt = get(data, 'huntMotTypes', []);
    const fish = get(data, 'fishMotTypes', []);

    return [
      ...Object.entries(huntMotMap)
        .filter(([_, prop]) => !!hunt[prop])
        .map(([mot, _]) => mot),
      ...Object.entries(fishMotMap)
        .filter(([_, prop]) => !!fish[prop])
        .map(([mot, _]) => mot),
    ];
  };

  const getFeatures = data => {
    const features = get(data, 'landDetails', {});

    return [
      ...get(features, 'waterSources', []),
      ...get(features, 'foodSources', []),
      ...get(features, 'shelter', []),
    ];
  };

  const getAmenities = data => {
    const hasLodging = get(data, 'lodging.hasLodging', false);
    const huntAmenities = get(data, 'huntAmenities', {});
    const allowsParking = get(data, 'policies.allowsParking', false);

    return [
      ...Object.entries(huntAmenitiesMap)
        .filter(([_, prop]) => !!huntAmenities[prop])
        .map(([amenity, _]) => amenity),
      ...(hasLodging ? ['lodging'] : []),
      ...(allowsParking ? ['parking'] : []),
    ];
  };

  const getListingImage = data => {
    const variants = ['scaled-medium', 'landscape-crop2x'];

    return (
      variants
        .map(variant => {
          return get(data, `images.0.attributes.variants.${variant}.url`, null);
        })
        .filter(url => !!url)
        .pop() || ''
    );
  };

  const getListingAddress = data => get(data, 'location.address', '');
  const getListingState = data => get(data, 'location.usState', '');

  const categories = get(publicData, 'categories', []);

  return {
    [props.LISTING_ID]: listing.id.uuid,
    [props.LISTING_NAME]: title,
    [props.LISTING_IMAGE]: getListingImage(listing),
    [props.ACREAGE]: get(publicData, 'location.acreage'),
    [props.ADDRESS]: getListingAddress(publicData),
    [props.LISTING_STATE]: getListingState(publicData),
    [props.HUNT]: categories.indexOf('hunt') > -1,
    [props.FISH]: categories.indexOf('fish') > -1,
    [props.ACCESS]: categories.indexOf('access') > -1,
    [props.NEAREST_TOWN]: get(publicData, 'nearestTown.selectedPlace.address'),
    [props.METHOD_OF_TAKE]: getMotTypes(publicData),
    [props.SPECIES]: get(publicData, 'gameTypeKeys', []),
    [props.FEATURES]: getFeatures(publicData),
    [props.AMENITIES]: getAmenities(publicData),
    [props.MAXIMUM_PARTY_SIZE]: Number(get(publicData, 'policies.sportsmen', 1)),
    [props.LANDOWNER_ID]: listing.author.id.uuid,
    [props.STARTING_PRICE]: Number(getListingStartingPrice(publicData.gameTypes)),
  };
};

export const getActivities = tx => {
  const ignore = [
    LINE_ITEM_PARTY_SIZE,
    LINE_ITEM_UNITS,
    LINE_ITEM_PROVIDER_COMMISSION,
    LINE_ITEM_CUSTOMER_COMMISSION,
  ];

  return tx.attributes.lineItems
    .filter(i => ignore.indexOf(i.code) === -1 && i.lineTotal.amount > 0)
    .map(i => humanizeLineItemCode(i.code).replace(' fee', ''));
};

export const getPartySize = tx => {
  const item = tx.attributes.lineItems.filter(i => i.code === LINE_ITEM_PARTY_SIZE).pop();

  return !item ? 1 : item.quantity.d[0] + 1;
};

export const getSportsmanFee = (tx, reversal = false) => {
  // This may return multiple line items.
  // First one is the LT fee and the second may be the donation.
  // We're only interested in one of them.
  const item =
    tx.attributes.lineItems
      .filter(i => i.code === LINE_ITEM_CUSTOMER_COMMISSION && i.reversal === reversal)
      .shift() || null;

  return !item ? 0 : Math.abs(convertMoneyToNumber(item.lineTotal));
};

export const getLandownerFee = (tx, reversal = false) => {
  const item =
    tx.attributes.lineItems
      .filter(i => i.code === LINE_ITEM_PROVIDER_COMMISSION && i.reversal === reversal)
      .pop() || null;

  return !item ? 0 : Math.abs(convertMoneyToNumber(item.lineTotal));
};

export const getTotalPrice = tx => convertMoneyToNumber(tx.attributes.payinTotal);

export const getSubTotal = tx =>
  convertMoneyToNumber(
    getLineItemsTotal(getNonCommissionNonReversalLineItems(tx), config.currency)
  );

export const getDonation = (tx, reversal = false) => {
  const item =
    tx.attributes.lineItems
      .filter(i => i.code === LINE_ITEM_CUSTOMER_COMMISSION && i.reversal === reversal)
      .pop() || null;

  const value = !item ? 0 : Math.abs(convertMoneyToNumber(item.lineTotal));

  // There can be multiple line items with customer commission.
  // Donation should never be greater than (--updated to 10$).
  return value > 10 ? 0 : value;
};

export const getSportsmanTotal = tx => getSubTotal(tx) + getSportsmanFee(tx);
export const getpartyMembers = tx => get(tx, 'attributes.protectedData.partyMemberDetails', {});

export const getBookingProps = tx => {
  return {
    [props.TRANSACTION_ID]: tx.id.uuid,
    [props.BOOKING]: tx.booking.id.uuid,
    [props.USER_ID]: tx.customer.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LANDOWNER_ID]: tx.provider.id.uuid,
    [props.BOOKING_START_DATE]: getEventDate(tx.booking.attributes.start),
    [props.BOOKING_END_DATE]: getEventDate(tx.booking.attributes.end),
    [props.DAYS_OF_BOOKING]: daysBetween(tx.booking.attributes.start, tx.booking.attributes.end),
    [props.PARTY_SIZE_SELECTED]: getPartySize(tx),
    [props.ACTIVITIES_SELECTED]: getActivities(tx),
    [props.SUBTOTAL]: getSubTotal(tx),
    [props.BOOKING_FEE]: getSportsmanFee(tx),
    [props.TOTAL_PRICE]: getTotalPrice(tx),
    [props.LANDOWNER_BOOKING_FEE]: getLandownerFee(tx),
    [props.SPORTSMAN_BOOKING_FEE]: getSportsmanFee(tx),
    [props.DONATION]: getDonation(tx),
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.PACKAGE_ID]: get(tx, 'attributes.protectedData.packageLineItem.id', 'N/A'),
    [props.PACKAGE_TITLE]: get(tx, 'attributes.protectedData.packageLineItem.title', 'N/A'),
    [props.PACKAGE_ACTIVITY]: get(tx, 'attributes.protectedData.packageLineItem.activity', 'N/A'),
    ...getFullListingProps(tx.listing),
  };
};

export const cleanEventData = e => {
  if (!e.mp_properties) {
    return e;
  }

  Object.keys(e.mp_properties).forEach(p => {
    if (
      e.mp_properties[p] === '' ||
      (isArray(e.mp_properties[p]) && e.mp_properties[p].length === 0)
    ) {
      delete e.mp_properties[p];
    }
  });

  return e;
};

export const isLandowner = state => state.TransactionPage.transactionRole === 'provider';
export const isSportsman = state => state.TransactionPage.transactionRole === 'customer';

export const isLateCancelBySportsman = tx => {
  return tx.attributes.lastTransition === TRANSITION_CUSTOMER_LATE_CANCEL;
};
