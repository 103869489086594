/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bool, oneOfType } from 'prop-types';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { get } from 'lodash';
import { scrollWithOffset } from '../../util/listing';

import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';

import { PrimaryButton, SectionPackageCard, Tabs, Tab } from '..';

import css from './SectionAvailablePackages.css';

const TABS = {
  ALL: 'all',
  HUNT: 'hunt',
  OUTDOOR_RECREATION: 'outdoor_recreation',
};

const SectionAvailablePackages = props => {
  const [activeTab, setActiveTab] = useState(TABS.ALL);

  const { isOwnListing, listing, listingPage, rootClassName, followSubjectMutation } = props;

  const packages = get(listing, 'attributes.publicData.packages', []);
  const activities = get(listing, 'attributes.publicData.categories', {});

  const publishedPackages = packages.filter(
    item => item.isPublished && activities.includes(item.activity)
  );

  const huntPackages = publishedPackages.filter(item => item.activity === HUNT_ACTIVITY);
  const outdoorRecreationPackages = publishedPackages.filter(
    item => item.activity === OUTDOOR_RECREATION_ACTIVITY
  );

  const activitesPackages = [];

  if (
    huntPackages.length &&
    [TABS.ALL, TABS.HUNT].includes(activeTab) &&
    activities.includes(HUNT_ACTIVITY)
  ) {
    activitesPackages.push({
      title: <FormattedMessage id="SectionAvailablePackages.huntTitle" />,
      packages: huntPackages,
      tab: TABS.HUNT,
    });
  }

  if (
    outdoorRecreationPackages.length &&
    [TABS.ALL, TABS.OUTDOOR_RECREATION].includes(activeTab) &&
    activities.includes(OUTDOOR_RECREATION_ACTIVITY)
  ) {
    activitesPackages.push({
      title: <FormattedMessage id="SectionAvailablePackages.outdoorRecreationTitle" />,
      packages: outdoorRecreationPackages,
      tab: TABS.OUTDOOR_RECREATION,
    });
  }

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showPackagesButton = hasListingState && !isClosed;

  const classes = classNames(rootClassName || css.root);

  return (
    <div className={classes} id="packagesMobile">
      <h1 className={css.title}>
        <FormattedMessage id="SectionAvailablePackages.title" />
      </h1>

      {activitesPackages.length > 0 && (
        <div className={css.tabWrapper}>
          <Tabs>
            <Tab handleClick={() => setActiveTab(TABS.ALL)} isSelected={activeTab === TABS.ALL}>
              <FormattedMessage
                id="SectionAvailablePackages.tabAll"
                values={{ count: publishedPackages.length }}
              />
            </Tab>

            {huntPackages.length > 0 && activities.includes(HUNT_ACTIVITY) && (
              <Tab handleClick={() => setActiveTab(TABS.HUNT)} isSelected={activeTab === TABS.HUNT}>
                <FormattedMessage
                  id="SectionAvailablePackages.tabHunting"
                  values={{ count: huntPackages.length }}
                />
              </Tab>
            )}

            {outdoorRecreationPackages.length > 0 &&
              activities.includes(OUTDOOR_RECREATION_ACTIVITY) && (
                <Tab
                  handleClick={() => setActiveTab(TABS.OUTDOOR_RECREATION)}
                  isSelected={activeTab === TABS.OUTDOOR_RECREATION}
                >
                  <FormattedMessage
                    id="SectionAvailablePackages.tabOutdoorRecreation"
                    values={{ count: outdoorRecreationPackages.length }}
                  />
                </Tab>
              )}
          </Tabs>
        </div>
      )}

      {activitesPackages.map((activity, index) => (
        <div key={`activity-section-${index}`}>
          <div className={css.activityTitle}>{activity.title}</div>

          <div>
            {activity.packages.map(item => (
              <div>
                <SectionPackageCard
                  listing={listing}
                  packageId={item.id}
                  item={item}
                  isResponsive
                  followSubjectMutation={followSubjectMutation}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {listingPage && (
        <div className={css.openBookingForm}>
          {showPackagesButton ? (
            <Link
              className={css.availLink}
              onClick={e => e.preventDefault()}
              to="#packagesMobile"
              scroll={scrollWithOffset}
            >
              <PrimaryButton
                className={css.bookButton}
                isFullWidth
                onClick={e => e.preventDefault()}
              >
                <FormattedMessage id="SectionAvailablePackages.ctaButtonMessage" />
              </PrimaryButton>
            </Link>
          ) : isOwnListing ? null : !isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="SectionAvailablePackages.loadingText" />
            </div>
          ) : (
            <div className={css.closedListingButton}>
              <FormattedMessage id="SectionAvailablePackages.closedListingButtonText" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SectionAvailablePackages.defaultProps = {
  isOwnListing: false,
};

SectionAvailablePackages.propTypes = {
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  isOwnListing: bool,
};

export default compose(
  withRouter,
  injectIntl
)(SectionAvailablePackages);
