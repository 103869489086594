import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';

import { PrimaryButton, NamedLink } from '..';
import listYourPropertyImage from './images/list-your-property.jpg';
import listYourPropertyImageWebP from './images/list-your-property.webp';
import avatarImage from './images/avatar.jpg';

import css from './SectionListYourProperty.css';

const SectionListYourProperty = ({ intl }) => {
  return (
    <div className={css.root} data-testid="list-your-property-section">
      <div className={css.header}>
        <div className={css.titleContainer}>
          <h1>
            <FormattedMessage id="SectionListYourProperty.title" />
          </h1>
        </div>

        <div className={css.imageContainer}>
          <picture>
            <source srcSet={listYourPropertyImageWebP} type="image/webp" />
            <source srcSet={listYourPropertyImage} type="image/jpeg" />
            <img
              src={listYourPropertyImage}
              alt={intl.formatMessage({ id: 'SectionListYourProperty.title' })}
              className={css.image}
            />
          </picture>
        </div>
      </div>

      <div className={css.content}>
        <FormattedHTMLMessage id="SectionListYourProperty.content" tagName="p" />

        <PrimaryButton component={NamedLink} name="FAQLandownersPage" className={css.cta}>
          <FormattedMessage id="SectionListYourProperty.cta" />
        </PrimaryButton>
      </div>

      <NamedLink name="FAQLandownersPage" className={css.testimonial}>
        <div className={css.testimonialHeader}>
          <img
            src={avatarImage}
            className={css.testimonialAvatar}
            alt={intl.formatMessage({ id: 'SectionListYourProperty.author' })}
          />

          <div className={css.testimonialHeaderContent}>
            <div className={css.testimonialAuthor}>
              <FormattedMessage id="SectionListYourProperty.author" />
            </div>
            <div className={css.testimonialListing}>
              <FormattedMessage id="SectionListYourProperty.listing" />
            </div>
          </div>
        </div>

        <div className={css.testimonialQuotes}>“</div>

        <div className={css.testimonialContent}>
          <FormattedMessage id="SectionListYourProperty.testimonial" />
        </div>
      </NamedLink>
    </div>
  );
};

SectionListYourProperty.propTypes = {};

export default injectIntl(SectionListYourProperty);
