import React from 'react';
import { get } from 'lodash';
import { func } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import css from './FieldTripInsurance.css';
import { FieldTripInsuranceToggle, ValidationError } from '..';
import { composeValidators, required } from '../../util/validators';
import { INSURANCE_PERCENTAGE } from '../../shared/transaction';

const { types } = require('sharetribe-flex-sdk');

const { Money } = types;

const FieldTripInsuranceComponent = ({
  intl,
  onApply,
  isApplyingInsurance,
  setIsApplyingInsurance,
  ...restProps
}) => {
  const { value, onChange, transaction, ...meta } = restProps;
  const [insuranceOption, setInsuranceOption] = React.useState(null);
  const allLineItems = get(transaction, 'attributes.lineItems', []);

  const calculateInsuranceCost = lineItems => {
    const filteredLineItems = lineItems.filter(
      item => item.code !== 'line-item/provider-commission' && item.code !== 'line-item/insurance'
    );

    const totalWithoutProviderAndInsurance = filteredLineItems.reduce(
      (total, { lineTotal }) => total + lineTotal.amount,
      0
    );

    const insurancePrice = new Decimal(totalWithoutProviderAndInsurance);
    const insuranceAmount = insurancePrice
      .times(INSURANCE_PERCENTAGE)
      .dividedBy(100)
      .toNearest(1, Decimal.ROUND_HALF_UP);
    const insuranceCost = formatMoney(intl, new Money(insuranceAmount, 'USD'));
    return insuranceCost;
  };

  const handleApplyInsurance = async tripInsurance => {
    const option = !!tripInsurance;
    setInsuranceOption(option);

    try {
      setIsApplyingInsurance(true);
      await onApply(tripInsurance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setIsApplyingInsurance(false);
    }
  };

  const handleChange = event => {
    onChange(event);
    handleApplyInsurance(value);
  };

  return (
    <div className={css.root}>
      {!isApplyingInsurance ? (
        <div className={css.inputWrapper}>
          <FieldTripInsuranceToggle
            name="tripInsurance"
            onChange={handleChange}
            validate={composeValidators(
              required(intl.formatMessage({ id: 'FieldTripInsurance.insuranceRequired' }))
            )}
            insuranceCost={calculateInsuranceCost(allLineItems)}
            insuranceOptionSelected={insuranceOption}
          />
        </div>
      ) : (
        <div className={css.loaderWrapper}>
          <div className={css.skeletonTop} />
          <div className={css.skeletonRadio} />
        </div>
      )}
      <ValidationError fieldMeta={{ ...meta }} />
    </div>
  );
};

const FieldTripInsurance = props => {
  return (
    <Field name="tripInsurance">
      {({ input, meta }) => <FieldTripInsuranceComponent {...input} {...meta} {...props} />}
    </Field>
  );
};

FieldTripInsurance.defaultProps = {};

FieldTripInsurance.propTypes = {
  onApply: func.isRequired,
};

export default injectIntl(FieldTripInsurance);
