import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import {
  AVAILABILITY,
  DESCRIPTION,
  LANDOWNER_STORY,
  LODGING,
  CAMPSITE,
  HUNT_EXPERIENCE,
  OUTDOOR_RECREATION_EXPERIENCE,
  POLICY,
  ARRIVAL,
  LOCATION,
  PHOTOS,
  SUMMARY,
  SUPPORTED_TABS,
  PHOTO_LIBRARY,
  PROPERTY_VISIT,
} from '../../util/editListing';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingCampsitePanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPhotoLibraryPanel,
  EditListingPropertyVisitPanel,
  EditListingLandownerStoryPanel,
  EditListingPoliciesPanel,
  EditListingArrivalPanel,
  EditListingHuntExperiencePanel,
  EditListingOutdoorRecreationExperiencePanel,
  EditListingSummaryPanel,
} from '..';
import config from '../../config';
import EditListingLodgingPanel from '../EditListingLodgingPanel/EditListingLodgingPanel';

const EditListingWizardTab = props => {
  const {
    tab,
    params,
    errors,
    availability,
    listing,
    currentUser,
    currentUserHasListings,
    onChange,
    updatedTab,
    updateInProgress,
    intl,
    onUpdateCategory,
    onSubmit,
    backLink,
    skipLink,
    submitButtonText,
    onUpdateProfile,
    onUploadProfileImage,
    history,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  switch (tab) {
    case LOCATION: {
      return (
        <EditListingLocationPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case DESCRIPTION: {
      return (
        <EditListingDescriptionPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
            onUpdateCategory(values.publicData.categories);
          }}
          backLink={backLink}
        />
      );
    }

    case LANDOWNER_STORY: {
      return (
        <EditListingLandownerStoryPanel
          errors={errors}
          user={currentUser}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onUpdateProfile(values).then(() => {
              onSubmit(tab, {});
            });
          }}
          onUploadProfileImage={onUploadProfileImage}
          backLink={backLink}
        />
      );
    }

    case LODGING: {
      return (
        <EditListingLodgingPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case CAMPSITE: {
      return (
        <EditListingCampsitePanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case HUNT_EXPERIENCE: {
      return (
        <EditListingHuntExperiencePanel
          params={params}
          history={history}
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case OUTDOOR_RECREATION_EXPERIENCE: {
      return (
        <EditListingOutdoorRecreationExperiencePanel
          params={params}
          history={history}
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case POLICY: {
      return (
        <EditListingPoliciesPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          categories={config.custom.rulesTypeMap}
          backLink={backLink}
        />
      );
    }

    case AVAILABILITY: {
      return (
        <EditListingAvailabilityPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          availability={availability}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case PHOTOS: {
      return (
        <EditListingPhotosPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case PHOTO_LIBRARY: {
      return (
        <EditListingPhotoLibraryPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case ARRIVAL: {
      return (
        <EditListingArrivalPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          categories={config.custom.rulesTypeMap}
          backLink={backLink}
          skipLink={skipLink}
        />
      );
    }

    case PROPERTY_VISIT: {
      return (
        <EditListingPropertyVisitPanel
          errors={errors}
          listing={listing}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={submitButtonText}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
          backLink={backLink}
        />
      );
    }

    case SUMMARY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.publishLand'
        : 'EditListingWizard.saveEdit';
      return (
        <EditListingSummaryPanel
          errors={errors}
          listing={listing}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          onChange={onChange}
          panelUpdated={updatedTab === tab}
          updateInProgress={updateInProgress}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onSubmit(tab, values);
          }}
        />
      );
    }

    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  currentUser: null,
  updatedTab: null,
  backLink: null,
  skipLink: null,
};

const { bool, func, object, oneOf, shape, string, node } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
    packageId: string,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  availability: object.isRequired,
  backLink: node,
  skipLink: node,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
  }),
  currentUser: object,
  currentUserHasListings: bool.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
  submitButtonText: string.isRequired,
};

export default EditListingWizardTab;
