import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { PrimaryButton } from '../../components/Button/Button';
import NamedLink from '../../components/NamedLink/NamedLink';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import css from './PackageNotFoundPage.css';
import NotFoundImage from '../../assets/photo-collage.png';

export const PackageNotFoundPageComponent = ({ intl, scrollingDisabled }) => {
  const title = intl.formatMessage({
    id: 'NotFoundPage.title',
  });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <div className={css.textContainer}>
              <h1 className={css.heading}>
                <FormattedMessage id="PackageNotFoundPage.heading" />
              </h1>
              <h1 className={css.headingBold}>
                <FormattedMessage id="PackageNotFoundPage.headingBold" />
              </h1>
              <h1 className={css.description}>
                <FormattedMessage id="PackageNotFoundPage.description" />
              </h1>
              <div className={css.actions}>
                <PrimaryButton
                  component={NamedLink}
                  name="LandingPage"
                  className={classNames(css.action, css.actionPrimary)}
                >
                  <FormattedMessage id="PackageNotFoundPage.button" />
                </PrimaryButton>
              </div>
            </div>
            <div className={css.imageContainer}>
              <img src={NotFoundImage} alt={title} className={css.notFoundImage} />
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

PackageNotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  // staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PackageNotFoundPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(PackageNotFoundPageComponent);

export default PackageNotFoundPage;
