import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton, NamedLink } from '../../components';
import FAQSectionSteps, { Step } from './FAQSectionSteps';

import { ReactComponent as IconMoney } from '../../assets/icons/money.svg';
import { ReactComponent as IconRegister } from '../../assets/icons/register.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/check.svg';

const FAQSectionHowItWorks = props => {
  return (
    <div>
      <FAQSectionSteps
        bg="olive"
        title={<FormattedMessage id="FAQLandownersPage.payments.title" />}
        cta={
          <PrimaryButton component={NamedLink} name="NewListingPage">
            <FormattedMessage id="FAQLandownersPage.payments.cta" />
          </PrimaryButton>
        }
      >
        <>
          <Step
            icon={<IconMoney />}
            title={<FormattedMessage id="FAQLandownersPage.payments.charge.title" />}
          >
            <FormattedMessage id="FAQLandownersPage.payments.charge.body" />
          </Step>

          <Step
            icon={<IconRegister />}
            title={<FormattedMessage id="FAQLandownersPage.payments.signup.title" />}
          >
            <FormattedMessage id="FAQLandownersPage.payments.signup.body" />
          </Step>

          <Step
            icon={<IconCheck />}
            title={<FormattedMessage id="FAQLandownersPage.payments.getPaid.title" />}
          >
            <FormattedMessage id="FAQLandownersPage.payments.getPaid.body" />
          </Step>
        </>
      </FAQSectionSteps>
    </div>
  );
};

FAQSectionHowItWorks.propTypes = {};

export default FAQSectionHowItWorks;
