import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import { Elements } from '@stripe/react-stripe-js';
import { StripeCheckoutForm } from '..';

import css from './InboxDetails.css';
import config from '../../config';

const InboxStripePayment = ({
  currentTransaction,
  currentUser,
  currentListing,
  intl,
  transactionState,
  fetchUser,
  followSubjectMutation,
  speculatedTransaction,
  onConfirmSpecialOfferPayment,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!transactionState.showCustomerPaymentDetails) {
    return null;
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const confirmParams = {
        speculatedTransaction,
        id: currentTransaction.id,
      };

      await onConfirmSpecialOfferPayment(confirmParams);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const stripePromise = loadStripe(config.stripe.publishableKey);

  const clientSecret = get(
    currentTransaction,
    'attributes.protectedData.stripePaymentIntents.default.stripePaymentIntentClientSecret',
    null
  );

  const stripeAppearance = {
    theme: 'stripe',
    rules: {
      '.Label': {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '6px',
      },
      '.Input': {
        border: '2px solid #cbd5e0',
      },
    },
  };
  const stripeOptions = {
    clientSecret,
    appearance: stripeAppearance,
  };

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <StripeCheckoutForm
        submitClassName={css.submitClassName}
        followSubjectMutation={followSubjectMutation}
        user={currentUser}
        listing={currentListing}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        buttonLabel={intl.formatMessage({ id: 'TransactionPanel.acceptSpecialOfferButton' })}
        currentUser={currentUser}
        fetchUser={fetchUser}
      />
    </Elements>
  );
};

export default injectIntl(InboxStripePayment);
