import React from 'react';
import { arrayOf, bool, func } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { dropWhile, get } from 'lodash';
import { Avatar, InlineTextButton } from '..';
import { formatDate } from '../../util/dates';
import { ensureTransaction } from '../../util/data';
import { getUserTxRole } from '../../util/transaction';

import { propTypes } from '../../util/types';

import css from './ActivityFeed.css';

const Message = props => {
  const { message, intl } = props;

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const firstName = get(message, 'sender.attributes.profile.displayName', '').split(' ')[0];
  return (
    <div className={css.message}>
      <div className={css.avatarWrapper}>
        <Avatar className={css.avatar} user={message.sender} />
        <p className={css.firstName}>{firstName}</p>
      </div>
      <div>
        <p className={css.messageContent}>{message.attributes.content}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{message.attributes.content}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  }
  return items;
};

export const ActivityFeedComponent = props => {
  const {
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const initialMessage = get(transaction, 'attributes.protectedData.message', null);
  const isOwnTransition = ownRole === 'customer';
  const firstName = get(currentTransaction, 'customer.attributes.profile.displayName', '').split(
    ' '
  )[0];
  const sender = currentTransaction.customer;

  const formattedOriginalMessage = initialMessage && (
    <div
      data-testId="own-inquiry-message"
      className={!isOwnTransition ? css.message : css.ownInquiryMessage}
    >
      {!isOwnTransition && (
        <div className={css.avatarWrapper}>
          <Avatar className={css.avatar} user={sender} />
          <p className={css.firstName}>{firstName}</p>
        </div>
      )}
      <div
        className={classNames(css.inquiryContainer, {
          [css.ownInquiry]: isOwnTransition,
          [css.inquiry]: !isOwnTransition,
        })}
      >
        {initialMessage && <div className={css.inquiryMessage}>{initialMessage}</div>}
      </div>
    </div>
  );
  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  return (
    <ul>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {formattedOriginalMessage && formattedOriginalMessage}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        }
        return null;
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = { currentUser: null, transaction: null, messages: null };

ActivityFeedComponent.propTypes = {
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
