import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './FieldTitle.css';

const FieldTitle = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.root, className);

  return <h2 className={classes}>{children}</h2>;
};

FieldTitle.defaultProps = {
  className: null,
  rootClassName: null,
};

FieldTitle.propTypes = {
  className: string,
  rootClassName: string,
};

export default FieldTitle;
