import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { requestUpdateListing } from '../../containers/EditListingPage/EditListingPage.duck';
import { EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { hasLodgingForPackage } from '../../util/package';
import { parseMoneyObjString } from '../../util/currency';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';

const EditListingBaseExperiencePanel = ({
  title,
  listing,
  activity,
  children,
  onUpdateListing,
  params,
}) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [createPackageMode, setCreatePackageMode] = useState(false);
  const publicData = get(listing, 'attributes.publicData', {});
  const allPackages = get(publicData, 'packages', []);
  const allActivityPackages = allPackages.filter(item => item.activity === activity);

  const noneActivityPackages = allPackages.filter(item => item.activity !== activity);
  const [packages, setPackages] = useState(allActivityPackages);

  useEffect(() => {
    const packageId = params?.packageId;

    const packageRawItem = allActivityPackages.find(item => item.id === packageId);

    if (packageRawItem) {
      const packageItem = {
        ...packageRawItem,
        price: parseMoneyObjString(packageRawItem.price),
      };

      setSelectedPackage(packageItem);
      setCreatePackageMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getCommonData = (packageData, dates, timestamp) => {
    let availableDateFrom;
    let availableDateTo;

    // Get initial values which are not exposed in the UI
    const { createdAt } = selectedPackage || {};

    if (dates) {
      const { startDate, endDate } = dates;

      availableDateFrom = moment(startDate).format('YYYY-MM-DD');
      availableDateTo = moment(endDate).format('YYYY-MM-DD');
    }

    let commonData = {};

    if (activity === HUNT_ACTIVITY) {
      commonData = {
        species: packageData.species,
        subSpecies: packageData.subSpecies,
        species_unit: packageData.species_unit,
        methods: packageData.methods,
      };
    } else if (activity === OUTDOOR_RECREATION_ACTIVITY) {
      commonData = {
        methods: packageData.methods,
      };
    }

    return {
      ...commonData,
      createdAt,
      price: JSON.stringify(packageData.price),
      availableDateFrom,
      availableDateTo,
      hasLodging: hasLodgingForPackage(packageData),
      quantity: !packageData.hasQuantityRestricted ? null : packageData.quantity,
      updatedAt: timestamp,
    };
  };

  const baseProps = {
    handleCreatePackage() {
      setSelectedPackage(null);
      setCreatePackageMode(true);
    },
    handleEditPackage(item) {
      setSelectedPackage(item);
      setCreatePackageMode(true);
    },
    async handleTogglePublishPackage(packageId, publish) {
      const filteredPackages = packages.map(item => {
        if (item.id === packageId) {
          return {
            ...item,
            isPublished: publish,
          };
        }
        return item;
      });

      const newPackages = [...filteredPackages, ...noneActivityPackages];

      await onUpdateListing({
        id: listing.id,
        publicData: {
          packages: newPackages,
        },
      });

      setPackages(filteredPackages);
    },
    async handleUpdatePackage(values) {
      let updatesPackages = [...packages];

      const { imageCaptions, dates, ...packageData } = values;

      const timestamp = moment.utc().unix();
      const commonData = getCommonData(packageData, dates, timestamp);

      if (values.id) {
        updatesPackages = packages.map(item => {
          if (item.id === values.id) {
            return {
              ...packageData,
              ...commonData,
              earlyAccessExpiration: item?.earlyAccessExpiration,
            };
          }
          return item;
        });
      } else {
        updatesPackages.push({
          ...commonData,
          id: uuidv4(),
          title: packageData.title,
          description: packageData.description,
          days: packageData.days,
          guests: packageData.guests,
          isPublished: true,
          lodgingPrices: packageData.lodgingPrices,
          activity,
          activities: packageData.activities,
          activitiesInfo: packageData.activitiesInfo,
          images: packageData.images,
          hasQuantityRestricted: packageData.hasQuantityRestricted,
          createdAt: timestamp,
        });
      }

      const newPackages = [...updatesPackages, ...noneActivityPackages];

      // Include species from packages into gameType for searches purposes
      const packagesSpecies = newPackages
        .filter(item => item.isPublished && item?.species?.length > 0)
        .map(item => item.species)
        .flat();

      const packagesSubSpecies = {};

      newPackages
        .filter(item => item.isPublished && Object.keys(item?.subSpecies || {}).length > 0)
        .forEach(item => {
          Object.keys(item.subSpecies).forEach(key => {
            if (!packagesSubSpecies[key]) {
              packagesSubSpecies[key] = [];
            }

            packagesSubSpecies[key] = uniq(packagesSubSpecies[key].concat(item.subSpecies[key]));
          });
        });

      await onUpdateListing({
        id: listing.id,
        publicData: {
          gameTypeKeys: uniq([...packagesSpecies]),
          subSpecies: Object.keys(packagesSubSpecies).length ? packagesSubSpecies : null,
          packages: newPackages,
          imageCaptions,
        },
      });

      setPackages(updatesPackages);
    },
    async handleRemovePackage(packageId) {
      const filteredPackages = packages.filter(item => item.id !== packageId);

      const newPackages = [...filteredPackages, ...noneActivityPackages];

      await onUpdateListing({
        id: listing.id,
        publicData: {
          packages: newPackages,
        },
      });

      setPackages(filteredPackages);
    },
    selectedPackage,
    packages,
    setCreatePackageMode,
    createPackageMode,
  };

  return (
    <EditListingPanel>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{title}</EditListingPanelHeading>
      </EditListingSection>

      <div>{children(baseProps)}</div>
    </EditListingPanel>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: values => dispatch(requestUpdateListing(null, values)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(EditListingBaseExperiencePanel);
