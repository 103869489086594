import React, {
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { injectIntl, intlShape } from 'react-intl';
import { ReactComponent as IconChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as IconChevronRight } from '../../assets/icons/chevron-right.svg';
import css from './ScrollingCarousel.css';

const CarouselContext = React.createContext(null);

export const Step = ({ children, index, className, onClick }) => {
  const { carousel, dispatch } = useContext(CarouselContext);
  const [ref, inView] = useInView({
    threshold: 0.75,
    root: carousel.current,
  });

  useEffect(() => {
    dispatch({ type: 'set_child_visibility', payload: { index, visible: inView } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div ref={ref} className={classnames(css.step, className)} onClick={onClick}>
      {children}
    </div>
  );
};

Step.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
};

Step.defaultProps = {
  className: undefined,
  onClick: () => {},
};

const childVisibilityReducer = (state, action) => {
  switch (action.type) {
    case 'set_child_visibility': {
      const newChildVisibility = [...state.childVisibility];

      // We do it like this (instead of a map) so that children can be added out of order or async
      // and we always have the right length
      newChildVisibility[action.payload.index] = action.payload.visible;

      return {
        childVisibility: newChildVisibility,
      };
    }
    default:
      return state;
  }
};

const ScrollingCarouselComponent = ({
  children,
  className,
  showButtons,
  showDots,
  numberOfDots,
  current,
  intl,
  showNavigationOnHover,
}) => {
  const carousel = useRef();

  // Keep a record of which children are currently visible
  const [state, dispatch] = useReducer(childVisibilityReducer, {
    childVisibility: [],
  });

  const [isHovering, setIsHovering] = useState(false);

  // Each child uses it's own intersection observer to tell us if it's currently visible so
  // we provide `dispatch` for this. We pass a ref to the carousel element for use in the intersection observer
  const carouselContextApi = useMemo(() => ({ carousel, dispatch }), []);

  // How are we looking and where can we go?
  const firstVisibleIndex = state.childVisibility.indexOf(true);
  const firstItemIsVisible = firstVisibleIndex === 0;
  const lastVisibleIndex = state.childVisibility.lastIndexOf(true);
  const lastItemIsVisible = lastVisibleIndex === state.childVisibility.length - 1;
  const anyItemsVisible = !!(firstVisibleIndex >= 0 && lastVisibleIndex >= 0);

  // TODO: Could improve this but it works just fine for now
  const getStepEl = index => carousel.current.querySelectorAll(`.${css.step}`)[index];

  const scrollTo = (el, config) => {
    // Chrome seems to only work sporadically if not in a timeout, in this
    // case the timeout doesn't have any drawbacks so we might as well use it
    setTimeout(() => {
      el.scrollIntoView(config);
    });
  };

  const next = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (lastItemIsVisible) return;

      const scrollToIndex = lastVisibleIndex + 1;
      const el = getStepEl(scrollToIndex);

      if (el) {
        // Bring the target element to the start
        scrollTo(el, {
          behavior: 'smooth',
          inline: 'start',
          block: 'nearest',
        });
      }
    },
    [lastItemIsVisible, lastVisibleIndex]
  );

  const back = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (firstItemIsVisible) return;

      const scrollToIndex = firstVisibleIndex - 1;
      const el = getStepEl(scrollToIndex);

      if (el) {
        // Bring the target element to the end
        scrollTo(el, {
          behavior: 'smooth',
          inline: 'end',
          block: 'nearest',
        });
      }
    },
    [firstItemIsVisible, firstVisibleIndex]
  );

  const goTo = useCallback((e, i) => {
    e.preventDefault();
    const el = getStepEl(i);

    if (el) {
      // Bring the target element to the center
      scrollTo(el, {
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      });
    }
  });

  // Add an index prop to all the steps so they know how to tell us which step they are upon visibility change
  const childrenWithIndex = React.Children.map(children, (child, index) =>
    React.cloneElement(child, { index, debug: true })
  );

  // Work out how to display the dots nicely
  const dotOffset = useRef(0);
  const dotWidth = 12;
  const totalDots = state.childVisibility.length;

  // Try to keep this dot visually in the middle
  const dotToCenterIndex = Math.round((firstVisibleIndex + lastVisibleIndex) / 2);

  // Allow this many dots at the start and the end before we start moving
  const dotMovementNeeded = Math.round(numberOfDots / 2) + 1;

  // Are we near the start or end?
  const centerDotNearStart = dotToCenterIndex < dotMovementNeeded - 1;
  const centerDotNearEnd = dotToCenterIndex > totalDots - dotMovementNeeded;

  // Work out how much we would need to move the dots in order for the given dot
  // to visually appear at the given position
  const offsetForDotAtPosition = (dotIndex, visualPositionIndex) => {
    return (dotIndex + visualPositionIndex) * dotWidth - dotMovementNeeded * dotWidth;
  };

  // Only move the offset if we are sure we have the correct data because
  // it can seem as if nothing is visible when the user is scrolling
  if (anyItemsVisible) {
    // It's nice to keep the dots in place if we are near the beginning or end
    if (centerDotNearStart) {
      // Just show the start of the dot list
      dotOffset.current = 0;
    } else if (centerDotNearEnd) {
      // Just show the end of the dots list
      dotOffset.current = dotWidth * (totalDots - numberOfDots);
    } else {
      // Follow the `dotToCenter`
      dotOffset.current = offsetForDotAtPosition(dotToCenterIndex, 2);
    }
  }

  const dotDistances = state.childVisibility.map((isVisible, i) => {
    return Math.abs(dotToCenterIndex - i);
  });

  useEffect(() => {
    const el = getStepEl(current);

    if (el) {
      scrollTo(el, {
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      });
    }
  }, [current]);

  const shouldShowNavigation = showNavigationOnHover ? isHovering : true;

  return (
    <div
      className={classnames(css.root, className)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={css.carousel} ref={carousel} data-testid="scrolling-carousel-carousel">
        <CarouselContext.Provider value={carouselContextApi}>
          {childrenWithIndex}
        </CarouselContext.Provider>
      </div>

      {shouldShowNavigation && (
        <div className={css.controls}>
          {showButtons && !firstItemIsVisible && (
            <button type="button" onClick={back} className={classnames(css.button, css.buttonLeft)}>
              <IconChevronLeft className={css.icon} />
            </button>
          )}

          {showButtons && !lastItemIsVisible && (
            <button
              type="button"
              onClick={next}
              className={classnames(css.button, css.buttonRight)}
            >
              <IconChevronRight className={css.icon} />
            </button>
          )}
        </div>
      )}

      {showDots && (
        <div className={css.dots}>
          <div className={css.dotsInner} style={{ maxWidth: `${dotWidth * numberOfDots}px` }}>
            {state.childVisibility.map((childVisibility, i) => (
              <button
                type="button"
                onClick={e => goTo(e, i)}
                className={classnames(css.dot, {
                  [css.dotDistance2]: dotDistances[i] === 2,
                  [css.dotDistance3]: dotDistances[i] === 3,
                  [css.dotDistanceGreaterThan3]: dotDistances[i] > 3,
                  [css.dotVisible]: childVisibility,
                })}
                style={{
                  width: `${dotWidth}px`,
                  height: `${dotWidth}px`,
                  transform: `translateX(-${dotOffset.current}px)`,
                }}
                key={`step-${i + 1}`}
                aria-label={intl.formatMessage(
                  { id: 'ScrollingCarousel.goToStep' },
                  { step: i + 1 }
                )}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ScrollingCarouselComponent.propTypes = {
  className: propTypes.string,
  showButtons: propTypes.bool,
  showDots: propTypes.bool,
  numberOfDots: propTypes.number,
  showNavigationOnHover: propTypes.bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

ScrollingCarouselComponent.defaultProps = {
  className: undefined,
  showButtons: true,
  showDots: false,
  numberOfDots: 5,
  showNavigationOnHover: false,
};

export const ScrollingCarousel = injectIntl(ScrollingCarouselComponent);
