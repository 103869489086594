import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './FieldCheckboxList.css';

const FieldCheckboxList = props => {
  const { className, rootClassName, heading, subHeading, children } = props;
  const classes = classNames(
    rootClassName || css.root,
    {
      [css.listWithHeading]: !!heading || !!subHeading,
    },
    className
  );

  return (
    <>
      {heading && <p className={css.heading}>{heading}</p>}
      {subHeading && <p className={css.subHeading}>{subHeading}</p>}

      <ul className={classes}>{children}</ul>
    </>
  );
};

FieldCheckboxList.defaultProps = {
  className: null,
  rootClassName: null,
  heading: null,
  subHeading: null,
};

FieldCheckboxList.propTypes = {
  className: string,
  rootClassName: string,
  heading: string,
  subHeading: string,
};

export default FieldCheckboxList;
