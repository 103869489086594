import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';

import { required, composeValidators, atLeast } from '../../util/validators';
import { propTypes } from '../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldPillInputGroup,
  FieldCondition,
  EditListingActions,
  EditListingError,
  FieldToggle,
  FieldNumberInput,
} from '../../components';
import { campfireOptions } from '../../marketplace-custom-config';

export const EditListingPoliciesFormComponent = ({ backLink, ...props }) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const classes = classNames(className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <EditListingSection>
            <EditListingError fetchErrors={fetchErrors} />
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step1Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="allowsCampfires" />
            </EditListingFieldWrapper>

            <FieldCondition when="allowsCampfires" is>
              <EditListingFieldWrapper>
                <FieldPillInputGroup name="campfires" id="campfires" options={campfireOptions} />
              </EditListingFieldWrapper>

              <EditListingFieldWrapper>
                <FieldTextInput
                  id="campfiresInfo"
                  name="campfiresInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMoreCampfiresInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step2Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="allowsPets" />
            </EditListingFieldWrapper>

            <FieldCondition when="allowsPets" is>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="petsInfo"
                  name="petsInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMorePetsInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step3Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="allowsATV" />
            </EditListingFieldWrapper>

            <FieldCondition when="allowsATV" is>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="ATVInfo"
                  name="ATVInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMoreATVInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step4Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="allowsParking" />
            </EditListingFieldWrapper>

            <FieldCondition when="allowsParking" is>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="parkingInfo"
                  name="parkingInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMoreParkingInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step7Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="hasDriveInAccess" />
            </EditListingFieldWrapper>

            <FieldCondition when="hasDriveInAccess" is>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="driveInAccessInfo"
                  name="driveInAccessInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMoreDriveInAccessInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step8Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldToggle name="hasWalkInAccess" />
            </EditListingFieldWrapper>

            <FieldCondition when="hasWalkInAccess" is>
              <EditListingFieldWrapper>
                <FieldTextInput
                  id="walkInAccessInfo"
                  name="walkInAccessInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingPoliciesForm.tellMoreWalkInAccessInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </FieldCondition>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step9Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldNumberInput name="vehicles" id="vehicles" />
            </EditListingFieldWrapper>

            <EditListingFieldWrapper>
              <FieldTextInput
                id="vehiclesInfo"
                name="vehiclesInfo"
                type="textarea"
                label={intl.formatMessage({
                  id: 'EditListingPoliciesForm.tellMoreVehiclesInfo',
                })}
              />
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingSection
            hasBackground
            title={intl.formatMessage({ id: 'EditListingPoliciesForm.step10Title' })}
            hasBottomMargin
          >
            <EditListingFieldWrapper>
              <FieldNumberInput
                name="sportsmen"
                id="sportsmen"
                max={20}
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingPoliciesForm.sportsmenRequired',
                    })
                  ),
                  atLeast(
                    intl.formatMessage({
                      id: 'EditListingPoliciesForm.sportsmenRequired',
                    }),
                    1
                  )
                )}
              />
            </EditListingFieldWrapper>

            <EditListingFieldWrapper>
              <FieldTextInput
                id="sportsmenInfo"
                name="sportsmenInfo"
                type="textarea"
                label={intl.formatMessage({
                  id: 'EditListingPoliciesForm.tellMoreSportsmenInfo',
                })}
              />
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingSection>
            <EditListingFieldWrapper>
              <FieldTextInput
                id="sportsmenPolicies"
                name="sportsmenPolicies"
                type="textarea"
                label={intl.formatMessage({
                  id: 'EditListingPoliciesForm.sportsmenPoliciesDescription',
                })}
                labelDescription={intl.formatMessage({
                  id: 'EditListingPoliciesForm.sportsmenPoliciesLabelDescription',
                })}
                placeholder={intl.formatMessage({
                  id: 'EditListingPoliciesForm.sportsmenPoliciesPlaceholder',
                })}
              />
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingActions>
            {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

            <EditListingActions.Forward>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
            </EditListingActions.Forward>
          </EditListingActions>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
