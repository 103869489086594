import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  FieldPhoneNumberInput,
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  FieldSelect,
  FieldToggle,
} from '../../components';
import { referralMethodMap } from '../../marketplace-custom-config';

import css from './ContactDetailsForm.css';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

class ContactDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerificationEmailSentMessage: false,
      showCurrentPassword: false,
    };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    const { onResendVerificationEmail } = this.props;

    onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  toggleShowNew() {
    const { showNewPassword } = this.state;

    this.setState({ showNewPassword: !showNewPassword });
  }

  toggleShowCurrent() {
    const { showCurrentPassword } = this.state;

    this.setState({ showCurrentPassword: !showCurrentPassword });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveEmailError,
            savePhoneNumberError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            values,

            submitSucceeded,
            pristine,
          } = fieldRenderProps;
          const { showVerificationEmailSentMessage } = this.state;
          const {
            fname,
            lname,
            role,
            email,
            phoneNumber,
            secondaryPhoneNumber,
            referralMethod,
            smsConsent,
          } = values;
          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { email: currentEmail, emailVerified, pendingEmail, profile } = user.attributes;

          // First Name
          const firstNameLabel = intl.formatMessage({
            id: 'ContactDetailsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          const firstNameChanged = profile.firstName !== fname;

          // Last Name
          const lastNameLabel = intl.formatMessage({
            id: 'ContactDetailsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          const lastNameChanged = profile.lasttName !== lname;

          // Phone
          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;
          const currentSecondaryPhoneNumber = protectedData.secondaryPhoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
          const secondaryPhoneNumberChanged = currentSecondaryPhoneNumber !== secondaryPhoneNumber;

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
          const secondaryPhoneLabel = intl.formatMessage({
            id: 'ContactDetailsForm.secondaryPhoneLabel',
          });

          // Email

          // has the email changed
          const emailChanged = currentEmail !== email;

          const emailLabel = intl.formatMessage({
            id: 'ContactDetailsForm.emailLabel',
          });

          const emailPlaceholder = currentEmail || '';

          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
          );

          const emailTouched = this.submittedValues.email !== values.email;
          const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
            ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
            : null;

          let resendEmailMessage = null;
          if (tooManyVerificationRequests) {
            resendEmailMessage = (
              <span className={css.tooMany}>
                <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
              </span>
            );
          } else if (sendVerificationEmailInProgress || showVerificationEmailSentMessage) {
            resendEmailMessage = (
              <span className={css.emailSent}>
                <FormattedMessage id="ContactDetailsForm.emailSent" />
              </span>
            );
          } else {
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            /* eslint-disable jsx-a11y/interactive-supports-focus */
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            resendEmailMessage = (
              <span
                className={css.helperLink}
                onClick={this.handleResendVerificationEmail}
                role="button"
              >
                <FormattedMessage id="ContactDetailsForm.resendEmailVerificationText" />
              </span>
            );
            /* eslint-enable jsx-a11y/no-static-element-interactions */
          }

          // Email status info: unverified, verified and pending email (aka changed unverified email)
          let emailVerifiedInfo = null;

          if (emailVerified && !pendingEmail && !emailChanged) {
            // Current email is verified and there's no pending unverified email
            emailVerifiedInfo = (
              <span className={css.emailVerified}>
                <FormattedMessage id="ContactDetailsForm.emailVerified" />
              </span>
            );
          } else if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form

            emailVerifiedInfo = (
              <span className={css.emailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.emailUnverified"
                  values={{ resendEmailMessage }}
                />
              </span>
            );
          } else if (pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified

            const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className={css.checkInbox}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailCheckInbox"
                  values={{ pendingEmail: pendingEmailStyled }}
                />
              </span>
            );

            emailVerifiedInfo = (
              <span className={css.pendingEmailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailUnverified"
                  values={{ pendingEmailCheckInbox, resendEmailMessage }}
                />
              </span>
            );
          }

          // Role
          const currentRole = protectedData.role;

          const roleRequiredMessage = intl.formatMessage({
            id: 'SignupForm.roleRequired',
          });
          const rolesRequired = validators.nonEmptyArray(roleRequiredMessage);

          const rolesErrorText = rolesRequired(role);

          const roleChanged = !isEqual(currentRole, role);

          // Referral
          const currentReferralMethod = protectedData.referralMethod;

          const referralMethodRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.otherErrorMessage',
          });
          const referralMethodRequired = validators.required(referralMethodRequiredMessage);

          const referralMethodChanged = !isEqual(currentReferralMethod, referralMethod);

          const otherOption = values.referralMethod === 'other';

          const otherLabel = intl.formatMessage({
            id: 'ContactDetailsForm.otherLabel',
          });
          const otherPlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.otherPlaceholder',
          });

          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'PasswordChangeForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          // password
          const passwordLabel = intl.formatMessage({
            id: 'ContactDetailsForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordRequired',
          });

          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordValidators = emailChanged
            ? validators.composeValidators(passwordRequired, passwordMinLength)
            : null;

          const passwordForEmailFailedMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordFailed',
          });
          const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;
          const passwordForEmailErrorText = isChangeEmailWrongPassword(saveEmailError)
            ? passwordForEmailFailedMessage
            : null;

          const confirmEmailClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: emailChanged,
          });

          // sms consent
          const smsChanged = protectedData.smsConsent !== smsConsent;

          // generic error
          const isGenericEmailError = saveEmailError;

          let genericError = null;

          if (isGenericEmailError && savePhoneNumberError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericFailure" />
              </span>
            );
          } else if (isGenericEmailError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericEmailFailure" />
              </span>
            );
          } else if (savePhoneNumberError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericPhoneNumberFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = submitSucceeded && pristine;
          const submitDisabled =
            invalid ||
            submitReady ||
            inProgress ||
            !(
              firstNameChanged ||
              lastNameChanged ||
              roleChanged ||
              emailChanged ||
              phoneNumberChanged ||
              secondaryPhoneNumberChanged ||
              referralMethodChanged ||
              smsChanged
            );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <div className={css.fieldLabel}>
                  <FormattedMessage id="ContactDetailsForm.contactInformationLabel" />
                </div>

                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>

                <FieldPhoneNumberInput
                  className={css.phone}
                  name="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                />

                <FieldPhoneNumberInput
                  className={css.secondaryPhone}
                  name="secondaryPhoneNumber"
                  id={formId ? `${formId}.secondaryPhoneNumber` : 'secondaryPhoneNumber'}
                  label={secondaryPhoneLabel}
                  placeholder={phonePlaceholder}
                />

                <FieldTextInput
                  className={css.email}
                  type="email"
                  name="email"
                  id={formId ? `${formId}.email` : 'email'}
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  customErrorText={emailTouched ? null : emailTakenErrorText}
                />
                {emailVerifiedInfo}
              </div>

              <div className={confirmEmailClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesInfo" />
                </p>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  name="currentPasswordForEmail"
                  id={formId ? `${formId}.currentPassword` : 'currentPassword'}
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                  customErrorText={passwordTouched ? null : passwordForEmailErrorText}
                />
              </div>

              <div className={css.rolesSection}>
                <div className={css.fieldLabel}>
                  <FormattedMessage id="ContactDetailsForm.roleLabel" />
                </div>

                <FieldCheckbox
                  labelClassName={css.rolesSportsman}
                  id="role-sportsman"
                  name="role"
                  value="sportsman"
                  label={intl.formatMessage({
                    id: 'ContactDetailsForm.sportsmanLabel',
                  })}
                  validate={rolesRequired}
                />
                <FieldCheckbox
                  id="role-landowner"
                  name="role"
                  value="landowner"
                  label={intl.formatMessage({
                    id: 'ContactDetailsForm.landownerLabel',
                  })}
                  validate={rolesRequired}
                />

                {roleChanged && rolesErrorText && (
                  <span className={css.error}>{rolesErrorText}</span>
                )}
              </div>
              {!currentReferralMethod ? (
                <div className={css.referralSection}>
                  <div className={css.fieldLabel}>
                    <FormattedMessage id="ContactDetailsForm.hearAboutUs" />
                  </div>
                  <FieldSelect
                    selectClassName={css.referralSelect}
                    id="referral-select"
                    name="referralMethod"
                  >
                    <option className={css.referralSelectOption} disabled value="">
                      {intl.formatMessage({
                        id: 'ContactDetailsForm.referralSelect',
                      })}
                    </option>
                    {referralMethodMap.map(c => (
                      <option key={c.key} value={c.key}>
                        {c.label}
                      </option>
                    ))}
                  </FieldSelect>
                  {otherOption ? (
                    <FieldTextInput
                      type="textarea"
                      className={css.referralOther}
                      id="referral-input"
                      name="referralOther"
                      label={otherLabel}
                      placeholder={otherPlaceholder}
                      validate={referralMethodRequired}
                      rows={3}
                    />
                  ) : null}
                </div>
              ) : null}

              <div className={css.smsConsentSection}>
                <div className={css.fieldLabel}>
                  <FormattedMessage id="ContactDetailsForm.smsConsentLabel" />
                </div>
                <FieldToggle name="smsConsent" />
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={submitReady}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  secondaryPhoneNumber: null,
  role: [],
  referralMethod: null,
  referralOther: null,
  smsConsent: false,
};

const { bool, func, string, array } = PropTypes;

ContactDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  secondaryPhoneNumber: string,
  role: array,
  referralMethod: string,
  referralOther: string,
  smsConsent: bool,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
