import * as props from '../prop-names';
import { formatEvent, getDefaultUserProps, getEventDate } from '../helpers';

export const trackPaymentMethodSubmittedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.PAYMENT_METHOD_SUBMIT_DATE]: getEventDate(),
    ...getDefaultUserProps(state),
  };

  return event;
};

export const trackPayoutDetailsSubmittedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    ...getDefaultUserProps(state),
  };

  return event;
};
