import React from 'react';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import {
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldPillInputGroup,
  FieldCondition,
  FieldToggle,
  FieldNumberInput,
} from '../../components';
import {
  cabinAmenitiesOptions,
  cabinCookingOptions,
  garbageAreaOptions,
  cabinSafeDrinkingWaterOptions,
} from '../../marketplace-custom-config';
import EditListingBedFields from './EditListingBedFields';
import { composeValidators, required, atLeast } from '../../util/validators';

const EditListingLodgingCabinFields = ({ intl }) => {
  return (
    <>
      <EditListingSection hasBackground hasBottomMargin>
        <EditListingFieldWrapper>
          <FieldNumberInput
            id="cabin.sportsmen"
            name="cabin.sportsmen"
            label={intl.formatMessage({
              id: 'EditListingLodgingCabinFields.sportsmenLabel',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'EditListingLodgingForm.addValue',
                })
              ),
              atLeast(
                intl.formatMessage({
                  id: 'EditListingLodgingForm.valueAtLeastOne',
                }),
                1
              )
            )}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldNumberInput
            id="cabin.bedrooms"
            name="cabin.bedrooms"
            label={intl.formatMessage({
              id: 'EditListingLodgingCabinFields.bedroomsLabel',
            })}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <EditListingBedFields namespace="cabin" />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldTextInput
            id="cabin.bedroomInfo"
            name="cabin.bedroomInfo"
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingForms.additionalInfoLabel',
            })}
          />
        </EditListingFieldWrapper>
      </EditListingSection>

      <EditListingSection
        hasBackground
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step2Title' })}
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasElectricity" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasElectricity" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.electricityInfo"
              name="cabin.electricityInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step3Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasToilet" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasToilet" is>
          <EditListingFieldWrapper>
            <FieldNumberInput
              id="cabin.toilets"
              name="cabin.toilets"
              label={intl.formatMessage({
                id: 'EditListingLodgingCabinFields.toiletCountLabel',
              })}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.toiletInfo"
              name="cabin.toiletInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step4Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasShower" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasShower" is>
          <EditListingFieldWrapper>
            <FieldNumberInput
              id="cabin.showers"
              name="cabin.showers"
              label={intl.formatMessage({
                id: 'EditListingLodgingCabinFields.showerCountLabel',
              })}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldToggle
              name="cabin.hasHotWater"
              label={intl.formatMessage({
                id: 'EditListingLodgingCabinFields.showerHotWaterLabel',
              })}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.showerInfo"
              name="cabin.showerInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step5Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasDrinkingWater" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasDrinkingWater" is>
          <EditListingFieldWrapper>
            <FieldPillInputGroup
              name="cabin.drinkingWater"
              id="cabin.drinkingWater"
              options={cabinSafeDrinkingWaterOptions}
            />
          </EditListingFieldWrapper>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.drinkingWaterInfo"
              name="cabin.drinkingWaterInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step6Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldPillInputGroup
            name="cabin.amenities"
            id="cabin.amenities"
            options={cabinAmenitiesOptions}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldTextInput
            id="cabin.amenitiesInfo"
            name="cabin.amenitiesInfo"
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingForms.additionalInfoLabel',
            })}
          />
        </EditListingFieldWrapper>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step7Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasCooking" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasCooking" is>
          <EditListingFieldWrapper>
            <FieldPillInputGroup
              name="cabin.cooking"
              id="cabin.cooking"
              options={cabinCookingOptions}
              minColumnWidth={150}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.cookingInfo"
              name="cabin.cookingInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step8Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasGarbage" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasGarbage" is>
          <EditListingFieldWrapper>
            <FieldPillInputGroup
              name="cabin.garbage"
              id="cabin.garbage"
              options={garbageAreaOptions}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.garbageInfo"
              name="cabin.garbageInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step9Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.allowsPets" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.allowsPets" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.petsInfo"
              name="cabin.petsInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingCabinFields.step11Title' })}
        hasBackground
      >
        <EditListingFieldWrapper>
          <FieldToggle name="cabin.hasWifi" />
        </EditListingFieldWrapper>

        <FieldCondition when="cabin.hasWifi" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="cabin.wifiInfo"
              name="cabin.wifiInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>
    </>
  );
};

EditListingLodgingCabinFields.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingLodgingCabinFields);
