import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionAccept = ({ transition, user, ownRole, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = get(user, 'attributes.profile.displayName', '');
  const firstName = getFirstName(displayName);

  return (
    <InboxTransitionBase
      header={
        isOwnTransition ? (
          <FormattedMessage
            id="BookingStatus.ownTransitionAccept"
            values={{ displayName: firstName }}
          />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionAccept"
            values={{ displayName: firstName }}
          />
        )
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    >
      {isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.ownTransitionAcceptSubtext"
          values={{ displayName: firstName }}
        />
      ) : (
        <FormattedMessage id="BookingStatus.transitionAcceptSubtext" />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionAccept;
