import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReviewCard from './ReviewCard';
import config from '../../config';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';
import css from './SectionFeaturedReviews.css';

const SectionFeaturedReviews = props => {
  const { featuredReviews } = config.custom;
  const { gold } = props;
  return (
    <div className={css.root} data-testid="featured-reviews-section">
      <h1 className={css.title}>
        <FormattedMessage id="SectionFeaturedReviews.title" />
      </h1>
      <ScrollingCarousel className={css.carousel}>
        {featuredReviews.map(review => (
          <Step key={review.listingId} className={css.step}>
            <ReviewCard review={review} gold={gold} />
          </Step>
        ))}
      </ScrollingCarousel>
    </div>
  );
};

export default SectionFeaturedReviews;
