/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getSeasonInfo } from '../../util/seasonInfo';
import { TertiaryButton, SecondaryButton, Tabs, Tab } from '../../components';
import * as log from '../../util/log';
import css from './SectionSeasonInfo.css';
import { toHumanReadable } from '../../shared/utils';

const SectionSeasonInfo = ({ currentPackage, publicData }) => {
  const [seasonInfo, setSeasonInfo] = useState(null);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [selectedTab, setSelectedTab] = useState('tagAvailability');
  const [selectedLicenseType, setSelectedLicenseType] = useState(null);
  const [selectedHD, setSelectedHD] = useState(null);
  const { species: gameSpecies, species_unit: speciesUnit } = currentPackage;

  const { location: listingLocation } = publicData;

  useEffect(() => {
    const fetchSeasonInfo = async () => {
      try {
        const data = await getSeasonInfo(gameSpecies, speciesUnit, listingLocation);
        if (data.error) {
          log.error('Error fetching season info:', data.message);
          setSeasonInfo({ error: true, message: data.message });
        } else if (data.message) {
          setSeasonInfo({ error: true, message: data.message });
        } else {
          setSeasonInfo(data);
        }
      } catch (error) {
        log.error('Error fetching season info:', error);
      }
    };

    if (speciesUnit) {
      fetchSeasonInfo();
    }
  }, [gameSpecies, speciesUnit, listingLocation]);

  useEffect(() => {
    if (seasonInfo) {
      const firstHDKey = Object.keys(seasonInfo)[0];
      setSelectedHD(firstHDKey);
    }
  }, [seasonInfo, gameSpecies]);

  useEffect(() => {
    if (selectedHD && gameSpecies.length > 0) {
      const firstSpeciesKey = gameSpecies[0];
      setSelectedSpecies(firstSpeciesKey);
    }
  }, [selectedHD, gameSpecies]);

  useEffect(() => {
    if (selectedHD && selectedSpecies) {
      const hdInfo = seasonInfo[selectedHD];
      const speciesInfo = hdInfo ? hdInfo[selectedSpecies] : null;
      const firstLicenseType =
        speciesInfo && speciesInfo.opportunities ? Object.keys(speciesInfo.opportunities)[0] : null;
      setSelectedLicenseType(firstLicenseType);
    }
  }, [selectedHD, selectedSpecies, seasonInfo]);

  const handleHDButtonClick = hd => {
    // If the clicked HD is already selected, return early
    if (hd === selectedHD) {
      return;
    }

    setSelectedHD(hd);
    if (seasonInfo[hd]) {
      const firstSpeciesKey = gameSpecies.find(species => seasonInfo[hd][species]) || null;
      setSelectedSpecies(firstSpeciesKey);
      if (firstSpeciesKey && seasonInfo[hd][firstSpeciesKey].opportunities) {
        const firstLicenseType =
          Object.keys(seasonInfo[hd][firstSpeciesKey].opportunities)[0] || null;
        setSelectedLicenseType(firstLicenseType);
      } else {
        setSelectedLicenseType(null);
      }
    } else {
      log.error(`No season info available for HD: ${hd}`, seasonInfo);
    }
  };

  const handleButtonClick = species => {
    // If the clicked species is already selected, return early
    if (species === selectedSpecies) {
      return;
    }

    setSelectedSpecies(species);
    const newSpeciesData = seasonInfo[species];
    const firstLicenseType =
      newSpeciesData && newSpeciesData.opportunities
        ? Object.keys(newSpeciesData.opportunities)[0]
        : null;
    setSelectedLicenseType(firstLicenseType);
  };

  const handleTabClick = tab => {
    setSelectedTab(tab);
  };

  const handleSelectChange = event => {
    setSelectedLicenseType(event.target.value);
  };

  const renderLicenseTypeSelect = () => {
    const hdInfo = seasonInfo[selectedHD];
    const speciesInfo = hdInfo ? hdInfo[selectedSpecies] : null;
    const options =
      speciesInfo && speciesInfo.opportunities ? Object.keys(speciesInfo.opportunities) : [];

    return (
      <SelectOptions
        options={options}
        selectedValue={selectedLicenseType}
        onChange={handleSelectChange}
      />
    );
  };

  const SelectOptions = ({ options, selectedValue, onChange }) => (
    <select
      className={css.licenseTypeSelector}
      value={selectedValue}
      onChange={onChange}
      data-testid="license-type-select"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );

  const renderTagAvailability = tagAvailability => {
    const renderList = options => (
      <ul>
        {options.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>
    );

    const renderMultipleOptions = options => (
      <p className={css.infoText}>{options.join(' & ')} Opportunities</p>
    );

    return (
      <div>
        <div className={css.tagAvailabilitySection}>
          <h4 className={css.titleHeader}>
            <FormattedMessage id="ListingPage.tagInfo.resident" />
          </h4>
          {tagAvailability.resident.length === 1
            ? renderList(tagAvailability.resident)
            : renderMultipleOptions(tagAvailability.resident)}
        </div>
        <div className={css.tagAvailabilitySection}>
          <h4 className={css.titleHeader}>
            <FormattedMessage id="ListingPage.tagInfo.nonresident" />
          </h4>
          {tagAvailability.nonresident.length === 1
            ? renderList(tagAvailability.nonresident)
            : renderMultipleOptions(tagAvailability.nonresident)}
        </div>
      </div>
    );
  };

  const SeasonList = ({ seasons }) => {
    const hasTitle = season => typeof season === 'object' && !season.START;

    const renderWithTitle = season =>
      Object.entries(season).map(([title, dates]) => {
        return title === 'Season' ? (
          <h4 key={title} className={`${css.titleHeader} ${css.specialTitleHeader}`}>
            {dates}
          </h4>
        ) : (
          <div key={title} className={css.infoText}>
            <span>
              {title}:{' '}
              {dates.START ? (
                <span>
                  {dates.START} - {dates.END}
                </span>
              ) : (
                <span>{dates}</span>
              )}
            </span>
          </div>
        );
      });

    const renderWithoutTitle = season => (
      <p className={css.infoText}>
        {season.START} - {season.END}
      </p>
    );

    return (
      <div data-testid="seasons-list">
        {seasons.flat().map((season, index) => (
          <div key={index}>
            {hasTitle(season) ? renderWithTitle(season) : renderWithoutTitle(season)}
          </div>
        ))}
      </div>
    );
  };

  const OpportunitiesComponent = ({ opportunities }) => {
    if (!Array.isArray(opportunities)) {
      return (
        <p className={css.noInfoText}>
          <FormattedMessage id="ListingPage.tagInfo.noInformationAvailable" />
        </p>
      );
    }

    const renderOpportunity = opportunity => {
      if (typeof opportunity === 'string') {
        return <h4 className={css.titleHeader}>{opportunity}</h4>;
      }

      if (typeof opportunity === 'object') {
        return (
          <div>
            {Object.entries(opportunity).map(([key, value], index) => {
              if (value.trim() !== '') {
                return (
                  <div key={`opportunity-detail-${index}`}>
                    <h4 className={css.titleHeader}>{key.replace('_', ' ')}</h4>
                    <p className={css.infoText}>{value}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        {opportunities.map((opportunityItem, index) => (
          <div key={`opportunity-${index}`} className={css.opportunity}>
            {renderOpportunity(opportunityItem.opportunity)}
            <p className={css.opportunityDetails}>{opportunityItem.opportunity_details}</p>
            <div className={css.seasonDates}>
              <SeasonList seasons={opportunityItem.season_dates} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const isTagAvailabilityAvailable = () => {
    return (
      !!seasonInfo &&
      Object.keys(seasonInfo).some(hd =>
        Object.keys(seasonInfo[hd]).some(species => seasonInfo[hd][species].tagAvailability)
      )
    );
  };

  const isOpportunitiesAvailable = () => {
    return (
      !!seasonInfo &&
      Object.keys(seasonInfo).some(hd =>
        Object.keys(seasonInfo[hd]).some(species => seasonInfo[hd][species].opportunities)
      )
    );
  };

  const renderTabs = () => (
    <Tabs>
      <Tab
        handleClick={() => handleTabClick('tagAvailability')}
        isSelected={selectedTab === 'tagAvailability'}
        data-testid="tag-availability-tab"
      >
        <div className={css.tabContainer}>
          <FormattedMessage id="SectionSeasonInfo.tagAvailability" />
        </div>
      </Tab>
      <Tab
        handleClick={() => handleTabClick('opportunities')}
        isSelected={selectedTab === 'opportunities'}
        data-testid="opportunities-tab"
      >
        <div className={css.tabContainer}>
          <FormattedMessage id="SectionSeasonInfo.opportunities" />
        </div>
      </Tab>
    </Tabs>
  );

  const renderHDTabs = () => {
    if (!seasonInfo || seasonInfo.error) return null;

    if (Object.keys(seasonInfo).length === 1) {
      const hdName = Object.keys(seasonInfo)[0];
      return (
        <div className={css.hdTabs}>
          <h4 className={css.tabHeaders}>
            <FormattedMessage id="ListingPage.singleHuntingDistrict" values={{ hdName }} />
          </h4>
        </div>
      );
    }

    return (
      <div className={css.hdTabs}>
        <h4 className={css.tabHeaders}>
          <FormattedMessage id="ListingPage.huntingDistricts" />
        </h4>
        {Object.keys(seasonInfo).map(hd => {
          const isHDSelected = hd === selectedHD;
          const ButtonComponent = isHDSelected ? TertiaryButton : SecondaryButton;
          const buttonComponentClassName = isHDSelected ? css.hdTab : css.hdTabInactive;

          return (
            <ButtonComponent
              className={buttonComponentClassName}
              key={hd}
              onClick={() => handleHDButtonClick(hd)}
              isSmall
            >
              {hd}
            </ButtonComponent>
          );
        })}
      </div>
    );
  };

  const renderSpeciesPills = () => {
    if (gameSpecies.length === 1) {
      const speciesName = toHumanReadable(gameSpecies[0]);
      return (
        <div className={css.speciesPillContainer}>
          <h4 className={css.tabHeaders}>
            <FormattedMessage id="ListingPage.tagInfo.singleSpecies" values={{ speciesName }} />
          </h4>
        </div>
      );
    }

    return (
      <div className={css.speciesPillContainer}>
        <h4 className={css.tabHeaders}>
          <FormattedMessage id="ListingPage.tagInfo.species" />
        </h4>
        <div className={css.speciesPills}>
          {gameSpecies.map(species => {
            const ButtonComponent = species === selectedSpecies ? TertiaryButton : SecondaryButton;
            const buttonComponentClassName =
              species === selectedSpecies ? css.speciesPill : css.speciesPillInactive;

            return (
              <ButtonComponent
                className={buttonComponentClassName}
                key={species}
                onClick={() => handleButtonClick(species)}
                isSmall
              >
                {toHumanReadable(species)}
              </ButtonComponent>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    if (!seasonInfo || !selectedHD || !selectedSpecies)
      return <FormattedMessage id="ListingPage.seasonInfo.loading" />;

    if (seasonInfo.error) {
      return (
        <div className={css.error}>
          <FormattedMessage id="ListingPage.tagInfo.noInformationAvailable" />
        </div>
      );
    }

    const hdInfo = seasonInfo[selectedHD];
    const speciesInfo = hdInfo ? hdInfo[selectedSpecies] : null;

    if (!speciesInfo) {
      return (
        <div className={css.noInfoText}>
          <FormattedMessage id="ListingPage.tagInfo.noInformationAvailable" />
        </div>
      );
    }

    const licenseTypeInfo =
      speciesInfo.opportunities && speciesInfo.opportunities[selectedLicenseType];
    if (!licenseTypeInfo) {
      return (
        <div>
          <FormattedMessage id="ListingPage.tagInfo.noInformationAvailableForTagType" />
        </div>
      );
    }

    switch (selectedTab) {
      case 'tagAvailability':
        return speciesInfo.tagAvailability ? (
          <div>{renderTagAvailability(speciesInfo.tagAvailability)}</div>
        ) : (
          <div className={css.noInfoText}>
            <FormattedMessage id="ListingPage.tagInfo.noInformationAvailable" />
          </div>
        );
      case 'opportunities':
        return (
          <div>
            <h4 className={css.infoHeader}>
              <FormattedMessage id="ListingPage.chooseOpportunities" />
            </h4>
            {renderLicenseTypeSelect()}
            <OpportunitiesComponent opportunities={licenseTypeInfo.opportunities} />
          </div>
        );
      default:
        return null;
    }
  };

  if (!speciesUnit) {
    return (
      <div className={css.noData}>
        <h3 className={css.header}>
          <FormattedMessage id="ListingPage.seasonInfoTitle" />
        </h3>
        <p>
          <FormattedMessage id="ListingPage.tagInfo.noHuntingUnitProvided" />
        </p>
      </div>
    );
  }

  return (
    <div className={css.root}>
      <h3 className={css.header}>
        <FormattedMessage id="ListingPage.seasonInfoTitle" />
      </h3>
      {renderHDTabs()}
      {renderSpeciesPills()}
      {(isTagAvailabilityAvailable() || isOpportunitiesAvailable()) && (
        <>
          {renderTabs()}
          <div className={css.tabContent} data-testid="tab-content">
            {renderTabContent()}
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(SectionSeasonInfo);
