/* eslint-disable react/no-array-index-key */
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { get } from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import { averageListingRating, getListingLocation, scrollWithOffset } from '../../util/listing';

import { ReactComponent as IconStarAlt } from '../../assets/icons/rating-star-gold.svg';

import css from './ListingPage.css';

const SectionHeading = props => {
  const { richTitle, listing, metaData, reviews = [], hideListingLocation } = props;

  const rating = get(metaData, 'rating', null);
  const fixedRating = rating && rating.toFixed(1);
  const avgRatings =
    fixedRating <= averageListingRating(reviews) ? fixedRating : averageListingRating(reviews);

  const totalReviews = get(metaData, 'totalReviews', null);
  const ratingsLength = totalReviews <= reviews.length ? totalReviews : reviews.length;
  const isReviewed = reviews.length > 0;
  const listingLocation = getListingLocation(listing);
  const listingInfo = listingLocation && !hideListingLocation;
  const showlistingInfo = isReviewed || listingInfo;

  return (
    <div className={css.headingContainer}>
      <h1 className={css.title}>{richTitle}</h1>

      {showlistingInfo && (
        <div className={css.headerReviewsContainer}>
          {isReviewed && (
            <div className={css.listingRating}>
              <IconStarAlt className={css.keyInfoIcon} />
              <Link
                to="#listingReviews"
                scroll={scrollWithOffset}
                onClick={e => e.preventDefault()}
              >
                <FormattedHTMLMessage
                  id="ListingPage.keyInfoReviews"
                  values={{ rating: avgRatings, ratingsLength }}
                />
              </Link>
            </div>
          )}
          {isReviewed && <span className={css.infoSeparator}>•</span>}
          {listingLocation && !hideListingLocation && (
            <Link
              className={css.locationLinkDecor}
              to="#listingMap"
              scroll={scrollWithOffset}
              onClick={e => e.preventDefault()}
            >
              <h2 className={css.subTitle}>in {listingLocation}</h2>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionHeading;
