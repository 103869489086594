import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as IconArchery } from '../../assets/icons/ListingPage/icon-archery.svg';
import { ReactComponent as IconRifle } from '../../assets/icons/ListingPage/icon-rifle.svg';
import { ReactComponent as IconTreeStand } from '../../assets/icons/ListingPage/icon-tree-stand.svg';
import { ReactComponent as IconGroundBlind } from '../../assets/icons/ListingPage/icon-ground-blind.svg';
import { ReactComponent as IconGameCamera } from '../../assets/icons/ListingPage/icon-game-cam.svg';
import { ReactComponent as IconGameFeeder } from '../../assets/icons/ListingPage/icon-game-feeder.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/ListingPage/icon-check.svg';
import { ReactComponent as IconElk } from '../../assets/icons/ListingPage/icon-elk.svg';
import { ReactComponent as IconPheasant } from '../../assets/icons/ListingPage/icon-pheasant.svg';
import { ReactComponent as IconWhitetail } from '../../assets/icons/ListingPage/icon-whitetail.svg';
import { ReactComponent as IconTurkey } from '../../assets/icons/ListingPage/icon-turkey.svg';
import { ReactComponent as IconPig } from '../../assets/icons/ListingPage/icon-pig.svg';
import { ReactComponent as IconAntelope } from '../../assets/icons/ListingPage/icon-antelope.svg';
import { ReactComponent as IconBear } from '../../assets/icons/ListingPage/icon-bear.svg';
import { ReactComponent as IconDuck } from '../../assets/icons/ListingPage/icon-duck.svg';
import { ReactComponent as IconMuleDeer } from '../../assets/icons/ListingPage/icon-mule-deer.svg';
import { ReactComponent as IconUpland } from '../../assets/icons/ListingPage/icon-upland.svg';
import { ReactComponent as IconCoyote } from '../../assets/icons/ListingPage/icon-coyote.svg';
import { ReactComponent as IconWaterfowl } from '../../assets/icons/ListingPage/icon-waterfowl.svg';

import css from './SectionHuntDetails.css';
import { species } from '../../marketplace-custom-config';
import Modal from '../../components/Modal/Modal';
import { Button } from '../../components';

const SectionHuntDetails = props => {
  const { activityData, currentPackage } = props;
  const { species: gameSpecies, methods } = currentPackage;
  const { amenities } = activityData;
  const {
    hasTreeStands,
    hasGroundBlinds,
    hasGameCameras,
    hasGameFeeders,
    treeStands,
    groundBlinds,
    gameCameras,
    gameFeeders,
    treeStandsInfo,
    groundBlindsInfo,
    gameCamerasInfo,
    gameFeedersInfo,
  } = amenities || {};
  const shouldRenderHuntAmenities =
    hasTreeStands || hasGroundBlinds || hasGameCameras || hasGameFeeders;

  const { allowsArchery, allowsFirearms, archeryInfo, firearmsInfo } = methods || {};
  const shouldRenderHuntMethods = allowsArchery || allowsFirearms;
  const shouldRenderHuntModal =
    archeryInfo ||
    firearmsInfo ||
    gameFeedersInfo ||
    gameCamerasInfo ||
    groundBlindsInfo ||
    treeStandsInfo;

  const speciesIcons = {
    elk: IconElk,
    pheasant: IconPheasant,
    whitetail: IconWhitetail,
    antelope: IconAntelope,
    bear: IconBear,
    duck: IconDuck,
    muleDeer: IconMuleDeer,
    hog: IconPig,
    turkey: IconTurkey,
    upland: IconUpland,
    coyote: IconCoyote,
    waterfowl: IconWaterfowl,
  };

  // ex: converts mule-deer to muleDeer
  const toCamelCase = str => {
    return str.replace(/-([a-z])/g, g => g[1].toUpperCase());
  };

  const activeGameList =
    gameSpecies &&
    gameSpecies.map(gameKey => {
      const iconKey = toCamelCase(gameKey);
      const Icons = speciesIcons[iconKey] || IconCheck;

      return (
        <li className={css.detail}>
          <Icons className={css.speciesIcon} />
          {gameKey === 'other' && gameKey.name ? gameKey.name : species?.hunt[gameKey]?.label}
        </li>
      );
    });
  const [isHuntModalOpen, setOpenHuntModal] = useState(false);

  return (
    <div className={css.sectionHuntFishDetails}>
      <div className={css.detailsGroupsContainer}>
        {activeGameList && activeGameList.length > 0 && (
          <div className={css.detailsGroup}>
            <div className={css.detailsSubtitle}>
              <FormattedMessage id="ListingPage.gameTypesTitle" />
            </div>
            <ul className={css.speciesContainer}>{activeGameList}</ul>
          </div>
        )}

        {(allowsFirearms || allowsArchery) && (
          <div className={css.huntingMethodsDetailsGroup}>
            <div className={css.detailsSubtitle}>
              <FormattedMessage id="ListingPage.huntMotTitle" />
            </div>
            <div className={css.huntMethodsContainer}>
              {allowsFirearms && (
                <div className={css.detail}>
                  <IconRifle className={css.huntingFishingIcons} />
                  <FormattedMessage id="ListingPage.huntMotFirearms" />
                </div>
              )}
              {allowsArchery && (
                <div className={css.detail}>
                  <IconArchery className={css.huntingFishingIcons} />
                  <FormattedMessage id="ListingPage.huntMotArchery" />
                </div>
              )}
            </div>
          </div>
        )}
        {shouldRenderHuntAmenities && (
          <div className={css.detailsGroup}>
            <div className={css.detailsSubtitle}>
              <FormattedMessage id="ListingPage.huntAmenities" />
            </div>
            <ul className={css.ammenitiesList}>
              {hasTreeStands && (
                <li className={css.detail}>
                  <IconTreeStand className={css.huntingFishingIcons} />
                  <FormattedMessage id="ListingPage.TreeStands" values={{ quantity: treeStands }} />
                </li>
              )}
              {hasGroundBlinds && (
                <li className={css.detail}>
                  <IconGroundBlind className={css.huntingFishingIcons} />
                  <FormattedMessage
                    id="ListingPage.GroundBlinds"
                    values={{ quantity: groundBlinds }}
                  />
                </li>
              )}
              {hasGameCameras && (
                <li className={css.detail}>
                  <IconGameCamera className={css.huntingFishingIcons} />
                  <FormattedMessage
                    id="ListingPage.GameCameras"
                    values={{ quantity: gameCameras }}
                  />
                </li>
              )}
              {hasGameFeeders && (
                <li className={css.detail}>
                  <IconGameFeeder className={css.huntingFishingIcons} />
                  <FormattedMessage
                    id="ListingPage.GameFeeders"
                    values={{ quantity: gameFeeders }}
                  />
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      {shouldRenderHuntModal && (
        <div>
          <Modal
            id="ListingPage.huntModal"
            isOpen={isHuntModalOpen}
            onClose={() => setOpenHuntModal(!isHuntModalOpen)}
          >
            <h3 className={css.huntFishDetailsTitle}>
              <FormattedMessage id="ListingPage.huntDetailsTitle" />
            </h3>
            <div>
              {shouldRenderHuntMethods && (
                <div>
                  <div className={css.detailsSubtitle}>
                    <FormattedMessage id="ListingPage.huntMotTitle" />
                  </div>
                  {allowsFirearms && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconRifle className={css.huntingFishingIcons} />
                        <FormattedMessage id="ListingPage.huntMotFirearms" />
                      </div>
                      <div className={css.info}>{firearmsInfo}</div>
                    </div>
                  )}

                  {allowsArchery && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconArchery className={css.huntingFishingIcons} />
                        <FormattedMessage id="ListingPage.huntMotArchery" />
                      </div>
                      <div className={css.info}>{archeryInfo}</div>
                    </div>
                  )}
                </div>
              )}
              <hr className={css.totalDivider} />
              {shouldRenderHuntAmenities && (
                <div>
                  <div className={css.detailsSubtitle}>
                    <FormattedMessage id="ListingPage.huntAmenities" />
                  </div>
                  {hasTreeStands && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconTreeStand className={css.huntingFishingIcons} />
                        <FormattedMessage
                          id="ListingPage.TreeStands"
                          values={{ quantity: treeStands }}
                        />
                      </div>
                      <div className={css.info}>{treeStandsInfo}</div>
                    </div>
                  )}

                  {hasGroundBlinds && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconGroundBlind className={css.huntingFishingIcons} />
                        <FormattedMessage
                          id="ListingPage.GroundBlinds"
                          values={{ quantity: groundBlinds }}
                        />
                      </div>
                      <div className={css.info}>{groundBlindsInfo}</div>
                    </div>
                  )}
                  {hasGameCameras && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconGameCamera className={css.huntingFishingIcons} />
                        <FormattedMessage
                          id="ListingPage.GameCameras"
                          values={{ quantity: gameCameras }}
                        />
                      </div>
                      <div className={css.info}>{gameCamerasInfo}</div>
                    </div>
                  )}
                  {hasGameFeeders && (
                    <div className={css.modalDetails}>
                      <div className={css.detail}>
                        <IconGameFeeder className={css.huntingFishingIcons} />
                        <FormattedMessage
                          id="ListingPage.GameFeeders"
                          values={{ quantity: gameFeeders }}
                        />
                      </div>
                      <div className={css.info}>{gameFeedersInfo}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal>
          <div className={css.formFieldWrapper}>
            <Button isFullWidth type="submit" onClick={() => setOpenHuntModal(!isHuntModalOpen)}>
              <div className={css.buttonText}>
                <FormattedMessage id="ListingPage.buttonText" />
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(SectionHuntDetails);
