/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { ReactComponent as IDCheck } from '../../assets/icons/id-check.svg';
import { Form, FieldTextInput, PrimaryButton, NamedLink } from '../../components';
import { propTypes } from '../../util/types';

import css from './SendMessageForm.css';
import { isVerified } from '../../util/user';

const BLUR_TIMEOUT_MS = 100;

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
    this.state = {
      focused: false,
    };
  }

  handleFocus() {
    this.setState({ focused: true });
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    this.setState({ focused: false });

    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    const user = this.props.currentUser;
    const isLandowner = user?.attributes?.profile?.protectedData?.role?.includes('landowner');
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            textAreaClassName,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            currentUser,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const textAreaClasses = classNames(css.textarea, textAreaClassName);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div
                className={classNames(css.textAreaContainer, {
                  // eslint-disable-next-line react/destructuring-assignment
                  [css.textAreaFocused]: this.state.focused,
                })}
              >
                <FieldTextInput
                  inputRootClass={textAreaClasses}
                  type="textarea"
                  id="message"
                  name="message"
                  placeholder={messagePlaceholder}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                />
                <div className={css.submitContainer}>
                  <PrimaryButton
                    className={css.submit}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  >
                    <FormattedMessage id="SendMessageForm.sendMessage" />
                  </PrimaryButton>
                </div>
                {sendMessageError && (
                  <div className={css.errorContainer}>
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  </div>
                )}
              </div>

              {!isLandowner && !isVerified(currentUser) && (
                <div className={css.verificationContainer}>
                  <div className={css.verificationIconContainer}>
                    <IDCheck />
                  </div>

                  <div className={css.verificationContent}>
                    <FormattedMessage id="SendMessageForm.verifyIdReminder" />{' '}
                    <NamedLink className={css.verifySectionLink} name="IDVerificationPage">
                      <FormattedMessage id="AccountDetails.verifyIdLink" />
                    </NamedLink>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  currentUser: null,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  currentUser: propTypes.currentUser,
  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
