import React from 'react';
import { node, string, bool, number, func, array, shape } from 'prop-types';
import { Tooltip } from '..';
import ProtipLink from './ProtipLink';
import ProtipTooltipBody from './ProtipTooltipBody';

const ProtipTooltip = ({
  children,
  body,
  placement,
  trigger,
  tooltipShown,
  onTooltipShown,
  className,
  modifiers,
  description,
}) => {
  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      tooltipShown={tooltipShown}
      onTooltipShown={onTooltipShown}
      tooltip={<ProtipTooltipBody description={description}>{body}</ProtipTooltipBody>}
      className={className}
      modifiers={modifiers}
    >
      {children || <ProtipLink description={description} />}
    </Tooltip>
  );
};

ProtipTooltip.defaultProps = {
  children: null,
  body: null,
  placement: 'auto',
  trigger: 'click',
  tooltipShown: false,
  onTooltipShown: null,
  className: null,
  modifiers: [],
  description: null,
};

ProtipTooltip.propTypes = {
  children: node,
  body: node,
  placement: string,
  trigger: string,
  tooltipShown: bool,
  onTooltipShown: func,
  className: string,
  modifiers: array,
  description: shape({
    id: string,
    title: string.isRequired,
    description: string,
    days: number.isRequired,
    sportsmen: number,
  }),
};

export default ProtipTooltip;
