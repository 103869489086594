/**
 * Special Offer breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the SpecialOfferForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { dateFromLocalToAPI, nightsBetween } from '../../util/dates';
import {
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  TRANSITION_ENQUIRE,
  TRANSITION_ENQUIRE_GATED,
  TX_TRANSITION_ACTOR_PROVIDER,
  getLineItemByCode,
} from '../../util/transaction';
import {
  LINE_ITEM_UNITS,
  LINE_ITEM_SPECIAL_OFFER,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import { PricingDetails } from '../../components';

import css from './SpecialOfferForm.css';

const { Money } = sdkTypes;
// TODO REMOVE UNITPRICE
const estimatedTotalPrice = (unitPrice, unitCount, specialOffer) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const specialOfferPrice = specialOffer ? convertMoneyToNumber(specialOffer) : 0;

  const numericTotalPrice = new Decimal(numericPrice).plus(specialOfferPrice).toNumber();

  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the booking breakdown. This function creates
// an estimated transaction object for that use case.
const estimatedTransaction = (
  unitType,
  bookingStart,
  bookingEnd,
  unitPrice,
  specialOffer,
  transactionId
) => {
  const now = new Date();
  const unitCount = nightsBetween(bookingStart, bookingEnd);

  const specialOfferLineItem = {
    code: LINE_ITEM_SPECIAL_OFFER,
    includeFor: ['customer', 'provider'],
    unitPrice: specialOffer,
    quantity: new Decimal(1),
    lineTotal: specialOffer,
    reversal: false,
  };

  const specialOfferLineItemMaybe = specialOffer ? [specialOfferLineItem] : [];

  const totalPrice = estimatedTotalPrice(unitPrice, unitCount, specialOffer);

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart = dateFromLocalToAPI(
    moment(bookingStart)
      .startOf('day')
      .toDate()
  );
  const serverDayEnd = dateFromLocalToAPI(
    moment(bookingEnd)
      .startOf('day')
      .toDate()
  );

  const specialOfferProviderCommissionEstimate = specialOffer ? -(specialOffer.amount * 0.2) : 0;

  const lineItems = [
    ...specialOfferLineItemMaybe,

    {
      code: unitType,
      includeFor: ['customer', 'provider'],
      unitPrice,
      quantity: new Decimal(unitCount),
      lineTotal: totalPrice,
      reversal: false,
    },
    {
      code: LINE_ITEM_PROVIDER_COMMISSION,
      includeFor: ['provider'],
      unitPrice,
      percentage: new Decimal(10),
      lineTotal: new Money(specialOfferProviderCommissionEstimate, 'USD'),
      quantity: new Decimal(unitCount),
      reversal: false,
    },
  ];

  return {
    id: transactionId,
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_ENQUIRE || TRANSITION_ENQUIRE_GATED,
      payinTotal: totalPrice,
      payoutTotal: totalPrice,
      lineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_PROVIDER,
          transition: TRANSITION_PROVIDER_SPECIAL_OFFER,
        },
      ],
      protectedData: {
        isEstimate: true,
      },
    },
    booking: {
      id: transactionId,
      type: 'booking',
      attributes: {
        displayStart: serverDayStart,
        displayEnd: serverDayEnd,
        start: serverDayStart,
        end: serverDayEnd,
      },
    },
  };
};

const EstimatedBreakdownMaybe = props => {
  const { bookingData, transaction } = props;
  const { unitType, unitPrice, startDate, endDate, specialOffer, quantity } = bookingData;
  const { id: transactionId } = transaction;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const quantityIfUsingUnits = !isUnits || Number.isInteger(quantity);
  const canEstimatePrice = startDate && endDate && unitPrice && quantityIfUsingUnits;
  if (!canEstimatePrice) {
    return null;
  }

  const tx = estimatedTransaction(
    unitType,
    startDate,
    endDate,
    unitPrice,
    specialOffer,
    transactionId
  );
  const hasPrice = getLineItemByCode(tx, LINE_ITEM_SPECIAL_OFFER).length > 0;

  if (hasPrice) {
    return (
      <PricingDetails
        className={css.receipt}
        userRole="provider"
        unitType={unitType}
        transaction={tx}
        booking={tx.booking}
        isSpecialOffer
      />
    );
  }
  return null;
};

export default EstimatedBreakdownMaybe;
