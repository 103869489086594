import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Page } from '../../components';
import { TopbarContainer } from '..';

import css from './InboxPageV2.css';

export const ListLoader = () => {
  return (
    <div className={css.listContainerLoader}>
      <Skeleton borderRadius={12} height={40} style={{ marginBottom: '12px' }} />

      <Skeleton count={6} borderRadius={12} height={122} style={{ marginBottom: '8px' }} />
    </div>
  );
};

export const MessagesLoader = () => {
  return (
    <div className={css.messagesContainerLoader}>
      <Skeleton borderRadius={12} height={40} width={274} style={{ marginBottom: '12px' }} />

      <div
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '10px',
          alignItems: 'center',
        }}
      >
        <Skeleton circle width={40} height={40} />
        <Skeleton borderRadius={12} height={26} width={186} />
      </div>

      <Skeleton count={2} borderRadius={12} height={204} style={{ marginBottom: '30px' }} />
      <Skeleton borderRadius={12} height={204} style={{ marginTop: '50px' }} />
    </div>
  );
};

export const ActionLoader = () => {
  return (
    <div className={css.actionContainerLoader}>
      <div className={css.containerLoader}>
        <Skeleton height="100%" />
      </div>
    </div>
  );
};

const InboxV2Loading = ({ title }) => {
  return (
    <Page title={title}>
      <div className={css.root}>
        <div className={css.topbarContainer}>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxV2Page"
          />
        </div>

        <div className={css.mainContainerLoader}>
          <ListLoader />

          <MessagesLoader />

          <ActionLoader />
        </div>
      </div>
    </Page>
  );
};

export default InboxV2Loading;
