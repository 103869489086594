function toHumanReadable(str) {
  const lowerCaseWords = new Set(['a', 'an', 'the', 'and', 'but', 'for', 'nor', 'or', 'so', 'yet', 'in', 'to', 
    'of', 'at', 'by', 'up', 'for', 'off', 'on']);
  
  return str
    .split('-')
    .map((word, index, array) => {
      // Always capitalize the first and last word
      if (index === 0 || index === array.length - 1) {
        return capitalize(word);
      }
      // Capitalize if the word is not in the lowercase list
      return lowerCaseWords.has(word.toLowerCase()) ? word.toLowerCase() : capitalize(word);
    })
    .join(' ');
};

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

module.exports = {
  toHumanReadable,
};