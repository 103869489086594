/* eslint-disable no-shadow */
import React from 'react';
import { bool, func, shape, string, node, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { required, atLeast, composeValidators } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingFieldWrapper,
  EditListingSection,
  EditListingSectionHeading,
  FieldTextInput,
  FieldNumberInput,
  FieldToggle,
  FieldCondition,
  FieldPillInputGroup,
  FormValidationTooltip,
} from '../../components';
import {
  wildlifeFoodOptions,
  wildlifeWaterSourcesOptions,
  wildlifeShelterOptions,
} from '../../marketplace-custom-config';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingHuntExperienceForm.css';

const EditListingHuntExperienceFormComponent = ({ backLink, listing, onUpdate, ...formProps }) => {
  return (
    <FinalForm
      {...formProps}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          packageSection,
          errors,
          packages,
        } = fieldRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || (packages && !packages.length);
        const allErrors = {
          ...errors,
        };

        if (packages && !packages.length) {
          allErrors.packages = intl.formatMessage({
            id: 'EditListingHuntExperienceForm.packagesRequired',
          });
        }

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={props => {
                onUpdate(props.values);
              }}
            />

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingHuntExperienceForm.foodSourcesLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasFoodSources" />
              </EditListingFieldWrapper>
              <FieldCondition when="hasFoodSources" is>
                <EditListingFieldWrapper>
                  <FieldPillInputGroup
                    name="foodSources"
                    id="foodSources"
                    options={wildlifeFoodOptions}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="foodSourcesInfo"
                    name="foodSourcesInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.tellMoreFoodSources',
                    })}
                    protip={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.tellMoreFoodSourcesProtip',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingHuntExperienceForm.waterSourcesLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasWaterSources" />
              </EditListingFieldWrapper>
              <FieldCondition when="hasWaterSources" is>
                <>
                  <EditListingFieldWrapper>
                    <FieldPillInputGroup
                      name="waterSources"
                      id="waterSources"
                      options={wildlifeWaterSourcesOptions}
                    />
                  </EditListingFieldWrapper>
                  <EditListingFieldWrapper>
                    <FieldTextInput
                      id="waterSourcesInfo"
                      name="waterSourcesInfo"
                      type="textarea"
                      label={intl.formatMessage({
                        id: 'EditListingHuntExperienceForm.tellMoreWaterSources',
                      })}
                      protip={intl.formatMessage({
                        id: 'EditListingHuntExperienceForm.tellMoreWaterSourcesProtip',
                      })}
                    />
                  </EditListingFieldWrapper>
                </>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingHuntExperienceForm.shelterLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasShelter" />
              </EditListingFieldWrapper>
              <FieldCondition when="hasShelter" is>
                <>
                  <EditListingFieldWrapper>
                    <FieldPillInputGroup
                      name="shelter"
                      id="shelter"
                      options={wildlifeShelterOptions}
                    />
                  </EditListingFieldWrapper>
                  <EditListingFieldWrapper>
                    <FieldTextInput
                      id="shelterInfo"
                      name="shelterInfo"
                      type="textarea"
                      label={intl.formatMessage({
                        id: 'EditListingHuntExperienceForm.tellMoreShelter',
                      })}
                      protip={intl.formatMessage({
                        id: 'EditListingHuntExperienceForm.tellMoreShelterProtip',
                      })}
                    />
                  </EditListingFieldWrapper>
                </>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingHuntExperienceForm.foodPlotsLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasFoodPlots" />
              </EditListingFieldWrapper>
            </EditListingSection>
            <EditListingSection>
              <EditListingSectionHeading>
                <FormattedMessage id="EditListingHuntExperienceForm.amenitiesLabel" />
              </EditListingSectionHeading>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingHuntExperienceForm.treeStandsLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasTreeStands" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasTreeStands" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="treeStands"
                    id="treeStands"
                    label={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.treeStandsCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="treeStandsInfo"
                    name="treeStandsInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingHuntExperienceForm.groundBlindsLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasGroundBlinds" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasGroundBlinds" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="groundBlinds"
                    id="groundBlinds"
                    label={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.groundBlindsCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="groundBlindsInfo"
                    name="groundBlindsInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingHuntExperienceForm.gameFeedersLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasGameFeeders" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasGameFeeders" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="gameFeeders"
                    id="gameFeeders"
                    label={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.gameFeedersCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="gameFeedersInfo"
                    name="gameFeedersInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingHuntExperienceForm.gameCamerasLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasGameCameras" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasGameCameras" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="gameCameras"
                    id="gameCameras"
                    label={intl.formatMessage({
                      id: 'EditListingHuntExperienceForm.gameCamerasCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>

                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="gameCamerasInfo"
                    name="gameCamerasInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            {packageSection}

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                {Object.keys(allErrors).length > 0 ? (
                  <FormValidationTooltip
                    placement="top"
                    body={
                      <ul>
                        {Object.keys(allErrors).map(field => (
                          <li key={`tooltip-field-${field}`}>{allErrors[field]}</li>
                        ))}
                      </ul>
                    }
                    title={
                      <FormattedMessage id="EditListingHuntExperienceForm.validationTooltipTitle" />
                    }
                  >
                    <PrimaryButton type="button" disabled>
                      {saveActionMsg}
                    </PrimaryButton>
                  </FormValidationTooltip>
                ) : (
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </PrimaryButton>
                )}
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingHuntExperienceFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingHuntExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  listing: object.isRequired,
  packageSection: node.isRequired,
  packages: array.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingHuntExperienceFormComponent));
