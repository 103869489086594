/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';

import css from './Protip.css';

const ProtipLink = ({ onClick, description }) => {
  const handleClick = e => {
    e.preventDefault();
    if (onClick) onClick();
  };

  return description ? (
    <a href="#" className={css.linkAlt} onClick={handleClick}>
      <span>
        <FormattedMessage id="ProtipLink.description" />
      </span>
    </a>
  ) : (
    <a href="#" className={css.link} onClick={handleClick}>
      <LightIcon className={css.icon} />
      <span>
        <FormattedMessage id="ProtipLink.proTip" />
      </span>
    </a>
  );
};

ProtipLink.protTypes = {
  onClick: func,
};

export default ProtipLink;
