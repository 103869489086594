/* eslint-disable no-shadow */
import React from 'react';
import { bool, func, shape, string, node, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { required, atLeast, composeValidators } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingFieldWrapper,
  EditListingSection,
  EditListingSectionHeading,
  FieldTextInput,
  FieldNumberInput,
  FieldLabel,
  FieldToggle,
  FieldCondition,
  FieldPillInputGroup,
  FormValidationTooltip,
} from '../../components';
import { habitatDiversityOptions } from '../../marketplace-custom-config';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingBirdExperienceForm.css';

const EditListingBirdExperienceFormComponent = ({ backLink, listing, onUpdate, ...formProps }) => {
  return (
    <FinalForm
      {...formProps}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          packageSection,
          errors,
          packages,
        } = fieldRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || (packages && !packages.length);
        const allErrors = {
          ...errors,
        };

        if (packages && !packages.length) {
          allErrors.packages = intl.formatMessage({
            id: 'EditListingHuntExperienceForm.packagesRequired',
          });
        }

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={props => {
                onUpdate(props.values);
              }}
            />

            <EditListingSection>
              <FieldLabel
                description={intl.formatMessage({
                  id: 'EditListingBirdExperienceForm.birdSpeciesDescription',
                })}
              >
                <FormattedMessage id="EditListingBirdExperienceForm.birdSpeciesLabel" />
              </FieldLabel>

              <EditListingFieldWrapper>
                <FieldTextInput
                  id="birdSpecies"
                  name="birdSpecies"
                  type="textarea"
                  placeholder={intl.formatMessage({
                    id: 'EditListingBirdExperienceForm.birdSpeciesPlaceholder',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingBirdExperienceForm.habitatDiversityLabel',
              })}
              hasTopMargin
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldPillInputGroup
                  name="habitatDiversity"
                  id="habitatDiversity"
                  options={habitatDiversityOptions}
                />
              </EditListingFieldWrapper>

              <EditListingFieldWrapper>
                <FieldTextInput
                  id="habitatDiversityInfo"
                  name="habitatDiversityInfo"
                  type="textarea"
                  label={intl.formatMessage({
                    id: 'EditListingBirdExperienceForm.habitatDiversityInfo',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingBirdExperienceForm.hasNationalParksLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasNationalParks" />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingBirdExperienceForm.hasWildlifeRefugesLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasWildlifeRefuges" />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection>
              <EditListingSectionHeading>
                <FormattedMessage id="EditListingBirdExperienceForm.amenitiesLabel" />
              </EditListingSectionHeading>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingBirdExperienceForm.birdBlindsLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasBirdBlinds" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasBirdBlinds" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="birdBlinds"
                    id="birdBlinds"
                    label={intl.formatMessage({
                      id: 'EditListingBirdExperienceForm.birdBlindsCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingBirdExperienceForm.birdFeedersLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasBirdFeeders" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasBirdFeeders" is>
                <EditListingFieldWrapper>
                  <FieldNumberInput
                    name="birdFeeders"
                    id="birdFeeders"
                    label={intl.formatMessage({
                      id: 'EditListingBirdExperienceForm.birdFeedersCountLabel',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.addValue',
                        })
                      ),
                      atLeast(
                        intl.formatMessage({
                          id: 'EditListingLodgingForm.valueAtLeastOne',
                        }),
                        1
                      )
                    )}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              hasBottomMargin
              title={intl.formatMessage({ id: 'EditListingBirdExperienceForm.cateringLabel' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasCatering" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasCatering" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="cateringInfo"
                    name="cateringInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingBirdExperienceForm.cateringInfo',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingBirdExperienceForm.activitiesLabel' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasActivities" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasActivities" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="activitiesInfo"
                    name="activitiesInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingBirdExperienceForm.activitiesInfo',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            {packageSection}

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                {Object.keys(allErrors).length > 0 ? (
                  <FormValidationTooltip
                    placement="top"
                    body={
                      <ul>
                        {Object.keys(allErrors).map(field => (
                          <li key={`tooltip-field-${field}`}>{allErrors[field]}</li>
                        ))}
                      </ul>
                    }
                    title={
                      <FormattedMessage id="EditListingHuntExperienceForm.validationTooltipTitle" />
                    }
                  >
                    <PrimaryButton type="button" disabled>
                      {saveActionMsg}
                    </PrimaryButton>
                  </FormValidationTooltip>
                ) : (
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </PrimaryButton>
                )}
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingBirdExperienceFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingBirdExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  listing: object.isRequired,
  packageSection: node.isRequired,
  package: array.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingBirdExperienceFormComponent));
