import React from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import { chunk } from 'lodash';

import { FieldCheckbox, ValidationError } from '..';

import css from './FishFormItems.css';
import FieldTextInput from '../FieldTextInput/FieldTextInput';

const FishFormItemsRenderer = ({ className, rootClassName, id, options, meta, values }) => {
  const classes = classNames(rootClassName || css.root, className);
  const optionsChunks = chunk(options, Math.ceil(options.length / 4));

  return (
    <div className={classes}>
      <div className={css.columns}>
        {optionsChunks.map(optionsChunk => {
          const columnKey = optionsChunk.map(option => option.key).join(',');

          return (
            <div key={columnKey} className={css.column}>
              {optionsChunk.map(option => {
                const fieldId = `${id}.${option.key}`;
                const fieldIsActive =
                  values && !!values[option.key] && values[option.key].isActive === true;

                return (
                  <div
                    key={fieldId}
                    className={classNames(css.item, {
                      [css.itemActive]: fieldIsActive,
                    })}
                  >
                    <FieldCheckbox
                      id={fieldId}
                      name={`fish.${option.key}.isActive`}
                      label={option.label}
                      labelClassName={css.fieldCheckboxLabel}
                    />

                    {fieldIsActive && option.key === 'other' ? (
                      <FieldTextInput id={fieldId} name={`fish.${option.key}.name`} />
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <ValidationError fieldMeta={{ ...meta }} />
    </div>
  );
};

FishFormItemsRenderer.defaultProps = {
  rootClassName: null,
  className: null,
};

FishFormItemsRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
};

const FishFormItems = props => <FieldArray component={FishFormItemsRenderer} {...props} />;

FishFormItems.propTypes = {
  name: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(FishFormItems);
