import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { HUNT_DIY_ACTIVITY } from '../../marketplace-custom-config';
import { authenticationInProgress } from '../../ducks/Auth.duck';

export const InquiryWizardContext = createContext();

export const STEP_ACTIVITY = {
  label: 'Activity',
  path: 'activity',
};
export const STEP_PACKAGES = {
  label: 'Packages',
  path: 'packages',
};
export const STEP_SPECIES = {
  label: 'Species',
  path: 'species',
};
export const STEP_PARTY = {
  label: 'Guests & Dates',
  path: 'guests-dates',
};
export const STEP_REVIEW = {
  label: 'Review',
  path: 'review',
};
export const STEP_SIGNUP = {
  label: 'Account Creation',
  path: 'signup',
};

const ALL_STEPS = [
  STEP_ACTIVITY,
  STEP_PACKAGES,
  STEP_SPECIES,
  STEP_PARTY,
  STEP_REVIEW,
  STEP_SIGNUP,
];

const ALL_STEPS_FOR_AUTH_USER = [
  STEP_ACTIVITY,
  STEP_PACKAGES,
  STEP_SPECIES,
  STEP_PARTY,
  STEP_REVIEW,
];

const getStepUrl = (params, stepPath) => {
  return createResourceLocatorString(
    'NewInquiryPage',
    routeConfiguration(),
    {
      ...params,
      step: stepPath,
    },
    {}
  );
};

const getStepsFromFormValues = (formValues, params, isAuthenticated) => {
  const isAuthenticatedOnFirstStep = isAuthenticated && params.step === STEP_REVIEW.path;
  const hasPackage = formValues?.selectedPackage;
  const hasDiyPackage = formValues?.selectedPackage?.activity === HUNT_DIY_ACTIVITY;
  if (isAuthenticatedOnFirstStep) {
    return ALL_STEPS_FOR_AUTH_USER.filter(step => {
      if (step.label === STEP_SPECIES.label && hasPackage && !hasDiyPackage) {
        return false;
      }

      return true;
    }).map(step => ({
      ...step,
      url: getStepUrl(params, step.path),
    }));
  }
  return ALL_STEPS.filter(step => {
    if (step.label === STEP_SPECIES.label && hasPackage && !hasDiyPackage) {
      return false;
    }

    return true;
  }).map(step => ({
    ...step,
    url: getStepUrl(params, step.path),
  }));
};

const getStepIndex = (formValues, steps, stepPath) => {
  const currentStepIndex = steps.findIndex(step => step.path === stepPath);
  const stepLabel = steps[currentStepIndex]?.label;

  // Validate the steps against formValues
  switch (stepLabel) {
    case STEP_ACTIVITY.label: {
      return currentStepIndex;
    }

    case STEP_PACKAGES.label: {
      if (formValues.activity) {
        return currentStepIndex;
      }

      return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
    }

    case STEP_SPECIES.label: {
      if (formValues.activity && formValues.selectedPackage) {
        return currentStepIndex;
      }

      return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
    }

    case STEP_PARTY.label: {
      if (formValues.activity && formValues.selectedPackage) {
        if (formValues.selectedPackage?.activity === HUNT_DIY_ACTIVITY && !formValues.species) {
          return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
        }

        return currentStepIndex;
      }

      return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
    }
    case STEP_SIGNUP.label: {
      if (
        formValues.activity &&
        formValues.selectedPackage &&
        formValues.partySize &&
        formValues.bookingDates
      ) {
        return currentStepIndex;
      }

      return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
    }

    case STEP_REVIEW.label: {
      if (
        formValues.activity &&
        formValues.selectedPackage &&
        formValues.partySize &&
        formValues.bookingDates
      ) {
        if (formValues.selectedPackage?.activity === HUNT_DIY_ACTIVITY && !formValues.species) {
          return currentStepIndex;
        }

        return currentStepIndex;
      }

      return getStepIndex(formValues, steps, steps[currentStepIndex - 1]);
    }

    default:
      return 0;
  }
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    isAuthenticated,
    currentUser,
  };
};

const InquiryWizardProviderBase = ({ children, defaultValues, isAuthenticated }) => {
  const { params, history, location } = defaultValues;
  const { state } = location;

  const [formValues, setFormValues] = useState(state || {});

  // Based on values build the steps
  const steps = useMemo(() => getStepsFromFormValues(formValues, params, isAuthenticated), [
    formValues,
    params,
    isAuthenticated,
  ]);
  const stepIndex = getStepIndex(formValues, steps, params.step);

  const next = useCallback(
    values => {
      const possibleSteps = values ? getStepsFromFormValues(values, params) : steps;
      const url = getStepUrl(params, possibleSteps[stepIndex + 1].path);

      history.push(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps, params]
  );

  // Check if step is invalid, if so go back to the correct step
  const paramStepIndex = steps.findIndex(step => step.path === params.step);

  if (paramStepIndex !== stepIndex) {
    const url = getStepUrl(params, steps[stepIndex].path);
    history.push(url);

    return null;
  }

  const isFirst = stepIndex === 0;
  const isLast = stepIndex + 1 === steps.length;
  const step = steps[stepIndex];

  const hasNext = !isLast;
  const hasPrev = !isFirst;

  const value = {
    isFirst,
    isLast,

    hasNext,
    hasPrev,

    next,

    steps,
    step,
    stepIndex,
    setFormValues,
    formValues,
  };

  return <InquiryWizardContext.Provider value={value}>{children}</InquiryWizardContext.Provider>;
};
export const InquiryWizardProvider = withRouter(
  connect(mapStateToProps)(InquiryWizardProviderBase)
);
export const useInquiryWizard = () => useContext(InquiryWizardContext);
