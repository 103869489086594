import React, { useMemo } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import {
  syncPhotoLibraryForSubmission,
  PHOTO_LIBRARY_TABS,
  sortImagesByOrder,
} from '../../util/photoLibrary';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

const getInitialValues = listing => {
  const { title, description, publicData } = listing.attributes;
  const images = get(listing, 'images', []);
  const imageCaptions = get(publicData, 'imageCaptions', {});
  const photoLibraryTags = get(publicData, 'photoLibraryTags', {});
  const propertyImagesOrder = get(publicData, 'propertyImagesOrder', []);
  const sortedImages = sortImagesByOrder(images, propertyImagesOrder);

  return {
    title: title === 'Untitled' ? null : title,
    description,
    categories: publicData.categories,
    images: sortedImages,
    imageCaptions,
    tags: photoLibraryTags,
  };
};

const getSubmissionValues = (listing, values) => {
  const { title, description, categories, tags, images, imageCaptions } = values;

  const {
    updatedImages,
    updatePackages,
    updatedArrivalInstructions,
    updatedImageCaptions,
  } = syncPhotoLibraryForSubmission(listing, images, imageCaptions);

  const propertyImagesIds = Object.keys(tags).filter(imageId =>
    tags[imageId].includes(PHOTO_LIBRARY_TABS.PROPERTY)
  );
  const propertyImagesOrder = images
    .filter(image => propertyImagesIds.includes(image.id.uuid))
    .map(image => image.id.uuid);

  return {
    title: title.trim(),
    description,
    images: updatedImages,
    publicData: {
      categories,
      propertyImagesOrder,
      packages: updatePackages,
      arrivalInstructions: updatedArrivalInstructions,
      imageCaptions: updatedImageCaptions,
      photoLibraryTags: tags,
    },
  };
};

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
  } = props;

  const initialValues = useMemo(() => getInitialValues(listing), [listing]);
  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingDescriptionForm
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          onSubmit(getSubmissionValues(currentListing, values));
        }}
        onChange={onChange}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={config.custom.categories}
        backLink={backLink}
        isPublished={isPublished}
      />
    </EditListingPanel>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default EditListingDescriptionPanel;
