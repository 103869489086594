import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { ProgressBar } from '..';
import css from './EditListingUploadProgressBar.css';

const { string, object } = PropTypes;

// From: https://stackoverflow.com/a/18650828/178959
const formattedFilesize = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const EditListingUploadProgressBar = ({
  className,
  rootClassName,
  progress,
  files,
  intl,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const uploadsInProgress = Object.keys(progress)
    .filter(key => progress[key] >= 0 && progress[key] <= 100)
    .map(key => {
      return {
        file: files[key],
        progress: progress[key],
      };
    })
    .filter(image => !!image.file);

  if (uploadsInProgress.length === 0) return null;

  const bytes = uploadsInProgress.reduce(
    (acc, uploadInProgress) => {
      const { size } = uploadInProgress.file;
      const percent = uploadInProgress.progress;

      return {
        uploaded: acc.uploaded + (percent / 100) * size,
        total: acc.total + size,
      };
    },
    { uploaded: 0, total: 0 }
  );

  const bytesPercent = (bytes.uploaded / bytes.total) * 100;
  const bytesRemaining = bytes.total - bytes.uploaded;

  return (
    <div className={classes}>
      <div className={css.labels}>
        <div className={css.label}>
          {intl.formatMessage(
            {
              id: 'EditListingUploadProgressBar.label',
            },
            {
              uploadsInProgress: uploadsInProgress.length,
            }
          )}
        </div>

        <div className={css.label}>
          {bytesRemaining > 0 &&
            intl.formatMessage(
              {
                id: 'EditListingUploadProgressBar.progress',
              },
              {
                formattedRemainingSize: formattedFilesize(bytesRemaining),
              }
            )}
        </div>
      </div>

      <ProgressBar progress={bytesPercent} />
    </div>
  );
};

EditListingUploadProgressBar.defaultProps = {
  className: '',
  rootClassName: '',
};

EditListingUploadProgressBar.propTypes = {
  className: string,
  rootClassName: string,
  progress: object.isRequired,
  files: object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EditListingUploadProgressBar);
