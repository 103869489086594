import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import NamedLink from '../NamedLink/NamedLink';
import css from './StateCard.css';

const StateCard = ({ title, image, routeName, routeTo, routeParams, className }) => {
  return (
    <NamedLink
      className={classnames(css.root, className)}
      name={routeName}
      to={routeTo}
      params={routeParams}
    >
      <div className={css.imageOverlay} />
      <img src={image} alt={title} className={css.image} />
      <div className={css.title}>{title}</div>
    </NamedLink>
  );
};

StateCard.propTypes = {};

export default StateCard;
