import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';
import SectionHuntDetails from './SectionHuntDetails';
import SectionOutdoorActivities from './SectionOutdoorActivities';

import css from './SectionWhatsIncluded.css';

const SectionWhatsIncluded = ({
  publicData,
  currentPackage,
  activityData,
  onManageDisableScrolling,
}) => {
  const { activity } = currentPackage;

  const isHunting = activity === HUNT_ACTIVITY;
  const isOutdoorRecreation = activity === OUTDOOR_RECREATION_ACTIVITY;

  return (
    <div className={css.root}>
      <h3 className={css.header}>
        <FormattedMessage id="ListingPage.whatsIncludedTitle" />
      </h3>

      {isOutdoorRecreation && <SectionOutdoorActivities currentPackage={currentPackage} />}

      {isHunting && (
        <SectionHuntDetails
          publicData={publicData}
          activityData={activityData}
          currentPackage={currentPackage}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      )}
    </div>
  );
};

export default injectIntl(SectionWhatsIncluded);
