import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { EditListingLocationForm } from '../../forms';
import { reverseGeocode, parseCoordinates, getState, getCounty } from '../../util/maps';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const { geolocation, publicData } = currentListing.attributes;
  const location = publicData && publicData.location ? publicData.location : {};
  const nearestTown = publicData.nearestTown || { search: '', selectedPlace: { origin: {} } };
  const { address, acreage, usState } = location;

  // Only render current search if full place object is available in the URL params
  // TODO bounds are missing - those need to be queried directly from Google Places
  const locationFieldsPresent =
    publicData && publicData.location && publicData.location && geolocation;

  const coordinates = !address && geolocation ? `${geolocation.lat},${geolocation.lng}` : null;

  return {
    nearestTown: {
      search: nearestTown.search,
      selectedPlace: {
        ...nearestTown.selectedPlace,
        origin: new sdkTypes.LatLng(
          nearestTown.selectedPlace.origin.lat,
          nearestTown.selectedPlace.origin.lng
        ),
      },
    },
    acreage,
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin: geolocation, usState },
        }
      : null,
    coordinates,
  };
};

const resolveLocation = async (location, coords) => {
  let locData = { geolocation: null, address: '', usState: '' };

  if (coords && !location.selectedPlace) {
    const [lat, lng] = parseCoordinates(coords);
    const res = await reverseGeocode({ lng, lat });
    locData = {
      ...locData,
      geolocation: new sdkTypes.LatLng(lat, lng),
      usState: getState(res),
      usCounty: getCounty(res),
    };
  } else if (location && location.selectedPlace) {
    locData = {
      ...locData,
      geolocation: location.selectedPlace.origin,
      address: location.selectedPlace.address,
      usState: get(location, 'selectedPlace.usState', null),
      usCounty: get(location, 'selectedPlace.usCounty', null),
    };
  }
  return locData;
};

const getSubmissionValues = async (listing, values) => {
  const { nearestTown, acreage = 0, location, coordinates } = values;
  const { geolocation, address, usState, usCounty } = await resolveLocation(location, coordinates);

  return {
    title: listing.attributes.title || 'Untitled',
    geolocation,
    publicData: {
      location: {
        address,
        usState,
        usCounty,
        acreage,
      },
      nearestTown,
    },
  };
};

const EditListingLocationPanel = ({
  className,
  rootClassName,
  listing,
  onSubmit,
  onChange,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  errors,
  backLink,
}) => {
  const initialValues = getInitialValues(listing);

  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingLocationPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingLocationForm
        initialValues={initialValues}
        onSubmit={async values => {
          onSubmit(await getSubmissionValues(listing, values));
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backLink={backLink}
        isPublished={isPublished}
      />
    </EditListingPanel>
  );
};

const { func, object, string, bool, node } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default EditListingLocationPanel;
