/* eslint-disable no-unused-vars */
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { get } from 'lodash';
import moment from 'moment';
import config from '../../config';
import {
  stripeCancel,
  stripeCapture,
  stripePayout,
  stripeRefund,
  stripeCreate,
  stripeRetrieve,
  compareLineItemTotals,
} from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import {
  txIsEnquired,
  txIsEnquiryExpired,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_CUSTOMER_CANCEL_WITH_REFUND,
  TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND,
  TRANSITION_CUSTOMER_LATE_CANCEL,
  TRANSITION_PROVIDER_EARLY_CANCEL,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_PROVIDER_LATE_CANCEL,
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY,
  TRANSITION_CUSTOMER_ACCEPT_OFFER,
  TRANSITION_CUSTOMER_DECLINE_OFFER,
  TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
  TRANSITION_PROVIDER_CANCEL_OFFER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REFUND_PERIOD_OVER,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_1,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_2,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_3,
  TRANSITION_REQUESTED_CHANGES,
  TRANSITION_ACCEPTED_CHANGES,
  TRANSITION_DECLINED_CHANGES,
  isLegacyPaymentFlow,
  txIsAccepted,
  hasPaymentIntent,
  hasProviderAcceptedChanges,
  txIsCustomerCancellableNonRefundable,
  TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD,
} from '../../util/transaction';
import * as log from '../../util/log';
import analytics from '../../util/affiliate';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { daysBetween } from '../../util/dates';
import { PAYMENT_INTENT_TYPES } from '../../util/types';
import { getListingTimezone, listingStateLinks } from '../../util/listing';
import {
  addMarketplaceEntities,
  getTransactionById,
  getListingById,
} from '../../ducks/marketplaceData.duck';
import {
  fetchCurrentUserHasOrdersSuccess,
  fetchCurrentUserNotifications,
  fetchCurrentUser,
} from '../../ducks/user.duck';
import { updateListing } from '../../ducks/listing.duck';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',
    'variants.square-small',
    'variants.square-small2x',
    'variants.facebook',
    'variants.twitter',
  ],
};

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSACTIONS_TOGETHER_REQUEST =
  'app/TransactionPage/FETCH_TRANSACTIONS_TOGETHER_REQUEST';
export const FETCH_TRANSACTIONS_TOGETHER_SUCCESS =
  'app/TransactionPage/FETCH_TRANSACTIONS_TOGETHER_SUCCESS';
export const FETCH_TRANSACTIONS_TOGETHER_ERROR =
  'app/TransactionPage/FETCH_TRANSACTIONS_TOGETHER_ERROR';

export const INITIATE_OFFER_REQUEST = 'app/TransactionPage/INITIATE_OFFER_REQUEST';
export const INITIATE_OFFER_SUCCESS = 'app/TransactionPage/INITIATE_OFFER_SUCCESS';
export const INITIATE_OFFER_ERROR = 'app/TransactionPage/INITIATE_OFFER_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const CANCEL_SALE_REQUEST = 'app/TransactionPage/CANCEL_SALE_REQUEST';
export const CANCEL_SALE_SUCCESS = 'app/TransactionPage/CANCEL_SALE_SUCCESS';
export const CANCEL_SALE_ERROR = 'app/TransactionPage/CANCEL_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const UPDATE_PARTY_MEMBER_DETAILS_REQUEST =
  'app/TransactionPage/UPDATE_PARTY_MEMBER_DETAILS_REQUEST';
export const UPDATE_PARTY_MEMBER_DETAILS_SUCCESS =
  'app/TransactionPage/UPDATE_PARTY_MEMBER_DETAILS_SUCCESS';
export const UPDATE_PARTY_MEMBER_DETAILS_ERROR =
  'app/TransactionPage/UPDATE_PARTY_MEMBER_DETAILS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const CONFIRM_OFFER_PAYMENT_REQUEST = 'app/TransactionPage/CONFIRM_OFFER_PAYMENT_REQUEST';
export const CONFIRM_OFFER_PAYMENT_SUCCESS = 'app/TransactionPage/CONFIRM_OFFER_PAYMENT_SUCCESS';
export const CONFIRM_OFFER_PAYMENT_ERROR = 'app/TransactionPage/CONFIRM_OFFER_PAYMENT_ERROR';

export const DECLINE_OFFER_SUCCESS = 'app/TransactionPage/DECLINE_OFFER_SUCCESS';
export const CANCEL_OFFER_SUCCESS = 'app/TransactionPage/CANCEL_OFFER_SUCCESS';

export const REQUESTED_CHANGES_REQUEST = 'app/TransactionPage/REQUESTED_CHANGES_REQUEST';
export const REQUESTED_CHANGES_SUCCESS = 'app/TransactionPage/REQUESTED_CHANGES_SUCCESS';
export const REQUESTED_CHANGES_ERROR = 'app/TransactionPage/REQUESTED_CHANGES_ERROR';

export const ACCEPT_CHANGES_REQUEST = 'app/TransactionPage/ACCEPT_CHANGES_REQUEST';
export const ACCEPT_CHANGES_SUCCESS = 'app/TransactionPage/ACCEPT_CHANGES_SUCCESS';
export const ACCEPT_CHANGES_ERROR = 'app/TransactionPage/ACCEPT_CHANGES_ERROR';

export const DECLINE_CHANGES_REQUEST = 'app/TransactionPage/DECLINE_CHANGES_REQUEST';
export const DECLINE_CHANGES_SUCCESS = 'app/TransactionPage/DECLINE_CHANGES_SUCCESS';
export const DECLINE_CHANGES_ERROR = 'app/TransactionPage/DECLINE_CHANGES_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  cancelInProgress: false,
  cancelSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  confirmOfferPaymentError: null,
  fetchTransactionsTogetherError: null,
  fetchTransactionsTogetherTransactions: null,
  currentUsersHaveOrdersTogether: false,
  requestedChangesInProgress: false,
  requestedChangesError: null,
  acceptChangesInProgress: false,
  acceptChangesError: null,
  declineChangesInProgress: false,
  declineChangesError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionInProgress: true,
        fetchTransactionError: null,
        transactionRole: payload.transactionRole,
      };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSACTIONS_TOGETHER_REQUEST:
      return { ...state, fetchTransactionsTogetherError: null };
    case FETCH_TRANSACTIONS_TOGETHER_SUCCESS:
      return {
        ...state,
        fetchTransactionsTogetherTransactions: payload,
        currentUsersHaveOrdersTogether: payload.hasOrdersTogether || false,
      };
    case FETCH_TRANSACTIONS_TOGETHER_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionsTogetherError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case INITIATE_OFFER_REQUEST:
      return { ...state, initiateOfferError: null };
    case INITIATE_OFFER_SUCCESS:
      return { ...state, transaction: payload.transaction };
    case INITIATE_OFFER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOfferError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case CANCEL_SALE_REQUEST:
      return { ...state, cancelInProgress: true, cancelSaleError: null, declineSaleError: null };
    case CANCEL_SALE_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_SALE_ERROR:
      return { ...state, cancelInProgress: false, cancelSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case UPDATE_PARTY_MEMBER_DETAILS_REQUEST:
      return { ...state, updatePartyMemberFormInProgress: true, updatePartyMemberFormError: null };
    case UPDATE_PARTY_MEMBER_DETAILS_SUCCESS:
      return { ...state, updatePartyMemberFormInProgress: false };
    case UPDATE_PARTY_MEMBER_DETAILS_ERROR:
      return {
        ...state,
        updatePartyMemberFormInProgress: false,
        updatePartyMemberFormError: payload,
      };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case CONFIRM_OFFER_PAYMENT_REQUEST:
      return { ...state, confirmOfferPaymentError: null };
    case CONFIRM_OFFER_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_OFFER_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmOfferPaymentError: payload };

    case REQUESTED_CHANGES_REQUEST:
      return {
        ...state,
        requestedChangesInProgress: true,
        requestedChangesError: null,
      };
    case REQUESTED_CHANGES_SUCCESS:
      return { ...state, requestedChangesInProgress: false };
    case REQUESTED_CHANGES_ERROR:
      return {
        ...state,
        requestedChangesInProgress: false,
        requestedChangesError: payload,
      };

    case ACCEPT_CHANGES_REQUEST:
      return {
        ...state,
        acceptChangesInProgress: true,
        acceptChangesError: null,
      };
    case ACCEPT_CHANGES_SUCCESS:
      return { ...state, acceptChangesInProgress: false };
    case ACCEPT_CHANGES_ERROR:
      return {
        ...state,
        acceptChangesInProgress: false,
        acceptChangesError: payload,
      };

    case DECLINE_CHANGES_REQUEST:
      return {
        ...state,
        declineChangesInProgress: true,
        declineChangesError: null,
      };
    case DECLINE_CHANGES_SUCCESS:
      return { ...state, declineChangesInProgress: false };
    case DECLINE_CHANGES_ERROR:
      return {
        ...state,
        declineChangesInProgress: false,
        declineChangesError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};
export const cancelInProgress = state => {
  return state.TransactionPage.cancelInProgress;
};
export const requestChangesInProgress = state => {
  return state.TransactionPage.requestedChangesInProgress;
};

export const acceptChangesInProgress = state => {
  return state.TransactionPage.acceptChangesInProgress;
};

export const declineChangesInProgress = state => {
  return state.TransactionPage.declineChangesInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = txRole => ({
  type: FETCH_TRANSACTION_REQUEST,
  payload: { transactionRole: txRole },
});
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransactionsTogetherRequest = () => ({
  type: FETCH_TRANSACTIONS_TOGETHER_REQUEST,
});

export const fetchTransactionsTogetherSuccess = (transactions, hasOrdersTogether) => ({
  type: FETCH_TRANSACTIONS_TOGETHER_SUCCESS,
  payload: { transactions, hasOrdersTogether },
});

const fetchTransactionsTogetherError = e => ({
  type: FETCH_TRANSACTIONS_TOGETHER_ERROR,
  error: true,
  payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const initiateOfferRequest = () => ({ type: INITIATE_OFFER_REQUEST });
const initiateOfferSuccess = payload => ({
  type: INITIATE_OFFER_SUCCESS,
  payload,
});
const initiateOfferError = e => ({
  type: INITIATE_OFFER_ERROR,
  error: true,
  payload: e,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = id => ({ type: ACCEPT_SALE_SUCCESS, payload: { id } });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const cancelSaleRequest = () => ({ type: CANCEL_SALE_REQUEST });
const cancelSaleSuccess = id => ({ type: CANCEL_SALE_SUCCESS, payload: { id } });
const cancelSaleError = e => ({ type: CANCEL_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = id => ({ type: DECLINE_SALE_SUCCESS, payload: { id } });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const declineOfferSuccess = id => ({ type: DECLINE_OFFER_SUCCESS, payload: { id } });
const cancelOfferSuccess = id => ({ type: CANCEL_OFFER_SUCCESS, payload: { id } });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (txId, messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { txId, messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = txId => ({ type: SEND_MESSAGE_SUCCESS, payload: { txId } });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

export const updatePartyMemberDetailsRequest = () => ({
  type: UPDATE_PARTY_MEMBER_DETAILS_REQUEST,
});

export const updatePartyMemberDetailsSuccess = id => ({
  type: UPDATE_PARTY_MEMBER_DETAILS_SUCCESS,
  payload: { id },
});

export const updatePartyMemberDetailsError = () => ({
  type: UPDATE_PARTY_MEMBER_DETAILS_ERROR,
});

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
const fetchTimeSlotsError = e => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

const confirmOfferPaymentRequest = () => ({ type: CONFIRM_OFFER_PAYMENT_REQUEST });

const confirmOfferPaymentSuccess = id => ({
  type: CONFIRM_OFFER_PAYMENT_SUCCESS,
  payload: { id },
});

const confirmOfferPaymentError = e => ({
  type: CONFIRM_OFFER_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const requestChangesRequest = () => ({ type: REQUESTED_CHANGES_REQUEST });
const requestChangesSuccess = id => ({
  type: REQUESTED_CHANGES_SUCCESS,
  payload: { id },
});
const requestChangesError = e => ({
  type: REQUESTED_CHANGES_ERROR,
  error: true,
  payload: e,
});

const acceptChangesRequest = () => ({ type: ACCEPT_CHANGES_REQUEST });
const acceptChangesSuccess = id => ({
  type: ACCEPT_CHANGES_SUCCESS,
  payload: { id },
});
const acceptChangesError = e => ({
  type: ACCEPT_CHANGES_ERROR,
  error: true,
  payload: e,
});

const declineChangesRequest = () => ({ type: DECLINE_CHANGES_REQUEST });
const declineChangesSuccess = id => ({
  type: DECLINE_CHANGES_SUCCESS,
  payload: { id },
});
const declineChangesError = e => ({
  type: DECLINE_CHANGES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const specialOfferOrder = (orderParams, transactionId, tx) => (dispatch, getState, sdk) => {
  const { bookingDates, bookingData, listing } = orderParams;
  const { bookingStart, bookingEnd } = bookingDates;
  const { anchorTag, listingState, stateStatute } = listingStateLinks(listing);

  let nextTransition = null;

  if (txIsEnquired(tx)) {
    nextTransition = TRANSITION_PROVIDER_SPECIAL_OFFER;
  }

  if (txIsEnquiryExpired(tx)) {
    nextTransition = TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY;
  }

  const protectedData = {
    offerMessage: bookingData.offerMessage,
    stateStatute: stateStatute && stateStatute,
    listingState: stateStatute &&
      listingState &&
      anchorTag && { anchor: anchorTag, state: listingState },
    bookingDates: {},
    utm: { ...analytics.all() },
  };

  // We wanna get the date at the listing location.
  // This will then be used for the display dates which are hooked
  // with our process.end transitions
  const timezone = getListingTimezone(listing);

  const bookingStartDate = moment(bookingStart).format('YYYY-MM-DD');
  const bookingEndDate = moment(bookingEnd).format('YYYY-MM-DD');

  const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
  const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);

  const bookingStartDateInLocaleInUTC = moment(bookingStartDateInLocale).utc();
  const bookingEndDateInLocaleInUTC = moment(bookingEndDateInLocale).utc();

  const bookingDisplayStart = bookingStartDateInLocaleInUTC.toDate();
  const bookingDisplayEnd = bookingEndDateInLocaleInUTC.toDate();

  // We will store the booking dates and timezone into protected data
  // in order to access it later when displaying the dates to the guests
  // (using the listing time, NOT local guest time!!!).
  protectedData.bookingDates = {
    start: bookingStartDateInLocale.format(),
    end: bookingEndDateInLocale.format(),
    timezone,
    days: daysBetween(bookingStartDateInLocale.format(), bookingEndDateInLocale.format()) + 1,
  };

  // The API booking end date is always exclusive. In order to have e.g.
  // 1st of Jul - 2nd of July we need to send end date as 3rd.
  // Important, the 3rd of July won't be blocked from future bookings.
  const apiBookingEnd = moment
    .utc(bookingEnd)
    .set({ hour: 0, minute: 0, seconds: 0 })
    .add(1, 'd')
    .toDate();
  const apiBookingStart = moment
    .utc(bookingStart)
    .set({ hour: 0, minute: 0 })
    .toDate();

  dispatch(initiateOfferRequest());
  const bodyParams = {
    id: transactionId,
    transition: nextTransition,
    params: {
      bookingStart: apiBookingStart,
      bookingEnd: apiBookingEnd,
      bookingDisplayStart,
      bookingDisplayEnd,
      negotiatedTotal: bookingData.specialOffer,
      protectedData,
    },
  };
  const queryParams = {
    include: ['booking', 'provider', 'listing', 'author', 'customer'],
    expand: true,
  };
  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const transaction = entities[0];

      dispatch(addMarketplaceEntities(response));
      dispatch(initiateOfferSuccess({ transaction }));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      return transaction;
    })
    .catch(e => {
      dispatch(initiateOfferError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'special-offer-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listing.id.uuid,
        bookingStart: orderParams.bookingDates.bookingStart,
        bookingEnd: orderParams.bookingDates.bookingEnd,
        lineItems: orderParams.bookingData.specialOffer,
      });
      throw e;
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);
  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.
  // For LandTrust, We've extended this out to requesting up to 365 days,
  // by creating 5 separate requests of up to 90 days each.
  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();

  const params = { listingId, start, end, per_page: maxTimeSlots };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      // As long as bookingRange (configured in config.js) is further out than the next number of timeSlots
      // another request is made, up to 365 days.
      const secondRequest = bookingRange > maxTimeSlots;
      const thirdRequest = bookingRange > maxTimeSlots * 2;
      const fourthRequest = bookingRange > maxTimeSlots * 3;
      const fifthRequest = bookingRange > maxTimeSlots * 4;
      const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
      const thirdRange = Math.min(maxTimeSlots, bookingRange - (maxTimeSlots + secondRange));
      const fourthRange = Math.min(
        maxTimeSlots,
        bookingRange - (maxTimeSlots + secondRange + thirdRange)
      );
      const fifthRange = Math.min(
        maxTimeSlots,
        bookingRange - (maxTimeSlots + secondRange + thirdRange + fourthRange)
      );
      // each of these params are used in their respective API calls to fetch more availability dates.
      const secondParams = {
        listingId,
        start: end,
        end: moment(end)
          .add(secondRange, 'days')
          .toDate(),
        per_page: maxTimeSlots,
      };
      const thirdParams = {
        listingId,
        start: secondParams.end,
        end: moment(secondParams.end)
          .add(thirdRange, 'days')
          .toDate(),
        per_page: maxTimeSlots,
      };
      const fourthParams = {
        listingId,
        start: thirdParams.end,
        end: moment(thirdParams.end)
          .add(fourthRange, 'days')
          .toDate(),
        per_page: maxTimeSlots,
      };
      const fifthParams = {
        listingId,
        start: fourthParams.end,
        end: moment(fourthParams.end)
          .add(fifthRange, 'days')
          .toDate(),
        per_page: maxTimeSlots,
      };
      // depending on what config.dayCountAvailableForBooking
      // up to 5 requests will be made of no more than 90 days each
      switch (true) {
        case fifthRequest:
          dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
            timeSlots.push(...secondBatch);
          });
          dispatch(timeSlotsRequest(thirdParams)).then(thirdBatch => {
            timeSlots.push(...thirdBatch);
          });
          dispatch(timeSlotsRequest(fourthParams)).then(fourthBatch => {
            timeSlots.push(...fourthBatch);
          });
          dispatch(timeSlotsRequest(fifthParams)).then(fifthBatch => {
            timeSlots.push(...fifthBatch);
          });
          dispatch(fetchTimeSlotsSuccess(timeSlots));
          break;
        case fourthRequest:
          dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
            timeSlots.push(...secondBatch);
          });
          dispatch(timeSlotsRequest(thirdParams)).then(thirdBatch => {
            timeSlots.push(...thirdBatch);
          });
          dispatch(timeSlotsRequest(fourthParams)).then(fourthBatch => {
            timeSlots.push(...fourthBatch);
          });
          dispatch(fetchTimeSlotsSuccess(timeSlots));
          break;
        case thirdRequest:
          dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
            timeSlots.push(...secondBatch);
          });
          dispatch(timeSlotsRequest(thirdParams)).then(thirdBatch => {
            timeSlots.push(...thirdBatch);
          });
          dispatch(fetchTimeSlotsSuccess(timeSlots));
          break;
        case secondRequest:
          dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
            timeSlots.push(...secondBatch);
          });
          dispatch(fetchTimeSlotsSuccess(timeSlots));
          break;
        default:
          dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest(txRole));
  let txResponse = null;
  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];
      const currentOrExpiredOrAcceptedEnquiry =
        txIsEnquired(transaction) ||
        txIsEnquiryExpired(transaction) ||
        txIsAccepted(transaction) ||
        txIsCustomerCancellableNonRefundable(transaction);
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        config.enableAvailability && transaction && currentOrExpiredOrAcceptedEnquiry;

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      }
      return response;
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionsTogether = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionsTogetherRequest());

  const apiQueryParams = {
    only: 'sale',
    lastTransitions: [
      'transition/complete',
      'transition/expire-review-period',
      'transition/expire-customer-review-period',
      'transition/review-1-by-customer',
      'transition/review-2-by-customer',
      'transition/expire-provider-review-period',
      'transition/review-1-by-provider',
      'transition/review-2-by-provider',
    ],
    userId: id,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      const hasOrdersTogether = response.data.data && response.data.data.length > 0;

      dispatch(fetchTransactionsTogetherSuccess(transactions, hasOrdersTogether));
    })
    .catch(e => dispatch(fetchTransactionsTogetherError(storableError(e))));
};

export const confirmSpecialOfferPayment = params => async (dispatch, getState, sdk) => {
  const { id } = params;
  dispatch(confirmOfferPaymentRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);
    const packageItem = get(currentTransaction, 'attributes.protectedData.packageLineItem', {});

    const listingId = get(currentTransaction, 'relationships.listing.data.id', null);
    const currentListing = getListingById(getState(), listingId.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeCapture({
        id,
      });
    }

    // Update package quantity
    const packages = get(currentListing, 'attributes.publicData.packages', []);

    const updatedPackages = packages.map(p => {
      if (p.id === packageItem.id && p?.quantity) {
        return {
          ...p,
          quantity: {
            ...p.quantity,
            booked: p.quantity.booked + 1,
          },
        };
      }
      return p;
    });

    dispatch(updateListing(listingId, { publicData: { packages: updatedPackages } }));
    dispatch(addMarketplaceEntities(response));
    dispatch(confirmOfferPaymentSuccess(id));
    dispatch(fetchCurrentUserNotifications());
    return response;
  } catch (e) {
    dispatch(confirmOfferPaymentError(storableError(e)));
    log.error(e, 'confirm-special-offer-failed', {
      txId: id,
      transition: TRANSITION_ACCEPT,
    });
    throw e;
  }
};
export const acceptSpecialOffer = (params, id) => async (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  const { currentUser, currentListing, currentProvider } = params;

  const bodyParams = {
    currentUser: {
      id: currentUser.id,
      stripeCustomer: currentUser.stripeCustomer,
    },
    currentListing: {
      id: currentListing.id,
      title: get(currentListing, 'attributes.title'),
    },
    currentProvider: {
      id: currentProvider.id,
    },
  };

  try {
    let paymentIntent;

    const currentTransaction = getTransactionById(getState(), id.uuid);

    if (!isLegacyPaymentFlow(currentTransaction)) {
      const { data } = await stripeCreate({ id, bodyParams });
      const { stripePaymentIntentClientSecret, stripePaymentIntentId } = data;

      paymentIntent = {
        stripePaymentIntentClientSecret,
        stripePaymentIntentId,
      };
    }

    const protectedData = {
      utm: {
        ...analytics.all(),
      },
    };

    if (paymentIntent) {
      protectedData.stripePaymentIntents = {
        default: paymentIntent,
      };
    }

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_CUSTOMER_ACCEPT_OFFER,
        params: {
          protectedData,
        },
      },
      { expand: true }
    );

    dispatch(addMarketplaceEntities(response));
    dispatch(acceptSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());
    return response;
  } catch (e) {
    dispatch(acceptSaleError(storableError(e)));
    log.error(e, 'accept-sale-failed', {
      txId: id,
      transition: TRANSITION_ACCEPT,
    });
    throw e;
  }
};
export const acceptSale = id => async (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  const currentTransaction = getTransactionById(getState(), id.uuid);
  const listingId = get(currentTransaction, 'relationships.listing.data.id', null);
  const currentListing = getListingById(getState(), listingId.uuid);
  const packageItem = get(currentTransaction, 'attributes.protectedData.packageLineItem', {});

  try {
    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeCapture({
        id,
      });
    }
  } catch (e) {
    dispatch(acceptSaleError(storableError(e)));
    log.error(e, 'accept-sale-failed-capture', {
      txId: id,
      transition: TRANSITION_ACCEPT,
    });
    throw e;
  }

  try {
    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_ACCEPT,
        params: {
          protectedData: {
            utm: { ...analytics.all() },
          },
        },
      },
      { expand: true }
    );

    // Update package quantity
    const packages = get(currentListing, 'attributes.publicData.packages', []);

    const updatedPackages = packages.map(p => {
      if (p.id === packageItem.id && p?.quantity) {
        return {
          ...p,
          quantity: {
            ...p.quantity,
            booked: p.quantity.booked + 1,
          },
        };
      }
      return p;
    });

    dispatch(updateListing(listingId, { publicData: { packages: updatedPackages } }));
    dispatch(addMarketplaceEntities(response));
    dispatch(acceptSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(acceptSaleError(storableError(e)));
    log.error(e, 'accept-sale-failed', {
      txId: id,
      transition: TRANSITION_ACCEPT,
    });
    throw e;
  }
};
// Customer can cancel transaction with Full refund up to 48 hours after the booking is accepted
export const cancelSaleCustomerWithRefund = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_CUSTOMER_CANCEL_WITH_REFUND,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeRefund({
        id,
      });

      if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
        if (hasProviderAcceptedChanges(currentTransaction)) {
          await stripeRefund({
            id,
            intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
          });
        }
      }
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};
// 48 hours after the booking is accepted, the Customer can cancel transaction, with no refund
export const cancelSaleCustomerWithoutRefund = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripePayout({
        id,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};
// Customer can cancel transaction even if booked within 48 hours of booking date, with no refund
export const cancelSaleCustomerLate = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_CUSTOMER_LATE_CANCEL,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripePayout({
        id,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};

// provider can cancel transaction, full refund to customer
export const cancelSaleProviderEarly = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_PROVIDER_EARLY_CANCEL,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeRefund({
        id,
      });

      if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
        if (hasProviderAcceptedChanges(currentTransaction)) {
          await stripeRefund({
            id,
            intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
          });
        }
      }
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};
// provider can cancel transaction with full refund to customer, even if customer can no longer cancel themselves with a full refund
export const cancelSaleProvider = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_PROVIDER_CANCEL,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeRefund({
        id,
      });

      if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
        if (hasProviderAcceptedChanges(currentTransaction)) {
          await stripeRefund({
            id,
            intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
          });
        }
      }
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};
// Provider can cancel sale late, between 48 hours until booking date or closer,
// the customer will receive a full refund and the customer will have the option to review the provider
export const cancelSaleProviderLate = id => async (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_PROVIDER_LATE_CANCEL,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeRefund({
        id,
      });

      if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
        if (hasProviderAcceptedChanges(currentTransaction)) {
          await stripeRefund({
            id,
            intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
          });
        }
      }
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(cancelSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(cancelSaleError(storableError(e)));
    log.error(e, 'cancel-sale-failed', {
      txId: id,
    });
    throw e;
  }
};
export const declineSpecialOffer = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_CUSTOMER_DECLINE_OFFER,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess(id));
      dispatch(declineOfferSuccess(id));
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_CUSTOMER_DECLINE_OFFER,
      });
      throw e;
    });
};
export const cancelSpecialOffer = id => (dispatch, getState, sdk) => {
  if (cancelInProgress(getState())) {
    return Promise.reject(new Error('Cancellation already in progress'));
  }
  dispatch(cancelSaleRequest());

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_PROVIDER_CANCEL_OFFER,
        params: { protectedData: { utm: { ...analytics.all() } } },
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelSaleSuccess(id));
      dispatch(cancelOfferSuccess(id));
      dispatch(fetchCurrentUserNotifications());

      return response;
    })
    .catch(e => {
      dispatch(cancelSaleError(storableError(e)));
      log.error(e, 'cancel-sale-failed', {
        txId: id,
      });
      throw e;
    });
};
export const declineSale = id => async (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const response = await sdk.transactions.transition(
      {
        id,
        transition: TRANSITION_DECLINE,
        params: {
          protectedData: {
            utm: { ...analytics.all() },
          },
        },
      },
      { expand: true }
    );

    if (!isLegacyPaymentFlow(currentTransaction)) {
      await stripeCancel({
        id,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(declineSaleSuccess(id));
    dispatch(fetchCurrentUserNotifications());

    return response;
  } catch (e) {
    dispatch(declineSaleError(storableError(e)));
    log.error(e, 'reject-sale-failed', {
      txId: id,
      transition: TRANSITION_DECLINE,
    });
    throw e;
  }
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const { totalMessages } = getState().TransactionPage;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(txId, messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess(txId));
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess(txId)));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const updatePartyMemberDetails = (values, transaction) => (dispatch, getState, sdk) => {
  const transactionId = transaction.id;
  const lastTransition = get(transaction, 'attributes.lastTransition', null);
  let nextTransition = null;
  if (lastTransition === TRANSITION_CONFIRM_PAYMENT) {
    nextTransition = TRANSITION_PARTY_MEMBERS_SUBMITTED_1;
  }
  if (lastTransition === TRANSITION_ACCEPT) {
    nextTransition = TRANSITION_PARTY_MEMBERS_SUBMITTED_2;
  }
  if (lastTransition === TRANSITION_REFUND_PERIOD_OVER) {
    nextTransition = TRANSITION_PARTY_MEMBERS_SUBMITTED_3;
  }

  dispatch(updatePartyMemberDetailsRequest());

  return sdk.transactions
    .transition({
      id: transactionId,
      transition: nextTransition,
      params: { protectedData: values },
    })
    .then(() => dispatch(fetchTransaction(transactionId, 'customer')))
    .then(() => dispatch(updatePartyMemberDetailsSuccess(transactionId)))
    .then(window.scrollTo(0, 0))
    .catch(() => updatePartyMemberDetailsError());
};

export const requestChanges = (params, id) => async (dispatch, getState, sdk) => {
  if (requestChangesInProgress(getState())) {
    return Promise.reject(new Error('Request changes already in progress'));
  }
  dispatch(requestChangesRequest());

  const {
    currentUser,
    currentListing,
    currentProvider,
    lineItems,
    newValues,
    originalValues,
    partySizeChanged,
    datesChanged,
    createPayment,
  } = params;

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);

    const { data: paymentIntent } = await stripeRetrieve({
      id,
    });

    const lastTransition = get(currentTransaction, 'attributes.lastTransition', null);

    const currentPackage = get(currentTransaction, 'attributes.protectedData.packageLineItem', {});
    const packageTitle = get(currentPackage, 'title', '');
    const listingTitle = get(currentListing, 'attributes.title', '');

    const bodyParams = {
      currentUser: {
        id: currentUser.id,
        stripeCustomer: currentUser.stripeCustomer,
      },
      currentListing: {
        id: currentListing.id,
        title: listingTitle,
      },
      currentProvider: {
        id: currentProvider.id,
      },
      currentPackage: {
        packageId: currentPackage.id,
        packageTitle,
      },
      paymentIntent,
      lineItems,
      overrideDescription: `Booking changes for ${listingTitle} - ${packageTitle}`,
      confirmPayment: true,
    };

    let data;
    let stripePaymentIntentId;
    let customerTotal;
    let applicationTotal;
    let changedTotals;

    if (createPayment) {
      const response = await stripeCreate({ id, bodyParams });
      data = response.data;
      stripePaymentIntentId = data.stripePaymentIntentId;
      customerTotal = data.customerTotal;
      applicationTotal = data.applicationTotal;
      changedTotals = data.changedTotals;
    } else {
      const response = await compareLineItemTotals({ id, lineItems });
      data = response.data;
      customerTotal = data.customerTotal;
      applicationTotal = data.applicationTotal;
      changedTotals = data.changedTotals;
    }

    const stripePaymentIntents = get(
      currentTransaction,
      'attributes.protectedData.stripePaymentIntents',
      {}
    );

    let updatedStripePaymentIntents = {
      ...stripePaymentIntents,
    };

    if (stripePaymentIntentId) {
      updatedStripePaymentIntents = {
        ...updatedStripePaymentIntents,
        [PAYMENT_INTENT_TYPES.BOOKING_CHANGES]: {
          stripePaymentIntentId,
        },
      };
    }

    const updatedLineItems = JSON.stringify(lineItems);

    const originalCustomerTotal = changedTotals.customerTotal?.amount - customerTotal?.amount;
    const originalLandTrustTotal =
      changedTotals.applicationTotal?.amount - applicationTotal?.amount;
    const originalProviderTotal = originalCustomerTotal - originalLandTrustTotal;

    const updatedAmounts = {
      customerTotal: {
        currency: customerTotal?.currency,
        amount: originalCustomerTotal / 100,
      },
      newCustomerTotal: {
        currency: changedTotals.customerTotal?.currency,
        amount: changedTotals.customerTotal?.amount / 100,
      },
      customerTotalDifference: {
        currency: changedTotals.customerTotal?.currency,
        amount: (changedTotals.customerTotal?.amount - originalCustomerTotal) / 100,
      },
      providerTotal: {
        currency: customerTotal?.currency,
        amount: originalProviderTotal / 100,
      },
      newProviderTotal: {
        currency: changedTotals.customerTotal?.currency,
        amount: changedTotals.providerTotal?.amount / 100,
      },
      providerTotalDifference: {
        currency: changedTotals.customerTotal?.currency,
        amount: (changedTotals.providerTotal?.amount - originalProviderTotal) / 100,
      },
      landTrustTotal: {
        currency: applicationTotal?.currency,
        amount: originalLandTrustTotal / 100,
      },
      newLandTrustTotal: {
        currency: changedTotals.applicationTotal?.currency,
        amount: changedTotals.applicationTotal?.amount / 100,
      },
      landTrustTotalDifference: {
        currency: changedTotals.customerTotal?.currency,
        amount: (changedTotals.applicationTotal?.amount - originalLandTrustTotal) / 100,
      },
    };

    const bookingChanges = {
      status: 'pending',
      newValues,
      originalValues,
      updatedLineItems,
      updatedAmounts,
      partySizeChanged,
      datesChanged,
    };

    const updatedProtectedData = {
      stripePaymentIntents: updatedStripePaymentIntents,
      bookingChanges,
    };

    let nextTransition = TRANSITION_REQUESTED_CHANGES;

    if (
      [TRANSITION_REFUND_PERIOD_OVER, TRANSITION_PARTY_MEMBERS_SUBMITTED_3].includes(lastTransition)
    ) {
      nextTransition = TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD;
    }

    const response = await sdk.transactions.transition(
      {
        id,
        transition: nextTransition,
        params: {
          protectedData: updatedProtectedData,
        },
      },
      { expand: true }
    );

    dispatch(addMarketplaceEntities(response));
    dispatch(requestChangesSuccess(id));

    return response;
  } catch (e) {
    dispatch(requestChangesError(storableError(e)));
    log.error(e, 'request-changes-customer-failed', {
      txId: id,
      transition: TRANSITION_ACCEPT,
    });
    throw e;
  }
};

export const acceptChanges = id => async (dispatch, getState, sdk) => {
  if (acceptChangesInProgress(getState())) {
    return Promise.reject(new Error('Accept changes already in progress'));
  }
  dispatch(acceptChangesRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);
    const lastTransition = get(currentTransaction, 'attributes.lastTransition', null);

    const listing = getListingById(
      getState(),
      currentTransaction.relationships.listing.data.id.uuid
    );

    const timezone = getListingTimezone(listing);
    const bookingChanges = get(currentTransaction, 'attributes.protectedData.bookingChanges', {});
    const packageLineItem = get(currentTransaction, 'attributes.protectedData.packageLineItem', {});
    const datesChanged = get(bookingChanges, 'datesChanged', false);
    const partySizeChanged = get(bookingChanges, 'partySizeChanged', false);
    const newValues = get(bookingChanges, 'newValues', {});
    const params = {};
    const updatedProtectedData = {};

    if (datesChanged) {
      // We wanna get the date at the listing location.
      // This will then be used for the display dates which are hooked
      // with our process.end transitions

      const bookingStart = JSON.parse(newValues.dates.startDate);
      const bookingEnd = JSON.parse(newValues.dates.endDate);

      const bookingStartDate = moment(bookingStart).format('YYYY-MM-DD');
      const bookingEndDate = moment(bookingEnd).format('YYYY-MM-DD');

      const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
      const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);

      const bookingStartDateInLocaleInUTC = moment(bookingStartDateInLocale).utc();
      const bookingEndDateInLocaleInUTC = moment(bookingEndDateInLocale).utc();

      const bookingDisplayStart = bookingStartDateInLocaleInUTC.toDate();
      const bookingDisplayEnd = bookingEndDateInLocaleInUTC.toDate();

      updatedProtectedData.bookingDates = {
        start: bookingStartDateInLocale.format(),
        end: bookingEndDateInLocale.format(),
        timezone,
        days: daysBetween(bookingStartDateInLocale.format(), bookingEndDateInLocale.format()) + 1,
      };

      const apiBookingEnd = moment
        .utc(bookingEnd)
        .set({ hour: 0, minute: 0, seconds: 0 })
        .add(1, 'd')
        .toDate();
      const apiBookingStart = moment
        .utc(bookingStart)
        .set({ hour: 0, minute: 0 })
        .toDate();

      params.protectedData = updatedProtectedData;
      params.bookingStart = apiBookingStart;
      params.bookingEnd = apiBookingEnd;
      params.bookingDisplayStart = bookingDisplayStart;
      params.bookingDisplayEnd = bookingDisplayEnd;
    }

    if (partySizeChanged) {
      const newPartySize = get(newValues, 'partySize', 0);
      updatedProtectedData.packageLineItem = {
        ...packageLineItem,
        guestSize: newPartySize,
      };
    }

    updatedProtectedData.bookingChanges = {
      ...bookingChanges,
      status: 'accepted',
    };

    params.protectedData = updatedProtectedData;

    let nextTransition = TRANSITION_ACCEPTED_CHANGES;

    if (lastTransition === TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD) {
      nextTransition = TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD;
    }

    const response = await sdk.transactions.transition(
      {
        id,
        transition: nextTransition,
        params,
      },
      { expand: true }
    );

    dispatch(fetchTransaction(id, 'provider'));

    if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
      await stripeCapture({
        id,
        intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(acceptChangesSuccess(id));

    return response;
  } catch (e) {
    dispatch(acceptChangesError(storableError(e)));
    log.error(e, 'accept-changes-failed', {
      txId: id,
      transition: TRANSITION_ACCEPTED_CHANGES,
    });
    throw e;
  }
};

export const declineChanges = id => async (dispatch, getState, sdk) => {
  if (declineChangesInProgress(getState())) {
    return Promise.reject(new Error('Decline changes already in progress'));
  }
  dispatch(declineChangesRequest());

  try {
    const currentTransaction = getTransactionById(getState(), id.uuid);
    const lastTransition = get(currentTransaction, 'attributes.lastTransition', null);

    const bookingChanges = get(currentTransaction, 'attributes.protectedData.bookingChanges', {});

    const updatedProtectedData = {
      bookingChanges: {
        ...bookingChanges,
        status: 'declined',
      },
    };

    let nextTransition = TRANSITION_DECLINED_CHANGES;

    if (lastTransition === TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD) {
      nextTransition = TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD;
    }

    const response = await sdk.transactions.transition(
      {
        id,
        transition: nextTransition,
        params: {
          protectedData: updatedProtectedData,
        },
      },
      { expand: true }
    );

    if (hasPaymentIntent(currentTransaction, PAYMENT_INTENT_TYPES.BOOKING_CHANGES)) {
      await stripeCancel({
        id,
        intentType: PAYMENT_INTENT_TYPES.BOOKING_CHANGES,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(declineChangesSuccess(id));

    return response;
  } catch (e) {
    dispatch(declineChangesError(storableError(e)));
    log.error(e, 'decline-changes-failed', {
      txId: id,
      transition: TRANSITION_DECLINED_CHANGES,
    });
    throw e;
  }
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  return dispatch(fetchTransaction(txId, txRole)).then(() =>
    Promise.all([dispatch(fetchMessages(txId, 1)), dispatch(fetchNextTransitions(txId))])
  );
};
