/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { bool, func, oneOf, shape, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
// TODO: move this logic to ProfileSettingsPage duck
import {
  savePayoutDetails,
  loadData,
} from '../../containers/StripePayoutPage/StripePayoutPage.duck';
import {
  stripeAccountClearError,
  getStripeConnectAccountLink,
} from '../../ducks/stripeConnectAccount.duck';
import { StripeConnectAccountForm } from '../../forms';
import { NamedRedirect, StripeConnectAccountStatusBox } from '..';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes) => {
  const path = createResourceLocatorString(
    'ProfileSettingsPayoutOnboardingPage',
    routes,
    { returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    // eslint-disable-next-line no-console
    .catch(err => console.error(err));
};

const PayoutDetails = ({
  currentUser,
  getAccountLinkInProgress,
  createStripeAccountError,
  updateStripeAccountError,
  fetchStripeAccountError,
  stripeAccountFetched,
  stripeAccount,
  onPayoutDetailsFormChange,
  onPayoutDetailsFormSubmit,
  onGetStripeConnectAccountLink,
  payoutDetailsSaveInProgress,
  payoutDetailsSaved,
  params,
  intl,
  initData,
}) => {
  useEffect(() => {
    initData();
  }, []);

  const { returnURLType } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  const formDisabled = getAccountLinkInProgress;

  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes);
  const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes);

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const savedCountry = stripeAccountData ? stripeAccountData.country : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
  const showVerificationError = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  // Redirect from success URL to basic path for Payout Details
  if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    return <NamedRedirect name="ProfileSettingsPage" params={{ tab: 'payout-details' }} />;
  }

  return (
    <div>
      <StripeConnectAccountForm
        disabled={formDisabled}
        inProgress={payoutDetailsSaveInProgress}
        ready={payoutDetailsSaved}
        stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
        savedCountry={savedCountry}
        submitButtonText={intl.formatMessage({
          id: 'StripePayoutPage.submitButtonText',
        })}
        stripeAccountError={
          createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
        }
        stripeAccountFetched={stripeAccountFetched}
        onChange={onPayoutDetailsFormChange}
        onSubmit={onPayoutDetailsFormSubmit}
        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
        stripeConnected={stripeConnected}
        noSkip
      >
        {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
          <StripeConnectAccountStatusBox
            type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
            inProgress={getAccountLinkInProgress}
            onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
              'custom_account_verification'
            )}
          />
        ) : stripeConnected && savedCountry ? (
          <StripeConnectAccountStatusBox
            type="verificationSuccess"
            inProgress={getAccountLinkInProgress}
            disabled={payoutDetailsSaveInProgress}
            onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
              'custom_account_update'
            )}
          />
        ) : null}
      </StripeConnectAccountForm>
    </div>
  );
};

PayoutDetails.defaultProps = {
  currentUser: null,
  getAccountLinkInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountFetched: false,
  stripeAccount: null,
  onPayoutDetailsFormChange: null,
  onPayoutDetailsFormSubmit: null,
  onGetStripeConnectAccountLink: null,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  intl: null,
  initData: null,
  params: {
    returnURLType: null,
  },
};

PayoutDetails.propTypes = {
  currentUser: propTypes.currentUser,
  getAccountLinkInProgress: bool,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountFetched: bool,
  stripeAccount: object,
  onPayoutDetailsFormChange: func,
  onPayoutDetailsFormSubmit: func,
  onGetStripeConnectAccountLink: func,
  payoutDetailsSaveInProgress: bool,
  payoutDetailsSaved: bool,
  intl: intlShape,
  initData: func,
  params: shape({
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }),
};
PayoutDetails.displayName = 'PayoutDetails';

const mapStateToProps = state => {
  const {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.StripePayoutPage;
  return {
    currentUser,
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
  };
};

const mapDispatchToProps = dispatch => ({
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  initData: () => dispatch(loadData()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PayoutDetails);
