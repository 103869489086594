import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './FieldCheckboxListItem.css';

const FieldCheckboxListItem = props => {
  const { className, rootClassName, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <li className={classes}>{children}</li>;
};

FieldCheckboxListItem.defaultProps = {
  className: null,
  rootClassName: null,
};

FieldCheckboxListItem.propTypes = {
  className: string,
  rootClassName: string,
};

export default FieldCheckboxListItem;
