/* eslint-disable react/no-danger */
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import css from './Protip.css';

const ProtipTooltipBody = ({ children, description }) => {
  const title = get(description, 'title', null);
  return (
    <div className={css.root}>
      <div className={css.header}>
        {!description && <LightIcon className={css.icon} />}
        {description ? (
          <span>
            {' '}
            <FormattedMessage id="Protip.description" values={{ title }} />
          </span>
        ) : (
          <span>
            {' '}
            <FormattedMessage id="Protip.ProTip" />
          </span>
        )}
      </div>

      {React.isValidElement(children) ? (
        <div className={css.content}>{children}</div>
      ) : (
        <div className={css.content} dangerouslySetInnerHTML={{ __html: children }} />
      )}
    </div>
  );
};

export default ProtipTooltipBody;
