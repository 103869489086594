// ================ Action types ================ //

import { pick, get } from 'lodash';

import config from '../../config';
import { storableError } from '../../util/errors';
import analytics from '../../util/affiliate';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { buildTimeSlotsRequests } from '../../util/listing';
import { TRANSITION_ENQUIRE_GATED } from '../../util/transaction';

export const SET_INITIAL_VALUES = 'app/NewInquiryPage/SET_INITIAL_VALUES';

export const FETCH_LISTING_REQUEST = 'app/NewInquiryPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/NewInquiryPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/NewInquiryPage/FETCH_LISTING_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/NewInquiryPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/NewInquiryPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/NewInquiryPage/FETCH_TIME_SLOTS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/NewInquiryPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/NewInquiryPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/NewInquiryPage/SEND_ENQUIRY_ERROR';

const { UUID } = sdkTypes;

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',
    'variants.square-small',
    'variants.square-small2x',
    'variants.facebook',
    'variants.twitter',
  ],
};

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchListingError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTimeSlotsInProgress: false,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
};

const newInquiryPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTING_REQUEST:
      return { ...state, id: payload.id, fetchListingError: null };
    case FETCH_LISTING_ERROR:
      return { ...state, id: payload.id, fetchListingError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null, fetchTimeSlotsInProgress: true };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload, fetchTimeSlotsInProgress: false };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload, fetchTimeSlotsInProgress: false };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    default:
      return state;
  }
};

export default newInquiryPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchListingRequest = id => ({
  type: FETCH_LISTING_REQUEST,
  payload: { id },
});
export const fetchListingSuccess = id => ({
  type: FETCH_LISTING_SUCCESS,
  payload: { id },
});
export const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = payload => ({ type: SEND_ENQUIRY_SUCCESS, payload });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const fetchListing = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest(listingId));
  dispatch(fetchCurrentUser());

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    ...IMAGE_VARIANTS,
  };

  return sdk.listings
    .show(params)
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      dispatch(fetchListingSuccess(listingId));
    })
    .catch(e => {
      dispatch(fetchListingError(storableError(e)));
    });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  const timeSlots = [];
  const reqs = buildTimeSlotsRequests(listingId, sdk);

  reqs.map(req => {
    return req
      .then(slots => {
        timeSlots.push(...slots);

        dispatch(fetchTimeSlotsSuccess(timeSlots));
      })
      .catch(e => {
        dispatch(fetchTimeSlotsError(storableError(e)));
      });
  });

  return Promise.resolve();
};

export const sendEnquiry = (
  listingId,
  message,
  {
    species,
    fishing,
    partySize,
    speciesCaps,
    potentialEarnings,
    emailStart,
    emailEnd,
    startDate,
    endDate,
    selectedPackage,
    activity,
    listingUSState,
    bookingStart,
    bookingEnd,
    bookingDisplayStart,
    bookingDisplayEnd,
  }
) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  const bodyParams = {
    transition: TRANSITION_ENQUIRE_GATED,
    processAlias: config.bookingProcessAlias,
    params: {
      listingId,
      bookingStart,
      bookingEnd,
      bookingDisplayStart,
      bookingDisplayEnd,
      protectedData: {
        species,
        fishing,
        partySize,
        speciesCaps,
        potentialEarnings,
        emailStart,
        emailEnd,
        startDate,
        endDate,
        message,
        selectedPackage,
        utm: {
          ...analytics.all(),
        },
      },
    },
  };

  const { currentUser } = getState().user;
  const {
    interestedActivities = [],
    preferredHuntingSpecies = [],
    preferredHuntingStates = [],
  } = get(currentUser, 'attributes.profile.publicData');

  if (!interestedActivities.includes(activity)) {
    interestedActivities.push(activity);
  }

  (species || []).forEach(item => {
    if (!preferredHuntingSpecies.includes(item)) {
      preferredHuntingSpecies.push(item);
    }
  });

  if (listingUSState) {
    const stateExists = preferredHuntingStates.some(item => item.address === listingUSState);
    if (!stateExists) {
      preferredHuntingStates.push({
        address: listingUSState,
      });
    }
  }

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      dispatch(sendEnquirySuccess({ listingId, transactionId }));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      return dispatch(
        updateProfile({
          publicData: { interestedActivities, preferredHuntingSpecies, preferredHuntingStates },
        })
      ).then(() => {
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      log.error(e, 'send-inquiry-failed', {
        bodyParams,
      });
      throw e;
    });
};

export const loadData = params => dispatch => {
  const listingId = new UUID(params.id);

  return Promise.all([dispatch(fetchListing(listingId)), dispatch(fetchTimeSlots(listingId))]);
};
