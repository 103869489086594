/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { node } from 'prop-types';
import { get } from 'lodash';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { getPackageImages } from '../../util/listing';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star-gold.svg';
import { AvatarMedium, ResponsiveImage, NamedLink } from '..';

import css from './BookingListingBreakdownSection.css';

const BookingListingBreakdownSection = ({ breakdown, listing, error, packageInfo }) => {
  const currentListing = ensureListing(listing);
  const currentAuthor = ensureUser(currentListing.author);
  const listingTitle = currentListing.attributes.title;
  const packagesImages = getPackageImages(currentListing, packageInfo?.id);
  const firstImage = packagesImages?.length > 0 ? packagesImages[0] : currentListing.images[0];
  const params = {
    id: currentListing.id.uuid,
    slug: createSlug(listingTitle),
    packageId: packageInfo?.id,
  };

  const metadata = get(currentAuthor, 'attributes.profile.metadata', {});
  const { totalReviews = 0, rating = 0 } = metadata || {};

  const backToListing = (
    <NamedLink
      className={css.detailsTitle}
      name={packageInfo ? 'PackagePage' : 'ListingPage'}
      params={params}
    >
      <h1 className={css.detailsTitle}>{packageInfo ? packageInfo.title : listingTitle}</h1>
    </NamedLink>
  );

  return (
    <div data-testId="booking-listing-section" className={css.breakdownWrapper}>
      <div className={css.breakdown}>
        <div className={css.titleInfoWrapper}>
          <div className={css.imageWrapper}>
            <ResponsiveImage
              rootClassName={css.image}
              alt={listingTitle}
              image={firstImage}
              variants={['facebook', 'scaled-large']}
            />
          </div>

          <div className={css.listingTitle}>
            <h2 className={css.detailsTitle}>{backToListing}</h2>
          </div>

          <div className={css.authorWrapper}>
            <AvatarMedium className={css.avatar} user={currentAuthor} />

            <div className={css.authorNameWrapper}>
              <div className={css.authorLabel}>
                <FormattedMessage id="TransactionListingHeading.hostedBy" />
              </div>
              <div className={css.authorName}>
                <span>{currentAuthor?.attributes?.profile?.displayName}</span>

                {totalReviews > 0 && (
                  <div className={css.starWrapper}>
                    {[...Array(Math.round(rating))].map((_, index) => (
                      <IconStar key={`star-${index}`} className={css.starIcon} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {error}
        {breakdown}
      </div>
    </div>
  );
};

BookingListingBreakdownSection.defaultProps = {
  breakdown: null,
  error: null,
};

BookingListingBreakdownSection.propTypes = {
  breakdown: node,
  listing: propTypes.listing.isRequired,
  error: node,
};

export default BookingListingBreakdownSection;
