import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Form, PrimaryButton, FieldSelect } from '../../components';
import { commissionRateMap } from '../../marketplace-custom-config';

import css from './ChangeCommissionForm.css';

const ChangeCommissionForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          intl,
          invalid,
          disabled,
          pristine,
          submitSucceeded,
          updateInProgress,
        } = fieldRenderProps;

        const submitReady = submitSucceeded && pristine;
        const submitDisabled = invalid || disabled || updateInProgress;

        const commisionLabel = intl.formatMessage({
          id: 'ChangeCommissionForm.commission',
        });

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.sectionContainer}>
              <div className={css.sectionContainer}>
                <div className={css.fieldLabel}>
                  <FormattedMessage id="ChangeCommissionForm.adjustRate" />
                </div>
                <div className={css.fieldLabelDescription}>
                  <FormattedMessage id="ChangeCommissionForm.adjustRateInfo" />
                </div>
              </div>
              <div className={css.selectContainer}>
                <FieldSelect
                  selectClassName={css.referralSelect}
                  id="commission-select"
                  name="commissionRate"
                  label={commisionLabel}
                  rootClassName={css.selectField}
                >
                  {commissionRateMap.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>

                <div className={css.buttonContainer}>
                  <PrimaryButton
                    type="submit"
                    isFullWidth
                    inProgress={updateInProgress}
                    ready={submitReady}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="ChangeCommissionForm.saveChanges" />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

ChangeCommissionForm.displayName = 'ChangeCommissionForm';

export default injectIntl(ChangeCommissionForm);
