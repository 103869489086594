/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { ReactComponent as PlusCircle } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as MinusCircle } from '../../assets/icons/minus-circle.svg';

import css from './Accordion.css';

const AccordionItem = ({ item, i }) => {
  const [isExtended, setIsExtended] = useState(false);
  const handleClick = () => {
    setIsExtended(!isExtended);
  };

  return (
    <div>
      <div className={css.accordionItemContainer}>
        <div
          role="button"
          className={css.itemTitle}
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex={i}
        >
          {item?.title}
        </div>

        <button className={css.buttonIcon} onClick={handleClick} type="button">
          {isExtended ? <MinusCircle /> : <PlusCircle />}
        </button>
      </div>

      {isExtended && <p className={css.itemContent}>{item?.content}</p>}
      {isExtended && <hr className={css.totalDivider} />}
    </div>
  );
};

AccordionItem.propTypes = {};

AccordionItem.defaultProps = {};

const Accordion = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <AccordionItem key={`accordion-item-${index}`} item={item} i={index} />
      ))}
    </>
  );
};

Accordion.propTypes = {};

Accordion.defaultProps = {};

export default injectIntl(Accordion);
