import { isArray, isEmpty } from 'lodash';

import {
  SIGNUP_STARTED,
  SUCCESSFUL_REGISTRATION,
  SUCCESSFUL_LOGIN,
  SEARCH,
  LISTING_VIEW,
  PAGE_VIEW,
  PROFILE_UPDATED,
  NEW_LISTING_STARTED,
  NEW_LISTING_COMPLETED,
  BOOKING_REQUEST_SUBMITTED,
  PRICING_VIEW_MOBILE_ONLY,
  BOOKING_REQUEST_ACCEPTED,
  BOOKING_REQUEST_DECLINED,
  NEW_QUERY_INITIATED,
  INITIAL_QUERY_READ,
  ID_VERIFICATION_SUCCESS,
  LISTING_CLICK,
  RESERVE_CLICKED,
  SIGNUP_CLICK,
  ADD_PROPERTY_CLICK,
  BOOKING_REQUEST_STARTED,
  BOOKING_REQUEST_RECEIVED,
  BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER,
  BOOKING_REQUEST_DECLINED_BY_LANDOWNER,
  INITIAL_QUERY_READ_BY_LANDOWNER,
  PAYMENT_METHOD_SUBMITTED,
  PAYOUT_DETAILS_SUBMITTED,
  MESSAGE_SENT,
  MESSAGES_LOADED,
  QUERY_REPLIED_BY_LANDOWNER,
  NEW_QUERY_READ_BY_LANDOWNER,
  REPLY_READ_BY_SPORTSMAN,
  TRANSACTION_CANCELED,
  TRANSACTION_CANCELED_BY_LANDOWNER,
  SPORTSMAN_CANCELED_TRANSACTION,
  TRANSACTION_CANCELED_BY_SPORTSMAN,
  SPORTSMAN_REPLIED,
  LOGOUT,
  SPECIAL_OFFER_SENT,
  SPECIAL_OFFER_ACCEPTED,
  SPECIAL_OFFER_DECLINED,
  SPECIAL_OFFER_CANCELED,
  LISTING_FAVORED,
  LISTING_UNFAVORED,
  SEND_REVIEW_SUCCESS,
  LANDOWNER_REVIEW_SUCCESS,
  SPORTSMAN_REVIEW_SUCCESS,
  PARTY_MEMBERS_SUBMITTED,
  TRANSACTION_PROMO_CODE_APPLIED,
} from './types';

import handlers from './handlers';

import { cleanEventData } from './helpers';

const eventHandlerMap = {
  [PAGE_VIEW]: handlers.trackPageViewEvent,
  [SEARCH]: handlers.trackSearchEvent,

  [SIGNUP_STARTED]: handlers.trackSignupStartedEvent,
  [SUCCESSFUL_REGISTRATION]: handlers.trackSuccessfulRegistrationEvent,
  [SUCCESSFUL_LOGIN]: handlers.trackLoginEvent,
  [LOGOUT]: handlers.trackLogoutEvent,
  [PROFILE_UPDATED]: handlers.trackProfileUpdatedEvent,

  [LISTING_CLICK]: handlers.trackListingClickEvent,
  [SIGNUP_CLICK]: handlers.trackSignupClickedEvent,
  [ADD_PROPERTY_CLICK]: handlers.trackAddPropertyClickedEvent,

  [LISTING_VIEW]: handlers.trackListingViewEvent,
  [PRICING_VIEW_MOBILE_ONLY]: handlers.trackPricingViewMobileOnlyEvent,
  [NEW_LISTING_STARTED]: handlers.trackNewListingStartedEvent,
  [NEW_LISTING_COMPLETED]: handlers.trackNewListingCompletedEvent,
  [LISTING_FAVORED]: handlers.trackListingFavored,
  [LISTING_UNFAVORED]: handlers.trackListingUnfavored,

  [BOOKING_REQUEST_STARTED]: (type, payload, state) => {
    return [
      handlers.trackReserveClickedEvent(RESERVE_CLICKED, payload, state),
      handlers.trackBookingRequestStartedEvent(type, payload, state),
    ];
  },
  [BOOKING_REQUEST_SUBMITTED]: (type, payload, state) => {
    return [
      handlers.trackBookingRequestSubmittedEvent(type, payload, state),
      handlers.trackBookingRequestReceivedEvent(BOOKING_REQUEST_RECEIVED, payload, state),
    ];
  },
  [BOOKING_REQUEST_ACCEPTED]: (type, payload, state) => {
    return [
      handlers.trackBookingRequestAcceptedEvent(type, payload, state),
      handlers.trackBookingAcceptedByLandownerEvent(
        BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER,
        payload,
        state
      ),
    ];
  },
  [BOOKING_REQUEST_DECLINED]: (type, payload, state) => {
    return [
      handlers.trackBookingRequestDeclinedEvent(type, payload, state),
      handlers.trackBookingDeclinedByLandownerEvent(
        BOOKING_REQUEST_DECLINED_BY_LANDOWNER,
        payload,
        state
      ),
    ];
  },
  [SPECIAL_OFFER_SENT]: (type, payload, state) => {
    return handlers.trackNewOfferCreatedEvent(type, payload, state);
  },
  [SPECIAL_OFFER_ACCEPTED]: (type, payload, state) => {
    return handlers.trackOfferAcceptedPaidEvent(type, payload, state);
  },
  [SPECIAL_OFFER_DECLINED]: (type, payload, state) => {
    return handlers.trackOfferDeclinedEvent(type, payload, state);
  },
  [SPECIAL_OFFER_CANCELED]: (type, payload, state) => {
    return handlers.trackOfferCanceledEvent(type, payload, state);
  },
  [NEW_QUERY_INITIATED]: handlers.trackNewQueryInitiatedEvent,

  [MESSAGES_LOADED]: (type, payload, state) => {
    return [
      handlers.trackInitialQueryReadEvent(INITIAL_QUERY_READ, payload, state),
      handlers.trackInitialQueryReadByLandownerEvent(
        INITIAL_QUERY_READ_BY_LANDOWNER,
        payload,
        state
      ),
      handlers.trackNewQueryReadByLandownerEvent(NEW_QUERY_READ_BY_LANDOWNER, payload, state),
      handlers.trackReplyReadBySportsmanEvent(REPLY_READ_BY_SPORTSMAN, payload, state),
    ];
  },

  // This Redux Action maps to a various handlers,
  // which would push events depending on the state
  // the transaction is in.
  [MESSAGE_SENT]: (type, payload, state) => {
    return [
      handlers.trackNewQueryRepliedByLandownerEvent(QUERY_REPLIED_BY_LANDOWNER, payload, state),
      handlers.trackSportsmanRepliedEvent(SPORTSMAN_REPLIED, payload, state),
    ];
  },

  [ID_VERIFICATION_SUCCESS]: handlers.trackIdVerificationSuccessEvent,

  [PAYMENT_METHOD_SUBMITTED]: handlers.trackPaymentMethodSubmittedEvent,
  [PAYOUT_DETAILS_SUBMITTED]: handlers.trackPayoutDetailsSubmittedEvent,

  [TRANSACTION_CANCELED]: (type, payload, state) => {
    return [
      handlers.trackTransactionCanceledEvent(type, payload, state),
      handlers.trackTransactionCanceledByLandownerEvent(
        TRANSACTION_CANCELED_BY_LANDOWNER,
        payload,
        state
      ),
      handlers.trackSportsmanCanceledTransactionEvent(
        SPORTSMAN_CANCELED_TRANSACTION,
        payload,
        state
      ),
      handlers.trackTransactionCanceledBySportsmanEvent(
        TRANSACTION_CANCELED_BY_SPORTSMAN,
        payload,
        state
      ),
    ];
  },
  [SEND_REVIEW_SUCCESS]: (type, payload, state) => {
    return [
      handlers.trackReviewByLandowner(LANDOWNER_REVIEW_SUCCESS, payload, state),
      handlers.trackReviewBySportsman(SPORTSMAN_REVIEW_SUCCESS, payload, state),
    ];
  },
  [PARTY_MEMBERS_SUBMITTED]: (type, payload, state) => {
    return [handlers.trackPartyMembersFormSubmitted(PARTY_MEMBERS_SUBMITTED, payload, state)];
  },
  [TRANSACTION_PROMO_CODE_APPLIED]: handlers.trackPromoCodeApplied,
};

const store = eventData => {
  // Could be in SSR mode
  if (typeof window === 'undefined' || process.env.REACT_APP_ENV !== 'production') {
    return;
  }

  window.dataLayer = window.dataLayer || {};
  window.dataLayer.push(eventData);
};

export default (type, payload, state) => {
  const handler = eventHandlerMap[type];

  if (!handler) {
    return;
  }

  let eventData = handler(type, payload, state);

  if (!isArray(eventData)) {
    eventData = [eventData];
  }

  eventData.forEach(e => {
    // Prevent from pushing empty events
    if (isEmpty(e)) {
      return;
    }

    store(cleanEventData(e));
  });
};
