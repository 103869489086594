import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl } from 'react-intl';
import { NamedLink } from '..';
import css from './EditListingNamedSkipLink.css';

const { string } = PropTypes;

export const EditListingNamedSkipLink = ({ className, rootClassName, intl, ...namedLinkProps }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <NamedLink className={classes} {...namedLinkProps}>
      {intl.formatMessage({ id: 'EditListingNamedSkipLink.skipLink' })}
    </NamedLink>
  );
};

EditListingNamedSkipLink.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingNamedSkipLink.propTypes = {
  className: string,
  rootClassName: string,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

export default injectIntl(EditListingNamedSkipLink);
