import React, { useEffect, useState, useRef } from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import IDVerificationJSON from '../../assets/lottie/id-verification.json';
import { ReactComponent as IDCard } from '../../assets/icons/id-card-verify.svg';
import { ReactComponent as ShieldCheck } from '../../assets/icons/shield-check-regular.svg';
import { ReactComponent as IDError } from '../../assets/icons/id-error.svg';
import {
  fetchVerificationData,
  verifyIdentity,
  ID_STATUSES,
} from '../../ducks/IDVerification.duck';
import { PrimaryButton } from '../Button/Button';

import css from './IDVerification.css';

export const IDVerificationButton = ({ cta, onVerifyId, inProgress, isLoading }) => {
  return (
    <PrimaryButton onClick={onVerifyId} inProgress={inProgress} disabled={isLoading}>
      {cta}
    </PrimaryButton>
  );
};

const IDVerificationComponent = ({
  onFetchVerificationData,
  data,
  onVerifyId,
  verificationInProgress: inProgress,
  isLoading,
  className,
  onClose,
  showMissingInformationReminder,
  isSpecialOffer,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { status } = data || {};
  const statusRef = useRef(status);
  statusRef.current = status;

  useEffect(() => {
    const fetchData = async () => {
      await onFetchVerificationData();
    };

    let intervalId;

    if (showMissingInformationReminder) {
      fetchData();

      intervalId = setInterval(() => {
        if (statusRef.current === ID_STATUSES.PROCESSING) {
          onFetchVerificationData();
        } else if (statusRef.current === ID_STATUSES.VERIFIED) {
          setIsProcessing(false);
        }
      }, 10000);
    }

    return () => clearInterval(intervalId);
  }, [showMissingInformationReminder, onFetchVerificationData]);

  useEffect(() => {
    if (status === ID_STATUSES.PROCESSING) {
      setIsProcessing(true);
    }
  }, [status]);

  const handleVerifyId = async () => {
    await onVerifyId();
    setIsProcessing(true);
  };

  const classes = classNames(className || css.root);

  return (
    <div className={classes}>
      {status === ID_STATUSES.UNVERIFIED && (
        <div className={css.IDModalContent}>
          <IDCard className={css.idIcon} />
          <p className={css.modalTitle}>
            <FormattedMessage id="ModalMissingInformation.idVerifiedReminderTitle" />
          </p>
          <p className={css.modalMessage}>
            {isSpecialOffer ? (
              <FormattedMessage id="ModalMissingInformation.idVerifiedReminderTextSpecialOffer" />
            ) : (
              <FormattedMessage id="ModalMissingInformation.idVerifiedReminderText" />
            )}
          </p>
          <div className={css.bottomWrapper}>
            <IDVerificationButton
              cta={<FormattedMessage id="IDVerificationPage.verifyCTA" />}
              onVerifyId={handleVerifyId}
              inProgress={inProgress}
              isLoading={isLoading}
            />
          </div>
          <div className={css.verifyTime}>
            <FormattedMessage id="IDVerificationPage.verifyTime" />
          </div>
        </div>
      )}
      {isProcessing && ![ID_STATUSES.VERIFIED, ID_STATUSES.ERROR].includes(status) && (
        <div className={css.IDModalContent}>
          <Player
            autoplay
            loop
            src={IDVerificationJSON}
            style={{
              height: '75px',
            }}
          />
          <p className={css.modalTitle}>
            <FormattedMessage id="ModalMissingInformation.idVerifyProcessingTitle" />
          </p>
          <p className={css.modalMessage}>
            {isSpecialOffer ? (
              <FormattedMessage id="ModalMissingInformation.idVerifyProcessingContentSpecialOffer" />
            ) : (
              <FormattedMessage id="ModalMissingInformation.idVerifyProcessingContent" />
            )}
          </p>
        </div>
      )}
      {status === ID_STATUSES.VERIFIED && (
        <div className={css.IDModalContent}>
          <ShieldCheck className={css.idIcon} />
          <p className={css.modalTitle}>
            <FormattedMessage id="ModalMissingInformation.idVerifySuccessTitle" />
          </p>
          <p className={css.modalMessage}>
            {isSpecialOffer ? (
              <FormattedMessage id="ModalMissingInformation.idVerifySuccessContentSpecialOffer" />
            ) : (
              <FormattedMessage id="ModalMissingInformation.idVerifySuccessContent" />
            )}
          </p>
          <div className={css.bottomWrapper}>
            <PrimaryButton onClick={onClose}>
              {' '}
              <FormattedMessage id="IDVerificationPage.idVerifyReturn" />
            </PrimaryButton>
          </div>
        </div>
      )}
      {status === ID_STATUSES.ERROR && (
        <div className={css.IDModalContent}>
          <IDError className={css.idIcon} />
          <p className={css.modalTitle}>
            <FormattedMessage id="ModalMissingInformation.idVerifyErrorTitle" />
          </p>
          <p className={css.modalMessage}>
            <FormattedHTMLMessage id="ModalMissingInformation.idVerifyErrorContent" />
          </p>
          <div className={css.bottomWrapper}>
            <IDVerificationButton
              cta={<FormattedMessage id="IDVerificationPage.retryCTA" />}
              onVerifyId={handleVerifyId}
              inProgress={inProgress}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    data: state.IDVerification.data,
    isLoading: state.IDVerification.isLoading,
    verificationInProgress: state.IDVerification.verificationInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchVerificationData: () => dispatch(fetchVerificationData()),
  onVerifyId: () => dispatch(verifyIdentity()),
});

IDVerificationComponent.defaultProps = {};

IDVerificationComponent.propTypes = {
  onClose: func.isRequired,
};

const IDVerification = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(IDVerificationComponent);

export default withRouter(IDVerification);
