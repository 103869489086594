import React from 'react';
import { node, func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { EditListingPoliciesForm } from '../../forms';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);
  const policies = get(currentListing, 'attributes.publicData.policies', {});

  return {
    allowsCampfires: get(policies, 'allowsCampfires', false),
    campfires: get(policies, 'campfires', []),
    campfiresInfo: get(policies, 'campfiresInfo', null),
    allowsPets: get(policies, 'allowsPets', false),
    petsInfo: get(policies, 'petsInfo', null),
    allowsATV: get(policies, 'allowsATV', false),
    ATVInfo: get(policies, 'ATVInfo', null),
    allowsParking: get(policies, 'allowsParking', false),
    parkingInfo: get(policies, 'parkingInfo', null),
    hasDriveInAccess: get(policies, 'hasDriveInAccess', false),
    driveInAccessInfo: get(policies, 'driveInAccessInfo', null),
    hasWalkInAccess: get(policies, 'hasWalkInAccess', false),
    walkInAccessInfo: get(policies, 'walkInAccessInfo', null),
    vehicles: get(policies, 'vehicles', 0),
    vehiclesInfo: get(policies, 'vehiclesInfo', null),
    sportsmen: get(policies, 'sportsmen', 0),
    sportsmenInfo: get(policies, 'sportsmenInfo', null),
    sportsmenPolicies: get(policies, 'sportsmenPolicies', null),
  };
};

const getSubmissionValues = formValues => {
  return {
    allowsCampfires: formValues.allowsCampfires,
    campfires: formValues.allowsCampfires ? formValues.campfires : [],
    campfiresInfo: formValues.allowsCampfires ? formValues.campfiresInfo : null,
    allowsPets: formValues.allowsPets,
    petsInfo: formValues.allowsPets ? formValues.petsInfo : null,
    allowsATV: formValues.allowsATV,
    ATVInfo: formValues.allowsATV ? formValues.ATVInfo : null,
    allowsParking: formValues.allowsParking,
    parkingInfo: formValues.allowsParking ? formValues.parkingInfo : null,
    hasDriveInAccess: formValues.hasDriveInAccess,
    driveInAccessInfo: formValues.hasDriveInAccess ? formValues.driveInAccessInfo : null,
    hasWalkInAccess: formValues.hasWalkInAccess,
    walkInAccessInfo: formValues.hasWalkInAccess ? formValues.walkInAccessInfo : null,
    vehicles: formValues.vehicles,
    vehiclesInfo: formValues.vehiclesInfo,
    sportsmen: formValues.sportsmen,
    sportsmenInfo: formValues.sportsmenInfo,
    sportsmenPolicies: formValues.sportsmenPolicies,
  };
};

const EditListingPoliciesPanel = ({
  rootClassName,
  className,
  listing,
  onSubmit,
  onChange,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  errors,
  backLink,
}) => {
  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingPoliciesForm
        initialValues={getInitialValues(listing)}
        onSubmit={values => {
          onSubmit({
            publicData: {
              policies: getSubmissionValues(values),
            },
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backLink={backLink}
      />
    </EditListingPanel>
  );
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

export default EditListingPoliciesPanel;
