/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { object, func, bool } from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HeartEmpty } from '../../assets/icons/heart-regular.svg';
import { ReactComponent as HeartFull } from '../../assets/icons/heart-solid.svg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { InlineTextButton } from '../Button/Button';
import NamedLink from '../NamedLink/NamedLink';
import css from './FavoriteListing.css';
import { toast } from '../Toaster/Toaster';

export const toastListingFavorited = currentUser =>
  toast(
    <span>
      <FormattedMessage
        id="Toast.favoriteListing"
        values={{
          favorites: (
            <NamedLink name="FavoritesPage" params={{ id: currentUser.id.uuid, tab: 'listings' }}>
              <FormattedMessage id="Toast.favorites" />
            </NamedLink>
          ),
        }}
      />
    </span>
  );
export const toastListingUnfavorited = currentUser =>
  toast(
    <span>
      <FormattedMessage
        id="Toast.unfavoriteListing"
        values={{
          favorites: (
            <NamedLink name="FavoritesPage" params={{ id: currentUser.id.uuid, tab: 'listings' }}>
              <FormattedMessage id="Toast.favorites" />
            </NamedLink>
          ),
        }}
      />
    </span>
  );

const FavoriteListing = ({
  currentUser,
  listing,
  onUnfavoriteListing,
  onFavoriteListing,
  history,
  includeLabel,
  listingFavoriteButton,
  followSubjectMutation,
  unfollowSubjectMutation,
  followedSubjects,
}) => {
  const followingConfig = followedSubjects?.find(subject => subject.subjectId === listing.id.uuid);
  const listingId = get(listing, 'id.uuid');
  const favoriteListings = get(currentUser, 'attributes.profile.publicData.favoriteListings', []);
  const isFavorited = favoriteListings.indexOf(listingId) > -1;
  const userId = currentUser?.id?.uuid;
  const handleRedirect = () => {
    window.open('/login', '_blank');
  };

  const followProperty = () => {
    if (userId) {
      followSubjectMutation(userId, listingId, { sms: true, email: true });
    } else {
      handleRedirect();
    }
  };

  const unfollowProperty = () => {
    const id = followingConfig?.id;
    unfollowSubjectMutation(id, userId);
  };

  const handleClick = async e => {
    e.preventDefault();

    if (currentUser) {
      if (favoriteListings.indexOf(listingId) > -1) {
        await onUnfavoriteListing(e);
        toastListingUnfavorited(currentUser);
        unfollowProperty();
      } else {
        onFavoriteListing(e);
        toastListingFavorited(currentUser);
        followProperty();
      }
    } else {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration()), {
        from: window.location.pathname,
      });
    }
  };

  return (
    <>
      {listingFavoriteButton ? (
        <span className={css.root}>
          <InlineTextButton className={css.favButton} onClick={handleClick}>
            <span className={css.buttonBody}>
              {isFavorited ? (
                <HeartFull className={css.iconFull} />
              ) : (
                <HeartEmpty className={css.icon} />
              )}

              <span className={css.buttonLabel}>
                <FormattedMessage id="ListingPage.favoriteButton" />
              </span>
            </span>
          </InlineTextButton>
        </span>
      ) : (
        <button type="button" className={css.circleFav} onClick={handleClick}>
          {isFavorited ? (
            <HeartFull className={css.iconFull} />
          ) : (
            <HeartEmpty className={css.icon} />
          )}
        </button>
      )}

      {includeLabel && (
        <>
          {isFavorited ? (
            <a href={`/u/${currentUser.id.uuid}/favorites`}>
              <FormattedMessage id="ListingPage.viewFavorites" />
            </a>
          ) : (
            <a href="#" onClick={handleClick}>
              <FormattedMessage id="ListingPage.favoriteThisProperty" />
            </a>
          )}
        </>
      )}
    </>
  );
};

FavoriteListing.defaultProps = {
  currentUser: null,
  listing: null,
  onUnfavoriteListing: null,
  onFavoriteListing: null,
  history: null,
  includeLabel: false,
};

FavoriteListing.propTypes = {
  currentUser: object,
  listing: object,
  onUnfavoriteListing: func,
  onFavoriteListing: func,
  history: object,
  includeLabel: bool,
};

export default withRouter(FavoriteListing);
