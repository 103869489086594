/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import {
  LINE_ITEMS,
  LINE_ITEM_LODGING_TAX,
  LINE_ITEM_CONSERVATION_DONATION,
  propTypes,
} from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemUnknownItemsMaybe = props => {
  const { transaction, intl } = props;

  // resolve unknown non-reversal line items
  const items = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  return items.length > 0 ? (
    <>
      {items.map(item => {
        const label = humanizeLineItemCode(item.code);
        let feeFreeLabel = label.replace('fee', '');

        let formattedTotal = formatMoney(intl, item.lineTotal);

        if (item.code === 'line-item/units') {
          // Dont render units subtotal as it is duplicate of total
          return null;
        }

        if (item.code === 'line-item/party-size') {
          return null;
        }
        if (item.code === 'line-item/access-fee' && item.lineTotal.amount === 0) {
          return null;
        }
        if (item.code === 'line-item/fishing-fee' && item.lineTotal.amount === 0) {
          return null;
        }
        if (item.code === 'line-item/negotiation') {
          return null;
        }
        if (item.code === LINE_ITEM_LODGING_TAX) {
          return null;
        }
        if (item.code === LINE_ITEM_CONSERVATION_DONATION) {
          return null;
        }
        if (item.code.match(/line-item\/(.+)-donation-fee/i)) {
          feeFreeLabel = 'Conservation Donation';
          formattedTotal = `+${formattedTotal}`;
        }
        return (
          <div key={`${item.code}`} className={css.lineItem}>
            <span className={css.itemLabel}>{feeFreeLabel}</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
