import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { TopbarContainer } from '..';
import FAQSectionHero from './FAQSectionHero';
import facebookImage from '../../assets/images/deep-link/link-logo-row.jpg';

import css from './FAQLandownersPage.css';

import FAQSectionSpecies from './FAQSectionSpecies';
import FAQSectionPlatforms from './FAQSectionPlatforms';
import FAQSectionBenefits from './FAQSectionBenefits';
import FAQSectionConservation from './FAQSectionConservation';
import FAQSectionHowItWorks from './FAQSectionHowItWorks';
import FAQSectionLandownerQuote from './FAQSectionLandownerQuote';
import FAQSectionPayments from './FAQSectionPayments';
import FAQSectionHelp from './FAQSectionHelp';
import FAQSectionFooter from './FAQSectionFooter';

export const FAQLandownersPageComponent = props => {
  const { intl, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'FAQLandownersPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'FAQLandownersPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <FAQSectionHero />

          <div className={css.section}>
            <FAQSectionSpecies />
          </div>

          <div className={classNames(css.section, css.sectionMarginless)}>
            <FAQSectionPlatforms />
          </div>

          <div className={classNames(css.section, css.sectionFullWidth)}>
            <FAQSectionBenefits />
          </div>

          <div className={css.section}>
            <FAQSectionConservation />
          </div>

          <div className={classNames(css.section, css.sectionFullWidth)}>
            <FAQSectionHowItWorks />
          </div>

          <div className={css.section}>
            <FAQSectionLandownerQuote />
          </div>

          <div className={classNames(css.section, css.sectionFullWidth)}>
            <FAQSectionPayments />
          </div>

          <div className={css.section}>
            <FAQSectionHelp />
          </div>

          <div className={classNames(css.section, css.sectionFullWidth, css.sectionMarginless)}>
            <FAQSectionFooter />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

FAQLandownersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const FAQLandownersPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FAQLandownersPageComponent);

export default FAQLandownersPage;
