/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object } from 'prop-types';

import css from './TripInsuranceBadges.css';

const TRIP_INSURANCE_BADGE = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};

const PackageQuantityBadge = ({ text, variant }) => {
  return (
    <div className={css[`${variant}Badge`]}>
      <span>{text}</span>
    </div>
  );
};

const TripInsuranceBadges = ({ currentTransaction }) => {
  const { tripInsurance } = currentTransaction?.attributes?.metadata || {};
  const insuranceOffered = tripInsurance !== undefined;

  let badge = null;

  if (!insuranceOffered) {
    return null;
  }

  if (tripInsurance) {
    badge = {
      text: <FormattedMessage id="TripInsuranceBadges.tripInsured" />,
      variant: TRIP_INSURANCE_BADGE.ACCEPTED,
    };
  }

  if (!tripInsurance) {
    badge = {
      text: <FormattedMessage id="TripInsuranceBadges.declinedInsurance" />,
      variant: TRIP_INSURANCE_BADGE.DECLINED,
    };
  }

  return (
    <div className={css.root}>
      <PackageQuantityBadge variant={badge?.variant} text={badge?.text} />
    </div>
  );
};

TripInsuranceBadges.propTypes = {
  currentTransaction: object.isRequired,
};

export default TripInsuranceBadges;
