/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  fetchNextTransitions,
  fetchMessages,
  fetchTransaction,
  specialOfferOrder,
  sendMessage,
  requestChanges,
  acceptChanges,
  declineChanges,
} from '../../ducks/Transaction.duck';
import routeConfiguration from '../../routeConfiguration';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isVerified } from '../../util/user';
import { createResourceLocatorString } from '../../util/routes';
import {
  Page,
  InboxList,
  InboxDetails,
  InboxAction,
  InboxMessages,
  InboxDetailsSendMessage,
  BookingChanges,
  PageSlider,
  UserDisplayName,
  IDVerification,
  NamedRedirect,
} from '../../components';
import { TopbarContainer } from '..';

import { initialFetch, loadData, sortedTransactions } from './InboxPageV2.duck';
import css from './InboxPageV2.css';
import { ensureListing, ensureUser } from '../../util/data';
import { getTxState, getUserTxRole } from '../../util/transaction';
import { createListingLink } from '../../components/TransactionListingHeading/TransactionListingHeading';
import { getPackage } from '../../util/listing';
import InboxV2Loading, { MessagesLoader } from './InboxV2Loading';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../marketplace-custom-config';
import InboxV2EmptyState from './InboxV2EmptyState';

const { UUID } = sdkTypes;

const InboxV2PageComponent = ({
  viewport,
  history,
  intl,
  transactionsSales,
  transactionsOrders,
  params,
  currentUser,
  currentUserInit,
  fetchUser,
  fetchInProgress,
  onFetchTransaction,
  onFetchTransactions,
  nextTransitions,
  onFetchNextTransitions,
  onFetchMessages,
  timeSlots,
  fetchTimeSlotsError,
  updatingWaitingListInProgress,
  onSpecialOffer,
  sendMessageInProgress,
  onSendMessage,
  initiateOrderError,
  onRequestChanges,
  requestedChangesInProgress,
  acceptChangesInProgress,
  declineChangesInProgress,
  onAcceptChanges,
  onDeclineChanges,
  sendMessageError,
  messages,
  fetchTransactionError,
  onInitialFetch,
}) => {
  const transactions = params.tab === 'sales' ? transactionsSales : transactionsOrders;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState('details');
  const [showBookingChanges, setShowBookingChanges] = useState(false);
  const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  let title = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const [state, setState] = useState({
    isIdVerified: false,
    showMissingInformationReminder: false,
    isSubmitting: false,
    isDefaultSet: false,
    defaultTransactionId: null,
    isFetching: false,
    initialFetch: false,
    showTabs: true,
    isEmpty: false,
  });

  useEffect(() => {
    const initialFetchData = async () => {
      const { hasSales, hasOrders } = await onInitialFetch();

      if (hasSales && hasOrders) {
        setState(prevState => ({
          ...prevState,
          showTabs: true,
        }));
      } else if (!hasSales && !hasOrders) {
        setState(prevState => ({
          ...prevState,
          showTabs: false,
          isEmpty: true,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          showTabs: false,
        }));

        if (hasSales) {
          history.push(
            createResourceLocatorString('InboxV2Page', routeConfiguration(), {
              ...params,
              tab: 'sales',
            })
          );
        } else if (hasOrders) {
          history.push(
            createResourceLocatorString('InboxV2Page', routeConfiguration(), {
              ...params,
              tab: 'orders',
            })
          );
        }
      }
    };

    initialFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({
        ...prevState,
        initialFetch: false,
      }));

      const { transactions: allTransactions, meta } = await onFetchTransactions(params, 1);

      if (allTransactions && allTransactions.length > 0) {
        setState(prevState => ({
          ...prevState,
          defaultTransactionId: allTransactions[0].id.uuid,
          initialFetch: true,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          initialFetch: true,
        }));
      }

      setHasMore(currentPage < meta.totalPages);
    };

    setCurrentPage(1);
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tab]);

  useEffect(() => {
    if (
      !isMobile &&
      !params.id &&
      transactions.length &&
      !fetchInProgress &&
      state.defaultTransactionId
    ) {
      history.push(
        createResourceLocatorString('InboxV2Page', routeConfiguration(), {
          ...params,
          id: state.defaultTransactionId,
        })
      );
    }
  }, [
    isMobile,
    state.defaultTransactionId,
    params.id,
    transactions,
    fetchInProgress,
    history,
    params,
  ]);

  const fetchTransactionData = async id => {
    const txId = new UUID(id);

    await onFetchTransaction(txId, params.transactionRole, params);
    await onFetchNextTransitions(txId);
    await onFetchMessages(txId);
  };

  useEffect(() => {
    const fetchData = async id => {
      try {
        setState(prevState => ({ ...prevState, isFetching: true }));

        await fetchTransactionData(id);
      } finally {
        setState(prevState => ({ ...prevState, isFetching: false }));
      }
    };

    if (params?.id) {
      fetchData(params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const messagesContentRef = useRef(null);

  useEffect(() => {
    if (messagesContentRef.current && !state.isFetching) {
      setTimeout(() => {
        messagesContentRef.current.scrollTop = messagesContentRef.current.scrollHeight;
      }, 0);
    }
  }, [messages, state.isFetching]);

  const loadMore = async () => {
    if (!hasMore) return;

    try {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingMore(true);

      const { meta } = await onFetchTransactions(params, nextPage);

      setHasMore(nextPage < meta.totalPages);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error loading more transactions:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const checkUserVerification = useCallback(async () => {
    await fetchUser();

    const verifiedUser = isVerified(currentUser);

    if (!verifiedUser) {
      setState(prevState => ({
        ...prevState,
        isIdVerified: false,
        showMissingInformationReminder: true,
        isSubmitting: true,
      }));

      return false;
    }

    setState(prevState => ({
      ...prevState,
      isIdVerified: true,
      showMissingInformationReminder: false,
    }));

    return true;
  }, [fetchUser, currentUser]);

  const currentTransaction = useMemo(() => {
    let transactionId;
    if (params.id) {
      transactionId = params.id;
    } else if (state.defaultTransactionId && isMobile) {
      transactionId = state.defaultTransactionId;
    }

    return transactions.find(tx => tx.id.uuid === transactionId);
  }, [transactions, params.id, state.defaultTransactionId, isMobile]);

  const transactionState = useMemo(
    () =>
      currentTransaction
        ? getTxState(
            intl,
            currentTransaction,
            getUserTxRole(currentUser?.id, currentTransaction),
            ensureUser(currentTransaction.customer),
            ensureUser(currentTransaction.provider),
            nextTransitions
          )
        : null,
    [currentTransaction, intl, currentUser?.id, nextTransitions]
  );

  if (!currentTransaction && !state.initialFetch) {
    return <InboxV2Loading title={title} />;
  }

  if (state.isEmpty) {
    return <InboxV2EmptyState title={title} params={params} />;
  }

  if (!currentTransaction) {
    return <InboxV2Loading title={title} />;
  }

  const currentListing = ensureListing(currentTransaction?.listing);

  const handleSubmitSpecialOfferRequest = values => {
    const { specialOfferCalendar, ...bookingData } = values;

    const initialValues = {
      listing: currentListing,
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: specialOfferCalendar.startDate,
        bookingEnd: specialOfferCalendar.endDate,
      },
      confirmPaymentError: null,
    };

    onSpecialOffer(initialValues, currentTransaction.id, currentTransaction);
  };

  const handleOpenChangeBookingDetails = () => {
    setShowBookingChanges(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseChangeBookingDetails = () => {
    setShowBookingChanges(false);
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };

  const handleRequestChanges = async (currentParams, id) => {
    await onRequestChanges(currentParams, id);

    window.scrollTop = 0;
  };

  const handleSendMessage = async (txId, message) => {
    try {
      await onSendMessage(txId, message);
      await onFetchTransactions(params);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const mainContainerClassName = classNames(css.mainContainer, {
    [css.mainContainerMobile]: isMobile && params.id,
  });

  const listClassName = classNames(css.listContainer, {
    [css.listContainerHidden]: isMobile && params.id,
  });

  const messagesClassName = classNames(css.messagesContainer, {
    [css.messagesContainerHidden]: isMobile && !params.id,
  });

  const actionClassName = classNames(css.actionContainer, {
    [css.actionContainerHidden]: isMobile && !params.id,
  });

  const packageId =
    get(currentTransaction, 'attributes.protectedData.selectedPackage.packageId', null) ||
    get(currentTransaction, 'attributes.protectedData.packageLineItem.id', null);
  const currentPackage = getPackage(currentListing, packageId);
  const packageTitle = currentPackage && currentPackage.title;
  title = packageTitle || get(currentListing, 'attributes.title', '');

  const listingLink = createListingLink(
    currentListing,
    title,
    currentPackage,
    {},
    css.listingTitleLink
  );

  const isCustomer = params.transactionRole === 'customer';
  const isProvider = params.transactionRole === 'provider';

  const isOrder = params.tab === 'orders';
  const user = isOrder ? currentTransaction.provider : currentTransaction.customer;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable && isProvider && currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable && isCustomer && currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProvider && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxV2Page" params={{ tab: 'sales' }} />;
  }
  if (isDataAvailable && isCustomer && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxV2Page" params={{ tab: 'orders' }} />;
  }

  return (
    <Page title={title} scrollingDisabled={false} className={css.pageRoot}>
      <div className={css.root}>
        {isMobile && params.id ? null : (
          <div className={css.topbarContainer}>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage="InboxV2Page"
            />
          </div>
        )}

        <div className={mainContainerClassName}>
          <div id="details" />

          <div className={listClassName}>
            <InboxList
              isMobile={isMobile}
              showTabs={state.showTabs}
              transactions={transactions}
              params={params}
              currentUser={currentUser}
              isFetching={!transactions.length && fetchInProgress}
              hasMore={hasMore}
              loadMore={loadMore}
              isLoadingMore={isLoadingMore}
              onReset={() => {
                setState(prevState => ({
                  ...prevState,
                  defaultTransactionId: null,
                }));
              }}
            />
          </div>

          {state.isFetching ? (
            <div className={css.messagesOnlyContainerLoader}>
              <MessagesLoader />
            </div>
          ) : (
            <div className={messagesClassName}>
              {!isMobile && (
                <div className={css.messagesHeader}>
                  <UserDisplayName user={user} intl={intl} />
                </div>
              )}

              <div className={css.messagesContent} ref={messagesContentRef}>
                <div id="messages" />
                <InboxMessages
                  transactions={transactions}
                  params={params}
                  currentUser={currentUser}
                  isFetching={state.isFetching}
                  transactionState={transactionState}
                />
              </div>

              <div className={css.sendMessageContent}>
                <InboxDetailsSendMessage
                  currentUser={currentUser}
                  currentTransaction={currentTransaction}
                  sendMessageInProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onSendMessage={handleSendMessage}
                />
              </div>
            </div>
          )}

          <div className={actionClassName}>
            <div className={css.mobileDetailsContainer}>
              <InboxDetails
                history={history}
                transactions={transactions}
                params={params}
                currentUser={currentUser}
                checkUserVerification={checkUserVerification}
                isFetching={state.isFetching}
                transactionState={transactionState}
                currentListing={currentListing}
                isMobile={isMobile}
                onChangeBookingDetails={handleOpenChangeBookingDetails}
              />
            </div>

            {isMobile && (
              <div className={css.mobileLinks}>
                <a
                  href="#details"
                  onClick={e => {
                    e.preventDefault();
                    document.getElementById('details').scrollIntoView({ behavior: 'smooth' });
                    setActiveTab('details');
                  }}
                  className={classNames(css.mobileLink, {
                    [css.mobileLinkActive]: activeTab === 'details',
                  })}
                >
                  <FormattedMessage id="InboxPage.tripDetails" />
                </a>
                <a
                  href="#messages"
                  onClick={e => {
                    e.preventDefault();
                    document.getElementById('messages').scrollIntoView({ behavior: 'smooth' });
                    setActiveTab('messages');
                  }}
                  className={classNames(css.mobileLink, {
                    [css.mobileLinkActive]: activeTab === 'messages',
                  })}
                >
                  <FormattedMessage id="InboxPage.messages" />
                </a>
              </div>
            )}

            <InboxAction
              transactionState={transactionState}
              currentListing={currentListing}
              currentUser={currentUser}
              currentUserInit={currentUserInit}
              currentTransaction={currentTransaction}
              transactionRole={params.transactionRole}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              updatingWaitingListInProgress={updatingWaitingListInProgress}
              onSubmitSpecialOffer={handleSubmitSpecialOfferRequest}
              sendMessageInProgress={sendMessageInProgress}
              onSendMessage={onSendMessage}
              initiateOrderError={initiateOrderError}
              isMobile={isMobile}
              onChangeBookingDetails={handleOpenChangeBookingDetails}
            />
          </div>
        </div>
      </div>

      {!state.isIdVerified && (
        <PageSlider
          active={state.showMissingInformationReminder}
          onClose={() => {
            setState(prevState => ({
              ...prevState,
              showMissingInformationReminder: false,
              isSubmitting: false,
            }));
          }}
          width="50%"
        >
          <IDVerification
            className={css.innerIDModal}
            onClose={() => {
              setState(prevState => ({
                ...prevState,
                showMissingInformationReminder: false,
                isSubmitting: false,
              }));

              fetchUser();
            }}
            showMissingInformationReminder={state.showMissingInformationReminder}
            isSpecialOffer
          />
        </PageSlider>
      )}

      {showBookingChanges && (
        <PageSlider
          active={showBookingChanges}
          onClose={() => {
            handleCloseChangeBookingDetails();
          }}
          width="100%"
        >
          <BookingChanges
            currentListing={currentListing}
            currentUser={currentUser}
            provider={currentTransaction?.provider}
            customer={currentTransaction?.customer}
            listingLink={listingLink}
            packageId={packageId}
            currentTransaction={currentTransaction}
            timeSlots={timeSlots}
            onSubmit={handleRequestChanges}
            inProgress={requestedChangesInProgress}
            onAccept={onAcceptChanges}
            acceptChangesInProgress={acceptChangesInProgress}
            onDecline={onDeclineChanges}
            declineChangesInProgress={declineChangesInProgress}
            stateData={transactionState}
            isProvider={isProvider}
            isCustomer={isCustomer}
            onClose={() => {
              handleCloseChangeBookingDetails();
            }}
          />
        </PageSlider>
      )}
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionSalesRefs,
    transactionOrdersRefs,
  } = state.InboxPageV2;
  const { currentUser, currentUserInit, updatingWaitingListInProgress } = state.user;
  const {
    processTransitions,
    timeSlots,
    fetchTimeSlotsError,
    sendMessageInProgress,
    sendMessageError,
    initiateOrderError,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
    messages,
    fetchTransactionError,
  } = state.Transaction;

  const transactionsSales = sortedTransactions(getMarketplaceEntities(state, transactionSalesRefs));
  const transactionsOrders = sortedTransactions(
    getMarketplaceEntities(state, transactionOrdersRefs)
  );

  return {
    currentUser,
    currentUserInit,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactionsSales,
    transactionsOrders,
    nextTransitions: processTransitions,
    timeSlots,
    updatingWaitingListInProgress,
    fetchTimeSlotsError,
    sendMessageInProgress,
    sendMessageError,
    initiateOrderError,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
    messages,
    fetchTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchCurrentUser()),
  onFetchTransactions: (params, page) => dispatch(loadData(params, page)),
  onFetchTransaction: (id, txRole, params) => dispatch(fetchTransaction(id, txRole, params)),
  onFetchNextTransitions: id => dispatch(fetchNextTransitions(id)),
  onFetchMessages: id => dispatch(fetchMessages(id, 1)),
  onSpecialOffer: (params, transactionId, currentTransaction) =>
    dispatch(specialOfferOrder(params, transactionId, currentTransaction)),
  onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
  onRequestChanges: (params, transactionId) => dispatch(requestChanges(params, transactionId)),
  onAcceptChanges: transactionId => dispatch(acceptChanges(transactionId)),
  onDeclineChanges: transactionId => dispatch(declineChanges(transactionId)),
  onInitialFetch: () => dispatch(initialFetch()),
});

const InboxV2Page = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(InboxV2PageComponent);

export default withViewport(InboxV2Page);
