/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { parse, format } from 'date-fns';
import { checkPackageAvailabilty } from '../../util/package';
import PackageQuantityBadge, {
  PACKAGE_QUANTITY_BADGE_VARIANTS,
} from '../PackageQuantityBadge/PackageQuantityBadge';

import css from './PackageQuantityBadges.css';

const PackageQuantityBadges = ({ packageInfo, isOwnListing }) => {
  const { hasQuantityRestricted, quantity, availableDateFrom } = packageInfo || {};
  const { bookingRangeIsPast, bookingRangeIsFuture, isAvailable } = checkPackageAvailabilty(
    packageInfo
  );

  const badges = [];
  const isSoldOut =
    (hasQuantityRestricted && quantity?.total === quantity?.booked) || bookingRangeIsPast;

  const showBadge =
    (hasQuantityRestricted && !isAvailable) ||
    bookingRangeIsFuture ||
    bookingRangeIsPast ||
    (isOwnListing && quantity?.total > 0);

  if (!showBadge) {
    return null;
  }

  if (isOwnListing && quantity?.total) {
    badges.push({
      text: (
        <FormattedMessage
          id="PackageCard.quantity"
          values={{
            booked: quantity.booked,
            total: quantity?.total,
          }}
        />
      ),
      variant: PACKAGE_QUANTITY_BADGE_VARIANTS.SUCCESS,
    });
  }

  if (isSoldOut) {
    badges.push({
      text: <FormattedMessage id="PackageCard.soldOut" />,
      variant: PACKAGE_QUANTITY_BADGE_VARIANTS.DANGER,
    });
  }

  if (bookingRangeIsFuture && !isSoldOut) {
    const date = parse(availableDateFrom);
    const formattedDate = format(date, 'MMM. D');

    badges.push({
      text: (
        <FormattedMessage
          id="PackageCard.bookInFuture"
          values={{
            date: formattedDate,
          }}
        />
      ),
      variant: PACKAGE_QUANTITY_BADGE_VARIANTS.WARNING,
    });
  }

  return (
    <div className={css.root}>
      {badges.map((badge, index) => {
        const { text, variant } = badge;

        return (
          <PackageQuantityBadge
            key={`${packageInfo.id}-badge-${index}`}
            variant={variant}
            text={text}
          />
        );
      })}
    </div>
  );
};

export default PackageQuantityBadges;
