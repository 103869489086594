import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import moment from 'moment';
import { HUNT_ACTIVITY } from '../../../marketplace-custom-config';
import { daysAdded } from '../../../util/dates';
import { FieldTextInput } from '../..';
import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';
import InquiryTopics from '../InquiryTopics';
import InquiryBreakdown from '../InquiryBreakdown';
import { required } from '../../../util/validators';
import { ReactComponent as DiamondLight } from '../../../assets/icons/diamond-light.svg';
import { ReactComponent as CalendarLinesLight } from '../../../assets/icons/calendar.svg';
import { ReactComponent as UserLight } from '../../../assets/icons/guest.svg';
import css from './InquiryWizardStepSummary.css';

const InquirySummary = ({ listing, formValues }) => {
  const activity = get(formValues, 'activity', null);
  const partySize = get(formValues, 'partySize', 1);
  const bookingDates = get(formValues, 'bookingDates', {});
  const { startDate, endDate } = bookingDates;

  const dayCount = daysAdded(startDate, endDate);
  const isSingleDay = dayCount === 1;
  const bookingStart = moment(startDate);
  const bookingEnd = moment(endDate);
  const bookingStartText = `${bookingStart.format('dddd, MMMM DD, YYYY')}`;
  const bookingEndText = `${bookingEnd.format('dddd, MMMM DD, YYYY')}`;
  const gameUnit =
    activity === HUNT_ACTIVITY
      ? get(listing, `attributes.publicData.activities.${activity}.game`, null)
      : null;

  return (
    <InquiryWizardSection className={css.summaryContainer}>
      <div>
        {partySize > 0 && (
          <div className={css.iconSectionWrapper}>
            <UserLight className={css.icon} />

            <div className={css.iconSection}>
              <div className={css.iconSectionLabel}>
                <FormattedMessage id="InquiryWizard.guest" />
              </div>
              <div className={css.iconSectionValue}>
                <FormattedMessage id="BookingDetails.guestSize" values={{ guests: partySize }} />
              </div>
            </div>
          </div>
        )}
        {isSingleDay ? (
          <div className={css.iconSectionWrapper}>
            <CalendarLinesLight className={css.icon} />

            <div className={css.iconSection}>
              <div className={css.iconSectionLabel}>
                <FormattedMessage id="InquiryWizard.tripDate" />
              </div>
              <div className={css.iconSectionValue}>{bookingStartText}</div>
            </div>
          </div>
        ) : (
          <>
            <div className={css.iconSectionWrapper}>
              <CalendarLinesLight className={css.icon} />

              <div className={css.iconSection}>
                <div className={css.iconSectionLabel}>
                  <FormattedMessage id="InquiryWizard.tripStart" />
                </div>
                <div className={css.iconSectionValue}>{bookingStartText}</div>
              </div>
            </div>

            <div className={css.iconSectionWrapper}>
              <CalendarLinesLight className={css.icon} />

              <div className={css.iconSection}>
                <div className={css.iconSectionLabel}>
                  <FormattedMessage id="InquiryWizard.tripEnd" />
                </div>
                <div className={css.iconSectionValue}>{bookingEndText}</div>
              </div>
            </div>
          </>
        )}

        {gameUnit && (
          <div className={css.iconSectionWrapper}>
            <DiamondLight className={css.icon} />

            <div className={css.iconSection}>
              <div className={css.iconSectionLabel}>
                <FormattedMessage id="InquiryWizard.gameUnit" />
              </div>
              <div className={css.iconSectionValue}>{gameUnit}</div>
            </div>
          </div>
        )}
      </div>
    </InquiryWizardSection>
  );
};

const InquiryWizardStepSummary = ({ intl, listing, formValues }) => {
  return (
    <InquiryWizardPanel>
      <div className={css.bookingDetailsTitle}>
        <FormattedMessage id="InquiryWizard.bookingDetailsTitle" />
      </div>
      <InquirySummary intl={intl} listing={listing} formValues={formValues} />

      <InquiryWizardSection className={css.messageContainer}>
        <FieldTextInput
          id="message"
          name="message"
          type="textarea"
          label={intl.formatMessage(
            {
              id: 'InquiryWizard.additionalQuestions',
            },
            { name: listing.author.attributes.profile.displayName }
          )}
          validate={required(
            intl.formatMessage({
              id: 'InquiryWizard.messageRequired',
            })
          )}
        />
      </InquiryWizardSection>

      <InquiryWizardSection className={css.breakdownContainer}>
        <InquiryBreakdown listing={listing} formValues={formValues} />
      </InquiryWizardSection>

      <InquiryWizardSection className={css.topicsContainer}>
        <InquiryTopics />
      </InquiryWizardSection>
    </InquiryWizardPanel>
  );
};

InquiryWizardStepSummary.propTypes = {};

export default injectIntl(InquiryWizardStepSummary);
