import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CONSERVATION_DONATION, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemConservationDonationMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const lineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CONSERVATION_DONATION
  );

  if (isCustomer && lineItem) {
    const fee = lineItem.lineTotal;
    const formattedFee = fee ? formatMoney(intl, fee) : null;

    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.conservationDonation" />
        </span>
        <span className={css.itemValue}>+{formattedFee}</span>
      </div>
    );
  }

  return null;
};

LineItemConservationDonationMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemConservationDonationMaybe;
