import React from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';

import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import format from 'date-fns/format';
import { propTypes } from '../../util/types';
import { fieldRepOptions } from '../../marketplace-custom-config';

import { required, composeValidators } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
  FieldWysiwyg,
  FieldDates,
  FieldSelect,
} from '../../components';
import css from './EditListingPropertyVisitForm.css';

const EditListingPropertyVisitFormComponent = ({ backLink, isPublished, ...formProps }) => (
  <FinalForm
    {...formProps}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
      } = fieldRenderProps;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const bookingDateText = values?.dates?.startDate ? (
        format(values.dates.startDate, 'MMM DD')
      ) : (
        <FormattedMessage id="EditListingPropertyVisitForm.datesFieldPlaceholder" />
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <EditListingSection>
            <EditListingError fetchErrors={fetchErrors} />

            <EditListingFieldWrapper>
              <FieldWysiwyg
                id="propertyUpdate"
                name="propertyUpdate"
                label={intl.formatMessage({
                  id: 'EditListingPropertyVisitForm.propertyUpdate',
                })}
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingPropertyVisitForm.propertyUpdateRequired',
                    })
                  )
                )}
                placeholder={intl.formatMessage({
                  id: 'EditListingPropertyVisitForm.propertyUpdatePlaceholder',
                })}
              />
            </EditListingFieldWrapper>
            <EditListingFieldWrapper>
              <FieldDates
                fieldDateProps={{
                  endDateOffset: day => day.add(0, 'days'),
                  minimumNights: false,
                  maximumNights: undefined,

                  calendarInfo: (
                    <div className={css.dateInfo}>
                      <div className={css.dateInfoTitle}>
                        <FormattedMessage id="EditListingPropertyVisitForm.datesFieldInfoTitle" />
                      </div>
                    </div>
                  ),
                  showPreviousDays: true,
                }}
                label={<FormattedMessage id="EditListingPropertyVisitForm.datesFieldHeading" />}
                text={bookingDateText}
                onChange={dates => {
                  form.change('dates', dates);
                }}
              />
            </EditListingFieldWrapper>
            <EditListingFieldWrapper>
              <FieldSelect
                name="fieldRep"
                id="fieldRep"
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingPropertyVisitForm.fieldRepRequired',
                    })
                  )
                )}
                label={<FormattedMessage id="EditListingPropertyVisitForm.fieldRepHeading" />}
              >
                <option value="">
                  {intl.formatMessage({
                    id: 'EditListingPropertyVisitForm.fieldRepPlaceholder',
                  })}
                </option>
                {fieldRepOptions.map(o => (
                  <option key={o.key} value={o.key}>
                    {o.label}
                  </option>
                ))}
              </FieldSelect>
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingActions>
            {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

            <EditListingActions.Forward>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
            </EditListingActions.Forward>
          </EditListingActions>
        </Form>
      );
    }}
  />
);

EditListingPropertyVisitFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingPropertyVisitFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  isPublished: bool.isRequired,
};

export default compose(injectIntl)(EditListingPropertyVisitFormComponent);
