/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  txIsEnquired,
  getUserTxRole,
  txIsSpecialOffer,
  txIsRequested,
  TRANSITION_CONFIRM_PAYMENT,
} from '../../util/transaction';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock-regular.svg';

import css from './InboxListItem.css';

const calculateRemainingHours = (lastTransitionDate, timeLimit) =>
  timeLimit - moment().diff(lastTransitionDate, 'hours');

const InboxListItemBadge = ({ transaction, user, intl }) => {
  if (!transaction || !user?.id?.uuid) {
    return null;
  }

  const { attributes, messages } = transaction;
  const ownRole = getUserTxRole(user.id, transaction);
  const isEnquiry = txIsEnquired(transaction);

  const isSpecialOffer = txIsSpecialOffer(transaction);
  const isRequested = txIsRequested(transaction);
  const { lastTransitionedAt } = attributes || {};
  let lastTransitionDate = moment(lastTransitionedAt);
  let isBookingRequest = false;

  if (isRequested) {
    const confirmPayment = attributes.transitions.find(
      t => t.transition === TRANSITION_CONFIRM_PAYMENT
    );

    if (confirmPayment) {
      isBookingRequest = true;
      lastTransitionDate = moment(confirmPayment.createdAt);
    }
  }

  const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1] : null;

  // Retrieve the last read timestamp from localStorage
  const transactionId = transaction?.id?.uuid;
  let lastReadTimestamps = {};
  let lastReadTimestamp = null;

  try {
    lastReadTimestamps = JSON.parse(localStorage.getItem('lastReadTimestamps')) || {};
    lastReadTimestamp = lastReadTimestamps[transactionId];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing lastReadTimestamps from localStorage:', e);
  }

  // Determine if there's a new message since the last read
  const hasNewMessage = lastMessage
    ? !lastReadTimestamp ||
      moment(lastMessage.attributes.createdAt).isAfter(moment(lastReadTimestamp))
    : false;

  let badgeClassName = null;
  let showIcon = false;
  let text = '';

  switch (ownRole) {
    case 'customer':
      if (isSpecialOffer) {
        const remainingHours = calculateRemainingHours(lastTransitionDate, 48);

        if (remainingHours > 17 && remainingHours <= 48) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeGreen;
          showIcon = true;
        } else if (remainingHours > 7 && remainingHours <= 17) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeYellow;
          showIcon = true;
        } else if (remainingHours > 0 && remainingHours <= 7) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeRed;
          showIcon = true;
        } else if (remainingHours >= -24) {
          text = intl.formatMessage({ id: 'InboxPage.badgeExpired' });
          badgeClassName = css.badgeRed;
          showIcon = true;
        }
      } else if (hasNewMessage) {
        const lastMessageSender = lastMessage?.sender?.id?.uuid;

        if (lastMessageSender !== user?.id?.uuid) {
          text = intl.formatMessage({ id: 'InboxPage.badgeNewReply' });
          badgeClassName = css.badgeYellow;
        }
      }

      break;

    case 'provider':
      if (isEnquiry) {
        const lastMessageSender = lastMessage?.sender?.id?.uuid;

        if (lastMessageSender !== user?.id?.uuid) {
          const remainingHours = calculateRemainingHours(lastTransitionDate, 24);

          if (remainingHours > 17 && remainingHours <= 24) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeGreen;
            showIcon = true;
          } else if (remainingHours > 7 && remainingHours <= 17) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeYellow;
            showIcon = true;
          } else if (remainingHours > 0 && remainingHours <= 7) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeRed;
            showIcon = true;
          } else {
            text = intl.formatMessage({ id: 'InboxPage.replyNow' });
            badgeClassName = css.badgeRed;
            showIcon = true;
          }
        }
      } else if (isBookingRequest) {
        const remainingHours = calculateRemainingHours(lastTransitionDate, 72);

        if (remainingHours > 17 && remainingHours <= 72) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeGreen;
          showIcon = true;
        } else if (remainingHours > 7 && remainingHours <= 17) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeYellow;
          showIcon = true;
        } else if (remainingHours > 0 && remainingHours <= 7) {
          text = intl.formatMessage({ id: 'InboxPage.badgeToDecide' }, { hours: remainingHours });
          badgeClassName = css.badgeRed;
          showIcon = true;
        } else {
          text = intl.formatMessage({ id: 'InboxPage.expired' });
          badgeClassName = css.badgeRed;
          showIcon = true;
        }
      } else if (lastMessage) {
        const lastMessageDate = moment(lastMessage.attributes.createdAt);

        const lastMessageSender = lastMessage?.sender?.id?.uuid;

        if (lastMessageSender !== user?.id?.uuid) {
          const remainingHours = calculateRemainingHours(lastMessageDate, 48);

          if (remainingHours > 17 && remainingHours <= 48) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeGreen;
            showIcon = true;
          } else if (remainingHours > 7 && remainingHours <= 17) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeYellow;
            showIcon = true;
          } else if (remainingHours > 0 && remainingHours <= 7) {
            text = intl.formatMessage({ id: 'InboxPage.badgeToReply' }, { hours: remainingHours });
            badgeClassName = css.badgeRed;
            showIcon = true;
          } else if (remainingHours >= -24) {
            text = intl.formatMessage({ id: 'InboxPage.replyNow' });
            badgeClassName = css.badgeRed;
            showIcon = true;
          }
        }
      }
      break;

    default:
  }

  if (!text) {
    return null;
  }

  return (
    <div className={classNames(css.itemBadge, badgeClassName)}>
      {showIcon && <ClockIcon className={css.clockIcon} />}
      {text}
    </div>
  );
};

export default injectIntl(InboxListItemBadge);
