import { ID_STATUSES } from '../ducks/IDVerification.duck';

export const MEMBERSHIP_TIERS = {
  BASIC: 'basic',
  PREMIUM: 'premium',
  ADMIN: 'admin',
};

// eslint-disable-next-line import/prefer-default-export
export const isVerified = user => {
  return user?.attributes?.profile?.protectedData?.idVerification?.status === ID_STATUSES.VERIFIED;
};

export const getFirstName = displayName => {
  return displayName ? displayName.split(' ')[0] : '';
};

export const hasAdminMembership = user => {
  return user?.attributes?.profile?.metadata?.membershipTier === MEMBERSHIP_TIERS.ADMIN;
};

export const hasPremiumMembership = user => {
  return (
    user?.attributes?.profile?.metadata?.membershipTier === MEMBERSHIP_TIERS.PREMIUM ||
    hasAdminMembership(user)
  );
};

export const getMembershipDetails = user => {
  return user?.attributes?.profile?.metadata?.membershipDetails || null;
};
