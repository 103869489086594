/* eslint-disable no-nested-ternary */
import React from 'react';
import { string, func, bool, array } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock-solid.svg';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, formatNumber } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, ReviewRating } from '..';
import { ReactComponent as IconHouse } from '../../assets/icons/house-blank.svg';
import { formatList } from '../../util/array';
import {
  getListingStartingPrice,
  getListingPackageMinPrice,
  getListingLocation,
  getListingImages,
  getCategoryLabels,
  getActiveActivityImages,
  LISTING_TITLE_MIN_LENGTH_FOR_LONG_WORDS,
} from '../../util/listing';
import { categories } from '../../marketplace-custom-config';
import { uiElementClicked } from '../../ducks/UI.duck';
import { toggleFavoriteListings } from '../../ducks/user.duck';
import { LISTING_CLICK } from '../../tracking/types';
import FavoriteListing from '../FavoriteListing/FavoriteListing';

import css from './ListingCard.css';

const ListingImage = ({ ...props }) => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    onListingClick,
    isResponsive,
    openListingInNewTab,
    currentUser,
    onToggleFavoriteListing,
    activityChecker,
    activity,
    activities,
    species,
    badgeClassName,
  } = props;

  const defaultRootClass = isResponsive ? css.rootResponsive : css.root;
  const classes = classNames(rootClassName || defaultRootClass, className);
  const badgeClasses = classNames(css.badgeWrapper, badgeClassName);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;

  const slug = createSlug(title);

  // Displays the lowest price available, from each category
  const { gameTypes, location, packages } = publicData;
  const speciesMinPrice = getListingStartingPrice(gameTypes, false, false, species);
  const packagesMinPrice = getListingPackageMinPrice(packages, activities, species);

  const prices = [];

  if (packagesMinPrice) {
    prices.push(packagesMinPrice);
  }

  if (speciesMinPrice) {
    prices.push(speciesMinPrice);
  }

  // get the first activity based image if an activity is passed (i.e. search page filter)
  const activityImage = activity && getActiveActivityImages(listing, activity);

  const firstImage =
    getListingImages(currentListing).length > 0
      ? getListingImages(currentListing)[0]
      : currentListing.images[0];

  const formattedCategories = formatList(getCategoryLabels(categories, publicData.categories));
  const formattedActivity = activity && getCategoryLabels(categories, activity.split(','));

  const listingLocation = getListingLocation(currentListing);
  const maxSportsmen = get(publicData, 'policies.sportsmen', null);
  const otherLinkProps = openListingInNewTab ? { target: '_blank' } : {};
  const acres = location && location.acreage ? formatNumber(location.acreage) : null;
  const hasLodging = get(publicData, 'lodging.hasLodging', false);
  const rating = get(metadata, 'rating', 0);

  return (
    <NamedLink
      className={classes}
      name={activityChecker ? 'ActivityPage' : 'ListingPage'}
      params={{ id, slug, activity }}
      onClick={() => onListingClick({ event: LISTING_CLICK, listing: currentListing })}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
      {...otherLinkProps}
      data-testid="listing-card"
    >
      <div className={isResponsive ? css.imageWrapperResponsive : css.imageWrapper}>
        <div className={css.favoriteWrapper}>
          <FavoriteListing
            className={css.favorite}
            currentUser={currentUser}
            listing={currentListing}
            onUnfavoriteListing={() => onToggleFavoriteListing(currentListing)}
            onFavoriteListing={() => onToggleFavoriteListing(currentListing)}
          />
        </div>

        <div className={css.imageInner}>
          <LazyImage
            rootClassName={isResponsive ? css.imageRootResponsive : css.imageRoot}
            alt={title}
            image={activityImage || firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>

      <div className={isResponsive ? css.infoContainerResponsive : css.infoContainer}>
        <div className={isResponsive ? css.infoMainResponsive : css.infoMain}>
          <div className={isResponsive ? css.infoHeaderResponsive : css.infoHeader}>
            {maxSportsmen && (
              <FormattedMessage id="ListingCard.sportsman" values={{ count: maxSportsmen }} />
            )}
            {formattedActivity && <span> | {formattedActivity}</span>}
            {!formattedActivity && formattedCategories && <span> | {formattedCategories}</span>}
          </div>

          <div className={isResponsive ? css.infoTitleResponsive : css.infoTitle}>
            {richText(title, {
              longWordMinLength: LISTING_TITLE_MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          <div className={isResponsive ? css.infoFooterResponsive : css.infoFooter}>
            {acres ? `${acres} Acres` : null}
            {` in ${listingLocation}`}
          </div>

          <div className={css.ratingWrapper}>
            {rating ? (
              <div>
                <ReviewRating
                  rating={rating}
                  className={css.reviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                  gold
                />
              </div>
            ) : (
              <div className={css.firstToBook}>
                <ClockIcon className={css.clockIcon} />
                <FormattedMessage id="ListingCard.firstToBook" />
              </div>
            )}
          </div>
          {hasLodging && (
            <div className={badgeClasses}>
              <IconHouse className={css.icon} />

              <span className={css.infoSpan}>
                <FormattedMessage id="ListingCard.lodgingBadge" />
              </span>
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  onListingClick: () => {},
  isResponsive: false,
  openListingInNewTab: false,
  currentUser: null,
  hideListingPrice: false,
  activities: [],
  species: [],
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  isResponsive: bool,
  openListingInNewTab: bool,
  hideListingPrice: bool,
  onListingClick: func,
  currentUser: propTypes.currentUser,
  activities: array,
  species: array,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onListingClick: payload => dispatch(uiElementClicked(payload)),
  onToggleFavoriteListing: listing => dispatch(toggleFavoriteListings(listing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ListingCardComponent));
