/* eslint-disable no-nested-ternary */
import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { arrayOf, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { isTransactionInitiateBookingTimeNotAvailableError } from '../../util/errors';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, PrimaryButton, NamedLink } from '..';
import { SpecialOfferForm } from '../../forms';

import css from './SpecialOfferPanel.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const openBookModal = (isClosed, history, location) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ ...parse(search), book: true })}`;
  history.push(`${pathname}${searchString}`, state);
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const SpecialOfferPanel = props => {
  const {
    rootClassName,
    className,
    timeSlots,
    onSubmit,
    listing,
    onManageDisableScrolling,
    fetchTimeSlotsError,
    subTitle,
    intl,
    unitType,
    sportsmanName,
    transaction,
    location,
    history,
    initiateOrderError,
    stripeConnected,
  } = props;

  const handleSubmit = values => {
    onSubmit(values);
  };

  const { price } = listing.attributes;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showSpecialOfferForm = hasListingState && !isClosed && stripeConnected;
  const showClosedListingHelpText = listing.id && isClosed;
  const isBook = !!parse(location.search).book;

  const subTitleText =
    subTitle ||
    (showClosedListingHelpText
      ? intl.formatMessage({ id: 'SpecialOfferPanel.subTitleClosedListing' })
      : null);

  const classes = classNames(rootClassName || css.root, className);

  const sendSportsmanName = (
    <FormattedMessage id="TransactionPanel.sendSportsmanName" values={{ name: sportsmanName }} />
  );
  const sportsmanSubInfo = (
    <FormattedMessage id="SpecialOfferPanel.offerSubtitle" values={{ name: sportsmanName }} />
  );

  const paymentDetailsPage = (
    <NamedLink
      className={css.reminderModalLinkButton}
      name="ProfileSettingsPage"
      params={{ tab: 'payout-details' }}
    >
      <FormattedMessage id="ModalMissingInformation.gotoPaymentSettings" />
    </NamedLink>
  );
  const connectStripeInfo = (
    <FormattedMessage
      id="SpecialOfferPanel.connectStripeInfo"
      values={{ sportsmanName, paymentDetailsPage }}
    />
  );
  const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
    initiateOrderError
  );
  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <div className={css.sportsmanInfo}>{sendSportsmanName}</div>
          <div className={css.author}>
            <FormattedMessage
              id="SpecialOfferPanel.offerSubtitle"
              values={{ name: sportsmanName }}
            />
          </div>
        </div>
        <div className={css.bookingHeading}>
          <div className={css.sportsmanInfo}>{sendSportsmanName}</div>
          {stripeConnected ? (
            <div className={css.sportsmanSubInfo}>{sportsmanSubInfo}</div>
          ) : (
            <div className={css.sportsmanSubInfo}>{connectStripeInfo}</div>
          )}
          {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
        </div>
        {showSpecialOfferForm ? (
          <SpecialOfferForm
            className={css.bookingForm}
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            transaction={transaction}
            onSubmit={handleSubmit}
            price={price}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
          />
        ) : null}
        {isBookingTimeNotAvailableError ? (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
          </p>
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {showSpecialOfferForm ? (
          <PrimaryButton
            isAnimating
            className={css.bookButton}
            onClick={() => {
              openBookModal(isClosed, history, location);
            }}
          >
            <FormattedMessage id="SpecialOfferPanel.ctaButtonMessage" values={{ sportsmanName }} />
          </PrimaryButton>
        ) : !stripeConnected ? null : !isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="SpecialOfferPanel.loadingText" />
          </div>
        ) : (
          <div className={css.closedListingButton}>
            <FormattedMessage id="SpecialOfferPanel.closedListingButtonText" />
          </div>
        )}
        {!stripeConnected ? <div className={css.sportsmanSubInfo}>{connectStripeInfo}</div> : null}
      </div>
    </div>
  );
};

SpecialOfferPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
};

SpecialOfferPanel.propTypes = {
  rootClassName: string,
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(SpecialOfferPanel);
