import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton } from '../Button/Button';
import { NamedLink } from '..';

import css from './ReviewCard.css';
import { createSlug } from '../../util/urlHelpers';
import ReviewRating from '../ReviewRating/ReviewRating';

const ReviewCard = ({ review, className, gold }) => {
  return (
    <div className={classnames(css.root, className)}>
      <div className={css.imageContainer}>
        <img className={css.image} src={review.image} alt={review.listingTitle} />
      </div>

      <div className={css.reviewContainer}>
        <div className={css.reviewBody}>
          <div className={css.reviewHeader}>
            <ReviewRating
              className={css.reviewRating}
              reviewStarClassName={css.reviewRatingStar}
              rating={review.rating}
              gold={gold}
            />
            <div className={css.reviewTitle}>{review.listingTitle}</div>
            <div className={css.reviewLocation}>
              {review.acreage} Acres • {review.location}
            </div>
          </div>
          <div className={css.reviewQuotes}>“</div>
          <div className={css.reviewContent}>{review.content}</div>
        </div>

        <div className={css.reviewCta}>
          <PrimaryButton
            component={NamedLink}
            name="ListingPage"
            params={{ id: review.listingId, slug: createSlug(review.listingTitle) }}
          >
            <FormattedMessage id="SectionFeaturedReviews.cta" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.shape({
    listingId: PropTypes.string.isRequired,
    listingTitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    acreage: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReviewCard;
