import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import {
  BIRD_ACTIVITY,
  HUNT_ACTIVITY,
  FISH_ACTIVITY,
  FARM_RANCH_ACTIVITY,
} from '../../marketplace-custom-config';
import { FieldReviewRating, Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './ReviewForm.css';

const ReviewFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        sendReviewError,
        sendReviewInProgress,
        isCustomer,
      } = fieldRenderProps;

      const reviewRatingRequiredMessage = intl.formatMessage({
        id: 'ReviewForm.reviewRatingRequired',
      });

      const reviewContent = isCustomer
        ? intl.formatMessage({
            id: 'ReviewForm.reviewContentLabelSportsman',
          })
        : intl.formatMessage({
            id: 'ReviewForm.reviewContentLabelLandowner',
          });

      const reviewContentPlaceholderMessage = isCustomer
        ? intl.formatMessage({
            id: 'ReviewForm.reviewContentPlaceholderSportsman',
          })
        : intl.formatMessage({
            id: 'ReviewForm.reviewContentPlaceholderLandowner',
          });
      const reviewContentRequiredMessage = intl.formatMessage({
        id: 'ReviewForm.reviewContentRequired',
      });
      const errorMessage = isTransactionsTransitionAlreadyReviewed(sendReviewError) ? (
        <p className={css.error}>
          <FormattedMessage id="ReviewForm.reviewSubmitAlreadySent" />
        </p>
      ) : (
        <p className={css.error}>
          <FormattedMessage id="ReviewForm.reviewSubmitFailed" />
        </p>
      );
      const errorArea = sendReviewError ? errorMessage : null;

      const reviewSubmitMessage = intl.formatMessage({
        id: 'ReviewForm.reviewSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendReviewInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldReviewRating
            className={css.reviewRating}
            id={formId ? `${formId}.starRating` : 'starRating'}
            name="reviewRating"
            validate={required(reviewRatingRequiredMessage)}
          />
          <FieldTextInput
            className={css.reviewContent}
            type="textarea"
            id={formId ? `${formId}.reviewContent` : 'reviewContent'}
            name="reviewContent"
            label={reviewContent}
            placeholder={reviewContentPlaceholderMessage}
            validate={required(reviewContentRequiredMessage)}
          />
          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {reviewSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ReviewFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
  packageActivity: null,
};

const { bool, func, string, oneOf } = PropTypes;

ReviewFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  isCustomer: bool.isRequired,
  packageActivity: oneOf([BIRD_ACTIVITY, HUNT_ACTIVITY, FISH_ACTIVITY, FARM_RANCH_ACTIVITY]),
};

const ReviewForm = compose(injectIntl)(ReviewFormComponent);
ReviewForm.displayName = 'ReviewForm';

export default ReviewForm;
