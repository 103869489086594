import React from 'react';
import { oneOf, node } from 'prop-types';
import classNames from 'classnames';

import css from './SectionSteps.css';
import { Container } from '../Layout';

export const Step = ({ icon, title, children }) => {
  return (
    <div className={css.stepRoot}>
      <div className={css.stepHeader}>
        <div className={css.stepIconContainer}>{icon}</div>
        <h2 className={css.stepTitle}>{title}</h2>
      </div>
      <p className={css.stepBody}>{children}</p>
    </div>
  );
};

Step.defaultProps = {
  icon: null,
  title: null,
  children: null,
};

Step.propTypes = {
  icon: node,
  title: node,
  children: node,
};

const SectionSteps = ({ bg, title, description, cta, children }) => {
  return (
    <div className={classNames(css.root, css[`root--${bg}`])}>
      <Container>
        <div>
          <h1 className={css.title}>{title}</h1>

          <p className={css.description}>{description}</p>
        </div>

        <div className={css.stepsWrapper}>
          <div className={css.stepsInner}>{children}</div>
        </div>

        {cta && <div className={css.ctaContainer}>{cta}</div>}
      </Container>
    </div>
  );
};

SectionSteps.propTypes = {
  bg: oneOf(['brown', 'olive', 'gray']),
  title: node,
  description: node,
  cta: node,
  children: node,
};

SectionSteps.defaultProps = {
  bg: 'brown',
  title: null,
  description: null,
  cta: null,
  children: null,
};

export default SectionSteps;
