/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { tagsGroups } from '../../util/photoLibrary';

import css from './Tags.css';

const Tags = ({ tags, onUpdateTags }) => {
  const handleUpdateTags = tag => {
    onUpdateTags(tag);
  };

  const filteredTagsGroups = useMemo(
    () =>
      tagsGroups.filter(group => {
        if (!group.requiredTags) {
          return true;
        }

        return group.requiredTags.some(requiredTag => tags.includes(requiredTag));
      }),
    [tags]
  );

  return (
    <div className={css.container}>
      {filteredTagsGroups.map((group, index) => (
        <div key={`group-${index}`}>
          <div
            className={classNames(css.header, {
              [css.headerLarge]: index === 0,
            })}
          >
            {group.header}
          </div>
          <div className={css.items}>
            {group.tags.map(item => (
              <div key={`group-${item.key}`}>
                <a
                  href="#"
                  className={tags.includes(item.key) ? css.itemSelected : css.item}
                  onClick={e => {
                    e.preventDefault();

                    handleUpdateTags(item.key);
                  }}
                >
                  {item.label}
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tags;
