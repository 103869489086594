import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: Feb 6, 2023</p>

      <div>
        <div>
          <p className={css.title}>
            <strong>Introduction</strong>
          </p>
          <br />
          <p>
            LandTrust is a community of outdoor enthusiasts and landowners with a desire to enjoy
            and protect the outdoors. While gathering information about the people who use
            LandTrust.com, our apps, platforms and related sites (collectively referred to as
            “LandTrust Sites”), we take your privacy very seriously and make every effort to
            safeguard your personal data. This privacy policy is intended to inform you of what
            information we collect, how this information is used and what steps we take to protect
            this information, as well as providing you a process to update, change or delete your
            personal data.
          </p>
          <br />
          <p>
            By using LandTrust Sites, you agree to our privacy policy. If you wish to update your
            account information or any specific content that you have provided to us on any of
            LandTrust Sites, please contact us at{' '}
            <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a>.
          </p>
          <br />
        </div>
        <div>
          <p className={css.title}>
            <strong>What information we collect</strong>
          </p>
          <br />
          We collect three (3) types of information:
          <ul className={css.disc}>
            <li className={css.disc}>
              Information that you provide directly to us when you create an account with us.
            </li>
            <li className={css.disc}>
              Data collected automatically such as tracking information, IP addresses or other data
              related tousage on Landtrust Sites.
            </li>
            <li className={css.disc}>
              Cookies, to help us understand how our users use the Landtrust Sites.
            </li>
          </ul>
        </div>
        <div>
          <p className={css.title}>
            <strong>Information you provide directly to us</strong>
          </p>
          <br />
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>Account information.</strong>
              <br />
              <p>
                At account registration, you provide information, such as your name, email address,
                and password, and you have the option to provide other information. If you create an
                account through a user profile (such as Facebook, or other social media sites) then
                your information is sent from your profile to use in accordance with our privacy
                policy. We will never sell or make available such data to any third party without
                your express permission.
              </p>
              <p>
                You can request that your account be deactivated at any time by contacting us at{' '}
                <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a>. When your account
                is deactivated, your user profile and all public activities (such as comments) will
                no longer be accessible by you or any other user of the LandTrust Sites. To
                unsubscribe from our newsletters, click on the link at the bottom of a newsletter
                you have received, or you can manage your subscriptions from your LandTrust profile.
                For any other changes regarding your personal data, please contact{' '}
                <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a> with your request
                and we will respond to you promptly.
              </p>
            </li>

            <br />
            <br />

            <li className={css.disc}>
              <strong>Booking information.</strong>
              <p>
                We collect your information when you book an experience with a Landowner through a
                LandTrust Site. At times, you may be asked to provide additional information, such
                as payment to provide under a secure payment process. When you provide your credit
                card number, it will only be shared with verified third parties who perform tasks
                required to complete the purchase transaction, and only the last four digits will be
                stored.
              </p>
            </li>

            <br />
            <br />
            <li className={css.disc}>
              <strong> Comments and other public activities.</strong>
              <br />
              We offer you the opportunity to comment on any experiences you book through a
              LandTrust Site in the form of reviews. These comments are visible to other users, and
              any information you include in your comments may be read, collected or used by third
              parties. We are not responsible for any personally identifiable information you choose
              to submit in public forums. Information shared here may be used by others, potentially
              to send you unsolicited messages. Therefore, you should be mindful of what you share.
            </li>
          </ul>
        </div>
        <br />
        <div>
          <p className={css.title}>
            <strong>Opt-in Data Collection:</strong>
          </p>
          <br />
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>Landowners</strong>
              <br />
              <ul>
                <li className={css.disc}>
                  As a normal part of our platform, LandTrust may seek to collect data about the
                  types of wildlife species typically present on a Landowner’s property, the number
                  of animals, size of animals, type of habitat, existence or use of food/mineral
                  plots, existence of predators, etc.
                </li>
                <li className={css.disc}>
                  As we seek to provide our Landowner partners with the additional value added
                  services, such as regenerative practice adoption and/or information around land
                  management for wildlife, we may also request information around grazing practices
                  like herd type, size, paddock sizes, and rotational frequency, as well as
                  agronomic practices like the use and timing of cover crops, food plots, tillage
                  practices, crop rotation, headlands usage, timber management practices, and
                  certain related land management practices such as controlled burns and predator
                  management.
                </li>
                <br />
                <ul className={css.disc}>
                  <li className={css.disc}>
                    LandTrust will not sell or make available such data to any third party without
                    the express consent of the Landowner or otherwise required by law.
                  </li>
                  <li className={css.disc}>
                    LandTrust may use this data, on an aggregated and anonymized basis:
                  </li>
                  <br />
                  <ul className={css.disc}>
                    <li className={css.disc}>
                      to participate with certain state and federal wildlife management agencies
                      regarding certain aspects of a region’s wildlife; for example, to provide
                      accurate reflections of the size and health of certain species for the purpose
                      of informing season dates and harvest targets
                    </li>
                    <li className={css.disc}>
                      to summarize the negative economic impact across our landowner operations such
                      as to facilitate better localized management through normal state programs
                      with a goal of reducing such impact through better wildlife management
                      practices
                    </li>
                    <li className={css.disc}>
                      to identify or quantify opportunities for the Landowner such as agronomic or
                      livestock practice changes, qualified grants or other land or animal
                      improvement programs
                    </li>
                  </ul>
                </ul>
              </ul>
            </li>
          </ul>
          <br />
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>Guests</strong>
              <br />
              <ul>
                <li className={css.disc}>
                  LandTrust may provide some or all aspects of this data to the listing owner as
                  part of the guest’s request to book an experience in an effort to facilitate the
                  transaction.
                </li>
                <li className={css.disc}>
                  As a normal part of our platform LandTrust may request your permission to collect
                  data about the particular aspects of your Activity, relative experience, preferred
                  methods, areas of interest, past experiences and future goals, as well as
                  mentor/mentee opportunities. LandTrust may also collect Guest-provided data to
                  track the type of wildlife observed, location, number, time of day, etc. as well
                  as relevant animal harvest information.  LandTrust may also collect Guest-provided
                  data about the types of equipment in use by a Guest and their party, including the
                  desire or need for new equipment in the future.
                </li>
                <br />
                <ul className={css.disc}>
                  <li className={css.disc}>
                    LandTrust will not sell or make available such data to any third party for the
                    purpose of third party marketing to a Guest.
                  </li>
                  <li className={css.disc}>
                    LandTrust will not use the likeness or image of a Guest in any situation without
                    his or her express permission.
                  </li>
                  <li className={css.disc}>
                    LandTrust will not use data in any way to manipulate the price of an Activity on
                    its platform. The pricing of Activities will remain in the control of the
                    Landowner.
                  </li>
                  <li className={css.disc}>
                    LandTrust may use this data in cooperation with any law enforcement or fish,
                    game, wildlife officers in support of any investigation into Guest wrongdoing.
                  </li>
                  <li className={css.disc}>
                    LandTrust may use this data, on an aggregated and anonymized basis:
                  </li>
                  <br />
                  <ul className={css.disc}>
                    <li className={css.disc}>
                      to participate with certain state and federal wildlife management agencies
                      regarding certain aspects of a region’s wildlife; for example, to provide
                      accurate reflections of the size and health of certain species for the purpose
                      of informing season dates and harvest targets
                    </li>
                    <li className={css.disc}>
                      in order to inform Landowners on fair market pricing. Such information shall
                      only serve as guidance for Landowners as LandTrust does not set Activity
                      pricing on its platform
                    </li>
                  </ul>
                  <br />
                  <li className={css.disc}>
                    If the Guest has provided us permission, LandTrust may use such information to
                    inform the Guest of equipment deals or discounts which may be of interest to the
                    Guest.
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
          <br />
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>All Users</strong>
              <br />
              <ul>
                <li className={css.disc}>
                  <u>Tracking information for our site.</u> This type of information is
                  automatically collected and stored relating to the site’s usage. We perform
                  statistical analyses of users of the site, and their viewing patterns, for product
                  development purposes and to generally inform advertisers about the nature of our
                  audience.
                </li>
                <li className={css.disc}>
                  <u>Device data.</u> We may collect information about the computer, mobile device
                  or other device you use to access LandTrust sites, such as IP address, geolocation
                  information, unique device identifiers, browser type, browser language and other
                  preferences automatically collected. We use this information to help us understand
                  how our users interact with the LandTrust sites.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>Data collected with cookies</strong>
              <br />
              <ul>
                <li className={css.disc}>
                  We use various technologies, such as Google Analytics cookies, to manage
                  LandTrust.com and track use of the content we provide. Cookies provide a mechanism
                  for us to understand how you use the site over time so that we can provide you the
                  best user experience according to your expressed preferences. We do not sell this
                  information to any third party, but may provide information to partners that
                  assist us in updating, managing or maintaining our websites, including:
                </li>
                <br />
                <ul className={css.disc}>
                  <li className={css.disc}>standard web analytics information</li>
                  <li className={css.disc}>
                    behavioral advertising which allows third parties to collect cookies to help
                    LandTrust advertise offerings more efficiently,
                  </li>
                  <li className={css.disc}>
                    aggregated data about email click-through rates and video viewing,
                  </li>
                  <li className={css.disc}>
                    data collected through HTML cookies, flash cookies, web beacons and similar
                    technologies, and
                  </li>
                  <li className={css.disc}>
                    demographics and other details to help us customize our sites based on your
                    preferences.
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
        <br />
        <div>
          <p className={css.title}>
            <strong>Your Rights</strong>
          </p>
          <p>
            You can exercise any of the rights described below consistent with applicable law by
            contacting us at <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a>. We
            may ask you to verify your identity and request before taking further action on your
            request.
          </p>
        </div>
        <div>
          <p className={css.title}>
            <strong>Managing Your Information</strong>
          </p>
          <p>
            You can access and update some of your personal information through your account
            settings. You are responsible for keeping your personal information up to date.
          </p>
        </div>
        <div>
          <ul className={css.disc}>
            <li className={css.disc}>
              <strong>Data Erasure</strong>
              <br />
              <ul>
                <li className={css.disc}>
                  You can request that your information be deleted by contacting us at{' '}
                  <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a>. Please note
                  that if you request deletion of your information, or if your account is suspended,
                  terminated, or voluntarily closed:
                </li>
                <br />
                <ul className={css.disc}>
                  <li className={css.disc}>
                    We may retain your information as necessary for our legitimate business
                    interests, such as prevention of money laundering, fraud detection and
                    prevention, and enhancing safety.
                  </li>
                  <li className={css.disc}>
                    We may retain and use your information to the extent necessary to comply with
                    our legal obligations.
                  </li>
                  <li className={css.disc}>
                    Information you have shared with others (e.g., Reviews, Ratings, User Content)
                    will continue to be publicly visible on LandTrust, even after your account is
                    canceled. However, attribution of such information to you will be removed. Some
                    copies of your information (e.g., your Listings or use of Services) will remain
                    in our database, but are disassociated from personal identifiers.
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <p className={css.title}>
            <strong>Children Under Age</strong>
          </p>
          <p>
            We do not knowingly collect information from children under 13 without verifiable
            parental consent. If we learn that we have collected any information from a child under
            the age of 13 (or under the age of 16 from the European Union) without verifiable
            parental consent, we will delete it from our database as quickly as possible. If you
            believe that we may have collected information from a child under age, please contact us
            at <a href="mailto: privacy@landtrust.com">privacy@landtrust.com</a>.
          </p>
        </div>
        <div>
          <p className={css.title}>
            <strong>Deactivating Target Advertising</strong>
          </p>
          <p>
            If you wish to opt out of advertising based on your browsing activities, interests and
            geographic location, you can opt to only receive generic advertising; for more
            information, please visit 
            <a href="http://preferences-mgr.truste.com" target="_blank" rel="noreferrer">
              http://preferences-mgr.truste.com
            </a>
            . To learn more about targeted advertising, please refer to the Digital Advertising
            Alliance on how you may opt out:{' '}
            <a href="http://www.aboutads.info/PMC" target="_blank" rel="noreferrer">
              http://www.aboutads.info/PMC
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
