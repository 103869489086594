import React from 'react';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import css from './PageSlider.css';

const PageSlider = ({ children, active, onClose, width = '100%' }) => {
  const overlayClasses = classNames(css.overlay, {
    [css.overlayActive]: active,
  });

  const contentClasses = classNames(css.content, {
    [css.contentActive]: active,
    [css.contentFull]: active && width === '100%',
    [css.contentHalf]: active && width === '50%',
  });

  return (
    <div className={overlayClasses}>
      <div className={contentClasses}>
        <div>{children}</div>
        <button type="button" className={css.buttonClose} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default PageSlider;
