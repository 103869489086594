/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import { get } from 'lodash';
import { generateTransaction } from '../../util/transaction';
import { LINE_ITEM_UNITS } from '../../util/types';
import { convertMoneyToNumber } from '../../util/currency';
import { PricingDetails } from '../../components';

import css from './BookingDatesForm.css';

const EstimatedBreakdownMaybe = props => {
  const { bookingData, listing, totalLabel, onPriceChange } = props;

  const { unitType, unitPrice, startDate, endDate, quantity } = bookingData;

  const isUnits = unitType === LINE_ITEM_UNITS;
  const quantityIfUsingUnits = !isUnits || Number.isInteger(quantity);
  const canEstimatePrice = startDate && endDate && unitPrice && quantityIfUsingUnits;
  if (!canEstimatePrice) {
    return null;
  }

  const tx = generateTransaction(bookingData, listing);

  if (!tx?.attributes?.payoutTotal?.amount && !tx?.attributes?.payinTotal?.amount) {
    return null;
  }

  if (onPriceChange) {
    const totalPrice = get(tx, 'attributes.payinTotal', null);
    const formattedTotalPrice = totalPrice ? convertMoneyToNumber(totalPrice) : 0;
    onPriceChange(formattedTotalPrice);
  }

  return (
    <PricingDetails
      className={css.receipt}
      userRole="customer"
      unitType={unitType}
      transaction={tx}
      booking={tx.booking}
      totalLabel={totalLabel}
    />
  );
};

export default EstimatedBreakdownMaybe;
