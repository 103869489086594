import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import {
  Page,
  Container,
  CustomerExperienceCard,
  PrimaryButton,
  NamedLink,
} from '../../components';
import {
  fetchVerificationData,
  verifyIdentity,
  ID_STATUSES,
} from '../../ducks/IDVerification.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { ReactComponent as LockSVG } from '../../assets/icons/lock.svg';

import css from './IDVerificationPage.css';

const StatusBanner = ({ variant, title, body, cta }) => {
  return (
    <div
      data-testId={`verification-status-${variant}`}
      className={classNames(css.statusBannerRoot, css[`statusBannerRoot--${variant}`])}
    >
      <h2>{title}</h2>
      <p>{body}</p>
      {cta}
    </div>
  );
};

const IDVerificationPageComponent = ({
  scrollingDisabled,
  intl,
  history,
  data,
  onFetchVerificationData,
  isLoading,
  verificationInProgress,
  onVerifyId,
}) => {
  const { status, metadata } = data || {};
  const title = intl.formatMessage({ id: 'IDVerificationPage.title' });

  const exit = () => {
    history.push(createResourceLocatorString('ProfileSettingsBasePage', routeConfiguration(), {}));
  };

  const exitButton = (
    <button type="button" className={css.topBarExitButton} onClick={exit}>
      {intl.formatMessage({ id: 'IDVerificationPage.exitButton' })}
    </button>
  );

  useEffect(() => {
    onFetchVerificationData();
  }, [onFetchVerificationData]);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        customMobileSecondaryActions={exitButton}
        customDesktopSecondaryActions={exitButton}
      />
      <Container>
        <div className={css.container}>
          <div className={css.verificationContainer}>
            <h1 data-testid="verification-header">
              <FormattedMessage id="IDVerificationPage.heading" />
            </h1>

            <FormattedMessage id="IDVerificationPage.intro" tagName="p" />
            {status === ID_STATUSES.UNVERIFIED && (
              <FormattedHTMLMessage id="IDVerificationPage.provideId" tagName="p" />
            )}

            <div data-testid="verification-status" className={css.frameContainer}>
              {status === ID_STATUSES.VERIFIED && (
                <StatusBanner
                  variant="success"
                  title={<FormattedMessage id="IDVerificationPage.approvedVerifiedTitle" />}
                  body={<FormattedMessage id="IDVerificationPage.approvedVerifiedDescription" />}
                />
              )}

              {status === ID_STATUSES.PROCESSING && (
                <StatusBanner
                  variant="warning"
                  title={<FormattedMessage id="IDVerificationPage.pendingVerifiedTitle" />}
                  body={<FormattedMessage id="IDVerificationPage.pendingVerifiedDescription" />}
                />
              )}

              {status === ID_STATUSES.ERROR && (
                <>
                  <StatusBanner
                    variant="error"
                    title={
                      <FormattedMessage
                        id={`IDVerificationPage.error.${metadata.error.code}.title`}
                      />
                    }
                    body={
                      <FormattedHTMLMessage
                        id={`IDVerificationPage.error.${metadata.error.code}.desc`}
                      />
                    }
                    cta={
                      <PrimaryButton
                        data-testId="verification-button-error"
                        onClick={onVerifyId}
                        inProgress={verificationInProgress}
                        disabled={isLoading}
                      >
                        <FormattedMessage id="IDVerificationPage.retryCTA" />
                      </PrimaryButton>
                    }
                  />
                </>
              )}

              {status === ID_STATUSES.UNVERIFIED && (
                <PrimaryButton
                  data-testId="verification-button-unverified"
                  onClick={onVerifyId}
                  inProgress={verificationInProgress}
                  disabled={isLoading}
                >
                  <FormattedMessage id="IDVerificationPage.verifyCTA" />
                </PrimaryButton>
              )}
            </div>

            <p data-testid="verification-id-sharing" className={css.sharingConsentContainer}>
              <LockSVG className={css.lockIcon} />
              <FormattedMessage id="IDVerificationPage.idSharing" />
            </p>

            <p data-testid="verification-safe-title">
              <FormattedHTMLMessage id="IDVerificationPage.keepingSafeTitle" />
              <br />
              <FormattedMessage id="IDVerificationPage.keepingSafeDescription" />
            </p>

            <p data-testid="verification-privacy-title">
              <FormattedHTMLMessage id="IDVerificationPage.privacyTitle" />
              <br />
              <FormattedMessage id="IDVerificationPage.privacyDescription" />
            </p>

            <div className={css.separator} />

            <NamedLink name="ProfileSettingsBasePage" className={css.backLink}>
              <FormattedMessage id="IDVerificationPage.back" />
            </NamedLink>
          </div>

          <div data-testid="verification-contact" className={css.coltonContainer}>
            <CustomerExperienceCard isHorizontal={false} />
          </div>
        </div>
      </Container>
    </Page>
  );
};

IDVerificationPageComponent.defaultProps = {};

const { bool } = PropTypes;

IDVerificationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    data: state.IDVerification.data,
    isLoading: state.IDVerification.isLoading,
    verificationInProgress: state.IDVerification.verificationInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchVerificationData: () => dispatch(fetchVerificationData()),
  onVerifyId: () => dispatch(verifyIdentity()),
});

const IDVerificationPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(IDVerificationPageComponent);

export default withRouter(IDVerificationPage);
