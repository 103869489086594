import React, { Component } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  NoSearchResults,
  SearchResultsPanel,
  SearchFilters,
  SearchFiltersPanel,
} from '../../components';
import {
  validFilterParams,
  hasSearchGotNonLocationFilters,
  hasSearchGotOnlyLocationFilters,
} from './SearchPage.helpers';
import css from './SearchPage.css';

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearchFiltersPanelOpen: false };
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      pagination,
      searchParamsForPagination,
      primaryFilters,
      secondaryFilters,
      activities,
      species,
      isHidden,
      isAuthenticated,
      hideListingPrice,
    } = this.props;
    const { isSearchFiltersPanelOpen } = this.state;

    const searchFiltersPanelOpen = !!secondaryFilters && isSearchFiltersPanelOpen;
    const selectedSecondaryFilters = secondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const searchFiltersPanelSelectedCount = Object.keys(selectedSecondaryFilters).length;

    const searchFiltersPanelProps = secondaryFilters
      ? {
          isSearchFiltersPanelOpen,
          toggleSearchFiltersPanel: isOpen => {
            this.setState({ isSearchFiltersPanelOpen: isOpen });
          },
          searchFiltersPanelSelectedCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const classes = classNames(rootClassName || css.searchResultContainer, className, {
      [css.searchResultContainerHidden]: isHidden,
    });

    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];

    const noResults = listingsAreLoaded && totalItems === 0;
    const searchHasNonLocationFilters = hasSearchGotNonLocationFilters(urlQueryParams);
    const searchHasGotOnlyLocationFilters = hasSearchGotOnlyLocationFilters(urlQueryParams);

    // We only hide the filters for the case where the user did a location search with no other filters applied
    const showFilters = !(noResults && searchHasGotOnlyLocationFilters);

    return (
      <div className={classes}>
        {showFilters && (
          <>
            <SearchFilters
              className={css.searchFilters}
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              onManageDisableScrolling={onManageDisableScrolling}
              {...searchFiltersPanelProps}
              {...primaryFilters}
              {...secondaryFilters}
            />

            <div className={css.searchResultSummary}>
              {searchInProgress ? (
                <div className={css.loadingResults}>
                  <FormattedMessage id="SearchFilters.loadingResults" />
                </div>
              ) : (
                <span className={css.resultsFound}>
                  <FormattedMessage
                    id="SearchFilters.foundResults"
                    values={{ count: totalItems }}
                  />
                </span>
              )}
            </div>
          </>
        )}

        {noResults && (
          <NoSearchResults
            urlQueryParams={urlQueryParams}
            className={classNames(css.noSearchResults, {
              [css.noSearchResultsNoFilters]: !showFilters,
            })}
            isAuthenticated={isAuthenticated}
            searchHasNonLocationFilters={searchHasNonLocationFilters}
          />
        )}

        {searchFiltersPanelOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            />
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              activities={activities}
              species={species}
              urlQueryParams={urlQueryParams}
              hideListingPrice={hideListingPrice}
            />
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  searchListingsError: null,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,
  urlQueryParams: object.isRequired,
  listings: array,
  resultsCount: number,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  isAuthenticated: bool.isRequired,
};

export default MainPanel;
