import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SectionSteps, Step } from '../../components';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as BrowseIcon } from '../../assets/icons/browse.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

const SectionHowItWorks = () => {
  return (
    <div data-testid="how-it-works-section">
      <SectionSteps
        bg="gray"
        title={<FormattedMessage id="LandingPage.howItWorks.title" />}
        description={<FormattedMessage id="LandingPage.howItWorks.body" />}
      >
        <>
          <Step
            icon={<SearchIcon width={32} height={32} />}
            title={<FormattedMessage id="LandingPage.howItWorks.search.title" />}
          >
            <FormattedMessage id="LandingPage.howItWorks.search.body" />
          </Step>

          <Step
            icon={<BrowseIcon />}
            title={<FormattedMessage id="LandingPage.howItWorks.browse.title" />}
          >
            <FormattedMessage id="LandingPage.howItWorks.browse.body" />
          </Step>

          <Step
            icon={<CheckIcon width={35} height={35} />}
            title={<FormattedMessage id="LandingPage.howItWorks.book.title" />}
          >
            <FormattedMessage id="LandingPage.howItWorks.book.body" />
          </Step>
        </>
      </SectionSteps>
    </div>
  );
};

SectionHowItWorks.propTypes = {};

export default SectionHowItWorks;
