import React, { useState } from 'react';
import classNames from 'classnames';
import config from '../../config';
import { CountdownTimer, ProgressBar } from '..';

import css from './CountdownWithProgressBar.css';

const CountdownWithProgressBar = ({
  label,
  expirationTimestamp,
  containerClassName,
  contentClassName,
  progressBarClassName,
}) => {
  const containerClass = classNames(containerClassName || css.container);
  const contentClass = classNames(contentClassName || css.content);
  const progressBarClass = classNames(progressBarClassName || css.progressBar);

  const [progress, setProgress] = useState(0);

  // In seconds
  const totalDuration = config.packagesEarlyAccessDays * 24 * 60 * 60;

  const handleTimeUpdate = secondsLeft => {
    const progressPercentage = totalDuration > 0 ? (secondsLeft / totalDuration) * 100 : 0;
    setProgress(parseInt(100 - progressPercentage, 10));
  };

  return (
    <div className={css.root}>
      <div className={css.progressBarContainer}>
        <ProgressBar progress={progress} className={progressBarClass} />
      </div>

      <div className={containerClass}>
        <div className={contentClass}>
          <div className={css.label}>{label}</div>

          <div className={css.counter}>
            <CountdownTimer
              expirationTimestamp={expirationTimestamp}
              onTimeUpdate={handleTimeUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownWithProgressBar;
