import { get } from 'lodash';
import config from '../config';
import { isFeatureFlagEnabled } from './localStorage';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../marketplace-custom-config';

export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const LANDOWNER_STORY = 'landownerStory';
export const LODGING = 'lodging';
export const CAMPSITE = 'campsite';
export const HUNT_EXPERIENCE = 'huntingExperience';
export const FISH_EXPERIENCE = 'fishingExperience';
export const BIRD_EXPERIENCE = 'birdingExperience';
export const FARM_RANCH_EXPERIENCE = 'farmRanchExperience';
export const OUTDOOR_RECREATION_EXPERIENCE = 'outdoorRecreationExperience';
export const LAND_DETAILS = 'landDetails';
export const FEATURES_TYPES = 'featuresTypes';
export const AMENITIES_TYPES = 'amenitiesTypes';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const ACCESS = 'accessFee';
export const PHOTOS = 'photos';
export const PHOTO_LIBRARY = 'photoLibrary';
export const ARRIVAL = 'arrival';
export const PROPERTY = 'property';
export const SUMMARY = 'summary';
export const ACCESS_ACTIVITY = 'access';
export const PROPERTY_VISIT = 'propertyVisit';

// EditListingWizardTab component supports these tabs
// TODO: SUMMARY panel needs to be the last one since it currently contains PayoutDetailsForm modal
// All the other panels can be reordered.
export const SUPPORTED_TABS = [
  LOCATION,
  DESCRIPTION,
  LANDOWNER_STORY,
  LODGING,
  CAMPSITE,
  HUNT_EXPERIENCE,
  OUTDOOR_RECREATION_EXPERIENCE,
  POLICY,
  AVAILABILITY,
  PHOTOS,
  ARRIVAL,
  SUMMARY,
];

// Show availability calendar only if environment variable availabilityEnabled is true
const availabilityMaybe = config.enableAvailability ? [AVAILABILITY] : [];

// TODO: SUMMARY panel needs to be the last one since it currently contains PayoutDetailsForm modal
// All the other panels can be reordered.
export const TABS = [
  LOCATION,
  DESCRIPTION,
  LANDOWNER_STORY,
  LODGING,
  CAMPSITE,
  HUNT_EXPERIENCE,
  OUTDOOR_RECREATION_EXPERIENCE,
  POLICY,
  ...availabilityMaybe,
  PHOTOS,
  ARRIVAL,
  SUMMARY,
];

export const TABS_PREFIX = [
  LOCATION,
  DESCRIPTION,
  LANDOWNER_STORY,
  LODGING,
  CAMPSITE,
  PHOTO_LIBRARY,
];

export const TABS_SUFFIX = [POLICY, ...availabilityMaybe, ARRIVAL, SUMMARY];

export const getTabList = (categories = []) => {
  const tabs = [...TABS_PREFIX];

  if (isFeatureFlagEnabled(config.featureFlags.PROPERTY_VISIT)) {
    tabs.push(PROPERTY_VISIT);
  }

  if (categories.includes(config.custom.HUNT_ACTIVITY)) {
    tabs.push(HUNT_EXPERIENCE);
  }

  if (categories.includes(config.custom.OUTDOOR_RECREATION_ACTIVITY)) {
    tabs.push(OUTDOOR_RECREATION_EXPERIENCE);
  }

  tabs.push(...TABS_SUFFIX);

  return tabs;
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
export const tabCompleted = (tab, listing) => {
  const { availabilityPlan, description, geolocation, title, publicData } = listing.attributes;
  const categories = get(publicData, 'categories', []);
  const { images } = listing;
  if (!publicData) {
    return false;
  }

  switch (tab) {
    case LOCATION:
      return !!(geolocation && publicData.location);
    case DESCRIPTION:
      return !!(description && title && categories.length > 0);
    case LANDOWNER_STORY:
      return true;
    case LODGING:
      return !!publicData.lodging;
    case CAMPSITE:
      return !!publicData.campsites;
    case HUNT_EXPERIENCE: {
      const hasPackage = get(publicData, 'packages', []).filter(
        item => item.activity === HUNT_ACTIVITY
      );

      return hasPackage.length > 0;
    }
    case OUTDOOR_RECREATION_EXPERIENCE: {
      const hasPackage = get(publicData, 'packages', []).filter(
        item => item.activity === OUTDOOR_RECREATION_ACTIVITY
      );

      return hasPackage.length > 0;
    }

    case POLICY:
      return !!publicData.policies;
    case AVAILABILITY:
      return !!availabilityPlan;
    case ARRIVAL:
      return !!publicData.arrivalInstructions;
    case PHOTOS:
      return images && images.length > 0;
    case SUMMARY:
      return true;
    case PHOTO_LIBRARY:
      return true;
    case PROPERTY_VISIT:
      return true;
    default:
      return false;
  }
};

export const tabsCompleted = currentListing => {
  const availableTabs = getTabList(currentListing.attributes.publicData.categories).filter(
    tab => tab !== SUMMARY
  );
  const canBePublished = availableTabs.every(tab => tabCompleted(tab, currentListing));

  return canBePublished;
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
export const tabsActive = (tabs, isNew, listing) => {
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const isActive =
      previousTabIndex >= 0 ? !isNew || tabCompleted(tabs[previousTabIndex], listing, true) : true;
    return { ...acc, [tab]: isActive };
  }, {});
};
