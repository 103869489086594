import React from 'react';
import { func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import {
  getTabList,
  tabCompleted,
  SUMMARY,
  tabsCompleted,
  LANDOWNER_STORY,
} from '../../util/editListing';
import { EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { EditListingSummaryForm } from '../../forms';
import {
  createSlug,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';

const EditListingSummaryPanel = ({
  rootClassName,
  className,
  listing,
  onSubmit,
  onChange,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  errors,
  currentUserHasListings,
}) => {
  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);

  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;
  const id = currentListing.id.uuid;
  const type = isDraft ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;

  const availableTabs = getTabList(listing.attributes.publicData.categories).filter(tab => {
    if (tab === LANDOWNER_STORY && (!isDraft || currentUserHasListings)) return false;
    return tab !== SUMMARY;
  });
  const listingItems = availableTabs.map(tab => ({
    label: tab,
    isValid: tabCompleted(tab, currentListing),
  }));

  const canBePublished = tabsCompleted(currentListing);

  let panelTitle;

  if (!isDraft) {
    panelTitle = <FormattedMessage id="EditListingSummaryPanel.titleEdit" />;
  } else {
    panelTitle = canBePublished ? (
      <FormattedMessage id="EditListingSummaryPanel.titleCanPublish" />
    ) : (
      <FormattedMessage id="EditListingSummaryPanel.titleCannotPublish" />
    );
  }

  const listingDetails = {
    slug,
    id,
    type,
  };

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingSummaryForm
        listing={listingDetails}
        onSubmit={onSubmit}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listingItems={listingItems}
        canBePublished={canBePublished}
        isDraft={isDraft}
      />
    </EditListingPanel>
  );
};

EditListingSummaryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  currentUserHasListings: bool.isRequired,
};

EditListingSummaryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

export default EditListingSummaryPanel;
