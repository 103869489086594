import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import moment from 'moment';
import { TRANSITION_ENQUIRE_GATED, TRANSITION_ENQUIRE_EXPIRE } from '../../util/transaction';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';
import { daysAdded } from '../../util/dates';

const InboxTransitionEnquireExpire = ({ transaction, ownRole, buttons, user, isMessage }) => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const customer = get(transaction, 'customer', null);
  const customerDisplayName = getFirstName(get(customer, 'attributes.profile.displayName', ''));

  const inquiryExpiredTransition = transitions.find(
    item => item.transition === TRANSITION_ENQUIRE_EXPIRE
  );
  const inquiryGatedTransition = transitions.find(
    item => item.transition === TRANSITION_ENQUIRE_GATED
  );

  const isOwnTransition = inquiryGatedTransition.by === ownRole;

  const startDate = get(transaction, 'attributes.protectedData.startDate', '');
  const endDate = get(transaction, 'attributes.protectedData.endDate', '');
  const selectedPackage = get(transaction, 'attributes.protectedData.selectedPackage', null);
  const packageTitle = get(selectedPackage, 'packageTitle', null);
  const dayCount = daysAdded(moment(startDate), moment(endDate));
  const isSingleDay = dayCount === 1;
  const startDateFormat = isSingleDay
    ? moment(startDate).format('ddd. MMMM Do, YYYY')
    : moment(startDate).format('ddd. MMMM Do');
  const endDateFormat = moment(endDate).format('ddd. MMMM Do, YYYY');
  const dateFormat = isSingleDay ? startDateFormat : `${startDateFormat} - ${endDateFormat}`;

  const transition = transitions.find(item => item.transition === TRANSITION_ENQUIRE_GATED);
  const formattedMessageId =
    transition.by === ownRole
      ? 'BookingStatus.ownTransitionHasPackage'
      : 'BookingStatus.transitionHasPackage';

  const inquiryDisplayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <>
      <InboxTransitionBase
        date={inquiryExpiredTransition.createdAt}
        variation={isOwnTransition ? 'dark' : 'light'}
        direction={isOwnTransition ? 'right' : 'left'}
        header={
          !isMessage ? (
            <>
              {isOwnTransition ? (
                <FormattedMessage id="BookingStatus.transitionOwnEnquiryExpired" />
              ) : (
                <FormattedMessage id="BookingStatus.transitionEnquiryExpired" />
              )}
            </>
          ) : null
        }
        buttons={buttons}
        isMessage={isMessage}
      >
        {isMessage ? (
          <div>
            <FormattedHTMLMessage
              id={formattedMessageId}
              values={{
                inquiryDisplayName,
                dateFormat,
                packageTitle,
              }}
            />
          </div>
        ) : (
          <>
            {isOwnTransition ? (
              <FormattedMessage id="BookingStatus.transitionOwnEnquiryExpiredSubtext" />
            ) : (
              <FormattedMessage
                id="BookingStatus.transitionEnquiryExpiredSubtext"
                values={{
                  guest: customerDisplayName,
                }}
              />
            )}
          </>
        )}
      </InboxTransitionBase>
    </>
  );
};

export default InboxTransitionEnquireExpire;
