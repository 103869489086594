import React, { useState } from 'react';
import { bool, array, string } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { FieldLabel } from '..';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl';
import css from './FieldMultipleLocationInput.css';

const Item = ({ location, onDelete }) => (
  <div data-testid={`item-${location.toLowerCase()}`} className={css.item}>
    <div className={css.itemText}>{location}</div>

    <button type="button" className={css.itemIcon} onClick={() => onDelete(location)}>
      <CloseIcon />
    </button>
  </div>
);

const FieldMultipleLocationInputComponent = ({ fields, ...restProps }) => {
  const { label, meta, ...otherProps } = restProps;
  const [inputValue, setInputValue] = useState({});

  const handleValueAddition = value => {
    const currentValue = [...fields.value];

    const index = currentValue.findIndex(field => field.address === value.address);

    if (index === -1) {
      currentValue.push(value);
      fields.change(currentValue);
    }
  };

  const handleValueDelete = value => {
    const filteredValue = fields.value.filter(field => {
      if (field.address !== value) {
        return true;
      }

      return false;
    });

    fields.change(filteredValue);
  };

  const fieldInput = {
    onChange: item => {
      if (item.selectedPlace) {
        handleValueAddition(item.selectedPlace);

        setInputValue({});
      } else {
        setInputValue(item);
      }
    },
    name: fields.name,
    value: inputValue,
  };

  const locationAutocompleteProps = {
    label,
    fields,
    showCheckbox: true,
    meta,
    ...otherProps,
    input: { ...fieldInput },
  };

  return (
    <div>
      {label && <FieldLabel>{label}</FieldLabel>}
      <LocationAutocompleteInputImpl {...locationAutocompleteProps} />

      <div className={css.items}>
        {fields?.value?.map(field => (
          <Item
            key={`location-${field.address}`}
            location={field.address}
            onDelete={handleValueDelete}
          />
        ))}
      </div>
    </div>
  );
};

FieldMultipleLocationInputComponent.defaultProps = {
  name: null,
  label: null,
  placeholder: null,
  useDefaultPredictions: true,
  showButton: true,
  showIcon: true,
};

FieldMultipleLocationInputComponent.propTypes = {
  name: string,
  label: string,
  placeholder: string,
  useDefaultPredictions: bool,
  valueFromForm: array.isRequired,
  showButton: bool,
  showIcon: bool,
};

const FieldMultipleLocationInput = props => {
  return <FieldArray component={FieldMultipleLocationInputComponent} {...props} />;
};

export default FieldMultipleLocationInput;
