import React from 'react';
import { FormattedMessage } from 'react-intl';

import { plusQuotes } from '../../marketplace-custom-config';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import css from './PlusTestimonials.css';

const PlusTestimonials = () => {
  return (
    <div className={css.sectionContainerStyle}>
      <div className={css.headerContainerStyles}>
        <div className={css.headingStyles}>
          <FormattedMessage id="SignupForm.quoteTitleCopy" />
        </div>
      </div>
      <div className={css.quoteWrapper}>
        <div className={css.wrapperStyles}>
          {plusQuotes.items.map((testimonial, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TestimonialCard key={`testimonial-${index}`} testimonial={testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlusTestimonials;
