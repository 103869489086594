import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';

import {
  NamedLink,
  PrimaryButton,
  PackageAvailabilityNotifyButton,
  EarlyAccessButton,
  CountdownTimer,
  CredovaPlugin,
} from '../../components';

import css from './PackagePage.css';

const MobileCheckAvailability = ({
  isAvailable,
  outOfBookingRange,
  startingPrice,
  currentTransaction,
  isOnWaitingList,
  setIsPackageAvailabilityModalOpen,
  isBlockedByEarlyAccess,
  earlyAccessExpiration,
  rawParams,
  setIsEarlyAccessModalOpen,
  setIsCredovaModalOpen,
  intl,
  currentUserInit,
  estimatedTotalPrice,
}) => {
  if (!currentUserInit) {
    return null;
  }

  if (isBlockedByEarlyAccess) {
    return (
      <div className={css.mobileCheckAvailabilityWrapper}>
        <div className={css.mobileCheckAvailability}>
          <div className={css.mobileCheckAvailabilityColumn}>
            <EarlyAccessButton setIsModalOpen={setIsEarlyAccessModalOpen} />

            <div className={css.packageAvailableTimer}>
              <FormattedMessage id="PackagePage.packageAvailable" />
              &nbsp;
              <CountdownTimer expirationTimestamp={earlyAccessExpiration} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Show the waiting list or sold out state when not available or out of booking range
  if (!isAvailable || outOfBookingRange) {
    return (
      <div className={css.mobileCheckAvailabilityWrapper}>
        <div className={css.mobileCheckAvailability}>
          <div className={css.mobileCheckAvailabilityColumn}>
            {isOnWaitingList ? (
              <div className={css.getNotified}>
                <b>
                  <FormattedMessage id="PackagePage.waitingListNotified" />
                </b>
              </div>
            ) : (
              <>
                <PackageAvailabilityNotifyButton
                  onClick={() => {
                    setIsPackageAvailabilityModalOpen(true);
                  }}
                />
                <div className={css.getNotified}>
                  <FormattedMessage id="PackagePricingPanel.soldOutgetNotified" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Default return section now shows the price and check availability if within range
  return (
    <div className={css.mobileCheckAvailabilityWrapper}>
      <div className={css.mobileCheckAvailability}>
        <div className={css.mobileCheckAvailabilityPrice}>
          <span className={css.priceText}>{formatMoney(intl, startingPrice)}</span>
          {startingPrice?.amount && (
            <span className={css.perGuestText}>
              <FormattedMessage id="PackageCard.perGuestText" />
            </span>
          )}
        </div>

        <NamedLink
          name="PricingPackagePage"
          params={{
            ...rawParams,
          }}
          to={{
            state: {
              currentTransaction,
            },
          }}
        >
          <PrimaryButton className={css.bookButton}>
            <FormattedMessage id="PackagePage.checkAvailability" />
          </PrimaryButton>
        </NamedLink>
      </div>

      <div className={css.mobileCredova}>
        <CredovaPlugin
          price={estimatedTotalPrice}
          variant="small"
          onModalOpen={setIsCredovaModalOpen}
        />
      </div>
    </div>
  );
};

export default injectIntl(MobileCheckAvailability);
