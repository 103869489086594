import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as IDCard } from '../../assets/icons/id-card-light.svg';
import { NamedLink, PrimaryButton } from '..';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { ContactDetailsForm } from '../../forms';
import css from './AccountDetails.css';
import { isVerified } from '../../util/user';
import { loadData } from '../../containers/StripePayoutPage/StripePayoutPage.duck';

const AccountDetails = ({
  saveEmailError,
  savePhoneNumberError,
  saveContactDetailsInProgress,
  currentUser,
  contactDetailsChanged,
  onContactDetailsClear,
  sendVerificationEmailInProgress,
  sendVerificationEmailError,
  onResendVerificationEmail,
  onSubmit,
  initData,
}) => {
  useEffect(() => {
    initData();
  }, []);
  const user = ensureCurrentUser(currentUser);

  const currentEmail = user.attributes.email || '';
  const currentFirstName = user.attributes.profile.firstName || '';
  const currentLastName = user.attributes.profile.lastName || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const currentPhoneNumber = protectedData.phoneNumber || '';
  const currentSecondaryPhoneNumber = protectedData.secondaryPhoneNumber || '';
  const currentRole = protectedData.role || [];
  const currentReferralMethod = protectedData.referralMethod || null;
  const currentReferralOther = protectedData.referralOther || null;
  const currentConsent = protectedData.smsConsent || false;

  return (
    <div>
      {!isVerified(user) && (
        <div className={css.verifySection}>
          <div className={css.verifySectionHeader}>
            <IDCard className={css.verifySectionIcon} />
            <span className={css.verifySectionHeaderText}>
              <FormattedMessage id="AccountDetails.verifyIdHeader" />
            </span>
          </div>

          <div className={css.verifySectionContent}>
            <FormattedMessage id="AccountDetails.verifyIdBody" />
          </div>

          <NamedLink className={css.verifySectionLink} name="IDVerificationPage">
            <PrimaryButton isSmall>
              <FormattedMessage id="AccountDetails.verifyIdLink" />
            </PrimaryButton>
          </NamedLink>
        </div>
      )}

      <ContactDetailsForm
        initialValues={{
          email: currentEmail,
          fname: currentFirstName,
          lname: currentLastName,
          phoneNumber: currentPhoneNumber,
          secondaryPhoneNumber: currentSecondaryPhoneNumber,
          role: currentRole,
          referralMethod: currentReferralMethod,
          referralOther: currentReferralOther,
          smsConsent: currentConsent,
        }}
        saveEmailError={saveEmailError}
        savePhoneNumberError={savePhoneNumberError}
        currentUser={currentUser}
        onResendVerificationEmail={onResendVerificationEmail}
        onSubmit={values => {
          onSubmit({
            ...values,
            currentFirstName,
            currentLastName,
            currentEmail,
            currentRole,
            currentPhoneNumber,
            currentSecondaryPhoneNumber,
            currentReferralMethod,
            currentConsent,
          });
        }}
        onChange={onContactDetailsClear}
        inProgress={saveContactDetailsInProgress}
        ready={contactDetailsChanged}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />
    </div>
  );
};

AccountDetails.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: null,
  currentUser: null,
  contactDetailsChanged: null,
  onContactDetailsClear: null,
  sendVerificationEmailInProgress: null,
  sendVerificationEmailError: null,
  onResendVerificationEmail: null,
  onSubmit: null,
  initData: null,
};

AccountDetails.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool,
  onContactDetailsClear: func,
  sendVerificationEmailInProgress: bool,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func,
  onSubmit: func,
  initData: func,
};
AccountDetails.displayName = 'AccountDetails';

const mapStateToProps = state => {
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ProfileSettingsPage;
  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  initData: () => dispatch(loadData()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AccountDetails);
