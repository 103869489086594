import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ReactComponent as IconCalendar } from '../../assets/icons/calendar-light.svg';
import { ReactComponent as IconHouse } from '../../assets/icons/house-blank.svg';
import { ReactComponent as IconGuests } from '../../assets/icons/guests.svg';
import { ReactComponent as IconRVFriendly } from '../../assets/icons/icon-rv-friendly.svg';
import { hasLodgingForPackage } from '../../util/package';

import css from './PackageBadges.css';

const PackageBadges = ({ packageInfo, overrideInfo, badgeClassName }) => {
  const badgeClasses = classNames(css.badgeWrapper, badgeClassName);
  const { hasRVSites: originalHasRVSites, isRvFriendly: originalIsRvFriendly, days, guests } =
    packageInfo || {};
  let newHasRVSites = originalHasRVSites;
  let newIsRvFriendly = originalIsRvFriendly;
  const newDays = { ...days };
  const newGuests = { ...guests };

  let hasLodging = hasLodgingForPackage(packageInfo);

  if (overrideInfo?.hasLodging) {
    hasLodging = overrideInfo.hasLodging;
  }

  if (overrideInfo?.days?.min) {
    newDays.min = overrideInfo.days.min;
  }

  if (overrideInfo?.guests?.min) {
    newGuests.min = overrideInfo.guests.min;
  }

  if (overrideInfo?.isRvFriendly) {
    newIsRvFriendly = overrideInfo.isRvFriendly;
  }

  if (overrideInfo?.hasRVSites) {
    newHasRVSites = overrideInfo.hasRVSites;
  }

  return (
    <div className={css.root}>
      {newGuests?.min && (
        <div className={badgeClasses}>
          <IconGuests className={css.icon} />

          <span className={css.infoSpan}>
            <FormattedMessage
              id="PackageCard.guests"
              values={{
                count: newGuests?.min,
              }}
            />
          </span>
        </div>
      )}
      {newDays?.min && (
        <div className={badgeClasses}>
          <IconCalendar className={css.icon} />

          <span className={css.infoSpan}>
            <FormattedMessage
              id="PackageCard.days"
              values={{
                count: newDays?.min,
              }}
            />
          </span>
        </div>
      )}
      {hasLodging && (
        <div className={badgeClasses}>
          <IconHouse className={css.icon} />

          <span className={css.infoSpan}>
            <FormattedMessage id="PackageCard.lodging" />
          </span>
        </div>
      )}
      {newIsRvFriendly && !newHasRVSites && (
        <div className={badgeClasses}>
          <IconRVFriendly className={css.icon} />

          <span className={css.infoSpan}>
            <FormattedMessage id="PackageCard.rvFriendly" />
          </span>
        </div>
      )}

      {newHasRVSites && (
        <div className={badgeClasses}>
          <IconRVFriendly className={css.icon} />

          <span className={css.infoSpan}>
            <FormattedMessage id="PackageCard.rvSites" />
          </span>
        </div>
      )}
    </div>
  );
};

export default PackageBadges;
