import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.css';

const IconRadioButton = ({ className, showAsRequired }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <circle
          className={showAsRequired ? css.required : css.notChecked}
          cx="11"
          cy="11"
          r="10"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />
        <g className={css.checked}>
          <circle cx="11" cy="11" r="11" />
          <path
            d="m 8.8665,13.6005 -2.8,-2.8 -0.933,0.933 3.733,3.733 8,-8 -0.933,-0.933 z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
  const { rootClassName, className, svgClassName, id, label, showAsRequired, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.radioButtonWrapper}>
          <IconRadioButton className={svgClassName} showAsRequired={showAsRequired} />
        </span>
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldRadioButtonComponent;
