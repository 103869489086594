/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { OnChange } from 'react-final-form-listeners';
import { FormattedMessage, intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import { daysBetween, dateFromAPIToLocalNoon } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldSelect,
  PageSlider,
  FieldDateRangeController,
  IDVerification,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import { formatMoney } from '../../util/currency';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { ReactComponent as LightningIcon } from '../../assets/icons/lightning.svg';

import css from './BookingDatesForm.css';
import { isVerified } from '../../util/user';

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      access: { disabled: false },
      fish: { disabled: false },
      idIsVerified: false,
      partySizeSelection: 1,
      showMissingInformationReminder: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.accessFeeCheck = React.createRef();
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  async handleFormSubmit(values) {
    const { intl } = this.props;
    const { startDate, endDate } = values.bookingDates || {};
    const { onSubmit, currentUser } = this.props;

    if (!startDate || !endDate) {
      return { [FORM_ERROR]: intl.formatMessage({ id: 'BookingDatesForm.requiredDate' }) };
    }

    const startDateIsValid = startDate instanceof Date;
    const endDateIsValid = endDate instanceof Date;

    if (!startDateIsValid) {
      return { [FORM_ERROR]: intl.formatMessage({ id: 'FieldDateRangeInput.invalidStartDate' }) };
    }

    if (!endDateIsValid) {
      return { [FORM_ERROR]: intl.formatMessage({ id: 'FieldDateRangeInput.invalidEndDate' }) };
    }

    if (!currentUser) {
      onSubmit(values);
    } else {
      if (isVerified(currentUser)) {
        this.setState({
          idIsVerified: true,
        });

        onSubmit(values);

        return;
      }

      this.setState({
        idIsVerified: false,
        showMissingInformationReminder: true,
      });
    }
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  render() {
    const {
      rootClassName,
      className,
      accessFee,
      fishingFee,
      gameFees,
      partySize,
      price: unitPrice,
      onManageDisableScrolling,
      ...rest
    } = this.props;
    const { idIsVerified, showMissingInformationReminder } = this.state;

    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }

    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            form,
            handleSubmit,
            intl,
            isOwnListing,
            listing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            publicData,
            feeError,
            submitError,
          } = fieldRenderProps;

          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

          const requiredFees = intl.formatMessage({ id: 'BookingDatesForm.requiredFees' });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          const selectedAccessFee =
            values && values.additionalItems && values.additionalItems.find(i => i === 'accessFee')
              ? accessFee
              : null;

          const selectedFishingFee =
            values && values.additionalItems && values.additionalItems.find(i => i === 'fishingFee')
              ? fishingFee
              : null;

          const speciesFeeCalc = {};
          if (values && values.additionalItems) {
            gameFees.forEach(({ name, price }) => {
              if (values.additionalItems.find(item => item === name)) {
                speciesFeeCalc[name] = price;
              }
            });
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,
                  // NOTE: If unitType is `line-item/units`, a new picker
                  // for the quantity should be added to the form.
                  quantity: 1,
                  partySize: parseInt(this.state.partySizeSelection),
                  accessFee: selectedAccessFee,
                  fishingFee: selectedFishingFee,
                  listing,
                  ...speciesFeeCalc,
                }
              : null;

          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <div className={css.priceBreakdownTitle}>
                <h3>
                  <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                </h3>
                <p className={css.estimateDisclaimer}>
                  <FormattedMessage id="BookingDatesForm.estimateDisclaimer" />
                </p>
              </div>
              <EstimatedBreakdownMaybe bookingData={bookingData} listing={listing} />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          // if hunting has been selected, options were selected, then hunting was de-selected, the species should not render in listing page.
          const hasHunting =
            publicData && publicData.categories ? publicData.categories.includes('hunt') : null;
          const hasFishing =
            publicData && publicData.categories ? publicData.categories.includes('fish') : null;

          const huntClass = 'css.cleaningFee';

          const toggleFishCheck = () => {
            const { fish } = this.state;

            this.setState({
              fish: {
                disabled: !fish.disabled,
              },
            });
          };

          const gameFeesRender =
            gameFees &&
            !this.state.access.disabled &&
            gameFees
              .filter(({ isActive }) => isActive === true)
              .map(({ name, price, checkBoxLabel }) => {
                return (
                  <div className={css.cleaningFee} key={name}>
                    <FieldCheckbox
                      className={huntClass}
                      id={`${form}.${name}`}
                      label={checkBoxLabel}
                      name="additionalItems"
                      value={name}
                    />
                    <span className={css.cleaningFeeAmount}>{formatMoney(intl, price)}</span>
                  </div>
                );
              });

          const fishingFeeLabel = intl.formatMessage({
            id: 'BookingDatesForm.fishingFee',
          });
          const partySizeLabel = intl.formatMessage({
            id: 'BookingDatesForm.partySize',
          });
          const feeSelectionSubtitle = intl.formatMessage({
            id: 'BookingDatesForm.feeSelectionSubtitleShort',
          });

          const perDaySubtitle = intl.formatMessage({
            id: 'BookingDatesForm.perDayPerSportsman',
          });

          const partySizeMax = [];
          for (let i = 1; i <= partySize; i++) {
            partySizeMax.push(i);
          }

          // these variables are used to render the actual booking dates, currently only using the count to display to customer
          // the current calendar model requires customers to choose dates midnight-midnight rather than midnight-11:59pm
          const displayStart = bookingData && bookingData.startDate ? bookingData.startDate : null;
          const displayEnd = bookingData && bookingData.endDate ? bookingData.endDate : null;
          const localStartDate = displayStart ? dateFromAPIToLocalNoon(displayStart) : null;
          const localEndDateRaw = displayEnd ? dateFromAPIToLocalNoon(displayEnd) : null;
          const dayCount = daysBetween(localStartDate, localEndDateRaw);
          const dayCountMessage =
            dayCount === 0 ? (
              <div className={css.calendarProTip}>
                <div>
                  <div className={css.contentContainer}>
                    <LightIcon className={css.icon} />

                    <div>
                      <div className={css.title}>
                        <FormattedHTMLMessage id="BookingBreakdown.endDate" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <FormattedHTMLMessage id="BookingBreakdown.dayCount" values={{ dayCount }} />
            );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              {isOwnListing ? null : (
                <p className={css.bookingHelp}>
                  <FormattedMessage id="BookingDatesForm.dateSelection" />
                </p>
              )}
              <FieldDateRangeController
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                focusedInput={this.state.focusedInput}
                format={null}
                timeSlots={timeSlots}
              />

              {displayEnd ? (
                <span className={css.nowrap}>{dayCountMessage}</span>
              ) : !isOwnListing ? (
                <p className={css.smallPrintDates}>
                  <FormattedMessage id="BookingDatesForm.calendarDates" />
                </p>
              ) : null}

              <a
                href="https://landtrust.com/contact"
                className={css.calendarHelpText}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="BookingDatesForm.howToUseCalendar" />
              </a>

              {isOwnListing ? null : (
                <div className={css.bookingHelp}>
                  <FormattedMessage id="BookingDatesForm.partySizeSubtitleShort" />
                </div>
              )}

              <div>
                <FieldSelect
                  className={css.partySize}
                  id={`${form}.partySize`}
                  name="partySize"
                  value="partySize"
                >
                  <OnChange name="partySize">
                    {value => {
                      this.setState({ partySizeSelection: value });
                    }}
                  </OnChange>
                  <option className={css.partySizeLabel} disabled value="">
                    {partySizeLabel}
                  </option>
                  {partySizeMax
                    ? partySizeMax.map(c => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))
                    : null}
                </FieldSelect>
              </div>
              <div className={css.bookingHelp}>{feeSelectionSubtitle}</div>
              <div className={css.perDay}>{perDaySubtitle}</div>
              {hasFishing && fishingFee && !this.state.access.disabled ? (
                <div className={css.cleaningFee}>
                  <FieldCheckbox
                    className={css.cleaningFeeLabel}
                    id={`${form}.fishingFee`}
                    label={fishingFeeLabel}
                    name="additionalItems"
                    value="fishingFee"
                    onClick={toggleFishCheck}
                  />
                  <span className={css.cleaningFeeAmount}>{formatMoney(intl, fishingFee)}</span>
                </div>
              ) : null}
              {hasHunting ? gameFeesRender : null}

              {bookingInfo}

              {isOwnListing ? null : (
                <div className={css.requestInfo}>
                  <div>
                    <div className={css.requestContainer}>
                      <LightningIcon className={css.icon} />
                      <div>
                        <div className={css.smallPrintDark}>
                          <FormattedMessage id="BookingDatesForm.reserveToday" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" className={css.submitButton}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
                {feeError ? <div className={css.feeError}>{requiredFees}</div> : null}
                {submitError ? <div className={css.feeError}>{submitError}</div> : null}

                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
                {idIsVerified ? null : (
                  <PageSlider
                    active={showMissingInformationReminder}
                    onClose={() => {
                      this.setState({
                        showMissingInformationReminder: false,
                      });
                    }}
                    width="50%"
                  >
                    <IDVerification
                      className={css.innerIDModal}
                      onClose={() => {
                        this.setState({
                          showMissingInformationReminder: false,
                        });
                      }}
                      showMissingInformationReminder={showMissingInformationReminder}
                    />
                  </PageSlider>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const BookingDatesForm = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  injectIntl
)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
