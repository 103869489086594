import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get, cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import format from 'date-fns/format';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import {
  HUNT_ACTIVITY,
  OUTDOOR_RECREATION_ACTIVITY,
  popularGameTypes,
} from '../../marketplace-custom-config';
import PackageCardPreview from '../../components/PackageCardPreview/PackageCardPreview';
import {
  EditListingFieldWrapper,
  EditListingSection,
  EditListingSectionHeading,
  EditListingAccessPhotoLibrary,
  FieldCheckbox,
  FieldCondition,
  FieldCurrencyInput,
  FieldDates,
  FieldLabel,
  FieldNumberInput,
  FieldTextInput,
  FieldToggle,
  FieldRadioButton,
  FieldWysiwyg,
  Form,
  FormValidationTooltip,
  IconArrowHead,
  PrimaryButton,
} from '../../components';
import {
  atLeast,
  composeValidators,
  maxLengthWithCount,
  moneySubUnitAmountAtLeast,
  required,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import { getLodgingPricesOptions } from '../../util/listing';
import config from '../../config';
import css from './EditListingCreatePackageForm.css';
import FieldLodgingAvailability from './FieldLodgingAvailability';
import { formatMoney } from '../../util/currency';
import { daysAdded } from '../../util/dates';
import openingPackage from '../../assets/lottie/opening-package.json';
import { types as sdkTypes } from '../../util/sdkLoader';

import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';

const { Money } = sdkTypes;

const allHunting = config.custom.genericTabsMap.gameTypes.filter(
  ({ type }) => type === HUNT_ACTIVITY
);

const allActivities = config.custom.genericTabsMap.activitiesTypes;

const categorizeOptions = options => {
  const { popular, other } = options
    .filter(({ type }) => type === 'hunt')
    .reduce(
      (acc, item) => {
        if (popularGameTypes.indexOf(item.key) > -1) {
          acc.popular.push(item);
        } else {
          acc.other.push(item);
        }

        return acc;
      },
      {
        popular: [],
        other: [],
      }
    );

  popular.sort((a, b) => popularGameTypes.indexOf(a.key) - popularGameTypes.indexOf(b.key));

  // "Other" is the last element in the 'other' array
  other.sort((a, b) => {
    if (a.key === 'other') return 1;
    if (b.key === 'other') return -1;
    return 0;
  });

  return { popular, other };
};

const categorizedOptions = categorizeOptions(allHunting);

const ShowMore = ({ onShow, onHide, isShowing }) => {
  return (
    <button type="button" onClick={isShowing ? onHide : onShow} className={css.showMore}>
      <FormattedMessage
        id={
          isShowing
            ? 'EditListingSpeciesForm.showLessSpecies'
            : 'EditListingSpeciesForm.showMoreSpecies'
        }
      />

      <IconArrowHead
        direction="up"
        rootClassName={classNames(css.arrowIcon, { [css.arrowIconDown]: isShowing })}
      />
    </button>
  );
};

const EditListingCreatePackageForm = ({
  onClose,
  intl,
  onUpdatePackage,
  active,
  currentListing,
  titlePlaceholder,
  descriptionPlaceholder,
  activity,
  isOpen,
  requestImageUpload,
  ...formProps
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [showOtherSpecies, setShowOtherSpecies] = useState(false);
  const [packageImagesIds, setPackageImagesIds] = useState([]);
  const imageCaptions = get(currentListing, 'attributes.publicData.imageCaptions', {});

  useEffect(() => {
    if (isOpen) {
      setPackageImagesIds(formProps.initialValues.images);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const priceRequired = required(
    intl.formatMessage({
      id: 'EditListingCreatePackageForm.priceRequired',
    })
  );
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const maxPrice = new Money(config.listingPackageMaximumPrice, config.currency);

  const minPriceRequired = moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingCreatePackageForm.priceTooLow',
      },
      {
        price: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );

  const maxPriceValidator = price => {
    const priceMoney = new Money(price, config.currency);
    if (priceMoney.amount.amount > maxPrice.amount) {
      return intl.formatMessage({ id: 'EditListingCreatePackageForm.priceTooHigh' });
    }
    return undefined;
  };

  const priceValidators = config.listingMinimumPriceSubUnits
    ? composeValidators(priceRequired, minPriceRequired, maxPriceValidator)
    : priceRequired;

  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);

  const validateLodgingPrices = value => {
    return !value ||
      !Array.isArray(value) ||
      lodgingPricesOptions?.some((option, index) => value[index] === undefined)
      ? 'Select all lodging options'
      : undefined;
  };

  const handleFormSubmit = async values => {
    try {
      setIsCreating(true);

      // Extend lodging prices
      const lodgingPrices = lodgingPricesOptions.map((option, index) => {
        return {
          type: option.type,
          index: option.index,
          option: values.lodgingPrices[index],
        };
      });

      // Extend the animation by at least 1 sec
      await onUpdatePackage({
        ...values,
        images: packageImagesIds,
        lodgingPrices,
      });

      setIsCreating(false);
      onClose();
    } catch (error) {
      setFormSubmitError(
        intl.formatMessage({
          id: 'EditListingCreatePackageForm.submitError',
        })
      );
    }
  };

  const overlayClasses = classNames(css.overlay, {
    [css.overlayActive]: active,
  });

  const contentClasses = classNames(css.content, {
    [css.contentActive]: active,
  });

  let heading;

  switch (activity) {
    case HUNT_ACTIVITY:
      heading = <FormattedMessage id="EditListingCreatePackageForm.createHuntingPackageHead" />;
      break;

    case OUTDOOR_RECREATION_ACTIVITY:
      heading = (
        <FormattedMessage id="EditListingCreatePackageForm.createOutdoorRecreationPackageHead" />
      );
      break;

    default:
  }

  const farmRanchPackageDetails = (
    <>
      <div className={css.fieldHeader}>
        <FormattedMessage id="EditListingCreatePackageForm.packageDetailsSectionHeader" />
      </div>

      <EditListingSection
        hasBackground
        title={intl.formatMessage({
          id: 'EditListingFarmExperienceForm.interactiveExperienceLabel',
        })}
        hasBottomMargin
        isFullWidth
      >
        <EditListingFieldWrapper className={css.radioWrapper}>
          <FieldRadioButton
            name="interactiveExperience"
            id="interactiveExperienceMinimal"
            value="Minimal"
            label={intl.formatMessage({
              id: 'EditListingFarmExperienceForm.interactiveExperienceMinimal',
            })}
          />

          <FieldRadioButton
            name="interactiveExperience"
            id="interactiveExperienceModerate"
            value="Moderate"
            label={intl.formatMessage({
              id: 'EditListingFarmExperienceForm.interactiveExperienceModerate',
            })}
          />

          <FieldRadioButton
            name="interactiveExperience"
            id="interactiveExperienceHighly"
            value="Highly"
            label={intl.formatMessage({
              id: 'EditListingFarmExperienceForm.interactiveExperienceHighly',
            })}
          />

          <FieldRadioButton
            name="interactiveExperience"
            id="interactiveExperienceNA"
            value="N/A"
            label={intl.formatMessage({
              id: 'EditListingFarmExperienceForm.interactiveExperienceNA',
            })}
          />
        </EditListingFieldWrapper>

        <FieldCondition when="interactiveExperience" operator="ne" is="N/A">
          <EditListingFieldWrapper>
            <FieldTextInput
              id="interactiveExperienceInfo"
              name="interactiveExperienceInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingFarmExperienceForm.interactiveExperienceInfo',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        hasBackground
        title={intl.formatMessage({
          id: 'EditListingFarmExperienceForm.kidsFriendlyLabel',
        })}
        hasBottomMargin
        isFullWidth
      >
        <EditListingFieldWrapper>
          <FieldToggle name="hasKidsFriendlyExperience" />
        </EditListingFieldWrapper>
        <FieldCondition when="hasKidsFriendlyExperience" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="kidsFriendlyInfo"
              name="kidsFriendlyInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingFarmExperienceForm.kidsFriendlyInfo',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>
    </>
  );

  return (
    <div className={overlayClasses}>
      <div className={contentClasses}>
        {isCreating ? (
          <div className={css.loadingWrapper}>
            <Player
              autoplay
              loop
              src={openingPackage}
              style={{
                height: '290px',
              }}
            />

            <div className={css.loadingTitleWrapper}>
              <div className={css.loadingTitle}>
                {formProps.initialValues.id ? (
                  <FormattedMessage id="EditListingCreatePackageForm.updatingPackage" />
                ) : (
                  <FormattedMessage id="EditListingCreatePackageForm.creatingPackage" />
                )}
              </div>

              <div className={css.loadingSubTitle}>
                <FormattedMessage id="EditListingCreatePackageForm.loadingSubtitle" />
              </div>
            </div>
          </div>
        ) : (
          <div className={css.mainWrapper}>
            {active && (
              <FinalForm
                {...formProps}
                initialValues={{
                  ...formProps.initialValues,
                  imageCaptions,
                }}
                onSubmit={handleFormSubmit}
                keepDirtyOnReinitialize
                render={fieldRenderProps => {
                  const {
                    rootClassName,
                    className,
                    handleSubmit,
                    updated,
                    pristine,
                    updateInProgress,
                    invalid,
                    disabled,
                    values,
                    form,
                    errors,
                  } = fieldRenderProps;

                  const classes = classNames(rootClassName || css.root, className);
                  const submitReady = updated && pristine;
                  const submitInProgress = updateInProgress;
                  const submitDisabled = invalid || disabled || submitInProgress;

                  const { popular: popularSpecies } = categorizedOptions;
                  const species = values.species || [];

                  const subspecies = popularSpecies.filter(item => {
                    const filteredSpecies = species.find(
                      key => key === item.key && item.children.length
                    );

                    return !!filteredSpecies;
                  });

                  let bookingDateText = (
                    <FormattedMessage id="EditListingCreatePackageForm.datesFieldPlaceholderPackage" />
                  );
                  const dateValues = values && values.dates;

                  const isSingleDay =
                    daysAdded(
                      dateValues && values.dates.startDate,
                      dateValues && values.dates.endDate
                    ) === 1;

                  if (dateValues) {
                    bookingDateText = `${format(values.dates.startDate, 'MMM DD')} - ${format(
                      values.dates.endDate,
                      'MMM DD, YYYY'
                    )}`;
                  }
                  if (dateValues && isSingleDay) {
                    bookingDateText = format(
                      values && values.dates && values.dates.startDate,
                      'MMM DD'
                    );
                  }

                  return (
                    <>
                      <Form className={classes} onSubmit={handleSubmit}>
                        <div className={css.columnWrapper}>
                          <div className={css.formColumn}>
                            <h2 className={css.header}>{heading}</h2>

                            <p className={css.subheader}>
                              <FormattedMessage id="EditListingCreatePackageForm.subheading" />
                            </p>

                            <EditListingSection isFullWidth rootClassName={css.formWrapper}>
                              {formSubmitError && (
                                <p className={css.formError}>{formSubmitError}</p>
                              )}

                              <div>
                                <button type="button" className={css.buttonClose} onClick={onClose}>
                                  <CloseIcon />
                                </button>
                              </div>

                              <EditListingFieldWrapper>
                                <FieldTextInput
                                  autoComplete="off"
                                  id="title"
                                  name="title"
                                  type="text"
                                  label={intl.formatMessage({
                                    id: 'EditListingCreatePackageForm.titleFieldLabel',
                                  })}
                                  validate={composeValidators(
                                    required(
                                      intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.titleFieldRequired',
                                      })
                                    ),
                                    maxLengthWithCount(
                                      64,
                                      intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.titleFieldMaxLength',
                                      })
                                    )
                                  )}
                                  placeholder={titlePlaceholder}
                                  charLimit={64}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldWysiwyg
                                  id="description"
                                  name="description"
                                  label={intl.formatMessage({
                                    id: 'EditListingCreatePackageForm.descriptionFieldLabel',
                                  })}
                                  validate={composeValidators(
                                    required(
                                      intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.descriptionFieldRequired',
                                      })
                                    )
                                  )}
                                  placeholder={descriptionPlaceholder}
                                  help={
                                    <FormattedHTMLMessage id="EditListingCreatePackageForm.descriptionFieldHelp" />
                                  }
                                />
                              </EditListingFieldWrapper>

                              {activity === OUTDOOR_RECREATION_ACTIVITY && (
                                <>
                                  <EditListingFieldWrapper>
                                    <EditListingSection>
                                      <EditListingSectionHeading>
                                        <FormattedMessage id="EditListingCreatePackageForm.activitiesFieldLabel" />
                                      </EditListingSectionHeading>
                                    </EditListingSection>

                                    <p className={css.subheader}>
                                      <FormattedHTMLMessage id="EditListingCreatePackageForm.activitiesFieldHelp" />
                                    </p>

                                    <div className={css.activitiesListWrapper}>
                                      {allActivities.map(activityItem => {
                                        const fieldId = `${activityItem.key}`;

                                        return (
                                          <div>
                                            <FieldCheckbox
                                              id={fieldId}
                                              key={fieldId}
                                              name="activities"
                                              value={fieldId}
                                              label={activityItem.label}
                                              labelClassName={classNames(
                                                css.activityFieldCheckboxLabel,
                                                {
                                                  [css.activityFieldCheckboxLabelSelected]: values?.activities?.includes(
                                                    fieldId
                                                  ),
                                                }
                                              )}
                                              validate={composeValidators(
                                                requiredFieldArrayCheckbox(
                                                  intl.formatMessage({
                                                    id:
                                                      'EditListingCreatePackageForm.activitiesRequired',
                                                  })
                                                )
                                              )}
                                            />

                                            {values?.activities?.includes(fieldId) && (
                                              <EditListingFieldWrapper>
                                                <FieldTextInput
                                                  id={`${fieldId}-info`}
                                                  name={`activitiesInfo[${fieldId}]`}
                                                  type="textarea"
                                                  placeholder={intl.formatMessage({
                                                    id:
                                                      'EditListingCreatePackageForm.activitiesFieldInfo',
                                                  })}
                                                  inputRootClass={css.activityFieldInput}
                                                />
                                              </EditListingFieldWrapper>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </EditListingFieldWrapper>
                                </>
                              )}

                              <EditListingAccessPhotoLibrary
                                form={form}
                                currentListing={currentListing}
                                imagesIds={packageImagesIds}
                                title={values.title}
                                onUpdateImagesIds={setPackageImagesIds}
                                requestImageUpload={requestImageUpload}
                                fieldHeader={
                                  <EditListingSectionHeading
                                    description={
                                      activity === OUTDOOR_RECREATION_ACTIVITY
                                        ? intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.photoOutdoorSectionHelp',
                                          })
                                        : intl.formatMessage({
                                            id: 'EditListingCreatePackageForm.photoSectionHelp',
                                          })
                                    }
                                  >
                                    <FormattedMessage id="EditListingCreatePackageForm.photoSectionHeader" />
                                  </EditListingSectionHeading>
                                }
                              />

                              {values?.activities?.includes('farm_tours') &&
                                farmRanchPackageDetails}

                              {lodgingPricesOptions.length > 0 && (
                                <FieldLodgingAvailability
                                  name="lodgingPrices"
                                  options={lodgingPricesOptions}
                                  validate={value => validateLodgingPrices(value)}
                                />
                              )}

                              <div className={css.fieldHeader}>
                                <FormattedMessage id="EditListingCreatePackageForm.daysFieldHeader" />
                              </div>

                              <EditListingSection
                                hasBackground
                                isFullWidth
                                rootClassName={css.listingSectionRoot}
                              >
                                <EditListingFieldWrapper>
                                  <FieldLabel>
                                    <FormattedMessage id="EditListingCreatePackageForm.daysFieldLabel" />
                                  </FieldLabel>
                                  <div className={css.fieldGuestsWrapper}>
                                    <FieldNumberInput
                                      className={css.fieldGuests}
                                      asRow
                                      min={1}
                                      name="days.min"
                                      id="daysMin"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.daysFieldLabelMin',
                                      })}
                                      validate={composeValidators(
                                        required(
                                          intl.formatMessage({
                                            id: 'EditListingCreatePackageForm.daysFieldRequired',
                                          })
                                        ),
                                        atLeast(
                                          intl.formatMessage({
                                            id: 'EditListingCreatePackageForm.daysFieldAtLeast',
                                          }),
                                          1
                                        )
                                      )}
                                      onChange={value => {
                                        form.change('days.min', value);

                                        // Make sure the min is not bigger than max
                                        if (values.days.max < value) {
                                          form.change('days.max', value);
                                        }
                                      }}
                                    />

                                    <FieldNumberInput
                                      className={css.fieldGuests}
                                      asRow
                                      min={1}
                                      max={365}
                                      name="days.max"
                                      id="daysMax"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.daysFieldLabelMax',
                                      })}
                                      validate={composeValidators(
                                        required(
                                          intl.formatMessage({
                                            id: 'EditListingCreatePackageForm.daysFieldRequired',
                                          })
                                        ),
                                        atLeast(
                                          intl.formatMessage({
                                            id: 'EditListingCreatePackageForm.daysFieldAtLeast',
                                          }),
                                          1
                                        )
                                      )}
                                      onChange={value => {
                                        form.change('days.max', value);

                                        // Make sure the min is not bigger than max
                                        if (values.days.min > value) {
                                          form.change('days.min', value);
                                        }
                                      }}
                                    />
                                  </div>
                                </EditListingFieldWrapper>
                              </EditListingSection>
                              <EditListingSection
                                hasBackground
                                hasTopMargin
                                hasBottomMargin
                                isFullWidth
                                title={intl.formatMessage({
                                  id: 'EditListingCreatePackageForm.quantityDatesFieldQuestion',
                                })}
                              >
                                <span>
                                  <FormattedMessage id="EditListingCreatePackageForm.quantitySubLabel" />
                                </span>
                                <EditListingFieldWrapper>
                                  <FieldToggle name="methods.showDatesField" />
                                  <OnChange name="methods.showDatesField">
                                    {value => {
                                      if (!value) {
                                        form.change('dates', null);
                                      }
                                    }}
                                  </OnChange>
                                </EditListingFieldWrapper>

                                <FieldCondition when="methods.showDatesField" is>
                                  <EditListingSection
                                    isFullWidth
                                    rootClassName={css.listingSectionRoot}
                                    className={css.datesFieldSection}
                                  >
                                    <EditListingFieldWrapper>
                                      <FieldDates
                                        fieldDateProps={{}}
                                        label={
                                          <FormattedMessage id="EditListingCreatePackageForm.quantityDatesFieldLabel" />
                                        }
                                        text={bookingDateText}
                                        onChange={dates => {
                                          form.change('dates', dates);
                                        }}
                                        onReset={() => {
                                          form.change('dates', null);
                                        }}
                                      />
                                    </EditListingFieldWrapper>
                                  </EditListingSection>
                                </FieldCondition>
                              </EditListingSection>

                              <EditListingSection
                                title={intl.formatMessage({
                                  id: 'EditListingCreatePackageForm.quantityLabel',
                                })}
                                hasBottomMargin
                                hasBackground
                                isFullWidth
                                rootClassName={css.listingSectionRoot}
                              >
                                <span>
                                  <FormattedMessage id="EditListingCreatePackageForm.quantitySubLabel" />
                                </span>

                                <EditListingFieldWrapper className={css.radioWrapper}>
                                  <FieldToggle name="hasQuantityRestricted" />
                                </EditListingFieldWrapper>
                                <FieldCondition
                                  when="hasQuantityRestricted"
                                  operator="ne"
                                  is={false}
                                >
                                  <EditListingFieldWrapper>
                                    <FieldNumberInput
                                      className={css.fieldGuests}
                                      min={1}
                                      name="quantity.total"
                                      id="quantity"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.quantityFieldLabel',
                                      })}
                                      validate={composeValidators(
                                        required(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.quantityFieldRequired',
                                          })
                                        ),
                                        atLeast(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.quantityMinFieldAtLeast',
                                          }),
                                          1
                                        )
                                      )}
                                    />
                                  </EditListingFieldWrapper>
                                </FieldCondition>
                              </EditListingSection>

                              <div className={css.fieldHeader}>
                                <FormattedMessage id="EditListingCreatePackageForm.priceFieldHeader" />
                              </div>

                              <EditListingSection
                                hasBackground
                                isFullWidth
                                hasBottomMargin
                                rootClassName={css.listingSectionRoot}
                              >
                                <EditListingFieldWrapper>
                                  <FieldCurrencyInput
                                    className={css.fieldInputPrice}
                                    id="price"
                                    name="price"
                                    label={intl.formatMessage({
                                      id: 'EditListingCreatePackageForm.priceFieldLabel',
                                    })}
                                    postfixLabel={intl.formatMessage({
                                      id: 'EditListingCreatePackageForm.priceFieldPostfix',
                                    })}
                                    placeholder="$0.00"
                                    currencyConfig={config.currencyConfig}
                                    validate={priceValidators}
                                  />
                                </EditListingFieldWrapper>
                                <EditListingFieldWrapper>
                                  <FieldLabel>
                                    <FormattedMessage id="EditListingCreatePackageForm.guestsFieldLabel" />
                                  </FieldLabel>

                                  <div className={css.fieldGuestsWrapper}>
                                    <FieldNumberInput
                                      className={css.fieldGuests}
                                      asRow
                                      min={1}
                                      name="guests.min"
                                      id="guestsMin"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.guestsMinFieldLabel',
                                      })}
                                      validate={composeValidators(
                                        required(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.guestsMinFieldRequired',
                                          })
                                        ),
                                        atLeast(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.guestsMinFieldAtLeast',
                                          }),
                                          1
                                        )
                                      )}
                                      onChange={value => {
                                        form.change('guests.min', value);

                                        // Make sure the min is not bigger than max
                                        if (values.guests.max < value) {
                                          form.change('guests.max', value);
                                        }
                                      }}
                                    />

                                    <FieldNumberInput
                                      className={css.fieldGuests}
                                      asRow
                                      min={1}
                                      name="guests.max"
                                      id="guestsMax"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.guestsMaxFieldLabel',
                                      })}
                                      validate={composeValidators(
                                        required(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.guestsMaxFieldRequired',
                                          })
                                        ),
                                        atLeast(
                                          intl.formatMessage({
                                            id:
                                              'EditListingCreatePackageForm.guestsMaxFieldAtLeast',
                                          }),
                                          1
                                        )
                                      )}
                                      onChange={value => {
                                        form.change('guests.max', value);

                                        // Make sure the min is not bigger than max
                                        if (values.guests.min > value) {
                                          form.change('guests.min', value);
                                        }
                                      }}
                                    />
                                  </div>
                                </EditListingFieldWrapper>
                              </EditListingSection>
                              {activity === HUNT_ACTIVITY && (
                                <>
                                  <EditListingSection>
                                    <EditListingSectionHeading>
                                      <FormattedMessage id="EditListingHuntExperienceForm.methodsLabel" />
                                    </EditListingSectionHeading>
                                  </EditListingSection>
                                  <EditListingSection
                                    hasBackground
                                    hasBottomMargin
                                    isFullWidth
                                    title={intl.formatMessage({
                                      id: 'EditListingHuntExperienceForm.allowsFirearmsLabel',
                                    })}
                                  >
                                    <EditListingFieldWrapper>
                                      <FieldToggle name="methods.allowsFirearms" />
                                    </EditListingFieldWrapper>

                                    <FieldCondition when="methods.allowsFirearms" is>
                                      <EditListingFieldWrapper>
                                        <FieldTextInput
                                          id="firearmsInfo"
                                          name="methods.firearmsInfo"
                                          type="textarea"
                                          label={intl.formatMessage({
                                            id: 'EditListingHuntExperienceForm.additionalInfoLabel',
                                          })}
                                        />
                                      </EditListingFieldWrapper>
                                    </FieldCondition>
                                  </EditListingSection>

                                  <EditListingSection
                                    hasBackground
                                    hasBottomMargin
                                    isFullWidth
                                    title={intl.formatMessage({
                                      id: 'EditListingHuntExperienceForm.allowsArcheryLabel',
                                    })}
                                  >
                                    <EditListingFieldWrapper>
                                      <FieldToggle name="methods.allowsArchery" />
                                    </EditListingFieldWrapper>

                                    <FieldCondition when="methods.allowsArchery" is>
                                      <EditListingFieldWrapper>
                                        <FieldTextInput
                                          id="archeryInfo"
                                          name="methods.archeryInfo"
                                          type="textarea"
                                          label={intl.formatMessage({
                                            id: 'EditListingHuntExperienceForm.additionalInfoLabel',
                                          })}
                                        />
                                      </EditListingFieldWrapper>
                                    </FieldCondition>
                                  </EditListingSection>

                                  <EditListingFieldWrapper>
                                    <EditListingSection>
                                      <EditListingSectionHeading>
                                        <FormattedMessage id="EditListingCreatePackageForm.speciesFieldLabel" />
                                      </EditListingSectionHeading>
                                    </EditListingSection>

                                    <p className={css.subheader}>
                                      <FormattedHTMLMessage id="EditListingCreatePackageForm.speciesFieldHelp" />
                                    </p>

                                    <div className={css.speciesListWrapper}>
                                      {categorizedOptions.popular.map(popularOption => {
                                        const fieldId = `${popularOption.key}`;

                                        return (
                                          <FieldCheckbox
                                            id={fieldId}
                                            key={fieldId}
                                            name="species"
                                            value={fieldId}
                                            label={popularOption.label}
                                            labelClassName={css.fieldCheckboxLabel}
                                            onChange={e => {
                                              const {
                                                species: currentSpecies = [],
                                                subSpecies: currentSubSpecies = {},
                                              } = values;
                                              const { value, checked: isSpeciesChecked } = e.target;

                                              let updatedSpecies = [...currentSpecies];
                                              const updatedSubSpecies = cloneDeep(
                                                currentSubSpecies
                                              );

                                              if (isSpeciesChecked) {
                                                updatedSpecies.push(value);
                                              } else {
                                                updatedSpecies = updatedSpecies.filter(item => {
                                                  return item !== value;
                                                });

                                                // Remove subspecies when parent species is unchecked
                                                if (updatedSubSpecies && updatedSubSpecies[value]) {
                                                  delete updatedSubSpecies[value];
                                                  form.change('subSpecies', updatedSubSpecies);
                                                }
                                              }

                                              form.change('species', updatedSpecies);
                                            }}
                                            validate={composeValidators(
                                              requiredFieldArrayCheckbox(
                                                intl.formatMessage({
                                                  id:
                                                    'EditListingCreatePackageForm.speciesRequired',
                                                })
                                              )
                                            )}
                                          />
                                        );
                                      })}
                                    </div>

                                    {subspecies.map(item => (
                                      <div
                                        key={`subspecies-${item.key}`}
                                        className={css.subspeciesListContainer}
                                      >
                                        <FieldLabel>
                                          <FormattedMessage
                                            id="EditListingCreatePackageForm.subspeciesFieldLabel"
                                            values={{
                                              species: item.key,
                                            }}
                                          />
                                        </FieldLabel>

                                        <div
                                          key={`subSpecies-${item.key}`}
                                          className={css.subspeciesListWrapper}
                                        >
                                          {item.children.map(child => {
                                            const fieldId = `${child.key}`;

                                            return (
                                              <FieldCheckbox
                                                id={fieldId}
                                                key={fieldId}
                                                name={`subSpecies[${item.key}]`}
                                                value={fieldId}
                                                label={child.label}
                                                labelClassName={css.fieldCheckboxLabel}
                                              />
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ))}

                                    <ShowMore
                                      isShowing={showOtherSpecies}
                                      onShow={() => setShowOtherSpecies(true)}
                                      onHide={() => setShowOtherSpecies(false)}
                                    />
                                    {showOtherSpecies && (
                                      <div className={css.speciesListWrapper}>
                                        {categorizedOptions.other.map(popularOption => {
                                          const fieldId = `${popularOption.key}`;

                                          return (
                                            <FieldCheckbox
                                              id={fieldId}
                                              key={fieldId}
                                              name="species"
                                              value={fieldId}
                                              label={popularOption.label}
                                              labelClassName={css.fieldCheckboxLabel}
                                            />
                                          );
                                        })}
                                      </div>
                                    )}
                                  </EditListingFieldWrapper>

                                  <EditListingFieldWrapper>
                                    <FieldTextInput
                                      autoComplete="off"
                                      id="species_unit"
                                      name="species_unit"
                                      type="text"
                                      label={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.speciesUnitLabel',
                                      })}
                                      placeholder={intl.formatMessage({
                                        id: 'EditListingCreatePackageForm.speciesUnitPlaceholder',
                                      })}
                                    />
                                  </EditListingFieldWrapper>
                                </>
                              )}
                            </EditListingSection>
                          </div>

                          <div className={css.previewColumn}>
                            <div className={css.previewColumnWrapper}>
                              <div className={css.fieldHeader}>
                                <FormattedMessage id="EditListingCreatePackageForm.packagePreviewHeader" />
                              </div>

                              <div className={css.fieldSubHeader}>
                                <FormattedMessage id="EditListingCreatePackageForm.packagePreviewSubHeader" />
                              </div>

                              <PackageCardPreview
                                item={values}
                                previewMode
                                className={css.packagePreview}
                                currentListing={currentListing}
                              />
                            </div>
                          </div>
                        </div>

                        <EditListingFieldWrapper className={css.submitButtonWrapper}>
                          {Object.keys(errors).length > 0 ? (
                            <FormValidationTooltip
                              placement="top"
                              body={
                                <ul>
                                  {Object.keys(errors).map(field => {
                                    if (field === 'lodgingPrices') {
                                      return (
                                        <li key={`tooltip-field-${field}`}>
                                          {errors[field]['FINAL_FORM/array-error']}
                                        </li>
                                      );
                                    }

                                    if (field === 'quantity') {
                                      return (
                                        <li key={`tooltip-field-${field}`}>
                                          {errors[field].total}
                                        </li>
                                      );
                                    }
                                    return <li key={`tooltip-field-${field}`}>{errors[field]}</li>;
                                  })}
                                </ul>
                              }
                              title={
                                <FormattedMessage id="EditListingCreatePackageForm.validationTooltipTitle" />
                              }
                            >
                              <PrimaryButton type="button" disabled isFullWidth>
                                {values.id ? (
                                  <FormattedMessage id="EditListingCreatePackageForm.updatePackage" />
                                ) : (
                                  <FormattedMessage id="EditListingCreatePackageForm.createPackage" />
                                )}
                              </PrimaryButton>
                            </FormValidationTooltip>
                          ) : (
                            <PrimaryButton
                              isFullWidth
                              type="submit"
                              inProgress={submitInProgress}
                              disabled={submitDisabled}
                              ready={submitReady}
                            >
                              {values.id ? (
                                <FormattedMessage id="EditListingCreatePackageForm.updatePackage" />
                              ) : (
                                <FormattedMessage id="EditListingCreatePackageForm.createPackage" />
                              )}
                            </PrimaryButton>
                          )}
                        </EditListingFieldWrapper>
                      </Form>
                    </>
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EditListingCreatePackageForm.defaultProps = {
  onCreate: null,
  onClose: null,
  active: false,
};

EditListingCreatePackageForm.propTypes = {
  intl: intlShape.isRequired,
  onCreate: func,
  onClose: func,
  onUpdatePackage: func.isRequired,
  active: bool,
  titlePlaceholder: string.isRequired,
  descriptionPlaceholder: string.isRequired,
  activity: string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingCreatePackageForm));
