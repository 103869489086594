import { speciesSearchGroups } from '../marketplace-custom-config';

export const findGameTypeGroupKeysBySearch = gameTypeSearch => {
  if (!gameTypeSearch) return null;

  // Get rid of the search type (like `has_any:blah,blah`) then split
  const searchGameTypes = gameTypeSearch.replace(/^.*:/, '').split(',');

  // Find all matching groups
  const matchingGameTypeGroupKeys = Object.keys(speciesSearchGroups.hunt).filter(searchGroupKey =>
    speciesSearchGroups.hunt[searchGroupKey].searchSpecies.every(gameType =>
      searchGameTypes.includes(gameType)
    )
  );

  return matchingGameTypeGroupKeys || null;
};

export const generateSearchFromGameTypeGroupKeys = gameTypeKeys => {
  if (!gameTypeKeys || gameTypeKeys.length === 0) return null;

  const gameTypes = Object.keys(speciesSearchGroups.hunt)
    .filter(searchGroupKey => gameTypeKeys.includes(searchGroupKey))
    .map(searchGroupKey => speciesSearchGroups.hunt[searchGroupKey].searchSpecies)
    .reduce((a, b) => [...a, ...b], []);

  return gameTypes && gameTypes.length > 0 ? `has_any:${gameTypes.join(',')}` : null;
};

export const findCategoryKeys = category => {
  if (!category) return null;

  const searchCategory = category.replace(/^.*:/, '').split(',');

  return searchCategory || null;
};
