/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, QuaternaryButton, FormValidationTooltip, PrimaryButton } from '..';
import InquiryAccountDetails from './InquiryAccountDetails';

import css from './InquirySignupForm.css';

const InquirySignupFormComponent = props => {
  const { affiliate } = useParams();
  const { inquiryButton } = props;

  let referralMethodFromURL = '';

  if (affiliate) {
    referralMethodFromURL = 'affiliate';
  } else if (typeof window !== 'undefined') {
    referralMethodFromURL = new URLSearchParams(window.location.search).get('referralMethod');
  } else {
    referralMethodFromURL = new URLSearchParams().get('referralMethod');
  }

  const initialValues = {
    fname: null,
    lname: null,
    phoneNumber: null,
    email: null,
    password: null,
    role: null,
    preferredHuntingSpecies: [],
    preferredHuntingStates: [],
    interestedActivities: [],
    usState: null,
    referralMethod: referralMethodFromURL || null,
    referralOther: null,
    smsConsent: true,
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSubmit = async values => {
    const { onSubmit, onHideTabs, stateFromInquiry } = props;

    // Make sure we scroll the page on top for mobile users especially
    window.scrollTo(0, 0);

    const token = await executeRecaptcha('register');

    const updatedValues = {
      ...values,
      role: ['sportsman'],
      usState: stateFromInquiry,
      token,
    };

    await onSubmit(updatedValues);

    onHideTabs();
  };

  return (
    <FinalForm
      keepDirtyOnReinitialize
      initialValues={initialValues}
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          invalid,
          errors,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <InquiryAccountDetails {...fieldRenderProps} />
            <div className={css.bottomWrapper}>
              {Object.keys(errors).length > 0 ? (
                <FormValidationTooltip
                  placement="top"
                  body={
                    <ul>
                      {Object.keys(errors).map(field => {
                        if (field === 'preferredHuntingStates') {
                          return (
                            <li key={`tooltip-field-${field}`}>
                              {errors[field]['FINAL_FORM/array-error']}
                            </li>
                          );
                        }

                        return <li key={`tooltip-field-${field}`}>{errors[field]}</li>;
                      })}
                    </ul>
                  }
                  title={<FormattedMessage id="SignupForm.validationTooltipTitle" />}
                >
                  <>
                    {inquiryButton ? (
                      <PrimaryButton
                        dataTestId="send-inquiry-button"
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        isFullWidth
                      >
                        <FormattedMessage id="InquiryWizard.sendInquiry" />
                      </PrimaryButton>
                    ) : (
                      <QuaternaryButton
                        data-testId="signup-button-disabled"
                        type="button"
                        disabled
                        isFullWidth
                      >
                        <FormattedMessage id="InquirySignupPage.buttonText" />
                      </QuaternaryButton>
                    )}
                  </>
                </FormValidationTooltip>
              ) : (
                <>
                  {inquiryButton ? (
                    <PrimaryButton
                      dataTestId="send-inquiry-button"
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      isFullWidth
                    >
                      <FormattedMessage id="InquiryWizard.sendInquiry" />
                    </PrimaryButton>
                  ) : (
                    <QuaternaryButton
                      data-testId="signup-button-enabled"
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      isFullWidth
                    >
                      <FormattedMessage id="InquirySignupPage.buttonText" />
                    </QuaternaryButton>
                  )}
                </>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

InquirySignupFormComponent.defaultProps = {
  inProgress: false,
  fromLTPlusPage: false,
  inquiryButton: false,
};

const { bool } = PropTypes;

InquirySignupFormComponent.propTypes = {
  inProgress: bool,
  fromLTPlusPage: bool,
  inquiryButton: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupWithRecaptcha = props => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <InquirySignupFormComponent {...props} />
    </GoogleReCaptchaProvider>
  );
};

const InquirySignupForm = compose(injectIntl)(SignupWithRecaptcha);
InquirySignupForm.displayName = 'SignupForm';

export default InquirySignupForm;
