/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
export const share = (href, callback) => {
  if (typeof window !== 'undefined' && window.FB) {
    window.FB.ui(
      {
        method: 'share',
        href,
      },
      function() {
        if (callback) {
          callback();
        }
      }
    );
  }
};
