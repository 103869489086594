/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import getCroppedImg from '../../util/crop';

import { ensureUser, ensureCurrentUser } from '../../util/data';

import css from './ImageCropper.css';

const ImageCropper = ({ user, onSaveCroppedImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const useStyles = makeStyles({
    thumb: {
      height: '15px',
      width: '15px',
    },
    track: {
      height: '7.5px',
    },
    rail: {
      height: '7.5px',
    },
  });

  const classes = useStyles();

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const getImageSrcFromVariants = useCallback(imageVariants => {
    const variant = imageVariants.default;
    return variant ? variant.url : null;
  }, []);

  useEffect(() => {
    if (avatarUser.profileImage && avatarUser.profileImage.attributes) {
      const src = getImageSrcFromVariants(avatarUser.profileImage.attributes.variants);
      setImageSrc(src);
    }
  }, [avatarUser.profileImage]);

  const handleSaveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onSaveCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div className={css.modalWrapper}>
      <div className={css.app}>
        <div className={css.cropContainer}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            zoomSpeed={4}
            zoomWithScroll
            aspect={1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </div>
      <div className={css.controls}>
        <div className={css.sliderContainer}>
          <div className={css.sliderLabel}>
            <FormattedMessage id="ProfilePage.ImageCropperZoom" />
          </div>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{
              thumb: classes.thumb,
              track: classes.track,
              rail: classes.rail,
            }}
            className={css.slider}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
      </div>
      <div className={css.modalButtonContainer}>
        <div />
        <div className={css.saveButtonContainer}>
          <button
            type="button"
            className={css.savePhotoButtonText}
            onClick={handleSaveCroppedImage}
          >
            <FormattedMessage id="ProfilePage.savePhotoButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
