import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import css from './InquiryTopics.css';

const InquiryTopics = () => {
  return (
    <div>
      <div className={css.topicsHeaderWrapper}>
        <LightIcon />

        <div className={css.topicsHeader}>
          <FormattedMessage id="InquiryWizard.topicsToDiscuss" />
        </div>
      </div>

      <ul className={css.topicsList}>
        <li className={css.topicsItem}>
          <FormattedMessage id="InquiryWizard.topicsFirst" />
        </li>
        <li className={css.topicsItem}>
          <FormattedMessage id="InquiryWizard.topicsSecond" />
        </li>
        <li className={css.topicsItem}>
          <FormattedMessage id="InquiryWizard.topicsThird" />
        </li>
      </ul>
    </div>
  );
};

export default InquiryTopics;
