import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl } = props;

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  // Keeping the Base Price feature for future updates, but not rendering
  return formattedUnitPrice ? (
    <div className={css.lineItem}>
      {/* <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.pricePerNight" />
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span> */}
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
