import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HUNT_ACTIVITY } from '../../marketplace-custom-config';
import { ReactComponent as IconLodging } from '../../assets/icons/ListingPage/icon-lodge-filled.svg';
import { ReactComponent as IconAcreage } from '../../assets/icons/ListingPage/icon-grid-acres.svg';
import { ReactComponent as IconTown } from '../../assets/icons/ListingPage/icon-pin-location.svg';
import css from './SectionKeyListingInfo.css';

const SectionKeyListingInfo = props => {
  const { nearestTown, hasLodging, acres, gameUnit, currentActivity } = props;
  const shortTown = nearestTown && nearestTown.replace(/, United States/g, '');
  const isHunting = currentActivity === HUNT_ACTIVITY;

  return (
    <div className={css.keyListingInfoContainer}>
      <div className={css.keyInfoFirstRow}>
        {nearestTown && (
          <div className={css.keyInfoItem}>
            <div className={css.iconWrapper}>
              <IconTown className={css.keyInfoIcon} />
            </div>
            <div className={css.keyInfoTextContainer}>
              <div>
                <FormattedMessage
                  className={css.descriptorTitle}
                  id="ListingPage.descriptionNearestTown"
                  values={{ town: shortTown }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {acres && (
        <div className={css.keyInfoItem}>
          <div className={css.iconWrapper}>
            <IconAcreage className={css.keyInfoIcon} />
          </div>
          <div className={css.keyInfoTextContainer}>
            <div>
              <FormattedMessage id="ListingPage.keyInfoAcreage" values={{ acres }} />

              {gameUnit && isHunting && (
                <>
                  &nbsp; &bull; &nbsp;
                  <FormattedMessage id="ListingPage.gameManagementUnit" values={{ gameUnit }} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {hasLodging && (
        <div className={css.keyInfoItem}>
          <IconLodging className={css.keyInfoIcon} />
          <FormattedMessage id="ListingPage.keyInfoLodging" />
        </div>
      )}
    </div>
  );
};

export default SectionKeyListingInfo;
