import React from 'react';
import { bool, func, object, string, node } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingLandownerStoryForm } from '../../forms';
import config from '../../config';

const getInitialValues = user => {
  const { attributes, profileImage } = user;
  const { profile } = attributes;

  return {
    bio: profile?.bio,
    profileImage,
  };
};

const getSubmissionValues = values => {
  const { bio } = values;

  return {
    bio,
  };
};

const EditListingLandownerStoryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    user,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    onUploadProfileImage,
  } = props;

  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingLandownerStoryPanel.title" />
  ) : (
    <FormattedMessage id="EditListingLandownerStoryPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingLandownerStoryForm
        initialValues={getInitialValues(user)}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          onSubmit(getSubmissionValues(values));
        }}
        onUploadProfileImage={onUploadProfileImage}
        onChange={onChange}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={config.custom.categories}
        backLink={backLink}
        user={user}
      />
    </EditListingPanel>
  );
};

EditListingLandownerStoryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

EditListingLandownerStoryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default EditListingLandownerStoryPanel;
