import * as props from '../prop-names';

import { formatEvent, getEventDate, getDefaultUserProps } from '../helpers';

// Tracked when the user successfully verifies id
export const trackIdVerificationSuccessEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.FIRST_ID_VERIFICATION_SUCCESSFUL_DATE]: getEventDate(),
    [props.LAST_ID_VERIFICATION_SUCCESSFUL_DATE]: getEventDate(),
    [props.ID_VERIFICATION_SUCCESSFUL_COUNT]: 1,
    ...getDefaultUserProps(state),
  };

  return event;
};

// eslint-disable-next-line no-unused-vars
export const trackIdVerificationFailedEvent = (type, payload, state) => {};
