/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import { get } from 'lodash';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { daysAdded } from '../../util/dates';
import { getBookingDates, txIsEnquired, txIsEnquiryExpired } from '../../util/transaction';
import { ReactComponent as CalendarLinesLight } from '../../assets/icons/calendar.svg';
import { ReactComponent as UserLight } from '../../assets/icons/guest.svg';
import AddressLinkMaybe from './AddressLinkMaybe';
import css from './BookingDetails.css';
import { propTypes } from '../../util/types';

const BookingDetails = ({ transaction, stateData }) => {
  let bookingGuestSize = get(
    transaction,
    'attributes.protectedData.packageLineItem.guestSize',
    null
  );

  let { bookingStart, bookingEnd } = getBookingDates(transaction);

  const currentOrExpiredInquiryState = txIsEnquired(transaction) || txIsEnquiryExpired(transaction);
  if (currentOrExpiredInquiryState) {
    bookingGuestSize = get(
      transaction,
      'attributes.protectedData.selectedPackage.packageParty',
      null
    );

    const inquiryStartDate = get(transaction, 'attributes.protectedData.startDate', null);
    const inquiryEndDate = get(transaction, 'attributes.protectedData.endDate', null);
    bookingStart = inquiryStartDate ? moment(inquiryStartDate) : null;
    bookingEnd = inquiryEndDate ? moment(inquiryEndDate) : null;
  }

  const isSingleDay = daysAdded(bookingStart, bookingEnd) === 1;
  let bookingStartText;
  let bookingEndText;

  if (bookingStart && bookingEnd) {
    bookingStartText = `${bookingStart.format('dddd, MMMM DD, YYYY')}`;
    bookingEndText = `${bookingEnd.format('dddd, MMMM DD, YYYY')}`;
  }

  const location = get(transaction, 'listing.attributes.publicData.location', null);
  const geolocation = get(transaction, 'listing.attributes.geolocation', null);

  const propertyAddress = location && geolocation && (
    <AddressLinkMaybe
      rootClassName={css.address}
      location={location}
      geolocation={geolocation}
      showAddress
    />
  );

  const arrivalInstructions = get(
    transaction,
    'listing.attributes.publicData.arrivalInstructions.instructions',
    null
  );

  const listingOnXWaypoints = get(transaction, 'listing.attributes.publicData.waypoints.onX', []);
  const listingHuntWiseWaypoints = get(
    transaction,
    'listing.attributes.publicData.waypoints.huntWise',
    []
  );

  const showPropertyAddress = stateData.showPropertyAddress && propertyAddress;
  const showArrivalInstructions = stateData.showArrivalInstructions && arrivalInstructions;
  const showListingOnXWaypoints =
    stateData.showListingWaypoints && listingOnXWaypoints && listingOnXWaypoints.length > 0;
  const showListingHuntWiseWaypoints =
    stateData.showListingWaypoints &&
    listingHuntWiseWaypoints &&
    listingHuntWiseWaypoints.length > 0;

  const showPropertyDetails =
    showPropertyAddress ||
    showArrivalInstructions ||
    showListingOnXWaypoints ||
    showListingHuntWiseWaypoints;

  return (
    <div className={css.root}>
      <div className={css.bookingDates}>
        {stateData.showBookingDetailsHeader && (
          <div className={css.header}>
            <FormattedMessage id="BookingDetails.header" />
          </div>
        )}

        {isSingleDay ? (
          <div className={css.bookingDatesItemWrapper}>
            <div className={css.bookingDatesIconWrapper}>
              <CalendarLinesLight className={css.bookingDatesIcon} />
            </div>
            <div className={css.bookingDatesItem}>
              <div className={css.bookingDatesItemLabel}>
                <FormattedMessage id="BookingDetails.tripDate" />
              </div>
              <div className={css.bookingDatesItemValue}>{bookingStartText}</div>
            </div>
          </div>
        ) : (
          <>
            <div className={css.bookingDatesItemWrapper}>
              <div className={css.bookingDatesIconWrapper}>
                <CalendarLinesLight className={css.bookingDatesIcon} />
              </div>
              <div className={css.bookingDatesItem}>
                <div className={css.bookingDatesItemLabel}>
                  <FormattedMessage id="BookingDetails.tripStart" />
                </div>
                <div className={css.bookingDatesItemValue}>{bookingStartText}</div>
              </div>
            </div>

            <div className={css.bookingDatesItemWrapper}>
              <div className={css.bookingDatesIconWrapper}>
                <CalendarLinesLight className={css.bookingDatesIcon} />
              </div>
              <div className={css.bookingDatesItem}>
                <div className={css.bookingDatesItemLabel}>
                  <FormattedMessage id="BookingDetails.tripEnd" />
                </div>
                <div className={css.bookingDatesItemValue}>{bookingEndText}</div>
              </div>
            </div>
          </>
        )}

        {bookingGuestSize > 0 && (
          <div className={css.bookingDatesItemWrapper}>
            <div className={css.bookingDatesIconWrapper}>
              <UserLight className={css.bookingDatesIcon} />
            </div>
            <div className={css.bookingDatesItem}>
              <div className={css.bookingDatesItemLabel}>
                <FormattedMessage id="BookingDetails.guest" values={{ guests: bookingGuestSize }} />
              </div>
              <div className={css.bookingDatesItemValue}>
                <FormattedMessage
                  id="BookingDetails.guestSize"
                  values={{ guests: bookingGuestSize }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {showPropertyDetails && (
        <div className={css.header}>
          <div className={css.divider} />
        </div>
      )}

      {showPropertyDetails && (
        <div className={css.header}>
          <FormattedMessage id="BookingDetails.propertyDetails" />
        </div>
      )}

      {showPropertyAddress && (
        <div className={css.addressWrapper}>
          <div className={css.title}>
            <FormattedMessage id="BookingDetails.propertyAddress" />
          </div>
          <div>{propertyAddress}</div>
        </div>
      )}

      {showArrivalInstructions && (
        <div>
          <div className={css.title}>
            <FormattedMessage id="BookingDetails.arrivalInstructions" />
          </div>

          <div
            className={css.description}
            dangerouslySetInnerHTML={{ __html: arrivalInstructions }}
          />
        </div>
      )}

      {(showPropertyAddress || showArrivalInstructions) &&
        (showListingOnXWaypoints || showListingHuntWiseWaypoints) && (
          <div className={css.divider} />
        )}

      {showListingOnXWaypoints && (
        <>
          <div className={css.title}>
            <FormattedMessage id="BookingDetails.onXWaypoints" />
          </div>

          <div className={css.waypoints}>
            {listingOnXWaypoints.map((waypoint, index) => (
              <a
                key={`waypoint-${index}`}
                className={css.waypointLink}
                href={waypoint.url}
                target="_blank"
                rel="noreferrer"
              >
                {waypoint.title}
              </a>
            ))}
          </div>
        </>
      )}

      {showListingHuntWiseWaypoints && (
        <>
          <div className={css.title}>
            <FormattedMessage id="BookingDetails.huntWiseWaypoints" />
          </div>

          <div className={css.waypoints}>
            {listingHuntWiseWaypoints.map((waypoint, index) => (
              <a
                key={`waypoint-${index}`}
                className={css.waypointLink}
                href={waypoint.url}
                target="_blank"
                rel="noreferrer"
              >
                {waypoint.title}
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

BookingDetails.defaultProps = {};

BookingDetails.propTypes = {
  transaction: propTypes.transaction.isRequired,
  stateData: object.isRequired,
};

export default BookingDetails;
