import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-regular.svg';
import { confirmSpecialOfferPayment, updatePartyMemberDetails } from '../../ducks/Transaction.duck';
import { ensureTransaction } from '../../util/data';
import { getUserTxRole } from '../../util/transaction';

import InboxDetailsManager from './InboxDetailsManager';
import InboxPartyMember from './InboxPartyMember';
import InboxStripePayment from './InboxStripePayment';
import InboxReview from './InboxReview';

import css from './InboxDetails.css';
import { getListingImages, getPackage, getPackageImages } from '../../util/listing';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import TripInsuranceBadges from '../TripInsuranceBadges/TripInsuranceBadges';
import { fetchCurrentUser, followSubject } from '../../ducks/user.duck';

const InboxDetails = ({
  history,
  transactions,
  params,
  currentUser,
  processTransitions,
  checkUserVerification,
  onUpdatePartyMemberDetails,
  isFetching,
  transactionState,
  currentListing,
  isMobile,
  onChangeBookingDetails,
  followSubjectMutation,
  fetchUser,
  onConfirmSpecialOfferPayment,
}) => {
  const transaction = transactions.find(tx => tx.id.uuid === params.id);

  if (!transaction) {
    return null;
  }

  const currentTransaction = ensureTransaction(transaction);

  const contentClassName = classNames(css.content, {
    [css.contentLoading]: isFetching,
  });

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const isCustomer = ownRole === 'customer';

  const packageId =
    get(currentTransaction, 'attributes.protectedData.selectedPackage.packageId', null) ||
    get(currentTransaction, 'attributes.protectedData.packageLineItem.id', null);

  const currentPackage = packageId ? getPackage(currentListing, packageId) : null;
  const packagesImages = currentPackage
    ? getPackageImages(currentListing, currentPackage?.id)
    : null;
  const listingImage = getListingImages(currentListing)[0] || null;
  const firstImage = packagesImages && packagesImages.length ? packagesImages[0] : listingImage;

  const onClose = () => {
    history.push(
      createResourceLocatorString('InboxV2Page', routeConfiguration(), {
        tab: params.tab,
      })
    );
  };

  if (isFetching) {
    return null;
  }

  return (
    <div className={classNames(css.root)}>
      <div
        className={css.headerContainer}
        style={{
          backgroundImage: firstImage
            ? `url(${firstImage.attributes.variants['scaled-large'].url})`
            : 'none',
        }}
      >
        {isMobile && (
          <button type="button" className={css.buttonClose} onClick={onClose}>
            <CloseIcon />
          </button>
        )}

        {currentTransaction && <TripInsuranceBadges currentTransaction={currentTransaction} />}

        <div className={contentClassName}>
          <InboxDetailsManager
            history={history}
            transaction={transaction}
            currentUser={currentUser}
            params={params}
            processTransitions={processTransitions}
            checkUserVerification={checkUserVerification}
            onChangeBookingDetails={onChangeBookingDetails}
          />
        </div>
      </div>

      <div className={css.footer}>
        <InboxReview currentUser={currentUser} currentTransaction={currentTransaction} />

        <InboxPartyMember
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
          transactionState={transactionState}
          isCustomer={isCustomer}
        />

        <InboxStripePayment
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          currentListing={currentListing}
          transactionState={transactionState}
          followSubjectMutation={followSubjectMutation}
          fetchUser={fetchUser}
          onConfirmSpecialOfferPayment={onConfirmSpecialOfferPayment}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { processTransitions } = state.Transaction;

  return {
    processTransitions,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdatePartyMemberDetails: (values, transaction) =>
    dispatch(updatePartyMemberDetails(values, transaction)),
  followSubjectMutation: (userId, subjectId, notifyBy) =>
    dispatch(followSubject(userId, subjectId, notifyBy)),
  fetchUser: () => dispatch(fetchCurrentUser()),
  onConfirmSpecialOfferPayment: transactionId =>
    dispatch(confirmSpecialOfferPayment(transactionId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(InboxDetails));
