import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  Form,
  FieldWysiwyg,
  PrimaryButton,
  FieldCheckbox,
  FieldLabel,
  FieldSpeciesCheckbox,
  LocationAutocompleteInputField,
  FieldMultipleLocationInput,
} from '../../components';
import {
  profileActivities,
  profileHuntingWeapons,
  speciesSearchGroups,
} from '../../marketplace-custom-config';
import css from './MyInterestsForm.css';

const MyInterestsForm = props => {
  const interestedActivities = get(props, 'initialValues.interestedActivities', []);
  const huntChecked = interestedActivities.includes('hunt');

  const [checked, setChecked] = useState(huntChecked);
  const handleClick = key => key === 'hunt' && setChecked(!checked);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          intl,
          invalid,
          disabled,
          pristine,
          submitSucceeded,
          updateInProgress,
          values,
        } = fieldRenderProps;

        const usStateLabel = intl.formatMessage({
          id: 'MyInterestsForm.usStateLabel',
        });
        const usStatePlaceholder = intl.formatMessage({
          id: 'MyInterestsForm.usStatePlaceholder',
        });
        const preferredStatesLabel = intl.formatMessage({
          id: 'MyInterestsForm.preferredStatesLabel',
        });
        const preferredStatesPlaceholder = intl.formatMessage({
          id: 'MyInterestsForm.preferredStatesPlaceholder',
        });
        const yourBioLabel = intl.formatMessage({
          id: 'MyInterestsForm.yourBioLabel',
        });
        const yourBioPlaceholder = intl.formatMessage({
          id: 'MyInterestsForm.yourBioPlaceholder',
        });

        const submitReady = submitSucceeded && pristine;
        const submitDisabled = invalid || disabled || updateInProgress;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.sectionContainer}>
              <div className={css.formSection}>
                <FieldWysiwyg
                  id="bio"
                  name="bio"
                  label={yourBioLabel}
                  placeholder={yourBioPlaceholder}
                />
              </div>

              <div className={css.formSection}>
                <LocationAutocompleteInputField
                  name="usState"
                  label={usStateLabel}
                  placeholder={usStatePlaceholder}
                  useDefaultPredictions={false}
                  format={null}
                  valueFromForm={values.usState}
                  showButton={false}
                  showIcon={false}
                />
              </div>

              <div className={css.formSection}>
                <FieldLabel>
                  <FormattedMessage id="MyInterestsForm.interestedActivitiesLabel" />
                </FieldLabel>
                <FieldLabel rootClassName={css.activityLabelSubTitle}>
                  <FormattedMessage id="MyInterestsForm.interestedActivitiesSubtitle" />
                </FieldLabel>

                <div className={css.activityCheckboxes}>
                  {Object.keys(profileActivities).map(key => (
                    <FieldCheckbox
                      key={`activity.${key}`}
                      id={`activity.${key}`}
                      name="interestedActivities"
                      value={key}
                      label={profileActivities[key].label}
                      labelClassName={css.fieldCheckboxLabel}
                      onClick={() => {
                        handleClick(key);
                      }}
                    />
                  ))}
                </div>
              </div>
              {checked && (
                <>
                  <div className={css.formSection}>
                    <FieldMultipleLocationInput
                      name="preferredHuntingStates"
                      label={preferredStatesLabel}
                      placeholder={preferredStatesPlaceholder}
                      useDefaultPredictions={false}
                      valueFromForm={values.preferredHuntingStates}
                      showButton={false}
                      showIcon={false}
                    />
                  </div>

                  <div className={css.formSection}>
                    <FieldLabel>
                      <FormattedMessage id="MyInterestsForm.huntingWeaponLabel" />
                    </FieldLabel>

                    <div className={css.weaponCheckboxes}>
                      {Object.keys(profileHuntingWeapons).map(key => (
                        <FieldCheckbox
                          key={`weapon.${key}`}
                          id={`weapon.${key}`}
                          name="preferredHuntingWeapons"
                          value={key}
                          label={profileHuntingWeapons[key].label}
                          labelClassName={css.fieldCheckboxLabel}
                        />
                      ))}
                    </div>
                  </div>

                  <div className={css.formSection}>
                    <FieldLabel>
                      <FormattedMessage id="MyInterestsForm.huntingSpeciesLabel" />
                    </FieldLabel>

                    <div className={css.huntSpeciesWrapper}>
                      {Object.keys(speciesSearchGroups.hunt).map(key => {
                        return (
                          <div key={`species.${key}`} className={css.huntSpeciesItem}>
                            <FieldSpeciesCheckbox
                              id={`species.${key}`}
                              name="preferredHuntingSpecies"
                              label={speciesSearchGroups.hunt[key].label}
                              speciesKey={key}
                              value={key}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <PrimaryButton
                type="submit"
                isFullWidth
                inProgress={updateInProgress}
                ready={submitReady}
                disabled={submitDisabled}
              >
                <FormattedMessage id="MyInterestsForm.saveChanges" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

MyInterestsForm.displayName = 'MyInterestsForm';

export default injectIntl(MyInterestsForm);
