import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Reviews } from '../../components';
import { averageListingRating } from '../../util/listing';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star.svg';

import css from './ListingPage.css';

const SectionReviews = props => {
  const { metaData, reviews, fetchReviewsError } = props;
  const rating = get(metaData, 'rating', null);
  const metaReviews = get(metaData, 'reviews', null);
  const fixedRating = rating && rating.toFixed(1);
  const avgRatings =
    fixedRating <= averageListingRating(reviews) ? fixedRating : averageListingRating(reviews);

  const totalReviews = get(metaData, 'totalReviews', null);
  const ratingsLength = totalReviews <= reviews.length ? reviews.length : totalReviews;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return reviews.length > 0 ? (
    <div className={css.sectionReviews} id="listingReviews">
      <div className={css.reviewsHeading}>
        <h3 className={css.sectionHeader}>
          <FormattedMessage id="ListingPage.reviewsHeading" values={{ count: ratingsLength }} />
        </h3>

        <span>
          <IconStar className={css.starIcon} />
        </span>
        <strong>{avgRatings}</strong>
      </div>
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={metaReviews} />
    </div>
  ) : null;
};

export default SectionReviews;
