import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionProviderCancelOffer = ({ transition, user, ownRole, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase date={transition.createdAt} direction="left" buttons={buttons}>
      {isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionCanceledOffer" />
      ) : (
        <FormattedMessage id="BookingStatus.transitionCanceledOffer" values={{ displayName }} />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionProviderCancelOffer;
