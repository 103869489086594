import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingPanelHeading.css';

const EditListingPanelHeading = ({ className, rootClassName, withSubheading, children }) => {
  const classes = classNames(
    rootClassName || css.root,
    {
      [css.headingSpacing]: !withSubheading,
    },
    className
  );

  return <h1 className={classes}>{children}</h1>;
};

EditListingPanelHeading.defaultProps = {
  className: null,
  rootClassName: null,
  withSubheading: false,
};

EditListingPanelHeading.propTypes = {
  className: string,
  rootClassName: string,
  withSubheading: bool,
};

export default EditListingPanelHeading;
