/* eslint-disable no-shadow */
import React from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ReactComponent as PencilIconSVG } from '../../assets/icons/pencil.svg';
import { propTypes } from '../../util/types';

import { required, composeValidators } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
  FieldLabel,
  FieldWysiwyg,
  ImageFromFile,
  IconSpinner,
  AvatarLarge,
} from '../../components';

import css from './EditListingLandownerStoryForm.css';

const EditListingLandownerStoryFormComponent = ({ backLink, isPublished, ...formProps }) => {
  return (
    <FinalForm
      {...formProps}
      render={fieldRenderProps => {
        const {
          form,
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          image,
          uploadImageError,
          uploadInProgress,
          onUploadProfileImage,
          user,
        } = fieldRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const profileImageId = values.profileImage ? values.profileImage.id : null;
        const profileImage = image || { imageId: profileImageId };

        const uploadingOverlay = uploadInProgress ? (
          <div className={css.uploadingImageOverlay}>
            <IconSpinner />
          </div>
        ) : null;

        // Ensure that file exists if imageFromFile is used
        const fileExists = !!profileImage.file;
        const fileUploadInProgress = uploadInProgress && fileExists;
        const imageFromFile =
          fileExists && fileUploadInProgress ? (
            <ImageFromFile
              id={profileImage.id}
              rootClassName={css.uploadingImage}
              aspectRatioClassName={css.squareAspectRatio}
              file={profileImage.file}
            >
              {uploadingOverlay}
            </ImageFromFile>
          ) : null;

        // Avatar is rendered in hidden during the upload delay
        // Upload delay smoothes image change process:
        // responsive img has time to load srcset stuff before it is shown to user.
        const avatarClasses = classNames(css.avatar);

        const chooseAvatarLabel = fileUploadInProgress ? (
          <div className={css.avatarContainer}>
            {imageFromFile}
            <div className={css.pencilIconContainer}>
              <PencilIconSVG className={css.pencilIcon} />
            </div>
          </div>
        ) : (
          <div className={css.avatarContainer}>
            <AvatarLarge user={user} className={avatarClasses} disableProfileLink />
            <div className={css.pencilIconContainer}>
              <PencilIconSVG className={css.pencilIcon} />
            </div>
          </div>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />

              <EditListingFieldWrapper>
                <FieldWysiwyg
                  id="bio"
                  name="bio"
                  label={intl.formatMessage({
                    id: 'EditListingLandownerStoryForm.bioLabel',
                  })}
                  validate={composeValidators(
                    required(
                      intl.formatMessage({
                        id: 'EditListingLandownerStoryForm.bioRequired',
                      })
                    )
                  )}
                  protip={intl.formatMessage({
                    id: 'EditListingLandownerStoryForm.bioProtip',
                  })}
                />
              </EditListingFieldWrapper>

              <EditListingFieldWrapper>
                <FieldLabel
                  description={intl.formatMessage({
                    id: 'EditListingLandownerStoryForm.profileImageDescription',
                  })}
                >
                  <FormattedMessage id="EditListingLandownerStoryForm.profileImageLabel" />
                </FieldLabel>

                <Field
                  accept="image/*"
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, type, disabled } = fieldProps;
                    const { name } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);

                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onUploadProfileImage({ id: tempId, file });
                      }
                    };

                    return (
                      <div>
                        <label className={css.uploadImageLabel} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                      </div>
                    );
                  }}
                </Field>
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingLandownerStoryFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingLandownerStoryFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  isPublished: bool.isRequired,
  onUploadProfileImage: func.isRequired,
  user: propTypes.user.isRequired,
};

const mapStateToProps = state => {
  const { image, uploadImageError, uploadInProgress } = state.ProfileSettingsPage;

  return {
    image,
    uploadImageError,
    uploadInProgress,
  };
};

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  injectIntl
)(EditListingLandownerStoryFormComponent);
