import React, { useState, useMemo } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotoLibraryForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import {
  PHOTO_LIBRARY_TABS,
  getPhotos,
  syncPhotoLibraryForSubmission,
} from '../../util/photoLibrary';
import {
  ListingLink,
  EditListingPanel,
  EditListingPanelHeading,
  EditListingPanelSubheading,
  EditListingSection,
  Tabs,
  Tab,
} from '..';
import css from './EditListingPhotoLibraryPanel.css';
import {
  BIRD_ACTIVITY,
  FARM_RANCH_ACTIVITY,
  FISH_ACTIVITY,
  HUNT_ACTIVITY,
  OUTDOOR_RECREATION_ACTIVITY,
} from '../../marketplace-custom-config';

const getInitialValues = listing => {
  const images = get(listing, 'images', []);
  const publicData = get(listing, 'attributes.publicData', {});
  const imageCaptions = get(publicData, 'imageCaptions', {});
  const photoLibraryTags = get(publicData, 'photoLibraryTags', {});

  return {
    images,
    imageCaptions,
    tags: photoLibraryTags,
  };
};

const getSubmissionValues = (listing, { images, imageCaptions, tags }) => {
  const {
    updatedImages,
    updatePackages,
    updatedArrivalInstructions,
    updatedImageCaptions,
  } = syncPhotoLibraryForSubmission(listing, images, imageCaptions);

  return {
    images: updatedImages,
    publicData: {
      imageCaptions: updatedImageCaptions,
      photoLibraryTags: tags,
      packages: updatePackages,
      arrivalInstructions: updatedArrivalInstructions,
    },
  };
};

const EditListingPhotoLibraryPanel = ({
  className,
  rootClassName,
  errors,
  listing,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  onChange,
  onSubmit,
  backLink,
}) => {
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const images = get(currentListing, 'images');
  const activities = get(currentListing, 'attributes.publicData.categories', []);
  const initialValues = useMemo(() => getInitialValues(listing), [listing]);
  const [activeTab, setActiveTab] = useState(PHOTO_LIBRARY_TABS.ALL);
  const [currentTags, setCurrentTags] = useState(initialValues.tags);
  const [currentImages, setCurrentImages] = useState(images);
  const [isUntaggedChecked, setIsUntaggedChecked] = useState(false);

  const handleUntaggedClick = () => {
    setIsUntaggedChecked(!isUntaggedChecked);
  };

  const handleTab = tab => {
    setActiveTab(tab);

    if (tab !== PHOTO_LIBRARY_TABS.ALL) {
      setIsUntaggedChecked(false);
    }
  };

  const propertyImages = useMemo(
    () => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.PROPERTY),
    [currentTags, currentImages]
  );

  const huntImages = useMemo(() => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.HUNT), [
    currentTags,
    currentImages,
  ]);
  const outdoorRecreationImages = useMemo(
    () => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION),
    [currentTags, currentImages]
  );

  const fishImages = useMemo(() => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.FISH), [
    currentTags,
    currentImages,
  ]);
  const birdImages = useMemo(() => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.BIRD), [
    currentTags,
    currentImages,
  ]);
  const farmImages = useMemo(() => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.FARM), [
    currentTags,
    currentImages,
  ]);
  const arrivalImages = useMemo(
    () => getPhotos(currentImages, currentTags, PHOTO_LIBRARY_TABS.ARRIVAL),
    [currentTags, currentImages]
  );

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPhotoLibraryPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPhotoLibraryPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth hasBottomMargin={false}>
        <EditListingPanelHeading withSubheading>{panelTitle}</EditListingPanelHeading>

        <EditListingPanelSubheading>
          <FormattedMessage id="EditListingPhotoLibraryPanel.subHeading" />
        </EditListingPanelSubheading>
      </EditListingSection>

      <div className={css.uploadRestrictions}>
        <p className={css.uploadRestrictionText}>
          <FormattedMessage id="EditListingPhotoLibraryForm.allowedFilesize" />
        </p>
        <p className={css.uploadRestrictionText}>
          <FormattedMessage id="EditListingPhotoLibraryForm.allowedImageTypes" />
        </p>
        <p className={css.uploadRestrictionText}>
          <FormattedMessage id="EditListingPhotoLibraryForm.allowedLimit" />
        </p>
      </div>

      <Tabs>
        <Tab
          handleClick={() => handleTab(PHOTO_LIBRARY_TABS.ALL)}
          isSelected={activeTab === PHOTO_LIBRARY_TABS.ALL}
        >
          <div className={css.tabContainer}>
            <FormattedMessage id="EditListingPhotoLibraryPanel.tabAll" />
            <span className={css.tabCount}>({currentImages.length}/100)</span>
          </div>
        </Tab>

        <Tab
          handleClick={() => handleTab(PHOTO_LIBRARY_TABS.PROPERTY)}
          isSelected={activeTab === PHOTO_LIBRARY_TABS.PROPERTY}
        >
          <div className={css.tabContainer}>
            <FormattedMessage id="EditListingPhotoLibraryPanel.tabProperty" />
            <span className={css.tabCount}>({propertyImages.length})</span>
          </div>
        </Tab>

        {activities.includes(HUNT_ACTIVITY) && (
          <Tab
            handleClick={() => handleTab(PHOTO_LIBRARY_TABS.HUNT)}
            isSelected={activeTab === PHOTO_LIBRARY_TABS.HUNT}
          >
            <div className={css.tabContainer}>
              <FormattedMessage id="EditListingPhotoLibraryPanel.tabHunting" />
              <span className={css.tabCount}>({huntImages.length})</span>
            </div>
          </Tab>
        )}

        {activities.includes(OUTDOOR_RECREATION_ACTIVITY) && (
          <Tab
            handleClick={() => handleTab(PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION)}
            isSelected={activeTab === PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION}
          >
            <div className={css.tabContainer}>
              <FormattedMessage id="EditListingPhotoLibraryPanel.tabOutdoorRecreation" />
              <span className={css.tabCount}>({outdoorRecreationImages.length})</span>
            </div>
          </Tab>
        )}

        {activities.includes(FISH_ACTIVITY) && (
          <Tab
            handleClick={() => handleTab(PHOTO_LIBRARY_TABS.FISH)}
            isSelected={activeTab === PHOTO_LIBRARY_TABS.FISH}
          >
            <div className={css.tabContainer}>
              <FormattedMessage id="EditListingPhotoLibraryPanel.tabFishing" />
              <span className={css.tabCount}>({fishImages.length})</span>
            </div>
          </Tab>
        )}

        {activities.includes(BIRD_ACTIVITY) && (
          <Tab
            handleClick={() => handleTab(PHOTO_LIBRARY_TABS.BIRD)}
            isSelected={activeTab === PHOTO_LIBRARY_TABS.BIRD}
          >
            <div className={css.tabContainer}>
              <FormattedMessage id="EditListingPhotoLibraryPanel.tabBirdwatching" />
              <span className={css.tabCount}>({birdImages.length})</span>
            </div>
          </Tab>
        )}

        {activities.includes(FARM_RANCH_ACTIVITY) && (
          <Tab
            handleClick={() => handleTab(PHOTO_LIBRARY_TABS.FARM)}
            isSelected={activeTab === PHOTO_LIBRARY_TABS.FARM}
          >
            <div className={css.tabContainer}>
              <FormattedMessage id="EditListingPhotoLibraryPanel.tabFarm" />
              <span className={css.tabCount}>({farmImages.length})</span>
            </div>
          </Tab>
        )}

        <Tab
          handleClick={() => handleTab(PHOTO_LIBRARY_TABS.ARRIVAL)}
          isSelected={activeTab === PHOTO_LIBRARY_TABS.ARRIVAL}
        >
          <div className={css.tabContainer}>
            <FormattedMessage id="EditListingPhotoLibraryPanel.tabArrival" />
            <span className={css.tabCount}>({arrivalImages.length})</span>
          </div>
        </Tab>
      </Tabs>

      <div className={css.imagesContainer}>
        <EditListingPhotoLibraryForm
          initialValues={initialValues}
          className={css.form}
          fetchErrors={errors}
          listing={listing}
          onSubmit={values => onSubmit(getSubmissionValues(listing, values))}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          backLink={backLink}
          activeTab={activeTab}
          updateTags={setCurrentTags}
          updateImages={setCurrentImages}
          isUntaggedChecked={isUntaggedChecked}
          onUntaggedClick={handleUntaggedClick}
        />
      </div>
    </EditListingPanel>
  );
};

EditListingPhotoLibraryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  backLink: null,
};

EditListingPhotoLibraryPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  backLink: node,
};

export default EditListingPhotoLibraryPanel;
