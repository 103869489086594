import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Field } from 'react-final-form';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { useMediaLayout } from 'use-media';

import { FieldLabel, ProtipTooltip, FieldCopy, ProtipLink } from '..';
import css from './FieldWysiwyg.css';

const FieldWysiwygComponent = ({
  id,
  label,
  labelDescription,
  input,
  protip,
  placeholder,
  help,
}) => {
  const [wysiwygState, setWysiwygState] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    setIsReady(true);
  }, []);

  // We use this hook to control different tooltip behaviour on mobile an desktop
  const isWide = useMediaLayout({ minWidth: '1024px' });

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(input.value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setWysiwygState(EditorState.createWithContent(contentState));
  }, []);

  // Need that state for onfocus event and use-media hook. Everthing need to be in the DOM first
  if (!isReady) return null;

  const fieldHelp = help ? <FieldCopy>{help}</FieldCopy> : null;

  const handleOnBlur = () => {
    setIsTooltipVisible(false);
  };

  const handleOnFocus = () => {
    if (isWide) {
      setIsTooltipVisible(true);
    }
  };

  const handleTooltipVisibility = isVisible => {
    setIsTooltipVisible(isVisible);
  };

  const renderEditor = () => {
    return (
      <Editor
        stripPastedStyles
        placeholder={placeholder}
        wrapperId={id}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        editorState={wysiwygState}
        wrapperClassName="package-description-wrapper"
        editorClassName={css.editor}
        onEditorStateChange={editorState => {
          const html = editorState.getCurrentContent().hasText()
            ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
            : '';

          input.onChange(html);
          setWysiwygState(editorState);
        }}
        toolbar={{
          options: ['inline', 'link', 'list'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'strikethrough'],
          },
          link: {
            popupClassName: css.linkPopup,
            options: ['link', 'unlink'],
          },
          list: {
            options: ['ordered', 'unordered'],
          },
        }}
      />
    );
  };

  return (
    <div>
      {label ? (
        <FieldLabel htmlFor={id} description={labelDescription}>
          {label}
        </FieldLabel>
      ) : null}

      {protip ? (
        <ProtipTooltip
          placement={isWide ? 'right' : 'bottom'}
          body={protip}
          tooltipShown={isTooltipVisible}
          onTooltipShown={handleTooltipVisibility}
          className={css.proTipTextArea}
          trigger="none"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: ({ placement }) => {
                  if (placement === 'bottom') {
                    return [0, 8];
                  }

                  return [0, 12];
                },
              },
            },
          ]}
        >
          {renderEditor()}
        </ProtipTooltip>
      ) : (
        renderEditor()
      )}

      <div className={css.helpWrapper}>
        {protip && (
          <div className={css.proTipWrapper}>
            <ProtipLink
              onClick={() => {
                setIsTooltipVisible(!isTooltipVisible);
              }}
            />
          </div>
        )}

        {fieldHelp && <div>{fieldHelp}</div>}
      </div>
    </div>
  );
};

const FieldWysiwyg = props => {
  return <Field component={FieldWysiwygComponent} {...props} />;
};

export default FieldWysiwyg;
