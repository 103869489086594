import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { oneOf } from 'prop-types';
import Decimal from 'decimal.js';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import config from '../../../config';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../../util/types';

import css from './LineItems.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  return new Money(amount, config.currency);
};

/**
 * Checks if line item represents commission and filters for the correct role
 */
// eslint-disable-next-line consistent-return
const isCommission = (lineItem, userRole) => {
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  if (isCustomer) {
    return lineItem.code === LINE_ITEM_PROVIDER_COMMISSION;
  }
  if (isProvider) {
    return lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION;
  }
};

/**
 * Returns all relevant reversal line items
 */
const reversalLineItems = (transaction, userRole) => {
  return transaction.attributes.lineItems.filter(
    item => !isCommission(item, userRole) && item.reversal && item.includeFor.includes(userRole)
  );
};

const LineItemRefundMaybe = props => {
  const { transaction, intl, userRole } = props;

  // all reversal line items
  const refundLineItems = reversalLineItems(transaction, userRole);

  const refund = lineItemsTotal(refundLineItems);

  const formattedRefund = refundLineItems.length > 0 ? formatMoney(intl, refund) : null;

  return formattedRefund ? (
    <div className={css.lineItemRefund}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refund" />
      </span>
      <span className={css.itemValue}>{formattedRefund}</span>
    </div>
  ) : null;
};

LineItemRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: oneOf(['provider', 'customer']).isRequired,

  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
