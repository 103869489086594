/* eslint-disable jsx-a11y/anchor-is-valid, no-undef, react/no-danger */
import React, { useState, useCallback } from 'react';
import { get } from 'lodash';
import { string, bool, func, number, shape, oneOf } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import OpeningPackageJSON from '../../assets/lottie/opening-package.json';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import MenuTooltip from '../MenuTooltip/MenuTooltip';
import { PrimaryButton } from '..';
import PackageBadges from '../PackageBadges/PackageBadges';
import Modal from '../Modal/Modal';
import { getStartingPrice, LODGING_INFO, getRVDetails } from '../../util/package';
import { getLodgingPricesOptions } from '../../util/listing';

import css from './PackageCardPreview.css';

const { Money } = sdkTypes;

export const FETCHING_STATUS = {
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
};

const PackageCardPreview = ({
  intl,
  editMode,
  previewMode,
  item,
  isPackageFetching,
  fetchingStatus,
  onEdit,
  onDuplicate,
  onRemove,
  onTogglePublish,
  onSubmit,
  displayBookingPackage,
  buttonLabel,
  rootClassName,
  className,
  isDisabled,
  currentListing,
}) => {
  const classes = classNames(rootClassName || css.package, className);
  const {
    guests,
    id,
    isPublished,
    title,
    days,
    price,
    description,
    lodgingPrices: lodgingPricesValues,
  } = item;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleTooltipVisibility = isVisible => {
    setIsTooltipVisible(isVisible);
  };

  const priceAmount = price?.amount || 0;
  const singleDayCount = days?.min === days?.max;

  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);

  const lodgingPrices = lodgingPricesOptions.map((option, index) => {
    return {
      ...option,
      option: lodgingPricesValues[index],
    };
  });

  const optionalLodging = (lodgingPrices || []).filter(
    lodgingPrice => lodgingPrice.option === LODGING_INFO.OPTIONAL
  );
  const includedLodging = (lodgingPrices || []).filter(
    lodgingPrice => lodgingPrice.option === LODGING_INFO.REQUIRED
  );

  const { isRvFriendly, hasRVSites } = getRVDetails(currentListing, { lodgingPrices });

  const packageItem = {
    price: price && JSON.stringify(price),
    guests,
    days,
    optionalLodging,
    includedLodging,
  };

  const startingPrice = getStartingPrice(packageItem);

  const userCommission = get(
    currentListing,
    'attributes.profile.privateData.commission.commissionRate',
    -15
  );

  const commission = startingPrice?.amount * guests.min * (userCommission / 100);

  const minEarningAmount = new Money(
    startingPrice?.amount * guests.min + commission,
    config.currency
  );

  const potentialEarnings = formatMoney(intl, minEarningAmount);
  const showPotentialEarnings = priceAmount > 0 && guests.max > 0 && guests.min > 0;

  const calendarLodgingIcons =
    previewMode && (!days || !guests.max || !guests.min) ? (
      <div className={css.packageDaysEmptyState} />
    ) : (
      <div className={css.badgesWrapper}>
        <PackageBadges
          packageInfo={{
            ...item,
          }}
          overrideInfo={{
            hasLodging: optionalLodging.length > 0 || includedLodging.length > 0,
            isRvFriendly,
            hasRVSites,
          }}
        />
      </div>
    );

  return (
    <div className={classes}>
      {isPackageFetching ? (
        <div className={css.packageLoadingWrapper}>
          <Player
            autoplay
            loop
            src={OpeningPackageJSON}
            style={{
              height: '100px',
            }}
          />

          <div className={css.fetchingStatus}>
            {fetchingStatus === FETCHING_STATUS.DUPLICATE && (
              <FormattedMessage id="PackageCard.fetchingDuplicate" />
            )}
            {fetchingStatus === FETCHING_STATUS.DELETE && (
              <FormattedMessage id="PackageCard.fetchingDelete" />
            )}
            {fetchingStatus === FETCHING_STATUS.PUBLISH && (
              <FormattedMessage id="PackageCard.fetchingPublish" />
            )}
            {fetchingStatus === FETCHING_STATUS.UNPUBLISH && (
              <FormattedMessage id="PackageCard.fetchingUnpublish" />
            )}
          </div>
        </div>
      ) : (
        <>
          {editMode && (
            <div className={css.menuIconWrapper}>
              <MenuTooltip
                tooltipShown={isTooltipVisible}
                onTooltipShown={handleTooltipVisibility}
                content={
                  <ul className={css.menuItemsWrapper}>
                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onEdit(e, item);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuEdit" />
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onDuplicate(e, item);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuDuplicate" />
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => onTogglePublish(e, id, !isPublished)}
                      >
                        {isPublished ? (
                          <FormattedMessage id="PackageCard.menuUnpublish" />
                        ) : (
                          <FormattedMessage id="PackageCard.menuPublish" />
                        )}
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onRemove(e, id);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuDelete" />
                      </a>
                    </li>
                  </ul>
                }
              />
            </div>
          )}
          <div className={css.bookingPackageWrapper}>
            <div className={css.bookingPackContainer}>
              <div className={css.packageDetails}>
                {previewMode && !title ? (
                  <div className={css.packageTitleEmptyState} />
                ) : (
                  <div className={css.packageTitle}>{title}</div>
                )}
                {description && !previewMode && (
                  <div className={css.description}>
                    <button type="button" className={css.packageModalBtn} onClick={openModal}>
                      <FormattedMessage id="PackageCard.viewDescription" />
                    </button>
                    <Modal
                      id="PackageDescriptionModal"
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      contentClassName={css.packageModalContent}
                      containerClassName={css.packageModalContainer}
                    >
                      <h3 className={css.packageModalHeader}>{title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </Modal>
                  </div>
                )}

                {previewMode && !price?.amount ? (
                  <div className={css.packagePriceEmptyState} />
                ) : (
                  <div>
                    <strong>
                      <FormattedMessage
                        id={
                          singleDayCount ? 'PackageCard.packagePrice' : 'PackageCard.startingPrice'
                        }
                        values={{
                          pricePerPerson: formatMoney(intl, startingPrice),
                        }}
                      />
                    </strong>
                  </div>
                )}

                {previewMode && showPotentialEarnings && (
                  <div className={css.packagePotentialEarnings}>
                    <FormattedMessage
                      id="PackageCard.estimatedEarnings"
                      values={{
                        earnings: potentialEarnings,
                      }}
                    />
                  </div>
                )}
                <div className={css.mobileCalendar}>{calendarLodgingIcons}</div>
              </div>
              {displayBookingPackage ? (
                <div className={css.packageButton}>
                  <PrimaryButton
                    type="button"
                    disabled={isDisabled}
                    className={css.chooseButton}
                    onClick={() => onSubmit({ selectedPackage: item, packageId: id })}
                  >
                    {buttonLabel}
                  </PrimaryButton>
                </div>
              ) : null}
            </div>
            <div className={css.desktopCalendar}>{calendarLodgingIcons}</div>
          </div>

          {previewMode &&
            (showPotentialEarnings ? (
              <div className={css.packagePotenatialEarnings}>
                <FormattedHTMLMessage id="PackageCard.previewFooter" />
              </div>
            ) : (
              <div className={css.packagePotenatialEarningsEmptyState} />
            ))}

          {editMode && description && !previewMode && (
            <div>
              <a href="#" onClick={e => onEdit(e, item)} className={css.packageDescription}>
                <FormattedMessage id="PackageCard.editDescription" />
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
};

PackageCardPreview.defaultProps = {
  editMode: false,
  previewMode: false,
  isPackageFetching: false,
  onEdit: null,
  onDuplicate: null,
  onRemove: null,
  onTogglePublish: null,
  fetchingStatus: null,
  isDisabled: false,
};

PackageCardPreview.propTypes = {
  intl: intlShape.isRequired,
  editMode: bool,
  previewMode: bool,
  item: shape({
    id: string,
    title: string.isRequired,
    description: string,
    days: number.isRequired,
    sportsmen: number,
    guests: shape({
      min: number.isRequired,
      max: number.isRequired,
    }),
  }).isRequired,
  isPackageFetching: bool,
  onEdit: func,
  onDuplicate: func,
  onRemove: func,
  onTogglePublish: func,
  fetchingStatus: oneOf([
    FETCHING_STATUS.DUPLICATE,
    FETCHING_STATUS.DELETE,
    FETCHING_STATUS.PUBLISH,
    FETCHING_STATUS.UNPUBLISH,
  ]),
  isDisabled: bool,
};

export default injectIntl(PackageCardPreview);
