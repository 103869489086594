import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EarlyAccessButton, CountdownTimer } from '..';
import css from './EarlyAccessButtonWithTimer.css';

const EarlyAccessButtonWithTimer = ({ expirationTimestamp, setIsModalOpen }) => {
  return (
    <div className={css.earlyAccess}>
      <EarlyAccessButton setIsModalOpen={setIsModalOpen} />

      <div className={css.packageAvailableTimer}>
        <FormattedMessage id="PackagePage.packageAvailable" />
        &nbsp;
        <CountdownTimer expirationTimestamp={expirationTimestamp} />
      </div>
    </div>
  );
};

export default EarlyAccessButtonWithTimer;
