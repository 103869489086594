import React from 'react';
import { injectIntl } from 'react-intl';
import { propTypes } from '../../util/types';
import { PartyMembersInfoForm } from '../../forms';
import css from './PartyMembers.css';

export const PartyMembersComponent = props => {
  const { transaction, partyMembers, onUpdatePartyMemberDetails, setBoth } = props;

  return (
    <div className={css.PartyMembersContainer}>
      <PartyMembersInfoForm
        initialValues={{
          partyMemberDetails: new Array(partyMembers).fill({ firstName: '', email: '' }),
        }}
        transaction={transaction}
        partyMembers={partyMembers}
        onSubmit={values => onUpdatePartyMemberDetails(values, transaction).then(setBoth(false))}
      />
    </div>
  );
};

PartyMembersComponent.defaultProps = { transaction: null };

PartyMembersComponent.propTypes = {
  transaction: propTypes.transaction,
};

const PartyMembers = injectIntl(PartyMembersComponent);

export default PartyMembers;
