/* eslint-disable import/prefer-default-export */
export const isFeatureFlagEnabled = flag => {
  try {
    const value = JSON.parse(localStorage.getItem(flag));

    return value === 1;
  } catch (e) {
    return false;
  }
};

export const updateRecentPackages = (listingID, packageID) => {
  let storedPackages = JSON.parse(localStorage.getItem('RecentPackages')) || [];

  const packages = `${listingID}:${packageID}`;

  // Remove the package if it already exists in the array
  const existingIndex = storedPackages.indexOf(packages);
  if (existingIndex !== -1) {
    storedPackages.splice(existingIndex, 1);
  }

  // Add the new packages to the beginning of the array
  storedPackages.unshift(packages);

  // Only keep the last 6 packages
  if (storedPackages.length > 6) {
    storedPackages = storedPackages.slice(0, 6);
  }

  localStorage.setItem('RecentPackages', JSON.stringify(storedPackages));
};
