/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { bool, string } from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { Form, AvatarLarge, ImageFromFile, IconSpinner } from '../../components';
import ImageCropper from '../../components/ImageCropper/ImageCropper';
import { AvatarXL } from '../../components/Avatar/Avatar';
import Modal from '../../components/Modal/Modal';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import { ReactComponent as PencilIconSVG } from '../../assets/icons/pencil.svg';
import { ReactComponent as IconAddPhotoSVG } from '../../assets/icons/icon-add-photo-upload.svg';
import { ReactComponent as IconEditPhotoSVG } from '../../assets/icons/icon-edit.svg';
import { ReactComponent as IconDeleteSVG } from '../../assets/icons/icon-delete-trash.svg';

import css from './UpdateAvatarForm.css';

const ACCEPT_IMAGES = 'image/*';

const UpdateAvatarForm = ({ ...props }) => {
  const fileInputRef = useRef(null);

  return (
    <FinalForm
      {...props}
      onSubmit={() => {}}
      render={fieldRenderProps => {
        const {
          className,
          currentUser,
          handleSubmit,
          onImageUpload,
          onImageDelete,
          profileImage,
          rootClassName,
          uploadImageError,
          uploadInProgress,
          form,
          isPhotoModalOpen,
          setIsPhotoModalOpen,
          isCropperModalOpen,
          setCropperModalOpen,
          showCropper,
          setShowCropper,
        } = fieldRenderProps;

        const user = ensureCurrentUser(currentUser);

        const openEditModal = () => {
          setIsPhotoModalOpen(false);
          setCropperModalOpen(true);
          setShowCropper(true);
        };

        const openImageModal = () => {
          setIsPhotoModalOpen(true);
          setCropperModalOpen(false);
        };

        const imageUpload = imageData => {
          openEditModal();
          onImageUpload(imageData);
        };

        const onSaveCroppedImage = async croppedImage => {
          setCropperModalOpen(false);
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          const file = new File([blob], 'profile-image.jpeg', { type: 'image/jpg' });

          const imageData = {
            imageId: profileImage.imageId,
            file,
          };

          onImageUpload(imageData);
        };

        const openFileExplorer = () => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        };

        const uploadingOverlay = uploadInProgress ? (
          <div className={css.uploadingImageOverlay}>
            <IconSpinner />
          </div>
        ) : null;

        // Ensure that file exists if imageFromFile is used
        const fileExists = !!profileImage.file;
        const fileUploadInProgress = uploadInProgress && fileExists;
        const imageFromFile =
          fileExists && fileUploadInProgress ? (
            <ImageFromFile
              id={profileImage.id}
              rootClassName={css.uploadingImage}
              aspectRatioClassName={css.squareAspectRatio}
              file={profileImage.file}
            >
              {uploadingOverlay}
            </ImageFromFile>
          ) : null;

        // Avatar is rendered in hidden during the upload delay
        // Upload delay smoothes image change process:
        // responsive img has time to load srcset stuff before it is shown to user.

        const hasProfileImage = profileImage.imageId;

        const userNoAvatarLabel = !hasProfileImage && (
          <div className={css.buttonContainer} onClick={openFileExplorer}>
            <FormattedMessage id="UpdateAvatarForm.chooseAvatarLabel" />
          </div>
        );
        const userHasAvatarLabel =
          hasProfileImage &&
          (fileUploadInProgress ? (
            <div className={css.avatarContainer}>
              {imageFromFile}
              <div className={css.pencilIconContainer}>
                <PencilIconSVG className={css.pencilIcon} />
              </div>
            </div>
          ) : (
            <div className={css.avatarContainer}>
              <div className={css.avatar} onClick={openImageModal} role="button">
                <AvatarLarge className={css.avatar} user={user} disableProfileLink />
              </div>

              <Modal
                isOpen={isPhotoModalOpen}
                onClose={() => setIsPhotoModalOpen(!isPhotoModalOpen)}
                containerClassName={css.modalContainer}
                customCloseButton={css.closeButton}
                customCloseIcon={css.closeIcon}
                id="photoModal"
              >
                <div className={css.avatarModalContainer}>
                  <AvatarXL user={user} disableProfileLink />
                </div>
                <hr className={css.totalDivider} />
                <div className={css.modalButtonContainer}>
                  <div className={css.leftButtonGroup}>
                    <div className={css.iconAddPhotoContainer} onClick={openFileExplorer}>
                      <IconAddPhotoSVG className={css.buttonIcons} />
                      <button type="button" className={css.changePhoto}>
                        <FormattedMessage id="ProfilePage.addPhotoButton" />
                      </button>
                    </div>
                    <div className={css.iconEditContainer} onClick={openEditModal}>
                      <IconEditPhotoSVG className={css.buttonIcons} />
                      <button type="button" className={css.changePhoto}>
                        <FormattedMessage id="ProfilePage.editPhotoButton" />
                      </button>
                    </div>
                  </div>

                  <div className={css.iconDeleteButtonContainer} onClick={onImageDelete}>
                    <IconDeleteSVG className={css.buttonIcons} />
                    <button type="button" className={css.changePhoto}>
                      <FormattedMessage id="ProfilePage.deletePhotoButton" />
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={isCropperModalOpen}
                onClose={() => setCropperModalOpen(false)}
                containerClassName={css.editModalContainer}
                customCloseButton={css.closeButton}
                customCloseIcon={css.closeIcon}
                id="editModal"
              >
                <div>
                  {showCropper && (
                    <ImageCropper user={user} onSaveCroppedImage={onSaveCroppedImage} />
                  )}
                </div>
              </Modal>
              <div className={css.pencilIconContainer} onClick={openImageModal}>
                <PencilIconSVG className={css.pencilIcon} />
              </div>
            </div>
          ));

        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              handleSubmit(e);
            }}
          >
            <Field
              accept={ACCEPT_IMAGES}
              id="profileImage"
              name="profileImage"
              label={hasProfileImage ? userHasAvatarLabel : userNoAvatarLabel}
              type="file"
              form={null}
              uploadImageError={uploadImageError}
              disabled={uploadInProgress}
            >
              {fieldProps => {
                const { accept, id, input, label, type, disabled } = fieldProps;
                const { name } = input;
                const onChange = e => {
                  const file = e.target.files[0];
                  form.change(`profileImage`, file);
                  form.blur(`profileImage`);

                  if (file != null) {
                    const tempId = `${file.name}_${Date.now()}`;
                    imageUpload({ id: tempId, file });
                  }
                };

                return (
                  <div className={css.uploadAvatarWrapper}>
                    <label className={css.label} htmlFor={id}>
                      {label}
                    </label>
                    <input
                      accept={accept}
                      ref={fileInputRef}
                      name={name}
                      className={css.uploadAvatarInput}
                      disabled={disabled}
                      onChange={onChange}
                      type={type}
                    />
                  </div>
                );
              }}
            </Field>
          </Form>
        );
      }}
    />
  );
};

UpdateAvatarForm.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
};

UpdateAvatarForm.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
};

export default UpdateAvatarForm;
