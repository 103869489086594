import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { IconSocialMediaTwitter, Logo, ExternalLink, NamedLink } from '..';
import { ReactComponent as FacebookSquare } from '../../assets/icons/facebook-square.svg';
import { ReactComponent as InstagramSquare } from '../../assets/icons/instagram-square.svg';
import { ReactComponent as YoutubeSquare } from '../../assets/icons/youtube-square.svg';
import { ReactComponent as LinkedinSquare } from '../../assets/icons/linkedin-square.svg';
import { ReactComponent as USFlag } from '../../assets/icons/us-flag.svg';

import css from './Footer.css';
import { Container } from '../Layout';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteYoutubePage,
    siteLinkedinPage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedIn' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} title={goToFb}>
      <FacebookSquare />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink key="linkToTwitter" href={siteTwitterPage} title={goToTwitter}>
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instagramLink = siteInstagramPage ? (
    <ExternalLink key="linkToInstagram" href={siteInstagramPage} title={goToInsta}>
      <InstagramSquare />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink key="linkToYoutube" href={siteYoutubePage} title={goToYoutube}>
      <YoutubeSquare />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink key="linkToLinkedin" href={siteLinkedinPage} title={goToLinkedin}>
      <LinkedinSquare />
    </ExternalLink>
  ) : null;

  return [fbLink, twitterLink, instagramLink, youtubeLink, linkedinLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Container>
        <NamedLink name="LandingPage" className={css.logoLink}>
          <Logo />
        </NamedLink>

        <div className={css.grid}>
          <div className={classNames(css.description, css.gridItem)}>
            <a href="/us" rel="noreferrer">
              <FormattedHTMLMessage id="Footer.organizationDescription" />
            </a>
            <br />
            <br />
            <FormattedHTMLMessage
              id="Footer.copyright"
              values={{ year: new Date().getFullYear() }}
            />{' '}
            <USFlag className={css.icon} />
          </div>

          <ul className={classNames(css.links, css.gridItem)}>
            <li>
              <NamedLink name="LandownersPage" className={css.link}>
                <FormattedMessage id="Footer.toLandownersPage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="Footer.toAboutPage" />
              </NamedLink>
            </li>

            <li>
              <NamedLink name="ContactPage" className={css.link}>
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="LandingPage" to={{ hash: '#popListings' }} className={css.link}>
                <FormattedMessage id="Footer.toSportsmenPage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="ConservationPartners" className={css.link}>
                <FormattedMessage id="Footer.toConservationPage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="HuntPrivatePage" className={css.link}>
                <FormattedMessage id="Footer.toHuntPrivatePage" />
              </NamedLink>
            </li>

            <li>
              <ExternalLink
                key="linkToBlog"
                href="https://blog.landtrust.com/home"
                className={css.link}
                title={Footer.goToBlog}
              >
                <FormattedMessage id="Footer.toBlog" />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                key="linkToJobs"
                href="https://www.linkedin.com/company/landtrustinc/jobs/"
                className={css.link}
                title={Footer.goToJobs}
              >
                <FormattedMessage id="Footer.toJobs" />
              </ExternalLink>
            </li>
            <li>
              <NamedLink name="InsurancePage" className={css.link}>
                <FormattedMessage id="Footer.toInsurancePage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="PerksPage" className={css.link}>
                <FormattedMessage id="Footer.toPerksPage" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="MediaPage" className={css.link}>
                <FormattedMessage id="Footer.toMediaPage" />
              </NamedLink>
            </li>
          </ul>

          <div className={classNames(css.otherLinks, css.gridItem)}>
            <div className={classNames(css.socialLinks)}>{socialMediaLinks}</div>
            <div>
              <NamedLink name="TermsOfServicePage" className={css.link}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </div>
            <div>
              <NamedLink name="PrivacyPolicyPage" className={css.link}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
