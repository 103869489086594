/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import Logo from '../../assets/landtrust-plus-v2.webp';
import { Page, LayoutSingleColumn, LayoutWrapperMain, LayoutWrapperTopbar } from '../../components';

import { TopbarContainer } from '..';

import InquiryAuthenticationPage from '../../components/InquiryAuthenticationPage/InquiryAuthenticationPage';
import PlusTestimonials from '../../components/PlusTestimonials/PlusTestimonials';
import css from './MembershipSignupPage.css';

export const MembershipSignupPageComponent = () => {
  const [hashPresent, setHashPresent] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isDiscountHashPresent = window.location.hash === '#discount';
      setHashPresent(isDiscountHashPresent);
    }
  }, []);

  return (
    <Page>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="MembershipSignupPage"
            showTabs={false}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.contentStyles}>
            <div>
              <img src={Logo} alt="Landtrust+" className={css.bannerLogoImage} />
            </div>
            {hashPresent ? (
              <div className={css.titleStyles}>
                <FormattedMessage id="SignupForm.titleCopyDiscount" />
              </div>
            ) : (
              <div className={css.titleStyles}>
                <FormattedMessage id="SignupForm.titleCopy" />
              </div>
            )}
          </div>
          <div className={css.authComponentWrapper}>
            <InquiryAuthenticationPage fromLTPlusPage plusLoginButton />
          </div>
          <div className={css.testimonialWrapper}>
            <PlusTestimonials />
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

MembershipSignupPageComponent.propTypes = {};

MembershipSignupPageComponent.defaultProps = {};

const MembershipSignupPage = compose(
  withRouter,
  connect(),
  injectIntl
)(MembershipSignupPageComponent);

export default MembershipSignupPage;
