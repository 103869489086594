import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';
import { TopbarSearchForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import css from './SectionHero.css';
import { Container } from '../Layout';
import HeroImage from '../../assets/hero-alt.jpg';

const SectionHero = props => {
  const { history, currentSearchParams } = props;

  const handleSubmit = values => {
    const { search, selectedPlace } = values.location;

    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={css.root} data-testid="hero-section">
      <Container>
        <div className={css.content}>
          <div className={classNames(css.column, css.sloganColumn)}>
            <h1 className={css.slogan}>
              <FormattedHTMLMessage id="SectionHero.slogan" />
            </h1>
            <div className={css.searchContainer}>
              <TopbarSearchForm
                form="TopbarSearchFormDesktop"
                onSubmit={handleSubmit}
                showButton
                iconLeft
              />
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url(${HeroImage})`,
            }}
            className={classNames(css.column, css.featuredColumn)}
          />
        </div>
      </Container>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {};

export default SectionHero;
