import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.css';

const IconCheckbox = props => {
  const { className, checkedBackgroundClassName, borderClassName } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        d="m 20.999959,4.0097303 -0.03891,13.9985887 c -0.0046,1.653588 -1.346475,2.991654 -3.000181,2.991654 H 4.000465 c -1.6569614,0 -3.0001942,-1.343143 -3.0001942,-2.999991 L 1.0000288,4.0000204 c 0,-1.6568494 1.3432324,-2.9999916 3.000194,-2.9999916 h 2.422e-4 l 13.999555,0.00137 c 1.65696,1.34e-4 3.000085,1.3433843 2.999951,3.0002339 a 3.0006356,3.0004331 0 0 1 -1.2e-5,0.00811 z"
        className={checkedBackgroundClassName || css.checkedBackground}
      />
      <path
        d="M 4 0 C 1.8026347 0 5.9211895e-16 1.802674 0 4 L 0 18 C 1.9631823e-16 20.197325 1.8026345 22 4 22 L 17.960938 22 C 20.154065 22 21.954837 20.204795 21.960938 18.011719 L 22 4.0117188 C 22.000002 4.0109979 21.999998 4.0104865 22 4.0097656 C 22.000007 4.0068821 22 4.0048366 22 4.0019531 C 22.000178 1.8046265 20.197364 0.002130828 18 0.001953125 L 4 0 z M 4 2 L 18 2.0019531 C 19.116556 2.0020434 20.00009 2.8855805 20 4.0019531 C 20 4.0037554 20.000005 4.0060102 20 4.0078125 L 20 4.0097656 L 19.960938 18.005859 C 19.957838 19.119959 19.075222 20 17.960938 20 L 4 20 C 2.8834427 20 2 19.116371 2 18 L 2 4 C 2 2.8836272 2.8834421 2 4 2 z"
        style={{ strokeWidth: 2 }}
        className={borderClassName || css.border}
      />

      <path
        style={{ fill: '#ffffff' }}
        d="m 8.8665,13.6005 -2.8,-2.8 -0.933,0.933 3.733,3.733 8,-8 -0.933,-0.933 z"
      />
    </svg>
  );
};

IconCheckbox.defaultProps = {
  className: null,
  checkedBackgroundClassName: null,
  borderClassName: null,
};

IconCheckbox.propTypes = {
  className: string,
  checkedBackgroundClassName: string,
  borderClassName: string,
};

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    labelClassName,
    id,
    label,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedBackgroundClassName: css.checkedBackgroundSuccess,
        borderClassName: css.borderSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={classNames(css.label, labelClassName)}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  labelClassName: null,
  label: null,
  value: undefined,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,
  labelClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  // TODO: the API here is really strange, this value is not required
  value: string,
};

export default FieldCheckboxComponent;
