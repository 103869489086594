import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingPanelSubheading.css';

const EditListingPanelSubheading = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.root, className);

  return <p className={classes}>{children}</p>;
};

EditListingPanelSubheading.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingPanelSubheading.propTypes = {
  className: string,
  rootClassName: string,
};

export default EditListingPanelSubheading;
