import React, { useState, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as IconBarn } from '../../assets/icons/OutdoorActivities/icon-barn-regular.svg';
import { ReactComponent as IconBiking } from '../../assets/icons/OutdoorActivities/icon-biking-regular.svg';
import { ReactComponent as IconBirdwatching } from '../../assets/icons/OutdoorActivities/icon-birdwatching-regular.svg';
import { ReactComponent as IconBoating } from '../../assets/icons/OutdoorActivities/icon-boating-regular.svg';
import { ReactComponent as IconCanoe } from '../../assets/icons/OutdoorActivities/icon-canoe-regular.svg';
import { ReactComponent as IconChristmasTree } from '../../assets/icons/OutdoorActivities/icon-christmas-tree-regular.svg';
import { ReactComponent as IconClimbing } from '../../assets/icons/OutdoorActivities/icon-climbing.svg';
import { ReactComponent as IconDogTraining } from '../../assets/icons/OutdoorActivities/icon-dog-training-regular.svg';
import { ReactComponent as IconFishing } from '../../assets/icons/OutdoorActivities/icon-fishing-regular.svg';
import { ReactComponent as IconForaging } from '../../assets/icons/OutdoorActivities/icon-foraging.svg';
import { ReactComponent as IconFossilHunting } from '../../assets/icons/OutdoorActivities/icon-fossil-hunting.svg';
import { ReactComponent as IconHiking } from '../../assets/icons/OutdoorActivities/icon-hiking.svg';
import { ReactComponent as IconHorsebackRiding } from '../../assets/icons/OutdoorActivities/icon-horseback-riding-regular.svg';
import { ReactComponent as IconKayak } from '../../assets/icons/OutdoorActivities/icon-kayak-regular.svg';
import { ReactComponent as IconNatureGathering } from '../../assets/icons/OutdoorActivities/icon-nature-gathering.svg';
import { ReactComponent as IconOffroad } from '../../assets/icons/OutdoorActivities/icon-offroad.svg';
import { ReactComponent as IconPaddleboard } from '../../assets/icons/OutdoorActivities/icon-paddleboard.svg';
import { ReactComponent as IconPhotography } from '../../assets/icons/OutdoorActivities/icon-photography.svg';
import { ReactComponent as IconSkiing } from '../../assets/icons/OutdoorActivities/icon-skiing-regular.svg';
import { ReactComponent as IconSledding } from '../../assets/icons/OutdoorActivities/icon-sledding.svg';
import { ReactComponent as IconSnowmobile } from '../../assets/icons/OutdoorActivities/icon-snowmobile.svg';
import { ReactComponent as IconStargaze } from '../../assets/icons/OutdoorActivities/icon-stargaze.svg';
import { ReactComponent as IconSwim } from '../../assets/icons/OutdoorActivities/icon-swim.svg';

import css from './SectionOutdoorActivities.css';
import { activitiesTypeMap } from '../../marketplace-custom-config';
import Modal from '../../components/Modal/Modal';
import { SecondaryButton } from '../../components';

const getActivitiesMap = () => {
  const activitiesMap = {};

  activitiesTypeMap.forEach(activity => {
    let icon = null;

    switch (activity.key) {
      case 'backcountry_skiing':
        icon = <IconSkiing className={css.icon} />;
        break;
      case 'biking':
        icon = <IconBiking className={css.icon} />;
        break;
      case 'bird':
        icon = <IconBirdwatching className={css.icon} />;
        break;
      case 'boating':
        icon = <IconBoating className={css.icon} />;
        break;
      case 'canoeing':
        icon = <IconCanoe className={css.icon} />;
        break;
      case 'christmas_tree_harvest':
        icon = <IconChristmasTree className={css.icon} />;
        break;
      case 'climbing':
        icon = <IconClimbing className={css.icon} />;
        break;
      case 'dog_training':
        icon = <IconDogTraining className={css.icon} />;
        break;
      case 'fish':
        icon = <IconFishing className={css.icon} />;
        break;
      case 'fossil_hunting':
        icon = <IconFossilHunting className={css.icon} />;
        break;
      case 'foraging':
        icon = <IconForaging className={css.icon} />;
        break;
      case 'farm':
        icon = <IconBarn className={css.icon} />;
        break;
      case 'hiking':
        icon = <IconHiking className={css.icon} />;
        break;
      case 'horseback_riding':
        icon = <IconHorsebackRiding className={css.icon} />;
        break;
      case 'kayaking':
        icon = <IconKayak className={css.icon} />;
        break;
      case 'nature_gathering':
        icon = <IconNatureGathering className={css.icon} />;
        break;
      case 'off_roading':
        icon = <IconOffroad className={css.icon} />;
        break;
      case 'paddleboarding':
        icon = <IconPaddleboard className={css.icon} />;
        break;
      case 'photography':
        icon = <IconPhotography className={css.icon} />;
        break;
      case 'sledding':
        icon = <IconSledding className={css.icon} />;
        break;
      case 'snowmobiling':
        icon = <IconSnowmobile className={css.icon} />;
        break;
      case 'snowshoeing':
        icon = <IconSkiing className={css.icon} />;
        break;
      case 'stargazing':
        icon = <IconStargaze className={css.icon} />;
        break;
      case 'swimming':
        icon = <IconSwim className={css.icon} />;
        break;
      default:
    }

    activitiesMap[activity.key] = {
      label: activity.label,
      icon,
    };
  });

  return activitiesMap;
};

const SectionOutdoorActivities = props => {
  const { currentPackage } = props;
  const { activities = [], activitiesInfo } = currentPackage || {};

  const [isActivitiesModalOpen, setOpenActivitiesModal] = useState(false);
  const activitiesMap = useMemo(() => getActivitiesMap(), []);

  if (!activities.length) {
    return null;
  }

  const hasActivitiesInfo =
    activitiesInfo && Object.keys(activitiesInfo).some(activity => !!activitiesInfo[activity]);

  return (
    <div className={css.sectionDetails}>
      <div className={css.detailsGroupsContainer}>
        <div className={css.detailsGroup}>
          <div className={css.detailsSubtitle}>
            <FormattedMessage id="ListingPage.outdoorActivitiesTitle" />
          </div>
          <ul className={css.activitiesContainer}>
            {activities.map(activity => (
              <li className={css.detail}>
                {activitiesMap[activity]?.icon}
                {activitiesMap[activity]?.label}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Modal
        className={css.outdoorActivitiesModal}
        scrollLayerClassName={css.outdoorActivitiesScrollModal}
        id="ListingPage.outdoorActivitiesTitle"
        isOpen={isActivitiesModalOpen}
        onClose={() => setOpenActivitiesModal(!isActivitiesModalOpen)}
      >
        <h3 className={css.modalTitle}>
          <FormattedMessage id="ListingPage.outdoorActivitiesTitle" />
        </h3>

        <div>
          <div>
            <div className={css.modalDetails}>
              {activities.map(activity => (
                <div className={css.modalDetailContainer} key={`activity-${activity}`}>
                  <div className={css.modalDetail}>
                    {activitiesMap[activity]?.icon}
                    <span className={css.detailLabel}>{activitiesMap[activity]?.label}</span>
                  </div>

                  {activitiesInfo && activitiesInfo[activity] && (
                    <div className={css.detailInfo}>{activitiesInfo[activity]}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>

      {hasActivitiesInfo && (
        <SecondaryButton
          type="button"
          onClick={() => setOpenActivitiesModal(!isActivitiesModalOpen)}
          className={css.viewMoreButton}
        >
          <FormattedMessage id="ListingPage.outdoorViewActivityDetails" />
        </SecondaryButton>
      )}
    </div>
  );
};

export default injectIntl(SectionOutdoorActivities);
