import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import UpArrow from '../../assets/icons/ListingPage/icon-arrow-up.webp';
import DownArrow from '../../assets/icons/ListingPage/icon-arrow-down.webp';
import css from '../../containers/ActivityFeed/FollowButtonSection.css';

const UnfollowButton = props => {
  const {
    buttonStyle,
    notificationIcon,
    renderText,
    unfollowProperty,
    closeIcon,
    notificationsEnabled,
    bellIcon,
    blackBellIcon,
    notifyMeUpdate,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipButtonClick = action => {
    action();
    setTooltipOpen(false);
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const renderArrowIcon = tooltipOpen ? UpArrow : DownArrow;

  return (
    <Button className={buttonStyle} onClick={toggleTooltip}>
      <span>
        <img src={notificationIcon} alt="Bell icon" className={css.tooltipIconStyles} />
        <span>
          <FormattedMessage id={renderText} />
        </span>
      </span>
      <Tooltip
        trigger="click"
        hideCloseButton
        tooltipShown={tooltipOpen}
        onTooltipShown={setTooltipOpen}
        rootClassName={css.tooltipRoot}
        placement="bottom-end"
        tooltip={
          <div className={css.tooltipWrapperStyles}>
            <button
              type="button"
              href="#"
              className={css.tooltipLineStyles}
              onClick={() => handleTooltipButtonClick(unfollowProperty)}
            >
              <div className={css.tooltipButtonStyles}>
                <img src={closeIcon} alt="test" className={css.tooltipIconStyles} />
              </div>
              <span className={css.toolipContent}>
                <FormattedMessage id="ListingPage.activityFeedUnfollow" />
              </span>
            </button>
            {notificationsEnabled && (
              <button
                type="button"
                className={css.tooltipLineStyles}
                onClick={() => handleTooltipButtonClick(() => notifyMeUpdate(false))}
              >
                <div className={css.tooltipButtonStyles}>
                  <img src={bellIcon} alt="test" className={css.tooltipIconStyles} />
                </div>
                <span className={css.toolipContent}>
                  <FormattedMessage id="ListingPage.activityFeedDontNotify" />
                </span>
              </button>
            )}
            {!notificationsEnabled && (
              <button
                type="button"
                className={css.tooltipLineStyles}
                onClick={() => handleTooltipButtonClick(() => notifyMeUpdate(true))}
              >
                <div className={css.tooltipButtonStyles}>
                  <img src={blackBellIcon} alt="test" className={css.tooltipIconStyles} />
                </div>
                <span className={css.toolipContent}>
                  <FormattedMessage id="ListingPage.activityFeedNotifyMe" />
                </span>
              </button>
            )}
          </div>
        }
      >
        <img src={renderArrowIcon} alt="Bell icon" className={css.arrowIconStyles} />
      </Tooltip>
    </Button>
  );
};
export default UnfollowButton;
