import React from 'react';

import { getBytescaleVideoURL } from '../../util/bytescale';

export default function BytescaleVideo({ videoPath, onClick, className }) {
  const url = getBytescaleVideoURL(videoPath);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls muted className={className} onClick={onClick} autoPlay playsInline>
      <source src={url} type="video/mp4" />
      {`Your browser does not support the video tag. Filename: ${videoPath}`}
    </video>
  );
}
