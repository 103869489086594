import React from 'react';
import { FormattedMessage } from 'react-intl';

import StateCard from './StateCard';

import css from './SectionPopularStates.css';

import montanaImg from './images/montana.jpg';
import jerseyImg from './images/new-jersey.jpg';
import ohioImg from './images/ohio.jpg';
import kansasImg from './images/kansas.jpg';
import alabamaImg from './images/alabama.jpg';
import kentuckyImg from './images/kentucky.jpg';
import illinoisImg from './images/illinois.jpg';
import missouriImg from './images/missouri.jpg';

const states = [
  {
    title: 'Montana',
    image: montanaImg,
    search:
      '?address=Montana&bounds=49.0013909999925%2C-104.039137017%2C44.3582200627177%2C-116.049976999991',
  },
  {
    title: 'New Jersey',
    image: jerseyImg,
    search:
      '?address=New%20Jersey&bounds=41.3573609083968%2C-73.785607078847%2C38.7439940487484%2C-75.5635869938709',
  },
  {
    title: 'Ohio',
    image: ohioImg,
    search:
      '?address=Ohio&bounds=42.3271325441891%2C-80.5189900192544%2C38.4040800199213%2C-84.820156709745',
  },
  {
    title: 'Kansas',
    image: kansasImg,
    search:
      '?address=Kansas&bounds=40.0030779944224%2C-94.5886570042397%2C36.9930150747522%2C-102.051744999984',
  },
  {
    title: 'Alabama',
    image: alabamaImg,
    search:
      '?address=Alabama&bounds=35.0080286862615%2C-84.8882450013822%2C30.0447920001261%2C-88.4732279990263',
  },
  {
    title: 'Kentucky',
    image: kentuckyImg,
    search:
      '?address=Kentucky&bounds=39.1472239832386%2C-81.9648960067585%2C36.4970570160105%2C-89.5712039462988',
  },
  {
    title: 'Illinois',
    image: illinoisImg,
    search:
      '?address=Illinois&bounds=42.508302851296%2C-87.0199341498631%2C36.9702970135355%2C-91.512974995438',
  },
  {
    title: 'Missouri',
    image: missouriImg,
    search:
      '?address=Missouri&bounds=40.6136408695545%2C-89.0989670123517%2C35.9956823013028%2C-95.7747049551669',
  },
];

const SectionPopularStates = () => {
  return (
    <div className={css.root} data-testid="popular-states-section">
      <h1 className={css.title}>
        <FormattedMessage id="SectionPopularStates.title" />
      </h1>

      <div className={css.states}>
        {states.map(s => (
          <StateCard
            key={s.title}
            routeName="SearchPage"
            routeTo={{
              search: s.search,
            }}
            title={s.title}
            image={s.image}
          />
        ))}
      </div>
    </div>
  );
};

SectionPopularStates.propTypes = {};

export default SectionPopularStates;
