import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionCustomerAcceptOffer = ({ transition, user, ownRole, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = get(user, 'attributes.profile.displayName', '');
  const firstName = getFirstName(displayName);

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.ownTransitionSpecialOfferRequiresPayment" />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionRequiresPayment"
            values={{ displayName: firstName }}
          />
        )
      }
    />
  );
};

export default InboxTransitionCustomerAcceptOffer;
