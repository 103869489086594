/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../../util/types';

import css from './LineItems.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemProviderCommissionMaybe = props => {
  const { transaction, isProvider, intl, modalOffer, onClick } = props;

  const providerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );
  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
  // which containt provider commissions so by default the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from BookingBreakdown.js file.
  if (isProvider && providerCommissionLineItem) {
    if (!isValidCommission(providerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', providerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or negative');
    }

    const commission = providerCommissionLineItem.lineTotal;
    const commissionIsZero = providerCommissionLineItem?.lineTotal?.amount === 0;
    const formattedCommission = commission ? formatMoney(intl, commission) : null;
    if (!commissionIsZero) {
      commissionItem = (
        <>
          <div className={css.lineItem}>
            <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
              <FormattedMessage id="BookingBreakdown.landtrustCommission" />
            </span>
            <span className={css.itemValue}>{formattedCommission}</span>
          </div>
          {modalOffer && (
            <div className={css.disclaimer}>
              <div>
                <FormattedMessage id="PricingToolTip.bookingFeeDescriptionLandowner" />
              </div>
            </div>
          )}
        </>
      );
    }
  }

  return commissionItem;
};

LineItemProviderCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  onClick: func.isRequired,
};

export default LineItemProviderCommissionMaybe;
