import React from 'react';
import { bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';
import { commissionRateMap } from '../../marketplace-custom-config';

import { ChangeCommissionForm } from '../../forms';

const CommissionUpdate = ({ currentUser, onSubmit, updateInProgress }) => {
  const user = ensureCurrentUser(currentUser);

  const currentCommission = get(
    user,
    'attributes.profile.privateData.commission.relationship',
    'standard'
  );

  const getSubmissionValues = values => {
    const selectedCommission = commissionRateMap.find(value => {
      return value.key === values?.commissionRate;
    });

    return {
      privateData: {
        commission: {
          commissionRate: selectedCommission?.commission,
          relationship: selectedCommission?.key,
        },
      },
      publicData: {
        landownerRelationship: selectedCommission?.key,
      },
    };
  };

  return (
    <div>
      <ChangeCommissionForm
        initialValues={{
          commissionRate: currentCommission,
        }}
        onSubmit={values => {
          onSubmit(getSubmissionValues(values));
        }}
        updateInProgress={updateInProgress}
        currentUser={currentUser}
      />
    </div>
  );
};

CommissionUpdate.defaultProps = {
  currentUser: null,
  onSubmit: null,
  updateInProgress: null,
};

CommissionUpdate.propTypes = {
  currentUser: object,
  onSubmit: func,
  updateInProgress: bool,
};
CommissionUpdate.displayName = 'CommissionUpdate';

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  injectIntl
)(CommissionUpdate);
