import React, { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { parse, format } from 'date-fns';
import { fetchTransactionsTogether } from '../../ducks/Transaction.duck';
import { PACKAGE_QUANTITY_BADGE_VARIANTS } from '../PackageQuantityBadge/PackageQuantityBadge';
import { ReactComponent as IconOpenLink } from '../../assets/icons/icon-open-link.svg';
import { ReactComponent as IconCancel } from '../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg';
import css from './InboxAction.css';
import { createListingLink } from '../TransactionListingHeading/TransactionListingHeading';
import { getPackage, getLodgingPricesOptions } from '../../util/listing';
import {
  getStartingPrice,
  checkPackageAvailabilty,
  isPackageOnWaitingList,
  getPackageLodgingPrices,
  LODGING_INFO,
} from '../../util/package';
import BookingDetails from '../TransactionPanel/BookingDetails';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import { createSlug } from '../../util/urlHelpers';
import TrustCard from '../TrustCard/TrustCard';
import { ensureUser, userDisplayNameAsString } from '../../util/data';
import TripInsuranceDetails from '../TripInsuranceDetails/TripInsuranceDetails';
import PricingDetails from '../TransactionPanel/PricingDetails';
import {
  BookingPanel,
  PackagePricingPanel,
  PackageQuantityBadge,
  PackageAvailabilityModal,
  PackageAvailabilityNotifyButton,
  SpecialOfferPanel,
  UserDisplayName,
  PrimaryButton,
} from '..';
import { updateWaitingList } from '../../ducks/user.duck';
import { formatMoney } from '../../util/currency';
import { createPackageCheckoutLink } from '../TransactionPanel/TransactionPanel.helpers';
import { TRANSITION_ENQUIRE_EXPIRE, TRANSITION_ENQUIRE_GATED } from '../../util/transaction';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';

  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const InboxAction = ({
  intl,
  currentTransaction,
  currentUser,
  updatingWaitingListInProgress,
  currentUserInit,
  currentListing,
  transactionState,
  onFetchTransactionsTogether,
  currentUsersHaveOrdersTogether,
  transactionRole,
  timeSlots,
  fetchTimeSlotsError,
  onUpdateWaitingList,
  onSubmitSpecialOffer,
  sendMessageInProgress,
  sendMessageError,
  onSendMessage,
  initiateOrderError,
  onChangeBookingDetails,
}) => {
  const [isPackageAvailabilityModalOpen, setIsPackageAvailabilityModalOpen] = useState(false);
  const { stripeConnected } = currentUser.attributes;

  const currentTransition = get(currentTransaction, 'attributes.lastTransition', null);
  const packageId =
    get(currentTransaction, 'attributes.protectedData.selectedPackage.packageId', null) ||
    get(currentTransaction, 'attributes.protectedData.packageLineItem.id', null);
  const selectedActivity = get(
    currentTransaction,
    'attributes.protectedData.selectedPackage.packageActivity',
    null
  );
  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);
  const currentPackage = getPackage(currentListing, packageId);
  const { optionalLodging, includedLodging } = getPackageLodgingPrices(
    currentPackage,
    lodgingPricesOptions
  );
  const { bookingRangeIsFuture, bookingRangeIsPast, isAvailable } = checkPackageAvailabilty(
    currentPackage
  );

  const { availableDateFrom } = currentPackage || {};

  const date = parse(availableDateFrom);
  const formattedPackageAvailableDate = format(date, 'MMMM D, YYYY');

  const outOfBookingRange = bookingRangeIsFuture || bookingRangeIsPast;

  const { days, guests, hasLodging: packageHasLodging } = currentPackage || {};
  const packageTitle = currentPackage && currentPackage.title;

  const title = packageTitle || get(currentListing, 'attributes.title', '');

  const listingLink = createListingLink(
    currentListing,
    title,
    currentPackage,
    {},
    css.listingTitleLink
  );

  const handleChangeBookingDetails = () => {
    onChangeBookingDetails();
  };

  const isStartingPrice =
    days?.min !== days?.max ||
    guests?.min !== guests?.max ||
    packageHasLodging === LODGING_INFO.OPTIONAL;

  const isOnWaitingList = isPackageOnWaitingList(currentUser, currentPackage?.id);
  const landownerName = get(currentListing, 'author.attributes.profile.displayName', 'Landowner');

  const renderAvailabilityBadges = isShortVersion => {
    let badge;

    if (!isAvailable) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.DANGER,
        text: isShortVersion ? (
          <FormattedMessage
            id="PackagePricingPanel.soldOutSimple"
            values={{ date: formattedPackageAvailableDate }}
          />
        ) : (
          <FormattedMessage id="PackagePage.soldOutInquiry" values={{ landowner: landownerName }} />
        ),
      };
    } else if (outOfBookingRange) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.WARNING,
        text: isShortVersion ? (
          <FormattedMessage
            id="PackagePricingPanel.outOfRange"
            values={{ date: formattedPackageAvailableDate }}
          />
        ) : (
          <FormattedMessage
            id="PackagePage.outOfBookingRange"
            values={{ date: formattedPackageAvailableDate, landowner: landownerName }}
          />
        ),
      };
    }

    if (!badge) {
      return null;
    }

    return (
      <div className={css.badgeWrapper}>
        <PackageQuantityBadge variant={badge.variant} size="large" text={badge.text} />
      </div>
    );
  };

  const showCancelButton =
    transactionState.showCancelButtonCustomerWithRefund ||
    transactionState.showCancelOffer ||
    transactionState.showCancelButtonCustomerWithoutRefund ||
    transactionState.showCancelButtonCustomerLateCancel ||
    transactionState.showCancelButtonProviderEarlyCancel ||
    transactionState.showCancelButtonProviderCancel ||
    transactionState.showCancelButtonProviderLateCancel;

  const cancelButton = (
    <>
      <LinkWithIcon
        icon={IconCancel}
        onClick={() => {}}
        label={intl.formatMessage({
          id: transactionState.showCancelOffer
            ? 'TransactionPanel.cancelOfferButton'
            : 'TransactionPanel.cancelTripButton',
        })}
      />
    </>
  );

  const renderSidebarLinks = () => {
    if (
      !transactionState.showViewPackage &&
      !transactionState.showBookingChangesLinkCustomer &&
      !showCancelButton
    ) {
      return null;
    }

    return (
      <div className={css.linkIconsWrapper}>
        {transactionState.showViewPackage && (
          <LinkWithIcon
            icon={IconOpenLink}
            name="PackagePage"
            params={{
              id: currentListing.id.uuid,
              slug: createSlug(title),
              packageId,
            }}
            label={intl.formatMessage({ id: 'TransactionPanel.viewPackage' })}
          />
        )}

        {transactionState.showBookingChangesLinkCustomer && (
          <LinkWithIcon
            icon={IconEdit}
            onClick={handleChangeBookingDetails}
            label={intl.formatMessage({ id: 'TransactionPanel.changeBookingDetails' })}
          />
        )}

        {showCancelButton && <>{cancelButton}</>}
      </div>
    );
  };

  const sidebarLinks = renderSidebarLinks();

  const currentProvider = ensureUser(currentTransaction.provider);
  const currentCustomer = ensureUser(currentTransaction.customer);

  const { tripInsurance } = currentTransaction?.attributes?.metadata || {};
  const insuranceOffered = tripInsurance !== undefined;
  const userIsGuest = currentCustomer?.id?.uuid === currentUser?.id?.uuid;

  const inquiryGated = currentTransition === TRANSITION_ENQUIRE_GATED;
  const inquiryExpired = currentTransition === TRANSITION_ENQUIRE_EXPIRE;
  const isInquiry = inquiryGated || inquiryExpired;

  const packageCheckoutLink = currentPackage
    ? createPackageCheckoutLink(
        currentListing,
        <PrimaryButton isAnimating>
          <FormattedMessage id="PackagePage.checkAvailability" />
        </PrimaryButton>,
        currentPackage.id,
        currentTransaction
      )
    : null;

  const startingPrice = getStartingPrice({
    ...currentPackage,
    optionalLodging,
    includedLodging,
  });

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPanel.deletedListingTitle',
  });

  const { otherUserDisplayNameString } = displayNames(
    currentUser,
    currentProvider,
    currentCustomer,
    intl
  );

  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  const bookingSubTitle = '';

  const sendMessagePlaceholder = intl.formatMessage(
    { id: 'TransactionPanel.sendMessagePlaceholder' },
    { name: otherUserDisplayNameString }
  );

  const onMessageSubmit = (values, form) => {
    const message = values.message ? values.message.trim() : null;

    if (!message) {
      return;
    }
    onSendMessage(currentTransaction.id, message)
      .then(() => {
        form.reset();
      })
      .catch(() => {
        // Ignore, Redux handles the error
      });
  };

  return (
    <div className={css.root}>
      <PackageAvailabilityModal
        id="package-pricing-availability-modal"
        isOpen={isPackageAvailabilityModalOpen}
        onClose={() => {
          setIsPackageAvailabilityModalOpen(false);
        }}
        isSoldOut={!isAvailable}
        isOutOfBookingRange={outOfBookingRange}
        packageAvailableDate={formattedPackageAvailableDate}
        landownerName={landownerName}
        onNotify={() => {
          onUpdateWaitingList(currentPackage.id, currentListing.id.uuid);
        }}
        isOnWaitingList={isOnWaitingList}
        isFetching={updatingWaitingListInProgress}
        isEnquiry
      />

      <div className={css.listingTitle}>{listingLink}</div>

      {transactionState.showBookingDetails && (
        <div className={css.bookingDetails}>
          <BookingDetails
            transaction={currentTransaction}
            stateData={transactionState}
            currentPackage={currentPackage}
          />
        </div>
      )}

      {sidebarLinks && (
        <div className={css.bookingDetails}>
          <div className={css.divider} />

          {sidebarLinks}
        </div>
      )}

      {transactionState.showBookingDetails && (
        <div className={css.bookingDetails}>
          <div className={css.divider} />
          <TrustCard
            landowner={currentProvider}
            sportsman={currentCustomer}
            currentUser={currentUser}
            currentTransaction={currentTransaction}
            onFetchTransactionsTogether={onFetchTransactionsTogether}
            currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
            insuranceOffered={insuranceOffered}
          />
        </div>
      )}

      {transactionState.showBookingDetails && insuranceOffered && userIsGuest && (
        <div className={css.bookingDetails}>
          <div className={css.divider} />

          <TripInsuranceDetails
            guest={currentCustomer}
            currentUser={currentUser}
            transaction={currentTransaction}
          />
        </div>
      )}

      {transactionState.showBookingDetails && !isInquiry && (
        <>
          <div className={css.divider} />
          <div className={css.pricingDetailsDesktop}>
            <PricingDetails transaction={currentTransaction} userRole={transactionRole} />
          </div>
        </>
      )}

      <div>
        {transactionState.showBookingPanel && currentPackage && (
          <>
            <div className={css.pricingPackagePanel}>
              <PackagePricingPanel
                currentUserInit={currentUserInit}
                rootClassName={css.pricingPanel}
                packageInfo={currentPackage}
                listing={currentListing}
                timeSlots={timeSlots}
                startingPrice={startingPrice}
                currentUser={currentUser}
                transaction={currentTransaction}
                availabilityPackageBadges={renderAvailabilityBadges(true)}
                isAvailable={isAvailable}
                outOfBookingRange={outOfBookingRange}
                bookingRangeIsFuture={bookingRangeIsFuture}
                formattedPackageAvailableDate={formattedPackageAvailableDate}
                isOnWaitingList={isOnWaitingList}
                setIsPackageAvailabilityModalOpen={isOpen => {
                  setIsPackageAvailabilityModalOpen(isOpen);
                }}
              />
            </div>
            <div className={css.divider} />
            {isAvailable && !outOfBookingRange ? (
              <div className={css.mobileCheckAvailability}>
                <div className={css.mobileCheckAvailabilityPrice}>
                  <FormattedMessage
                    id={isStartingPrice ? 'PackagePage.startingPrice' : 'PackagePage.price'}
                  />
                  {startingPrice?.amount && (
                    <FormattedHTMLMessage
                      id="PackageCard.perGuest"
                      values={{ price: formatMoney(intl, startingPrice) }}
                    />
                  )}{' '}
                </div>

                {packageCheckoutLink}
              </div>
            ) : (
              <div className={css.notifyMobileContainer}>
                {isOnWaitingList ? (
                  <div className={css.getNotified}>
                    <b>
                      <FormattedMessage id="PackagePage.waitingListNotified" />
                    </b>
                  </div>
                ) : (
                  <>
                    <PackageAvailabilityNotifyButton
                      onClick={() => {
                        setIsPackageAvailabilityModalOpen(true);
                      }}
                    />

                    <div className={css.getNotified}>
                      <FormattedMessage id="PackagePricingPanel.soldOutgetNotified" />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className={css.bookingDetailsDesktop}>
              <TrustCard
                landowner={currentProvider}
                sportsman={currentCustomer}
                currentUser={currentUser}
                currentTransaction={currentTransaction}
                onFetchTransactionsTogether={onFetchTransactionsTogether}
                currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
                insuranceOffered={insuranceOffered}
              />
            </div>
            {insuranceOffered && userIsGuest && (
              <div className={css.bookingDetailsDesktop}>
                <TripInsuranceDetails
                  guest={currentCustomer}
                  currentUser={currentUser}
                  transaction={currentTransaction}
                />
              </div>
            )}
          </>
        )}

        {transactionState.showBookingPanel && !currentPackage ? (
          <BookingPanel
            className={css.bookingPanel}
            listing={currentListing}
            isOwnListing={false}
            currentActivity={selectedActivity}
          />
        ) : null}

        {transactionState.showSpecialOffer ? (
          <SpecialOfferPanel
            className={css.bookingPanel}
            titleClassName={css.bookingTitle}
            timeSlots={timeSlots}
            onSubmit={onSubmitSpecialOffer}
            sportsmanName={otherUserDisplayNameString.split(' ')[0]}
            formForMessage="TransactionPanel.SendMessageForm"
            listing={currentListing}
            fetchTimeSlotsError={fetchTimeSlotsError}
            subTitle={bookingSubTitle}
            title={listingTitle}
            transaction={currentTransaction}
            messageootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            messageinProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            messageonFocus={() => {}}
            messageonBlur={() => {}}
            messageonSubmit={onMessageSubmit}
            initiateOrderError={initiateOrderError}
            stripeConnected={stripeConnected}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUsersHaveOrdersTogether } = state.Transaction;
  return { currentUsersHaveOrdersTogether };
};

const mapDispatchToProps = dispatch => ({
  onFetchTransactionsTogether: id => dispatch(fetchTransactionsTogether(id)),
  onUpdateWaitingList: (packageId, listingId) => dispatch(updateWaitingList(packageId, listingId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(InboxAction));
