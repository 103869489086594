/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';
import config from '../../config';
import { propTypes } from '../../util/types';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldCondition,
  EditListingActions,
  EditListingError,
  FieldToggle,
  FieldCurrencyInput,
  FieldPillInputGroup,
  FieldLabel,
  FieldNumberInput,
  SecondaryButton,
} from '../../components';
import { ReactComponent as PlusCircle } from '../../assets/icons/plus-circle-solid.svg';
import {
  rvParkingOptions,
  rvTypesOptions,
  rvAccessOptions,
  rvRoadQualityOptions,
  rvElectricHookupOptions,
  rvSewageHookupOptions,
  tentCookingAreaOptions,
  garbageAreaOptions,
  rvAmenitiesOptions,
  campfireOptions,
} from '../../marketplace-custom-config';

import css from './EditListingCampsiteForm.css';

export const EditListingCampsiteFormComponent = ({ backLink, ...props }) => {
  const { intl } = props;

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          emptyState,
          rvEmptyState,
          form: {
            mutators: { push },
          },
        } = formRenderProps;

        const classes = classNames(className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const canAddNewCampsite = formRenderProps.values.campsites[0]?.rv?.hasCampsite;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />
            </EditListingSection>

            <FieldArray name="campsites">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={name}>
                    {index === 0 ? (
                      <EditListingSection
                        hasBackground
                        title={intl.formatMessage({
                          id: 'EditListingCampsiteForm.allowTentsTitle',
                        })}
                        hasBottomMargin
                      >
                        <FieldToggle name={`${name}.allowsTents`} />

                        <FieldCondition when={`${name}.allowsTents`} is>
                          <EditListingSection hasBackground isFullWidth>
                            <EditListingFieldWrapper>
                              <FieldToggle
                                name={`${name}.tent.hasDrinkingWater`}
                                label={intl.formatMessage({
                                  id: 'EditListingCampsiteForm.tentAccessToWaterTitle',
                                })}
                              />
                            </EditListingFieldWrapper>

                            <EditListingFieldWrapper>
                              <FieldTextInput
                                id={`tentInfo-${index}`}
                                name={`${name}.tent.info`}
                                type="textarea"
                                label={intl.formatMessage({
                                  id: 'EditListingCampsiteForm.tentInfo',
                                })}
                                labelDescription={intl.formatMessage({
                                  id: 'EditListingCampsiteForm.tentSubInfo',
                                })}
                              />
                            </EditListingFieldWrapper>

                            <EditListingFieldWrapper>
                              <FieldCurrencyInput
                                className={css.fieldInputPrice}
                                id={`tentPrice-${index}`}
                                name={`${name}.tent.price`}
                                label={intl.formatMessage({
                                  id: 'EditListingCampsiteForm.tentPriceFieldLabel',
                                })}
                                postfixLabel={intl.formatMessage({
                                  id: 'EditListingCampsiteForm.tentPriceFieldPostfix',
                                })}
                                placeholder="$0.00"
                                currencyConfig={config.currencyConfig}
                              />
                            </EditListingFieldWrapper>
                          </EditListingSection>
                        </FieldCondition>
                      </EditListingSection>
                    ) : null}

                    <EditListingSection
                      hasBackground
                      title={intl.formatMessage({ id: 'EditListingCampsiteForm.allowRvsTitle' })}
                      hasBottomMargin
                    >
                      <FieldToggle name={`${name}.allowsRvs`} />

                      <FieldCondition when={`${name}.allowsRvs`} is>
                        <EditListingSection
                          title={intl.formatMessage({
                            id: 'EditListingCampsiteForm.hasCampsiteTitle',
                          })}
                          hasBottomMargin
                          hasTopMargin
                          isFullWidth
                        >
                          <FieldToggle name={`${name}.rv.hasCampsite`} />
                          <FieldCondition when={`${name}.rv.hasCampsite`} is>
                            <EditListingSection hasBackground isFullWidth>
                              <EditListingFieldWrapper>
                                <FieldTextInput
                                  autoComplete="off"
                                  id={`campsiteName-${index}`}
                                  name={`${name}.rv.campsiteName`}
                                  type="text"
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.campsiteNameTitle',
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.campsiteNamePlaceholder',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldPillInputGroup
                                  name={`${name}.rv.parking`}
                                  id={`rvParking-${index}`}
                                  options={rvParkingOptions}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.surfaceTitle',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.isSurfaceLevel`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.isSurfaceLevelLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.isSurfaceLevel`} is>
                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    id={`rvIsSurfaceInfo-${index}`}
                                    name={`${name}.rv.surfaceInfo`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldLabel>
                                  <FormattedMessage id="EditListingCampsiteForm.rvSurfaceSizeTitle" />
                                </FieldLabel>

                                <div className={css.fieldSurfaceSizeWrapper}>
                                  <FieldNumberInput
                                    asRow
                                    min={0}
                                    max={500}
                                    className={css.fieldSurfaceSize}
                                    id={`rvSurfaceSizeLength-${index}`}
                                    name={`${name}.rv.surfaceSizeLength`}
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.rvSurfaceSizeLengthLabel',
                                    })}
                                    hideButtons
                                    unit="ft"
                                  />

                                  <FieldNumberInput
                                    asRow
                                    min={0}
                                    max={500}
                                    className={css.fieldSurfaceSize}
                                    id={`rvSurfaceSizeWidth-${index}`}
                                    name={`${name}.rv.surfaceSizeWidth`}
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.rvSurfaceSizeWidthLabel',
                                    })}
                                    hideButtons
                                    unit="ft"
                                  />
                                </div>
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldPillInputGroup
                                  name={`${name}.rv.vehicleTypes`}
                                  id={`rvTypes-${index}`}
                                  options={rvTypesOptions}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvTypesLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldLabel>
                                  <FormattedMessage id="EditListingCampsiteForm.rvMaxLengthTitle" />
                                </FieldLabel>

                                <div className={css.fieldSurfaceSizeWrapper}>
                                  <FieldNumberInput
                                    asRow
                                    min={0}
                                    max={500}
                                    className={css.fieldSurfaceSize}
                                    id={`rvMaxLength-${index}`}
                                    name={`${name}.rv.maxLength`}
                                    hideButtons
                                    unit="ft"
                                  />
                                </div>
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldPillInputGroup
                                  name={`${name}.rv.access`}
                                  id={`rvAccess-${index}`}
                                  options={rvAccessOptions}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvAccessTitle',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldPillInputGroup
                                  name={`${name}.rv.roadQuality`}
                                  id={`rvRoadQuality-${index}`}
                                  options={rvRoadQualityOptions}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvRoadQualityLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.is4WDRequired`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvIs4WDRequiredLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldTextInput
                                  id={`rvAccessLabelInfo-${index}`}
                                  name={`${name}.rv.accessInfo`}
                                  type="textarea"
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.additionalAccessingInfoLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.hasElectricHookup`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvHasElectricHookupLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.hasElectricHookup`} is>
                                <EditListingFieldWrapper>
                                  <FieldPillInputGroup
                                    name={`${name}.rv.electricHookup`}
                                    id={`rvElectricHookup-${index}`}
                                    options={rvElectricHookupOptions}
                                  />
                                </EditListingFieldWrapper>

                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.electricHookupInfo`}
                                    id={`rvElectricHookupInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.hasWaterHookup`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvHasWaterHookupLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.hasWaterHookup`} is>
                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.waterHookupInfo`}
                                    id={`rvWaterHookupInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.hasSewageHookup`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvHasSewageHookupLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.hasSewageHookup`} is>
                                <EditListingFieldWrapper>
                                  <FieldPillInputGroup
                                    name={`${name}.rv.sewageHookup`}
                                    id={`rvSewageHookup-${index}`}
                                    options={rvSewageHookupOptions}
                                  />
                                </EditListingFieldWrapper>

                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.sewageHookupInfo`}
                                    id={`rvSewageHookupInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.allowsGenerators`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvAllowsGeneratorsLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.allowsGenerators`} is>
                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.generatorsInfo`}
                                    id={`rvAllowsGeneratorsInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.hasCookingArea`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvHasCookingLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.hasCookingArea`} is>
                                <EditListingFieldWrapper>
                                  <FieldPillInputGroup
                                    name={`${name}.rv.cookingArea`}
                                    id={`rvCookingArea-${index}`}
                                    options={tentCookingAreaOptions}
                                  />
                                </EditListingFieldWrapper>

                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.cookingAreaInfo`}
                                    id={`rvHasCookingInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.hasGarbage`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvHasGarbageLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.hasGarbage`} is>
                                <EditListingFieldWrapper>
                                  <FieldPillInputGroup
                                    name={`${name}.rv.garbage`}
                                    id={`rvGarbageArea-${index}`}
                                    options={garbageAreaOptions}
                                  />
                                </EditListingFieldWrapper>

                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.garbageInfo`}
                                    id={`rvHasGarbageInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldToggle
                                  name={`${name}.rv.areCampfiresAllowed`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvCampfiresAllowedLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <FieldCondition when={`${name}.rv.areCampfiresAllowed`} is>
                                <EditListingFieldWrapper>
                                  <FieldPillInputGroup
                                    name={`${name}.rv.campfires`}
                                    id={`rvCampfires-${index}`}
                                    options={campfireOptions}
                                  />
                                </EditListingFieldWrapper>

                                <EditListingFieldWrapper>
                                  <FieldTextInput
                                    name={`${name}.rv.campfiresInfo`}
                                    id={`rvCampfiresAllowedInfo-${index}`}
                                    type="textarea"
                                    label={intl.formatMessage({
                                      id: 'EditListingCampsiteForm.additionalInfoLabel',
                                    })}
                                  />
                                </EditListingFieldWrapper>
                              </FieldCondition>

                              <EditListingFieldWrapper>
                                <FieldPillInputGroup
                                  name={`${name}.rv.amenities`}
                                  id={`rvAmenities-${index}`}
                                  options={rvAmenitiesOptions}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvAmenitiesLabel',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldCurrencyInput
                                  className={css.fieldInputPrice}
                                  id={`rvPrice-${index}`}
                                  name={`${name}.rv.price`}
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvPriceFieldLabel',
                                  })}
                                  postfixLabel={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvPriceFieldPostfix',
                                  })}
                                  placeholder="$0.00"
                                  currencyConfig={config.currencyConfig}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldTextInput
                                  autoComplete="off"
                                  id={`campsiteLatLng-${index}`}
                                  name={`${name}.rv.latLng`}
                                  type="text"
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.latLngTitle',
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.latLngTitlePlaceholder',
                                  })}
                                />
                              </EditListingFieldWrapper>

                              <EditListingFieldWrapper>
                                <FieldTextInput
                                  id={`rvInfo-${index}`}
                                  name={`${name}.rv.info`}
                                  type="textarea"
                                  label={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvInfoLabel',
                                  })}
                                  labelDescription={intl.formatMessage({
                                    id: 'EditListingCampsiteForm.rvSubInfo',
                                  })}
                                />
                              </EditListingFieldWrapper>
                            </EditListingSection>
                          </FieldCondition>
                        </EditListingSection>
                      </FieldCondition>

                      <FieldCondition when={`${name}.rv.hasCampsite`} is>
                        <Link
                          className={css.removeCampsiteLink}
                          onClick={e => {
                            e.preventDefault();

                            if (fields.value.length > 1) {
                              fields.remove(index);
                            } else {
                              const newCampsites = [
                                {
                                  ...formRenderProps.values.campsites[0],
                                  ...rvEmptyState,
                                },
                              ];

                              formRenderProps.form.change('campsites', newCampsites);
                            }
                          }}
                        >
                          <FormattedMessage id="EditListingCampsiteForm.removeCampsite" />
                        </Link>
                      </FieldCondition>
                    </EditListingSection>
                  </div>
                ))
              }
            </FieldArray>

            {canAddNewCampsite && (
              <EditListingSection isFullWidth className={css.addButtonWrapper}>
                <SecondaryButton
                  type="button"
                  onClick={() =>
                    push('campsites', {
                      ...emptyState,
                      allowsRvs: true,
                      rv: {
                        ...emptyState.rv,
                        hasCampsite: true,
                      },
                    })
                  }
                  className={css.addButton}
                >
                  <div className={css.buttonFlex}>
                    <PlusCircle className={css.plusCircle} />
                    <div>
                      <FormattedMessage id="EditListingCampsiteForm.addCampsite" />
                    </div>
                  </div>
                </SecondaryButton>
              </EditListingSection>
            )}

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingCampsiteFormComponent.defaultProps = {
  campsites: null,
  fetchErrors: null,
};

EditListingCampsiteFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  campsites: arrayOf(propTypes.object),
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingCampsiteFormComponent);
