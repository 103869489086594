import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing } from '../../util/data';
import { getLodgingTax } from '../../util/listing';
import config from '../../config';
import EstimatedBreakdownMaybe from '../../forms/BookingDatesForm/EstimatedBreakdownMaybe';
import { LODGING_INFO } from '../../util/package';

import css from './InquiryBreakdown.css';

const { Money } = sdkTypes;

const InquiryBreakdown = ({ listing, formValues }) => {
  const currentListing = ensureListing(listing);
  const { price: listingPrice } = currentListing.attributes;
  const { bookingDates, partySize, selectedPackage, lodgingAddOn } = formValues || {};
  const { hasLodging } = selectedPackage;

  const { startDate, endDate } = bookingDates || {};

  const lodgingTax = getLodgingTax(listing, selectedPackage.id);
  const packageMinParty = get(selectedPackage, 'guests.min', 1);

  let packageItem = null;
  const speciesFeeCalc = {};

  const packagePrice = selectedPackage.price;
  const lodgingPrice = selectedPackage?.lodgingPrice && selectedPackage.lodgingPrice;
  const packageFee = new Money(packagePrice.amount, config.currency);
  const lodgingFee = lodgingPrice?.amount && new Money(lodgingPrice?.amount, config.currency);

  const includeLodging = lodgingAddOn?.length > 0 || hasLodging === LODGING_INFO.REQUIRED;

  packageItem = {
    code: `line-item/${selectedPackage.title.replace(/\s/g, '-')}`,
    title: selectedPackage.title,
    includeFor: ['customer', 'provider'],
    unitPrice: packageFee,
    quantity: new Decimal(1),
    lineTotal: packageFee,
    reversal: false,
    donation: {
      package: true,
    },
    guestSize: partySize ? parseInt(partySize, 10) : packageMinParty,
    activity: selectedPackage.activity,
    hasLodging: includeLodging,
    lodgingFee,
  };

  const bookingData = {
    unitType: config.bookingUnitType,
    unitPrice: listingPrice,
    startDate,
    endDate,
    quantity: 1,
    partySize,
    packageItem,
    lodgingTax: includeLodging && lodgingTax,
    ...speciesFeeCalc,
  };

  return (
    <div className={css.root}>
      <EstimatedBreakdownMaybe
        bookingData={bookingData}
        listing={listing}
        totalLabel={<FormattedMessage id="InquiryWizard.estimatedTotal" />}
      />
    </div>
  );
};

export default injectIntl(InquiryBreakdown);
