import React, { useMemo } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import moment from 'moment-timezone';
import { Form as FinalForm } from 'react-final-form';

import { composeValidators, required } from '../../util/validators';
import { getBookingDates } from '../../util/transaction';
import { daysAdded, createTimeSlots } from '../../util/dates';

import {
  Form,
  FieldDates,
  FieldPartySizeSelect,
  PrimaryButton,
  SecondaryButton,
  FieldLabel,
} from '..';

import css from './BookingChanges.css';

const BookingDetails = ({
  currentPackage,
  currentTransaction,
  timeSlots,
  setNewGuestSize,
  setNewBookingDates,
  onSubmit,
  inProgress,
  hasCustomerRequestedChanges,
  isProvider,
  onClose,
  onAccept,
  acceptChangesInProgress,
  onDecline,
  declineChangesInProgress,
  intl,
  pricingDetails,
  errorMessage,
}) => {
  const packageMinParty = get(currentPackage, 'guests.min', 1);
  const packageMaxParty = get(currentPackage, 'guests.max', 1);
  const days = get(currentPackage, 'days', {});
  const guestSize = get(
    currentTransaction,
    'attributes.protectedData.packageLineItem.guestSize',
    1
  );
  const originalMinDays = get(currentTransaction, 'attributes.protectedData.bookingDates.days', 1);
  const originalStart = get(
    currentTransaction,
    'attributes.protectedData.bookingDates.start',
    null
  );
  const originalEnd = get(currentTransaction, 'attributes.protectedData.bookingDates.end', null);

  let calendarInfoDays;

  if (originalMinDays === days.max) {
    calendarInfoDays = originalMinDays;
  } else if (days.max === 365) {
    calendarInfoDays = `${originalMinDays}+`;
  } else {
    calendarInfoDays = `${originalMinDays}-${days.max}`;
  }

  const { bookingStart, bookingEnd } = getBookingDates(currentTransaction);

  const numberOfDays = daysAdded(bookingStart, bookingEnd);

  const isSingleDay = numberOfDays === 1;
  let bookingDatesText;

  if (isSingleDay) {
    bookingDatesText = `${bookingStart.format('MMM DD, YYYY')}`;
  } else {
    bookingDatesText = `${bookingStart.format('MMM DD')} - ${bookingEnd.format('MMM DD, YYYY')}`;
  }

  const newTimeSlots = createTimeSlots(bookingStart, numberOfDays) || [];
  const updatedTimeSlots = (timeSlots || []).concat(newTimeSlots);

  const endDatePart = originalEnd.split('T')[0];
  const startDatePart = originalStart.split('T')[0];

  const end = moment(endDatePart).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  const start = moment(startDatePart).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

  const initialValues = useMemo(() => {
    return {
      partySize: guestSize,
      dates: {
        startDate: start.toDate(),
        endDate: end.toDate(),
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let newPartySize;
  let newDates;

  if (hasCustomerRequestedChanges || isProvider) {
    const bookingChangesValues = get(
      currentTransaction,
      'attributes.protectedData.bookingChanges.newValues',
      {}
    );
    newPartySize = bookingChangesValues.partySize;

    newDates = {
      startDate: new Date(JSON.parse(bookingChangesValues.dates.startDate)),
      endDate: new Date(JSON.parse(bookingChangesValues.dates.endDate)),
    };
  }

  return (
    <FinalForm
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, initialValues)}
      render={fieldRenderProps => {
        const { handleSubmit, form, values } = fieldRenderProps;

        let bookingDateText = <FormattedMessage id="CheckoutPage.datesFieldPlaceholderPackage" />;
        const dateValues =
          hasCustomerRequestedChanges || isProvider ? newDates : values && values.dates;

        const isSelectedSingleDay = daysAdded(dateValues.startDate, dateValues.endDate) === 1;

        if (dateValues) {
          bookingDateText = `${format(dateValues.startDate, 'MMM DD, YYYY')} - ${format(
            dateValues.endDate,
            'MMM DD, YYYY'
          )}`;
        }
        if (dateValues && isSelectedSingleDay) {
          bookingDateText = format(dateValues.startDate, 'MMM DD, YYYY');
        }

        const partySizeChanged =
          hasCustomerRequestedChanges || isProvider
            ? newPartySize !== initialValues.partySize
            : parseInt(values.partySize, 10) !== initialValues.partySize;

        const datesChanged =
          moment(dateValues.startDate).format('YYYY-MM-DD') !==
            moment(initialValues.dates.startDate).format('YYYY-MM-DD') ||
          moment(dateValues.endDate).format('YYYY-MM-DD') !==
            moment(initialValues.dates.endDate).format('YYYY-MM-DD');

        const submitDisabled = !partySizeChanged && !datesChanged;

        return (
          <Form onSubmit={handleSubmit} className={css.bookingDetails}>
            <div className={css.bookingDetailsContainer}>
              <div className={css.bookingDetailsHeader}>
                <FormattedMessage id="BookingChanges.bookingDetails" />
              </div>
            </div>

            <div className={css.formFieldWrapper}>
              {hasCustomerRequestedChanges || isProvider ? (
                <>
                  {partySizeChanged ? (
                    <>
                      <FieldLabel>
                        <FormattedMessage id="BookingChanges.newPartySize" />
                      </FieldLabel>

                      <div className={css.formFieldValue}>
                        <FormattedMessage
                          id="FieldPartySizeSelect.guestOption"
                          values={{
                            guests: newPartySize,
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <FieldPartySizeSelect
                  id="partySizeBookingChange"
                  label={
                    partySizeChanged ? (
                      intl.formatMessage({ id: 'BookingChanges.newPartySize' })
                    ) : (
                      <FormattedMessage id="BookingChanges.originalPartySize" />
                    )
                  }
                  name="partySize"
                  minPartySize={guestSize || packageMinParty}
                  maxPartySize={packageMaxParty}
                  price={JSON.parse(currentPackage.price)}
                  parse={value => (value === '' ? null : Number(value))}
                  format={value => String(value)}
                  validate={composeValidators(
                    required(intl.formatMessage({ id: 'PricingPage.partySizeRequired' }))
                  )}
                  onChange={partySize => {
                    setNewGuestSize(partySize.target.value);
                    form.change('partySize', partySize.target.value);
                  }}
                />
              )}

              {partySizeChanged && (
                <div className={css.formFieldHelper}>
                  <FormattedMessage id="BookingChanges.original" />{' '}
                  <FormattedMessage
                    id="FieldPartySizeSelect.guestOption"
                    values={{
                      guests: guestSize,
                    }}
                  />
                </div>
              )}
            </div>

            <div className={css.formFieldWrapper}>
              {hasCustomerRequestedChanges || isProvider ? (
                <>
                  {datesChanged ? (
                    <>
                      <FieldLabel>
                        <FormattedMessage id="BookingChanges.newDates" />
                      </FieldLabel>

                      <div className={css.formFieldValue}>{bookingDateText}</div>
                    </>
                  ) : null}
                </>
              ) : (
                <FieldDates
                  placement="top-end"
                  fieldDateProps={{
                    endDateOffset:
                      originalMinDays === currentPackage.days.max
                        ? day => day.add(originalMinDays - 1, 'days')
                        : undefined,
                    minimumNights:
                      originalMinDays !== currentPackage.days.max && originalMinDays - 1,
                    maximumNights:
                      currentPackage.days.max > 0 && originalMinDays !== currentPackage.days.max
                        ? currentPackage.days.max - 1
                        : undefined,
                    calendarInfo: (
                      <div className={css.dateInfo}>
                        <div className={css.dateInfoTitle}>
                          <FormattedMessage
                            id="PricingPage.datesFieldInfoPackageTitle"
                            values={{ days: calendarInfoDays }}
                          />
                        </div>
                      </div>
                    ),
                  }}
                  timeSlots={updatedTimeSlots}
                  label={
                    datesChanged ? (
                      <FormattedMessage id="BookingChanges.newDates" />
                    ) : (
                      <FormattedMessage id="BookingChanges.originalDates" />
                    )
                  }
                  text={bookingDateText}
                  onChange={dates => {
                    setNewBookingDates(dates);
                    form.change('dates', dates);
                  }}
                  isPackage
                />
              )}

              {datesChanged && (
                <div className={css.formFieldHelper}>
                  <FormattedMessage id="BookingChanges.original" /> {bookingDatesText}
                </div>
              )}
            </div>

            <div className={css.priceDetailsMobile}>{pricingDetails}</div>

            <div className={css.formFieldWrapper}>
              {errorMessage && (
                <div id="error-message" className={css.errorMessage}>
                  {errorMessage}
                </div>
              )}

              <div className={css.formFieldButtonWrapper}>
                {hasCustomerRequestedChanges || isProvider ? (
                  <>
                    {isProvider ? (
                      <>
                        <PrimaryButton
                          className={css.acceptButton}
                          inProgress={acceptChangesInProgress}
                          type="button"
                          onClick={() => {
                            onAccept();
                          }}
                        >
                          <FormattedMessage id="BookingChanges.acceptButton" />
                        </PrimaryButton>
                        <SecondaryButton
                          inProgress={declineChangesInProgress}
                          type="button"
                          onClick={() => {
                            onDecline();
                          }}
                        >
                          <FormattedMessage id="BookingChanges.declineButton" />
                        </SecondaryButton>
                      </>
                    ) : (
                      <>
                        <PrimaryButton
                          type="button"
                          isFullWidth
                          onClick={() => {
                            onClose();
                          }}
                        >
                          <FormattedMessage id="BookingChanges.returnToBookingButton" />
                        </PrimaryButton>
                      </>
                    )}
                  </>
                ) : (
                  <PrimaryButton
                    type="submit"
                    isFullWidth
                    inProgress={inProgress}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="BookingChanges.sendRequest" />
                  </PrimaryButton>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default BookingDetails;
