import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ActivityDot.css';

const ActivityDot = ({ count, className }) => {
  return <div className={classNames(css.root, className)}>{count}</div>;
};

ActivityDot.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ActivityDot;
