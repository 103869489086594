/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink } from '..';

import css from './TopbarMobileMenu.css';
import Logo from '../Logo/Logo';
import ActivityDot from '../ActivityDot/ActivityDot';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    isPremium,
    activityCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.header}>
          <NamedLink name="LandingPage">
            <Logo format="desktop" size="lg" />
          </NamedLink>
        </div>
        <div className={css.links}>
          <NamedLink name="SignupPage" className={css.link}>
            <FormattedMessage id="TopbarMobileMenu.signupLink" />
          </NamedLink>
          <NamedLink name="LoginPage" className={css.link}>
            <FormattedMessage id="TopbarMobileMenu.loginLink" />
          </NamedLink>
          <NamedLink name="LandownersPage" className={css.link}>
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
          <NamedLink name="ContactPage" className={css.link}>
            <FormattedMessage id="TopbarMobileMenu.help" />
          </NamedLink>
          <NamedLink className={css.link} name="LTPlusPage">
            <FormattedMessage id="TopbarMobileMenu.getLandtrustPlus" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge = null;
  const { profile, email } = user.attributes;
  const { displayName } = profile;
  const role = get(profile, 'protectedData.role', []);
  const isLandowner = role && role.includes('landowner');
  const isSportsman = role && role.includes('sportsman');
  const userId = get(user, 'id.uuid', null);
  const favoriteListings = get(user, 'attributes.profile.publicData.favoriteListings', []);
  const favoritePackages = get(user, 'attributes.profile.publicData.favoritePackages', []);

  const currentPageClass = page => {
    return currentPage === page ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={classNames(css.header, css.headerCenter)}>
        <div className={css.avatarContainer}>
          <AvatarLarge className={css.avatar} user={currentUser} />
          <span className={css.avatarDisplayName}>{displayName}</span>
          <span>{email}</span>
        </div>
      </div>

      <div className={css.links}>
        <NamedLink
          className={classNames(css.link, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>

        <NamedLink
          className={classNames(css.link, currentPageClass('InboxPage'))}
          params={{ slug: isLandowner ? 'bookings' : 'trips' }}
          name="BookingOrTripsPage"
        >
          <FormattedMessage
            id={isLandowner ? 'TopbarMobileMenu.bookingsLink' : 'TopbarMobileMenu.tripsLink'}
          />
        </NamedLink>

        <NamedLink className={classNames(css.link)} name="RecentActivityPage">
          {activityCount > 0 && <ActivityDot count={activityCount} className={css.activityDot} />}
          Recent Activity
        </NamedLink>

        {role.includes('landowner') && (
          <NamedLink
            className={classNames(css.link, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}

        <NamedLink
          className={classNames(css.link, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsBasePage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>

        {userId && (
          <NamedLink
            name="FavoritesPage"
            className={css.link}
            params={{
              id: userId,
              tab:
                favoriteListings.length <= 0 && favoritePackages.length > 0
                  ? 'packages'
                  : 'listings',
            }}
          >
            <FormattedMessage id="TopbarMobileMenu.favoritesLink" />
          </NamedLink>
        )}
        {isAuthenticated && !isLandowner && (
          <NamedLink className={css.link} name="PerksPage">
            <FormattedMessage id="TopbarMobileMenu.hookUpLink" />
          </NamedLink>
        )}

        {isAuthenticated && isLandowner && currentUserHasListings && (
          <NamedLink className={css.link} name="DashboardPage">
            <FormattedMessage id="TopbarMobileMenu.dashboardLink" />
          </NamedLink>
        )}

        {isAuthenticated && isLandowner && !currentUserHasListings && (
          <NamedLink className={css.link} name="LandownersPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        )}

        {!isAuthenticated && (
          <NamedLink className={css.link} name="PerksPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        )}
        <NamedLink name="FAQLandownersPage" className={css.link}>
          <FormattedMessage id="TopbarMobileMenu.help" />
        </NamedLink>

        {!isPremium && (
          <NamedLink className={css.link} name="LTPlusPage">
            <FormattedMessage id="TopbarMobileMenu.getLandtrustPlus" />
          </NamedLink>
        )}
        {(isSportsman || !isAuthenticated) && (
          <NamedLink className={css.link} name="FieldNotesPage">
            <FormattedMessage id="TopbarMobileMenu.fieldNotes" />
          </NamedLink>
        )}
        <InlineTextButton
          rootClassName={classNames(css.link, css.linkSecondary)}
          onClick={onLogout}
        >
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, currentPage: null };

const { bool, func, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
