import React from 'react';
import { node, func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { EditListingLodgingForm } from '../../forms';
import { getInitialValues, getSubmissionValues } from './helpers';

const EditListingLodgingPanel = ({
  rootClassName,
  className,
  listing,
  onSubmit,
  onChange,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  errors,
  backLink,
}) => {
  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingLodgingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingLodgingPanel.createListingTitle" />
  );

  return (
    <EditListingPanel className={classes}>
      <EditListingSection>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingLodgingForm
        initialValues={getInitialValues(listing)}
        onSubmit={values => {
          onSubmit({
            publicData: {
              lodging: getSubmissionValues(values),
            },
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backLink={backLink}
      />
    </EditListingPanel>
  );
};

EditListingLodgingPanel.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

EditListingLodgingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

export default EditListingLodgingPanel;
