import React from 'react';
import { bool, object, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';

import {
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
  PrimaryButton,
  Form,
} from '../../components';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';
import MultiPhotoUploadField from './EditListingPhotosMultiUploadField';

import css from './EditListingPhotosForm.css';

const EditListingPhotosForm = ({
  fetchErrors,
  listing,
  saveActionMsg,
  updated,
  ready,
  updateInProgress: submitInProgress,
  page,
  requestImageUpload,
  backLink,
  ...formProps
}) => {
  const initialImages = formProps?.initialValues?.images || [];

  return (
    <FinalForm
      {...formProps}
      keepDirtyOnReinitialize // Important so we don't lose state on re-renders
      render={({ form, className, handleSubmit, intl, invalid, values }) => {
        const allImages = listing.images.length - initialImages.length + values.images.length;
        const imagesValid = allImages <= 100;

        const { uploadImageError } = fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        let uploadImageFailed = null;

        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        } else if (!imagesValid) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.validationImagesLength" />
            </p>
          );
        }

        const classes = classNames(css.root, className);
        const submitInvalid = invalid || !imagesValid;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <div className={css.uploadRestrictions}>
                <p className={css.uploadRestrictionText}>
                  <FormattedMessage id="EditListingPhotosForm.allowedFilesize" />
                </p>
                <p className={css.uploadRestrictionText}>
                  <FormattedMessage id="EditListingPhotosForm.allowedImageTypes" />
                </p>
              </div>

              <EditListingError fetchErrors={fetchErrors} />

              <EditListingFieldWrapper>
                <MultiPhotoUploadField
                  form={form}
                  requestImageUpload={requestImageUpload}
                  intl={intl}
                  categories={['lodging', 'landscape', 'more']}
                />
              </EditListingFieldWrapper>

              {uploadImageFailed}
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitInvalid}>
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosForm.defaultProps = {
  fetchErrors: null,
  backLink: null,
};

EditListingPhotosForm.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  listing: object.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  backLink: node,

  // ...formProps
  onSubmit: func.isRequired,

  // From mapStateToProps
  page: object.isRequired,

  // From mapDispatchToProps
  requestImageUpload: func.isRequired,

  // From injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    page: state.EditListingPage,
  };
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

const ConnectedEditListingPhotosForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(EditListingPhotosForm));

export default ConnectedEditListingPhotosForm;
