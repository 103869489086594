import React, { useState, useEffect } from 'react';
import { string, node, bool } from 'prop-types';
import TooltipTrigger from 'react-popper-tooltip';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import css from './Tooltip.css';

const Tooltip = ({
  children,
  tooltip,
  rootClassName,
  className,
  tooltipShown,
  onTooltipShown,
  modifiers = [],
  hideCloseButton,
  hideArrow,
  dataTestId,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (tooltipShown !== undefined) {
      setIsVisible(tooltipShown);
    }
  }, [tooltipShown]);

  return (
    <TooltipTrigger
      {...props}
      tooltipShown={isVisible}
      onVisibilityChange={visible => {
        setIsVisible(visible);

        if (onTooltipShown) {
          onTooltipShown(visible);
        }
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        ...modifiers,
      ]}
      tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
        <div
          data-testId={dataTestId}
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(css.root, rootClassName),
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: css.tooltipArrow,
                'data-placement': placement,
              })}
            />
          )}

          {!hideCloseButton && (
            <button
              type="button"
              className={css.tooltipClose}
              onClick={() => {
                setIsVisible(false);

                if (onTooltipShown) {
                  onTooltipShown(false);
                }
              }}
            >
              <CloseIcon />
            </button>
          )}
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          style={{ cursor: 'pointer' }}
          {...getTriggerProps({
            ref: triggerRef,
            className,
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

Tooltip.defaultProps = {
  dataTestId: 'tooltip',
  children: null,
  tooltip: null,
  className: null,
  rootClassName: null,
  hideCloseButton: false,
  hideArrow: false,
};

Tooltip.propTypes = {
  dataTestId: string,
  children: node,
  tooltip: node,
  className: string,
  rootClassName: string,
  hideCloseButton: bool,
  hideArrow: bool,
};

export default Tooltip;
