import track from './tracking';
import * as types from './types';
import * as log from '../util/log';

import {
  CONFIRM_SUCCESS,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  LOGIN_WITH_IDENTITY_SUCCESS,
  LOGOUT_SUCCESS,
} from '../ducks/Auth.duck';
import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import { SEARCH_LISTINGS_REQUEST } from '../containers/SearchPage/SearchPage.duck';
import { SHOW_LISTING_SUCCESS } from '../containers/ListingPage/ListingPage.duck';
import { SEND_ENQUIRY_SUCCESS } from '../containers/NewInquiryPage/NewInquiryPage.duck';
import { UPDATE_PROFILE_SUCCESS } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  CREATE_LISTING_DRAFT_SUCCESS,
  PUBLISH_LISTING_SUCCESS,
  SAVE_PAYOUT_DETAILS_SUCCESS,
} from '../containers/EditListingPage/EditListingPage.duck';
import { SAVE_PAYOUT_DETAILS_SUCCESS as STRIPE_SAVE_PAYOUT_DETAILS_SUCCESS } from '../containers/StripePayoutPage/StripePayoutPage.duck';
import {
  INITIATE_ORDER_SUCCESS,
  SPECULATE_TRANSACTION_SUCCESS,
} from '../containers/CheckoutPage/CheckoutPage.duck';
import { ELEMENT_CLICKED } from '../ducks/UI.duck';
import {
  ACCEPT_SALE_SUCCESS,
  DECLINE_SALE_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  SEND_MESSAGE_SUCCESS,
  CANCEL_SALE_SUCCESS,
  INITIATE_OFFER_SUCCESS,
  CONFIRM_OFFER_PAYMENT_SUCCESS,
  DECLINE_OFFER_SUCCESS,
  CANCEL_OFFER_SUCCESS,
  UPDATE_PARTY_MEMBER_DETAILS_SUCCESS,
} from '../containers/TransactionPage/TransactionPage.duck';
import { SEND_REVIEW_SUCCESS } from '../containers/ReviewPage/ReviewPage.duck';
import { ID_VERIFICATION_SUCCESS } from '../ducks/IDVerification.duck';
import { ADD_PAYMENT_METHOD_SUCCESS } from '../ducks/paymentMethods.duck';
import {
  LISTING_FAVORED,
  LISTING_UNFAVORED,
  UPDATE_USER_PROMO_CODES_SUCCESS,
} from '../ducks/user.duck';

const eventMap = {
  [LOCATION_CHANGED]: types.PAGE_VIEW,
  [SIGNUP_STARTED]: types.SIGNUP_STARTED,
  [SIGNUP_SUCCESS]: types.SUCCESSFUL_REGISTRATION,
  [CONFIRM_SUCCESS]: types.SUCCESSFUL_REGISTRATION,
  [LOGIN_WITH_IDENTITY_SUCCESS]: types.SUCCESSFUL_LOGIN,
  [LOGOUT_SUCCESS]: types.LOGOUT,
  [SEARCH_LISTINGS_REQUEST]: types.SEARCH,
  [SHOW_LISTING_SUCCESS]: types.LISTING_VIEW,
  [UPDATE_PROFILE_SUCCESS]: types.PROFILE_UPDATED,
  [CREATE_LISTING_DRAFT_SUCCESS]: types.NEW_LISTING_STARTED,
  [PUBLISH_LISTING_SUCCESS]: types.NEW_LISTING_COMPLETED,
  [INITIATE_ORDER_SUCCESS]: types.BOOKING_REQUEST_SUBMITTED,
  [ACCEPT_SALE_SUCCESS]: types.BOOKING_REQUEST_ACCEPTED,
  [DECLINE_SALE_SUCCESS]: types.BOOKING_REQUEST_DECLINED,
  [SEND_ENQUIRY_SUCCESS]: types.NEW_QUERY_INITIATED,
  [FETCH_MESSAGES_SUCCESS]: types.MESSAGES_LOADED,
  [SEND_MESSAGE_SUCCESS]: types.MESSAGE_SENT,
  [ID_VERIFICATION_SUCCESS]: types.ID_VERIFICATION_SUCCESS,
  [SPECULATE_TRANSACTION_SUCCESS]: types.BOOKING_REQUEST_STARTED,
  [ADD_PAYMENT_METHOD_SUCCESS]: types.PAYMENT_METHOD_SUBMITTED,
  [SAVE_PAYOUT_DETAILS_SUCCESS]: types.PAYOUT_DETAILS_SUBMITTED,
  [STRIPE_SAVE_PAYOUT_DETAILS_SUCCESS]: types.PAYOUT_DETAILS_SUBMITTED,
  [CANCEL_SALE_SUCCESS]: types.TRANSACTION_CANCELED,
  [INITIATE_OFFER_SUCCESS]: types.SPECIAL_OFFER_SENT,
  [CONFIRM_OFFER_PAYMENT_SUCCESS]: types.SPECIAL_OFFER_ACCEPTED,
  [DECLINE_OFFER_SUCCESS]: types.SPECIAL_OFFER_DECLINED,
  [CANCEL_OFFER_SUCCESS]: types.SPECIAL_OFFER_CANCELED,
  [LISTING_FAVORED]: types.LISTING_FAVORED,
  [LISTING_UNFAVORED]: types.LISTING_UNFAVORED,
  [SEND_REVIEW_SUCCESS]: types.SEND_REVIEW_SUCCESS,
  [UPDATE_PARTY_MEMBER_DETAILS_SUCCESS]: types.PARTY_MEMBERS_SUBMITTED,
  [UPDATE_USER_PROMO_CODES_SUCCESS]: types.TRANSACTION_PROMO_CODE_APPLIED,
};

export default store => next => action => {
  let event = eventMap[action.type];
  const payload = action.payload || {};

  // Clicks are special, as we want to reuse the same action type
  if (action.type === ELEMENT_CLICKED) {
    event = action.payload.event;
  }

  if (event) {
    try {
      track(event, payload, store.getState());
    } catch (e) {
      log.error(e, 'tracking error', { event });
    }
  }

  return next(action);
};
