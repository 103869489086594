import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';

import { FieldNumberInput } from '../../components';

import css from './EditListingBedFields.css';

const EditListingBedFields = ({ intl, className, namespace }) => {
  const classes = classNames(css.root, className);

  const withNamespace = name => (namespace ? `${namespace}.${name}` : name);

  return (
    <div className={classes}>
      <FieldNumberInput
        name={withNamespace('sofaBeds')}
        id={withNamespace('sofaBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.sofaBed' })}
        asRow
      />
      <FieldNumberInput
        name={withNamespace('bunkBeds')}
        id={withNamespace('bunkBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.bunkBed' })}
        asRow
      />
      <FieldNumberInput
        name={withNamespace('singleBeds')}
        id={withNamespace('singleBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.singleBeds' })}
        asRow
      />
      <FieldNumberInput
        name={withNamespace('doubleBeds')}
        id={withNamespace('doubleBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.doubleBeds' })}
        asRow
      />
      <FieldNumberInput
        name={withNamespace('queenBeds')}
        id={withNamespace('queenBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.queenBeds' })}
        asRow
      />
      <FieldNumberInput
        name={withNamespace('kingBeds')}
        id={withNamespace('kingBeds')}
        label={intl.formatMessage({ id: 'EditListingForms.kingBeds' })}
        asRow
      />
    </div>
  );
};

EditListingBedFields.propTypes = {
  intl: intlShape.isRequired,
  className: string,
  namespace: string,
};

EditListingBedFields.defaultProps = {
  className: null,
  namespace: null,
};

export default compose(injectIntl)(EditListingBedFields);
