import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { SectionBySpecies } from '..';
import css from './NoSearchResults.css';
import { NotifyForAddedStateForm } from '../../forms';

const NoSearchResults = ({
  rootClassName,
  className,
  searchHasNonLocationFilters,
  urlQueryParams: { address },
}) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.heading}>
        <FormattedMessage
          id={
            searchHasNonLocationFilters
              ? 'NoSearchResults.nonLocationFiltersInSearchHeading'
              : 'NoSearchResults.onlyLocationFiltersInSearchHeading'
          }
        />
      </h1>

      <NotifyForAddedStateForm address={address} />

      <h1 className={classNames(css.heading, css.suggestionsHeading)}>
        <FormattedMessage id="NoSearchResults.speciesSuggestionHeading" />
      </h1>

      <SectionBySpecies variant={SectionBySpecies.variants.BOXES} className={css.suggestions} />
    </div>
  );
};

NoSearchResults.defaultProps = {
  rootClassName: null,
  className: null,
};

NoSearchResults.propTypes = {
  rootClassName: string,
  className: string,
  searchHasNonLocationFilters: bool.isRequired,
};

export default NoSearchResults;
