import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './ForLandownersCard.css';
import { PrimaryButton } from '../Button/Button';
import { NamedLink } from '..';
import { ReactComponent as LandIcon } from '../../assets/icons/land.svg';

const ForLandownersCard = () => {
  return (
    <div className={css.root}>
      <div className={css.contentContainer}>
        <LandIcon className={css.icon} />

        <div>
          <div className={css.title}>
            <FormattedMessage id="ForLandownersCard.title" />
          </div>
          <div className={css.subtitle}>
            <FormattedMessage id="ForLandownersCard.subTitle" />
          </div>
          <div>
            <FormattedMessage id="ForLandownersCard.body" />
          </div>
        </div>
      </div>
      <div className={css.ctaContainer}>
        <PrimaryButton component={NamedLink} name="LandownersPage">
          <FormattedMessage id="ForLandownersCard.cta" />
        </PrimaryButton>
      </div>
    </div>
  );
};

ForLandownersCard.propTypes = {};

export default ForLandownersCard;
