import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './FAQSectionSteps.css';
import { Container } from '../../components/Layout';

export const Step = ({ icon, title, children }) => {
  return (
    <div className={css.stepRoot}>
      <div className={css.stepHeader}>
        <div className={css.stepIconContainer}>{icon}</div>
        <h2 className={css.stepTitle}>{title}</h2>
      </div>
      <p className={css.stepBody}>{children}</p>
    </div>
  );
};

Step.propTypes = {};

const FAQSectionSteps = ({ bg, title, description, cta, children }) => {
  return (
    <div className={classNames(css.root, css[`root--${bg}`])}>
      <Container>
        <div>
          <h1 className={css.title}>{title}</h1>

          <p className={css.description}>{description}</p>
        </div>

        <div className={css.stepsWrapper}>
          <div className={css.stepsInner}>{children}</div>
        </div>

        <div className={css.ctaContainer}>{cta}</div>
      </Container>
    </div>
  );
};

FAQSectionSteps.propTypes = {
  bg: PropTypes.oneOf(['brown', 'olive']),
};

FAQSectionSteps.defaultProps = {
  bg: 'brown',
};

export default FAQSectionSteps;
