/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as QuoteLeft } from '../../assets/icons/quote-left.svg';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star-gold.svg';

import css from './AuthenticationPageReview.css';

const AuthenticationPageReview = ({ content, rating, author, isAuthorVerified }) => {
  return (
    <div className={css.root}>
      <QuoteLeft className={css.quoteIcon} />
      <div className={css.content}>{content}</div>

      <div className={css.ratingWrapper}>
        {[...Array(rating)].map((_, i) => (
          <IconStar key={`star-${i}`} className={css.starIcon} />
        ))}
      </div>

      <div className={css.authorWrapper}>
        <div className={css.author}>{author}</div>
        {isAuthorVerified && (
          <div className={css.authorVerified}>
            <div className={css.authorVerifiedIcon}>&#10003;</div>
            <div>
              <FormattedMessage id="AuthenticationPageReview.verifiedGuest" />
            </div>
          </div>
        )}
      </div>

      <div className={css.footer}>
        <FormattedMessage id="AuthenticationPageReview.footer" />
      </div>
    </div>
  );
};

export default AuthenticationPageReview;
