/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import get from 'lodash/get';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../../util/types';
import { isConservationProgramTransition } from '../../../util/transaction';

import css from './LineItems.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const LineItemCustomerCommissionMaybe = props => {
  const { transaction, isCustomer, intl, onClick } = props;
  const transitions = get(transaction, 'attributes.transitions');
  const isConservationProgram = isConservationProgramTransition(transitions);

  // If commission is passed it will be shown as a fee already reduces from the total price
  const commissionItems = [];

  const customerCommissionLineItems = transaction.attributes.lineItems.filter(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  if (isCustomer && customerCommissionLineItems.length) {
    customerCommissionLineItems.forEach((lineItem, index) => {
      const isEstimatedDonation = get(lineItem, 'donation', null);

      if (!isValidCommission(lineItem)) {
        // eslint-disable-next-line no-console
        console.error('invalid commission line item:', lineItem);
        throw new Error('Commission should be present and the value should be zero or positive');
      }

      const commission = lineItem.lineTotal;
      const commissionIsZero = lineItem?.lineTotal?.amount === 0;
      const formattedCommission = commission ? formatMoney(intl, commission) : null;
      let commissionLabel = <FormattedMessage id="BookingBreakdown.commission" />;
      if ((isConservationProgram && index === 1) || isEstimatedDonation) {
        commissionLabel = <FormattedMessage id="BookingBreakdown.conservationDonation" />;
      }
      if (!commissionIsZero) {
        commissionItems.push(
          <React.Fragment key={`commission-line-${index}`}>
            <div className={css.lineItem}>
              <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
                {commissionLabel}
              </span>
              <span className={css.itemValue}>+{formattedCommission}</span>
            </div>
          </React.Fragment>
        );
      }
    });
  }

  return <>{commissionItems}</>;
};

LineItemCustomerCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
  onClick: func.isRequired,
};

export default LineItemCustomerCommissionMaybe;
