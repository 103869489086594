import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton, NamedLink } from '../../components';

import css from './FAQSectionFooter.css';
import { Container } from '../../components/Layout';

const FAQSectionFooter = props => {
  return (
    <div className={css.root}>
      <h1>
        <FormattedMessage id="FAQLandownersPage.footer.title" />
      </h1>

      <p className={css.content}>
        <FormattedMessage id="FAQLandownersPage.footer.body" />
      </p>

      <PrimaryButton component={NamedLink} name="NewListingPage">
        <FormattedMessage id="FAQLandownersPage.howItWorks.cta" />
      </PrimaryButton>
    </div>
  );
};

FAQSectionFooter.propTypes = {};

export default FAQSectionFooter;
