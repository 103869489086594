import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { ValidationError, FieldLabel, FieldTextInput } from '..';
import * as validators from '../../util/validators';

import css from './FieldPointsOfInterestsInput.css';
import { ReactComponent as IconAdd } from '../../assets/icons/plus-circle-solid.svg';
import { ReactComponent as IconRemove } from '../../assets/icons/times-circle-solid.svg';

const FieldPointsOfInterestsInputRenderer = ({
  rootClassName,
  className,
  meta,
  fields,
  label,
  intl,
}) => {
  const classes = classNames(rootClassName || css.root, className);

  const validTitle = validators.required(
    intl.formatMessage({ id: 'FieldPointsOfInterestsInput.validTitle' })
  );
  const urlRequired = validators.required(
    intl.formatMessage({ id: 'FieldPointsOfInterestsInput.validUrl' })
  );

  const urlValid = validators.validBusinessURL(
    intl.formatMessage({ id: 'FieldPointsOfInterestsInput.validUrl' }),
    {
      includesProtocol: true,
    }
  );

  const urlValidators = validators.composeValidators(urlRequired, urlValid);

  return (
    <>
      {label && <FieldLabel className={css.label}>{label}</FieldLabel>}
      <fieldset className={classes}>
        <ul className={css.list}>
          {fields.map((name, index) => {
            return (
              <li key={name} className={css.item}>
                <div className={css.fieldGroup}>
                  <div className={css.fieldWrapper}>
                    <FieldTextInput
                      type="text"
                      id={`${name}.title`}
                      name={`${name}.title`}
                      label={intl.formatMessage(
                        { id: 'FieldPointsOfInterestsInput.posLinksTitleLabel' },
                        { count: index + 1 }
                      )}
                      validate={validTitle}
                    />
                  </div>

                  <div className={css.fieldWrapper}>
                    <FieldTextInput
                      type="text"
                      id={`${name}.url`}
                      name={`${name}.url`}
                      label={intl.formatMessage(
                        { id: 'FieldPointsOfInterestsInput.posLinksUrlLabel' },
                        { count: index + 1 }
                      )}
                      validate={urlValidators}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    className={classNames(css.button, css.removeButton)}
                    onClick={() => fields.remove(index)}
                  >
                    <IconRemove className={css.icon} />
                  </button>
                </div>
              </li>
            );
          })}
        </ul>

        <div>
          <button
            type="button"
            className={css.button}
            onClick={() => fields.push({ title: '', url: '' })}
          >
            <IconAdd className={css.icon} />
            {intl.formatMessage({ id: 'FieldPointsOfInterestsInput.addButtonLabel' })}
          </button>
        </div>

        <ValidationError fieldMeta={{ ...meta }} />
      </fieldset>
    </>
  );
};

FieldPointsOfInterestsInputRenderer.propTypes = {};

const FieldPointsOfInterestsInput = props => (
  <FieldArray component={FieldPointsOfInterestsInputRenderer} {...props} />
);

export default injectIntl(FieldPointsOfInterestsInput);
