/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import { bool, oneOfType, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { getFirstName } from '../../util/user';

const UserDisplayName = props => {
  const {
    rootClassName,
    className,
    user,
    intl,
    deletedUserDisplayName,
    bannedUserDisplayName,
    useOnlyFirstName,
  } = props;
  const hasAttributes = user && user.attributes;
  const userIsDeleted = hasAttributes && user.attributes.deleted;
  const userIsBanned = hasAttributes && user.attributes.banned;
  const userHasProfile = hasAttributes && user.attributes.profile;
  const userDisplayName = userHasProfile && user.attributes.profile.displayName;
  const userFirstName = useOnlyFirstName ? getFirstName(userDisplayName) : null;

  const deletedUserDisplayNameInUse =
    deletedUserDisplayName ||
    intl.formatMessage({
      id: 'UserDisplayName.deleted',
    });

  const bannedUserDisplayNameInUse =
    bannedUserDisplayName ||
    intl.formatMessage({
      id: 'UserDisplayName.banned',
    });

  const displayName =
    userFirstName ||
    userDisplayName ||
    (userIsDeleted
      ? deletedUserDisplayNameInUse
      : userIsBanned
      ? bannedUserDisplayNameInUse
      : null);

  const classes = classNames(rootClassName, className);
  return <span className={classes}>{displayName}</span>;
};

UserDisplayName.defaultProps = {
  rootClassName: null,
  className: null,

  user: null,
  deletedUserDisplayName: null,
  bannedUserDisplayName: null,
  useOnlyFirstName: false,
};

UserDisplayName.propTypes = {
  rootClassName: string,
  className: string,

  user: oneOfType([propTypes.user, propTypes.currentUser]),
  deletedUserDisplayName: string,
  bannedUserDisplayName: string,
  useOnlyFirstName: bool,
};

export default UserDisplayName;
