import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  PrimaryButton,
  EditListingActions,
  EditListingFieldWrapper,
  EditListingSection,
} from '../../components';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.css';

export const EditListingAvailabilityFormComponent = ({ backLink, ...props }) => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        availability,
        availabilityPlan,
        listingId,
      } = formRenderProps;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          <EditListingSection isFullWidth>
            <EditListingFieldWrapper>
              <ManageAvailabilityCalendar
                availability={availability}
                availabilityPlan={availabilityPlan}
                listingId={listingId}
              />
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingActions>
            {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

            <EditListingActions.Forward>
              <PrimaryButton
                type="submit"
                className={css.submitButton}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
            </EditListingActions.Forward>
          </EditListingActions>
        </Form>
      );
    }}
  />
);

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
