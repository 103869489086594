import React from 'react';
import classNames from 'classnames';

import css from './EditListingPhotoLibraryForm.css';

const EditListingPhotosImageCategory = ({ onClick, icon, label, isDisabled }) => {
  const buttonClass = classNames(css.imageCategory, { [css.imageCategoryDisabled]: isDisabled });
  const innerButtonClass = classNames(css.imageCategoryInner, {
    [css.imageCategoryInnerDisabled]: isDisabled,
  });
  const iconWithClass = React.cloneElement(icon, {
    className: classNames(css.imageCategoryIcon, icon.props.className),
  });

  return (
    <button type="button" className={buttonClass} onClick={onClick}>
      <div className={innerButtonClass}>
        {iconWithClass}

        <p className={css.imageCategoryLabel}>{label}</p>
      </div>
    </button>
  );
};

export default EditListingPhotosImageCategory;
