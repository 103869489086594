import React from 'react';
import { bool, func, object, string, node } from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingCampsiteForm } from '../../forms';
import {
  getInitialValues,
  getSubmissionValues,
  emptyState,
  rvEmptyState,
} from '../../util/campsite';

const EditListingCampsitePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
  } = props;

  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCampsitePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCampsitePanel.createListingTitle" />
  );
  const packages = get(currentListing, 'attributes.publicData.packages', []);

  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingCampsiteForm
        emptyState={emptyState}
        rvEmptyState={rvEmptyState}
        initialValues={getInitialValues(currentListing)}
        onSubmit={values => {
          onSubmit({
            publicData: getSubmissionValues(values, packages),
          });
        }}
        onChange={onChange}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backLink={backLink}
        isPublished={isPublished}
        saveActionMsg={submitButtonText}
      />
    </EditListingPanel>
  );
};

EditListingCampsitePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

EditListingCampsitePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default EditListingCampsitePanel;
