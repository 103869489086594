/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useReducer } from 'react';
import { Field } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { object, bool, array, func } from 'prop-types';
import arrayMove from 'array-move';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';
import { shouldShowErrorForField } from '../../util/forms';
import { PROPERTY, ARRIVAL } from '../../util/editListing';
import { PHOTO_LIBRARY_TABS, getPhotos } from '../../util/photoLibrary';
import {
  required,
  composeValidators,
  nonEmptyArray,
  each,
  propertyValidator,
} from '../../util/validators';
import config from '../../config';
import {
  EditListingPhotoLibraryPreviews,
  EditListingUploadProgressBar,
  ValidationError,
  FieldCheckbox,
  FieldLabel,
} from '../../components';
import { initialUploadState, uploadActions, uploadReducer } from './reducer';
import ImageCategory from './EditListingPhotoLibraryImageCategory';

import { ReactComponent as PlusIcon } from './images/plus.svg';

import css from './EditListingPhotoLibraryForm.css';

const ACCEPT_IMAGES = ['.jpg', '.jpeg', '.png'];

// We need an error message to convince react-final-form the form is invalid
// but we don't want to display that error to the user in this case
const DONT_SHOW_THIS_ERROR = 'DONT_SHOW_THIS_ERROR';

// If something has a tmpId id because it has recently been uploaded then keep using it
// even after it gets it's full id back from the API
const imageIdsForComparison = images =>
  images ? images.map(image => image.tmpId || image.id.uuid).join(',') : null;

const EditListingPhotoLibraryMultiUploadField = ({
  form,
  requestImageUpload,
  intl,
  categories,
  isRequired,
  tags,
  imageCaptions,
  activeTab,
  onManageDisableScrolling,
  isUntaggedChecked,
  onUntaggedClick,
  updateTags,
  updateImages,
  description,
  title,
  disableSorting,
}) => {
  const [uploadState, uploadDispatch] = useReducer(uploadReducer, initialUploadState);
  const getCurrentImagesValue = () => form.getFieldState('images').value;

  let localTags = {
    ...tags,
  };

  const startUpload = useCallback(
    (tmpId, file, currentTab) => {
      const handleUploadProgress = progressEvent => {
        const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;

        uploadDispatch({
          type: uploadActions.PROGRESS,
          payload: { tmpId, progress: percentCompleted },
        });
      };

      uploadDispatch({ type: uploadActions.STARTED, payload: { tmpId, file } });

      requestImageUpload(file, handleUploadProgress)
        .then(apiId => {
          uploadDispatch({
            type: uploadActions.SUCCESS,
            payload: { tmpId, apiId },
          });

          const currentImages = getCurrentImagesValue();
          const newImages = currentImages.map(currentImage => {
            if (currentImage.tmpId && currentImage.tmpId === tmpId) {
              return {
                ...currentImage,
                id: apiId,
              };
            }

            return currentImage;
          });

          form.change('images', newImages);

          let initialTag;

          switch (currentTab) {
            case PHOTO_LIBRARY_TABS.PROPERTY:
              initialTag = PROPERTY;
              break;

            case PHOTO_LIBRARY_TABS.HUNT:
              initialTag = config.custom.HUNT_ACTIVITY;
              break;

            case PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION:
              initialTag = config.custom.OUTDOOR_RECREATION_ACTIVITY;
              break;

            case PHOTO_LIBRARY_TABS.ARRIVAL:
              initialTag = ARRIVAL;
              break;

            default:
          }

          if (initialTag) {
            localTags = {
              ...localTags,
              [apiId.uuid]: [initialTag],
            };
            form.change('tags', localTags);

            if (updateTags) {
              updateTags(localTags);
            }
          }

          if (updateImages) {
            updateImages(newImages);
          }
        })
        .catch(e => {
          let message = 'EditListingPhotosForm.imageUploadFailed.uploadFailed';

          if (e?.data?.errors[0]?.code === 'request-upload-over-limit') {
            message = 'EditListingPhotosForm.imageUploadFailed.uploadOverLimit';
          }

          uploadDispatch({
            type: uploadActions.ERROR,
            payload: {
              tmpId,
              error: intl.formatMessage({
                id: message,
              }),
            },
          });
        });
    },
    [requestImageUpload]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      const currentImages = getCurrentImagesValue();

      const newImages = [];
      const files = {};

      acceptedFiles.forEach(file => {
        const tmpId = `${btoa(file.name)}_${Date.now()}`;

        // id is null until the upload is finished
        newImages.push({ tmpId, id: null });
        files[tmpId] = file;
      });

      // Programmatically change the value but issue focus/blur so that values
      // like `touched` get updated as if the user interacted with a normal input
      form.focus('images');
      form.change('images', [...currentImages, ...newImages]);
      form.blur('images');

      newImages.forEach(({ tmpId }) => {
        startUpload(tmpId, files[tmpId], activeTab);
      });
    },
    [activeTab]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPT_IMAGES,
  });

  return (
    <Field
      name="images"
      isEqual={(a, b) => imageIdsForComparison(a) === imageIdsForComparison(b)}
      validate={
        isRequired &&
        composeValidators(
          nonEmptyArray(
            intl.formatMessage({
              id: 'EditListingPhotosForm.imageRequired',
            })
          ),

          each(propertyValidator('id.uuid', required(DONT_SHOW_THIS_ERROR)))
        )
      }
    >
      {({ input, meta }) => {
        const isLimitValid = input.value.length < 100;

        let descriptionText;

        if (description) {
          descriptionText = description;
        } else {
          descriptionText = (
            <FormattedMessage id="EditListingPhotoLibraryMultiUploadField.imagesDescription" />
          );
        }

        return (
          <div className={css.EditListingPhotoContainer}>
            <div className={css.PhotoFieldWrapper}>
              {title && <FieldLabel>{title}</FieldLabel>}
              <div className={css.dropzoneDescription}>
                {isLimitValid ? (
                  descriptionText
                ) : (
                  <span className={css.imagesLimitValidation}>
                    <FormattedMessage id="EditListingPhotoLibraryMultiUploadField.imagesLimitValidation" />
                  </span>
                )}
              </div>

              <div
                {...getRootProps()}
                className={classNames(css.dropzoneContainer, {
                  [css.dropzoneContainerActive]: isDragActive,
                  [css.dropzoneContainerInactive]: !isLimitValid,
                })}
              >
                {isLimitValid && <input {...getInputProps()} />}

                <div className={css.imageCategories}>
                  {(!categories || categories.includes('add')) && (
                    <ImageCategory
                      isDisabled={!isLimitValid}
                      icon={<PlusIcon />}
                      label={
                        <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.addLabel" />
                      }
                    />
                  )}
                </div>

                {isDragActive && (
                  <div className={css.dropzoneDropNotification}>
                    <p className={css.dropzoneDropNotificationLabel}>
                      <FormattedMessage id="EditListingPhotosForm.imageUploadDropNotification" />
                    </p>
                  </div>
                )}
              </div>
            </div>
            {activeTab === PHOTO_LIBRARY_TABS.ALL && (
              <div className={css.tagCheckboxContainer}>
                <FieldCheckbox
                  labelClassName={css.tagCheckboxLabel}
                  id="taggedCheckbox"
                  name="taggedCheckbox"
                  checked={isUntaggedChecked}
                  label={
                    <FormattedMessage id="EditListingPhotoLibraryMultiUploadField.untaggedCheckbox" />
                  }
                  onClick={() => {
                    onUntaggedClick(isUntaggedChecked);
                  }}
                />
              </div>
            )}

            <EditListingUploadProgressBar
              className={css.progressBar}
              progress={uploadState.progress}
              files={uploadState.files}
            />

            <EditListingPhotoLibraryPreviews
              disableSorting={disableSorting}
              className={css.photoPreviews}
              images={input.value}
              tags={tags}
              imageCaptions={imageCaptions}
              files={uploadState.files}
              errors={uploadState.errors}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              savedImageCaptionPlaceholderText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageCaptionPlaceholderText',
              })}
              onRemoveImage={({ tmpId, id }) => {
                const updatedImages = input.value.filter(image => {
                  if (tmpId && image.tmpId === tmpId) return false;
                  if (id && image.id === id) return false;

                  return true;
                });

                input.onFocus();
                input.onChange(updatedImages);
                input.onBlur();

                const newTags = JSON.parse(JSON.stringify(tags));

                delete newTags[tmpId || id?.uuid];

                form.change('tags', {
                  ...newTags,
                });

                if (updateTags) {
                  updateTags(newTags);
                }

                if (updateImages) {
                  updateImages(updatedImages);
                }
              }}
              onUpdateImageOrder={({ oldIndex, newIndex }) => {
                if (disableSorting) return;

                const filteredImages = getPhotos(input.value, tags, activeTab);
                const oldImage = filteredImages[oldIndex];
                const newImage = filteredImages[newIndex];

                const oldImageIndex = input.value.findIndex(image => {
                  return image.id?.uuid === oldImage.id?.uuid;
                });

                const newImageIndex = input.value.findIndex(image => {
                  return image.id?.uuid === newImage.id?.uuid;
                });

                input.onFocus();
                input.onChange(arrayMove(input.value, oldImageIndex, newImageIndex));
                input.onBlur();
              }}
              onUpdateTags={(id, tag) => {
                const newTags = JSON.parse(JSON.stringify(tags));
                let imageTags = newTags[id] || [];

                if (imageTags.includes(tag)) {
                  imageTags = imageTags.filter(imageTag => imageTag !== tag);

                  if (tag === config.custom.HUNT_ACTIVITY) {
                    imageTags = imageTags.filter(imageTag => {
                      return !Object.keys(config.custom.species.hunt).includes(imageTag);
                    });
                  } else if (tag === config.custom.OUTDOOR_RECREATION_ACTIVITY) {
                    imageTags = imageTags.filter(imageTag => {
                      return !config.custom.activitiesTypeMap.some(
                        activity => activity.key === imageTag
                      );
                    });
                  }
                } else {
                  imageTags.push(tag);

                  // Preselect hunting tag if given tag is one of the species
                  if (
                    Object.keys(config.custom.species.hunt).some(speciesKey => {
                      return speciesKey === tag;
                    }) &&
                    !imageTags.includes(config.custom.HUNT_ACTIVITY)
                  ) {
                    imageTags.push(config.custom.HUNT_ACTIVITY);
                  }
                }

                const updatedTags = {
                  ...newTags,
                  [id]: imageTags,
                };

                if (!imageTags.length) {
                  delete updatedTags[id];
                }

                form.change('tags', updatedTags);

                if (updateTags) {
                  updateTags(updatedTags);
                }
              }}
              activeTab={activeTab}
              onManageDisableScrolling={onManageDisableScrolling}
              isUntaggedChecked={isUntaggedChecked}
            />

            {shouldShowErrorForField(meta) && meta.error !== DONT_SHOW_THIS_ERROR ? (
              <ValidationError fieldMeta={meta} />
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

EditListingPhotoLibraryMultiUploadField.defaultProps = {
  isRequired: true,
};

EditListingPhotoLibraryMultiUploadField.propTypes = {
  form: object.isRequired,
  requestImageUpload: func.isRequired,
  intl: intlShape.isRequired,
  categories: array.isRequired,
  isRequired: bool,
};

export default EditListingPhotoLibraryMultiUploadField;
