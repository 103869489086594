import React, { useEffect, useRef } from 'react';
import { array } from 'prop-types';
import classNames from 'classnames';

import { IconArrowHead } from '..';

import css from './Breadcrumbs.css';

const Breadcrumbs = ({ breadcrumbs }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.scrollWidth) {
      ref.current.scrollLeft = ref.current.scrollWidth;
    }
  }, [breadcrumbs]);

  return (
    <div className={css.root} ref={ref}>
      {breadcrumbs.map((item, index) => (
        <>
          <span
            className={classNames(css.breadcrumb, {
              [css.active]: breadcrumbs.length - 1 === index,
            })}
          >
            {item}
          </span>
          {breadcrumbs.length - 1 > index && (
            <IconArrowHead direction="right" size="small" className={css.arrow} />
          )}
        </>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: array.isRequired,
};

export default Breadcrumbs;
