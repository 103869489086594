import React from 'react';
import { node, string, bool, func } from 'prop-types';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { Tooltip } from '..';
import css from './FormValidationTooltip.css';

const FormValidationTooltip = ({
  children,
  body,
  placement,
  trigger,
  tooltipShown,
  onTooltipShown,
  className,
  title,
}) => {
  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      tooltipShown={tooltipShown}
      onTooltipShown={onTooltipShown}
      tooltip={
        <div className={css.root}>
          <div className={css.header}>
            <LightIcon className={css.icon} />
            {title}
          </div>

          <div className={css.content}>{body}</div>
        </div>
      }
      className={className}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: () => {
              return [0, 20];
            },
          },
        },
      ]}
    >
      {children}
    </Tooltip>
  );
};

FormValidationTooltip.defaultProps = {
  children: null,
  body: null,
  placement: 'auto',
  trigger: 'click',
  tooltipShown: false,
  onTooltipShown: null,
  className: null,
};

FormValidationTooltip.propTypes = {
  children: node,
  body: node,
  placement: string,
  trigger: string,
  tooltipShown: bool,
  onTooltipShown: func,
  className: string,
};

export default FormValidationTooltip;
