/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';

import { arrayOf, object } from 'prop-types';
import { get } from 'lodash';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { propTypes } from '../../util/types';
import HasTruncation from '../Truncate/HasTruncation';
import Modal from '../Modal/Modal';
import { Form, NamedLink, PrimaryButton, Breadcrumbs } from '..';
import TransactionListingHeading from '../TransactionListingHeading/TransactionListingHeading';
import {
  InquiryWizardStepActivities,
  InquiryWizardStepPackages,
  InquiryWizardStepSpecies,
  InquiryWizardStepParty,
  InquiryWizardStepSummary,
} from './steps';
import InquiryWizardStepSignup from './steps/InquiryWizardStepSignup';
import InquiryWizardSection from './InquiryWizardSection';
import InquiryWizardPanelHeading from './InquiryWizardPanelHeading';
import InquiryTopics from './InquiryTopics';
import css from './InquiryWizard.css';
import {
  useInquiryWizard,
  STEP_ACTIVITY,
  STEP_PACKAGES,
  STEP_SPECIES,
  STEP_PARTY,
  STEP_SIGNUP,
  STEP_REVIEW,
} from './InquiryWizardContext';

import InquiryBreakdown from './InquiryBreakdown';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWindowWidth(window.innerWidth);

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, []);

  return windowWidth;
};

const InquiryWizardTitle = ({ step, formValues, listing }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { selectedPackage } = formValues || {};
  const { description } = selectedPackage || {};

  const stepDescription = description;

  const provider = listing.author;
  const displayName = get(provider, 'attributes.profile.displayName');
  const displayNameParts = displayName.split(' ');

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  switch (step.label) {
    case STEP_SPECIES.label:
      return (
        <InquiryWizardSection>
          <InquiryWizardPanelHeading
            title={
              <FormattedMessage
                id="InquiryWizard.speciesTitle"
                values={{
                  landowner: displayNameParts[0],
                }}
              />
            }
          />
        </InquiryWizardSection>
      );
    case STEP_PARTY.label:
    case STEP_REVIEW.label:
      return (
        <>
          <InquiryWizardSection>
            <InquiryWizardPanelHeading
              title={<FormattedMessage id="InquiryWizard.packageDetails" />}
            />

            <div className={css.descriptionWrapper}>
              <HasTruncation onShowMore={handleOpenModal}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: stepDescription,
                  }}
                />
              </HasTruncation>
            </div>
          </InquiryWizardSection>
          <Modal
            id="InquiryPage.descriptionModal"
            isOpen={isModalOpen}
            onClose={() => setModalOpen(!isModalOpen)}
            contentClassName={css.modalContent}
            containerClassName={css.containerStyles}
          >
            <div className={css.contentWrapper}>
              <div className={css.contentHeader}>
                <FormattedMessage id="PackagePage.descriptionTitle" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          </Modal>
        </>
      );
    default:
      return null;
  }
};

const InquiryWizardStatus = ({ stepIndex, steps, params }) => {
  const breadcrumbs = [
    <NamedLink
      className={css.breadcrumbLink}
      name="ListingPage"
      params={{
        ...params,
      }}
    >
      Listing
    </NamedLink>,
  ];

  const breadcrumbsSteps = steps.slice(0, stepIndex + 1);

  breadcrumbsSteps.forEach((item, index) => {
    if (index < breadcrumbsSteps.length - 1) {
      breadcrumbs.push(
        <NamedLink
          className={css.breadcrumbLink}
          name="NewInquiryPage"
          params={{
            ...params,
            step: item.path,
          }}
        >
          {item.label}
        </NamedLink>
      );
    } else {
      breadcrumbs.push(item.label);
    }
  });

  return (
    <InquiryWizardSection>
      <div className={css.statusTitle}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
    </InquiryWizardSection>
  );
};

const InquiryWizardSteps = ({
  step,
  setStep,
  listing,
  timeSlots,
  form,
  bookingDateText,
  onNext,
  values,
  currentUser,
  history,
}) => {
  switch (step.label) {
    case STEP_ACTIVITY.label:
      return <InquiryWizardStepActivities listing={listing} form={form} onNext={onNext} />;
    case STEP_PACKAGES.label:
      return (
        <InquiryWizardStepPackages
          listing={listing}
          activity={values.activity}
          form={form}
          onNext={onNext}
          setStep={setStep}
          currentUser={currentUser}
          history={history}
        />
      );
    case STEP_SPECIES.label:
      return <InquiryWizardStepSpecies listing={listing} />;
    case STEP_PARTY.label:
      return (
        <InquiryWizardStepParty
          listing={listing}
          timeSlots={timeSlots}
          formValues={form.getState().values}
          onChange={dates => {
            form.change('bookingDates', dates);
            form.change('dates', dates);
          }}
          text={bookingDateText}
        />
      );

    case STEP_REVIEW.label:
      return <InquiryWizardStepSummary listing={listing} formValues={form.getState().values} />;
    case STEP_SIGNUP.label:
      return <InquiryWizardStepSignup formValues={form.getState().values} listing={listing} />;
    default:
      return null;
  }
};

InquiryWizardSteps.propTypes = {
  listing: object.isRequired,
};

const InquiryWizardControls = ({
  intl,
  onNext,
  isLastStep,
  disabled,
  isLoading,
  currentUser,
  listing,
  followSubjectMutation,
  onSignupFormTab,
  errorMessage,
}) => {
  const listingId = listing?.id?.uuid;
  const userId = currentUser?.id?.uuid;
  const followProperty = () => {
    if (currentUser.id) {
      followSubjectMutation(userId, listingId, { sms: true, email: true });
    }
  };

  return (
    <div className={css.controlsContainer}>
      {errorMessage && <div className={css.errorMessage}>{errorMessage}</div>}
      <div className={css.controls}>
        {!isLastStep && (
          <>
            <PrimaryButton
              type="button"
              className={css.nextBtnMobile}
              isFullWidth
              disabled={disabled}
              onClick={() => onNext()}
            >
              {intl.formatMessage({ id: 'InquiryWizard.next' })}
            </PrimaryButton>
            <PrimaryButton
              dataTestId="send-inquiry-button"
              type="button"
              className={css.nextBtnDesktop}
              disabled={disabled}
              onClick={() => onNext()}
            >
              {intl.formatMessage({ id: 'InquiryWizard.next' })}
            </PrimaryButton>
          </>
        )}

        {isLastStep && !onSignupFormTab && (
          <>
            <PrimaryButton
              type="submit"
              isFullWidth
              inProgress={isLoading}
              disabled={disabled}
              ready={false}
              className={css.nextBtnMobile}
              onClick={followProperty}
            >
              {intl.formatMessage({ id: 'InquiryWizard.sendInquiry' })}
            </PrimaryButton>
            <PrimaryButton
              dataTestId="send-inquiry-button"
              type="submit"
              inProgress={isLoading}
              disabled={disabled}
              ready={false}
              className={css.nextBtnDesktop}
              onClick={followProperty}
            >
              {intl.formatMessage({ id: 'InquiryWizard.sendInquiry' })}
            </PrimaryButton>
          </>
        )}
      </div>
      {!onSignupFormTab && (
        <div className={css.controlsFooter}>
          <FormattedHTMLMessage id="InquiryWizard.footerNote" />
        </div>
      )}
    </div>
  );
};

const InquiryWizard = ({
  intl,
  listing,
  timeSlots,
  sendEnquiryInProgress,
  currentUser,
  history,
  match,
  followSubjectMutation,
  errorMessage,
  ...props
}) => {
  const { params } = match;
  const windowWidth = useWindowWidth();

  const {
    steps,
    stepIndex,
    step,
    next,
    isFirst,
    isLast,
    setFormValues,
    formValues,
  } = useInquiryWizard();

  const hideControls = [STEP_ACTIVITY.label, STEP_PACKAGES.label].includes(steps[stepIndex].label);

  const wizardStatusComponent = (
    <InquiryWizardStatus stepIndex={stepIndex} steps={steps} isLast={isLast} params={params} />
  );
  const formRef = useRef(null);
  const currentUserLoaded = !!currentUser?.id;

  const onPartyFormTab = step.label === STEP_PARTY.label;
  const onSignupFormTab = step.label === STEP_SIGNUP.label;
  const onSignupFormTabDesktop = step.label === STEP_SIGNUP.label && windowWidth >= 768;
  const onReviewTab = step.label === STEP_REVIEW.label;
  const onActivityTab = step.label === STEP_ACTIVITY.label && windowWidth >= 768;
  const onPackagesTab = step.label === STEP_PACKAGES.label && windowWidth >= 768;

  useEffect(() => {
    if (currentUserLoaded && onSignupFormTab && formRef.current) {
      formRef.current.submit();
    }
  }, [currentUserLoaded, onSignupFormTab, formRef]);

  return (
    <FinalForm
      {...props}
      keepDirtyOnReinitialize
      initialValues={formValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, form, invalid, disabled, values }) => {
        const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

        let submitDisabled = invalid || disabled;

        if (step.label === STEP_PARTY.label) {
          submitDisabled = invalid || disabled || !startDate || !endDate;
        }

        formRef.current = form;

        return (
          <Form onSubmit={handleSubmit} className={css.formContainer}>
            <FormSpy
              subscription={{ values: true }}
              onChange={spyProps => {
                setFormValues(spyProps.values);
              }}
            />

            <div className={css.formInnerContainer}>
              <div className={css.wizardElementsContainer}>
                <div className={css.desktopWizardStatus}>{wizardStatusComponent}</div>

                <InquiryWizardTitle
                  listing={listing}
                  step={step}
                  formValues={form.getState().values}
                />

                <div className={css.stepsContainer}>
                  <InquiryWizardSteps
                    values={values}
                    step={step}
                    listing={listing}
                    timeSlots={timeSlots}
                    form={form}
                    onNext={next}
                    currentUser={currentUser}
                    history={history}
                  />
                </div>

                {!hideControls && (
                  <InquiryWizardControls
                    intl={intl}
                    onNext={next}
                    isFirstStep={isFirst}
                    isLastStep={isLast}
                    currentUser={currentUser}
                    listing={listing}
                    disabled={submitDisabled}
                    followSubjectMutation={followSubjectMutation}
                    isLoading={sendEnquiryInProgress}
                    onSignupFormTab={onSignupFormTab}
                    errorMessage={errorMessage}
                  />
                )}
              </div>

              <div className={css.sidebarContainer}>
                <div className={css.sidebarPanel}>
                  <div>
                    <div className={css.mobileWizardStatus}>{wizardStatusComponent}</div>
                    {(onReviewTab ||
                      onSignupFormTabDesktop ||
                      onPartyFormTab ||
                      onActivityTab ||
                      onPackagesTab) && (
                      <InquiryWizardSection>
                        <TransactionListingHeading
                          listing={listing}
                          currentUser={currentUser}
                          provider={listing.author}
                          customer={currentUser}
                          activity={values.activity}
                          packageId={values?.selectedPackage?.id}
                          isInquiry
                        />
                      </InquiryWizardSection>
                    )}
                  </div>

                  {(onReviewTab || onSignupFormTab) && (
                    <div className={css.inquirySectionDesktop}>
                      <InquiryBreakdown listing={listing} formValues={form.getState().values} />
                    </div>
                  )}
                </div>

                {onReviewTab && (
                  <div className={css.inquirySectionDesktop}>
                    <div className={css.sidebarPanel}>
                      <InquiryTopics />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

InquiryWizard.defaultProps = {
  timeSlots: null,
  currentUser: null,
};

InquiryWizard.propTypes = {
  intl: intlShape.isRequired,
  listing: object.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  currentUser: propTypes.currentUser,
};

export default withRouter(injectIntl(InquiryWizard));
