/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bool, object, oneOf, string, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { parse, format } from 'date-fns';
import { intlShape, injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import { get } from 'lodash';
import OpeningPackageJSON from '../../assets/lottie/opening-package.json';
import { propTypes } from '../../util/types';
import { ensureUser, ensureListing, formatNumber } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';
import { NamedLink, ResponsiveImage, PackageBadges, PackageQuantityBadges } from '..';
import {
  getPackage,
  getPackageImages,
  getListingLocation,
  getLodgingPricesOptions,
} from '../../util/listing';
import { formatMoney, parseMoneyObjString } from '../../util/currency';
import {
  getStartingPrice,
  checkPackageAvailabilty,
  getRVDetails,
  getPackageLodgingPrices,
  isEarlyAccessAvailable,
} from '../../util/package';
import MenuTooltip from '../MenuTooltip/MenuTooltip';

import css from './SectionPackageCard.css';
import { toggleFavoritePackages } from '../../ducks/user.duck';
import FavoritePackage from '../FavoritePackage/FavoritePackage';

export const FETCHING_STATUS = {
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
};

export const SectionPackageCardComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    isResponsive,
    intl,
    packageId,
    item,
    editMode,
    fetchingStatus,
    onTogglePublish,
    onRemove,
    onDuplicate,
    onEdit,
    isPackageFetching,
    onClick,
    currentUser,
    onToggleFavoritePackage,
    followSubjectMutation,
  } = props;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleTooltipVisibility = isVisible => {
    setIsTooltipVisible(isVisible);
  };

  const defaultRootClass = isResponsive ? css.rootResponsive : css.root;
  const classes = classNames(rootClassName || defaultRootClass, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title, publicData } = currentListing.attributes;

  const slug = createSlug(title);

  const { location } = publicData;

  const currentPackage = getPackage(currentListing, packageId);

  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);

  const { optionalLodging, includedLodging } = getPackageLodgingPrices(
    currentPackage,
    lodgingPricesOptions
  );

  const packageInfo = currentPackage || {
    ...item,
    price: JSON.stringify(item.price),
  };

  const {
    title: packageTitle,
    days,
    guests,
    isPublished,
    availableDateFrom,
    hasQuantityRestricted,
    quantity: packageQuantity,
    earlyAccessExpiration,
  } = packageInfo;

  const { bookingRangeIsFuture, bookingRangeIsPast } = checkPackageAvailabilty(packageInfo);

  let formattedPackageAvailableDate;

  if (availableDateFrom) {
    const date = parse(availableDateFrom);
    formattedPackageAvailableDate = format(date, 'MMM. D');
  }

  const quantityBooked = packageQuantity?.booked ? packageQuantity.booked : 0;
  const isAvailable = quantityBooked < packageQuantity?.total;

  const showPackageAvailableOverlay =
    (hasQuantityRestricted && !isAvailable) || bookingRangeIsFuture || bookingRangeIsPast;
  const showEarlyAccessBadge =
    isEarlyAccessAvailable(earlyAccessExpiration) && !showPackageAvailableOverlay;

  const packagesImages = getPackageImages(currentListing, packageId);
  const headerImages = packagesImages?.length ? packagesImages : currentListing.images;

  const startingPrice = getStartingPrice({
    ...packageInfo,
    optionalLodging,
    includedLodging,
  });

  const listingLocation = getListingLocation(currentListing);
  const { isRvFriendly, hasRVSites } = getRVDetails(listing, packageInfo);
  const acres = location && location.acreage ? formatNumber(location.acreage) : null;

  const currentAuthor = ensureUser(currentListing.author);

  const isOwnListing =
    currentUser &&
    currentUser.id &&
    currentAuthor &&
    currentAuthor.id &&
    currentAuthor.id.uuid === currentUser.id.uuid;

  const richTitle = (
    <NamedLink
      className={css.titleLink}
      name="ListingPage"
      params={{ id: currentListing.id.uuid, slug }}
    >
      {title}
    </NamedLink>
  );

  const innerImage = (
    <div>
      <ScrollingCarousel
        showDots={!showPackageAvailableOverlay && headerImages.length > 1}
        showNavigationOnHover
      >
        {headerImages.map((image, index) => (
          <Step className={css.step} key={get(image, index)}>
            <div className={css.imageInner}>
              <div className={css.imageInnerBox}>
                <ResponsiveImage
                  rootClassName={isResponsive ? css.carouselResponsiveImage : css.imageRoot}
                  alt={packageTitle}
                  image={image}
                  variants={['scaled-large', 'default']}
                  sizes={renderSizes}
                />
              </div>
            </div>
          </Step>
        ))}
      </ScrollingCarousel>

      {showPackageAvailableOverlay && (
        <div className={css.availablePackageWrapper}>
          <div className={css.availablePackageBlurWrapper}>
            <div className={css.availablePackageBlur} />
          </div>
          <div className={css.availablePackageText}>
            <FormattedHTMLMessage
              id={
                bookingRangeIsFuture
                  ? 'SectionPackageCard.availableInFuture'
                  : 'SectionPackageCard.soldOut'
              }
              values={{
                date: formattedPackageAvailableDate,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );

  const isStartingPrice =
    days?.min !== days?.max || guests.min !== guests.max || optionalLodging.length;

  const mainPackageInfo = (
    <div className={isResponsive ? css.infoContainerResponsive : css.infoContainer}>
      <div className={isResponsive ? css.infoMainResponsive : css.infoMain}>
        <div className={isResponsive ? css.infoTitleResponsive : css.infoTitle}>{packageTitle}</div>

        <div className={isResponsive ? css.infoFooterResponsive : css.infoFooter}>
          {acres ? `${acres} Acres at ` : null}
          {richTitle && richTitle}
          {` in ${listingLocation}`}
        </div>
        <div className={isResponsive ? css.priceInfoLabelResponsive : css.priceInfoLabel}>
          <FormattedMessage
            id={isStartingPrice ? 'SectionPackageCard.startingPrice' : 'SectionPackageCard.price'}
          />
          &nbsp;
          {startingPrice?.amount && (
            <FormattedHTMLMessage
              id="PackageCard.perGuest"
              values={{ price: formatMoney(intl, startingPrice, true) }}
            />
          )}
        </div>
      </div>

      <div className={css.badgesContainer}>
        <PackageBadges
          packageInfo={{
            ...packageInfo,
          }}
          overrideInfo={{
            isRvFriendly,
            hasRVSites,
          }}
        />
      </div>
      <div className={css.badgesContainer}>
        <PackageQuantityBadges packageInfo={packageInfo} isOwnListing={isOwnListing} />
      </div>
    </div>
  );

  const renderCard = () => (
    <div className={classes}>
      {isPackageFetching ? (
        <div className={css.packageLoadingWrapper}>
          <Player
            autoplay
            loop
            src={OpeningPackageJSON}
            style={{
              height: '100px',
            }}
          />

          <div className={css.fetchingStatus}>
            {fetchingStatus === FETCHING_STATUS.DUPLICATE && (
              <FormattedMessage id="PackageCard.fetchingDuplicate" />
            )}
            {fetchingStatus === FETCHING_STATUS.DELETE && (
              <FormattedMessage id="PackageCard.fetchingDelete" />
            )}
            {fetchingStatus === FETCHING_STATUS.PUBLISH && (
              <FormattedMessage id="PackageCard.fetchingPublish" />
            )}
            {fetchingStatus === FETCHING_STATUS.UNPUBLISH && (
              <FormattedMessage id="PackageCard.fetchingUnpublish" />
            )}
          </div>
        </div>
      ) : (
        <>
          {editMode && (
            <div className={css.menuIconWrapper}>
              <MenuTooltip
                tooltipShown={isTooltipVisible}
                onTooltipShown={handleTooltipVisibility}
                content={
                  <ul className={css.menuItemsWrapper}>
                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onEdit(e, item);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuEdit" />
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onDuplicate(e, item);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuDuplicate" />
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => onTogglePublish(e, packageId, !isPublished)}
                      >
                        {isPublished ? (
                          <FormattedMessage id="PackageCard.menuUnpublish" />
                        ) : (
                          <FormattedMessage id="PackageCard.menuPublish" />
                        )}
                      </a>
                    </li>

                    <li>
                      <a
                        className={css.menuItemLink}
                        href="#"
                        onClick={e => {
                          onRemove(e, packageId);
                          setIsTooltipVisible(false);
                        }}
                      >
                        <FormattedMessage id="PackageCard.menuDelete" />
                      </a>
                    </li>
                  </ul>
                }
              />
            </div>
          )}

          <div className={isResponsive ? css.imageWrapperResponsive : css.imageWrapper}>
            {onEdit ? (
              <Link
                className={css.menuItemLink}
                href="#"
                onClick={e => {
                  onEdit(e, item);
                  setIsTooltipVisible(false);
                }}
              >
                {innerImage}
              </Link>
            ) : (
              <>
                <div className={css.favoriteWrapper}>
                  <FavoritePackage
                    currentUser={currentUser}
                    listing={currentListing}
                    package={currentPackage}
                    followSubjectMutation={followSubjectMutation}
                    onUnfavoritePackage={() =>
                      onToggleFavoritePackage(currentPackage, currentListing)
                    }
                    onFavoritePackage={() =>
                      onToggleFavoritePackage(currentPackage, currentListing)
                    }
                  />
                </div>

                {showEarlyAccessBadge && (
                  <div className={css.earlyAccessBadge}>
                    <FormattedMessage id="SectionPackageCard.earlyAccess" />
                  </div>
                )}
                {innerImage}
              </>
            )}
          </div>

          {onEdit ? (
            <Link
              className={css.mainInfoLink}
              href="#"
              onClick={e => {
                onEdit(e, item);
                setIsTooltipVisible(false);
              }}
            >
              {mainPackageInfo}
            </Link>
          ) : (
            mainPackageInfo
          )}
        </>
      )}
    </div>
  );

  if (onClick) {
    return (
      <a
        className={css.linkRoot}
        href="#"
        onClick={e => {
          e.preventDefault();

          onClick({
            selectedPackage: {
              ...currentPackage,
              price: parseMoneyObjString(currentPackage.price),
              lodgingPrice: parseMoneyObjString(currentPackage.lodgingPrice),
            },
            packageId: currentPackage.id,
          });
        }}
      >
        {renderCard()}
      </a>
    );
  }

  if (onEdit) {
    return renderCard();
  }

  return currentListing.type === 'ownListing' ? (
    <NamedLink
      className={css.linkRoot}
      name="PackagePageVariant"
      params={{ id, slug, packageId, variant: listing.attributes.state }}
    >
      {renderCard()}
    </NamedLink>
  ) : (
    <NamedLink className={css.linkRoot} name="PackagePage" params={{ id, slug, packageId }}>
      {renderCard()}
    </NamedLink>
  );
};

SectionPackageCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  onEdit: null,
  editMode: false,
  isPackageFetching: false,
  onDuplicate: null,
  onRemove: null,
  onTogglePublish: null,
  fetchingStatus: null,
  onClick: null,
};

SectionPackageCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  packageId: string.isRequired,
  onEdit: func,
  editMode: bool,
  item: object.isRequired,
  isPackageFetching: bool,
  onDuplicate: func,
  onRemove: func,
  onTogglePublish: func,
  fetchingStatus: oneOf([
    FETCHING_STATUS.DUPLICATE,
    FETCHING_STATUS.DELETE,
    FETCHING_STATUS.PUBLISH,
    FETCHING_STATUS.UNPUBLISH,
  ]),
  onClick: func,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser };
};

const mapDispatchToProps = dispatch => ({
  onToggleFavoritePackage: (pkg, listing) => dispatch(toggleFavoritePackages(pkg, listing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SectionPackageCardComponent));
