/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import { LINE_ITEM_INSURANCE, propTypes } from '../../../util/types';

import css from './LineItems.css';

const LineItemInsurance = props => {
  const { transaction, isCustomer, intl, onClick } = props;

  const lineItem = transaction.attributes.lineItems.find(item => item.code === LINE_ITEM_INSURANCE);
  if (isCustomer && lineItem) {
    const fee = lineItem.lineTotal;
    const formattedFee = fee ? formatMoney(intl, fee) : null;

    return (
      <div className={css.lineItem}>
        <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
          <FormattedMessage id="BookingBreakdown.tripInsurance" />
        </span>
        <span className={css.itemValue}>{formattedFee}</span>
      </div>
    );
  }

  return null;
};

LineItemInsurance.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
  onClick: func.isRequired,
};

export default LineItemInsurance;
