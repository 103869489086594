import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { propTypes } from '../../util/types';
import { ChangePasswordForm } from '../../forms';

const PasswordUpdate = ({
  currentUser,
  onSubmit,
  changePasswordError,
  changePasswordInProgress,
  passwordChanged,
}) => {
  return (
    <div>
      <ChangePasswordForm
        onSubmit={values => {
          onSubmit({
            ...values,
          });
        }}
        changePasswordError={changePasswordError}
        changePasswordInProgress={changePasswordInProgress}
        passwordChanged={passwordChanged}
        currentUser={currentUser}
      />
    </div>
  );
};

PasswordUpdate.defaultProps = {
  onSubmit: null,
  changePasswordError: null,
  changePasswordInProgress: null,
  passwordChanged: null,
};

PasswordUpdate.propTypes = {
  onSubmit: func,
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool,
  passwordChanged: bool,
};
PasswordUpdate.displayName = 'PasswordUpdate';

const mapStateToProps = state => {
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.ProfileSettingsPage;
  return {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  };
};

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  injectIntl
)(PasswordUpdate);
