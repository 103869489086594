import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Modal, InlineTextButton } from '..';

import css from './ResponsiveModal.css';

const ResponsiveModal = props => {
  const {
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    content,
    buttons,
    closeButtonMessage,
    className,
    containerClassName,
  } = props;

  const rootClass = classNames(css.root, className);
  const containerClass = classNames(css.container, containerClassName);

  return (
    <Modal
      id={id}
      containerClassName={rootClass}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <div className={containerClass}>
        {content}

        {buttons}

        <InlineTextButton
          onClick={onCloseModal}
          isFullWidth
          className={css.closeButton}
          type="button"
        >
          {closeButtonMessage}
        </InlineTextButton>
      </div>
    </Modal>
  );
};

ResponsiveModal.propTypes = {};

export default injectIntl(ResponsiveModal);
