import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import { ScrollingCarousel, Step } from '../../components/ScrollingCarousel/ScrollingCarousel';

import ActionBarMaybe from './ActionBarMaybe';
import { InlineTextButton } from '../../components/Button/Button';

import css from './ListingPage.css';

const SectionImages = props => {
  const { title, listing, isOwnListing, editParams, listingImages, listingImageCaptions } = props;

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const hasImages = listingImages && listingImages.length > 0;
  const maxCollageImages = listingImages && listingImages.length > 5;

  const collageClasses = classNames(css.collage, {
    [css.collage1Photo]: hasImages && listingImages.length === 1,
    [css.collage2Photos]: hasImages && listingImages.length === 2,
    [css.collage3Photos]: hasImages && listingImages.length === 3,
    [css.collage4Photos]: hasImages && listingImages.length === 4,
    [css.collage5PlusPhotos]: hasImages && listingImages.length > 4,
  });
  const collageItemClasses = classNames(css.collageItem, {
    [css.collage4PhotosItem]: hasImages && listingImages.length === 4,
    [css.collage5PlusPhotosItem]: hasImages && listingImages.length > 4,
  });
  const collageImages = listingImages.slice(0, 5);

  const handleImageClick = image => {
    setImageCarouselOpen(true);
    setSelectedImage(image || null);
  };

  const onImageCarouselClose = () => {
    setImageCarouselOpen(false);
    setSelectedImage(null);
  };

  const actionBar = listing.id ? (
    <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
  ) : null;
  const viewPhotosButton =
    hasImages && maxCollageImages ? (
      <div className={css.viewImagesWrapper}>
        <div className={css.viewPhotosBlurWrapper}>
          <div className={css.viewPhotosBlur} />
        </div>
        <InlineTextButton className={css.viewPhotos} onClick={() => handleImageClick()}>
          <div className={css.viewPhotosText}>
            <FormattedMessage
              id="ListingPage.viewImagesNumber"
              values={{ count: listingImages.length - 5 }}
            />
          </div>
          <div className={css.viewPhotosText}>
            <FormattedMessage
              id="ListingPage.viewImagesButtonText"
              values={{ count: listingImages.length - 5 }}
            />
          </div>
        </InlineTextButton>
      </div>
    ) : null;

  return (
    <div className={css.sectionImages} data-testid="images-section">
      <ScrollingCarousel
        showButtons={false}
        showDots={hasImages && listingImages.length > 1}
        className={css.carousel}
      >
        {listingImages.map(image => (
          <Step className={css.step} key={image.id.uuid}>
            <ResponsiveImage
              rootClassName={css.carouselResponsiveImage}
              alt={title}
              image={image}
              variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
              onClick={() => handleImageClick(image)}
            />
          </Step>
        ))}
      </ScrollingCarousel>

      <div className={css.gridPhotos}>
        <div className={collageClasses}>
          {collageImages.map((image, index) => (
            <div className={collageItemClasses} key={image.id.uuid}>
              <ResponsiveImage
                rootClassName={css.collageImage}
                alt={title}
                image={image}
                variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
                onClick={() => handleImageClick(image)}
              />

              {index + 1 === collageImages.length && viewPhotosButton}
            </div>
          ))}
        </div>
      </div>

      {actionBar}
      {/* TODO: I'm not sure why modals render their contents when closed */}
      {imageCarouselOpen && (
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          contentClassName={css.modalContentContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
        >
          <ImageCarousel
            images={listingImages}
            selectedImage={selectedImage}
            captions={listingImageCaptions}
          />
        </Modal>
      )}
    </div>
  );
};

export default SectionImages;
