/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './AgritourismStatutes.css';

const LandownerReferral = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        The information provided below has been prepared by LandTrust, and is general background
        information about the various states’ agritourism statutes, and is provided in summary form
        only and does not purport to be complete. The information below does not contain all the
        information that is or may be material to anyone who engages in agritourism. The information
        should not be considered to be legal or other advice, and any landowner considering making
        their land available for agritourism should consult with their own counsel and advisers as
        to all legal, tax, regulatory, financial and related matters concerning an such activities.
        All information below is given as of the date set forth below, and does not reflect any
        changed to the statutes referenced.
      </p>
      <p>
        You are encouraged to review more complete summaries of the statutes at the National
        Agricultural Law Center’s agritourism website{' '}
        <a
          href="https://nationalaglawcenter.org/state-compilations/agritourism"
          target="_blank"
          rel="noreferrer"
        >
          (https://nationalaglawcenter.org/state-compilations/agritourism/)
        </a>
        , which has links to the statutes. You are also encouraged to contact your state’s
        agritourism agency.
      </p>
      <p>This compilation was last updated on 2/9/21.</p>
      <p id="alabama" className={css.anchor} />
      <p>
        <strong>Alabama</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/alabama.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/alabama.pdf
        </a>
      </p>
      <p>
        WARNING. “Under Alabama law, an agritourism professional is not liable for injury, sickness,
        or damage to, or the death of, a participant in an agritourism activity at this location if
        the injury, sickness, damage, or death results from the inherent risks of theagritourism
        activity. “Inherent risks of an agritourism activity include risks of injury, sickness,
        damage, or death inherent to land, equipment, and animals as well as the potential for you
        to act in a negligent manner that may contribute to your injury, sickness, damage, or death,
        or for another participant to act in a manner that may cause your injury, sickness, damage,
        or death. “You are assuming the risk of participating in this agritourism activity.
      </p>
      <p>
        <p id="arizona" className={css.anchor} />
        <strong>Arizona</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Arizona.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/arizona.pdf
        </a>
      </p>

      <p id="arkansas" className={css.anchor} />
      <p>
        <strong>Arkansas</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Arkansas.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/arkansas.pdf
        </a>
      </p>
      <p>
        WARNING -- Under Arkansas law, an agritourism activity operator is not liable for the injury
        or death of a participant in an agritourism activity resulting from the inherent risk of
        agritourism activities. Inherent risks include without limitation the risk of animals,
        weather, land conditions, and the potential for you as a participant to act in a negligent
        way that may contribute to your own injury or death. You are assuming the risk of
        participating in this agritourism activity.
      </p>
      <p id="colorado" className={css.anchor} />
      <p>
        <strong>Colorado</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Colorado.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/colorado.pdf
        </a>
      </p>
      <p>
        WARNING UNDER COLORADO LAW, THERE IS NO LIABILITY FOR THE DEATH OF OR INJURY TO A
        PARTICIPANT IN AN AGRICULTURAL RECREATION OR AGRITOURISM ACTIVITY RESULTING FROM THE
        INHERENT RISKS OF THE AGRICULTURAL RECREATION OR AGRITOURISM ACTIVITY, PURSUANT TO SECTION
        13-21-121, COLORADO REVISED STATUTES.
      </p>
      <p>
        <p id="delaware" className={css.anchor} />
        <strong>Delaware</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Delaware.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/delaware.pdf
        </a>
      </p>
      <p id="florida" className={css.anchor} />
      <p>
        <strong>Florida</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Florida.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/florida.pdf
        </a>
      </p>
      <p>
        WARNING Under Florida law, an agritourism operator is not liable for injury or death of, or
        damage or loss to, a participant in an agritourism activity conducted at this agritourism
        location if such injury, death, damage, or loss results from the inherent risks of the
        agritourism activity. Inherent risks of agritourism activities include, among others, risks
        of injury inherent to land, equipment, and animals, as well as the potential for you to act
        in a negligent manner that may contribute to your injury, death, damage, or loss. You are
        assuming the risk of participating in this agritourism activity.
      </p>
      <p id="georgia" className={css.anchor} />
      <p>
        <strong>Georgia</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Georgia.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/georgia.pdf
        </a>
      </p>
      <p>
        Warning Under Georgia law, there is no liability for an injury or death of a participant at
        least 18 years of age in a registered agritourism activity conducted at this registered
        agritourism location if such injury or death results from the inherent risks of such
        agritourism activity. Inherent risks of agritourism activities include, but shall not be
        limited to, the potential of you to act in a negligent manner that may contribute to your
        injury or death and the potential of another participant to act in a negligent manner that
        may contribute to your injury or death.
      </p>
      <p id="idaho" className={css.anchor} />
      <p>
        <strong>Idaho</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Idaho.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/idaho.pdf
        </a>
      </p>
      <p>
        WARNING Under Idaho law, there are risks associated with agritourism, which could lead to
        injury or death. You are assuming these risks. Section 6-3004, Idaho Code.
      </p>
      <p>
        <p id="illinois" className={css.anchor} />
        <strong>Illinois</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Illinois.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/illinois.pdf
        </a>
      </p>

      <p id="indiana" className={css.anchor} />
      <p>
        <strong>Indiana</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Indiana.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/indiana.pdf
        </a>
      </p>
      <p>
        WARNING Under Indiana law, an agritourism provider is not liable for an injury to, or the
        death of, a participant in agritourism activities at this location if the death or injury
        results from the inherent risks of agritourism activity. Inherent risks of agritourism
        activities include risks of injury inherent to land, equipment, and animals as well as the
        potential for you to act in a negligent manner that may contribute to your injury or death,
        or for other participants to act in a manner that may cause you injury or cause your death.
        You are assuming the risk of participating in this agritourism activity
      </p>
      <p id="kansas" className={css.anchor} />
      <p>
        <strong>Kansas</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Kansas.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/kansas.pdf
        </a>
      </p>
      <p>
        WARNING Under Kansas law, there is no liability for an injury or death of a participant in a
        registered agritourism activity conducted at this registered agritourism location if such
        injury or death results from the inherent risks of such agritourism activity. Inherent risks
        of agritourism activities include, but shall not be limited to, the potential of you as a
        participant to act in a negligent manner that may contribute to your injury or death and the
        potential of another participant to act in a negligent manner that may contribute to your
        injury or death. You are assuming the risk of participating in this registered agritourism
        activity.
      </p>
      <p id="kentucky" className={css.anchor} />
      <p>
        <strong>Kentucky</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Kentucky.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/kentucky.pdf
        </a>
      </p>
      <p>
        WARNING Under Kentucky law, there is no liability for an injury to or death of a participant
        in an agritourism activity conducted at this agritourism location if the injury or death
        results exclusively from the inherent risks of the agritourism activity and in the absence
        of negligence. You are assuming the risk of participating in this agritourism activity.
      </p>
      <p id="louisiana" className={css.anchor} />
      <p>
        <strong>Louisiana</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Louisiana.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/louisiana.pdf
        </a>
      </p>
      <p>
        WARNING Under Louisiana law, R.S. 9:2795.5, there is no liability for an injury to or death
        of a participant in an agritourism activity conducted at this agritourism location if such
        injury or death results from the inherent risks of the agritourism activity. Inherent risks
        of agritourism activities include, among others, risks of injury inherent to land,
        equipment, and animals, as well as the potential for you to act in a negligent manner that
        may contribute to your injury or death. You are assuming the risk of participating in this
        agritourism activity.
      </p>
      <p id="maine" className={css.anchor} />
      <p>
        <strong>Maine</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Maine.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/maine.pdf
        </a>
      </p>
      <p>
        WARNING Under Maine law, there is no liability for injury to a participant in an agritourism
        activity conducted at this agritourism location if such injury results from the inherent
        risks of the agritourism activity. Inherent risks of agritourism activities include, among
        others, risks of injury inherent to land, equipment and animals, as well as the potential
        for injury if you act in a negligent manner. You are assuming the risk of participating in
        this agritourism activity.
      </p>
      <p id="maryland" className={css.anchor} />
      <p>
        <strong>Maryland</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Maryland.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/maryland.pdf
        </a>
      </p>
      <p>
        WARNING: Pursuant to Natural Resources Article Section 5–1109, each person who uses this
        property for hunting consents to adhere to every law, to observe every safety precaution and
        practice, to observe all property boundaries, to take every precaution against fire, and to
        assume all responsibility and liability for your safety and your property while hunting on
        this property.
      </p>
      <p>
        <p id="massachusetts" className={css.anchor} />
        <strong>Massachusetts</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Massachusetts.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Massachusetts.pdf
        </a>
      </p>
      <p id="mississippi" className={css.anchor} />
      <p>
        <strong>Mississippi</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Mississippi.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/mississippi.pdf
        </a>
      </p>
      <p>
        WARNING Under Mississippi law, there is no liability for an injury to or death of a
        participant in an agritourism activity conducted at this agritourism location if the injury
        or death results from the inherent risks of the agritourism activity. Inherent risks of
        agritourism activities include, among others, risks of injury inherent to land, equipment
        and animals, as well as the potential for you or another participant to act in a negligent
        manner that may contribute to your injury or death. You are assuming the risk of
        participating in this agritourism activity.
      </p>
      <p id="missouri" className={css.anchor} />
      <p>
        <strong>Missouri</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Missouri.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/missouri.pdf
        </a>
      </p>
      <p>
        WARNING: Under Missouri law, there is no liability for an injury or death of a participant
        in a registered agritourism activity conducted at this registered agritourism location if
        such injury or death results from the inherent risks of such agritourism activity. Inherent
        risks of agritourism activities include, but are not limited to, the potential of you as a
        participant to act in a negligent manner that may contribute to your injury or death and the
        potential of another participant to act in a negligent manner that may contribute to your
        injury or death. You are assuming the risk of participating in this registered agritourism
        activity.
      </p>
      <p id="montana" className={css.anchor} />
      <p>
        <strong>Montana</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Montana.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/montana.pdf
        </a>
      </p>
      <p>
        By signing this document you may be waiving your legal right to a jury trial to hold the
        provider legally responsible for any injuries or damages resulting from risks inherent in
        the sport or recreational opportunity or for any injuries or damages you may suffer due to
        the provider's ordinary negligence that are the result of the provider's failure to exercise
        reasonable care.
      </p>
      <p id="nebraska" className={css.anchor} />
      <p>
        <strong>Nebraska</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Nebraska.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/nebraska.pdf
        </a>
      </p>
      <p>
        WARNING--Under Nebraska law, an owner of property, including lands and waters, is not liable
        for the injury to or death of the participant in agritourism activities or damage to the
        participant's property resulting from the inherent risks of such activities. Inherent risks
        include, without limitation, the risk of animals and land and water conditions, the ordinary
        dangers of structures or equipment ordinarily used in farming or ranching operations, and
        the potential for you or another participant to act in a negligent manner that may
        contribute to your own injury or death. You are assuming the risk of participating in the
        agritourism activities for which you are entering the owner's premises.
      </p>
      <p id="north-carolina" className={css.anchor} />
      <p>
        <strong>North Carolina</strong>
      </p>
      <p>
        WARNING Under North Carolina law, there is no liability for an injury to or death of a
        participant in an agritourism activity conducted at this agritourism location if such injury
        or death results from the inherent risks of the agritourism activity. Inherent risks of
        agritourism activities include, among others, risks of injury inherent to land, equipment,
        and animals, as well as the potential for you to act in a negligent manner that may
        contribute to your injury or death. You are assuming the risk of participating in this
        agritourism activity.
      </p>
      <p>
        <p id="new-hampshire" className={css.anchor} />
        <strong>New Hampshire</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/New-Hampshire.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/New-Hampshire.pdf
        </a>
      </p>
      <p>
        <p id="new-york" className={css.anchor} />
        <strong>New York</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/New-York.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/New-York.pdf
        </a>
      </p>
      <p>
        <p id="north-carolina" className={css.anchor} />
        <strong>North Carolina</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/North-Carolina.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/North-Carolina.pdf
        </a>
      </p>
      <p id="north-dakota" className={css.anchor} />
      <p>
        <strong>North Dakota</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/North-Dakota.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/North-Dakota.pdf
        </a>
      </p>
      <p>
        Under the laws of this state, the registered agritourism operator is not liable for any
        injury to or for the death of a participant if the injury or death results from an inherent
        risk.
      </p>
      <p id="oklahoma" className={css.anchor} />
      <p>
        <strong>Oklahoma</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Oklahoma.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Oklahoma.pdf
        </a>
      </p>
      <p>
        WARNING Under Oklahoma law, there is no liability for an injury to or death of a participant
        in an agritourism activity conducted at this agritourism location if such injury or death
        results from the inherent risks of the agritourism activity. Inherent risks of agritourism
        activities include, among others, risks of injury inherent to land, equipment, and animals,
        as well as the potential for you to act in a negligent manner that may contribute to your
        injury or death. You are assuming the risk of participating in this agritourism activity.
      </p>
      <p id="oregon" className={css.anchor} />
      <p>
        <strong>Oregon</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Oregon.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Oregon.pdf
        </a>
      </p>
      <p>
        WARNING Under Oregon law, there is no liability for an injury to or the death of a
        participant in an agri-tourism activity conducted at this agri-tourism location if the
        injury or death results from the inherent risks of the agri-tourism activity. Inherent risks
        of agri-tourism activities are risks of injury inherent to land, equipment and animals, as
        well as the potential for you to act in a negligent manner that may contribute to your
        injury or death. You are assuming the risk of participating in this agri-tourism activity.
      </p>

      <p>
        <p id="pennsylvania" className={css.anchor} />
        <strong>Pennsylvania</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Pennsylvania.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Pennsylvania.pdf
        </a>
      </p>
      <p id="south-carolina" className={css.anchor} />
      <p>
        <strong>South Carolina</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/South-Carolina.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/South-Carolina.pdf
        </a>
      </p>
      <p>
        WARNING ! Under South Carolina law, an agritourism professional is not liable for an injury
        to or the death of a participant in an agritourism activity resulting from an inherent risk
        associated with the agritourism activity. (Chapter 53, Title 46, Code of Laws of South
        Carolina, 1976).
      </p>
      <p id="tennessee" className={css.anchor} />
      <p>
        <strong>Tennessee</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Tennessee.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Tennessee.pdf
        </a>
      </p>
      <p>
        WARNING Under Tennessee law, there is no liability for an injury to or death of a
        participant in an agritourism activity conducted at this agritourism location or by this
        agritourism professional if such injury or death results from the inherent risks of the
        agritourism activity. Inherent risks of agritourism activities include, among others, risks
        of injury inherent to land, equipment, and animals, as well as the potential for you to act
        in a negligent manner that may contribute to your injury or death. You are assuming the risk
        of participating in this agritourism activity.
      </p>
      <p id="texas" className={css.anchor} />
      <p>
        <strong>Texas</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Texas.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Texas.pdf
        </a>
      </p>
      <p>
        WARNING UNDER TEXAS LAW (CHAPTER 75A, CIVIL PRACTICE AND REMEDIES CODE), AN AGRITOURISM
        ENTITY IS NOT LIABLE FOR ANY INJURY TO OR DEATH OF AN AGRITOURISM PARTICIPANT RESULTING FROM
        AN AGRITOURISM ACTIVITY.
      </p>

      <p>
        <p id="utah" className={css.anchor} />
        <strong>Utah</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Utah.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Utah.pdf
        </a>
      </p>
      <p>
        <p id="vermont" className={css.anchor} />
        <strong>Vermont</strong>{' '}
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Vermont.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Vermont.pdf
        </a>
      </p>
      <p id="virginia" className={css.anchor} />
      <p>
        <strong>Virginia</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Virginia.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Virginia.pdf
        </a>
      </p>
      <p>
        WARNING Under Virginia law, there is no liability for an injury to or death of a participant
        in an agritourism activity conducted at this agritourism location if such injury or death
        results from the inherent risks of the agritourism activity. Inherent risks of agritourism
        activities include, among others, risks of injury inherent to land, equipment, and animals,
        as well as the potential for you to act in a negligent manner that may contribute to your
        injury or death. You are assuming the risk of participating in this agritourism activity.
      </p>
      <p id="washington" className={css.anchor} />
      <p>
        <strong>Washington</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Washington.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Washington.pdf
        </a>
      </p>
      <p>
        WARNING Under Washington state law, there is limited liability for an injury to or death of
        a participant in an agritourism activity conducted at this agritourism location if such an
        injury or death results exclusively from the inherent risks of the agritourism activity.
        Inherent risks of agritourism activities include, among others, risks of injury inherent to
        land, equipment, and animals, as well as the potential for you to act in a negligent manner
        that may contribute to your injury or death. We are required to ensure that in any activity
        involving minor children, only age-appropriate access to activities, equipment, and animals
        is permitted. You are assuming the risk of participating in this agritourism activity.
      </p>
      <p id="west-virginia" className={css.anchor} />
      <p>
        <strong>West Virginia</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/West-Virginia.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/West-Virginia.pdf
        </a>
      </p>
      <p>
        NOTICE Under West Virginia law, there may be limited liability for an injury to or death of
        a participant in an agritourism activity conducted at this agritourism business if the
        injury or death results from the inherent risks of the agritourism activity. Inherent risks
        of agritourism activities include, among others, risks of injury inherent to landscape,
        terrain, equipment, and animals, as well as the potential for you to act in a negligent
        manner that may contribute to your injury or death. You are assuming the risk of
        participating in this agritourism activity.
      </p>
      <p id="wisconsin" className={css.anchor} />
      <p>
        <strong>Wisconsin</strong>
      </p>
      <p>
        <a
          href="https://nationalaglawcenter.org/wp-content/uploads/assets/agritourism/Wisconsin.pdf"
          target="_blank"
          rel="noreferrer"
          className={css.agriLink}
        >
          Nationalaglawcenter.org/Wisconsin.pdf
        </a>
      </p>
      <p>
        Notice: A person who observes or participates in an agricultural tourism activity on this
        property assumes the risks inherent in the agricultural tourism activity. Risks inherent in
        the agricultural tourism activity may include conditions on the land, the unpredictable
        behavior of farm animals, the ordinary dangers associated with equipment used in farming
        operations, and the potential that a participant in the agricultural tourism activity may
        act in a negligent way that may contribute to injury or death. The agricultural tourism
        provider is not liable for the injury or death of a person involved in an agricultural
        tourism activity resulting from those inherent risks.
      </p>
    </div>
  );
};

LandownerReferral.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

LandownerReferral.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandownerReferral;
