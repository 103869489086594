import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './EditListingError.css';

const EditListingError = ({ fetchErrors }) => {
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};

  return (
    <>
      {createListingDraftError && (
        <p className={css.error}>
          <FormattedMessage id="EditListingError.createListingDraftError" />
        </p>
      )}

      {updateListingError && (
        <p className={css.error}>
          <FormattedMessage id="EditListingError.updateFailed" />
        </p>
      )}

      {showListingsError && (
        <p className={css.error}>
          <FormattedMessage id="EditListingError.showListingFailed" />
        </p>
      )}
    </>
  );
};

EditListingError.defaultProps = {
  fetchErrors: null,
};

EditListingError.propTypes = {
  fetchErrors: object,
};

export default EditListingError;
