import React from 'react';
import { bool, node, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldPillInput.css';

const FieldPillInput = ({ rootClassName, className, label, value, name, id, type, ...props }) => {
  const classes = classNames(rootClassName || css.root, className);

  const pillProps = {
    className: css.inputRoot,
    component: 'input',
    type,
    name,
    id,
    ...props,
  };

  return (
    <span className={classes}>
      <Field value={value} {...pillProps} />

      <label htmlFor={id} className={css.inputLabel}>
        <span>{label}</span>
      </label>
    </span>
  );
};

FieldPillInput.propTypes = {
  rootClassName: string,
  className: string,
  label: node,
  type: string,

  id: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
};

FieldPillInput.defaultProps = {
  rootClassName: null,
  className: null,
  label: '',
  type: 'checkbox',
};

export default FieldPillInput;
