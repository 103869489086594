import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionReview2 = ({ transition, ownRole, user, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.ownTransitionReview" values={{ displayName }} />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        )
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    />
  );
};

export default InboxTransitionReview2;
