import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Accordion } from '..';

import css from './SectionFAQ.css';

const SectionFAQ = props => {
  const { faqs } = props;

  return (
    <div className={css.sectionContainer}>
      <h3 className={css.sectionHeader}>
        <FormattedMessage id="SectionFAQ.SectionFAQHeading" />
      </h3>
      <div className={css.accordionContainer}>
        <Accordion items={faqs} />
      </div>
    </div>
  );
};

SectionFAQ.defaultProps = {};

SectionFAQ.propTypes = {};

export default SectionFAQ;
