import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { InlineTextButton, NamedLink } from '..';
import treesImage from './images/trees.webp';

import css from './ModalMissingInformation.css';

const EmailReminder = props => {
  const {
    className,
    user,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
  } = props;

  const email = user.id ? <span className={css.email}>{user.attributes.email}</span> : '';
  const isLandowner = get(user, 'attributes.profile.protectedData.role', []).includes('landowner');

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.ctaLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="ModalMissingInformation.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.ctaLink} name="ProfileSettingsBasePage">
      <FormattedMessage id="ModalMissingInformation.fixEmailLinkText" />
    </NamedLink>
  );

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'ModalMissingInformation.resendFailedTooManyRequests'
    : 'ModalMissingInformation.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const classes = classNames(css.twoColumnContainer, className);

  return (
    <div className={classes}>
      <div
        className={classNames(css.firstColumn)}
        style={{ backgroundImage: `url(${treesImage})` }}
      />

      <div className={css.secondColumn}>
        <div className={css.innerContentContainer}>
          <p className={css.modalTitle}>
            {isLandowner && (
              <FormattedMessage id="ModalMissingInformation.verifyEmailTitleLandowner" />
            )}
            {!isLandowner && (
              <FormattedMessage id="ModalMissingInformation.verifyEmailTitleGuest" />
            )}
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModalMissingInformation.verifyEmailText" values={{ email }} />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModalMissingInformation.checkInbox" />
          </p>

          {resendErrorMessage}

          <div className={css.bottomWrapper}>
            <p className={css.helperText}>
              {sendVerificationEmailInProgress ? (
                <FormattedMessage id="ModalMissingInformation.sendingEmail" />
              ) : (
                <FormattedMessage
                  id="ModalMissingInformation.resendEmail"
                  values={{ resendEmailLink }}
                />
              )}
            </p>
            <p className={css.helperText}>
              <FormattedMessage id="ModalMissingInformation.fixEmail" values={{ fixEmailLink }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailReminder;
