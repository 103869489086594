import React, { useCallback, useReducer } from 'react';
import { Field } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { object, bool, array, func } from 'prop-types';
import arrayMove from 'array-move';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';
import { shouldShowErrorForField } from '../../util/forms';
import {
  required,
  composeValidators,
  nonEmptyArray,
  each,
  propertyValidator,
} from '../../util/validators';
import {
  EditListingPhotoPreviews,
  EditListingUploadProgressBar,
  ValidationError,
} from '../../components';
import { initialUploadState, uploadActions, uploadReducer } from './reducer';
import ImageCategory from './EditListingPhotosImageCategory';

import { ReactComponent as FeederIcon } from './images/feeder.svg';
import { ReactComponent as GameCameraIcon } from './images/game-camera.svg';
import { ReactComponent as LandscapeIcon } from './images/landscape.svg';
import { ReactComponent as LodgingIcon } from './images/lodging.svg';
import { ReactComponent as PlusIcon } from './images/plus.svg';
import { ReactComponent as WaterSourceIcon } from './images/water-source.svg';
import { ReactComponent as WildlifeIcon } from './images/wildlife.svg';
import { ReactComponent as FishIcon } from './images/fish.svg';
import { ReactComponent as BinocularesIcon } from './images/binoculars.svg';
import { ReactComponent as CrowIcon } from './images/crow.svg';
import { ReactComponent as RunningIcon } from './images/running.svg';

import css from './EditListingPhotosForm.css';

const ACCEPT_IMAGES = ['.jpg', '.jpeg', '.png'];

// We need an error message to convince react-final-form the form is invalid
// but we don't want to display that error to the user in this case
const DONT_SHOW_THIS_ERROR = 'DONT_SHOW_THIS_ERROR';

// If something has a tmpId id because it has recently been uploaded then keep using it
// even after it gets it's full id back from the API
const imageIdsForComparison = images =>
  images ? images.map(image => image.tmpId || image.id.uuid).join(',') : null;

const EditListingPhotosMultiUploadField = ({
  form,
  requestImageUpload,
  intl,
  categories,
  isRequired,
}) => {
  const [uploadState, uploadDispatch] = useReducer(uploadReducer, initialUploadState);
  const getCurrentImagesValue = () => form.getFieldState('images').value;

  const startUpload = useCallback(
    (tmpId, file) => {
      const handleUploadProgress = progressEvent => {
        const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;

        uploadDispatch({
          type: uploadActions.PROGRESS,
          payload: { tmpId, progress: percentCompleted },
        });
      };

      uploadDispatch({ type: uploadActions.STARTED, payload: { tmpId, file } });

      requestImageUpload(file, handleUploadProgress)
        .then(apiId => {
          uploadDispatch({
            type: uploadActions.SUCCESS,
            payload: { tmpId, apiId },
          });

          const currentImages = getCurrentImagesValue();

          form.change(
            'images',
            currentImages.map(currentImage => {
              if (currentImage.tmpId && currentImage.tmpId === tmpId) {
                return {
                  ...currentImage,
                  id: apiId,
                };
              }

              return currentImage;
            })
          );
        })
        .catch(e => {
          let message = 'EditListingPhotosForm.imageUploadFailed.uploadFailed';

          if (e?.data?.errors[0]?.code === 'request-upload-over-limit') {
            message = 'EditListingPhotosForm.imageUploadFailed.uploadOverLimit';
          }

          uploadDispatch({
            type: uploadActions.ERROR,
            payload: {
              tmpId,
              error: intl.formatMessage({
                id: message,
              }),
            },
          });
        });
    },
    [requestImageUpload]
  );

  const onDrop = useCallback(acceptedFiles => {
    const currentImages = getCurrentImagesValue();

    const newImages = [];
    const files = {};

    acceptedFiles.forEach(file => {
      const tmpId = `${btoa(file.name)}_${Date.now()}`;

      // id is null until the upload is finished
      newImages.push({ tmpId, id: null });
      files[tmpId] = file;
    });

    // Programmatically change the value but issue focus/blur so that values
    // like `touched` get updated as if the user interacted with a normal input
    form.focus('images');
    form.change('images', [...currentImages, ...newImages]);
    form.blur('images');

    newImages.forEach(({ tmpId }) => {
      startUpload(tmpId, files[tmpId]);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPT_IMAGES,
  });

  return (
    <Field
      name="images"
      isEqual={(a, b) => imageIdsForComparison(a) === imageIdsForComparison(b)}
      validate={
        isRequired &&
        composeValidators(
          nonEmptyArray(
            intl.formatMessage({
              id: 'EditListingPhotosForm.imageRequired',
            })
          ),

          each(propertyValidator('id.uuid', required(DONT_SHOW_THIS_ERROR)))
        )
      }
    >
      {({ input, meta }) => (
        <>
          <div
            {...getRootProps()}
            className={classNames(css.dropzoneContainer, {
              [css.dropzoneContainerActive]: isDragActive,
            })}
          >
            <input {...getInputProps()} />

            <div className={css.imageCategories}>
              {(!categories || categories.includes('game-camera')) && (
                <ImageCategory
                  icon={<GameCameraIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.gameCamera" />
                  }
                />
              )}

              {(!categories || categories.includes('fish')) && (
                <ImageCategory
                  icon={<FishIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.fishLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('birds')) && (
                <ImageCategory
                  icon={<CrowIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.birdLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('lodging')) && (
                <ImageCategory
                  icon={<LodgingIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.lodgingLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('wildlife')) && (
                <ImageCategory
                  icon={<WildlifeIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.wildlifeLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('landscape')) && (
                <ImageCategory
                  icon={<LandscapeIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.landscapeLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('water-sources')) && (
                <ImageCategory
                  icon={<WaterSourceIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.waterSourceLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('food-sources')) && (
                <ImageCategory
                  icon={<FeederIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.feederLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('guests')) && (
                <ImageCategory
                  icon={<BinocularesIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.guestsLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('activities')) && (
                <ImageCategory
                  icon={<RunningIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.activitiesLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('more')) && (
                <ImageCategory
                  icon={<PlusIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.plusLabel" />
                  }
                />
              )}

              {(!categories || categories.includes('add')) && (
                <ImageCategory
                  icon={<PlusIcon />}
                  label={
                    <FormattedMessage id="EditListingPhotosForm.imageUploadCategory.addLabel" />
                  }
                />
              )}
            </div>

            {isDragActive && (
              <div className={css.dropzoneDropNotification}>
                <p className={css.dropzoneDropNotificationLabel}>
                  <FormattedMessage id="EditListingPhotosForm.imageUploadDropNotification" />
                </p>
              </div>
            )}
          </div>

          <EditListingUploadProgressBar
            className={css.progressBar}
            progress={uploadState.progress}
            files={uploadState.files}
          />

          <EditListingPhotoPreviews
            className={css.photoPreviews}
            images={input.value}
            files={uploadState.files}
            errors={uploadState.errors}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            savedImageCaptionPlaceholderText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageCaptionPlaceholderText',
            })}
            onRemoveImage={({ tmpId, id }) => {
              input.onFocus();
              input.onChange(
                input.value.filter(image => {
                  if (tmpId && image.tmpId === tmpId) return false;
                  if (id && image.id === id) return false;

                  return true;
                })
              );
              input.onBlur();
            }}
            onUpdateImageOrder={({ oldIndex, newIndex }) => {
              // input.onFocus();
              input.onChange(arrayMove(input.value, oldIndex, newIndex));
              // input.onBlur();
            }}
          />

          {shouldShowErrorForField(meta) && meta.error !== DONT_SHOW_THIS_ERROR ? (
            <ValidationError fieldMeta={meta} />
          ) : null}
        </>
      )}
    </Field>
  );
};

EditListingPhotosMultiUploadField.defaultProps = {
  isRequired: true,
};

EditListingPhotosMultiUploadField.propTypes = {
  form: object.isRequired,
  requestImageUpload: func.isRequired,
  intl: intlShape.isRequired,
  categories: array.isRequired,
  isRequired: bool,
};

export default EditListingPhotosMultiUploadField;
