/* eslint-disable no-param-reassign */
import React, { useMemo, useRef } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { ensureOwnListing } from '../../util/data';
import { OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';
import { getInitialPackage } from '../../util/package';
import { createResourceLocatorString } from '../../util/routes';
import { EditListingPackagesSection, EditListingBaseExperiencePanel } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import {
  EditListingOutdoorRecreationExperienceForm,
  EditListingCreatePackageForm,
} from '../../forms';

const getInitialValues = () => {
  return {};
};

const getSubmissionValues = listing => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentActivities = get(publicData, 'activities', {});

  const submissionValues = {
    price: { _sdkType: 'Money', amount: 0, currency: 'USD' },
    publicData: {
      activities: {
        ...currentActivities,
        [OUTDOOR_RECREATION_ACTIVITY]: {
          ...currentActivities[OUTDOOR_RECREATION_ACTIVITY],
        },
      },
    },
  };

  return submissionValues;
};

const EditListingOutdoorRecreationExperiencePanel = props => {
  const {
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    intl,
    params,
    history,
  } = props;
  const updatedValuesRef = useRef({});
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingOutdoorRecreationExperiencePanel.title" />
  ) : (
    <FormattedMessage id="EditListingOutdoorRecreationExperiencePanel.createListingTitle" />
  );

  const initialValues = useMemo(() => {
    const values = getInitialValues(listing);

    return {
      ...values,
      ...updatedValuesRef.current,
    };
  }, [listing]);

  return (
    <EditListingBaseExperiencePanel
      title={panelTitle}
      listing={currentListing}
      activity={OUTDOOR_RECREATION_ACTIVITY}
      params={params}
    >
      {baseProps => {
        return (
          <>
            <EditListingOutdoorRecreationExperienceForm
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                onSubmit(getSubmissionValues(currentListing, values, baseProps.packages));
              }}
              onUpdate={values => {
                // Keep the values of the child form. I had to use ref here to avoid too many state updates
                updatedValuesRef.current = values;
              }}
              onChange={onChange}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              backLink={backLink}
              listing={currentListing}
              packages={baseProps.packages}
              packageSection={
                <EditListingPackagesSection
                  onCreatePackageMode={baseProps.handleCreatePackage}
                  onEditPackage={item => {
                    baseProps.handleEditPackage(item);

                    history.push(
                      createResourceLocatorString('EditListingPagePackage', routeConfiguration(), {
                        slug: params.slug,
                        id: params.id,
                        type: params.type,
                        tab: params.tab,
                        packageId: item.id,
                      })
                    );
                  }}
                  onTogglePublishPackage={baseProps.handleTogglePublishPackage}
                  onDuplicatePackage={baseProps.handleUpdatePackage}
                  onUpdatePackage={baseProps.handleUpdatePackage}
                  onRemovePackage={baseProps.handleRemovePackage}
                  packages={baseProps.packages}
                  selectedPackage={baseProps.selectedPackage}
                  currentListing={currentListing}
                />
              }
            />
            <EditListingCreatePackageForm
              activity={OUTDOOR_RECREATION_ACTIVITY}
              initialValues={getInitialPackage(
                baseProps.selectedPackage,
                OUTDOOR_RECREATION_ACTIVITY
              )}
              onClose={() => {
                baseProps.setCreatePackageMode(false);
                history.push(
                  createResourceLocatorString('EditListingPage', routeConfiguration(), {
                    slug: params.slug,
                    id: params.id,
                    type: params.type,
                    tab: params.tab,
                  })
                );
              }}
              currentListing={currentListing}
              active={baseProps.createPackageMode}
              onUpdatePackage={async values => {
                await baseProps.handleUpdatePackage(values);
              }}
              titlePlaceholder={intl.formatMessage({
                id: 'EditListingOutdoorRecreationExperiencePanel.packageTitlePlaceholder',
              })}
              descriptionPlaceholder={intl.formatMessage({
                id: 'EditListingOutdoorRecreationExperiencePanel.packageDescriptionPlaceholder',
              })}
              intl={intl}
              isOpen={baseProps.createPackageMode}
            />
          </>
        );
      }}
    </EditListingBaseExperiencePanel>
  );
};

EditListingOutdoorRecreationExperiencePanel.defaultProps = {
  listing: null,
  backLink: null,
};

EditListingOutdoorRecreationExperiencePanel.propTypes = {
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,

  params: object.isRequired,
};

export default injectIntl(EditListingOutdoorRecreationExperiencePanel);
