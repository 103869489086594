import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import css from './CountdownTimer.css';

const CountdownTimer = ({ expirationTimestamp, onTimeUpdate }) => {
  const [timeLeft, setTimeLeft] = useState('');

  const calculateTimeLeft = useCallback(() => {
    if (expirationTimestamp) {
      const now = moment.utc();
      const expiration = moment.unix(expirationTimestamp);
      const duration = moment.duration(expiration.diff(now));

      if (duration.asSeconds() > 0) {
        if (onTimeUpdate) {
          onTimeUpdate(duration.asSeconds());
        }

        return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
      }

      if (onTimeUpdate) {
        onTimeUpdate(0);
      }
      return null;
    }
    return null;
  }, [expirationTimestamp, onTimeUpdate]);

  useEffect(() => {
    // Set initial time left immediately
    setTimeLeft(calculateTimeLeft());

    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft) {
        setTimeLeft(newTimeLeft);
      } else {
        setTimeLeft(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationTimestamp, calculateTimeLeft]);

  if (!timeLeft) return null;

  return <div className={css.root}>{timeLeft}</div>;
};

export default CountdownTimer;
