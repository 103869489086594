import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconEdit, PrimaryButton, InlineTextButton } from '..';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import css from './StripeConnectAccountStatusBox.css';

const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
const STATUS_VERIFICATION_ERROR = 'verificationError';

const StripeConnectAccountStatusBox = props => {
  const { type, onGetStripeConnectAccountLink, inProgress, disabled } = props;

  if (type === STATUS_VERIFICATION_NEEDED) {
    return (
      <div className={css.verifySection}>
        <div className={css.verifySectionHeader}>
          <ShieldIcon />
          <span>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" />
          </span>
        </div>

        <div className={css.verifySectionContent}>
          <p>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText1" />
          </p>
          <p>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText2" />
          </p>
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </PrimaryButton>
      </div>
    );
  }
  if (type === STATUS_VERIFICATION_SUCCESS) {
    return (
      <div className={css.verifySection}>
        <div className={css.verifySectionHeader}>
          <ShieldIcon />
          <span>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" />
          </span>
        </div>

        <InlineTextButton
          className={css.editVerificationButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
          <FormattedMessage id="StripeConnectAccountStatusBox.editAccountButton" />
        </InlineTextButton>
      </div>
    );
  }
  if (type === STATUS_VERIFICATION_ERROR) {
    return (
      <div className={css.verifySection}>
        <div className={css.verifySectionHeader}>
          <ShieldIcon />
          <span>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedTitle" />
          </span>
        </div>

        <div className={css.verifySectionContent}>
          <p>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
          </p>
          {/* TODO: updates this with copy */}
          <p>
            <a
              className={css.linkText}
              href="https://landtrust.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationVideo" />
            </a>
          </p>
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </PrimaryButton>
      </div>
    );
  }
  throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
};

export default StripeConnectAccountStatusBox;
