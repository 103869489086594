import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fetchMoreMessages, sendMessage } from '../../ducks/Transaction.duck';
import { ensureTransaction } from '../../util/data';

import InboxFeed from './InboxFeed/InboxFeed';

import css from './InboxMessages.css';

const InboxMessages = ({
  transactions,
  params,
  currentUser,
  onShowMoreMessages,
  fetchMessagesInProgress,
  fetchMessagesError,
  totalMessagePages,
  oldestMessagePageFetched,
  messages,
  initialMessageFailedToTransaction,
  isFetching,
  transactionState,
}) => {
  const transaction = transactions.find(tx => tx.id.uuid === params.id);

  if (!transaction) {
    return null;
  }

  const currentTransaction = ensureTransaction(transaction);

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const contentClassName = classNames(css.content, {
    [css.contentLoading]: isFetching,
  });

  return (
    <div className={css.root}>
      <div className={contentClassName}>
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <>
            <InboxFeed
              currentTransaction={transaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onShowMoreMessages={() => onShowMoreMessages(transaction.id)}
              totalMessagePages={totalMessagePages}
              transactionState={transactionState}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendMessageError,
  } = state.Transaction;

  return {
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendMessageError,
  };
};

const mapDispatchToProps = dispatch => ({
  onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
  onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(InboxMessages));
