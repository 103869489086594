import { get } from 'lodash';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureOwnListing } from '../../util/data';

const getHouseValues = house => ({
  sportsmen: get(house, 'sportsmen', 0),
  bedrooms: get(house, 'bedrooms', 0),
  sofaBeds: get(house, 'sofaBeds', 0),
  bunkBeds: get(house, 'bunkBeds', 0),
  singleBeds: get(house, 'singleBeds', 0),
  doubleBeds: get(house, 'doubleBeds', 0),
  queenBeds: get(house, 'queenBeds', 0),
  kingBeds: get(house, 'kingBeds', 0),
  bedroomInfo: get(house, 'bedroomInfo', null),
  bathrooms: get(house, 'bathrooms', 0),
  hasShower: get(house, 'hasShower', false),
  hasHotWater: get(house, 'hasHotWater', false),
  bathroomInfo: get(house, 'bathroomInfo', null),
  amenities: get(house, 'amenities', []),
  amenitiesInfo: get(house, 'amenitiesInfo', null),
  hasCooking: get(house, 'hasCooking', false),
  cooking: get(house, 'cooking', []),
  cookingInfo: get(house, 'cookingInfo', null),
  hasGarbage: get(house, 'hasGarbage', false),
  garbage: get(house, 'garbage', []),
  garbageInfo: get(house, 'garbageInfo', null),
  allowsPets: get(house, 'allowsPets', false),
  petsInfo: get(house, 'petsInfo', null),
  hasWifi: get(house, 'hasWifi', false),
  wifiInfo: get(house, 'wifiInfo', null),
});

const getCabinValues = cabin => ({
  sportsmen: get(cabin, 'sportsmen', 0),
  bedrooms: get(cabin, 'bedrooms', 0),
  sofaBeds: get(cabin, 'sofaBeds', 0),
  bunkBeds: get(cabin, 'bunkBeds', 0),
  singleBeds: get(cabin, 'singleBeds', 0),
  doubleBeds: get(cabin, 'doubleBeds', 0),
  queenBeds: get(cabin, 'queenBeds', 0),
  kingBeds: get(cabin, 'kingBeds', 0),
  bedroomInfo: get(cabin, 'bedroomInfo', null),
  hasElectricity: get(cabin, 'hasElectricity', false),
  electricityInfo: get(cabin, 'electricityInfo', null),
  hasToilet: get(cabin, 'hasToilet', false),
  toilets: get(cabin, 'toilets', 0),
  toiletInfo: get(cabin, 'toiletInfo', null),
  hasShower: get(cabin, 'hasShower', false),
  showers: get(cabin, 'showers', 0),
  hasHotWater: get(cabin, 'hasHotWater', false),
  showerInfo: get(cabin, 'showerInfo', null),
  hasDrinkingWater: get(cabin, 'hasDrinkingWater', false),
  drinkingWater: get(cabin, 'drinkingWater', []),
  drinkingWaterInfo: get(cabin, 'drinkingWaterInfo', null),
  amenities: get(cabin, 'amenities', []),
  amenitiesInfo: get(cabin, 'amenitiesInfo', null),
  hasCooking: get(cabin, 'hasCooking', false),
  cooking: get(cabin, 'cooking', []),
  cookingInfo: get(cabin, 'cookingInfo', null),
  hasGarbage: get(cabin, 'hasGarbage', false),
  garbage: get(cabin, 'garbage', []),
  garbageInfo: get(cabin, 'garbageInfo', null),
  allowsPets: get(cabin, 'allowsPets', false),
  petsInfo: get(cabin, 'petsInfo', null),
  hasWifi: get(cabin, 'hasWifi', false),
  wifiInfo: get(cabin, 'wifiInfo', null),
});

export const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);
  const lodging = get(currentListing, 'attributes.publicData.lodging', null);

  if (!lodging) {
    return {
      type: null,
      hasLodging: false,
      house: getHouseValues({}),
      cabin: getCabinValues({}),
      price: null,
    };
  }

  const { type, hasLodging, house, cabin, price, overallInfo } = lodging;

  return {
    type,
    hasLodging,
    house: getHouseValues(house),
    cabin: getCabinValues(cabin),
    price: price ? JSON.parse(price, sdkTypes.reviver) : null,

    overallInfo: overallInfo || null,
  };
};

const getHouseSubmissionValues = formValues => {
  const values = { ...formValues };

  if (values.bathrooms === 0) {
    values.hasShower = false;
    values.hasHotWater = false;
  }

  if (!values.hasCooking) {
    values.cooking = [];
    values.cookingInfo = null;
  }

  if (!values.hasGarbage) {
    values.garbage = [];
    values.garbageInfo = null;
  }

  if (!values.allowsPets) {
    values.petsInfo = null;
  }

  if (!values.hasWifi) {
    values.wifiInfo = null;
  }

  return values;
};

const getCabinSubmissionValues = formValues => {
  const values = { ...formValues };

  if (!values.hasElectricity) {
    values.electricityInfo = null;
  }

  if (!values.hasToilet) {
    values.toilets = 0;
    values.toiletInfo = null;
  }

  if (!values.hasShower) {
    values.showers = 0;
    values.showerInfo = null;
  }

  if (!values.hasDrinkingWater) {
    values.drinkingWater = [];
    values.drinkingWaterInfo = null;
  }

  if (!values.hasCooking) {
    values.cooking = [];
    values.cookingInfo = null;
  }

  if (!values.hasGarbage) {
    values.garbage = [];
    values.garbageInfo = null;
  }

  if (!values.hasGarbage) {
    values.garbage = [];
    values.garbageInfo = null;
  }

  if (!values.allowsPets) {
    values.petsInfo = null;
  }

  if (!values.hasWifi) {
    values.wifiInfo = null;
  }

  return values;
};

export const getSubmissionValues = formValues => {
  const values = { ...formValues };
  const { house, cabin, price, ...rest } = values;

  return {
    ...rest,
    house: getHouseSubmissionValues(house),
    cabin: getCabinSubmissionValues(cabin),
    price: JSON.stringify(price),
  };
};
