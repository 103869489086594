/* eslint-disable no-nested-ternary */
import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { func, node, oneOfType } from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { propTypes } from '../../util/types';
import { getActiveActivityImages } from '../../util/listing';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';
import ActivityCard from './ActivityCard';

import HuntImg from './images/hunt.png';
import FishImg from './images/fish.png';

import css from './SectionAvailableActivities.css';

const SectionAvailableActivities = props => {
  const { title, listing, onSubmit, rootClassName } = props;
  const categories = get(listing, 'attributes.publicData.categories', []);
  const packages = get(listing, 'attributes.publicData.packages', []);

  const hasPublishedPackageForActivity = activity =>
    packages.some(pkg => pkg.activity === activity && pkg.isPublished === true);

  const hasHunting =
    categories.includes(HUNT_ACTIVITY) && hasPublishedPackageForActivity(HUNT_ACTIVITY);

  const hasOutdoorRecreation =
    categories.includes(OUTDOOR_RECREATION_ACTIVITY) &&
    hasPublishedPackageForActivity(OUTDOOR_RECREATION_ACTIVITY);

  const activites = [
    {
      title: 'Hunting',
      activity: HUNT_ACTIVITY,
      image: getActiveActivityImages(listing, HUNT_ACTIVITY),
      imageAlt: HuntImg,
      isActive: hasHunting,
    },
    {
      title: 'Outdoor Recreation',
      activity: OUTDOOR_RECREATION_ACTIVITY,
      image: getActiveActivityImages(listing, OUTDOOR_RECREATION_ACTIVITY),
      imageAlt: FishImg,
      isActive: hasOutdoorRecreation,
    },
  ];

  const sectionPackagesTitle = <FormattedMessage id="SectionPackages.sectionTitle" />;
  const classes = classNames(rootClassName || css.root);

  return (
    <>
      <div className={classes} id="activitiesMobile">
        {title && <h1 className={css.title}>{title}</h1>}
        <div className={css.activities}>
          {activites.map(a => (
            <ActivityCard
              key={a.title}
              activity={a.activity}
              title={a.title}
              image={a.image}
              defaultImage={a.imageAlt}
              onSubmit={onSubmit}
              listing={listing}
              sectionPackagesTitle={sectionPackagesTitle}
              isActive={a.isActive}
            />
          ))}
        </div>
      </div>
    </>
  );
};

SectionAvailableActivities.defaultProps = {
  title: <FormattedMessage id="SectionAvailableActivities.title" />,
};

SectionAvailableActivities.propTypes = {
  title: node,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  onSubmit: func.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(SectionAvailableActivities);
