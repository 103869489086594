import React from 'react';
import { func, bool, string } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { ResponsiveModal, TertiaryButton } from '..';

import css from './CancelMembershipModal.css';

const getErrorMessage = error => {
  if (!error) {
    return null;
  }

  switch (error) {
    case 'stripe-subscription-not-found':
      return <FormattedMessage id="CancelMembershipModal.membershipNotFoundError" />;
    default:
      return <FormattedMessage id="CancelMembershipModal.genericError" />;
  }
};

const CancelMembershipModal = ({
  id,
  intl,
  isOpen,
  onCloseModal,
  onManageDisableScrolling,
  onCancel,
  inProgress,
  disabled,
  endDate,
  error,
}) => {
  const errorMessage = getErrorMessage(error);

  return (
    <ResponsiveModal
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      content={
        <>
          <div className={css.header}>
            <FormattedMessage id="CancelMembershipModal.header" />
          </div>
          <FormattedMessage id="CancelMembershipModal.description" values={{ date: endDate }} />

          {errorMessage && <div className={css.errorMessage}>{errorMessage}</div>}
        </>
      }
      buttons={
        <TertiaryButton
          className={css.buttonYes}
          onClick={onCancel}
          disabled={disabled}
          inProgress={inProgress}
          isFullWidth
        >
          <FormattedMessage id="CancelMembershipModal.buttonYes" />
        </TertiaryButton>
      }
      closeButtonMessage={intl.formatMessage({ id: 'CancelMembershipModal.goBack' })}
    />
  );
};

CancelMembershipModal.defaultProps = {
  error: null,
};

CancelMembershipModal.propTypes = {
  intl: intlShape.isRequired,
  onCancel: func.isRequired,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  endDate: string.isRequired,
  inProgress: bool.isRequired,
  error: string,
};

export default injectIntl(CancelMembershipModal);
