import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton } from '../../Button/Button';
import { NamedLink } from '../..';

const BookNextTripButton = () => {
  return (
    <NamedLink name="LandingPage">
      <PrimaryButton isAnimating>
        <FormattedMessage id="BookingStatus.bookNextTrip" />
      </PrimaryButton>
    </NamedLink>
  );
};

export default BookNextTripButton;
