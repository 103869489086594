// TODO: Fix nested ternary
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { bool, func, object, oneOf, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { LISTING_PAGE_PARAM_TYPES } from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { SUPPORTED_TABS, tabsActive, getTabList } from '../../util/editListing';
import { NamedRedirect } from '..';
import EditListingWizardTab from './EditListingWizardTab';

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabList: getTabList(props.listing.attributes.publicData.categories),
    };

    this.updateTabs = this.updateTabs.bind(this);
  }

  updateTabs(categories) {
    this.setState({ tabList: getTabList(categories) });
  }

  render() {
    const {
      params,
      listing,
      intl,
      errors,
      fetchInProgress,
      stripeAccount,
      currentUser,
      currentUserHasListings,
      onSubmit,
      newListingPublished,
      history,
      availability,
      onChange,
      updatedTab,
      updateInProgress,
      backLink,
      skipLink,
      isNewListingFlow,
      submitButtonText,
      onUpdateProfile,
      onUploadProfileImage,
    } = this.props;

    const { tabList } = this.state;
    const selectedTab = params.tab;
    const currentListing = ensureListing(listing);
    const tabsStatus = tabsActive(tabList, isNewListingFlow, currentListing);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
    const { returnURLType, ...pathParams } = params;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const returnedNormallyFromStripe = returnURLType === 'success';

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      return <NamedRedirect name="EditListingPage" params={pathParams} />;
    }

    return (
      <EditListingWizardTab
        newListingPublished={newListingPublished}
        history={history}
        availability={availability}
        onChange={onChange}
        updatedTab={updatedTab}
        updateInProgress={updateInProgress}
        disabled={isNewListingFlow && !tabsStatus[selectedTab]}
        tab={selectedTab}
        intl={intl}
        params={params}
        listing={listing}
        currentUser={ensuredCurrentUser}
        currentUserHasListings={currentUserHasListings}
        errors={errors}
        fetchInProgress={fetchInProgress}
        onUpdateCategory={this.updateTabs}
        onSubmit={onSubmit}
        onUpdateProfile={onUpdateProfile}
        onUploadProfileImage={onUploadProfileImage}
        submitButtonText={submitButtonText}
        backLink={backLink}
        skipLink={skipLink}
      />
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  currentUser: null,
  backLink: null,
  skipLink: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
    packageId: string,
  }).isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  backLink: node,
  skipLink: node,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
  }),

  currentUser: object,
  currentUserHasListings: bool.isRequired,

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
    createStripeAccountError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isNewListingFlow: bool.isRequired,
  submitButtonText: string.isRequired,
  onUpdateProfile: func.isRequired,
  onUploadProfileImage: func.isRequired,
};

export default compose(injectIntl)(EditListingWizard);
