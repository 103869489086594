import * as props from '../prop-names';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { formatEvent, getEventDate, getDefaultUserProps, getBookingProps } from '../helpers';

// Tracked when the user successfully Sends Special Offer
// eslint-disable-next-line import/prefer-default-export
export const trackNewOfferCreatedEvent = (type, payload, state) => {
  // Usual function that adds some base formatting to the event
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [
    { id: payload.transaction.id, type: 'transaction' },
  ]).pop();

  event.mp_properties = {
    ...getDefaultUserProps(state),

    [props.FIRST_NEW_OFFER_CREATED_DATE]: getEventDate(),
    [props.LAST_NEW_OFFER_CREATED_DATE]: getEventDate(),
    [props.OFFER_CREATED_COUNT]: 1,
    ...getBookingProps(tx),
  };

  return event;
};

export const trackOfferAcceptedPaidEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...getDefaultUserProps(state),

    [props.FIRST_OFFER_ACCEPTED_DATE]: getEventDate(),
    [props.LAST_OFFER_ACCEPTED_DATE]: getEventDate(),
    [props.OFFER_ACCEPTED_COUNT]: 1,

    ...getBookingProps(tx),
  };

  return event;
};

export const trackOfferDeclinedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...getDefaultUserProps(state),

    [props.FIRST_OFFER_DECLINED_DATE]: getEventDate(),
    [props.LAST_OFFER_DECLINED_DATE]: getEventDate(),
    [props.OFFER_DECLINED_COUNT]: 1,

    ...getBookingProps(tx),
  };

  return event;
};

export const trackOfferCanceledEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.id, type: 'transaction' }]).pop();

  event.mp_properties = {
    ...getDefaultUserProps(state),

    [props.FIRST_OFFER_CANCELED_DATE]: getEventDate(),
    [props.LAST_OFFER_CANCELED_DATE]: getEventDate(),
    [props.OFFER_CANCELED_COUNT]: 1,

    ...getBookingProps(tx),
  };

  return event;
};
