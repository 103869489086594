/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, node, func } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { ResponsiveModal, QuaternaryButton } from '..';
import EarlyAccessImage from '../../assets/early-access.webp';
import Logo from '../../assets/logo-landtrust-plus-v-2-1.webp';
import { ReactComponent as IconCheck } from '../../assets/icons/icon-check.svg';

import css from './LandtrustPlusModal.css';

const LandtrustPlusModal = ({
  id,
  intl,
  isOpen,
  onCloseModal,
  onManageDisableScrolling,
  onSuccess,
  inProgress,
  disabled,
  header,
  headerImage,
  description,
  items,
  successBtnLabel,
  closeBtnLabel,
}) => {
  return (
    <ResponsiveModal
      containerClassName={css.container}
      className={css.root}
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      content={
        <>
          <div className={css.headerContainer}>
            <img
              src={headerImage || EarlyAccessImage}
              alt="Early Access header"
              className={css.headerImage}
            />

            <div className={css.intro}>
              <FormattedMessage id="LandtrustPlusModal.intro" />
            </div>

            <div>
              <img src={Logo} alt="Landtrust+" className={css.logoImage} />
            </div>

            <div className={css.header}>{header}</div>
          </div>

          <div className={css.content}>
            <div className={css.description}>{description}</div>

            <ul className={css.list}>
              {items.map((item, index) => (
                <li key={`item-${index}`}>
                  <IconCheck className={css.checkIcon} />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </>
      }
      buttons={
        <QuaternaryButton
          className={css.buttonLearnMore}
          onClick={onSuccess}
          disabled={disabled}
          inProgress={inProgress}
          type="button"
        >
          {successBtnLabel || <FormattedMessage id="LandtrustPlusModal.learnMore" />}
        </QuaternaryButton>
      }
      closeButtonMessage={
        closeBtnLabel || intl.formatMessage({ id: 'LandtrustPlusModal.maybeLater' })
      }
    />
  );
};

LandtrustPlusModal.defaultProps = {
  successBtnLabel: null,
  closeBtnLabel: null,
};

LandtrustPlusModal.propTypes = {
  intl: intlShape.isRequired,
  onSuccess: func.isRequired,
  onCloseModal: func.isRequired,
  header: node.isRequired,
  headerImage: node.isRequired,
  description: node.isRequired,
  items: arrayOf(node).isRequired,
  successBtnLabel: node,
  closeBtnLabel: node,
};

export default injectIntl(LandtrustPlusModal);
