/* eslint-disable react/no-array-index-key */
import React from 'react';
import { node, func } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { ResponsiveModal } from '..';

import css from './CredovaModal.css';

const CredovaModal = ({
  id,
  intl,
  isOpen,
  onCloseModal,
  onManageDisableScrolling,
  closeBtnLabel,
}) => {
  return (
    <ResponsiveModal
      containerClassName={css.container}
      className={css.root}
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      content={
        <div className={css.container}>
          <div className={css.headerContainer}>
            <div className={css.header}>
              <FormattedMessage id="CredovaModal.header" />
            </div>
          </div>

          <div className={css.content}>
            <div className={css.description}>
              <FormattedMessage id="CredovaModal.description" />
            </div>

            <div className={css.subHeader}>
              <FormattedMessage id="CredovaModal.howItWorks" />
            </div>

            <div className={css.listWrapper}>
              <ul className={css.list}>
                <li>
                  <FormattedMessage id="CredovaModal.step1" />
                </li>
                <li>
                  <FormattedMessage id="CredovaModal.step2" />
                </li>
                <li>
                  <FormattedMessage id="CredovaModal.step3" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      buttons={null}
      closeButtonMessage={closeBtnLabel || intl.formatMessage({ id: 'CredovaModal.close' })}
    />
  );
};

CredovaModal.defaultProps = {
  closeBtnLabel: null,
};

CredovaModal.propTypes = {
  intl: intlShape.isRequired,
  onCloseModal: func.isRequired,
  closeBtnLabel: node,
};

export default injectIntl(CredovaModal);
