import React, { useState } from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import chunk from 'lodash/chunk';
import config from '../../config';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { FieldCheckbox, FieldSpeciesCheckbox, IconArrowHead, ValidationError } from '..';
import { popularGameTypes } from '../../marketplace-custom-config';
import SpeciesCurrencyInput from './SpeciesCurrencyInput';
import css from './SpeciesFormItems.css';

const { Money } = sdkTypes;

const categorizeOptions = options => {
  const { popular, other } = options
    .filter(({ type }) => type === 'hunt')
    .reduce(
      (acc, item) => {
        if (popularGameTypes.indexOf(item.key) > -1) {
          acc.popular.push(item);
        } else {
          acc.other.push(item);
        }

        return acc;
      },
      {
        popular: [],
        other: [],
      }
    );

  popular.sort((a, b) => popularGameTypes.indexOf(a.key) - popularGameTypes.indexOf(b.key));

  return { popular, other };
};

const hasOtherSpeciesSelected = (otherSpecies, selected) => {
  return (
    Object.keys(selected || {})
      .filter(k => selected[k].isActive)
      .filter(k => otherSpecies.map(i => i.key).indexOf(k) > -1).length > 0
  );
};

const ShowMore = ({ onShow, onHide, isShowing }) => {
  return (
    <button type="button" onClick={isShowing ? onHide : onShow} className={css.showMore}>
      <FormattedMessage
        id={
          isShowing
            ? 'EditListingSpeciesForm.showLessSpecies'
            : 'EditListingSpeciesForm.showMoreSpecies'
        }
      />

      <IconArrowHead
        direction="up"
        rootClassName={classNames(css.arrowIcon, { [css.arrowIconDown]: isShowing })}
      />
    </button>
  );
};

const SpeciesFormItemRenderer = ({ className, rootClassName, id, intl, options, meta, values }) => {
  const classes = classNames(rootClassName || css.root, className);
  const categorizedOptions = categorizeOptions(options);

  const [showMore, setShowMore] = useState(
    hasOtherSpeciesSelected(categorizedOptions.other, values)
  );

  const pricePlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.priceInputPlaceholder',
  });

  const priceRequired = validators.required(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);

  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingPricingForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );
  const priceValidators = config.listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  const otherOptionsChunks = chunk(
    categorizedOptions.other,
    Math.ceil(categorizedOptions.other.length / 4)
  );

  return (
    <div className={classes}>
      <div className={css.title}>
        {intl.formatMessage({
          id: 'EditListingPricingForm.selectAllThatApply',
        })}
      </div>

      <div className={css.popularList}>
        {categorizedOptions.popular.map(option => {
          const fieldId = `${id}.${option.key}`;
          const fieldIsActive = !!values[option.key] && values[option.key].isActive === true;

          return (
            <div key={fieldId} className={css.popularListItem}>
              <FieldSpeciesCheckbox
                id={fieldId}
                name={`hunt.${option.key}.isActive`}
                label={option.label}
                speciesKey={option.key}
              />

              {fieldIsActive ? (
                <SpeciesCurrencyInput
                  id={fieldId}
                  name={`hunt.${option.key}.price`}
                  className={css.priceInput}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
              ) : null}
            </div>
          );
        })}
      </div>

      <ShowMore
        isShowing={showMore}
        onShow={() => setShowMore(true)}
        onHide={() => setShowMore(false)}
      />

      {showMore && (
        <div className={css.otherSpeciesColumns}>
          {otherOptionsChunks.map(otherOptionsChunk => {
            const columnKey = otherOptionsChunk.map(otherOption => otherOption.key).join(',');

            return (
              <div key={columnKey} className={css.otherSpeciesColumn}>
                {otherOptionsChunk.map(otherOption => {
                  const fieldId = `${id}.${otherOption.key}`;
                  const fieldIsActive =
                    !!values[otherOption.key] && values[otherOption.key].isActive === true;

                  return (
                    <div
                      key={fieldId}
                      className={classNames(css.otherSpeciesItem, {
                        [css.otherSpeciesItemActive]: fieldIsActive,
                      })}
                    >
                      <FieldCheckbox
                        id={fieldId}
                        name={`hunt.${otherOption.key}.isActive`}
                        label={otherOption.label}
                        labelClassName={css.fieldCheckboxLabel}
                      />

                      {fieldIsActive ? (
                        <SpeciesCurrencyInput
                          id={fieldId}
                          name={`hunt.${otherOption.key}.price`}
                          className={css.priceInput}
                          placeholder={pricePlaceholderMessage}
                          currencyConfig={config.currencyConfig}
                          validate={priceValidators}
                        />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      <ValidationError fieldMeta={{ ...meta }} />
    </div>
  );
};

SpeciesFormItemRenderer.defaultProps = {
  rootClassName: null,
  className: null,
};

SpeciesFormItemRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
};

const SpeciesFormItems = props => <FieldArray component={SpeciesFormItemRenderer} {...props} />;

SpeciesFormItems.propTypes = {
  name: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SpeciesFormItems);
