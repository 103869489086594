/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { getNonCommissionNonReversalLineItems } from '../../../util/transaction';
import {
  LINE_ITEM_NEGOTIATION,
  LINE_ITEM_SPECIAL_OFFER,
  LINE_ITEM_UNITS,
  propTypes,
} from '../../../util/types';
import { formatMoney } from '../../../util/currency';

import css from './LineItems.css';

const LineItemSpecialOfferMaybe = props => {
  const { transaction, intl, onClick } = props;

  // resolve unknown non-reversal line items
  const items = getNonCommissionNonReversalLineItems(transaction);

  return items.length > 0 ? (
    <>
      {items.map((item, i) => {
        const label = <FormattedMessage id="LineItemSpecialOffer.label" />;
        const formattedTotal = formatMoney(intl, item.lineTotal);

        if (item.code === LINE_ITEM_UNITS) {
          // Dont render units subtotal as it is duplicate of total
          return null;
        }

        if (item.code === LINE_ITEM_NEGOTIATION || item.code === LINE_ITEM_SPECIAL_OFFER) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
                {label}
              </span>
              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        }

        return null;
      })}
    </>
  ) : null;
};

LineItemSpecialOfferMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  onClick: func.isRequired,
};

export default LineItemSpecialOfferMaybe;
