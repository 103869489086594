import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './MobileLogo.css';

const MobileLogo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <path
              className={css.st0}
              d="M53.5,20.9l-0.6-2.8h-3.3L49,20.9h-3.1L48.7,8h5.2l2.8,12.9H53.5z M50,15.4h2.5l-0.9-4.5H51L50,15.4z"
            />
          </g>
          <path className={css.st0} d="M35,8.1h3.3v10.1h4.1V21H35V8.1z" />
          <path className={css.st0} d="M63,13.9V21h-3V8.1h3l3.8,7.1V8.1h3V21h-3.1L63,13.9z" />
          <path
            className={css.st0}
            d="M81.4,8.1c1.4,0,2.6,1.2,2.6,2.6v7.6c0,1.5-1.2,2.6-2.6,2.6h-6.9V8.1H81.4z M80.7,11.8c0-0.6-0.4-0.9-0.9-0.9
           h-2v7.3h2c0.6,0,0.9-0.4,0.9-0.9V11.8z"
          />
          <path className={css.st0} d="M96.4,8.1v2.8h-2.9V21h-3.3V10.9h-2.9V8.1H96.4z" />
          <path
            className={css.st0}
            d="M107.9,8.1c0.8,0,1.4,0.6,1.4,1.4v5.9c0,0.8-0.7,1.4-1.4,1.4h-0.1l1.6,4.1h-3.2l-1.6-4.1h-1.4v4.1h-3.3V8.1
           H107.9z M106.1,11c0-0.1-0.1-0.2-0.2-0.2h-2.7v3.4h2.7c0.1,0,0.2-0.1,0.2-0.2V11z"
          />
          <path
            className={css.st0}
            d="M117,8.1V18c0,0.1,0.1,0.2,0.2,0.2h2.4c0.1,0,0.2-0.1,0.2-0.2V8.1h3.3v11.4c0,0.8-0.6,1.5-1.4,1.5h-6.5
           c-0.8,0-1.5-0.6-1.5-1.5V8.1H117z"
          />
          <path className={css.st0} d="M148,8.1v2.8h-2.9V21h-3.3V10.9h-2.9V8.1H148z" />
          <path
            className={css.st0}
            d="M135.8,9.5c-0.1-0.3-0.3-0.6-0.5-0.8s-0.5-0.4-0.8-0.5s-0.6-0.2-1-0.2h-4c-0.3,0-0.7,0.1-1,0.2
           c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8s-0.2,0.6-0.2,1v2.7c0,0.5,0.1,0.9,0.4,1.3c0.3,0.4,0.7,0.7,1.1,0.8l4.5,0.8
           c0.1,0,0.1,0.1,0.1,0.2v1.5c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H130c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V17
           H127v1.4c0,0.3,0.1,0.7,0.2,1s0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5s0.6,0.2,1,0.2h4c0.3,0,0.7-0.1,1-0.2s0.6-0.3,0.8-0.5
           s0.4-0.5,0.5-0.8s0.2-0.6,0.2-1v-2.7c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.4-0.7-0.7-1.1-0.8l-4.6-0.8c-0.1,0-0.1-0.1-0.1-0.2v-1.5
           c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h2.8c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.8h2.8v-1.4
           C136,10.2,136,9.8,135.8,9.5z"
          />
        </g>
        <path
          className={css.st1}
          d="M0,0v19.3c0,3.7,2,7,5.9,9.4c2.8,1.8,5.6,2.4,5.7,2.5l0.3,0.1l0.3-0.1c0.1,0,2.9-0.7,5.7-2.5
       c3.9-2.4,5.9-5.7,5.9-9.4V0H0z M2.2,10.2h19.4v5.2c-3.4-1.1-6.7-0.2-10,0.6c-3.3,0.9-6.4,1.7-9.4,0.4C2.2,16.4,2.2,10.2,2.2,10.2z
        M21.6,2.2V8H2.2V2.2H21.6z M11.9,28.9c-0.6-0.2-2.1-0.7-3.8-1.6l3.9-2.2l3.8,2.1C14.1,28.2,12.5,28.7,11.9,28.9z M17.9,26L12,22.7
       L6,26c-2-1.5-3.8-3.7-3.8-6.8v-0.4c1.1,0.4,2.3,0.5,3.4,0.5c2.2,0,4.4-0.6,6.6-1.1c3.3-0.9,6.4-1.7,9.4-0.4v1.5
       C21.6,22.3,19.9,24.4,17.9,26z"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

MobileLogo.defaultProps = {
  className: null,
  rootClassName: null,
};

MobileLogo.propTypes = {
  className: string,
  rootClassName: string,
};

export default MobileLogo;
