/* eslint-disable react/destructuring-assignment */

import React, { useCallback, useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { pathByRouteName } from '../../util/routes';
import { Modal } from '..';

import EmailReminder from './EmailReminder';
import StripeAccountReminder from './StripeAccountReminder';
import css from './ModalMissingInformation.css';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'IDVerificationPage',
];

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';

const ModalMissingInformation = ({
  rootClassName,
  className,
  location,
  missingInformationReminderShown,
  currentUser,
  currentUserHasListings,
  currentUserHasOrders,
  sendVerificationEmailInProgress,
  sendVerificationEmailError,
  onManageDisableScrolling,
  onResendVerificationEmail,
  onMissingInformationReminderShown,
}) => {
  const [reminder, setReminder] = useState(null);
  const user = ensureCurrentUser(currentUser);
  const classes = classNames(rootClassName || css.root, className);

  const currentUserLoaded = user && user.id;

  let content = null;

  const handleMissingInformationReminder = useCallback(() => {
    const routes = routeConfiguration();
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );

    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(location.pathname);

    if (!isPageWhitelisted && !missingInformationReminderShown) {
      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const hasOrders = currentUserHasOrders === true;
      const hasListingsOrOrders = currentUserHasListings || hasOrders;

      const emailUnverified = !!user.id && !user.attributes.emailVerified;
      const emailVerificationNeeded = hasListingsOrOrders && emailUnverified;

      const stripeAccountMissing = !!user.id && !user.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;
      // Show reminder
      if (emailVerificationNeeded) {
        setReminder(EMAIL_VERIFICATION);
      } else if (stripeAccountNeeded) {
        setReminder(STRIPE_ACCOUNT);
      }
    }
  }, [
    currentUserHasListings,
    currentUserHasOrders,
    location.pathname,
    missingInformationReminderShown,
    user.attributes.emailVerified,
    user.attributes.stripeConnected,
    user.id,
  ]);

  const handleClose = useCallback(() => {
    setReminder(null);
    onMissingInformationReminderShown(true);
  }, [onMissingInformationReminderShown]);

  useEffect(() => {
    handleMissingInformationReminder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentUserLoaded) {
    return content;
  }

  if (reminder === EMAIL_VERIFICATION) {
    content = (
      <EmailReminder
        className={classes}
        user={user}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />
    );
  } else if (reminder === STRIPE_ACCOUNT) {
    content = <StripeAccountReminder className={classes} onAction={handleClose} />;
  }

  return (
    <Modal
      id="MissingInformationReminder"
      containerClassName={css.modalContainer}
      isOpen={!!reminder}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={
        <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
      }
      lightCloseButton
    >
      {content}
    </Modal>
  );
};

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

ModalMissingInformation.propTypes = {
  className: string,
  rootClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
