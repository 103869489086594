import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compact } from 'lodash';
import HasTruncation from '../../components/Truncate/HasTruncation';
import css from './SectionLandDetails.css';
import { habitatDiversityOptions, BIRD_ACTIVITY } from '../../marketplace-custom-config';
import { ReactComponent as IconFood } from '../../assets/icons/food-plots.svg';
import { ReactComponent as IconWater } from '../../assets/icons/water.svg';
import { ReactComponent as IconShelter } from '../../assets/icons/shelter.svg';
import { ReactComponent as IconMapSigns } from '../../assets/icons/map-signs-solid.svg';
import { formatSourcesList } from '../../util/data';

const SectionLandDetails = props => {
  const { landDetails, categories } = props;
  const {
    foodSources,
    waterSources,
    shelter,
    hasFoodSources,
    hasWaterSources,
    hasShelter,
    foodSourcesInfo,
    waterSourcesInfo,
    shelterInfo,
    habitatDiversity,
    habitatDiversityInfo,
    hasNationalParks,
    hasWildlifeRefuges,
  } = landDetails;

  const hasHabitatDiversity = habitatDiversity && habitatDiversity.length > 0;
  const shouldRender = hasShelter || hasWaterSources || hasFoodSources || hasHabitatDiversity;

  const SourceListFormatter = (sources, labelsMap) => {
    const formattedSources = sources.map(
      source =>
        labelsMap.find(labelObject => source === labelObject.key) &&
        labelsMap.find(labelObject => source === labelObject.key).label
    );
    return compact(formattedSources).join(' 	 •	  ');
  };

  const foodSourcesList = foodSources ? formatSourcesList(foodSources) : '';
  const waterSourcesList = waterSources ? formatSourcesList(waterSources) : '';
  const shelterSourcesList = shelter ? formatSourcesList(shelter) : '';

  return shouldRender ? (
    <div className={css.sectionLandDetails}>
      <div className={css.landDetailsContent}>
        <h3 className={css.sectionHeader}>
          <FormattedMessage id="ListingPage.landDetailsTitle" />
        </h3>
        {hasFoodSources && foodSources ? (
          <section>
            <div className={css.detailsContainer}>
              <div className={css.detailsTitle}>
                <IconFood />
                <div className={css.landDetailsTitleText}>
                  <FormattedMessage
                    id="ListingPage.landDetailsFood"
                    values={{ items: foodSourcesList }}
                  />
                </div>
              </div>

              {foodSourcesInfo ? (
                <div className={css.sourcesInfo}>
                  <HasTruncation>{foodSourcesInfo}</HasTruncation>
                </div>
              ) : null}
            </div>
          </section>
        ) : null}
        {hasWaterSources && waterSources ? (
          <section>
            <div className={css.detailsContainer}>
              <div className={css.detailsTitle}>
                <IconWater />
                <div className={css.landDetailsWaterTitleText}>
                  <FormattedMessage
                    id="ListingPage.landDetailsWater"
                    values={{ items: waterSourcesList }}
                  />
                </div>
              </div>

              {waterSourcesInfo ? (
                <div className={css.sourcesInfo}>
                  <HasTruncation>{waterSourcesInfo}</HasTruncation>
                </div>
              ) : null}
            </div>
          </section>
        ) : null}
        {hasShelter && shelter ? (
          <section>
            <div className={css.detailsContainer}>
              <div className={css.detailsTitle}>
                <div>
                  <IconShelter />
                </div>
                <div className={css.landDetailsShelterTitleText}>
                  <FormattedMessage
                    id="ListingPage.landDetailsShelter"
                    values={{ items: shelterSourcesList }}
                  />
                </div>
              </div>

              {shelterInfo ? (
                <div className={css.sourcesInfo}>
                  <HasTruncation>{shelterInfo}</HasTruncation>
                </div>
              ) : null}
            </div>
          </section>
        ) : null}
        {hasHabitatDiversity ? (
          <section>
            <div className={css.detailsContainer}>
              <div className={css.detailsTitle}>
                <IconFood />
                <div className={css.detailsTitleText}>
                  <FormattedMessage id="ListingPage.landDetailsHabitatDiversity" />
                </div>
              </div>
              <div>
                {habitatDiversity && habitatDiversity.length > 0 && (
                  <div className={css.sources}>
                    {SourceListFormatter(habitatDiversity, habitatDiversityOptions)}
                  </div>
                )}
              </div>
              {habitatDiversityInfo ? (
                <HasTruncation>
                  <p className={css.sourcesInfo}>{habitatDiversityInfo}</p>
                </HasTruncation>
              ) : null}
            </div>
          </section>
        ) : null}
        {categories.includes(BIRD_ACTIVITY) && (
          <section>
            <div className={css.detailsContainer}>
              <div className={css.detailsTitle}>
                <IconMapSigns />
                <div className={css.detailsTitleText}>
                  <FormattedMessage id="ListingPage.landDetailsPublicAccess" />
                </div>
              </div>
              <div>
                <strong>
                  <FormattedMessage id="ListingPage.landDetailsNationalParksLabel" />
                </strong>
                :{' '}
                {hasNationalParks ? (
                  <FormattedMessage id="FieldBoolean.yes" />
                ) : (
                  <FormattedMessage id="FieldBoolean.no" />
                )}
              </div>
              <div>
                <strong>
                  <FormattedMessage id="ListingPage.landDetailsNationalWildlifeLabel" />
                </strong>
                :{' '}
                {hasWildlifeRefuges ? (
                  <FormattedMessage id="FieldBoolean.yes" />
                ) : (
                  <FormattedMessage id="FieldBoolean.no" />
                )}
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  ) : null;
};

export default SectionLandDetails;
