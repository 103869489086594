import React from 'react';
import { node } from 'prop-types';
import css from './ModalHeadings.css';

const ModalHeadings = ({ heading, subHeading }) => {
  return (
    <>
      <h1 className={css.heading}>{heading}</h1>
      {subHeading && <p className={css.subHeading}>{subHeading}</p>}
    </>
  );
};

ModalHeadings.defaultProps = {
  subHeading: null,
};

ModalHeadings.propTypes = {
  heading: node.isRequired,
  subHeading: node,
};

export default ModalHeadings;
