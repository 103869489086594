import React from 'react';
import { ReactComponent as StarSolid } from '../../assets/icons/star-solid.svg';

import css from './Rating.css';

const Rating = ({ className, size = 6 }) => {
  const stars = [];
  for (let i = 0; i < 5; i += 1) {
    stars.push(
      <div key={i} className={css.rootStyles} style={{ width: size, height: size }}>
        <StarSolid style={{ width: size, height: size }} />
      </div>
    );
  }

  const ratingClassName = `${className} ${css.rating}`;

  return <div className={ratingClassName}>{stars}</div>;
};

export default Rating;
