import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  FieldMultipleLocationInput,
  FieldCheckbox,
  LocationAutocompleteInputField,
} from '../../../components';
import { signupSpecies } from '../../../marketplace-custom-config';
import * as validators from '../../../util/validators';
import css from '../IdpSignupForm.css';

const Step3 = props => {
  const { intl, values } = props;

  // state
  const preferredStatesLabel = intl.formatMessage({
    id: 'ConfirmSignupForm.preferredStatesLabel',
  });
  const preferredStatesPlaceholder = intl.formatMessage({
    id: 'ConfirmSignupForm.preferredStatesPlaceholder',
  });

  // home state
  const usStateLabel = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStateLabel',
  });

  const usStatePlaceholder = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStatePlaceholder',
  });

  const usStateRequiredMessage = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStateRequired',
  });

  const usStateRequired = validators.autocompletePlaceSelected(usStateRequiredMessage);

  return (
    <div>
      <h1 className={css.signupWithIdpTitle}>
        <FormattedMessage id="IdpSignupForm.step3Header" />
      </h1>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="IdpSignupForm.step3SubHeader" />
      </p>

      <div>
        <div className={css.formSection}>
          <LocationAutocompleteInputField
            name="usState"
            label={usStateLabel}
            placeholder={usStatePlaceholder}
            useDefaultPredictions={false}
            format={null}
            valueFromForm={values.usState}
            showButton={false}
            showIcon={false}
            validate={usStateRequired}
          />
        </div>

        <div className={css.formSection}>
          <FieldMultipleLocationInput
            name="preferredHuntingStates"
            label={preferredStatesLabel}
            placeholder={preferredStatesPlaceholder}
            useDefaultPredictions={false}
            valueFromForm={values.preferredHuntingStates}
            showButton={false}
            showIcon={false}
          />
        </div>

        {values?.interestedActivities.includes('hunt') && (
          <div className={css.formSection}>
            <div className={css.fieldLabel}>
              <FormattedMessage id="ContactDetailsForm.speciesCheck" />
            </div>
            {Object.keys(signupSpecies.hunt).map(key => {
              return (
                <div key={`species.${key}`} className={css.huntSpeciesItem}>
                  <FieldCheckbox
                    id={`species.${key}`}
                    name="preferredHuntingSpecies"
                    label={signupSpecies.hunt[key].label}
                    speciesKey={key}
                    value={key}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step3;
