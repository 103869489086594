import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { ProgressBar } from '..';
import css from './EditListingProgressBar.css';

const { string, number } = PropTypes;

export const EditListingProgressBar = ({ className, rootClassName, progress, pageLabel, intl }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <ProgressBar progress={progress} />

      <div className={css.labels}>
        <div className={css.pageLabel}>{pageLabel}</div>

        <div className={css.percentLabel}>
          {intl.formatMessage(
            {
              id: 'EditListingProgressBar.progressPercent',
            },
            {
              percent: progress,
            }
          )}
        </div>
      </div>
    </div>
  );
};

EditListingProgressBar.defaultProps = {
  className: '',
  rootClassName: '',
  progress: 0,
};

EditListingProgressBar.propTypes = {
  className: string,
  rootClassName: string,
  progress: number,
  pageLabel: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EditListingProgressBar);
