import React from 'react';
import classNames from 'classnames';
import { BookingStatus } from '..';
import { TRANSITION_ENQUIRE_GATED, TRANSITION_ENQUIRE_EXPIRE } from '../../util/transaction';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActivityFeed section
const BookingStatusSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    onOpenReviewModal,
    onGoToReview,
    stateData,
    cancelButton,
    viewRequestButton,
    saleButtons,
    specialOfferCustomerButtons,
    inquiryGated,
    inquiryExpired,
    authorDisplayName,
  } = props;

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];

  const showFeed = txTransitions.length > 0;

  const classes = classNames(rootClassName || css.feedContainer, className);

  const inquiryExpiredTransition =
    currentTransaction.attributes.lastTransition === TRANSITION_ENQUIRE_EXPIRE;

  const inquiryGatedTransition =
    currentTransaction.attributes.lastTransition === TRANSITION_ENQUIRE_GATED;

  return showFeed ? (
    <div className={classes}>
      <div className={css.dividerTop} />

      <BookingStatus
        className={css.feed}
        transaction={currentTransaction}
        currentUser={currentUser}
        onOpenReviewModal={onOpenReviewModal}
        onGoToReview={onGoToReview}
        stateData={stateData}
        cancelButton={cancelButton}
        viewRequestButton={viewRequestButton}
        saleButtons={saleButtons}
        specialOfferCustomerButtons={specialOfferCustomerButtons}
        inquiryGated={inquiryGated}
        inquiryExpired={inquiryExpired}
        authorDisplayName={authorDisplayName}
      />
      {!(inquiryExpiredTransition || inquiryGatedTransition) && <div className={css.divider} />}
    </div>
  ) : null;
};

export default BookingStatusSection;
