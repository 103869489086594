import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import EditListingSection from './EditListingSection';
import css from './EditListingActions.css';

const EditListingForwardAction = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.forwardAction, className);

  return <div className={classes}>{children}</div>;
};

EditListingForwardAction.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingForwardAction.propTypes = {
  className: string,
  rootClassName: string,
};

const EditListingBackAction = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.backAction, className);

  return <div className={classes}>{children}</div>;
};

EditListingBackAction.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingBackAction.propTypes = {
  className: string,
  rootClassName: string,
};

const EditListingSkipAction = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.skipAction, className);

  return <div className={classes}>{children}</div>;
};

EditListingSkipAction.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingSkipAction.propTypes = {
  className: string,
  rootClassName: string,
};

const EditListingActions = ({ className, rootClassName, children }) => {
  let hasSkipAction = false;

  React.Children.forEach(children, child => {
    if (child && child.type === EditListingSkipAction) hasSkipAction = true;
  });

  const classes = classNames(
    rootClassName || css.root,
    {
      [css.withoutSkipAction]: !hasSkipAction,
    },
    className
  );

  return (
    <EditListingSection className={classes} isFullWidth>
      {children}
    </EditListingSection>
  );
};

EditListingActions.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingActions.propTypes = {
  className: string,
  rootClassName: string,
};

EditListingActions.Forward = EditListingForwardAction;
EditListingActions.Back = EditListingBackAction;
EditListingActions.Skip = EditListingSkipAction;

export default EditListingActions;
