/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { dropWhile, get } from 'lodash';
import { ensureTransaction, ensureUser } from '../../../util/data';
import { InlineTextButton } from '../..';
import InboxFeedItem from './InboxFeedItem';

import css from './InboxFeedItems.css';
import {
  getUserTxRole,
  theLastTransition,
  TRANSITION_ENQUIRE_EXPIRE,
  TRANSITION_ENQUIRE_GATED,
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  txRoleIsProvider,
} from '../../../util/transaction';
import InboxTransitionEnquireGated from '../../InboxTransition/InboxTransitionEnquireGated';
import InboxTransitionEnquireExpire from '../../InboxTransition/InboxTransitionEnquireExpire';

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  }
  return items;
};

const InboxFeedItems = ({
  messages,
  transaction,
  currentUser,
  hasOlderMessages,
  onShowOlderMessages,
  fetchMessagesInProgress,
}) => {
  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const user = txRoleIsProvider(ownRole) ? currentCustomer : currentProvider;

  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];

  const initialMessage = get(transaction, 'attributes.protectedData.message', null);
  let newMessages = [...messages];

  if (initialMessage) {
    let initialMessageCreatedAt;
    const initialMessageTransition = transitions.find(
      t => t.transition === TRANSITION_PROVIDER_SPECIAL_OFFER
    );

    if (initialMessageTransition) {
      initialMessageCreatedAt = initialMessageTransition.createdAt;
    } else {
      initialMessageCreatedAt = currentTransaction.attributes.createdAt;
    }

    const sender = currentTransaction.customer;

    const initialMessageObj = {
      attributes: {
        content: initialMessage,
        createdAt: initialMessageCreatedAt,
      },
      sender,
      type: 'message',
    };

    newMessages = [initialMessageObj, ...newMessages];
  }

  // combine messages and transaction transitions
  const items = organizedItems(
    newMessages,
    transitions,
    hasOlderMessages || fetchMessagesInProgress
  );

  const renderTransitionItems = () => {
    const lastTransition = theLastTransition(transaction);

    switch (lastTransition.transition) {
      case TRANSITION_ENQUIRE_EXPIRE:
        return (
          <InboxTransitionEnquireExpire
            transaction={transaction}
            ownRole={ownRole}
            buttons={[]}
            user={user}
            isMessage
          />
        );

      case TRANSITION_ENQUIRE_GATED:
        return (
          <InboxTransitionEnquireGated
            transaction={transaction}
            ownRole={ownRole}
            buttons={[]}
            user={user}
            isMessage
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={css.root}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}

      {renderTransitionItems()}

      {items
        .filter(item => isMessage(item))
        .map((item, index) => (
          <InboxFeedItem key={`item-${index}`} message={item} currentUser={currentUser} />
        ))}
    </div>
  );
};

export default InboxFeedItems;
