import * as props from '../prop-names';

import {
  formatEvent,
  getEventDate,
  getCurrUserId,
  isLandowner,
  isSportsman,
  getBookingProps,
} from '../helpers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Tracked when the landowner reviews the sportsman
export const trackReviewByLandowner = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LANDOWNER_ID]: getCurrUserId(state),
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.LISTING_NAME]: tx.listing.attributes.title,
    [props.LAST_LANDOWNER_REVIEW_RECEIVED]: getEventDate(),
    [props.FIRST_LANDOWNER_REVIEW_RECEIVED]: getEventDate(),
    [props.LANDOWNER_REVIEW_RECEIVED_TIMESTAMP]: getEventDate(),
    ...getBookingProps(tx),
  };

  return event;
};
export const trackReviewBySportsman = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isSportsman(state)) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LANDOWNER_ID]: getCurrUserId(state),
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.LISTING_NAME]: tx.listing.attributes.title,
    [props.LAST_SPORTSMAN_REVIEW_RECEIVED]: getEventDate(),
    [props.FIRST_SPORTSMAN_REVIEW_RECEIVED]: getEventDate(),
    [props.SPORTSMAN_REVIEW_RECEIVED_TIMESTAMP]: getEventDate(),
    ...getBookingProps(tx),
  };

  return event;
};
