import React from 'react';
import { arrayOf, shape, node, string, oneOf, number } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';

import { ValidationError, FieldPillInput, FieldLabel } from '..';

import css from './FieldPillInputGroup.css';

const FieldPillInputGroupRenderer = ({
  rootClassName,
  className,
  minColumnWidth,
  options,
  id,
  meta,
  fields,
  validate,
  type,
  label,
}) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      {label && <FieldLabel>{label}</FieldLabel>}
      <fieldset className={classes}>
        <ul className={css.list}>
          {options.map(option => {
            // eslint-disable-next-line no-shadow
            const { key, label } = option;
            const fieldId = `${id}.${key}`;

            return (
              <li key={fieldId} className={css.item} style={{ minWidth: minColumnWidth }}>
                <FieldPillInput
                  id={fieldId}
                  value={key}
                  name={fields.name}
                  label={label}
                  validate={validate}
                  type={type}
                />
              </li>
            );
          })}
        </ul>
        <ValidationError fieldMeta={{ ...meta }} />
      </fieldset>
    </>
  );
};

FieldPillInputGroupRenderer.propTypes = {
  rootClassName: string,
  className: string,
  minColumnWidth: number,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  type: oneOf(['checkbox', 'radio']),
  label: string,
};

FieldPillInputGroupRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  minColumnWidth: 120,
  type: 'checkbox',
  label: null,
};

const FieldPillInputGroup = props => (
  <FieldArray component={FieldPillInputGroupRenderer} {...props} />
);

FieldPillInputGroup.propTypes = {
  name: string.isRequired,
};

export default FieldPillInputGroup;
