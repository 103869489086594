import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { FieldTextInput, PrimaryButton } from '../../components';
import css from './NotifyForAddedStateForm.css';
import config from '../../config';
import * as validators from '../../util/validators';

const NotifyForAddedStateForm = ({ address, user, intl }) => {
  const email = user.currentUser?.attributes?.email;
  const listId = config.klaviyo.addedStateList;

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async values => {
    try {
      await axios.post(config.klaviyo.url, new URLSearchParams(values));
      setSubmitted(true);
    } catch (err) {
      setError(true);
    }
  };

  return submitted ? (
    <p className={classNames(css.subscribeFormSection, css.subscribeFormText)}>
      <FormattedMessage id="NotifyForAddedStateForm.confirmation" />
    </p>
  ) : (
    <div className={css.subscribeFormSection}>
      <p className={css.subscribeFormText}>
        <FormattedMessage id="NotifyForAddedStateForm.cta" />
      </p>
      <FinalForm
        initialValues={{
          email,
          g: listId,
          'Notify For Added State': address,
          $fields: 'Notify For Added State',
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldTextInput
              type="text"
              name="email"
              placeholder={intl.formatMessage({ id: 'NotifyForAddedStateForm.emailPlaceholder' })}
              validate={validators.composeValidators(
                validators.required(
                  intl.formatMessage({ id: 'NotifyForAddedStateForm.emailRequired' })
                ),
                validators.emailFormatValid(
                  intl.formatMessage({ id: 'NotifyForAddedStateForm.emailInvalid' })
                )
              )}
            />
            <PrimaryButton className={css.subscribeFormSubmit} type="submit">
              <FormattedMessage id="NotifyForAddedStateForm.submitText" />
            </PrimaryButton>
            {error && (
              <p>
                <FormattedMessage id="NotifyForAddedStateForm.submitError" />
              </p>
            )}
          </form>
        )}
      />
    </div>
  );
};

export default compose(
  connect(
    ({ user }) => ({ user }),
    null
  ),
  injectIntl
)(NotifyForAddedStateForm);
