/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import {
  composeValidators,
  requiredIf,
  required,
  moneySubUnitAmountAtLeast,
} from '../../util/validators';
import { lodgingOptions } from '../../marketplace-custom-config';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldCondition,
  EditListingActions,
  EditListingError,
  FieldToggle,
  FieldSelect,
  FieldCurrencyInput,
} from '../../components';

import { types as sdkTypes } from '../../util/sdkLoader';

import EditListingLodgingHouseFields from './EditListingLodgingHouseFields';
import EditListingLodgingCabinFields from './EditListingLodgingCabinFields';

import css from './EditListingLodgingForm.css';

const { Money } = sdkTypes;

export const EditListingLodgingFormComponent = ({ intl, backLink, ...props }) => {
  const priceRequired = required(
    intl.formatMessage({
      id: 'EditListingCreatePackageForm.priceRequired',
    })
  );

  const lodgingRequiredMinPrice = new Money(
    config.listingLodgingRequiredMinimumPrice,
    config.currency
  );

  const LodgingPriceRequiredRequired = moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingCreatePackageForm.priceTooLow',
      },
      {
        price: formatMoney(intl, lodgingRequiredMinPrice),
      }
    ),
    config.listingLodgingRequiredMinimumPrice
  );

  const lodgingPriceRequiredValidators = config.listingLodgingRequiredMinimumPrice
    ? composeValidators(priceRequired, LodgingPriceRequiredRequired)
    : LodgingPriceRequiredRequired;

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
        } = formRenderProps;

        const classes = classNames(className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({ id: 'EditListingLodgingForm.step1Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasLodging" />
              </EditListingFieldWrapper>
              <FieldCondition when="hasLodging" is>
                <EditListingFieldWrapper>
                  <FieldSelect
                    name="type"
                    id="lodgingType"
                    validate={composeValidators(
                      requiredIf(
                        form,
                        'hasLodging',
                        true,
                        intl.formatMessage({ id: 'EditListingLodgingForm.lodgingTypeRequired' })
                      )
                    )}
                  >
                    <option disabled value="">
                      Choose One
                    </option>
                    {lodgingOptions.map(o => (
                      <option key={o.key} value={o.key}>
                        {o.label}
                      </option>
                    ))}
                  </FieldSelect>
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <FieldCondition when="hasLodging" is>
              <FieldCondition when="type" is="house">
                <EditListingLodgingHouseFields />
              </FieldCondition>

              <FieldCondition when="type" is="cabin">
                <EditListingLodgingCabinFields />
              </FieldCondition>

              {formRenderProps.values.type && (
                <EditListingSection
                  hasBackground
                  hasBottomMargin
                  hasTopMargin
                  title={intl.formatMessage(
                    {
                      id: 'EditListingLodgingForm.lodgingPriceFieldLabel',
                    },
                    {
                      type: formRenderProps.values.type,
                    }
                  )}
                >
                  <EditListingFieldWrapper>
                    <FieldCurrencyInput
                      className={css.fieldInputPrice}
                      id="price"
                      name="price"
                      postfixLabel={intl.formatMessage({
                        id: 'EditListingLodgingForm.lodgingPriceFieldPostfix',
                      })}
                      placeholder="$0.00"
                      currencyConfig={config.currencyConfig}
                      validate={lodgingPriceRequiredValidators}
                    />
                  </EditListingFieldWrapper>
                </EditListingSection>
              )}
            </FieldCondition>

            <FieldCondition when="hasLodging" is>
              <EditListingSection hasTopMargin>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="overallInfo"
                    name="overallInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingLodgingForm.lodgingOverallDescription',
                    })}
                    labelDescription={intl.formatMessage({
                      id: 'EditListingLodgingForm.lodgingOverallLabelDescription',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'EditListingLodgingForm.lodgingOverallPlaceholder',
                    })}
                  />
                </EditListingFieldWrapper>
              </EditListingSection>
            </FieldCondition>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingLodgingFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLodgingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLodgingFormComponent);
