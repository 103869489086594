/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import { get } from 'lodash';
import { formatTimeAgo } from '../../../util/dates';
import NamedLink from '../../../components/NamedLink/NamedLink';
import { createSlug } from '../../../util/urlHelpers';
import { getPackage } from '../../../util/listing';
import Avatar from '../../../components/Avatar/Avatar';
import css from './ListingUpdate.css';
import { ScrollingCarousel, Step } from '../../../components/ScrollingCarousel/ScrollingCarousel';

const SectionPhotosUpdate = props => {
  const { listing, activity, currentPackage, isListing, renderBellIcon } = props;

  const activityPhotos = activity.photos || [];
  const hasImages = activityPhotos.length > 0;

  const { packageId } = activity;
  const activityPackage = getPackage(listing, packageId);
  const activityPackageTitle = activityPackage?.title;

  const packageName = currentPackage ? currentPackage.title : '';
  const slug = createSlug(packageName);
  const id = listing.id.uuid;

  const richTitle = activityPackageTitle && (
    <NamedLink className={css.titleLink} name="PackagePage" params={{ id, slug, packageId }}>
      {activityPackageTitle} package
    </NamedLink>
  );

  let messageID;

  if (!isListing && currentPackage?.id === packageId) {
    messageID = 'ListingPage.activityFeedNewPhotoThisPackage';
  } else if (activityPackageTitle) {
    messageID = 'ListingPage.activityFeedNewPhoto';
  } else {
    messageID = 'ListingPage.activityFeedDefaultMessage';
  }

  if (!isListing && currentPackage?.id !== packageId) {
    return null;
  }

  return (
    <>
      {hasImages && (
        <>
          <div key={activity.createdAt} className={css.content}>
            <div className={css.info}>
              <div className={css.flexContainer}>
                <div className={css.avatarContainer}>
                  <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                    <>
                      <Avatar
                        user={listing.author}
                        disableProfileLink
                        className={css.customAvatarSize}
                        listing={listing}
                        useListingImage
                      />
                      {renderBellIcon}
                    </>
                  </NamedLink>
                </div>
                <div className={css.propertyNameContainer}>
                  <div className={css.propertyName}>
                    <NamedLink name="ListingPage" params={{ id: listing.id.uuid, slug }}>
                      <FormattedMessage
                        id="ListingPage.activityFeedPropertyName"
                        values={{
                          propertyName: listing.attributes.title,
                        }}
                      />
                    </NamedLink>
                  </div>
                  <div className={css.dateWrapper}>
                    <span className={css.dateContainer}>
                      <p className={css.landownerContainer}>
                        <FormattedMessage
                          id="ListingPage.activityFeedPhotoUpdate"
                          values={{
                            landownerName: listing.author.attributes.profile.displayName,
                          }}
                        />
                        {' • '}
                        {formatTimeAgo(activity?.createdAt)}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className={css.postWrapper}>
                <div className={css.descriptionStyles}>
                  <FormattedMessage id={messageID} values={{ richTitle }} />
                </div>
                {hasImages && (
                  <ScrollingCarousel showDots={activityPhotos.length > 1} showNavigationOnHover>
                    {activityPhotos.map((image, index) => (
                      <Step key={get(image, index)} className={css.step}>
                        <div className={css.imageWrapper}>
                          <img src={image.path} className={css.imageStyles} alt="in carousel" />
                        </div>
                      </Step>
                    ))}
                  </ScrollingCarousel>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

SectionPhotosUpdate.defaultProps = {};

SectionPhotosUpdate.propTypes = {
  listing: propTypes.object.isRequired,
};

export default SectionPhotosUpdate;
