export const PAGE_URL = 'Page URL';
export const PAGE_TYPE = 'Page Type';
export const SIGNUP_START_DATE = 'Signup Start Date';
export const SIGNUP_DATE = 'Signup Date';
export const SIGNUP_SOURCE = 'Signup Source';
export const USER_ID = 'User ID';
export const AFFILIATE_PROGRAM = 'Affiliate Program';
export const SIGNUP_MEDIUM = 'Signup Medium';
export const SIGNUP_CAMPAIGN = 'Signup Campaign';
export const ROLE = 'Role';
export const SIGNUP_METHOD = 'Signup Method';
export const ADDRESS = 'Listing Address';
export const LISTING_STATE = 'Listing State';
export const STATE = 'State';
export const EMAIL = '$email';
export const EMAIL_SHA1 = 'Email SHA1';
export const PHONE = '$phone';
export const SECONDARY_PHONE = 'Secondary Phone Number';
export const FIRST_NAME = '$first_name';
export const LAST_NAME = '$last_name';
export const FIRST_LOGIN_DATE = 'First Login Date';
export const LAST_LOGIN_DATE = 'Last Login Date';
export const LOGIN_COUNT = 'Login Count';
export const LAST_SEARCH_TERM = 'Last Search Term';
export const LAST_SEARCH_DATE = 'Last Search Date';
export const SEARCH_TERM = 'Search Term';
export const SEARCH_POSITION = 'Search Position';
export const TOTAL_SEARCHES_COUNT = 'Total Searches Count';
export const USER_STATUS = 'User Status';
export const LISTING_ID = 'Listing ID';
export const LISTING_NAME = 'Listing Name';
export const LISTING_IMAGE = 'Listing Image';
export const PACKAGE_ID = 'Package ID';
export const PACKAGE_TITLE = 'Package Title';
export const PACKAGE_ACTIVITY = 'Package Activity';
export const LANDOWNER_ID = 'Landowner ID';
export const VIEWED_LISTING_COUNT = 'Viewed Listing Count';
export const NEAREST_TOWN = 'Nearest Town';
export const ACREAGE = 'Acreage';
export const HUNT = 'Hunt';
export const FISH = 'Fish';
export const ACCESS = 'Access';
export const STARTING_PRICE = 'Starting Price';
export const BIO = 'Bio';
export const HAS_BIO = 'Has Bio';
export const HAS_PROFILE_IMAGE = 'Has Profile Image';
export const FIRST_NEW_LISTING_STARTED_DATE = 'First New Listing Started Date';
export const LAST_NEW_LISTING_STARTED_DATE = 'Last New Listing Started Date';
export const NEW_LISTING_STARTED_COUNT = 'New Listing Started Count';
export const FIRST_NEW_LISTING_COMPLETED_DATE = 'First New Listing Completed Date';
export const LAST_NEW_LISTING_COMPLETED_DATE = 'Last New Listing Completed Date';
export const METHOD_OF_TAKE = 'Method of Take';
export const FEATURES = 'Features';
export const AMENITIES = 'Amenities';
export const MAXIMUM_PARTY_SIZE = 'Maximum party size';
export const BOOKING = 'Booking';
export const FIRST_BOOKING_RECEIVED_DATE = 'First Booking Received Date';
export const LAST_BOOKING_RECEIVED_DATE = 'Last Booking Received Date';
export const BOOKING_RECEIVED_COUNT = 'Booking Received Count';
export const BOOKING_START_DATE = 'Booking Start Date';
export const BOOKING_END_DATE = 'Booking End Date';
export const DAYS_OF_BOOKING = 'Days of Booking';
export const PARTY_SIZE_SELECTED = 'Party size selected';
export const ACTIVITIES_SELECTED = 'Activities Selected';
export const SUBTOTAL = 'Subtotal';
export const BOOKING_FEE = 'Booking Fee';
export const TOTAL_PRICE = 'Total Price';
export const SPECIES = 'Species';
export const FIRST_BOOKING_REQUEST_ACCEPTED_DATE = 'First Booking Request Accepted Date';
export const LAST_BOOKING_REQUEST_ACCEPTED_DATE = 'Last Booking Request Accepted Date';
export const BOOKING_REQUESTED_ACCEPTED_COUNT = 'Booking Requested Accepted Count';
export const SPORTSMAN_ID = 'Sportsman ID';
export const TOTAL_EARNING = 'Total Earning';
export const FIRST_BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_DATE =
  'First Booking Request Accepted By Landowner Date';
export const LAST_BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_DATE =
  'Last Booking Request Accepted By Landonwer Date';
export const BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER_COUNT =
  'Booking Requested Accepted By Landowner Count';
export const REVENUE = 'Revenue';
export const LTV_REVENUE = 'LTV Revenue';
export const FIRST_BOOKING_REQUEST_DECLINED_DATE = 'First Booking Request Declined Date';
export const LAST_BOOKING_REQUEST_DECLINED_DATE = 'Last Booking Request Declined Date';
export const BOOKING_REQUEST_DECLINED_COUNT = 'Booking Request Declined Count';
export const FIRST_BOOKING_REQUEST_DECLINED_BY_LANDOWNER_DATE =
  'First Booking Request Declined By Landowner Date';
export const LAST_BOOKING_REQUEST_DECLINED_BY_LANDOWNER_DATE =
  'Last Booking Request Declined By Landowner Date';
export const BOOKING_REQUEST_DECLINED_BY_LANDOWNER_COUNT =
  'Booking Request Declined By Landowner Count';
export const FIRST_NEW_QUERY_INITIATED = 'First New Query Initiated';
export const LAST_NEW_QUERY_INITIATED = 'Last New Query Initiated';
export const NEW_QUERY_INITIATED_COUNT = 'New Query Initiated Count';
export const FIRST_INITIAL_QUERY_READ_DATE = 'First Initial Query Read Date';
export const LAST_INITIAL_QUERY_READ_DATE = 'Last Initial Query Read Date';
export const INITIAL_QUERY_READ_COUNT = 'Initial Query Read Count';
export const FIRST_QUERY_READ_BY_LANDOWNER_DATE = 'First Query Read By Landowner Date';
export const LAST_QUERY_READ_BY_LANDOWNER_DATE = 'Last Query Read By Landowner Date';
export const QUERY_READ_BY_LANDOWNER_COUNT = 'Query Read By Landowner Count';
export const FIRST_ID_VERIFICATION_SUCCESSFUL_DATE = 'First ID Verification Successful Date';
export const LAST_ID_VERIFICATION_SUCCESSFUL_DATE = 'Last ID Verification Successful Date';
export const ID_VERIFICATION_SUCCESSFUL_COUNT = 'ID Verification Successful Count';
export const ID_VERIFICATION_STATUS = 'ID Verification Status';
export const ID_TRANSACTION_REFERENCE = 'ID Transaction Reference';
export const FIRST_LISTING_CLICK_DATE = 'First Listing Click Date';
export const LAST_LISTING_CLICK_DATE = 'Last Listing Click Date';
export const LISTING_CLICK_COUNT = 'Listing Click Count';
export const FIRST_BOOKING_SUBMITTED_DATE = 'First Booking Submitted Date';
export const LAST_BOOKING_SUBMITTED_DATE = 'Last Booking Submitted Date';
export const BOOKING_SUBMITTED_COUNT = 'Booking Submitted Count';
export const FIRST_BOOKING_STARTED_DATE = 'First Booking Started Date';
export const LAST_BOOKING_STARTED_DATE = 'Last Booking Started Date';
export const BOOKING_STARTED_COUNT = 'Booking Started Count';
export const PAYMENT_METHOD_SUBMIT_DATE = 'Payment Method Submit Date';
export const TRANSACTION_ID = 'Transaction ID';
export const LANDOWNER_BOOKING_FEE = 'Landowner Booking Fee';
export const SPORTSMAN_BOOKING_FEE = 'Sportsman Booking Fee';
export const FIRST_NEW_QUERY_READ_DATE = 'First New Query Read Date';
export const LAST_NEW_QUERY_READ_DATE = 'Last New Query Read Date';
export const NEW_QUERY_READ_COUNT = 'New Query Read Count';
export const FIRST_NEW_QUERY_REPLIED_DATE = 'First New Query Replied Date';
export const LAST_NEW_QUERY_REPLIED_DATE = 'Last New Query Replied Date';
export const NEW_QUERY_REPLIED_COUNT = 'New Query Replied Count';
export const FIRST_REPLY_READ_DATE = 'First Reply Read Date';
export const LAST_REPLY_READ_DATE = 'Last Reply Read Date';
export const LAST_REPLY_DATE = 'Last Reply Date';
export const REPLY_READ_COUNT = 'Reply Read Count';
export const FIRST_TRANSACTION_CANCELED_DATE = 'First Transaction Canceled Date';
export const LAST_TRANSACTION_CANCELED_DATE = 'Last Transaction Canceled Date';
export const TRANSACTION_CANCELED_COUNT = 'Transaction Canceled Count';
export const FIRST_TRANSACTION_CANCELED_BY_LANDOWNER_DATE =
  'First Transaction Canceled By Landowner Date';
export const LAST_TRANSACTION_CANCELED_BY_LANDOWNER_DATE =
  'Last Transaction Canceled By Landowner Date';
export const TRANSACTION_CANCELED_BY_LANDOWNER_COUNT = 'Transaction Canceled By Landowner Count';
export const LANDOWNER_REFUND = 'Landowner Refund';
export const TOTAL_LANDOWNER_REFUND = 'Total Landowner Refund';
export const SPORTSMAN_REFUND = 'Sportsman Refund';
export const TOTAL_SPORTSMAN_REFUND = 'Total Sportsman Refund';
export const FIRST_TRANSACTION_CANCELED_BY_SPORTSMAN_DATE =
  'First Transaction Canceled By Sportsman Date';
export const LAST_TRANSACTION_CANCELED_BY_SPORTSMAN_DATE =
  'Last Transaction Canceled By Sportsman Date';
export const TRANSACTION_CANCELED_BY_SPORTSMAN_COUNT = 'Transaction Canceled By Sportsman Count';
export const FIRST_REPLY_DATE = 'First Reply Date';
export const REPLY_COUNT = 'Reply Count';
export const FIRST_LOG_OUT_DATE = 'First Log out Date';
export const LAST_LOG_OUT_DATE = 'Last Log out Date';
export const TOTAL_LOG_OUT_COUNT = 'Total Log out Count';
export const FIRST_NEW_OFFER_CREATED_DATE = 'First New Offer Created Date';
export const LAST_NEW_OFFER_CREATED_DATE = 'Last New Offer Created Date';
export const OFFER_CREATED_COUNT = 'Offer Created Count';
export const FIRST_OFFER_ACCEPTED_DATE = 'First Offer Accepted Date';
export const LAST_OFFER_ACCEPTED_DATE = 'Last Offer Accepted Date';
export const OFFER_ACCEPTED_COUNT = 'Offer Accepted Count';
export const FIRST_OFFER_DECLINED_DATE = 'First Offer Declined Date';
export const LAST_OFFER_DECLINED_DATE = 'Last Offer Declined Date';
export const OFFER_DECLINED_COUNT = 'Offer Declined Count';
export const FIRST_OFFER_CANCELED_DATE = 'First Offer Canceled Date';
export const LAST_OFFER_CANCELED_DATE = 'Last Offer Canceled Date';
export const OFFER_CANCELED_COUNT = 'Offer Canceled Count';
export const LIKES_TO_FISH = 'Likes To Fish';
export const INTERESTED_ACTIVITIES = 'Interested Activities';
export const PREFERRED_HUNT_SPECIES = 'Preferred Hunt Species';
export const PREFERRED_HUNT_WEAPONS = 'Preferred Hunt Weapons';
export const PREFERRED_HUNT_STATES = 'Preferred Hunt States';
export const HOME_STATE = 'Home State';
export const PREFERRED_STATE = 'Preferred State';
export const FIRST_LISTING_FAVORED_DATE = 'First Listing Favored Date';
export const LAST_LISTING_FAVORED_DATE = 'Last Listing Favored Date';
export const LISTING_FAVORED_COUNT = 'Listing Favored Count';
export const FIRST_LISTING_UNFAVORED_DATE = 'First Listing Unfavored Date';
export const LAST_LISTING_UNFAVORED_DATE = 'Last Listing Unfavored Date';
export const LISTING_UNFAVORED_COUNT = 'Listing Unfavored Count';
export const LAST_LANDOWNER_REVIEW_RECEIVED = 'Last Landowner Review Received';
export const FIRST_LANDOWNER_REVIEW_RECEIVED = 'First Landowner Review Received';
export const LANDOWNER_REVIEW_RECEIVED_TIMESTAMP = 'Landowner Review Received Timestamp';
export const LAST_SPORTSMAN_REVIEW_RECEIVED = 'Last Landowner Review Received';
export const FIRST_SPORTSMAN_REVIEW_RECEIVED = 'First Landowner Review Received';
export const SPORTSMAN_REVIEW_RECEIVED_TIMESTAMP = 'Landowner Review Received Timestamp';
export const PARTY_MEMBERS_FORM_SUBMITTED = 'Party Members List';
export const DONATION = 'Donation';
export const PROMO_CODE = 'Promo Code';
