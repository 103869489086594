/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        <strong>TERMS OF SERVICE</strong>
      </p>
      <p>
        <strong>
          PLEASE READ THESE TERMS OF SERVICE (&ldquo;TERMS&rdquo;) CAREFULLY AS THEY CONTAIN
          IMPORTANT INFORMATION ABOUT YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. BY ACCESSING OR
          USING THE LANDTRUST PLATFORM, YOU AGREE TO COMPLY WITH AND BE BOUND BY THESE TERMS.
        </strong>
      </p>
      <p>
        <strong>
          THE TERMS OF USE INCLUDE A CLASS ACTION WAIVER AND REQUIRE BINDING ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS. By accepting these Terms,
          you agree to be bound by this arbitration clause and class action waiver. Please read it
          carefully.
        </strong>
      </p>
      <p>Last Updated: February 06, 2023</p>
      <p>
        These Terms constitute a legally binding agreement ("<strong>Agreement</strong>") between
        you and LandTrust, Inc. a Delaware corporation (&ldquo;<strong>LandTrust</strong>&rdquo;)
        governing your access to and use of the LandTrust website (the "<strong>Site</strong>"), our
        mobile applications, and application program interfaces (collectively, "
        <strong>Application</strong>") and all associated services (collectively, "
        <strong>LandTrust Services</strong>"). The Site, Application and LandTrust Services together
        are hereinafter collectively referred to as the &ldquo;<strong>LandTrust Platform</strong>
        &rdquo;.&nbsp;
      </p>
      <p>
        When these Terms mention &ldquo;<strong>LandTrust</strong>,&rdquo; &ldquo;
        <strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;
        <strong>our</strong>,&rdquo; it refers to LandTrust.&nbsp;
      </p>
      <p>
        Our collection and use of personal information in connection with your access to and use of
        the LandTrust Platform is described in our&nbsp;Privacy Policy.
      </p>
      <p>
        Any and all payment processing services in connection with your use of the LandTrust
        Platform ("<strong>Payment Services</strong>") are provided by a third-party payment
        processor, and subject to such third-party&rsquo;s terms of use.
      </p>
      <p>
        Landowners alone are responsible for identifying, understanding, and complying with all
        laws, rules and regulations that apply to their Listings and Landowner Services (as defined
        below). If you have questions about how local laws apply to your Listing(s) and Landowner
        Service(s) on LandTrust, you should always seek legal guidance.
      </p>
      <p>
        <strong>1. Scope of LandTrust Services</strong>
      </p>
      <p>
        1.1 The LandTrust Platform is an online marketplace that enables registered users (&ldquo;
        <strong>Users</strong>&rdquo;) and certain third parties who offer services (Users and third
        parties who offer services are &ldquo;<strong>Landowners</strong>&rdquo; and the services
        they offer are &ldquo;<strong>Landowner Services</strong>&rdquo;) to publish such Landowner
        Services on the LandTrust Platform (&ldquo;<strong>Listings</strong>&rdquo;) and to
        communicate and transact directly with Users who are seeking to book such Landowner Services
        (Users using Landowner Services are &ldquo;<strong>Guests</strong>&rdquo;). Landowner
        Services include the offering of single or multi-day access to Landowner&rsquo;s property
        for Landowner-specified hunting, fishing or other outdoor related activities (&ldquo;
        <strong>Activities</strong>&rdquo;). A Landowner may also provide Guests access to public
        lands through the Landowner&rsquo;s land.
      </p>
      <p>
        1.2 As the provider of the LandTrust Platform, LandTrust does not own, create, sell, resell,
        provide, control, manage, offer, deliver, or supply any Listings or Landowner Services.
        Landowners alone are responsible for their Listings and Landowner Services. When Users make
        or accept a booking, they are entering into a contract directly with each other. LandTrust
        is not, and does not become, a party to or otherwise participant in any contractual
        relationship between Users. LandTrust is not acting as an agent in any capacity for any
        User. By using the LandTrust Platform, you agree that any legal remedy or liability that you
        seek to obtain for actions or omissions of another User or other third parties will be
        limited to a claim against the particular User or other third parties who caused you harm.
        You agree not to attempt to impose liability on or seek any legal remedy from LandTrust with
        respect to such actions or omissions. Accordingly, we encourage you to communicate directly
        with the other User regarding any Listings.&nbsp;
      </p>
      <p>
        1.3 While we may help facilitate the resolution of disputes, LandTrust has no control over
        and does not guarantee (i) the existence, quality, safety, suitability, or legality of any
        Listings or Landowner Services, (ii) the truth or accuracy of any Listing descriptions or
        other User Content (as defined below), or (iii) the performance or conduct of any User or
        third party. LandTrust does not endorse any User, Listing or Landowner Services. Any
        references to a User being "verified" (or similar language) only indicate that the User has
        completed a relevant verification or identification process and nothing else. Any such
        description is not an endorsement, certification or guarantee by LandTrust about any User,
        including the User's identity or background or whether the User is trustworthy, safe or
        suitable. You should always exercise due diligence and care when deciding whether to use
        Landowner Services, accept a booking request from a Guest, or communicating and interacting
        with other Users, whether online or in person.
      </p>
      <p>
        1.4 If you choose to use the LandTrust Platform as a Landowner, your relationship with
        LandTrust is limited to being an independent, third-party contractor, and not an employee,
        agent, joint venturer or partner of LandTrust for any reason, and you act exclusively on
        your own behalf and for your own benefit, and not on behalf, or for the benefit, of
        LandTrust. LandTrust does not, and shall not be deemed to, direct or control you generally
        or in your performance under these Terms specifically, including in connection with your
        provision of the Landowner Services. You acknowledge and agree that you have complete
        discretion whether to list Landowner Services or otherwise engage in other business or
        employment activities.
      </p>
      <p>
        1.5 To promote the LandTrust Platform and to increase the exposure of Listings to potential
        Guests, Listings and other User Content may be displayed on other websites, in applications,
        within emails, and in online and offline advertisements.&nbsp;
      </p>
      <p>
        1.6 The LandTrust Platform may contain links to third-party websites or resources (&ldquo;
        <strong>Third-Party Services</strong>&rdquo;). Such Third-Party Services may be subject to
        different terms and conditions and privacy practices. LandTrust is not responsible or liable
        for the availability or accuracy of such Third-Party Services, or the content, products, or
        services available from such Third-Party Services. Links to such Third-Party Services are
        not an endorsement by LandTrust of such Third-Party Services.
      </p>
      <p>
        1.7 Due to the nature of the Internet, LandTrust cannot guarantee the continuous and
        uninterrupted availability and accessibility of the LandTrust Platform. LandTrust may
        restrict the availability of the LandTrust Platform or certain areas or features thereof, if
        this is necessary in view of capacity limits, the security or integrity of our servers, or
        to carry out maintenance measures that ensure the proper or improved functioning of the
        LandTrust Platform. LandTrust may improve, enhance and modify the LandTrust Platform and
        introduce new LandTrust Services from time to time.
      </p>
      <p>
        <strong>2. Eligibility, Using the LandTrust Platform, User Verification</strong>
      </p>
      <p>
        2.1 In order to access and use the LandTrust Platform or register a LandTrust Account you
        must be an individual at least 18 years old or a duly organized, validly existing business,
        organization or other legal entity in good standing under the laws of the state or country
        in which you are established, and able to enter into legally binding contracts.
      </p>
      <p>
        2.2 LandTrust may make access to and use of the LandTrust Platform, or certain areas or
        features of the LandTrust Platform, subject to certain conditions or requirements.
      </p>
      <p>
        2.3 Although LandTrust uses third party identity verification processes, User verification
        on the Internet is difficult and we cannot assure Landowners that such processes will result
        in accurate or complete information about a particular User.
      </p>
      <p>
        <strong>3. Modification of these Terms</strong>
      </p>
      <p>
        LandTrust reserves the right to modify these Terms at any time in accordance with this
        provision. If we make changes to these Terms, we will post the revised Terms on the
        LandTrust Platform and update the &ldquo;Last Updated&rdquo; date at the top of these Terms.
        We will also provide you with notice of the modifications by email at least thirty (30) days
        before the date they become effective. If you disagree with the revised Terms, you may
        terminate this Agreement with immediate effect. We will inform you about your right to
        terminate the Agreement in the notification email. If you do not terminate your Agreement
        before the date the revised Terms become effective, your continued access to or use of the
        LandTrust Platform will constitute acceptance of the revised Terms.
      </p>
      <p>
        <strong>4. Account Registration</strong>
      </p>
      <p>
        4.1 You must register an account ("<strong>LandTrust Account</strong>") to access and use
        certain features of the LandTrust Platform, such as publishing or booking a Listing. If you
        are registering a LandTrust Account for a business, organization or other legal entity, you
        represent and warrant that you have the authority to legally bind that entity and grant us
        all permissions and licenses provided in these Terms.
      </p>
      <p>
        4.2 You can register a LandTrust Account using an email address and creating a password, or
        through your account with certain third-party social networking services, such as Facebook
        or Google ("<strong>SNS Account</strong>"). You have the ability to disable the connection
        between your LandTrust Account and your SNS Account at any time, by accessing the "Settings"
        section of the LandTrust Platform.
      </p>
      <p>
        4.3 You must provide accurate, current and complete information during the registration
        process and keep your LandTrust Account and public LandTrust Account profile page
        information up-to-date at all times.
      </p>
      <p>
        4.4 You may not register more than one (1) LandTrust Account unless LandTrust authorizes you
        to do so. You may not assign or otherwise transfer your LandTrust Account to another party.
      </p>
      <p>
        4.5 You are responsible for maintaining the confidentiality and security of your LandTrust
        Account credentials and may not disclose your credentials to any third party. You are liable
        for any and all activities conducted through your LandTrust Account, unless you immediately
        notify LandTrust if you know, or have any reason to suspect, that your credentials have been
        lost, stolen, misappropriated, or otherwise compromised or in case of any actual or
        suspected unauthorized use of your LandTrust Account.
      </p>
      <p>
        4.6 If you signed up for a subscription-based membership, your membership will automatically
        renew at the conclusion of the then-current one year term unless you turn off auto-renewal
        at least twenty-four (24) hours before the conclusion of the then-current term. You must
        cancel your membership or turn off auto-renewal to end recurring charges. You may cancel
        your membership at any time; you will have access to the membership for the remaining
        portion of the then-current term. LandTrust will not issue you a refund for early
        termination of your membership. In order to terminate your subscription-based membership,
        you must log in to your account at LandTrust.com and navigate to your “Profile” in the upper
        right corner. Then select the “Billing & Membership” section on your profile page. You will
        then see an option to “Cancel Membership”.
      </p>
      <p>
        <strong>5. Content</strong>
      </p>
      <p>
        5.1 LandTrust may, in its sole discretion, enable Users to (i) create, upload, post, send,
        receive and store content, such as text, photos, audio, video, or other materials and
        information on or through the LandTrust Platform ("<strong>User Content</strong>"); and (ii)
        access and view User Content and any content that LandTrust itself makes available on or
        through the LandTrust Platform, including proprietary LandTrust content and any content
        licensed or authorized for use by or through LandTrust from a third party ("
        <strong>LandTrust Content</strong>" and together with User Content, "
        <strong>Collective Content</strong>").
      </p>
      <p>
        5.2 The LandTrust Platform, LandTrust Content, and User Content may in its entirety or in
        part be protected by copyright, trademark, and/or other laws of the United States and other
        countries. You acknowledge and agree that the LandTrust Platform and LandTrust Content,
        including all associated intellectual property rights, are the exclusive property of
        LandTrust and/or its licensors or authorizing third parties. You will not remove, alter or
        obscure any copyright, trademark, service mark or other proprietary rights notices
        incorporated in or accompanying the LandTrust Platform, LandTrust Content or User Content.
        All trademarks, service marks, logos, trade names, and any other source identifiers of
        LandTrust used on or in connection with the LandTrust Platform and LandTrust Content are
        trademarks or registered trademarks of LandTrust in the United States and abroad.
        Trademarks, service marks, logos, trade names and any other proprietary designations of
        third parties used on or in connection with the LandTrust Platform, LandTrust Content,
        and/or Collective Content are used for identification purposes only and may be the property
        of their respective owners.
      </p>
      <p>
        5.3 You will not use, copy, adapt, modify, prepare derivative works of, distribute, license,
        sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit
        the LandTrust Platform or Collective Content, except to the extent you are the legal owner
        of certain User Content or as expressly permitted in these Terms. No licenses or rights are
        granted to you by implication or otherwise under any intellectual property rights owned or
        controlled by LandTrust or its licensors, except for the licenses and rights expressly
        granted in these Terms.
      </p>
      <p>
        5.4 Subject to your compliance with these Terms, LandTrust grants you a limited,
        non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and
        use the Application on your personal device(s); and (ii) access and view any Collective
        Content made available on or through the LandTrust Platform and accessible to you, solely
        for your personal and non-commercial use.
      </p>
      <p>
        5.5 By creating, uploading, posting, sending, receiving, storing, or otherwise making
        available any User Content on or through the LandTrust Platform, you grant to LandTrust a
        non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the
        protection), sub-licensable and transferable license to such User Content to access, use,
        store, copy, modify, prepare derivative works of, distribute, publish, transmit, stream,
        broadcast, and otherwise exploit in any manner such User Content to provide and/or promote
        the LandTrust Platform, in any media or platform. Insofar as User Content (including
        Verified Images) includes personal information, such User Content will only be used for
        these purposes if such use complies with applicable data protection laws in accordance with
        our&nbsp;<a href="https://landtrust.com/privacy-policy">Privacy Policy</a>. Unless you
        provide specific consent, LandTrust does not claim any ownership rights in any User Content
        and nothing in these Terms will be deemed to restrict any rights that you may have to use or
        exploit your User Content.
      </p>
      <p>
        5.6 You are solely responsible for all User Content that you make available on or through
        the LandTrust Platform. Accordingly, you represent and warrant that: (i) you either are the
        sole and exclusive owner of all User Content that you make available on or through the
        LandTrust Platform or you have all rights, licenses, consents and releases that are
        necessary to grant to LandTrust the rights in and to such User Content, as contemplated
        under these Terms; and (ii) neither the User Content nor your posting, uploading,
        publication, submission or transmittal of the User Content or LandTrust's use of the User
        Content (or any portion thereof) as contemplated under these Terms will infringe,
        misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral
        rights or other proprietary or intellectual property rights, or rights of publicity or
        privacy, or result in the violation of any applicable law or regulation.
      </p>
      <p>
        5.7 You will not post, upload, publish, submit or transmit any User Content that: (i) is
        fraudulent, false, misleading (directly or by omission or failure to update information) or
        deceptive; (ii) is defamatory, libelous, obscene, pornographic, vulgar or offensive; (iii)
        promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual
        or group; (iv) is violent or threatening or promotes violence or actions that are
        threatening to any other person; (v) promotes illegal or harmful activities or substances;
        or (vi) violates LandTrust policy. LandTrust may, without prior notice, remove or disable
        access to any User Content that LandTrust finds to be in violation of applicable law, these
        Terms, or otherwise may be harmful or objectionable to LandTrust, its Users, third parties,
        or property.
      </p>
      <p>
        5.8 LandTrust respects copyright law and expects its Users to do the same. If you believe
        that any content on the LandTrust Platform infringes copyrights you own, please notify us in
        accordance with our Copyright Policy.
      </p>
      <p>
        <strong>6. Service Fees</strong>
      </p>
      <p>
        6.1 LandTrust may charge fees to Landowners ("<strong>Landowner Fees</strong>") and/or
        Guests ("<strong>Guest Fees</strong>") (collectively, "<strong>Service Fees</strong>") in
        consideration for the use of the LandTrust Platform. More information about when Service
        Fees apply and how they are calculated can be found on our Service Fees page.
      </p>
      <p>
        6.2 Any applicable Service Fees (including any applicable Taxes) will be displayed to a
        Landowner or Guest prior to publishing or booking a Listing. LandTrust reserves the right to
        change the Service Fees at any time, and will provide Users adequate notice of any fee
        changes before they become effective. Such fee changes will not affect any bookings made
        prior to the effective date of the fee change.
      </p>
      <p>6.3 You are responsible for paying any Service Fees that you owe to LandTrust.</p>
      <p>
        6.4 You will not use the LandTrust Platform, LandTrust Content, and User Content, in an
        effort to contravene any rights of LandTrust to collect Services Fees, including, without
        limitation, encouraging any Guest or Landowner to book or list a Listing other than through
        the LandTrust Platform. If you make any booking other than on the LandTrust Platform, that
        booking will not be covered by these Terms of Service or LandTrust&rsquo;s insurance.&nbsp;
      </p>
      <p>
        <strong>7. Terms specific for Landowners</strong>
      </p>
      <p>
        <strong>7.1 Terms applicable to all Listings</strong>
      </p>
      <p>
        7.1.1 When creating a Listing through the LandTrust Platform you must (i) provide complete
        and accurate information about your Landowner Service (such as listing description,
        location, and calendar availability), (ii) disclose any deficiencies, restrictions and
        requirements that apply and (iii) provide any other pertinent information requested by
        LandTrust. You are responsible for keeping your Listing information (including calendar
        availability) up-to-date at all times.
      </p>
      <p>
        7.1.2 When creating a listing, you represent and warrant that you are the owner of the
        property, or have the right to offer the activities listed on the LandTrust Platform. In the
        sole discretion of LandTrust, LandTrust may subject any listing to a review process before
        making the listing available to LandTrust guests. Non-owner listings may be subject to
        additional review at LandTrust's sole discretion. LandTrust reserves the right to reject any
        listing for any reason.
      </p>
      <p>
        7.13 You are solely responsible for setting a price (including any Taxes if applicable) for
        your Listing (&ldquo;<strong>Listing Fee</strong>&rdquo;). Once a Guest requests a booking
        of your Listing, you may not request that the Guest pays a higher price than in the booking
        request.
      </p>
      <p>
        7.1.4 Any terms and conditions included in your Listing, in particular in relation to
        cancellations, must not conflict with these Terms or the relevant cancellation policy for
        your Listing.
      </p>
      <p>
        7.1.5 Pictures, animations or videos (collectively, "<strong>Images</strong>") used in your
        Listings must accurately reflect the quality and condition of your Landowner Services.
      </p>
      <p>
        7.1.6 The placement and ranking of Listings in search results on the LandTrust Platform may
        vary and depend on a variety of factors, such as Guest search parameters and preferences,
        Landowner requirements, price and calendar availability, number and quality of Images,
        customer service and cancellation history, Reviews and Ratings, type of Landowner Service,
        and/or ease of booking.
      </p>
      <p>
        7.1.7 When you accept or have pre-approved a booking request by a Guest, you are entering
        into a legally binding agreement with the Guest and are required to provide your Landowner
        Service(s) to the Guest as described in your Listing when the booking request is made. You
        also agree to pay the applicable Landowner Fee and any applicable Taxes.
      </p>
      <p>
        7.1.8 Although LandTrust has obtained certain insurance (see{' '}
        <a href="https://landtrust.com/insurance">https://landtrust.com/insurance</a>), LandTrust
        recommends that Landowners obtain appropriate insurance for their Landowner Services. Please
        review any respective insurance policy carefully, and in particular make sure that you are
        familiar with and understand any exclusions to, and any deductibles that may apply for, such
        insurance policy, including, but not limited to, whether or not your insurance policy will
        cover the actions or inactions of Guests while participating in your Landowner Service.
      </p>
      <p>
        7.1.9 When listing an Activity, you must, where applicable, fully educate and inform Guests
        about (i) any risks inherent to the Activity, (ii) any requirements for participation, such
        as the minimum age, related skills, level of fitness or other requirements, and (iii)
        anything else they may need to know to safely participate in the Activity (including special
        certifications or licenses).
      </p>
      <p>
        <strong>8. Terms specific for Guests</strong>
      </p>
      <p>
        <strong>8.1 Terms applicable to all bookings</strong>
      </p>
      <p>
        8.1.1 Subject to meeting any requirements (such as completing any verification processes)
        set by LandTrust and/or the Landowner, you can book a Listing available on the LandTrust
        Platform by following the respective booking process. All applicable fees, including the
        Listing Fee, Security Deposit (if applicable), Guest Fee and any applicable Taxes
        (collectively, &ldquo;<strong>Total Fees</strong>&rdquo;) will be presented to you prior to
        booking a Listing. You agree to pay the Total Fees for any booking requested in connection
        with your LandTrust Account.
      </p>
      <p>
        8.1.2 Upon receipt of a booking confirmation from LandTrust, a legally binding agreement is
        formed between you and your Landowner, subject to any additional terms and conditions of the
        Landowner that apply, including in particular the applicable cancellation policy and any
        rules and restrictions specified in the Listing.&nbsp;
      </p>
      <p>
        8.1.3 If you book a Landowner Service on behalf of additional Guests, you are required to
        ensure that every additional Guest meets any requirements set by the Landowner, and is made
        aware of and agrees to these Terms and any terms and conditions, rules and restrictions set
        by the Landowner. If you are booking for an additional Guest who is a minor, you represent
        and warrant that you are legally authorized to act on behalf of the minor. Minors may only
        participate in the Landowner Service if accompanied by an adult who is responsible for them.
      </p>
      <p>
        8.1.4 You should carefully review the description of any Landowner Service you intend to
        book to ensure you (and any additional Guests you are booking for) meet any minimum age,
        proficiency, fitness or other requirements which the Landowner has specified in their
        Listing.&nbsp;
      </p>
      <p>
        8.1.5 Before and during an Activity you must at all times adhere to the Landowners&rsquo;
        instructions and&nbsp;all applicable laws.
      </p>
      <p>
        8.1.6 Each&nbsp;Guest is responsible for ensuring that he and all members of his party have
        all appropriate permits, licenses, and tags. Should the Guest realize he, or his party, has
        performed an Activity without the appropriate permit, license, or tag, it is the
        Guest&rsquo;s responsibility to report the occurrence to the appropriate authority.
      </p>
      <p>
        8.1.7 Each Guest is responsible for his own safety and for handling an emergency, and should
        have a plan of what to do in case he needs assistance. Each Guest should have a method of
        communication and check-in process, but should also be aware that cell coverage is spotty
        and not always reliable. Guests cannot expect Landowners to rescue them. For a medical
        emergency, Guests should contact emergency services.
      </p>
      <p>
        8.1.8 Although LandTrust has obtained certain insurance (see{' '}
        <a href="https://landtrust.com/insurance">https://landtrust.com/insurance</a>), LandTrust
        recommends that Users obtain appropriate insurance, including appropriate vehicle and health
        insurance. Please review any respective insurance policy carefully, and in particular make
        sure that you are familiar with and understand any exclusions to, and any deductibles that
        may apply for, such insurance policy, including, but not limited to, whether or not your
        insurance policy will cover the provide sufficient medical coverage in the event you or a
        member of your party are injured while participating in an activity booked on the LandTrust
        Platform.You should not consider LandTrust as an insurer, and you should not rely on any
        insurance that LandTrust may provide as being sufficient or an alternative to insurance you
        maintain, or should maintain.
      </p>
      <p id="cancellation-policy">
        <strong>9. Booking Modifications, Cancellations and Refunds</strong>
      </p>
      <p>
        9.1 Landowners and Guests are responsible for any modifications to a booking that they make
        via the LandTrust Platform or direct LandTrust customer service to make ("
        <strong>Booking Modifications</strong>"), and agree to pay any additional Listing Fees,
        Landowner Fees or Guest Fees and/or Taxes associated with such Booking Modifications.
      </p>
      <p>
        9.2 Subject to Section 9.4, below, Guests can cancel a confirmed booking at any time
        pursuant to the Listing&rsquo;s cancellation policy set by the Landowner.
      </p>
      <p>
        9.3 If a Landowner cancels a confirmed booking, the Guest will receive a full refund of the
        Total Fees for such booking.&nbsp;
      </p>
      <p>
        9.4 If a Guest cancels a&nbsp;confirmed booking within 48 hours from the time of booking
        confirmation, the Guest will be given a full refund. Unless otherwise set forth in the
        Listing,&nbsp;If a Guest cancels a&nbsp;confirmed booking more than 48 hours from the time a
        booking has been confirmed by the Landowner, the Guest will not receive a refund. This
        policy is to protect Landowners from potentially losing their only opportunities to make
        money from their properties due to the nature of certain Activities and the limited time
        windows available for them.
      </p>
      <p>
        9.5 If a Guest is a LandTrust+ member and has booked a hunt with a participating landowner
        before draw results are known, and that Guest is later unsuccessful in drawing the necessary
        tag, then the Guest will be given a full refund, less any credit card processing fees, so
        long as they notify LandTrust customer service within 14 days of receiving their draw
        results.
      </p>
      <p>
        <strong>10. Ratings and Reviews</strong>
      </p>
      <p>
        10.1 Within a certain time frame after completing a booking, Guests and Landowners can leave
        a public review (&ldquo;<strong>Review</strong>&rdquo;) and submit a star rating (&ldquo;
        <strong>Rating</strong>&rdquo;) about each other. Ratings or Reviews reflect the opinions of
        individual Users and do not reflect the opinion of LandTrust. Ratings and Reviews are not
        verified by LandTrust for accuracy and may be incorrect or misleading.
      </p>
      <p>
        10.2 Ratings and Reviews by Guests and Landowners must be accurate and may not contain any
        offensive or defamatory language.&nbsp;
      </p>
      <p>
        10.3 Users are prohibited from manipulating the Ratings and Reviews system in any manner,
        such as instructing a third party to write a positive or negative Review about another User.
      </p>
      <p>
        10.4 Ratings and Reviews are part of a User&rsquo;s public profile and may also be surfaced
        elsewhere on the LandTrust Platform (such as the Listing page) together with other relevant
        information such as number of bookings, number of cancellations, average response time and
        other information.
      </p>
      <p>
        <strong>11. Damage to Property, Disputes between Users</strong>
      </p>
      <p>
        11.1 As a Guest, you are responsible for leaving the Landowner&rsquo;s property in the
        condition it was in when you arrived. You are responsible for your own acts and omissions
        and are also responsible for the acts and omissions of any member of your party.
      </p>
      <p>
        <strong>12. Taxes</strong>
      </p>
      <p>
        12.1 As a Landowner you are solely responsible for determining your obligations to report,
        collect, remit or include in your Listing Fees any applicable VAT or other indirect sales
        taxes, occupancy tax, tourist or other visitor taxes or income taxes ("
        <strong>Taxes</strong>").
      </p>
      <p>
        <strong>13. Prohibited Activities</strong>
      </p>
      <p>
        13.1 You are solely responsible for compliance with any and all laws, rules, regulations,
        and Tax obligations that may apply to your use of the LandTrust Platform. In connection with
        your use of the LandTrust Platform, you will not and will not assist or enable others to:
      </p>
      <ul>
        <li>
          breach or circumvent any applicable laws or regulations, agreements with third-parties,
          third-party rights, or our Terms;
        </li>
        <li>
          use the LandTrust Platform or Collective Content for any commercial or other purposes that
          are not expressly permitted by these Terms or in a manner that falsely implies LandTrust
          endorsement, partnership or otherwise misleads others as to your affiliation with
          LandTrust;
        </li>
        <li>
          copy, store or otherwise access or use any information, including personally identifiable
          information about any other User, contained on the LandTrust Platform in any way that is
          inconsistent with LandTrust&rsquo;s Privacy Policy, these Terms or that otherwise violates
          the privacy rights of Users or third parties;
        </li>
        <li>
          use the LandTrust Platform in connection with the distribution of unsolicited commercial
          messages ("spam");
        </li>
        <li>
          offer, as a Landowner, access to property that you do not yourself own or have permission
          to make available through the LandTrust Platform;
        </li>
        <li>
          contact another User for any purpose other than asking a question related to your own
          booking, Listing, or the User's use of the LandTrust Platform, including, but not limited
          to, recruiting or otherwise soliciting any User to join third-party services, applications
          or websites, without our prior written approval;
        </li>
        <li>
          use the LandTrust Platform to request, make or accept a booking independent of the
          LandTrust Platform, to circumvent any Service Fees or for any other reason;
        </li>
        <li>
          request, accept or make any payment for Listing Fees outside of the LandTrust Platform. If
          you do so, you acknowledge and agree that you: (i) would be in breach of these Terms; (ii)
          accept all risks and responsibility for such payment, and (iii) hold LandTrust harmless
          from any liability for such payment;
        </li>
        <li>
          discriminate against or harass anyone on the basis of race, national origin, religion,
          gender, gender identity, physical or mental disability, medical condition, marital status,
          age or sexual orientation, or otherwise engage in any violent, harmful, abusive or
          disruptive behavior;
        </li>
        <li>
          use, display, mirror or frame the LandTrust Platform or Collective Content, or any
          individual element within the LandTrust Platform, LandTrust's name, any LandTrust
          trademark, logo or other proprietary information, or the layout and design of any page or
          form contained on a page in the LandTrust Platform, without LandTrust's express written
          consent;
        </li>
        <li>
          dilute, tarnish or otherwise harm the LandTrust brand in any way, including through
          unauthorized use of Collective Content, registering and/or using LandTrust or derivative
          terms in domain names, trade names, trademarks or other source identifiers, or registering
          and/or using domain names, trade names, trademarks or other source identifiers that
          closely imitate or are confusingly similar to LandTrust domains, trademarks, taglines,
          promotional campaigns or Collective Content;
        </li>
        <li>
          use any robot, spider, crawler, scraper or other automated means or processes to access,
          collect data or other content from or otherwise interact with the LandTrust Platform for
          any purpose;
        </li>
        <li>
          avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent
          any technological measure implemented by LandTrust or any of LandTrust's providers or any
          other third party to protect the LandTrust Platform;
        </li>
        <li>
          attempt to decipher, decompile, disassemble or reverse engineer any of the software used
          to provide the LandTrust Platform;
        </li>
        <li>
          take any action that damages or adversely affects, or could damage or adversely affect the
          performance or proper functioning of the LandTrust Platform;
        </li>
        <li>
          export, re-export, import, or transfer the Application except as authorized by United
          States law, the export control laws of your jurisdiction, and any other applicable laws;
          or
        </li>
        <li>violate or infringe anyone else&rsquo;s rights or otherwise cause harm to anyone.</li>
      </ul>
      <p>
        13.2 You acknowledge that LandTrust has no obligation to monitor the access to or use of the
        LandTrust Platform by any User or to review, disable access to, or edit any User Content,
        but has the right to do so to (i) operate, secure and improve the LandTrust Platform
        (including without limitation for fraud prevention, risk assessment, investigation and
        customer support purposes); (ii) ensure Users&rsquo; compliance with these Terms; (iii)
        comply with applicable law or the order or requirement of a court, law enforcement or other
        administrative agency or governmental body; (iv) respond to User Content that it determines
        is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to
        cooperate with and assist LandTrust in good faith, and to provide LandTrust with such
        information and take such actions as may be reasonably requested by LandTrust with respect
        to any investigation undertaken by LandTrust or a representative of LandTrust regarding the
        use or abuse of the LandTrust Platform.
      </p>
      <p>
        13.3 If you feel that any User you interact with, whether online or in person, is acting or
        has acted inappropriately, including but not limited to anyone who (i) engages in offensive,
        violent or sexually inappropriate behavior, (ii) you suspect of stealing from you, or (iii)
        engages in any other disturbing conduct, you should immediately report such person to the
        appropriate authorities and then to LandTrust by contacting us with your police station and
        report number (if available). You agree that any report you make will not obligate us to
        take any action (beyond that required by law, if any).
      </p>
      <p>
        <strong>14. Term and Termination, Suspension and other Measures</strong>
      </p>
      <p>
        14.1 This Agreement shall be effective until such time when you or LandTrust terminate the
        Agreement in accordance with this Section 14.
      </p>
      <p>
        14.2 You may terminate this Agreement at any time by sending us an email. If you cancel your
        LandTrust Account as a Landowner, any confirmed booking(s) will be automatically cancelled
        and your Guests will receive a full refund, net of LandTrust&rsquo;s booking fees. If you
        cancel your LandTrust Account as a Guest, any confirmed booking(s) will be automatically
        cancelled and any refund will depend upon the terms of the Listing&rsquo;s cancellation
        policy.
      </p>
      <p>
        14.3 Without limiting our rights specified below, LandTrust may terminate this Agreement for
        convenience at any time by giving you thirty (30) days' notice via email to your registered
        email address.
      </p>
      <p>
        14.4 LandTrust may immediately, without notice, terminate this Agreement and/or stop
        providing access to the LandTrust Platform if (i) you have materially breached your
        obligations under these Terms or our Privacy Policy, (ii) you have violated applicable laws,
        regulations or third party rights, or (iii) LandTrust believes in good faith that such
        action is reasonably necessary to protect the personal safety or property of LandTrust, its
        Users, or third parties (for example in the case of fraudulent behavior of a User).
      </p>
      <p>
        14.5 In addition, LandTrust may take any of the following measures (i) to comply with
        applicable law, or the order or request of a court, law enforcement or other administrative
        agency or governmental body, or if (ii) you have breached these Terms, our Privacy Policy,
        applicable laws, regulations, or third party rights, (iii) you have provided inaccurate,
        fraudulent, outdated or incomplete information during the LandTrust Account registration,
        Listing process or thereafter, (iv) you and/or your Listings or Landowner Services at any
        time fail to meet any applicable quality or eligibility criteria, (v) you have repeatedly
        received poor Ratings or Reviews or LandTrust otherwise becomes aware of or has received
        complaints about your performance or conduct, (vi) you have repeatedly cancelled confirmed
        bookings or failed to respond to booking requests without a valid reason, or (vii) LandTrust
        believes in good faith that such action is reasonably necessary to protect the personal
        safety or property of LandTrust, its Users, or third parties, or to prevent fraud or other
        illegal activity:
      </p>
      <ul>
        <li>cancel any pending or confirmed bookings;</li>
        <li>limit your access to or use of the LandTrust Platform;</li>
        <li>
          temporarily or permanently suspend your LandTrust Account and stop providing access to the
          LandTrust Platform.
        </li>
      </ul>
      <p>
        14.6 When this Agreement has been terminated, you are not entitled to a restoration of your
        LandTrust Account or any of your User Content. If your access to or use of the LandTrust
        Platform has been limited or your LandTrust Account has been suspended or this Agreement has
        been terminated by us, you may not register a new LandTrust Account or access and use the
        LandTrust Platform through a LandTrust Account of another User.
      </p>
      <p>
        14.7 This Section 14.7 and Sections 5 and 15 to 21 of these Terms shall survive any
        termination or expiration of this Agreement.
      </p>
      <p>
        <strong>15. Disclaimers</strong>
      </p>
      <p>
        <strong>
          If you choose to use the LandTrust Platform or Collective Content, you do so voluntarily
          and at your sole risk. The LandTrust Platform and Collective Content is provided &ldquo;as
          is&rdquo;, without warranty of any kind, either express or implied.
        </strong>
      </p>
      <p>
        <strong>
          You agree that you have had whatever opportunity you deem necessary to investigate the
          LandTrust Services, laws, rules, or regulations that may be applicable to your Listings
          and/or Landowner Services you are receiving and that you are not relying upon any
          statement of law or fact made by LandTrust relating to a Listing.
        </strong>
      </p>
      <p>
        <strong>
          YOU AGREE THAT SOME LANDOWNER SERVICES MAY CARRY INHERENT RISK, AND BY PARTICIPATING IN
          SUCH SERVICES, YOU CHOOSE TO ASSUME THOSE RISKS VOLUNTARILY. FOR EXAMPLE, SOME LANDOWNER
          SERVICES MAY CARRY RISK OF ILLNESS, BODILY INJURY, DISABILITY, OR DEATH, AND YOU FREELY
          AND WILLFULLY ASSUME THOSE RISKS BY CHOOSING TO PARTICIPATE IN THOSE LANDOWNER SERVICES.
          YOU ASSUME FULL RESPONSIBILITY FOR THE CHOICES YOU MAKE BEFORE, DURING AND AFTER YOUR
          PARTICIPATION IN A LANDOWNER SERVICE. IF YOU ARE BRINGING A MINOR AS AN ADDITIONAL GUEST,
          YOU ARE SOLELY RESPONSIBLE FOR THE SUPERVISION OF THAT MINOR THROUGHOUT THE DURATION OF
          YOUR LANDOWNER SERVICE AND TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE TO RELEASE
          AND HOLD HARMLESS LANDTRUST FROM ALL LIABILITIES AND CLAIMS THAT ARISE IN ANY WAY FROM ANY
          INJURY, DEATH, LOSS OR HARM THAT OCCURS TO THAT MINOR DURING THE LANDOWNER SERVICE OR IN
          ANY WAY RELATED TO YOUR LANDOWNER SERVICE. YOU FURTHER AGREE TO RELEASE AND HOLD HARMLESS
          LANDOWNER FROM ALL LIABILITY AND CLAIMS THAT ARISE IN ANY WAY FROM ANY INJURY, DEATH, LOSS
          OR HARM THAT OCCURS TO YOU OR A MINOR DURING YOUR PARTICIPATION IN THE LANDOWNER SERVICES,
          OTHER THAN ANY SUCH INJURY, DEATH, LOSS OR HARM ARISING FROM LANDOWNER&rsquo;S GROSS
          NEGLIGENCE OR WILLFUL MISCONDUCT.
        </strong>
      </p>
      <p>
        <strong>
          The foregoing disclaimers apply to the maximum extent permitted by law. You may have other
          statutory rights. However, the duration of statutorily required warranties, if any, shall
          be limited to the maximum extent permitted by law.
        </strong>
      </p>
      <p>
        <strong>16. Liability</strong>
      </p>
      <p>
        <strong>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK
          ARISING OUT OF YOUR ACCESS TO AND USE OF THE LANDTRUST PLATFORM AND COLLECTIVE CONTENT,
          YOUR PUBLISHING OR BOOKING OF ANY LISTING VIA THE LANDTRUST PLATFORM, YOUR PARTICIPATION
          LANDOWNER SERVICE, OR ANY OTHER INTERACTION YOU HAVE WITH OTHER USERS WHETHER IN PERSON OR
          ONLINE REMAINS WITH YOU. NEITHER LANDTRUST NOR ANY OTHER PARTY INVOLVED IN CREATING,
          PRODUCING, OR DELIVERING THE LANDTRUST PLATFORM OR COLLECTIVE CONTENT WILL BE LIABLE FOR
          ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS
          OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR
          THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY
          INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH (I) THESE TERMS, (II)
          FROM THE USE OF OR INABILITY TO USE THE LANDTRUST PLATFORM OR COLLECTIVE CONTENT, (III)
          FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OR OTHER PERSONS WITH
          WHOM YOU COMMUNICATE, INTERACT OR MEET WITH AS A RESULT OF YOUR USE OF THE LANDTRUST
          PLATFORM, OR (IV) FROM YOUR PUBLISHING OR BOOKING OF A LISTING, INCLUDING THE PROVISION OR
          USE OF A LISTING&rsquo;S LANDOWNER SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
          (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
          LANDTRUST HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY
          SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. In no event will
          LandTrust&rsquo;s aggregate liability arising out of or in connection with these Terms and
          your use of the LandTrust Platform including, but not limited to, from your publishing or
          booking of any Listings via the LandTrust Platform, or from the use of or inability to use
          the LandTrust Platform or Collective Content and in connection with any Landowner Service
          or any interactions with any other Users, exceed the amounts you have paid or owe for
          bookings via the LandTrust Platform as a Guest in the twelve (12) month period prior to
          the event giving rise to the liability, or if you are a Landowner, the amounts paid by
          LandTrust to you in the twelve (12) month period prior to the event giving rise to the
          liability, or one hundred U.S. dollars (US$100), if no such payments have been made, as
          applicable. The assumption of liability and limitations of damages set forth above are
          fundamental elements of the basis of the bargain between LandTrust and you. Some
          jurisdictions do not allow the exclusion or limitation of liability for consequential or
          incidental damages, so the above limitation may not apply to you.&nbsp;
        </strong>
      </p>
      <p>
        <strong>17. Indemnification</strong>
      </p>
      <p>
        To the maximum extent permitted by applicable law, you agree to release, defend (at
        LandTrust&rsquo;s option), indemnify, and hold LandTrust, its affiliates, and their
        respective officers, directors, employees and agents, harmless from and against any claims,
        liabilities, damages, losses, and expenses, including, without limitation, reasonable legal
        and accounting fees, arising out of or in any way connected with (i) your breach of these
        Terms or our Privacy Policy, (ii) your improper use of the LandTrust Platform or any
        LandTrust Services, (iii) your interaction with any User or participation in any Landowner
        Service, including without limitation any injuries, losses or damages (whether compensatory,
        direct, incidental, consequential or otherwise) of any kind arising in connection with or as
        a result of such interaction, participation, or use, or (iv) your breach of any laws,
        regulations or third party rights.
      </p>
      <p>
        In addition, each Guest, on behalf of himself and each member and/or guest of Guest, agrees
        to indemnify, defend, and save and hold the Landowner, its affiliates, and all of their
        agents, contractors, and employees (collectively, the &ldquo;Landowner Indemnified
        Parties&rdquo;) free and harmless for, from, and against any and all liens, claims, loss,
        injury, costs, liability, demands, causes of action, and/or damages including any incidental
        and consequential expenses/damages incurred by the Landowner Indemnified Parties associated
        with or at all attributable to (directly or indirectly) any injury to or death of persons or
        damage to property (including but not in any way limited to the Landowner's property and
        costs and attorney fees incurred in defense), in whole or in part caused by, associated
        with, or in any way attributable to (directly or indirectly), the condition of the
        Landowner&rsquo;s property and/or by acts of commission, omission or negligence on the part
        of the Guest, his guests, agents or employees, (including trespass onto neighboring lands),
        arising in conjunction with access to the Landowner Services, whether such loss or liability
        is caused or contributed to by any act of the Landowner Indemnified Parties or by the
        condition of the Landowner&rsquo;s property, including the conditions of any private roads,
        bridges, drainage structures, gates, or other infrastructure installed or maintained by any
        of the Landowner Indemnified Parties
      </p>
      <p>
        <strong>18. Dispute Resolution</strong>
      </p>
      <p>
        You agree that all matters relating to your access to or use of the LandTrust Platform,
        including all disputes, will be governed by the laws of the United States and by the laws of
        the State of Delaware without regard to its conflicts of laws provisions. You agree to the
        personal jurisdiction by and venue in the state and federal courts in Gallatin County,
        Montana or, if unavailable, in Yellowstone County, Montana, and waive any objection to such
        jurisdiction or venue. Any claim under these Terms must be brought within one (1) year after
        the cause of action arises, or such claim or cause of action is barred. No recovery may be
        sought or received for damages other than out-of-pocket expenses, except that the prevailing
        party will be entitled to costs and attorneys&rsquo; fees. In the event of any controversy
        or dispute between LandTrust and you arising out of or in connection with your use of the
        LandTrust Platform, the parties shall attempt, promptly and in good faith, to resolve any
        such dispute. If we are unable to resolve any such dispute within a reasonable time (not to
        exceed thirty (30) days), then either party may submit such controversy or dispute to
        mediation. If the dispute cannot be resolved through mediation, then the parties shall be
        free to pursue any right or remedy available to them under applicable law.
      </p>
      <p>
        <strong>19. Feedback</strong>
      </p>
      <p>
        We welcome and encourage you to provide feedback, comments and suggestions for improvements
        to the LandTrust Platform (&ldquo;<strong>Feedback</strong>&ldquo;). You may submit Feedback
        by emailing us, through the &ldquo;Contact&rdquo; section of the LandTrust Platform, or by
        other means of communication. Any Feedback you submit to us will be considered
        non-confidential and non-proprietary to you. By submitting Feedback to us, you grant us a
        non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to
        use and publish those ideas and materials for any purpose, without compensation to you.
      </p>
      <p>
        <strong>20. Applicable Law</strong>
      </p>
      <p>
        These Terms will be interpreted in accordance with the laws of the State of Montana and the
        United States of America, without regard to conflict-of-law provisions.&nbsp;
      </p>
      <p>
        <strong>21. General Provisions</strong>
      </p>
      <p>
        21.1 Except as they may be supplemented by additional terms and conditions, policies,
        guidelines or standards, these Terms constitute the entire Agreement between LandTrust and
        you pertaining to the subject matter hereof, and supersede any and all prior oral or written
        understandings or agreements between LandTrust and you in relation to the access to and use
        of the LandTrust Platform.
      </p>
      <p>
        21.2 No joint venture, partnership, employment, or agency relationship exists between you
        and LandTrust as a result of this Agreement or your use of the LandTrust Platform.
      </p>
      <p>
        21.3 These Terms do not and are not intended to confer any rights or remedies upon any
        person other than the parties.
      </p>
      <p>
        21.4 If any provision of these Terms is held to be invalid or unenforceable, such provision
        will be struck and will not affect the validity and enforceability of the remaining
        provisions.
      </p>
      <p>
        21.5 LandTrust&rsquo;s failure to enforce any right or provision in these Terms will not
        constitute a waiver of such right or provision unless acknowledged and agreed to by us in
        writing. Except as expressly set forth in these Terms, the exercise by either party of any
        of its remedies under these Terms will be without prejudice to its other remedies under
        these Terms or otherwise permitted under law.
      </p>
      <p>
        21.6 You may not assign, transfer or delegate this Agreement and your rights and obligations
        hereunder without LandTrust's prior written consent. LandTrust may without restriction
        assign, transfer or delegate this Agreement and any rights and obligations hereunder, at its
        sole discretion, with 30 days prior notice. Your right to terminate this Agreement at any
        time remains unaffected.
      </p>
      <p>
        21.7 Unless specified otherwise, any notices or other communications to Users permitted or
        required under this Agreement, will be provided electronically and given by LandTrust via
        email, LandTrust Platform notification, or messaging service (including SMS and
        WeChat).&nbsp;
      </p>
      <p>
        21.8 It is LandTrust&rsquo;s policy to terminate membership privileges of any User who
        repeatedly infringes copyright upon prompt notification to LandTrust by the copyright owner
        or the copyright owner&rsquo;s legal agent. Without limiting the foregoing, if you believe
        that your work has been copied and posted on the LandTrust Platform in a way that
        constitutes copyright infringement, please provide our Copyright Agent with the following
        information: (1) an electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright interest; (2) a description of the copyrighted work
        that you claim has been infringed; (3) a description of the location on the LandTrust
        Platform of the material that you claim is infringing; (4) your address, telephone number
        and e-mail address; (5) a written statement by you that you have a good faith belief that
        the disputed use is not authorized by the copyright owner, its agent or the law; and (6) a
        statement by you, made under penalty of perjury, that the above information in your notice
        is accurate and that you are the copyright owner or authorized to act on the copyright
        owner&rsquo;s behalf. Contact information for LandTrust&rsquo;s Copyright Agent for notice
        of claims of copyright infringement is as follows: LandTrust, Inc., PO Box 1691, Bozeman, MT
        59771, Attn: Copyright Agent.
      </p>
      <p>
        21.9 If you have any questions about these Terms please email us at{' '}
        <a href="mailto: support@landtrust.com">support@landtrust.com</a>.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
