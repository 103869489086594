import React, { useEffect, useState } from 'react';
import { func, number, oneOf } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InlineTextButton } from '../Button/Button';
import credovaLogo from '../../assets/images/Credova/logo.png';

import css from './CredovaPlugin.css';
import CredovaModal from './CredovaModal';

const CREDOVA_MINIMUM = 50000;
const CREDOVA_MAXIMUM = 1000000;

function isBrowser() {
  return typeof window !== 'undefined';
}

const CredovaPlugin = ({ price, variant, onModalOpen }) => {
  // FIXME: We have an edge case for onModalOpen for the package page, to display the modal correctly
  // we control the modal from the outside
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isBrowser() && window.CRDV) {
      const { CRDV } = window;
      if (CRDV) {
        CRDV.plugin.config({
          environment:
            process.env.REACT_APP_ENV === 'production'
              ? CRDV.Environment.Production
              : CRDV.Environment.Sandbox,
          store: process.env.REACT_APP_CREDOVA_STORE,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isBrowser() && window.CRDV) {
      const { CRDV } = window;
      if (CRDV && CRDV.plugin) {
        CRDV.plugin.inject('credovaPremade');
      }
    }
  }, [price]);

  if (!price) {
    return null;
  }

  const credovaRange = price * 100 > CREDOVA_MINIMUM && price * 100 < CREDOVA_MAXIMUM;
  const isInitialized = isBrowser() && window?.CRDV;

  if (!isInitialized || !credovaRange) {
    return null;
  }

  let headerClass = css.header;
  let credovaWrapperClass = css.credovaWrapper;
  let credovaHeadingClass = css.credovaHeading;
  let credovaLogoClass = css.credovaLogo;
  let credovaTextClass = css.credovaText;
  let moreInfoClass = css.moreInfo;

  if (variant === 'small') {
    credovaWrapperClass = css.credovaWrapperSmall;
    credovaHeadingClass = css.credovaHeadingSmall;
    credovaLogoClass = css.credovaLogoSmall;
    credovaTextClass = css.credovaTextSmall;
    moreInfoClass = css.moreInfoSmall;
  } else if (variant === 'large') {
    headerClass = css.headerLarge;
    credovaWrapperClass = css.credovaWrapperLarge;
    credovaHeadingClass = css.credovaHeadingLarge;
    credovaLogoClass = css.credovaLogoLarge;
    credovaTextClass = css.credovaTextLarge;
    moreInfoClass = css.moreInfoLarge;
  }

  const handleModalOpen = () => {
    if (onModalOpen) {
      onModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const renderCredovaBox = () => (
    <div className={credovaWrapperClass}>
      {!onModalOpen && (
        <CredovaModal isOpen={isModalOpen} onCloseModal={() => setIsModalOpen(false)} />
      )}

      <h3 className={credovaHeadingClass}>
        <FormattedMessage id="CredovaPlugin.bookNow" />
        <img src={credovaLogo} alt="Credova Logo" className={credovaLogoClass} />
      </h3>
      <div className={css.credovaTextWrapper}>
        <p
          className={classNames(credovaTextClass, 'credovaPremade')}
          data-amount={price}
          data-type="text"
          data-message={
            variant === 'small'
              ? 'Financing as low as <b>%0/mo</b> for %2 months.'
              : 'As low as <b>%0/mo</b> for %2 months.'
          }
          data-hide-brand="true"
        />
        {variant !== 'small' && (
          <InlineTextButton type="button" className={moreInfoClass} onClick={handleModalOpen}>
            <FormattedMessage id="CredovaPlugin.learnMore" />
          </InlineTextButton>
        )}
      </div>
    </div>
  );

  switch (variant) {
    case 'small':
      return (
        <div className={css.smallRoot}>
          <div className={css.smallDivider} />
          {renderCredovaBox()}
        </div>
      );
    case 'medium':
      return renderCredovaBox();
    case 'large':
    default:
      return (
        <div className={css.root}>
          <h3 className={headerClass}>
            <FormattedMessage id="CredovaPlugin.header" />
          </h3>
          <div className={css.boxWrapper}>
            {renderCredovaBox()}
            <div className={css.credovaButtonWrapper}>
              <p
                type="button"
                className={classNames(css.credovaPremade, 'credovaPremade')}
                data-amount={price}
                data-type="popup"
                data-message="Get Prequalified"
                data-hide-brand="true"
              />
            </div>
          </div>
        </div>
      );
  }
};

CredovaPlugin.defaultProps = {
  variant: 'medium',
};

CredovaPlugin.propTypes = {
  price: number.isRequired,
  variant: oneOf(['small', 'medium', 'large']),
  onModalOpen: func.isRequired,
};

export default injectIntl(CredovaPlugin);
