import React from 'react';
import classnames from 'classnames';

import css from './Container.css';

const Container = ({ className, isFullWidth, children }) => {
  return (
    <div
      className={classnames(
        css.root,
        {
          [css.fullWidthRoot]: isFullWidth,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
