/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { object, func } from 'prop-types';

import css from './LineItems.css';

const LineItemPackageItem = props => {
  const { itemInfo, onClick } = props;
  return itemInfo?.map(item => (
    <div key={`${item?.lineItem?.code}`} className={css.lineItem}>
      <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
        {item.packageLabel}
      </span>
      <span className={css.itemValue}>{item.formattedPackageTotal}</span>
    </div>
  ));
};

LineItemPackageItem.propTypes = {
  itemInfo: object.isRequired,
  onClick: func.isRequired,
};

export default LineItemPackageItem;
