import React from 'react';
import { get } from 'lodash';
import { bool, func, object, string, node } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { EditListingPanel, EditListingPanelHeading, EditListingSection } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPropertyVisitForm } from '../../forms';

const getInitialValues = listing => {
  const propertyVisit = get(listing, 'attributes.publicData.propertyVisit', {
    propertyUpdate: null,
    visitDate: null,
    fieldRep: null,
  });

  const { propertyUpdate, visitDate, fieldRep } = propertyVisit;

  return {
    propertyUpdate,
    dates: visitDate ? { startDate: new Date(visitDate), endDate: new Date(visitDate) } : null,
    fieldRep,
  };
};

const getSubmissionValues = values => {
  const { propertyUpdate, dates, fieldRep } = values;

  return {
    publicData: {
      propertyVisit: {
        propertyUpdate,
        visitDate: dates?.startDate?.toISOString(),
        fieldRep,
      },
    },
  };
};

const EditListingPropertyVisitPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
  } = props;

  const classes = classNames(rootClassName, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = <FormattedMessage id="EditListingPropertyVisitPanel.propertyVisitTitle" />;
  return (
    <EditListingPanel className={classes}>
      <EditListingSection isFullWidth>
        <EditListingPanelHeading>{panelTitle}</EditListingPanelHeading>
      </EditListingSection>

      <EditListingPropertyVisitForm
        initialValues={getInitialValues(currentListing)}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          onSubmit(getSubmissionValues(values));
        }}
        onChange={onChange}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        backLink={backLink}
        isPublished={isPublished}
      />
    </EditListingPanel>
  );
};

EditListingPropertyVisitPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  backLink: null,
};

EditListingPropertyVisitPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default EditListingPropertyVisitPanel;
