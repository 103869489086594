import React from 'react';
import classNames from 'classnames';

import css from './EditListingPhotosForm.css';

const EditListingPhotosImageCategory = ({ onClick, icon, label }) => {
  const iconWithClass = React.cloneElement(icon, {
    className: classNames(css.imageCategoryIcon, icon.props.className),
  });

  return (
    <button type="button" className={css.imageCategory} onClick={onClick}>
      <div className={css.imageCategoryInner}>
        {iconWithClass}

        <p className={css.imageCategoryLabel}>{label}</p>
      </div>
    </button>
  );
};

export default EditListingPhotosImageCategory;
