import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'lottie-react';
import Mailbox from '../../assets/mail-inquiry.webp';
import animationData from '../../assets/lottie/success-animation.json';
import css from './InquirySuccessModal.css';

const InquirySuccessModal = ({ user }) => {
  const [showAnimation, setShowAnimation] = useState(false);

  setTimeout(() => {
    setShowAnimation(true);
  }, 1000);

  const firstInquiry =
    user?.attributes?.profile?.metadata?.allTimeStatsAsCustomer?.inquiriesMade === 1;
  const emailVerified = user?.attributes?.emailVerified;

  if (!user) {
    return null;
  }

  return (
    <div className={css.contentWrapper}>
      <div className={css.sectionStyles}>
        <div className={css.headingWrapper}>
          <img src={Mailbox} alt="Mail" className={css.imageStyles} />
          {showAnimation && (
            <div
              className={emailVerified ? css.verifiedLottieAnimation : css.normalLottieAnimation}
            >
              <Lottie animationData={animationData} loop={false} autoPlay />
            </div>
          )}
          {firstInquiry ? (
            <div className={css.heading}>
              <FormattedMessage id="InquirySuccessModal.firstInquiryHeading" />
            </div>
          ) : (
            <div className={css.heading}>
              <FormattedMessage id="InquirySuccessModal.heading" />
            </div>
          )}
          <div className={css.subHeading}>
            <FormattedMessage id="InquirySuccessModal.subHeading" />
          </div>
        </div>
        {!emailVerified && (
          <div className={css.dividerWrapper}>
            <hr className={css.divider} />
          </div>
        )}
        {!emailVerified && (
          <>
            <div className={css.tipSectionWrapper}>
              <div className={css.tipsHeading}>
                <FormattedMessage id="InquirySuccessModal.tipsHeading" />
              </div>
              <div className={css.tipsWrapper}>
                <div className={css.tipsTextOne}>
                  <FormattedMessage id="InquirySuccessModal.tipOne" />
                </div>
                <div className={css.tipsTextTwo}>
                  <FormattedMessage id="InquirySuccessModal.tipTwo" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InquirySuccessModal;
