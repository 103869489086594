import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconCheckmark } from '..';
import SquareSpeciesImage from '../SectionBySpecies/SquareSpeciesImage';
import css from './FieldSpeciesCheckbox.css';

const FieldSpeciesCheckbox = ({
  rootClassName,
  className,
  id,
  label,
  speciesKey,
  ...checkboxProps
}) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={classes}>
      <Field id={id} className={css.input} component="input" type="checkbox" {...checkboxProps} />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id} className={css.label}>
        <SquareSpeciesImage className={css.image} imageKey={speciesKey} />

        <IconCheckmark className={css.checkmark} size="big" />

        <div className={css.text}>{label}</div>
      </label>
    </span>
  );
};

FieldSpeciesCheckbox.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
};

FieldSpeciesCheckbox.propTypes = {
  className: string,
  rootClassName: string,
  speciesKey: string.isRequired,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldSpeciesCheckbox;
