import React from 'react';
import { string, oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { NamedLink } from '..';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';
import SpeciesCard from './SpeciesCard';
import SquareSpeciesImage from './SquareSpeciesImage';
import whitetailImage from '../../assets/images/species/whitetail.png';
import turkeyImage from '../../assets/images/species/turkey.png';
import muleDeerImage from '../../assets/images/species/mule-deer.png';
import elkImage from '../../assets/images/species/elk.png';
import uplandImage from '../../assets/images/species/upland.png';
import waterfowlImage from '../../assets/images/species/waterfowl.png';
import hogImage from '../../assets/images/species/hog.png';
import whitetailImageWebP from '../../assets/images/species/whitetail.webp';
import turkeyImageWebP from '../../assets/images/species/turkey.webp';
import muleDeerImageWebP from '../../assets/images/species/mule-deer.webp';
import elkImageWebP from '../../assets/images/species/elk.webp';
import uplandImageWebP from '../../assets/images/species/upland.webp';
import waterfowlImageWebP from '../../assets/images/species/waterfowl.webp';
import hogImageWebP from '../../assets/images/species/hog.webp';
import { ReactComponent as WhitetailSVG } from '../../assets/images/species/whitetail.svg';
import { ReactComponent as TurkeySVG } from '../../assets/images/species/turkey.svg';
import { ReactComponent as MuleDeerSVG } from '../../assets/images/species/mule-deer.svg';
import { ReactComponent as ElkSVG } from '../../assets/images/species/elk.svg';
import { ReactComponent as UplandSVG } from '../../assets/images/species/upland.svg';
import { ReactComponent as WaterfowlSVG } from '../../assets/images/species/waterfowl.svg';
import { ReactComponent as HogSVG } from '../../assets/images/species/hog.svg';
import css from './SectionBySpecies.css';
import { speciesSearchGroups } from '../../marketplace-custom-config';

const imageMap = {
  whitetail: {
    png: whitetailImage,
    webp: whitetailImageWebP,
  },
  turkey: {
    png: turkeyImage,
    webp: turkeyImageWebP,
  },
  'mule-deer': {
    png: muleDeerImage,
    webp: muleDeerImageWebP,
  },
  elk: {
    png: elkImage,
    webp: elkImageWebP,
  },
  upland: {
    png: uplandImage,
    webp: uplandImageWebP,
  },
  pheasant: {
    png: uplandImage,
    webp: uplandImageWebP,
  },
  waterfowl: {
    png: waterfowlImage,
    webp: waterfowlImageWebP,
  },
  hog: {
    png: hogImage,
    webp: hogImageWebP,
  },
};

const svgMap = {
  whitetail: WhitetailSVG,
  turkey: TurkeySVG,
  'mule-deer': MuleDeerSVG,
  elk: ElkSVG,
  upland: UplandSVG,
  pheasant: UplandSVG,
  waterfowl: WaterfowlSVG,
  hog: HogSVG,
};

const getSearchQuery = group => {
  return `?address=&bounds=69.96271704%2C-56.89389338%2C-18.35556663%2C-131.22017221&pub_gameTypeKeys=has_any:${speciesSearchGroups.hunt[
    group
  ].searchSpecies.join()}`;
};

export const SectionBySpeciesVariants = {
  CAROUSEL: 'carousel',
  BOXES: 'boxes',
};

export const SectionBySpeciesCarousel = () => {
  return (
    <div className={css.root} id="SectionBySpecies" data-testid="hunts-by-species-section">
      <h1 className={css.title}>
        <FormattedMessage id="SectionBySpecies.title" />
      </h1>

      <ScrollingCarousel className={css.carousel}>
        {Object.keys(speciesSearchGroups.hunt).map(g => (
          <Step className={css.step} key={g}>
            <SpeciesCard
              src={imageMap[g]}
              bg={svgMap[g]}
              label={speciesSearchGroups.hunt[g].label}
              title={speciesSearchGroups.hunt[g].title}
              routeName="SearchPage"
              routeTo={{
                search: getSearchQuery(g),
                gameFilter: g,
              }}
            />
          </Step>
        ))}
      </ScrollingCarousel>
    </div>
  );
};

export const SectionBySpeciesBoxes = ({ rootClassName, className }) => {
  return (
    <div className={classNames(rootClassName || css.boxesRoot, className)}>
      <div className={css.boxesInner}>
        {Object.keys(speciesSearchGroups.hunt).map(g => (
          <NamedLink
            key={g}
            className={css.box}
            name="SearchPage"
            to={{
              search: getSearchQuery(g),
              gameFilter: g,
            }}
          >
            <div className={css.boxInner}>
              <SquareSpeciesImage
                imageKey={g}
                alt={speciesSearchGroups.hunt[g].label}
                className={css.boxImage}
              />
              <div className={css.boxTitle}>{speciesSearchGroups.hunt[g].label}</div>
            </div>
          </NamedLink>
        ))}
      </div>
    </div>
  );
};

SectionBySpeciesBoxes.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionBySpeciesBoxes.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionBySpecies = ({ variant, ...otherProps }) => {
  return variant === SectionBySpeciesVariants.BOXES ? (
    <SectionBySpeciesBoxes {...otherProps} />
  ) : (
    <SectionBySpeciesCarousel {...otherProps} />
  );
};

SectionBySpecies.defaultProps = {
  variant: SectionBySpeciesVariants.CAROUSEL,
};

SectionBySpecies.propTypes = {
  variant: oneOf(Object.values(SectionBySpeciesVariants)),
};

SectionBySpecies.variants = SectionBySpeciesVariants;

export default SectionBySpecies;
