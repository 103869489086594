import React from 'react';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { propTypes, LINE_ITEM_CONSERVATION_DONATION } from '../../util/types';
import { speciesFoundations, FARM_RANCH_ACTIVITY } from '../../marketplace-custom-config';

import css from './SectionConservationDonation.css';

const SectionConservationDonation = props => {
  const { transaction } = props;
  const protectedData = get(transaction, 'attributes.protectedData');
  const packageLineItem = get(protectedData, 'packageLineItem', null);
  const conservationDonationSpecies = get(protectedData, 'conservationDonationSpecies', null);

  const lineItems = get(transaction, 'attributes.lineItems', {});
  const isConservationProgram = lineItems.some(
    item => item.code === LINE_ITEM_CONSERVATION_DONATION
  );
  const hasEstimatedDonation = lineItems.find(item => item.donation);
  const activityItem = lineItems.find(item => item.activity) || packageLineItem;
  // TODO: Use for new farm/ranch partner donation image
  const isFarmRanch = activityItem && activityItem.activity === FARM_RANCH_ACTIVITY;

  if (isConservationProgram && (conservationDonationSpecies || packageLineItem)) {
    const foundationDetails = isFarmRanch
      ? speciesFoundations.farmers
      : conservationDonationSpecies &&
        speciesFoundations[protectedData.conservationDonationSpecies.replace(' ', '-')];

    if ((foundationDetails || packageLineItem) && !isFarmRanch) {
      return (
        <div className={css.conservationContainer}>
          {packageLineItem ? (
            <div className={css.conservationIconPackageWrapper}>
              <div className={css.conservationIconPackageFirst}>
                <img
                  className={css.conservationIconImage}
                  src={speciesFoundations['mule-deer'].logo}
                  alt={speciesFoundations['mule-deer'].name}
                />
              </div>

              <div className={css.conservationIconPackageSecond}>
                <img
                  className={css.conservationIconImage}
                  src={speciesFoundations.turkey.logo}
                  alt={speciesFoundations.turkey.name}
                />
              </div>

              <div className={css.conservationIconPackageThird}>
                <img
                  className={css.conservationIconImage}
                  src={speciesFoundations.duck.logo}
                  alt={speciesFoundations.duck.name}
                />
              </div>

              <div className={css.conservationIconPackageFourth}>
                <img
                  className={css.conservationIconImage}
                  src={speciesFoundations.elk.logo}
                  alt={speciesFoundations.elk.name}
                />
              </div>
            </div>
          ) : (
            <div className={css.conservationIconContainer}>
              <img
                className={css.conservationIconImage}
                src={foundationDetails.logo}
                alt={foundationDetails.name}
              />
            </div>
          )}
        </div>
      );
    }
    if ((foundationDetails || packageLineItem) && isFarmRanch) {
      return (
        <div className={css.conservationContainer}>
          <div className={css.conservationFarmersIconContainer}>
            <img
              className={css.conservationFarmersIconImage}
              src={foundationDetails.logo}
              alt={foundationDetails.name}
            />
          </div>
        </div>
      );
    }
  }

  if (hasEstimatedDonation && !isFarmRanch) {
    const { donation } = hasEstimatedDonation;
    return (
      <div className={css.conservationContainer}>
        {donation.package ? (
          <div className={css.conservationIconPackageWrapper}>
            <div className={css.conservationIconPackageFirst}>
              <img
                className={css.conservationIconImage}
                src={speciesFoundations['mule-deer'].logo}
                alt={speciesFoundations['mule-deer'].name}
              />
            </div>

            <div className={css.conservationIconPackageSecond}>
              <img
                className={css.conservationIconImage}
                src={speciesFoundations.turkey.logo}
                alt={speciesFoundations.turkey.name}
              />
            </div>

            <div className={css.conservationIconPackageThird}>
              <img
                className={css.conservationIconImage}
                src={speciesFoundations.duck.logo}
                alt={speciesFoundations.duck.name}
              />
            </div>

            <div className={css.conservationIconPackageFourth}>
              <img
                className={css.conservationIconImage}
                src={speciesFoundations.elk.logo}
                alt={speciesFoundations.elk.name}
              />
            </div>
          </div>
        ) : (
          <div className={css.conservationIconContainer}>
            <img className={css.conservationIconImage} src={donation.logo} alt={donation.name} />
          </div>
        )}
      </div>
    );
  }

  if (hasEstimatedDonation && isFarmRanch) {
    return (
      <div className={css.conservationContainer}>
        <div className={css.conservationFarmersIconContainer}>
          <img
            className={css.conservationFarmersIconImage}
            src={speciesFoundations.farmers.logo}
            alt={speciesFoundations.farmers.name}
          />
        </div>
      </div>
    );
  }

  return null;
};

SectionConservationDonation.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default injectIntl(SectionConservationDonation);
