/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';
import { useDropzone } from 'react-dropzone';
import {
  PHOTO_LIBRARY_TABS,
  getPhotos,
  flatTags,
  syncPhotoLibraryForSubmission,
} from '../../util/photoLibrary';
import { PROPERTY, ARRIVAL } from '../../util/editListing';
import {
  ResponsiveImage,
  ImageFromFile,
  PrimaryButton,
  Tabs,
  Tab,
  Tags,
  EditListingUploadProgressBar,
} from '..';
import Tooltip from '../Tooltip/Tooltip';
import { InlineTextButton } from '../Button/Button';
import ImageCategory from '../../forms/EditListingPhotoLibraryForm/EditListingPhotoLibraryImageCategory';
import {
  initialUploadState,
  uploadActions,
  uploadReducer,
} from '../../forms/EditListingPhotoLibraryForm/reducer';
import { ReactComponent as IconCheck } from '../../assets/icons/simple-check.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle-dark.svg';
import { ReactComponent as TagIcon } from '../../assets/icons/tag-light.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-regular.svg';
import config from '../../config';

import css from './PhotoLibrary.css';

const ACCEPT_IMAGES = ['.jpg', '.jpeg', '.png'];

const PhotoLibrary = ({
  intl,
  listing,
  packageName,
  imagesIds,
  onAddImagesIds,
  requestImageUpload,
  onUpdateListing,
}) => {
  const [selectedImagesIds, setSelectedImagesIds] = useState(imagesIds);
  const [activeTab, setActiveTab] = useState(PHOTO_LIBRARY_TABS.ALL);
  const publicData = get(listing, 'attributes.publicData', {});
  const photoLibraryTags = get(publicData, 'photoLibraryTags', {});
  const imageCaptions = get(publicData, 'imageCaptions', {});
  const listingImages = get(listing, 'images', []);
  const [uploadState, uploadDispatch] = useReducer(uploadReducer, initialUploadState);
  const imagesRef = useRef([...listingImages]);
  const tagsRef = useRef(photoLibraryTags);
  const [tags, setTags] = useState(tagsRef.current);

  useEffect(() => {
    setSelectedImagesIds(imagesIds);
  }, [imagesIds]);

  const updateSelectedImages = imageId => {
    let updatedPhotos = [];

    if (selectedImagesIds.includes(imageId)) {
      updatedPhotos = selectedImagesIds.filter(photoId => photoId !== imageId);
    } else {
      updatedPhotos = [...selectedImagesIds];
      updatedPhotos.push(imageId);
    }

    setSelectedImagesIds(updatedPhotos);
  };

  const handlePhotoClick = imageId => {
    updateSelectedImages(imageId);
  };

  const startUpload = useCallback(
    (tmpId, file, currentTab) => {
      const handleUploadProgress = progressEvent => {
        const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;

        uploadDispatch({
          type: uploadActions.PROGRESS,
          payload: { tmpId, progress: percentCompleted },
        });
      };

      uploadDispatch({ type: uploadActions.STARTED, payload: { tmpId, file } });

      requestImageUpload(file, handleUploadProgress)
        .then(apiId => {
          imagesRef.current = imagesRef.current.map(currentImage => {
            if (currentImage.tmpId && currentImage.tmpId === tmpId) {
              return {
                ...currentImage,
                file,
                id: apiId,
              };
            }

            return currentImage;
          });

          let initialTag;

          switch (currentTab) {
            case PHOTO_LIBRARY_TABS.PROPERTY:
              initialTag = PROPERTY;
              break;

            case PHOTO_LIBRARY_TABS.HUNT:
              initialTag = config.custom.HUNT_ACTIVITY;
              break;

            case PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION:
              initialTag = config.custom.OUTDOOR_RECREATION_ACTIVITY;
              break;

            case PHOTO_LIBRARY_TABS.ARRIVAL:
              initialTag = ARRIVAL;
              break;

            default:
          }

          if (initialTag) {
            tagsRef.current = {
              ...tagsRef.current,
              [apiId.uuid]: [initialTag],
            };

            setTags(tagsRef.current);
          }

          setSelectedImagesIds(prev => [...prev, apiId.uuid]);

          uploadDispatch({
            type: uploadActions.SUCCESS,
            payload: { tmpId, apiId },
          });
        })
        .catch(e => {
          let message = 'EditListingPhotosForm.imageUploadFailed.uploadFailed';

          if (e?.data?.errors[0]?.code === 'request-upload-over-limit') {
            message = 'EditListingPhotosForm.imageUploadFailed.uploadOverLimit';
          }

          uploadDispatch({
            type: uploadActions.ERROR,
            payload: {
              tmpId,
              error: intl.formatMessage({
                id: message,
              }),
            },
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestImageUpload]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      const newImages = [];
      const files = {};
      acceptedFiles.forEach(file => {
        const tmpId = `${btoa(file.name)}_${Date.now()}`;
        // id is null until the upload is finished
        newImages.push({ tmpId, id: null });
        files[tmpId] = file;
      });

      imagesRef.current = [...newImages, ...imagesRef.current];

      newImages.forEach(({ tmpId }) => {
        startUpload(tmpId, files[tmpId], activeTab);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPT_IMAGES,
  });

  const isLimitValid = imagesRef.current.length < 100;

  const filteredImages = imagesRef.current.filter(image => {
    return !!image?.attributes?.variants || !!image?.file;
  });

  const currentImages = getPhotos(filteredImages, tagsRef.current, activeTab);

  const handleSubmit = async () => {
    try {
      const {
        updatedImages,
        updatePackages,
        updatedArrivalInstructions,
        updatedImageCaptions,
      } = syncPhotoLibraryForSubmission(listing, imagesRef.current, imageCaptions);

      const data = {
        id: listing.id,
        images: updatedImages.map(img => img.id),
        publicData: {
          imageCaptions: updatedImageCaptions,
          photoLibraryTags: tags,
          packages: updatePackages,
          arrivalInstructions: updatedArrivalInstructions,
        },
      };

      await onUpdateListing(data);

      onAddImagesIds(selectedImagesIds);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const onUpdateTags = (id, tag) => {
    const newTags = JSON.parse(JSON.stringify(tagsRef.current));
    let imageTags = newTags[id] || [];

    if (imageTags.includes(tag)) {
      imageTags = imageTags.filter(imageTag => imageTag !== tag);

      if (tag === config.custom.HUNT_ACTIVITY) {
        imageTags = imageTags.filter(imageTag => {
          return !Object.keys(config.custom.species.hunt).includes(imageTag);
        });
      } else if (tag === config.custom.OUTDOOR_RECREATION_ACTIVITY) {
        imageTags = imageTags.filter(imageTag => {
          return !config.custom.activitiesTypeMap.some(activity => activity.key === imageTag);
        });
      }
    } else {
      imageTags.push(tag);

      // Preselect hunting tag if given tag is one of the species
      if (
        Object.keys(config.custom.species.hunt).some(speciesKey => {
          return speciesKey === tag;
        }) &&
        !imageTags.includes(config.custom.HUNT_ACTIVITY)
      ) {
        imageTags.push(config.custom.HUNT_ACTIVITY);
      }
    }

    const updatedTags = {
      ...newTags,
      [id]: imageTags,
    };

    if (!imageTags.length) {
      delete updatedTags[id];
    }

    setTags(updatedTags);

    tagsRef.current = updatedTags;
  };

  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.header}>
          <FormattedMessage
            id="PhotoLibrary.header"
            values={{
              packageName,
            }}
          />
        </div>

        <div className={css.tabsContainer}>
          <Tabs>
            <Tab
              handleClick={() => setActiveTab(PHOTO_LIBRARY_TABS.ALL)}
              isSelected={activeTab === PHOTO_LIBRARY_TABS.ALL}
            >
              <div className={css.tabContainer}>
                <FormattedMessage id="EditListingPhotoLibraryPanel.tabAll" />
                <span className={css.tabCount}>({listingImages?.length}/100)</span>
              </div>
            </Tab>

            <Tab
              handleClick={() => setActiveTab(PHOTO_LIBRARY_TABS.PROPERTY)}
              isSelected={activeTab === PHOTO_LIBRARY_TABS.PROPERTY}
            >
              <div className={css.tabContainer}>
                <FormattedMessage id="EditListingPhotoLibraryPanel.tabProperty" />
              </div>
            </Tab>

            <Tab
              handleClick={() => setActiveTab(PHOTO_LIBRARY_TABS.HUNT)}
              isSelected={activeTab === PHOTO_LIBRARY_TABS.HUNT}
            >
              <div className={css.tabContainer}>
                <FormattedMessage id="EditListingPhotoLibraryPanel.tabHunting" />
              </div>
            </Tab>

            <Tab
              handleClick={() => setActiveTab(PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION)}
              isSelected={activeTab === PHOTO_LIBRARY_TABS.OUTDOOR_RECREATION}
            >
              <div className={css.tabContainer}>
                <FormattedMessage id="EditListingPhotoLibraryPanel.tabOutdoorRecreation" />
              </div>
            </Tab>

            <Tab
              handleClick={() => setActiveTab(PHOTO_LIBRARY_TABS.ARRIVAL)}
              isSelected={activeTab === PHOTO_LIBRARY_TABS.ARRIVAL}
            >
              <div className={css.tabContainer}>
                <FormattedMessage id="EditListingPhotoLibraryPanel.tabArrival" />
              </div>
            </Tab>
          </Tabs>
        </div>

        <div
          {...getRootProps()}
          className={classNames(css.dropzoneContainer, {
            [css.dropzoneContainerActive]: isDragActive,
            [css.dropzoneContainerInactive]: !isLimitValid,
          })}
        >
          {isLimitValid && <input {...getInputProps()} />}

          <div className={css.imageCategories}>
            <ImageCategory
              isDisabled={!isLimitValid}
              icon={<PlusIcon />}
              label={<FormattedMessage id="EditListingPhotosForm.imageUploadCategory.addLabel" />}
            />
          </div>

          {isDragActive && (
            <div className={css.dropzoneDropNotification}>
              <p className={css.dropzoneDropNotificationLabel}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadDropNotification" />
              </p>
            </div>
          )}
        </div>

        <EditListingUploadProgressBar
          className={css.progressBar}
          progress={uploadState.progress}
          files={uploadState.files}
        />

        <div className={css.imagesContainer}>
          {currentImages.map((image, index) => (
            <div className={css.imageContainer} key={`image-${index}`}>
              <div
                className={css.imageWrapper}
                onClick={() => {
                  handlePhotoClick(image.id.uuid);
                }}
              >
                <div className={css.imageBox}>
                  <span className={css.imageSpan}>
                    {image?.file ? (
                      <ImageFromFile
                        id={image.tmpId}
                        rootClassName={css.image}
                        file={image.file}
                        justImage
                        key={image.tmpId}
                      />
                    ) : (
                      <ResponsiveImage
                        rootClassName={css.image}
                        image={image}
                        variants={[
                          'scaled-small',
                          'scaled-medium',
                          'scaled-large',
                          'scaled-xlarge',
                        ]}
                      />
                    )}
                  </span>
                </div>

                <div
                  className={classNames(css.checkIconWrapper, {
                    [css.checkIconWrapperSelected]: selectedImagesIds.includes(image?.id?.uuid),
                  })}
                >
                  {selectedImagesIds.includes(image?.id?.uuid) && (
                    <IconCheck className={css.checkIcon} />
                  )}
                </div>
              </div>

              <div className={css.photoPreviewTagsContainer}>
                <div className={css.photoPreviewTagsLinkContainer}>
                  <TagIcon className={css.photoPreviewTagsIcon} />

                  <Tooltip
                    hideArrow
                    hideCloseButton
                    rootClassName={css.photoPreviewTagsTooltip}
                    trigger="click"
                    tooltip={
                      <Tags
                        tags={tags[(image?.id?.uuid)] || []}
                        onUpdateTags={tag => onUpdateTags(image.id.uuid, tag)}
                      />
                    }
                    modifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: () => {
                            return [-15, 10];
                          },
                        },
                      },
                    ]}
                  >
                    <InlineTextButton className={css.photoPreviewTagsLink}>
                      <FormattedMessage id="EditListingPhotoLibraryPreviews.tag" />
                    </InlineTextButton>
                  </Tooltip>
                </div>

                {(tags[(image?.id?.uuid)] || []).map((tag, tagIndex) => (
                  <div className={css.photoPreviewTagsItem} key={`${image.id.uuid}-${tagIndex}`}>
                    <span>{flatTags[tag]}</span>

                    <button
                      type="button"
                      className={css.photoPreviewTagsItemClose}
                      onClick={() => onUpdateTags(image.id.uuid, tag)}
                    >
                      <CloseIcon className={css.photoPreviewTagsCloseIcon} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={css.saveBarContainer}>
        <div className={css.saveBar}>
          <PrimaryButton type="button" onClick={handleSubmit}>
            <FormattedMessage id="PhotoLibrary.saveButton" />
          </PrimaryButton>

          <div className={css.saveBarText}>
            <FormattedMessage
              id="PhotoLibrary.selectedPhotos"
              values={{ count: selectedImagesIds.length }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoLibrary;
