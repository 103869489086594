/* eslint-disable react/no-danger */
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import format from 'date-fns/format';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { formatMoney } from '../../../util/currency';
import { required } from '../../../util/validators';

import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';
import { FieldCheckbox, FieldDates, FieldPartySizeSelect } from '../..';
import { ReactComponent as VerificationIcon } from '../../../assets/icons/verification.svg';
import { daysAdded } from '../../../util/dates';
import { LODGING_INFO } from '../../../util/package';

import css from './InquiryWizardStepParty.css';

const InquiryWizardStepParty = ({ intl, listing, timeSlots, formValues, onChange }) => {
  const { selectedPackage } = formValues || {};

  const { guests, price, hasLodging } = selectedPackage || {};
  const { max: maxParty, min: minParty = 1 } = { ...guests };
  const maxDiyPartySize = get(listing, 'attributes.publicData.policies.sportsmen', 1);

  const startDate = get(formValues, 'bookingDates.startDate');
  const endDate = get(formValues, 'bookingDates.endDate');
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  let bookingDateText = <FormattedMessage id="CheckoutPage.datesFieldPlaceholder" />;
  const dateValues = formValues && formValues.bookingDates;

  const isSingleDay =
    daysAdded(
      dateValues && formValues.bookingDates.startDate,
      dateValues && formValues.bookingDates.endDate
    ) === 1;

  if (dateValues) {
    bookingDateText = `${format(formValues.bookingDates.startDate, 'MMM DD')} - ${format(
      formValues.bookingDates.endDate,
      'MMM DD, YYYY'
    )}`;
  }

  if (dateValues && isSingleDay) {
    bookingDateText = format(
      formValues && formValues.bookingDates && formValues.bookingDates.startDate,
      'MMM DD'
    );
  }

  const lodgingPrice = selectedPackage?.lodgingPrice;

  let calendarInfoDays;
  const { days } = selectedPackage || {};

  if (days.min === days.max) {
    calendarInfoDays = days.min;
  } else if (days.max === 365) {
    calendarInfoDays = `${days.min}+`;
  } else {
    calendarInfoDays = `${days.min}-${days.max}`;
  }

  return (
    <InquiryWizardPanel className={css.panelContainer}>
      <InquiryWizardSection>
        <div className={css.fieldContainer}>
          <FieldPartySizeSelect
            id="party-size-select"
            label="Choose your party size"
            className={css.partySizeSelect}
            name="partySize"
            minPartySize={minParty || 1}
            maxPartySize={maxParty || maxDiyPartySize}
            price={price}
            parse={value => (value === '' ? null : Number(value))}
            format={value => String(value)}
            validate={required(
              intl.formatMessage({
                id: 'InquiryWizard.partySizeRequired',
              })
            )}
          />
        </div>

        <div>
          <div className={css.formFieldWrapper}>
            <FieldDates
              fieldDateProps={{
                endDateOffset:
                  selectedPackage.days.min === selectedPackage.days.max
                    ? day => day.add(selectedPackage.days.min - 1, 'days')
                    : undefined,
                minimumNights:
                  selectedPackage.days.min !== selectedPackage.days.max &&
                  selectedPackage.days.min - 1,
                maximumNights:
                  selectedPackage.days.max > 0 &&
                  selectedPackage.days.min !== selectedPackage.days.max
                    ? selectedPackage.days.max - 1
                    : undefined,
                calendarInfo: (
                  <div className={css.dateInfo}>
                    <div className={css.dateInfoTitle}>
                      <FormattedMessage
                        id="PricingPage.datesFieldInfoPackageTitle"
                        values={{ days: calendarInfoDays }}
                      />
                    </div>
                  </div>
                ),
              }}
              timeSlots={timeSlots}
              label={<FormattedMessage id="InquiryWizard.datesFieldHeadingPackage" />}
              text={bookingDateText}
              onChange={values => {
                onChange(values);
              }}
              isPackage
            />
          </div>
        </div>

        {startDate && endDate && (
          <div className={css.availableDates}>
            <VerificationIcon className={css.verificationIcon} />
            <FormattedMessage
              id="InquiryWizard.selectedDate"
              values={{ dates: formattedStartDate === formattedEndDate ? 1 : 0 }}
            />
          </div>
        )}
        {hasLodging === LODGING_INFO.OPTIONAL && (selectedPackage.days.min > 1 || !isSingleDay) && (
          <div className={css.formFieldWrapper}>
            <div className={css.packageLodgingAddOn}>
              <FormattedMessage id="InquiryWizard.addOnsTitle" />
              <FieldCheckbox
                labelClassName={css.lodging}
                id="lodgingAddOn"
                name="lodgingAddOn"
                value
                label={
                  <FormattedMessage
                    id="InquiryWizard.lodgingLabel"
                    values={{ price: formatMoney(intl, lodgingPrice) }}
                  />
                }
              />
            </div>
          </div>
        )}
      </InquiryWizardSection>
    </InquiryWizardPanel>
  );
};

InquiryWizardStepParty.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(InquiryWizardStepParty);
