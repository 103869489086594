import React from 'react';
import { node, func } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import { OnChange } from 'react-final-form-listeners';

import { FieldRadioButton, FieldLabel } from '..';

import css from './FieldToggle.css';

const FieldToggle = ({ wrapperClassName, intl, name, label, onChange, ...rest }) => {
  const wrapperClasses = classNames(css.radioWrapper, wrapperClassName);

  return (
    <div className={css.root}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <div className={wrapperClasses}>
        <OnChange name={name}>
          {(value, prevValue) => {
            onChange(value, prevValue);
          }}
        </OnChange>
        <FieldRadioButton
          name={name}
          id={`${name}Yes`}
          value="true"
          label={intl.formatMessage({ id: 'FieldToggle.toggleYes' })}
          parse={() => true}
          format={value => String(value)}
          {...rest}
        />
        <FieldRadioButton
          name={name}
          id={`${name}No`}
          value="false"
          label={intl.formatMessage({ id: 'FieldToggle.toggleNo' })}
          parse={() => false}
          format={value => String(value)}
          {...rest}
        />
      </div>
    </div>
  );
};

FieldToggle.propTypes = {
  intl: intlShape.isRequired,
  label: node,
  onChange: func,
};

FieldToggle.defaultProps = {
  label: null,
  onChange: () => {},
};

export default compose(injectIntl)(FieldToggle);
