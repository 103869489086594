/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import { BookingDateRangeFilter, SelectMultipleFilter, ModalHeadings } from '..';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import {
  findCategoryKeys,
  findGameTypeGroupKeysBySearch,
  generateSearchFromGameTypeGroupKeys,
} from '../../util/search';
import css from './SearchFilters.css';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams[paramName];
  const rawValuesFromParams = dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  return !!dates && valuesFromParams.length === 2
    ? {
        dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
      }
    : { dates: null };
};

const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    resultsCount,
    searchInProgress,
    dateRangeFilter,
    gameTypeFilter,
    categoryFilter,
    onManageDisableScrolling,
    history,
    intl,
  } = props;

  const [savedUrlQueryParams, setSavedUrlQueryParams] = useState(null);
  const classes = classNames(rootClassName || css.root, className);

  const initialDateRange = dateRangeFilter
    ? initialDateRangeValue(urlQueryParams, dateRangeFilter.paramName)
    : null;

  const initialGameTypes = gameTypeFilter
    ? initialValue(urlQueryParams, gameTypeFilter.paramName)
    : null;

  const initialCategory = categoryFilter
    ? initialValue(urlQueryParams, categoryFilter.paramName)
    : null;

  const initialGameTypeGroupKeys = findGameTypeGroupKeysBySearch(initialGameTypes);
  const initialCategoryKeys = findCategoryKeys(initialCategory);

  const handleGameType = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? {
            ...urlQueryParams,
            [urlParam]: generateSearchFromGameTypeGroupKeys(options),
            [categoryFilter.paramName]: [
              // Using a Set here to ensure uniqueness in the array
              ...new Set([...(urlQueryParams[categoryFilter.paramName]?.split(',') || []), 'hunt']),
            ].join(','),
          }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleCategoryType = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const handleDateRange = (urlParam, dateRange) => {
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? { ...urlQueryParams, [urlParam]: `${start},${end}` }
        : omit(urlQueryParams, urlParam);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  const resetToSavedFilters = () => {
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, savedUrlQueryParams)
    );

    setSavedUrlQueryParams(null);
  };

  const saveFilters = () => {
    setSavedUrlQueryParams(urlQueryParams);
  };

  // If you are looking for how to enable other filters you will need to go back in time because they have been removed!
  // See https://github.com/landtrustco/landtrust/blob/v0.21/src/components/SearchFilters/SearchFilters.js for a time
  // before they were deleted.
  //
  // These old filters will need to be updated to support being shown in a modal on mobile. See <BookingDateRangeFilter />
  // and <SelectMultipleFilter /> for examples of how this is done.

  const dateRangeFilterElement =
    dateRangeFilter && dateRangeFilter.config.active ? (
      <BookingDateRangeFilter
        id="SearchFilters.dateRangeFilter"
        urlParam={dateRangeFilter.paramName}
        onSubmit={handleDateRange}
        showAsPopup
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialValues={initialDateRange}
        onManageDisableScrolling={onManageDisableScrolling}
        saveFilters={saveFilters}
        resetToSavedFilters={resetToSavedFilters}
        resultsCount={resultsCount}
        searchInProgress={searchInProgress}
      />
    ) : null;

  const gameTypesFilterTitles = (
    <ModalHeadings
      heading={intl.formatMessage({ id: 'SearchFilters.gameTypeModalHeading' })}
      subHeading={intl.formatMessage({ id: 'SearchFilters.gameTypeModalSubHeading' })}
    />
  );

  const categoriesFilterTitles = (
    <ModalHeadings
      heading={intl.formatMessage({ id: 'SearchFilters.categoriesModalHeading' })}
      subHeading={intl.formatMessage({ id: 'SearchFilters.categoriesModalSubHeading' })}
    />
  );
  const gameTypesFilterElement =
    gameTypeFilter && gameTypeFilter.config.active ? (
      <SelectMultipleFilter
        id="SearchFilters.gameTypeFilter"
        label={intl.formatMessage({
          id: 'SearchFilters.gameTypeLabel',
        })}
        name="gameType"
        titles={gameTypesFilterTitles}
        options={gameTypeFilter.config.options}
        urlParam={gameTypeFilter.paramName}
        onSubmit={handleGameType}
        showAsPopup
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialValues={initialGameTypeGroupKeys}
        onManageDisableScrolling={onManageDisableScrolling}
        saveFilters={saveFilters}
        resetToSavedFilters={resetToSavedFilters}
        resultsCount={resultsCount}
        searchInProgress={searchInProgress}
      />
    ) : null;

  const categoryFilterElement = categoryFilter && categoryFilter.config.active && (
    <SelectMultipleFilter
      id="SearchFilters.categoryFilter"
      label={intl.formatMessage({
        id: 'SearchFilters.categoryLabel',
      })}
      name="categoryType"
      titles={categoriesFilterTitles}
      options={categoryFilter.config.options}
      urlParam={categoryFilter.paramName}
      onSubmit={handleCategoryType}
      showAsPopup
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      initialValues={initialCategoryKeys}
      onManageDisableScrolling={onManageDisableScrolling}
      saveFilters={saveFilters}
      resetToSavedFilters={resetToSavedFilters}
      resultsCount={resultsCount}
      searchInProgress={searchInProgress}
    />
  );

  return (
    <div className={classes}>
      <div className={css.filtersWrapper}>
        <div className={css.filtersInner}>
          {gameTypesFilterElement && <div className={css.filter}>{gameTypesFilterElement}</div>}
          {dateRangeFilterElement && <div className={css.filter}>{dateRangeFilterElement}</div>}
          {categoryFilterElement && <div className={css.filter}>{categoryFilterElement}</div>}
        </div>
      </div>
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  dateRangeFilter: null,
  gameTypeFilter: null,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  dateRangeFilter: shape({ paramName: string.isRequired, config: object }),
  gameTypeFilter: shape({ paramName: string.isRequired, config: object }),

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(
  withRouter,
  injectIntl
)(SearchFiltersComponent);

export default SearchFilters;
