/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, PrimaryButton, FormValidationTooltip, NamedRedirect } from '../../components';
import AccountDetails from './steps/AccountDetails';
import AccountPreferences from './steps/AccountPreferences';

import css from './SignupForm.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [allSteps, setAllSteps] = useState(1);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { affiliate } = useParams();

  let referralMethodFromURL = '';

  if (affiliate) {
    referralMethodFromURL = 'affiliate';
  } else if (typeof window !== 'undefined') {
    referralMethodFromURL = new URLSearchParams(window.location.search).get('referralMethod');
  } else {
    referralMethodFromURL = new URLSearchParams().get('referralMethod');
  }
  const {
    isAuthenticated,
    currentUserLoaded,
    showEmailVerification,
    isLandowner,
    config,
    from,
  } = props;

  const redirect = () => {
    if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
      if (isLandowner && !config.dev) {
        return <NamedRedirect name="LandownersPage" />;
      }

      return <NamedRedirect name="LandingPage" />;
    }
    return null;
  };

  const [allValues, setAllValues] = useState({
    fname: null,
    lname: null,
    phoneNumber: null,
    email: null,
    password: null,
    role: null,
    preferredHuntingSpecies: [],
    preferredHuntingStates: [],
    interestedActivities: [],
    usState: null,
    referralMethod: referralMethodFromURL || null,
    referralOther: null,
    smsConsent: true,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSubmit = async values => {
    const { onSubmit, onUpdate, onHideTabs } = props;

    // Make sure we scroll the page on top for mobile users especially
    window.scrollTo(0, 0);

    const isOnlyLandowner = values.role.includes('landowner') && values.role.length === 1;
    const isSecondLastStep = activeStep === allSteps - 2;

    const submitForm = async () => {
      const token = await executeRecaptcha('register');

      await onSubmit({
        ...values,
        token,
      });

      onHideTabs();
    };

    if (isOnlyLandowner) {
      await submitForm();
    } else if (isSecondLastStep && !from) {
      await submitForm();
      setAllValues(values);
      setActiveStep(activeStep + 1);
    } else if (isSecondLastStep && from) {
      await submitForm();
    } else if (activeStep === 1) {
      await onUpdate(values);
      setShouldRedirect(true);
    }
  };

  return (
    <FinalForm
      keepDirtyOnReinitialize
      initialValues={allValues}
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          invalid,
          onOpenTermsOfService,
          errors,
        } = fieldRenderProps;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const steps = [
          <AccountDetails setAllSteps={setAllSteps} {...fieldRenderProps} />,
          <AccountPreferences {...fieldRenderProps} />,
        ];

        const currentStep = steps[activeStep];

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {currentStep}
            <div className={css.bottomWrapper}>
              {Object.keys(errors).length > 0 ? (
                <FormValidationTooltip
                  placement="top"
                  body={
                    <ul>
                      {Object.keys(errors).map(field => {
                        if (field === 'preferredHuntingStates') {
                          return (
                            <li key={`tooltip-field-${field}`}>
                              {errors[field]['FINAL_FORM/array-error']}
                            </li>
                          );
                        }

                        return <li key={`tooltip-field-${field}`}>{errors[field]}</li>;
                      })}
                    </ul>
                  }
                  title={<FormattedMessage id="SignupForm.validationTooltipTitle" />}
                >
                  <PrimaryButton
                    data-testId="signup-button-disabled"
                    type="button"
                    disabled
                    isFullWidth
                  >
                    {activeStep > 0 ? (
                      <FormattedMessage id="SignupForm.createAccount" />
                    ) : (
                      <FormattedMessage id="SignupForm.joinLandtrust" />
                    )}
                  </PrimaryButton>
                </FormValidationTooltip>
              ) : (
                <PrimaryButton
                  data-testId="signup-button-enabled"
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  isFullWidth
                >
                  {activeStep > 0 ? (
                    <FormattedMessage id="SignupForm.createAccount" />
                  ) : (
                    <FormattedMessage id="SignupForm.joinLandtrust" />
                  )}
                </PrimaryButton>
              )}

              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p>
            </div>
            {shouldRedirect && redirect()}
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupWithRecaptcha = props => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <SignupFormComponent {...props} />
    </GoogleReCaptchaProvider>
  );
};

const SignupForm = compose(injectIntl)(SignupWithRecaptcha);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
