/* eslint-disable import/prefer-default-export */
export const shouldShowErrorForField = fieldMeta => {
  const { touched, error } = fieldMeta;

  return touched && error;
};

export const resetFieldsMutator = ([condition, fields], state, { changeValue, resetFieldState }) =>
  condition &&
  Object.entries(fields).forEach(([key, value]) => {
    changeValue(state, key, () => value);
  });
