/* eslint-disable one-var */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { throttle } from 'lodash';

import { InboxListItem, NamedLink } from '..';

import css from './InboxList.css';

const InboxList = ({
  isMobile,
  transactions,
  params,
  intl,
  currentUser,
  isFetching,
  onReset,
  loadMore,
  hasMore,
  isLoadingMore,
  showTabs,
}) => {
  const sentinelRef = useRef();
  const contentRef = useRef();
  const isLoadingMoreRef = useRef(isLoadingMore);
  const hasMoreRef = useRef(hasMore);

  const { tab } = params;
  const [selectedType, setSelectedType] = useState(tab);

  useEffect(() => {
    setSelectedType(params.tab);
  }, [params]);

  useEffect(() => {
    isLoadingMoreRef.current = isLoadingMore;
  }, [isLoadingMore]);

  useEffect(() => {
    hasMoreRef.current = hasMore;
  }, [hasMore]);

  useEffect(() => {
    const scrollContainer = isMobile ? document : contentRef.current;

    const handleScroll = throttle(() => {
      if (!hasMoreRef.current || isLoadingMoreRef.current) {
        return;
      }

      let scrollTop, scrollHeight, clientHeight;

      if (isMobile) {
        const scrollElement = document.documentElement || document.body;
        scrollTop = window.scrollY || scrollElement.scrollTop;
        scrollHeight = scrollElement.scrollHeight;
        clientHeight = window.innerHeight;
      } else if (scrollContainer) {
        scrollTop = scrollContainer.scrollTop;
        scrollHeight = scrollContainer.scrollHeight;
        clientHeight = scrollContainer.clientHeight;
      }

      if (scrollHeight - scrollTop - clientHeight < 400) {
        loadMore();
      }
    }, 200);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadMore, isMobile]);

  const contentClassName = classNames(css.content, {
    [css.contentLoading]: isFetching,
  });

  return (
    <div className={css.root}>
      <div className={css.header}>All Messages</div>

      {showTabs && (
        <div className={css.switch}>
          <NamedLink
            className={classNames(css.switchItem, {
              [css.switchItemSelected]: selectedType === 'orders',
            })}
            name="InboxV2Page"
            params={{
              tab: 'orders',
            }}
            onClick={onReset}
          >
            Booking
          </NamedLink>

          <NamedLink
            className={classNames(css.switchItem, {
              [css.switchItemSelected]: selectedType === 'sales',
            })}
            name="InboxV2Page"
            params={{
              tab: 'sales',
            }}
            onClick={onReset}
          >
            Hosting
          </NamedLink>
        </div>
      )}

      <div className={contentClassName} ref={contentRef}>
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <>
            {transactions.map(transaction => (
              <InboxListItem
                key={transaction.id.uuid}
                transaction={transaction}
                params={params}
                intl={intl}
                currentUser={currentUser}
              />
            ))}

            {isLoadingMore && (
              <div className={css.loadingMore}>
                <FormattedMessage id="InboxPage.loadingMore" />
              </div>
            )}

            <div ref={sentinelRef} className={css.sentinel} />
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(InboxList);
