import React, { useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bool, object } from 'prop-types';
import classNames from 'classnames';
import { SectionPackageCard, InlineTextButton } from '..';
import { HUNT_ACTIVITY, HUNT_DIY_ACTIVITY } from '../../marketplace-custom-config';
import { parseMoneyObjString } from '../../util/currency';

import css from './SectionPackages.css';

const SectionPackages = props => {
  const {
    listing,
    onSubmit,
    rootClassName,
    className,
    listingBookingPanel,
    sectionPackagesTitle,
    showDefaultPackages,
    currentActivity,
  } = props;

  const [showAll, setShowAll] = useState(false);

  const ShowMore = () => {
    return (
      <div className={css.viewAll}>
        <InlineTextButton
          className={css.viewAllText}
          type="button"
          onClick={() => setShowAll(true)}
        >
          <FormattedMessage id="SectionPackages.showAll" />
        </InlineTextButton>
      </div>
    );
  };
  const publicData = get(listing, 'attributes.publicData', {});
  const { packages } = publicData;

  const showDiy =
    currentActivity === HUNT_ACTIVITY || currentActivity === HUNT_DIY_ACTIVITY || !currentActivity;

  const activityPackages = packages.filter(allPackages => {
    const { activity } = allPackages;
    return showDiy ? activity === HUNT_ACTIVITY : activity === currentActivity;
  });

  const classes = classNames(rootClassName || css.sectionPackages, className);
  const parsedPackages = activityPackages
    ? activityPackages.map(item => {
        return {
          ...item,
          price: parseMoneyObjString(item.price),
          lodgingPrice: parseMoneyObjString(item.lodgingPrice),
        };
      })
    : null;
  const parsedPublishedPackages = parsedPackages && parsedPackages.filter(item => item.isPublished);
  const showMoreButton = !showAll && parsedPackages && parsedPackages.length > showDefaultPackages;

  return (
    <div className={listingBookingPanel ? css.listingBookingPanel : classes} id="packagesMobile">
      {sectionPackagesTitle && (
        <div>
          <h3 className={css.sectionTitle}>{sectionPackagesTitle}</h3>
        </div>
      )}
      {parsedPublishedPackages &&
        parsedPublishedPackages
          .slice(0, !showAll ? showDefaultPackages : parsedPublishedPackages.length)
          .map(item => (
            <SectionPackageCard
              key={item.id}
              listing={listing}
              packageId={item.id}
              onClick={onSubmit}
              isResponsive
            />
          ))}

      {showMoreButton && <hr className={css.packageDivider} />}
      {showMoreButton && <ShowMore />}
    </div>
  );
};
SectionPackages.defaultProps = {
  listing: null,
  listingBookingPanel: null,
};

SectionPackages.propTypes = {
  listing: object,
  listingBookingPanel: bool,
};

export default compose(
  withRouter,
  injectIntl
)(SectionPackages);
