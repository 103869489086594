import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './FieldLabel.css';

const FieldLabel = ({ className, rootClassName, description, children, ...labelProps }) => {
  const classes = classNames(rootClassName || css.root, className);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classes} {...labelProps}>
      <>
        {children}
        {description && <span className={css.description}>{description}</span>}
      </>
    </label>
  );
};

FieldLabel.defaultProps = {
  className: null,
  rootClassName: null,
  description: null,
};

FieldLabel.propTypes = {
  className: string,
  rootClassName: string,
  description: string,
};

export default FieldLabel;
