import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import squareWhitetailImage from '../../assets/images/species/square/whitetail/whitetail.jpg';
import squareWhitetailImage2x from '../../assets/images/species/square/whitetail/whitetail@2x.jpg';
import squareWhitetailImage3x from '../../assets/images/species/square/whitetail/whitetail@3x.jpg';
import squareTurkeyImage from '../../assets/images/species/square/turkey/turkey.jpg';
import squareTurkeyImage2x from '../../assets/images/species/square/turkey/turkey@2x.jpg';
import squareTurkeyImage3x from '../../assets/images/species/square/turkey/turkey@3x.jpg';
import squareMuleDeerImage from '../../assets/images/species/square/mule-deer/mule-deer.jpg';
import squareMuleDeerImage2x from '../../assets/images/species/square/mule-deer/mule-deer@2x.jpg';
import squareMuleDeerImage3x from '../../assets/images/species/square/mule-deer/mule-deer@3x.jpg';
import squareElkImage from '../../assets/images/species/square/elk/elk.jpg';
import squareElkImage2x from '../../assets/images/species/square/elk/elk@2x.jpg';
import squareElkImage3x from '../../assets/images/species/square/elk/elk@3x.jpg';
import squareUplandImage from '../../assets/images/species/square/upland/upland.jpg';
import squareUplandImage2x from '../../assets/images/species/square/upland/upland@2x.jpg';
import squareUplandImage3x from '../../assets/images/species/square/upland/upland@3x.jpg';
import squareWaterfowlImage from '../../assets/images/species/square/waterfowl/waterfowl.jpg';
import squareWaterfowlImage2x from '../../assets/images/species/square/waterfowl/waterfowl@2x.jpg';
import squareWaterfowlImage3x from '../../assets/images/species/square/waterfowl/waterfowl@3x.jpg';
import squareHogImage from '../../assets/images/species/square/hog/hog.jpg';
import squareHogImage2x from '../../assets/images/species/square/hog/hog@2x.jpg';
import squareHogImage3x from '../../assets/images/species/square/hog/hog@3x.jpg';
import squareAntelopeImage from '../../assets/images/species/square/antelope/antelope.jpg';
import squareAntelopeImage2x from '../../assets/images/species/square/antelope/antelope@2x.jpg';
import squareAntelopeImage3x from '../../assets/images/species/square/antelope/antelope@3x.jpg';
import squareSmallSpeciesImage3x from '../../assets/images/species/square/small-game/small-game@3x.jpg';
import squareSmallSpeciesImage2x from '../../assets/images/species/square/small-game/small-game@2x.jpg';
import squareSmallSpeciesImage from '../../assets/images/species/square/small-game/small-game.jpg';
import squareCoyoteImage from '../../assets/images/species/square/coyote/coyote.webp';
import squareGopherImage from '../../assets/images/species/square/gopher/gopher.webp';
import squarePrairieDogImage from '../../assets/images/species/square/prairie-dog/prairie-dog.webp';
import squareRabbitImage from '../../assets/images/species/square/rabbit/rabbit.webp';
import squareRaccoonImage from '../../assets/images/species/square/raccoon/raccoon.webp';
import squareSquirrelImage from '../../assets/images/species/square/squirrel/squirrel.webp';

import css from './SquareSpeciesImage.css';

const squareImageMap = {
  whitetail: {
    '1x': squareWhitetailImage,
    '2x': squareWhitetailImage2x,
    '3x': squareWhitetailImage3x,
  },
  turkey: { '1x': squareTurkeyImage, '2x': squareTurkeyImage2x, '3x': squareTurkeyImage3x },
  'mule-deer': {
    '1x': squareMuleDeerImage,
    '2x': squareMuleDeerImage2x,
    '3x': squareMuleDeerImage3x,
  },
  elk: { '1x': squareElkImage, '2x': squareElkImage2x, '3x': squareElkImage3x },
  upland: { '1x': squareUplandImage, '2x': squareUplandImage2x, '3x': squareUplandImage3x },
  pheasant: { '1x': squareUplandImage, '2x': squareUplandImage2x, '3x': squareUplandImage3x },
  waterfowl: {
    '1x': squareWaterfowlImage,
    '2x': squareWaterfowlImage2x,
    '3x': squareWaterfowlImage3x,
  },
  hog: { '1x': squareHogImage, '2x': squareHogImage2x, '3x': squareHogImage3x },
  antelope: { '1x': squareAntelopeImage, '2x': squareAntelopeImage2x, '3x': squareAntelopeImage3x },
  'small-game': {
    '1x': squareSmallSpeciesImage,
    '2x': squareSmallSpeciesImage2x,
    '3x': squareSmallSpeciesImage3x,
  },
  coyote: { '1x': squareCoyoteImage, '2x': squareCoyoteImage, '3x': squareCoyoteImage },
  gopher: { '1x': squareGopherImage, '2x': squareGopherImage, '3x': squareGopherImage },
  'prairie-dog': {
    '1x': squarePrairieDogImage,
    '2x': squarePrairieDogImage,
    '3x': squarePrairieDogImage,
  },
  rabbit: { '1x': squareRabbitImage, '2x': squareRabbitImage, '3x': squareRabbitImage },
  raccoon: { '1x': squareRaccoonImage, '2x': squareRaccoonImage, '3x': squareRaccoonImage },
  squirrel: { '1x': squareSquirrelImage, '2x': squareSquirrelImage, '3x': squareSquirrelImage },
};

const SquareSpeciesImage = ({
  className,
  rootClassName,
  imageClassName,
  imageKey,
  ...imgProps
}) => {
  const rootClasses = classNames(rootClassName || css.root, className);
  const imgClasses = classNames(imageClassName || css.image);

  if (!squareImageMap[imageKey]) return null;

  return (
    <div className={rootClasses}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        className={imgClasses}
        src={squareImageMap[imageKey]['1x']}
        srcSet={`${squareImageMap[imageKey]['2x']} 260w, ${squareImageMap[imageKey]['3x']} 390w`}
        {...imgProps}
      />
    </div>
  );
};

SquareSpeciesImage.defaultProps = {
  className: null,
  rootClassName: null,
  imageClassName: null,
};

SquareSpeciesImage.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  imageKey: PropTypes.string.isRequired,
  imageClassName: PropTypes.string,
};

export default SquareSpeciesImage;
