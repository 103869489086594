import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
// import routeConfiguration from '../../routeConfiguration';
// import { pathByRouteName } from '../../util/routes';
// import { apiBaseUrl } from '../../util/api';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { format } from '../../util/fiFormatter';
import { isSignupEmailTakenError } from '../../util/errors';
import { LinkTabNavHorizontal, NamedRedirect } from '..';
import { LoginForm, IdpSignupForm } from '../../forms';
import InquirySignupForm from './InquirySignupForm';
import {
  login,
  authenticationInProgress,
  signup,
  signupStarted,
  signupWithIdp,
} from '../../ducks/Auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
// import { FacebookLogo, GoogleLogo } from '../../containers/AuthenticationPage/socialLoginLogos';

import css from './InquiryAuthenticationPage.css';

export class InquiryAuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      // eslint-disable-next-line react/no-unused-state
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      showTabs: true,
      activeTab: 'signup',
      hashPresent: false,
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);

    const { signupStart, location } = this.props;
    if (props.tab === 'signup') {
      signupStart({ path: location.pathname });
    }
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    this.updateHashPresence();
    Cookies.remove('st-autherror');
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { hashPresent } = this.state;
    if (prevProps.location.hash !== location.hash) {
      const newHashPresent = location.hash === '#discount';
      if (newHashPresent !== hashPresent) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ hashPresent: newHashPresent });
      }
    }
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  updateHashPresence() {
    const hashPresent = window.location.hash === '#discount';
    this.setState({ hashPresent });
  }

  toggleShow() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  render() {
    const {
      authInProgress,
      currentUser,
      isAuthenticated,
      // location,
      loginError,
      signupError,
      submitLogin,
      submitSignup,
      tab,
      confirmError,
      submitSignupWithIdp,
      fromLTPlusPage,
      useRedirects,
      inquiryButton,
      plusLoginButton,
      listing,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const { showPassword, authInfo, showTabs, activeTab, hashPresent } = this.state;
    const stateFromInquiry = listing?.listing?.attributes?.publicData?.location?.usState;
    const isLogin = activeTab === 'login';
    // const locationFrom = get(location, 'state.from', null);
    // const authinfoFrom = get(authInfo, 'from', null);
    // let from = null;
    // if (locationFrom) {
    //   from = locationFrom;
    // }
    // if (authinfoFrom) {
    //   from = authinfoFrom;
    // }

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const redirectToCheckout = () => {
      if (isAuthenticated && currentUserLoaded && useRedirects) {
        return hashPresent ? (
          <NamedRedirect name="LTPlusCheckoutPageDiscount" />
        ) : (
          <NamedRedirect name="LTPlusCheckoutPage" />
        );
      }
      return null;
    };

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.

    const isLandowner = user?.attributes?.profile?.protectedData?.role?.includes('landowner');

    const loginErrorMessage = (
      <div className={css.error} data-testid="login-form-error">
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error} data-testid="signup-form-error">
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage
            id="AuthenticationPage.signupFailedEmailAlreadyTaken"
            values={{
              link: (
                <Link to="/login" style={{ textDecoration: 'underline' }} className={css.error}>
                  logging in
                </Link>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const tabs = [
      {
        text: (
          <h4 className={css.tabContent}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h4>
        ),
        selected: activeTab === 'signup',
        onClick: () => this.setActiveTab('signup'),
      },
      {
        text: (
          <h4 className={css.tabContent}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h4>
        ),
        selected: activeTab === 'login',
        onClick: () => this.setActiveTab('login'),
      },
    ];

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;
      const params = { firstName: fname?.trim(), lastName: lname?.trim(), ...rest };
      submitSignup(params);
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = authInfo;
      const {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        preferredHuntingStates = [],
        preferredHuntingSpecies = [],
        interestedActivities = [],
        usState,
        ...rest
      } = values;
      // Pass email, fistName or lastName to Flex API only if user has edited them
      // and they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
        usState,
        preferredHuntingStates,
        preferredHuntingSpecies,
        interestedActivities,
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      // Add formatted phone number for email purposes
      if (protectedData && protectedData.phoneNumber) {
        const phoneNumberFormatted = format(protectedData.phoneNumber);

        protectedData.phoneNumberFormatted = phoneNumberFormatted;
      }

      submitSignupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      });
    };

    // const getDefaultRoutes = () => {
    //   const routes = routeConfiguration();
    //   const baseUrl = apiBaseUrl();

    //   // Route where the user should be returned after authentication
    //   // This is used e.g. with EditListingPage and ListingPage
    //   const fromParam = from ? `from=${from}` : '';

    //   // Default route where user is returned after successfull authentication
    //   const defaultReturn = pathByRouteName('LandingPage', routes);
    //   const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    //   // Route for confirming user data before creating a new user
    //   const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    //   const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    //   return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    // };

    // const authWithFacebook = () => {
    //   const defaultRoutes = getDefaultRoutes();
    //   const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    //   window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    // };

    // const authWithGoogle = () => {
    //   const defaultRoutes = getDefaultRoutes();
    //   const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    //   window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    // };

    const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

    // Form for confirming information from IdP (e.g. Facebook)
    // before new user is created to Flex
    const idpSignupForm = (
      <div className={css.content}>
        <IdpSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          isFromSignup
          authInfo={authInfo}
          idp={idp}
          confirmErrorMessage={confirmErrorMessage}
        />
      </div>
    );

    // Social login buttons
    // const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    // const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    // const showSocialLogins = showFacebookLogin || showGoogleLogin;

    // const facebookButtonText = isLogin ? (
    //   <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    // ) : (
    //   <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    // );

    // const googleButtonText = isLogin ? (
    //   <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    // ) : (
    //   <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    // );
    // const socialLoginButtonsMaybe =
    //   showSocialLogins && showTabs ? (
    //     <div className={css.idpButtons}>
    //       {showFacebookLogin ? (
    //         <div data-testId="login-facebook-button" className={css.socialButtonWrapper}>
    //           <FacebookLoginButton onClick={() => authWithFacebook()}>
    //             <span className={css.buttonIcon}>{FacebookLogo}</span>
    //             {facebookButtonText}
    //           </FacebookLoginButton>
    //         </div>
    //       ) : null}

    //       {showGoogleLogin ? (
    //         <div className={css.socialButtonWrapper}>
    //           <GoogleLoginButton onClick={() => authWithGoogle()}>
    //             <span className={css.buttonIcon}>{GoogleLogo}</span>
    //             {googleButtonText}
    //           </GoogleLoginButton>
    //         </div>
    //       ) : null}
    //       <div className={css.socialButtonsOr}>
    //         <span className={css.socialButtonsOrText}>
    //           {isLogin ? (
    //             <FormattedMessage id="AuthenticationPage.orLogin" />
    //           ) : (
    //             <FormattedMessage id="AuthenticationPage.orSignup" />
    //           )}
    //         </span>
    //       </div>
    //     </div>
    //   ) : null;

    // Tabs for SignupForm and LoginForm
    const authenticationForms = (
      <div data-testid="authentication-form" className={css.content}>
        {showTabs && (
          <>
            <LinkTabNavHorizontal
              className={css.tabs}
              tabRootClassName={css.tab}
              tabClassName={css.tab}
              tabs={tabs}
            />
          </>
        )}

        {loginOrSignupError}
        {/* {socialLoginButtonsMaybe} */}
        {isLogin ? (
          <LoginForm
            className={css.form}
            onSubmit={submitLogin}
            inProgress={authInProgress}
            showPassword={showPassword}
            fromLTPlusPage={fromLTPlusPage}
            toggleShow={this.toggleShow}
            plusLoginButton={plusLoginButton}
          />
        ) : (
          <InquirySignupForm
            className={css.form}
            isFromSignup
            onSubmit={handleSubmitSignup}
            useRedirects={useRedirects}
            inProgress={authInProgress}
            showPassword={showPassword}
            toggleShow={this.toggleShow}
            onHideTabs={() => this.setState({ showTabs: false })}
            isAuthenticated={isAuthenticated}
            currentUserLoaded={currentUserLoaded}
            isLandowner={isLandowner}
            config={config}
            fromLTPlusPage={fromLTPlusPage}
            inquiryButton={inquiryButton}
            stateFromInquiry={stateFromInquiry}
          />
        )}
      </div>
    );
    const formContent = isConfirm ? idpSignupForm : authenticationForms;

    return (
      <div className={css.layoutWrapperMain}>
        <div className={css.root}>{formContent}</div>
        {useRedirects && redirectToCheckout()}
      </div>
    );
  }
}

InquiryAuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  useRedirects: true,
};

const { bool, func, object, oneOf, shape } = PropTypes;

InquiryAuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  confirmError: propTypes.error,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  useRedirects: bool,
  tab: oneOf(['login', 'signup', 'confirm']),

  // from withRouter
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  signupStart: params => dispatch(signupStarted(params)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  submitSignup: params => dispatch(signup(params)),
  updateUserData: data => dispatch(updateProfile(data)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const InquiryAuthenticationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(InquiryAuthenticationPageComponent);

export default InquiryAuthenticationPage;
