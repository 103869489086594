import React, { useState } from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldWysiwyg,
  EditListingFieldWrapper,
  EditListingSection,
  EditListingSectionHeading,
  EditListingAccessPhotoLibrary,
  EditListingActions,
  EditListingError,
  FieldTimeInput,
  FieldCondition,
  FieldRadioButton,
  FieldToggle,
  FieldLabel,
  FieldPillInputGroup,
  FieldPointsOfInterestsInput,
  LessMore,
} from '../../components';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';
import { cellServiceOptions } from '../../marketplace-custom-config';
import css from './EditListingArrivalForm.css';

export const EditListingArrivalFormComponent = ({
  backLink,
  listing,
  onSubmit,
  initialValues,
  requestImageUpload,
  ...props
}) => {
  const [arrivalImagesIds, setArrivalImagesIds] = useState(initialValues.images);

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={values => {
        onSubmit({
          ...values,
          images: arrivalImagesIds,
        });
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const classes = classNames(className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />
            </EditListingSection>

            <EditListingSection
              title={intl.formatMessage({ id: 'EditListingArrivalForm.step1Title' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldWysiwyg
                  id="instructions"
                  name="instructions"
                  placeholder={intl.formatMessage({
                    id: 'EditListingArrivalForm.instructionsPlaceholder',
                  })}
                  protip={intl.formatMessage({
                    id: 'EditListingArrivalForm.instructionsProtip',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              title={intl.formatMessage({ id: 'EditListingArrivalForm.step2Title' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldTimeInput name="checkInTime" id="checkInTime" />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              title={intl.formatMessage({ id: 'EditListingArrivalForm.step3Title' })}
              hasBottomMargin
            >
              <EditListingFieldWrapper>
                <FieldTimeInput name="checkOutTime" id="checkOutTime" />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBottomMargin
              title={intl.formatMessage({ id: 'EditListingArrivalForm.step4Title' })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="hasCellService" />
              </EditListingFieldWrapper>

              <FieldCondition when="hasCellService" is>
                <EditListingFieldWrapper>
                  <FieldPillInputGroup
                    name="cellService"
                    id="cellService"
                    options={cellServiceOptions}
                    label={intl.formatMessage({
                      id: 'EditListingArrivalForm.cellServiceLabel',
                    })}
                  />
                </EditListingFieldWrapper>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="cellServiceInfo"
                    name="cellServiceInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingForms.additionalInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection hasBottomMargin>
              <EditListingFieldWrapper>
                <FieldLabel
                  description={intl.formatMessage({
                    id: 'EditListingArrivalForm.tipsTricksDescription',
                  })}
                >
                  <FormattedMessage id="EditListingArrivalForm.tipsTricksLabel" />
                </FieldLabel>

                <FieldWysiwyg
                  id="tipsTricks"
                  name="tipsTricks"
                  placeholder={intl.formatMessage({
                    id: 'EditListingArrivalForm.tipsTricksPlaceholder',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection>
              <EditListingSectionHeading>
                <FormattedMessage id="EditListingArrivalForm.accessLabel" />
              </EditListingSectionHeading>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingArrivalForm.milesToInterstateLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper className={css.radioWrapper}>
                <FieldRadioButton
                  name="milesToInterstate"
                  id="milesToInterstateFirst"
                  value="0-10 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles10',
                  })}
                />

                <FieldRadioButton
                  name="milesToInterstate"
                  id="milesToInterstateSecond"
                  value="11-20 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles11',
                  })}
                />

                <FieldRadioButton
                  name="milesToInterstate"
                  id="milesToInterstateThird"
                  value="20+ miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles20',
                  })}
                />

                <FieldRadioButton
                  name="milesToInterstate"
                  id="milesToInterstateForth"
                  value="N/A"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.milesNA',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingArrivalForm.milesToHighwayLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper className={css.radioWrapper}>
                <FieldRadioButton
                  name="milesToHighway"
                  id="milesToHighwayFirst"
                  value="0-10 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles10',
                  })}
                />

                <FieldRadioButton
                  name="milesToHighway"
                  id="milesToHighwaySecond"
                  value="11-20 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles11',
                  })}
                />

                <FieldRadioButton
                  name="milesToHighway"
                  id="milesToHighwayThird"
                  value="20+ miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles20',
                  })}
                />

                <FieldRadioButton
                  name="milesToHighway"
                  id="milesToHighwayForth"
                  value="N/A"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.milesNA',
                  })}
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingSection
              hasBackground
              title={intl.formatMessage({
                id: 'EditListingArrivalForm.milesToAirportLabel',
              })}
              hasBottomMargin
            >
              <EditListingFieldWrapper className={css.radioWrapper}>
                <FieldRadioButton
                  name="milesToAirport"
                  id="milesToAirportFirst"
                  value="0-10 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles10',
                  })}
                />

                <FieldRadioButton
                  name="milesToAirport"
                  id="milesToAirportSecond"
                  value="11-20 miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles11',
                  })}
                />

                <FieldRadioButton
                  name="milesToAirport"
                  id="milesToAirportThird"
                  value="20+ miles"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.miles20',
                  })}
                />

                <FieldRadioButton
                  name="milesToAirport"
                  id="milesToAirportForth"
                  value="N/A"
                  label={intl.formatMessage({
                    id: 'EditListingArrivalForm.milesNA',
                  })}
                />
              </EditListingFieldWrapper>

              <FieldCondition when="milesToAirport" operator="ne" is="N/A">
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="milesToAirportInfo"
                    name="milesToAirportInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingArrivalForm.milesToAirportInfo',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection
              hasBackground
              hasBottomMargin
              title={intl.formatMessage({
                id: 'EditListingArrivalForm.highVehicleRequired',
              })}
            >
              <EditListingFieldWrapper>
                <FieldToggle name="highVehicleRequired" />
              </EditListingFieldWrapper>

              <FieldCondition when="highVehicleRequired" is>
                <EditListingFieldWrapper>
                  <FieldTextInput
                    id="highVehicleRequiredInfo"
                    name="highVehicleRequiredInfo"
                    type="textarea"
                    label={intl.formatMessage({
                      id: 'EditListingArrivalForm.highVehicleRequiredInfoLabel',
                    })}
                  />
                </EditListingFieldWrapper>
              </FieldCondition>
            </EditListingSection>

            <EditListingSection hasBottomMargin>
              <EditListingAccessPhotoLibrary
                currentListing={listing}
                imagesIds={arrivalImagesIds}
                title={<FormattedMessage id="EditListingArrivalForm.photoLibraryPos" />}
                onUpdateImagesIds={setArrivalImagesIds}
                requestImageUpload={requestImageUpload}
                fieldHeader={
                  <EditListingSectionHeading
                    description={intl.formatMessage({
                      id: 'EditListingArrivalForm.imageDescription',
                    })}
                  >
                    <FormattedMessage id="EditListingArrivalForm.imageLabel" />
                  </EditListingSectionHeading>
                }
              />
            </EditListingSection>

            <EditListingSection>
              <FieldLabel
                description={intl.formatMessage({
                  id: 'EditListingArrivalForm.posDescription',
                })}
              >
                <FormattedMessage id="EditListingArrivalForm.posLabel" />
              </FieldLabel>

              <LessMore rootClassName={css.lessMoreBtn}>
                <>
                  <EditListingSectionHeading
                    description={
                      <FormattedMessage id="EditListingArrivalForm.posLinksDescription" />
                    }
                  >
                    <FormattedMessage id="EditListingArrivalForm.posLinksTitle" />
                  </EditListingSectionHeading>

                  <EditListingFieldWrapper>
                    <FieldPointsOfInterestsInput
                      id="waypoints-onX"
                      name="waypoints.onX"
                      label={intl.formatMessage({
                        id: 'EditListingArrivalForm.pointsOfInterestOnx',
                      })}
                    />
                    <FieldPointsOfInterestsInput
                      id="waypoints-huntWise"
                      name="waypoints.huntWise"
                      label={intl.formatMessage({
                        id: 'EditListingArrivalForm.pointsOfInterestHuntWise',
                      })}
                    />
                  </EditListingFieldWrapper>
                </>
              </LessMore>
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingArrivalFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  backLink: null,
  skipLink: null,
};

EditListingArrivalFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  skipLink: node,
  requestImageUpload: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingArrivalFormComponent));
