/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { func, arrayOf, bool } from 'prop-types';
import { dateObjectToJson, daysAdded } from '../../util/dates';
import { Page, Container, InquiryWizard } from '../../components';
import { TopbarContainer } from '..';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingState, getListingTimezone } from '../../util/listing';
import { loadData, sendEnquiry, setInitialValues } from './NewInquiryPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { useInquiryWizard } from '../../components/InquiryWizard/InquiryWizardContext';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { PROVIDER_COMMISSION_PERCENTAGE } from '../../shared/transaction';
import { LODGING_INFO } from '../../util/package';
import { commissionRateMap } from '../../marketplace-custom-config';
import { followSubject } from '../../ducks/user.duck';
import css from './NewInquiryPage.css';

const { UUID } = sdkTypes;

const NewInquiryPageComponent = ({
  intl,
  history,
  location,
  params,
  getListing,
  timeSlots,
  sendEnquiryInProgress,
  onSendInquiry,
  currentUser,
  followSubjectMutation,
  scrollingDisabled,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const activityParam = searchParams.get('activity');

  const listing = ensureListing(getListing(new UUID(params.id)));
  const { isFirst, prev } = useInquiryWizard();

  const exit = () => {
    const routeTo = activityParam ? 'ActivityPage' : 'ListingPage';

    history.push(
      createResourceLocatorString(routeTo, routeConfiguration(), {
        ...params,
        activity: activityParam && activityParam,
      })
    );
  };

  if (!listing.id) return null;

  const handleSubmit = async values => {
    const landowner = listing?.author?.id?.uuid;
    const currentUserId = currentUser?.id?.uuid;

    if (currentUserId === landowner) {
      setErrorMessage('You cannot send an inquiry for your own property.');
      return;
    }
    const { bookingDates, lodgingAddOn, message, partySize, selectedPackage } = values;
    const packageActivity = get(selectedPackage, 'activity', null);
    const hasLodging = get(selectedPackage, 'hasLodging', null);
    const lodgingAmount = get(selectedPackage, 'lodgingPrice.amount', 0);
    const packageAmount = get(selectedPackage, 'price.amount', 0);
    const packageId = get(selectedPackage, 'id', null);
    const packageDescription = get(selectedPackage, 'description', null);
    const packageTitle = get(selectedPackage, 'title', null);
    const packagePrice = {
      amount: get(selectedPackage, 'price.amount', null),
      currency: 'USD',
    };
    const packageSpecies = get(selectedPackage, 'species', []);

    const { startDate, endDate } = bookingDates;

    const days = daysAdded(startDate, endDate);
    const nights = days - 1;

    const includeLodging = lodgingAddOn?.length > 0 || hasLodging === LODGING_INFO.REQUIRED;
    const totalLodgingAmount = includeLodging ? lodgingAmount * nights : 0;

    const subtotalAmount = selectedPackage && packageAmount * partySize * days + totalLodgingAmount;

    const landownerRelationship = get(
      listing,
      'author.attributes.profile.publicData.landownerRelationship',
      'default'
    );
    const commissionRate = commissionRateMap.find(object => {
      return object.key === landownerRelationship;
    });
    const percentageSubtotal =
      ((commissionRate?.commission || PROVIDER_COMMISSION_PERCENTAGE) / 100) * subtotalAmount;

    const potentialEarnings = (subtotalAmount + percentageSubtotal) / 100;

    const startObj = dateObjectToJson(startDate);
    const endObj = dateObjectToJson(endDate);
    const endDateMaybe = startDate.toISOString() !== endDate.toISOString() ? endObj : null;

    // We wanna get the date at the listing location.
    // This will then be used for the display dates which are hooked
    // with our process.end transitions
    const timezone = getListingTimezone(listing);

    const bookingStartDate = moment(startDate).format('YYYY-MM-DD');
    const bookingEndDate = moment(endDate).format('YYYY-MM-DD');

    const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
    const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);

    const bookingStartDateInLocaleInUTC = moment(bookingStartDateInLocale).utc();
    const bookingEndDateInLocaleInUTC = moment(bookingEndDateInLocale).utc();

    const bookingDisplayStart = bookingStartDateInLocaleInUTC.toDate();
    const bookingDisplayEnd = bookingEndDateInLocaleInUTC.toDate();

    // The API booking end date is always exclusive. In order to have e.g.
    // 1st of Jul - 2nd of July we need to send end date as 3rd.
    // Important, the 3rd of July won't be blocked from future bookings.
    const apiBookingEnd = moment
      .utc(endDate)
      .set({ hour: 0, minute: 0, seconds: 0 })
      .add(1, 'd')
      .toDate();
    const apiBookingStart = moment
      .utc(startDate)
      .set({ hour: 0, minute: 0 })
      .toDate();

    try {
      const txId = await onSendInquiry(listing.id.uuid, message, {
        species: packageSpecies,
        partySize,
        selectedPackage: selectedPackage && {
          packageId,
          packageDescription,
          packageTitle,
          packagePrice,
          packageParty: partySize,
          packageActivity,
        },
        potentialEarnings: { amount: potentialEarnings, currency: 'USD' },
        emailStart: startObj,
        emailEnd: endDateMaybe,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        activity: activityParam,
        listingUSState: getListingState(listing)?.state,
        bookingStart: apiBookingStart,
        bookingEnd: apiBookingEnd,
        bookingDisplayStart,
        bookingDisplayEnd,
      });

      history.push({
        pathname: createResourceLocatorString(
          'OrderDetailsPage',
          routeConfiguration(),
          { id: txId.uuid },
          {}
        ),
        state: { showInquiryModal: true },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, 'sending inquiry');
    }
  };

  const exitButton = (
    <button type="button" className={css.topBarExitButton} onClick={exit}>
      {intl.formatMessage({ id: 'NewInquiryPage.exitButton' })}
    </button>
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'NewInquiryPage.title' })}
      scrollingDisabled={scrollingDisabled}
      noindex
      nofollow
    >
      <Container className={css.container}>
        <div className={css.formContainer}>
          {listing.id && (
            <InquiryWizard
              listing={listing}
              timeSlots={timeSlots}
              sendEnquiryInProgress={sendEnquiryInProgress}
              onSubmit={handleSubmit}
              currentUser={currentUser}
              followSubjectMutation={followSubjectMutation}
              errorMessage={errorMessage}
            />
          )}
        </div>
      </Container>
    </Page>
  );
};

NewInquiryPageComponent.defaultProps = {
  timeSlots: null,
  currentUser: null,
};

NewInquiryPageComponent.propTypes = {
  intl: intlShape.isRequired,
  getListing: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),

  sendEnquiryInProgress: bool.isRequired,
  onSendInquiry: func.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const { timeSlots, sendEnquiryInProgress } = state.NewInquiryPage;
  const { currentUser } = state.user;

  return {
    currentUser,
    getListing,
    timeSlots,
    sendEnquiryInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSendInquiry: (listingId, message, meta) => dispatch(sendEnquiry(listingId, message, meta)),
  followSubjectMutation: (userId, subjectId, notifyBy) =>
    dispatch(followSubject(userId, subjectId, notifyBy)),
});

const NewInquiryPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(NewInquiryPageComponent);

NewInquiryPage.setInitialValues = initialValues => setInitialValues(initialValues);
NewInquiryPage.loadData = loadData;

export default NewInquiryPage;
