import React from 'react';
import { FormattedMessage } from 'react-intl';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionLateBooking = ({ transition, buttons }) => {
  return (
    <InboxTransitionBase date={transition.createdAt} direction="left" buttons={buttons}>
      <FormattedMessage id="BookingStatus.transitionLateBooking" />
    </InboxTransitionBase>
  );
};

export default InboxTransitionLateBooking;
