import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { string } from 'prop-types';
import { ExternalLink } from '../../components';

import css from './ListingPage.css';

const SectionHelpfulResources = props => {
  const { licenseLink, regulationsLink } = props;

  return licenseLink || regulationsLink ? (
    <div className={css.helpfulResources}>
      <strong>
        <FormattedHTMLMessage id="ListingPage.helpfulResources" />
      </strong>
      <div className={css.linkContainer}>
        {licenseLink ? (
          <div className={css.links}>
            <ExternalLink
              className={css.linkDecor}
              key="linkToLicence"
              href={licenseLink}
              title={SectionHelpfulResources.goToLicense}
            >
              <FormattedHTMLMessage id="ListingPage.licenseLink" />
            </ExternalLink>
          </div>
        ) : null}
        {regulationsLink ? (
          <div className={css.links}>
            <ExternalLink
              className={css.linkDecor}
              key="linkToRegulations"
              href={regulationsLink}
              title={SectionHelpfulResources.goToRegulations}
            >
              <FormattedHTMLMessage id="ListingPage.regulationsLink" />
            </ExternalLink>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};
SectionHelpfulResources.defaultProps = {
  licenseLink: null,
  regulationsLink: null,
};

SectionHelpfulResources.propTypes = {
  licenseLink: string,
  regulationsLink: string,
};

export default SectionHelpfulResources;
