import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as BellIcon } from '../../assets/icons/bell-solid.svg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TertiaryButton } from '..';
import css from './PackageAvailabilityNotifyButton.css';

const PackageAvailabilityNotifyButton = ({
  onClick,
  isLoading,
  isAuthenticated,
  history,
  location,
}) => {
  const handleClick = e => {
    if (!isAuthenticated) {
      const state = {
        from: location.pathname,
      };

      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      onClick(e);
    }
  };

  return (
    <TertiaryButton isFullWidth type="button" onClick={handleClick} inProgress={isLoading}>
      <div className={css.buttonWrapper}>
        <BellIcon className={css.icon} />
        <FormattedMessage id="PackageAvailabilityNotifyButton.notifyMe" />
      </div>
    </TertiaryButton>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(PackageAvailabilityNotifyButton);
