import isEqual from 'lodash/isEqual';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { format } from '../../util/fiFormatter';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST =
  'app/ProfileSettingsPage/AccountDetails/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS =
  'app/ProfileSettingsPage/AccountDetails/SAVE_CONTACT_DETAILS_SUCCESS';

export const CHANGE_PASSWORD_REQUEST =
  'app/ProfileSettingsPage/AccountDetails/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS =
  'app/ProfileSettingsPage/AccountDetails/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/ProfileSettingsPage/AccountDetails/CHANGE_PASSWORD_ERROR';

export const CHANGE_PASSWORD_CLEAR = 'app/ProfileSettingsPage/AccountDetails/CHANGE_PASSWORD_CLEAR';
export const SAVE_EMAIL_ERROR = 'app/ProfileSettingsPage/AccountDetails/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR =
  'app/ProfileSettingsPage/AccountDetails/SAVE_PHONE_NUMBER_ERROR';
export const SAVE_FIRST_NAME_ERROR = 'app/ProfileSettingsPage/AccountDetails/SAVE_FIRST_NAME_ERROR';
export const SAVE_LAST_NAME_ERROR = 'app/ProfileSettingsPage/AccountDetails/SAVE_LAST_NAME_ERROR';
export const SAVE_ROLE_ERROR = 'app/ProfileSettingsPage/AccountDetails/SAVE_ROLE_ERROR';
export const SAVE_REFERRAL_ERROR = 'app/ProfileSettingsPage/AccountDetails/SAVE_REFERRAL_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR =
  'app/ProfileSettingsPage/AccountDetails/SAVE_CONTACT_DETAILS_CLEAR';
export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/AccountDetails/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/MyInterests/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/MyInterests/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/MyInterests/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  saveFirstNameError: null,
  saveLastNameError: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  saveRoleError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };
    case SAVE_FIRST_NAME_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveFirstNameError: payload };
    case SAVE_LAST_NAME_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveLastNameError: payload };
    case SAVE_ROLE_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveRoleError: payload };
    case SAVE_REFERRAL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveReferralError: payload };
    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHANGE_PASSWORD_CLEAR:
      return { ...initialState };
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = error => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});
export const saveFirstNameError = error => ({
  type: SAVE_FIRST_NAME_ERROR,
  payload: error,
  error: true,
});
export const saveLastNameError = error => ({
  type: SAVE_LAST_NAME_ERROR,
  payload: error,
  error: true,
});
export const saveRoleError = error => ({
  type: SAVE_ROLE_ERROR,
  payload: error,
  error: true,
});
export const saveReferralError = error => ({
  type: SAVE_REFERRAL_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

export const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });
export const changePasswordError = error => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
  error: true,
});

export const changePasswordClear = () => ({ type: CHANGE_PASSWORD_CLEAR });

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumber = params => (dispatch, getState, sdk) => {
  const { phoneNumber, secondaryPhoneNumber } = params;

  // Add formatted phone number for email purposes
  const phoneNumberFormatted = format(phoneNumber);
  const secondaryPhoneNumberFormatted = format(secondaryPhoneNumber);

  return sdk.currentUser
    .updateProfile(
      {
        protectedData: {
          phoneNumber,
          phoneNumberFormatted,
          secondaryPhoneNumber,
          secondaryPhoneNumberFormatted,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make an email update request to the API and return the current user.
 */
const requestSaveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

const requestSaveFirstName = params => (dispatch, getState, sdk) => {
  const { fname } = params;

  return sdk.currentUser
    .updateProfile(
      { firstName: fname },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveFirstNameError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

const requestSaveLastName = params => (dispatch, getState, sdk) => {
  const { lname } = params;

  return sdk.currentUser
    .updateProfile(
      { lastName: lname },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveLastNameError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

const requestSaveRole = params => (dispatch, getState, sdk) => {
  const { role } = params;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { role } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveRoleError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a referralMethod update request to the API and return the current user.
 */

const requestSaveReferral = params => (dispatch, getState, sdk) => {
  const { referralMethod, referralOther } = params;
  return sdk.currentUser
    .updateProfile(
      { protectedData: { referralMethod, referralOther } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveReferralError(storableError(e)));
      throw e;
    });
};

const requestSaveConsent = params => (dispatch, getState, sdk) => {
  const { smsConsent = false } = params;
  return sdk.currentUser
    .updateProfile(
      { protectedData: { smsConsent } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveReferralError(storableError(e)));
      throw e;
    });
};

/**
 * Save email and update the current user.
 */
const saveEmail = params => dispatch => {
  return (
    dispatch(requestSaveEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
      })
      // error action dispatched in requestSaveEmail
      .catch(() => null)
  );
};

/**
 * Save phone number and update the current user.
 */
const savePhoneNumber = params => dispatch => {
  return (
    dispatch(requestSavePhoneNumber(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(() => null)
  );
};

const saveFirstName = params => dispatch => {
  return (
    dispatch(requestSaveFirstName(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
      })
      // error action dispatched in requestSaveFirstName
      .catch(() => null)
  );
};

const saveLastName = params => dispatch => {
  return (
    dispatch(requestSaveLastName(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
      })
      // error action dispatched in requestSaveFirstName
      .catch(() => null)
  );
};

const saveRole = params => dispatch => {
  return (
    dispatch(requestSaveRole(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
      })
      // error action dispatched in requestSaveFirstName
      .catch(() => null)
  );
};

const saveReferral = params => dispatch => {
  return (
    dispatch(requestSaveReferral(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
      })
      // error action dispatched in requestSaveFirstName
      .catch(() => null)
  );
};

const saveConsent = params => dispatch => {
  return (
    dispatch(requestSaveConsent(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
      })
      // error action dispatched in requestSaveFirstName
      .catch(() => null)
  );
};

export const changePassword = params => (dispatch, getState, sdk) => {
  dispatch(changePasswordRequest());
  const { newPassword, currentPassword } = params;

  return sdk.currentUser
    .changePassword({ newPassword, currentPassword })
    .then(() => dispatch(changePasswordSuccess()))
    .catch(e => {
      dispatch(changePasswordError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      // throw e;
    });
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => dispatch => {
  dispatch(saveContactDetailsRequest());

  const {
    currentFirstName,
    currentLastName,
    currentEmail,
    currentRole,
    currentPasswordForEmail,
    currentPhoneNumber,
    currentSecondaryPhoneNumber,
    currentReferralMethod,
    currentConsent,
    fname,
    lname,
    email,
    role,
    phoneNumber,
    secondaryPhoneNumber,
    referralMethod,
    referralOther,
    currentReferralOther,
    smsConsent,
  } = params;
  const promises = [];

  const firstNameChanged = fname !== currentFirstName;
  const lastNameChanged = lname !== currentLastName;
  const emailChanged = email !== currentEmail;
  const roleChanged = !isEqual(role, currentRole);
  const phoneNumberChanged = phoneNumber !== currentPhoneNumber;
  const secondaryPhoneNumberChanged = secondaryPhoneNumber !== currentSecondaryPhoneNumber;
  const referralMethodChanged = referralMethod !== currentReferralMethod;
  const referralMethodOtherChanged = referralOther !== currentReferralOther;
  const consentChanged = smsConsent !== currentConsent;

  if (emailChanged) {
    promises.push(dispatch(saveEmail({ email, currentPassword: currentPasswordForEmail })));
  }

  if (phoneNumberChanged || secondaryPhoneNumberChanged) {
    promises.push(dispatch(savePhoneNumber({ phoneNumber, secondaryPhoneNumber })));
  }

  if (firstNameChanged) {
    promises.push(dispatch(saveFirstName({ fname })));
  }

  if (lastNameChanged) {
    promises.push(dispatch(saveLastName({ lname })));
  }

  if (roleChanged) {
    promises.push(dispatch(saveRole({ role })));
  }

  if (referralMethodChanged) {
    promises.push(dispatch(saveReferral({ referralMethod })));
  }

  if (referralMethodOtherChanged) {
    promises.push(dispatch(saveReferral({ referralMethod, referralOther })));
  }

  if (consentChanged) {
    promises.push(dispatch(saveConsent({ smsConsent })));
  }

  return Promise.all(promises)
    .then(() => {
      dispatch(saveContactDetailsSuccess());
    })
    .catch(() => null);
};

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.default'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const { id } = actionPayload;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));

        return uploadedImage;
      })
      .then(image => {
        return dispatch(updateProfile({ profileImageId: image.id }));
      })
      .then(() => {
        return sdk.currentUser
          .show({ include: ['profileImage'], 'fields.image': ['variants.square-small'] })
          .then(res => {
            const profileImageURL = res.data.included[0].attributes.variants['square-small'].url;
            return dispatch(updateProfile({ publicData: { profileImageURL } }));
          });
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const deleteImage = () => dispatch => {
  dispatch(updateProfile({ profileImageId: null }));
};

export const updatePayoutDetails = () => {
  return () => {};
};

export const updatePaymentMethods = () => {
  return () => {};
};
