import React from 'react';
import classNames from 'classnames';

import css from './InquiryWizardPanelHeading.css';

const InquiryWizardPanelHeading = ({ title, subtitle, className }) => {
  return (
    <div className={classNames(css.root, className)}>
      <h1 className={css.title}>{title}</h1>
      <p className={css.subtitle}>{subtitle}</p>
    </div>
  );
};

InquiryWizardPanelHeading.propTypes = {};

export default InquiryWizardPanelHeading;
