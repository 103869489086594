import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { string } from 'prop-types';
// import { LandtrustPlusModal } from '../../components';
// import EarlyAccessImage from '../../assets/early-access.webp';
import css from './ActivityFeed.css';
import FollowButtonSection from './FollowButtonSection';
import RenderActivities from './RenderActivities';
import { toast } from '../../components/Toaster/Toaster';

export const DEFAULT_ACTIVITY_FEED_LIMIT = 5;

export const toastListingFollow = () =>
  toast(
    <span>
      <FormattedMessage id="Toast.followListing" />
    </span>
  );
export const toastListingUnfollow = () =>
  toast(
    <span>
      <FormattedMessage id="Toast.unfollowListing" />
    </span>
  );

export const toastPropertyDontNotify = () =>
  toast(
    <span>
      <FormattedMessage id="Toast.dontNotify" />
    </span>
  );
export const toastPropertyNotifyMe = () =>
  toast(
    <span>
      <FormattedMessage id="Toast.notifyMe" />
    </span>
  );

const ActivityFeed = props => {
  const {
    rootClassName,
    isOwnListing,
    listing,
    currentPackage,
    isListing,
    fetchActivityFeedData,
    reviews,
    activityPackageFeedData,
    activityFeedData,
    followedSubjects,
    fetchFollowedSubjectsData,
    followSubjectMutation,
    unfollowSubjectMutation,
    updateFollowedSubjectMutation,
    currentUser,
  } = props;
  const [activityFeedPageOffset, setActivityFeedPageOffset] = useState(0);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [followingProperty, setFollowingProperty] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const userId = currentUser?.id?.uuid;
  const isPremium = currentUser?.attributes?.profile?.metadata?.membershipTier === 'premium';
  const classes = classNames(rootClassName, css.breakoutContainer);
  const followingConfig = followedSubjects?.find(subject => subject.subjectId === listing.id.uuid);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);
  const packageId = currentPackage?.id;

  const handleRedirect = () => {
    window.open('/login', '_blank');
  };

  const followProperty = () => {
    if (userId) {
      followSubjectMutation(userId, listing.id.uuid, { sms: true, email: true });
      setFollowingProperty(true);
      toastListingFollow(currentUser);
    } else {
      handleRedirect();
    }
  };

  const unfollowProperty = () => {
    const id = followingConfig?.id;
    unfollowSubjectMutation(id, userId);
    setFollowingProperty(false);
    setNotificationsEnabled(false);
    toastListingUnfollow(currentUser);
  };

  const notifyMeUpdate = spamMe => {
    const id = followingConfig?.id;
    updateFollowedSubjectMutation(id, { sms: spamMe, email: spamMe });
    setNotificationsEnabled(spamMe);
    if (spamMe) {
      toastPropertyNotifyMe(currentUser);
    } else {
      toastPropertyDontNotify(currentUser);
    }
  };

  useEffect(() => {
    if (window.location.hash === '#fieldnotes') {
      const element = document.getElementById('activityFeed');
      if (element) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: elementPosition,
            behavior: 'smooth',
          });
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    const limit = DEFAULT_ACTIVITY_FEED_LIMIT;
    if (isListing) {
      if (listing?.id?.uuid) {
        fetchActivityFeedData(
          listing?.id?.uuid,
          limit,
          activityFeedPageOffset * DEFAULT_ACTIVITY_FEED_LIMIT
        );
        fetchFollowedSubjectsData(userId);
      }
    } else if (packageId) {
      fetchActivityFeedData(packageId, limit, activityFeedPageOffset * DEFAULT_ACTIVITY_FEED_LIMIT);
      fetchFollowedSubjectsData(userId);
    }
  }, [
    fetchActivityFeedData,
    activityFeedPageOffset,
    packageId,
    isListing,
    listing?.id?.uuid,
    fetchFollowedSubjectsData,
    userId,
  ]);

  useEffect(() => {
    const propertyFollowed = followedSubjects?.find(
      subject => subject.subjectId === listing.id.uuid
    );
    setFollowingProperty(!!propertyFollowed);
    const recievingNotifications =
      propertyFollowed?.notifyBy?.sms || propertyFollowed?.notifyBy?.email;
    setNotificationsEnabled(!!recievingNotifications);
  }, [followedSubjects, listing.id.uuid]);

  const showMoreActivities = () => {
    setActivityFeedPageOffset(activityFeedPageOffset + 1);
  };

  const allListingActivities = activityFeedData?.items || [];
  const allPackageActivities = activityPackageFeedData?.items || [];
  const displayedActivities = isListing ? allListingActivities : allPackageActivities;
  const activityCount = isListing ? activityFeedData : activityPackageFeedData;

  // const handleSuccess = () => {
  //   window.open('/plus', '_blank');
  // };

  return (
    <div id="activityFeed">
      <div className={css.sectionContainer}>
        <div className={classes} />
        <div className={css.visitHeading}>
          <div className={css.descriptionHeader}>
            <FormattedMessage id="ListingPage.activityFeedTitle" />
          </div>
        </div>
        {displayedActivities.length < 1 ? (
          <>
            <div className={css.subHeadingEmptyState}>
              <FormattedMessage id="ListingPage.activityFeedEmptyHeader" />
            </div>
            <div className={css.subtitleEmptyState}>
              <FormattedMessage id="ListingPage.activityFeedEmptySubtitle" />
            </div>
          </>
        ) : (
          <div className={css.subHeading}>
            <FormattedMessage
              id="ListingPage.activityFeedSubtitle"
              values={{
                listing: <span className={css.displayName}>{listing.attributes.title}</span>,
              }}
            />
          </div>
        )}
        {!isOwnListing && (
          <div className={css.followButtonWrapper}>
            <FollowButtonSection
              followingProperty={followingProperty}
              notificationsEnabled={notificationsEnabled}
              isPremiumUser={isPremium}
              currentUser={currentUser}
              unfollowProperty={unfollowProperty}
              notifyMeUpdate={notifyMeUpdate}
              followProperty={followProperty}
              // openModal={openModal}
            />
          </div>
        )}
        <div className={css.content}>
          <RenderActivities
            allActivities={displayedActivities}
            listing={listing}
            isListing={isListing}
            currentPackage={currentPackage}
            reviews={reviews}
            notificationsEnabled={notificationsEnabled}
          />
          {activityCount?.totalRecordCount > displayedActivities?.length && (
            <button onClick={showMoreActivities} className={css.showMoreButton} type="button">
              <FormattedMessage id="ListingPage.activityFeedShowMore" />
            </button>
          )}
        </div>

        {/* {!isPremium && (
          <LandtrustPlusModal
            headerImage={EarlyAccessImage}
            isOpen={isModalOpen}
            onCloseModal={closeModal}
            header={
              <FormattedHTMLMessage
                id="EarlyAccessModal.activityFeedHeader"
                values={{
                  span: term => <span>{term}</span>,
                }}
              />
            }
            description={<FormattedMessage id="EarlyAccessModal.activityFeedDescription" />}
            items={[
              <FormattedMessage id="EarlyAccessModal.activityFeeditem1" />,
              <FormattedMessage id="EarlyAccessModal.activityFeeditem2" />,
              <FormattedMessage id="EarlyAccessModal.activityFeeditem3" />,
              <FormattedMessage id="EarlyAccessModal.activityFeeditem4" />,
            ]}
            onSuccess={handleSuccess}
          />
        )} */}
      </div>
    </div>
  );
};

ActivityFeed.defaultProps = {
  rootClassName: null,
};

ActivityFeed.propTypes = {
  rootClassName: string,
};

export default ActivityFeed;
