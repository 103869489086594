/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  txIsEnquired,
  txIsEnquiryExpired,
  getBookingDates,
  getTxState,
  getUserTxRole,
} from '../../util/transaction';
import { ensureTransaction } from '../../util/data';
import { dateFromAPIToLocalNoon, daysAdded, formatRelativeDate } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import { UserDisplayName, Avatar, NamedLink, ResponsiveImage } from '..';

import css from './InboxListItem.css';
import { getListingImages, getPackageImages } from '../../util/listing';
import { formatMoney } from '../../util/currency';
import InboxListItemBadge from './InboxListItemBadge';

const { Money } = sdkTypes;

const parseTransaction = (transaction, isOrder) => {
  const isEnquiry = txIsEnquired(transaction);
  const isEnquiryExpired = txIsEnquiryExpired(transaction);
  const { attributes, booking, listing, messages } = transaction;
  const { protectedData, lastTransitionedAt } = attributes || {};

  if (!protectedData || Object.keys(protectedData).length === 0) {
    return null;
  }

  let bookingDates;
  let startDateParsed;
  let endDateParsed;
  let isSingleDay;
  const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1] : null;

  let lastTransitionDate = moment(lastTransitionedAt);

  if (lastMessage) {
    const lastMessageDate = moment(lastMessage.attributes.createdAt);

    // Compare the dates and use the more recent one
    lastTransitionDate = lastMessageDate.isAfter(lastTransitionDate)
      ? lastMessageDate
      : lastTransitionDate;
  }

  if (booking) {
    bookingDates = getBookingDates(transaction);
  }

  if (isEnquiry || isEnquiryExpired) {
    const { selectedPackage, startDate, endDate, potentialEarnings } = protectedData || {};
    const { packageId } = selectedPackage;
    const packagesImages = packageId ? getPackageImages(listing, packageId) : null;
    const listingImage = getListingImages(listing)[0] || null;
    const firstImage = packagesImages && packagesImages.length ? packagesImages[0] : listingImage;

    const dayCount = daysAdded(moment(startDate), moment(endDate));
    isSingleDay = dayCount === 1;

    if (startDate && endDate) {
      const bookingStart = moment(dateFromAPIToLocalNoon(startDate));
      const bookingEnd = moment(dateFromAPIToLocalNoon(endDate));
      isSingleDay = daysAdded(bookingStart.utc().format(), bookingEnd.utc().format()) === 1;
      startDateParsed = bookingStart;
      endDateParsed = bookingEnd;
    } else if (bookingDates) {
      startDateParsed = bookingDates.bookingStart;
      endDateParsed = bookingDates.bookingEnd;
      isSingleDay = bookingDates.isSingleDay;
    }

    return {
      title: selectedPackage?.packageTitle,
      packagePrice: selectedPackage?.packagePrice,
      startDate: startDateParsed,
      endDate: endDateParsed,
      isSingleDay,
      price: new Money(potentialEarnings.amount * 100, potentialEarnings.currency),
      firstImage,
      lastTransitionDate,
    };
  }

  const { packageLineItem, selectedPackage } = protectedData || {};

  if (packageLineItem) {
    const { id: packageId } = packageLineItem;
    const packagesImages = packageId ? getPackageImages(listing, packageId) : null;
    const listingImage = getListingImages(listing)[0] || null;
    const firstImage = packagesImages && packagesImages.length ? packagesImages[0] : listingImage;

    if (bookingDates) {
      startDateParsed = bookingDates.bookingStart;
      endDateParsed = bookingDates.bookingEnd;
      isSingleDay = bookingDates.isSingleDay;
    }

    const bookingPrice = isOrder ? attributes.payinTotal : attributes.payoutTotal;

    return {
      title: packageLineItem?.title,
      packagePrice: packageLineItem?.price,
      startDate: startDateParsed,
      endDate: endDateParsed,
      isSingleDay,
      price: bookingPrice,
      firstImage,
      lastTransitionDate,
    };
  }

  if (selectedPackage) {
    const { packageId } = selectedPackage;
    const packagesImages = packageId ? getPackageImages(listing, packageId) : null;
    const listingImage = getListingImages(listing)[0] || null;
    const firstImage = packagesImages && packagesImages.length ? packagesImages[0] : listingImage;

    if (bookingDates) {
      startDateParsed = bookingDates.bookingStart;
      endDateParsed = bookingDates.bookingEnd;
      isSingleDay = bookingDates.isSingleDay;
    }

    const bookingPrice = isOrder ? attributes.payinTotal : attributes.payoutTotal;

    return {
      title: selectedPackage?.packageTitle,
      packagePrice: selectedPackage?.packagePrice,
      startDate: startDateParsed,
      endDate: endDateParsed,
      isSingleDay,
      price: bookingPrice,
      firstImage,
      lastTransitionDate,
    };
  }

  return null;
};

const InboxListItem = ({ transaction, params, intl, currentUser }) => {
  const { tab, id } = params;
  const { customer, provider } = transaction;
  const isOrder = tab === 'orders';
  const user = isOrder ? provider : customer;

  const currentTransaction = ensureTransaction(transaction);
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const parsedTransaction = parseTransaction(
    transaction,
    isOrder,
    currentUser?.id?.uuid,
    ownRole,
    intl
  );
  const transactionState = getTxState(intl, transaction, ownRole);

  if (!parsedTransaction) {
    return null;
  }

  const {
    title,
    startDate,
    endDate,
    isSingleDay,
    firstImage,
    lastTransitionDate,
    price,
  } = parsedTransaction;

  const dateInfo = isSingleDay
    ? startDate.format('ddd MMM DD')
    : `${startDate.format('ddd MMM DD')} - ${endDate.format('ddd MMM DD')}`;

  const lastTransitonDateInfo = formatRelativeDate(lastTransitionDate);
  const formattedPrice = price ? formatMoney(intl, price) : 0;
  const isWaitingForReply = false;

  const classes = classNames(css.root, {
    [css.rootSelected]: transaction.id.uuid === id,
  });

  const headerClass = classNames(css.itemHeader, {
    [css.itemHeaderBold]: isWaitingForReply,
  });

  const titleClass = classNames(css.itemTitle, {
    [css.itemTitleBold]: isWaitingForReply,
  });

  const handleEnterItem = transactionId => {
    try {
      const lastReadTimestamps = JSON.parse(localStorage.getItem('lastReadTimestamps')) || {};
      lastReadTimestamps[transactionId] = new Date().toISOString();
      localStorage.setItem('lastReadTimestamps', JSON.stringify(lastReadTimestamps));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error accessing localStorage:', error);
    }
  };

  return (
    <NamedLink
      className={classes}
      name="InboxV2Page"
      params={{
        tab,
        id: transaction.id.uuid,
      }}
      onClick={() => handleEnterItem(transaction.id.uuid)}
    >
      <div className={css.container}>
        <div className={css.imageWrapper}>
          <ResponsiveImage
            rootClassName={css.image}
            alt={title}
            image={firstImage}
            variants={['facebook', 'scaled-large']}
          />
          <div className={css.avatarContainer}>
            <Avatar user={user} disableProfileLink className={css.avatar} />
          </div>
        </div>

        <div className={css.itemContainer}>
          <div className={headerClass}>
            <UserDisplayName user={user} intl={intl} />
            <div className={css.itemHeaderDate}>{lastTransitonDateInfo}</div>
          </div>

          <div className={titleClass}>{title}</div>
          <div className={css.itemDate}>{dateInfo}</div>

          <div className={css.itemFooter}>
            <div className={css.itemStateLabel}>
              {transactionState.state} &bull; {formattedPrice}
            </div>
            <InboxListItemBadge transaction={transaction} user={currentUser} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

export default injectIntl(InboxListItem);
