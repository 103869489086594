import * as ui from './ui';
import * as auth from './auth';
import * as booking from './booking';
import * as listing from './listing';
import * as query from './query';
import * as idVerification from './id-verification';
import * as payment from './payment';
import * as transaction from './transaction';
import * as specialOffer from './special-offer';
import * as reviews from './reviews';

export default {
  ...ui,
  ...auth,
  ...booking,
  ...listing,
  ...query,
  ...idVerification,
  ...payment,
  ...transaction,
  ...specialOffer,
  ...reviews,
};
