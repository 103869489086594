import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { FieldDateRangeController, FilterPopup, FilterPlain, ModalHeadings } from '..';
import { ReactComponent as CalendarFilterIcon } from '../../assets/icons/calendar-filter.svg';
import css from './BookingDateRangeFilter.css';

export const BookingDateRangeFilterComponent = ({
  className,
  rootClassName,
  showAsPopup,
  initialValues: initialValuesRaw,
  id,
  contentPlacementOffset,
  onSubmit,
  urlParam,
  intl,
  onManageDisableScrolling,
  saveFilters,
  resetToSavedFilters,
  resultsCount,
  searchInProgress,
  ...rest
}) => {
  const isSelected = !!initialValuesRaw && !!initialValuesRaw.dates;
  const initialValues = isSelected ? initialValuesRaw : { dates: null };

  const startDate = isSelected ? initialValues.dates.startDate : null;
  const endDate = isSelected ? initialValues.dates.endDate : null;

  const format = {
    month: 'short',
    day: 'numeric',
  };

  const formattedStartDate = isSelected ? intl.formatDate(startDate, format) : null;
  const formattedEndDate = isSelected ? intl.formatDate(endDate, format) : null;

  const labelForPlain = isSelected
    ? intl.formatMessage(
        { id: 'BookingDateRangeFilter.labelSelectedPlain' },
        {
          dates: `${formattedStartDate} - ${formattedEndDate}`,
        }
      )
    : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPlain' });

  const labelForPopup = isSelected
    ? intl.formatMessage(
        { id: 'BookingDateRangeFilter.labelSelectedPopup' },
        {
          dates: `${formattedStartDate} - ${formattedEndDate}`,
        }
      )
    : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPopup' });

  const titles = (
    <ModalHeadings
      heading={intl.formatMessage({ id: 'BookingDateRangeFilter.modalHeading' })}
      subHeading={intl.formatMessage({ id: 'BookingDateRangeFilter.modalSubHeading' })}
    />
  );

  const onClear = () => onSubmit(urlParam);
  const onCancel = () => onSubmit(urlParam, { startDate, endDate });

  return showAsPopup ? (
    <FilterPopup
      className={className}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      label={labelForPopup}
      icon={<CalendarFilterIcon />}
      titles={titles}
      isSelected={isSelected}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={onSubmit}
      onClear={onClear}
      onCancel={onCancel}
      initialValues={initialValues}
      urlParam={urlParam}
      onManageDisableScrolling={onManageDisableScrolling}
      saveFilters={saveFilters}
      resetToSavedFilters={resetToSavedFilters}
      resultsCount={resultsCount}
      searchInProgress={searchInProgress}
      {...rest}
    >
      <FieldDateRangeController name="dates" isResponsive showClearDates={false} />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelForPlain}
      isSelected={isSelected}
      id={`${id}.plain`}
      liveEdit
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={onSubmit}
      onClear={onClear}
      onCancel={onCancel}
      initialValues={initialValues}
      urlParam={urlParam}
      {...rest}
    >
      <FieldDateRangeController name="dates" />
    </FilterPlain>
  );
};

BookingDateRangeFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
  onManageDisableScrolling: null,
  saveFilters: null,
  resetToSavedFilters: null,
};

BookingDateRangeFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  showAsPopup: bool,
  liveEdit: bool,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,
  onManageDisableScrolling: func,
  saveFilters: func,
  resetToSavedFilters: func,
  resultsCount: number.isRequired,
  searchInProgress: bool.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeFilter = injectIntl(BookingDateRangeFilterComponent);

export default BookingDateRangeFilter;
