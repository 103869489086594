import React from 'react';
import { injectIntl } from 'react-intl';
import { number } from 'prop-types';
import { FieldSelect } from '..';

const FieldPartySizeSelect = ({ intl, maxPartySize, minPartySize, price, ...props }) => {
  const options = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const hasPrice = price && price.amount;
  return (
    <FieldSelect {...props} defaultValue={minPartySize}>
      {options(minPartySize, maxPartySize, 1).map(v => (
        <option key={v} value={v}>
          {intl.formatMessage(
            {
              id: hasPrice
                ? 'FieldPartySizeSelect.guestOption'
                : 'FieldPartySizeSelect.diyGuestOption',
            },
            {
              guests: v,
            }
          )}
        </option>
      ))}
    </FieldSelect>
  );
};

FieldPartySizeSelect.propTypes = {
  maxPartySize: number.isRequired,
};

export default injectIntl(FieldPartySizeSelect);
