import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldDateRangeController,
  FieldCurrencyInput,
  FieldTextInput,
} from '../../components';
import SpecialOfferBreakdown from './SpecialOfferEstimatedBreakdown';
import * as validators from '../../util/validators';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './SpecialOfferForm.css';

const { Money } = sdkTypes;

const FieldDateRangeControllerMemoized = React.memo(
  ({ ...props }) => {
    return <FieldDateRangeController {...props} />;
  },
  () => {
    // NOTE: <FieldDateRangeController /> receives a large array of timeSlot props.
    // As other input change, it causes a re-render of the date picker, which is
    // very expensive and causes the browser to stall. With React.memo(), we can memoize
    // the component and prevent unnecessary renders. I think it's safe to do this
    // as the props passed to <FieldDateRangeController /> aren't changing on the fly
    // from what I can tell.
    return true;
  }
);

const SpecialOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        form,
        handleSubmit,
        rootClassName,
        className,
        inProgress,
        intl,
        invalid,
        timeSlots,
        sportsmanName,
        transaction,
        values,
      } = fieldRenderProps;

      const { specialOffer, specialOfferCalendar, offerMessage } = values;
      const { startDate, endDate } = values && specialOfferCalendar ? specialOfferCalendar : {};
      const unitType = config.bookingUnitType;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      // calendar validator messages
      const requiredMessage = intl.formatMessage({ id: 'SpecialOffer.requiredDate' });
      const startDateErrorMessage = intl.formatMessage({
        id: 'FieldDateRangeInput.invalidStartDate',
      });
      const endDateErrorMessage = intl.formatMessage({
        id: 'FieldDateRangeInput.invalidEndDate',
      });

      // prices and validators
      const pricePlaceholderMessage = intl.formatMessage({
        id: 'SpecialOffer.priceInputPlaceholder',
      });
      const specialOfferLabel = intl.formatMessage({
        id: 'SpecialOffer.inputLabel',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'SpecialOfferForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingAccessForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      // Description Validators and placeholders
      const titleMessage = intl.formatMessage({ id: 'SpecialOfferForm.offerDetails' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'SpecialOfferForm.offerDetailsPlaceHolder',
      });
      const requiredDetailsMessage = intl.formatMessage({ id: 'SpecialOffer.requiredDetails' });

      // This is the place to collect breakdown estimation data.
      const bookingData =
        startDate && endDate
          ? {
              specialOffer,
              startDate,
              endDate,
              quantity: 1,
              unitType,
              unitPrice: new Money(0, 'USD'),
              offerMessage,
            }
          : null;

      const bookingInfo = bookingData ? (
        <div className={css.priceBreakdownContainer}>
          <SpecialOfferBreakdown bookingData={bookingData} transaction={transaction} />
        </div>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.bookingDatesDiv}>
            <div className={css.bookingDatesLabel}>
              <FormattedMessage id="SpecialOfferForm.chooseDates" />
            </div>
            <FieldDateRangeControllerMemoized
              className={css.bookingDates}
              name="specialOfferCalendar"
              unitType={unitType}
              focusedInput={null}
              format={null}
              timeSlots={timeSlots}
              onChange={dates => {
                form.change('specialOfferCalendar', dates);
                form.change('dates', dates);
              }}
              validate={validators.composeValidators(
                validators.required(requiredMessage),
                validators.bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
              )}
              endDateOffset={undefined}
              minimumNights={0}
              maximumNights={365}
            />
          </div>
          <FieldCurrencyInput
            id="specialOfferPrice"
            name="specialOffer"
            className={css.priceInput}
            label={specialOfferLabel}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <FieldTextInput
            id="offerMessage"
            name="offerMessage"
            className={css.offerMessage}
            type="textarea"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            validate={validators.composeValidators(validators.required(requiredDetailsMessage))}
          />

          {bookingInfo}
          <div className={css.buttonContainer}>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              isFullWidth
            >
              <FormattedMessage id="SpecialOfferForm.submitButtonText" values={{ sportsmanName }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SpecialOfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
  onSubmit: null,
};

const { string, bool, func } = PropTypes;

SpecialOfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  onSubmit: func,
  formId: string,
};

const SpecialOfferForm = compose(injectIntl)(SpecialOfferFormComponent);
SpecialOfferForm.displayName = 'SpecialOfferForm';

export default SpecialOfferForm;
