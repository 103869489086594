import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  FieldCheckbox,
  FieldPhoneNumberInput,
  FieldTextInput,
  FieldToggle,
} from '../../../components';
import * as validators from '../../../util/validators';
import { ReactComponent as ShowPassword } from '../../../assets/icons/show-password.svg';
import { ReactComponent as HidePassword } from '../../../assets/icons/hide-password.svg';

import { profileActivities } from '../../../marketplace-custom-config';
import css from '../SignupForm.css';

const AccountDetails = props => {
  const { intl, formId, setAllSteps, showPassword, toggleShow, values, touched } = props;

  // firstName
  const firstNameLabel = intl.formatMessage({
    id: 'SignupForm.firstNameLabel',
  });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.firstNamePlaceholder',
  });
  const firstNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.firstNameRequired',
  });
  const firstNameRequired = validators.required(firstNameRequiredMessage);

  // lastName
  const lastNameLabel = intl.formatMessage({
    id: 'SignupForm.lastNameLabel',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.lastNamePlaceholder',
  });
  const lastNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.lastNameRequired',
  });
  const lastNameRequired = validators.required(lastNameRequiredMessage);

  // email
  const emailLabel = intl.formatMessage({
    id: 'SignupForm.emailLabel',
  });
  const emailPlaceholder = intl.formatMessage({
    id: 'SignupForm.emailPlaceholder',
  });
  const emailRequiredMessage = intl.formatMessage({
    id: 'SignupForm.emailRequired',
  });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'SignupForm.emailInvalid',
  });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  // phone number
  const phonePlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.phonePlaceholder',
  });
  const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
  const phoneNumberRequiredMessage = intl.formatMessage({
    id: 'SignupForm.phoneNumberRequired',
  });
  const phoneNumberRequired = validators.minLength(phoneNumberRequiredMessage, 10);

  // password
  const passwordLabel = intl.formatMessage({
    id: 'SignupForm.passwordLabel',
  });
  const passwordPlaceholder = intl.formatMessage({
    id: 'SignupForm.passwordPlaceholder',
  });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  const passwordValidationError =
    (values && !values.password && touched && touched.password === true) ||
    (values &&
      values.password &&
      Object.keys(values.password).length < 8 &&
      touched &&
      touched.password === true);

  // role
  const roleRequiredMessage = intl.formatMessage({
    id: 'SignupForm.roleRequired',
  });
  const rolesRequired = validators.nonEmptyArray(roleRequiredMessage);

  const smsConsentRequired = validators.required(
    intl.formatMessage({
      id: 'SignupFormAccountPreferences.smsConsentRequired',
    })
  );

  return (
    <div>
      <div className={css.name}>
        <FieldTextInput
          data-testId="name-field"
          className={css.firstNameRoot}
          type="text"
          id={formId ? `${formId}.fname` : 'fname'}
          name="fname"
          autoComplete="given-name"
          label={firstNameLabel}
          placeholder={firstNamePlaceholder}
          validate={firstNameRequired}
        />

        <FieldTextInput
          data-testId="last-name-field"
          className={css.lastNameRoot}
          type="text"
          id={formId ? `${formId}.lname` : 'lname'}
          name="lname"
          autoComplete="family-name"
          label={lastNameLabel}
          placeholder={lastNamePlaceholder}
          validate={lastNameRequired}
        />
      </div>

      <FieldTextInput
        data-testId="email-field"
        className={css.email}
        type="email"
        id={formId ? `${formId}.email` : 'email'}
        name="email"
        autoComplete="email"
        label={emailLabel}
        placeholder={emailPlaceholder}
        validate={validators.composeValidators(emailRequired, emailValid)}
      />
      <div className={css.inputGroup}>
        {showPassword ? (
          <HidePassword
            className={passwordValidationError ? css.passwordIconError : css.passwordIcon}
            onClick={() => toggleShow()}
          />
        ) : (
          <ShowPassword
            className={passwordValidationError ? css.passwordIconError : css.passwordIcon}
            onClick={() => toggleShow()}
          />
        )}
        <FieldTextInput
          data-testId="password-field"
          className={css.password}
          type={showPassword ? 'text' : 'password'}
          id={formId ? `${formId}.password` : 'password'}
          name="password"
          autoComplete="new-password"
          label={passwordLabel}
          placeholder={passwordPlaceholder}
          validate={passwordValidators}
        />
      </div>
      <FieldPhoneNumberInput
        data-testId="phone-number-field"
        className={css.phone}
        name="phoneNumber"
        id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
        label={phoneLabel}
        placeholder={phonePlaceholder}
        validate={phoneNumberRequired}
      />
      <div data-testId="sms-consent-component" className={css.rolesSection}>
        <div className={css.fieldLabel}>
          <FormattedMessage id="SignupFormAccountPreferences.smsConsent" />
        </div>

        <FieldToggle name="smsConsent" validate={smsConsentRequired} />
      </div>
      <div data-testId="roles-component" className={css.rolesSection}>
        <div className={css.fieldLabel}>
          <FormattedMessage id="ContactDetailsForm.roleLabel" />
        </div>

        <FieldCheckbox
          labelClassName={css.rolesSportsman}
          data-testId="role-sportsman"
          id="role-sportsman"
          name="role"
          value="sportsman"
          label={intl.formatMessage({
            id: 'ContactDetailsForm.sportsmanLabelExtended',
          })}
          validate={rolesRequired}
          onClick={e => {
            setAllSteps(e.target.checked ? 2 : 1);
          }}
        />
        <FieldCheckbox
          data-testId="role-landowner"
          id="role-landowner"
          name="role"
          value="landowner"
          label={intl.formatMessage({
            id: 'ContactDetailsForm.landownerLabelExtended',
          })}
          validate={rolesRequired}
        />
      </div>

      {values?.role?.includes('sportsman') && (
        <div className={css.activitiesSection}>
          <div className={css.fieldLabel}>
            <FormattedMessage id="MyInterestsForm.interestedActivitiesLabel" />
          </div>

          <div data-testId="activities-component" className={css.activityCheckboxes}>
            {Object.keys(profileActivities).map(key => (
              <FieldCheckbox
                data-testId={`${key}-activity-checkbox`}
                key={`activity.${key}`}
                id={`activity.${key}`}
                name="interestedActivities"
                value={key}
                label={profileActivities[key].label}
                labelClassName={css.fieldCheckboxLabel}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
