import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import {
  FieldCheckbox,
  FieldMultipleLocationInput,
  LocationAutocompleteInputField,
  FieldSelect,
  FieldTextInput,
} from '../../../components';
import { signupSpecies, referralMethodMap } from '../../../marketplace-custom-config';
import * as validators from '../../../util/validators';
import { getState, reverseGeocode, getCoords, userLocation } from '../../../util/maps';
import { types as sdkTypes } from '../../../util/sdkLoader';
import css from '../SignupForm.css';

const { LatLng } = sdkTypes;

const AccountPreferences = props => {
  const { values, intl, change } = props;

  // home state
  const usStateLabel = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStateLabel',
  });

  const usStatePlaceholder = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStatePlaceholder',
  });

  const usStateRequiredMessage = intl.formatMessage({
    id: 'SignupFormAccountPreferences.usStateRequired',
  });

  const usStateRequired = validators.autocompletePlaceSelected(usStateRequiredMessage);

  // preferred state
  const preferredStatesLabel = intl.formatMessage({
    id: 'SignupFormAccountPreferences.preferredStatesLabel',
  });
  const preferredStatesPlaceholder = intl.formatMessage({
    id: 'SignupFormAccountPreferences.preferredStatesPlaceholder',
  });

  const preferredStatesRequiredMessage = intl.formatMessage({
    id: 'SignupFormAccountPreferences.preferredStatesRequired',
  });
  const preferredStatesRequired = validators.minLength(preferredStatesRequiredMessage, 1);

  useEffect(() => {
    const resolveLocation = async () => {
      const coords = await userLocation();
      return reverseGeocode({ lat: coords.lat, lng: coords.lng });
    };

    resolveLocation().then(res => {
      const state = getState(res);

      if (state) {
        const coords = getCoords(res);

        change('usState', {
          search: state,
          selectedPlace: {
            address: state,
            origin: new LatLng(coords.lat, coords.long),
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hash } = window.location;
      if (hash === '#worlddeerexpo') {
        change('referralMethod', 'world_deer_expo');
      }
    }
  }, [change]);

  // referral method
  const referralMethodRequiredMessage = intl.formatMessage({
    id: 'SignupForm.referralMethodRequired',
  });
  const referralMethodRequired = validators.required(referralMethodRequiredMessage);

  const referralMethodOtherRequiredMessage = intl.formatMessage({
    id: 'ContactDetailsForm.otherErrorMessage',
  });

  const referralMethodOtherRequired = validators.required(referralMethodOtherRequiredMessage);

  const otherOption = values.referralMethod === 'other';

  const otherLabel = intl.formatMessage({
    id: 'ContactDetailsForm.otherLabel',
  });
  const otherPlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.otherPlaceholder',
  });

  return (
    <>
      <h1 className={css.formHeader}>
        <FormattedMessage id="AuthenticationPage.confirmOneLastStep" />
      </h1>

      <p className={css.formSubHeader}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>

      <div className={css.formSection}>
        <LocationAutocompleteInputField
          name="usState"
          label={usStateLabel}
          placeholder={usStatePlaceholder}
          useDefaultPredictions={false}
          format={null}
          valueFromForm={values.usState}
          showButton={false}
          showIcon={false}
          validate={usStateRequired}
        />
      </div>

      <div className={css.formSection}>
        <FieldMultipleLocationInput
          name="preferredHuntingStates"
          label={preferredStatesLabel}
          placeholder={preferredStatesPlaceholder}
          useDefaultPredictions={false}
          valueFromForm={values.preferredHuntingStates}
          showButton={false}
          showIcon={false}
          validate={preferredStatesRequired}
        />
      </div>

      {values?.interestedActivities.includes('hunt') && (
        <div className={css.formSection}>
          <div className={css.fieldLabel}>
            <FormattedMessage id="ContactDetailsForm.speciesCheck" />
          </div>
          <div className={css.huntSpeciesContainer}>
            {Object.keys(signupSpecies.hunt).map(key => {
              return (
                <div key={`species.${key}`} className={css.huntSpeciesItem}>
                  <FieldCheckbox
                    id={`species.${key}`}
                    name="preferredHuntingSpecies"
                    label={signupSpecies.hunt[key].label}
                    speciesKey={key}
                    value={key}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div>
        <div className={css.fieldLabel}>
          <FormattedMessage id="ContactDetailsForm.hearAboutUs" />
        </div>
        <FieldSelect
          data-testId="referral-select"
          selectClassName={css.referralSelect}
          id="referral-select"
          name="referralMethod"
          validate={referralMethodRequired}
        >
          <option className={css.referralSelectOption} disabled value="">
            {intl.formatMessage({
              id: 'ContactDetailsForm.referralSelect',
            })}
          </option>
          {referralMethodMap.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
        {otherOption ? (
          <FieldTextInput
            type="textarea"
            className={css.referralOther}
            id="referral-input"
            name="referralOther"
            label={otherLabel}
            placeholder={otherPlaceholder}
            validate={referralMethodOtherRequired}
            rows={3}
          />
        ) : null}
      </div>
    </>
  );
};

export default AccountPreferences;
