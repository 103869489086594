import React, { useState } from 'react';
import { node } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';

import css from './LessMore.css';
import { InlineTextButton } from '..';

const LessMore = ({ children, intl }) => {
  const [visible, toggle] = useState(false);

  return (
    <>
      <InlineTextButton rootClassName={css.button} type="button" onClick={() => toggle(!visible)}>
        <span>{intl.formatMessage({ id: visible ? 'LessMore.less' : 'LessMore.more' })}</span>

        {visible ? <ChevronUp /> : <ChevronDown />}
      </InlineTextButton>

      {visible && children}
    </>
  );
};

LessMore.propTypes = {
  children: node.isRequired,
  intl: intlShape.isRequired,
};

LessMore.defaultProps = {};

export default injectIntl(LessMore);
