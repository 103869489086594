/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import SectionLodgingOptions from './SectionLodgingOptions';
import css from './SectionLodgingItems.css';
import { getCampsites, getLodging, getLodgingPricesOptions, getPackage } from '../../util/listing';
import { LODGING_INFO } from '../../util/package';

const SectionLodgingItems = ({ currentListing, intl, params: rawParams }) => {
  const campsites = getCampsites(currentListing);
  const lodging = getLodging(currentListing);

  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);
  const currentPackage = getPackage(currentListing, rawParams.packageId);

  const includedLodgingMerged = [];
  const optionalLodgingMerged = [];

  const { lodgingPrices = [] } = currentPackage || {};

  lodgingPrices.forEach((item, index) => {
    if (item.option === LODGING_INFO.REQUIRED) {
      includedLodgingMerged.push({ ...item, ...lodgingPricesOptions[index] });
    }
  });

  lodgingPrices.forEach((item, index) => {
    if (item.option === LODGING_INFO.OPTIONAL) {
      optionalLodgingMerged.push({ ...item, ...lodgingPricesOptions[index] });
    }
  });

  const mergedLodgingPrices = [...includedLodgingMerged, ...optionalLodgingMerged];
  const optionalLodgingPrices = mergedLodgingPrices.filter(
    item => item.option === LODGING_INFO.OPTIONAL
  );
  return (
    optionalLodgingPrices.length > 0 && (
      <div className={css.root}>
        <div className={css.detailsGroupsContainer}>
          <div className={css.detailsGroup}>
            <div>
              <>
                <h3 className={css.header}>
                  <FormattedMessage id="ListingPage.addonsTitle" />
                </h3>
                <SectionLodgingOptions
                  campsites={campsites}
                  lodging={lodging}
                  lodgingPricesOptions={optionalLodgingPrices}
                  showHeader={false}
                  showPrice
                />
              </>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default injectIntl(SectionLodgingItems);
