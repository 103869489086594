/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { object, func } from 'prop-types';

import css from './LineItems.css';

const LineItemLodgingFee = props => {
  const { itemInfo, onClick } = props;
  const { lineItem, lodgingFeeLabel, formattedLodgingFeeTotal } = itemInfo;
  return (
    <div key={`${lineItem.code}`} className={css.lineItem}>
      <span className={css.itemLabel} onClick={onClick} role="button" tabIndex="0">
        {lodgingFeeLabel}
      </span>
      <span className={css.itemValue}>{formattedLodgingFeeTotal}</span>
    </div>
  );
};

LineItemLodgingFee.propTypes = {
  itemInfo: object.isRequired,
  onClick: func.isRequired,
};

export default LineItemLodgingFee;
