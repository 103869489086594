import React from 'react';
import classnames from 'classnames';
import { ResponsiveImage } from '..';
import css from './ActivityCard.css';

const ActivityCard = ({
  title,
  image,
  className,
  onSubmit,
  listing,
  sectionPackagesTitle,
  activity,
  isActive,
  defaultImage,
}) => {
  const handleSubmit = values => {
    onSubmit(values);
  };

  return (
    isActive && (
      <div className={css.container}>
        <button
          className={classnames(css.root, className)}
          type="button"
          onClick={() =>
            handleSubmit({
              activity,
              listing,
              sectionPackagesTitle: { sectionPackagesTitle },
              showDefaultPackages: { Infinity },
            })
          }
        >
          <div className={css.imageContainer}>
            {image ? (
              <ResponsiveImage
                rootClassName={css.image}
                alt={title}
                image={image}
                variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
              />
            ) : (
              <img src={defaultImage} alt={title} className={css.image} />
            )}
          </div>
        </button>
        <button
          className={classnames(css.root, className)}
          type="button"
          onClick={() =>
            handleSubmit({
              activity,
              listing,
              sectionPackagesTitle: { sectionPackagesTitle },
              showDefaultPackages: { Infinity },
            })
          }
        >
          <div className={css.title}>{title}</div>
        </button>
      </div>
    )
  );
};

ActivityCard.propTypes = {};

export default ActivityCard;
