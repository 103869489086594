import React, { useState } from 'react';
import { get } from 'lodash';
import { shape, string, object, func, number, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { withRouter, Switch, Route } from 'react-router-dom';
import format from 'date-fns/format';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  Tabs,
  Tab,
  AccountDetails,
  PayoutDetails,
  MyInterests,
  PaymentMethods,
  PasswordUpdate,
  CommissionUpdate,
  AvatarLarge,
} from '../../components';
import { UpdateAvatarForm } from '../../forms';
import { Container } from '../../components/Layout';
import LayoutProfile from '../../components/LayoutProfile/LayoutProfile';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isFeatureFlagEnabled } from '../../util/localStorage';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { TopbarContainer } from '..';
import {
  updateProfile,
  uploadImage,
  deleteImage,
  saveContactDetails,
  saveContactDetailsClear,
  changePassword,
} from './ProfileSettingsPage.duck';
import config from '../../config';

import css from './ProfileSettingsPage.css';

const tabs = [
  { id: 'account-details', title: 'Account Details' },
  { id: 'payout-details', title: 'Payout Details' },
  { id: 'my-interests', title: 'My Interests' },
  { id: 'payment-methods', title: 'Billing & Membership' },
  { id: 'change-password', title: 'Change Password' },
];

const commissionRateEnabled = isFeatureFlagEnabled(config.featureFlags.COMMISSION_RATE);

if (commissionRateEnabled) {
  tabs.push({ id: 'commission-rate', title: 'Commission Rate' });
}

const redirectToRoute = (routeName, params, history) => {
  const routes = routeConfiguration();
  const to = createResourceLocatorString(routeName, routes, params, {});
  history.push(to);
};

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export const ProfileSettingsPage = ({
  scrollingDisabled,
  intl,
  currentUser,
  currentUserCompletedOrdersCount,
  updateInProgress,
  history,
  onUpdateProfile,
  onSubmitContactDetails,
  onResendVerificationEmail,
  onContactDetailsClear,
  onSubmitPasswordChange,
  image,
  onImageDelete,
  onImageUpload,
  uploadImageError,
  uploadInProgress,
  params,
}) => {
  const [activeTab, setActiveTab] = useState(params.tab);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [isCropperModalOpen, setCropperModalOpen] = useState(false);
  const [showCropper, setShowCropper] = useState(false);

  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

  const user = ensureCurrentUser(currentUser);

  if (!user.id) {
    return null;
  }

  const { profile, createdAt } = user.attributes;
  const { displayName } = profile;

  // Filter tabs based on user role
  const userTabs = tabs.filter(tab => {
    const role = get(profile, 'protectedData.role', []);

    if (tab.id === 'payout-details' && !role.includes('landowner')) {
      return false;
    }
    if (tab.id === 'commission-rate' && !role.includes('landowner')) {
      return false;
    }

    return true;
  });

  const handleTabChange = id => {
    setActiveTab(id);
    history.push(`/profile-settings/${id}`);
  };

  const handleMyInterestsSubmit = async data => {
    await onUpdateProfile(data);

    redirectToRoute(
      'ProfilePage',
      {
        id: user && user.id && user.id.uuid,
      },
      history
    );
  };

  const profileImageId = user.profileImage ? user.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };
  const hasProfileImage = profileImage.imageId;

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProfileSettingsPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <Container>
            <LayoutProfile
              infoComponent={
                <>
                  <div className={css.profileInfoContainer}>
                    <div className={css.profileFlexContainer}>
                      {!hasProfileImage ? (
                        <div className={css.avatarContainer}>
                          <AvatarLarge className={css.avatar} user={currentUser} />
                        </div>
                      ) : (
                        <UpdateAvatarForm
                          currentUser={currentUser}
                          isPhotoModalOpen={isPhotoModalOpen}
                          setIsPhotoModalOpen={setIsPhotoModalOpen}
                          isCropperModalOpen={isCropperModalOpen}
                          setCropperModalOpen={setCropperModalOpen}
                          showCropper={showCropper}
                          setShowCropper={setShowCropper}
                          profileImage={profileImage}
                          onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
                          onImageDelete={onImageDelete}
                          uploadInProgress={uploadInProgress}
                          updateInProgress={updateInProgress}
                          uploadImageError={uploadImageError}
                        />
                      )}
                      <div className={css.profileInfo}>
                        <div className={css.profileName}>{displayName}.</div>

                        <div className={css.profileLink}>
                          {user.id ? (
                            <NamedLink
                              className={css.profileLink}
                              name="ProfilePage"
                              params={{ id: user.id.uuid }}
                            >
                              <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                            </NamedLink>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {!hasProfileImage && (
                      <div className={css.profileFlexContainer}>
                        <UpdateAvatarForm
                          currentUser={currentUser}
                          isPhotoModalOpen={isPhotoModalOpen}
                          setIsPhotoModalOpen={setIsPhotoModalOpen}
                          isCropperModalOpen={isCropperModalOpen}
                          setCropperModalOpen={setCropperModalOpen}
                          showCropper={showCropper}
                          setShowCropper={setShowCropper}
                          profileImage={profileImage}
                          onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
                          onImageDelete={onImageDelete}
                          uploadInProgress={uploadInProgress}
                          updateInProgress={updateInProgress}
                          uploadImageError={uploadImageError}
                          rootClassName={css.uploadButton}
                        />
                      </div>
                    )}
                  </div>
                  {!hasProfileImage && (
                    <div className={css.profilePictureText}>
                      <FormattedMessage id="ProfileSettingsPage.uploadProfilePicture" />
                    </div>
                  )}
                  <div className={css.badgeContainer}>
                    <div className={css.badge}>
                      <div className={css.badgeHeader}>
                        <FormattedMessage id="ProfileSettingsPage.dateJoined" />
                      </div>
                      <div className={css.badgeContent}>{format(createdAt, 'MMM DD, YYYY')}</div>
                    </div>

                    <div className={css.badge}>
                      <div className={css.badgeHeader}>
                        <FormattedMessage id="ProfileSettingsPage.myTrips" />
                      </div>
                      <div className={css.badgeContent}>{currentUserCompletedOrdersCount}</div>
                    </div>
                  </div>
                  <div className={css.desktopTabs}>
                    <Tabs className={css.desktopTab}>
                      {userTabs.map(tab => (
                        <Tab
                          key={tab.id}
                          handleClick={() => handleTabChange(tab.id)}
                          isSelected={activeTab === tab.id}
                          isVertical
                        >
                          {tab.title}
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </>
              }
              mainComponent={
                <>
                  <div className={css.mobileTabs}>
                    <Tabs>
                      {userTabs.map(tab => (
                        <Tab
                          key={tab.id}
                          handleClick={() => handleTabChange(tab.id)}
                          isSelected={activeTab === tab.id}
                        >
                          {tab.title}
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                  <div className={css.tabContent}>
                    <Switch>
                      <Route exact path="/profile-settings/account-details">
                        <AccountDetails
                          user={user}
                          onSubmit={onSubmitContactDetails}
                          onResendVerificationEmail={onResendVerificationEmail}
                          onContactDetailsClear={onContactDetailsClear}
                        />
                      </Route>

                      <Route path="/profile-settings/payout-details">
                        <PayoutDetails params={params} />
                      </Route>

                      <Route exact path="/profile-settings/my-interests">
                        <MyInterests
                          user={user}
                          updateInProgress={updateInProgress}
                          onSubmit={handleMyInterestsSubmit}
                        />
                      </Route>

                      <Route exact path="/profile-settings/payment-methods">
                        <PaymentMethods />
                      </Route>

                      <Route exact path="/profile-settings/change-password">
                        <PasswordUpdate currentUser={user} onSubmit={onSubmitPasswordChange} />
                      </Route>
                      {commissionRateEnabled && (
                        <Route exact path="/profile-settings/commission-rate">
                          <CommissionUpdate
                            currentUser={user}
                            onSubmit={onUpdateProfile}
                            updateInProgress={updateInProgress}
                          />
                        </Route>
                      )}
                    </Switch>
                  </div>
                </>
              }
            />
          </Container>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ProfileSettingsPage.defaultProps = {
  scrollingDisabled: false,
  currentUser: null,
  currentUserCompletedOrdersCount: 0,
  updateInProgress: false,
  onUpdateProfile: null,
  history: null,
  onSubmitContactDetails: null,
  onResendVerificationEmail: null,
  onContactDetailsClear: null,
  image: null,
  onImageUpload: null,
  uploadImageError: null,
  uploadInProgress: false,
};

ProfileSettingsPage.propTypes = {
  scrollingDisabled: bool,
  intl: intlShape.isRequired,
  currentUser: propTypes.currentUser,
  currentUserCompletedOrdersCount: number,
  updateInProgress: bool,
  history: object,
  onUpdateProfile: func,
  onSubmitContactDetails: func,
  onResendVerificationEmail: func,
  onContactDetailsClear: func,
  image: object,
  onImageUpload: func,
  uploadImageError: propTypes.error,
  uploadInProgress: bool,
  params: shape({
    tab: string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserCompletedOrdersCount } = state.user;

  const { updateInProgress, image, uploadImageError, uploadInProgress } = state.ProfileSettingsPage;

  return {
    currentUser,
    currentUserCompletedOrdersCount,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    image,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onImageDelete: () => dispatch(deleteImage()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onContactDetailsClear: () => dispatch(saveContactDetailsClear()),
  onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitPasswordChange: values => dispatch(changePassword(values)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(withRouter(ProfileSettingsPage));
