import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { QuaternaryButton } from '..';

const EarlyAccessButton = ({ setIsModalOpen }) => {
  return (
    <>
      <QuaternaryButton
        isFullWidth
        isBold
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <FormattedMessage id="PackagePricingPanel.getAccessNow" />
      </QuaternaryButton>
    </>
  );
};

EarlyAccessButton.propTypes = {};

export default injectIntl(EarlyAccessButton);
