import React from 'react';
import { bool, object, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ReactComponent as IconCheck } from '../../assets/icons/simple-check.svg';
import { ReactComponent as IconWarning } from '../../assets/icons/warning.svg';

import {
  EditListingActions,
  EditListingFieldWrapper,
  EditListingSection,
  PrimaryButton,
  Form,
  NamedLink,
} from '../../components';

import css from './EditListingSummaryForm.css';

export const EditListingSummaryFormComponent = ({
  listing,
  saveActionMsg,
  updateInProgress: submitInProgress,
  listingItems,
  canBePublished,
  isDraft,
  ...formProps
}) => {
  const { slug, id, type } = listing;
  let subtitle;

  if (canBePublished) {
    subtitle = <FormattedMessage id="EditListingSummaryForm.subTitleCanPublish" />;
  } else {
    subtitle = <FormattedMessage id="EditListingSummaryForm.subTitleCannotPublish" />;
  }

  return (
    <FinalForm
      {...formProps}
      initialValues={{}}
      render={({ className, handleSubmit, invalid }) => {
        const classes = classNames(css.root, className);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection
              title={
                isDraft && (
                  <span>
                    {subtitle}{' '}
                    <NamedLink
                      name="ListingPageVariant"
                      params={{ id, slug, variant: type }}
                      target="_blank"
                      className={css.previewLink}
                    >
                      <FormattedMessage id="EditListingSummaryForm.previewListing" />
                    </NamedLink>
                  </span>
                )
              }
              hasBottomMargin
            >
              <EditListingFieldWrapper className={css.listing}>
                {listingItems.map(item => (
                  <div className={css.listingItem} key={`tab-${item.label}`}>
                    <NamedLink
                      className={css.link}
                      name="EditListingPage"
                      params={{ id, slug, type, tab: item.label }}
                    >
                      <span className={css.iconWrapper}>
                        {item.isValid ? (
                          <IconCheck className={css.icon} />
                        ) : (
                          <IconWarning className={css.icon} />
                        )}
                      </span>

                      <span className={css.linkLabel}>
                        <FormattedMessage id={`EditListingSummaryForm.${item.label}`} />
                      </span>
                    </NamedLink>
                  </div>
                ))}
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingActions>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={!canBePublished || invalid}
              >
                {saveActionMsg}
              </PrimaryButton>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingSummaryFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingSummaryFormComponent.propTypes = {
  intl: intlShape.isRequired,
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  listingItems: array.isRequired,
  listing: object.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  canBePublished: bool.isRequired,
  onSubmit: func.isRequired,
  isDraft: bool.isRequired,
};

export default compose(injectIntl)(EditListingSummaryFormComponent);
