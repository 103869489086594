import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { format } from 'date-fns';
import { bool, func } from 'prop-types';
import Logo from '../../assets/logo-landtrust-plus-v-2-1.webp';
import { InlineTextButton, QuaternaryButton, CancelMembershipModal } from '..';
import { propTypes } from '../../util/types';
import css from './YourMembership.css';
import { getMembershipDetails, hasPremiumMembership } from '../../util/user';
import config from '../../config';

const MEMBERSHIP_STATUS = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
};

const YourMembership = ({ currentUser, onCancel, cancelInProgress }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const premiumMembershipEnabled = useMemo(() => hasPremiumMembership(currentUser), [currentUser]);
  const membershipDetails = useMemo(() => getMembershipDetails(currentUser), [currentUser]);
  const [subscription, setSubscription] = useState(get(membershipDetails, 'subscription', null));

  const endDateObject = subscription ? new Date(subscription.endDate * 1000) : null;

  const formattedEndDate = endDateObject ? format(endDateObject, 'MMMM DD, YYYY') : null;
  const isMembershipActive = subscription
    ? subscription.status === MEMBERSHIP_STATUS.ACTIVE
    : false;
  const isMembershipCanceled = subscription
    ? subscription.status === MEMBERSHIP_STATUS.CANCELED
    : false;

  const handleOpenSalesPage = () => {
    window.location.href = premiumMembershipEnabled
      ? config.landtrustPlusBenefitsUrl
      : config.landtrustPlusUrl;
  };

  const handleCancel = async () => {
    try {
      setError(null);

      await onCancel();

      // optimistically update the subscription status
      setSubscription({ ...subscription, status: MEMBERSHIP_STATUS.CANCELED });
      setIsCancelModalOpen(false);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className={css.root}>
      <CancelMembershipModal
        id="CancelMembershipModal"
        isOpen={isCancelModalOpen}
        onCloseModal={() => setIsCancelModalOpen(false)}
        onCancel={handleCancel}
        endDate={formattedEndDate}
        inProgress={cancelInProgress}
        error={error}
      />
      <div className={css.banner}>
        <div>
          <img src={Logo} alt="Landtrust+" className={css.bannerLogoImage} />
        </div>

        <div className={css.bannerDescription}>
          <FormattedMessage id="PaymentMethods.bannerDescription" />
        </div>

        <QuaternaryButton type="button" isBold onClick={handleOpenSalesPage}>
          {premiumMembershipEnabled ? (
            <FormattedMessage id="PaymentMethods.viewBenefits" />
          ) : (
            <FormattedMessage id="PaymentMethods.learnMore" />
          )}
        </QuaternaryButton>
      </div>

      {premiumMembershipEnabled && membershipDetails && (
        <div className={css.membershipDetailsWrapper}>
          <h3 className={css.membershipHeader}>
            <FormattedMessage id="PaymentMethods.yourMembership" />
          </h3>

          <div className={css.membershipDetails}>
            <div className={css.membershipTypeWrapper}>
              <div className={css.membershipType}>LandTrust+ Annual</div>
              <div className={css.membershipPrice}>${config.landtrustPlusPrice}/year</div>
            </div>

            <div className={css.membershipAction}>
              {isMembershipActive && (
                <InlineTextButton
                  type="button"
                  className={css.membershipActionButton}
                  onClick={() => {
                    setIsCancelModalOpen(true);
                  }}
                >
                  <FormattedMessage id="PaymentMethods.cancelMembership" />
                </InlineTextButton>
              )}
            </div>
          </div>

          <div className={css.membershipStatusWrapper}>
            {isMembershipActive && (
              <FormattedMessage
                id="PaymentMethods.membershipStatusActive"
                values={{
                  date: formattedEndDate,
                }}
              />
            )}

            {isMembershipCanceled && (
              <FormattedMessage
                id="PaymentMethods.membershipStatusCanceled"
                values={{
                  date: formattedEndDate,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

YourMembership.defaultProps = {
  currentUser: null,
};
YourMembership.propTypes = {
  currentUser: propTypes.currentUser,
  onCancel: func.isRequired,
  cancelInProgress: bool.isRequired,
};
YourMembership.displayName = 'YourMembership';

export default YourMembership;
