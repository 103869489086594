import React from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { PHOTO_LIBRARY_TABS } from '../../util/photoLibrary';
import { isUploadImageOverLimitError } from '../../util/errors';
import { HUNT_ACTIVITY, OUTDOOR_RECREATION_ACTIVITY } from '../../marketplace-custom-config';
import { requestImageUpload as requestImageUploadAction } from '../../containers/EditListingPage/EditListingPage.duck';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
  FieldCheckbox,
  FieldCheckboxList,
  FieldCheckboxListItem,
  FieldWysiwyg,
} from '../../components';

import MultiPhotoUploadField from '../EditListingPhotoLibraryForm/EditListingPhotoLibraryMultiUploadField';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = ({ backLink, ...formProps }) => (
  <FinalForm
    {...formProps}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        requestImageUpload,
        values,
      } = fieldRenderProps;

      const { uploadImageError } = fetchErrors || {};
      const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

      let uploadImageFailed = null;

      if (uploadOverLimit) {
        uploadImageFailed = (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
          </p>
        );
      } else if (uploadImageError) {
        uploadImageFailed = (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
          </p>
        );
      }

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { tags, imageCaptions } = values;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <EditListingSection>
            <EditListingError fetchErrors={fetchErrors} />

            <EditListingFieldWrapper>
              <FieldTextInput
                autoComplete="off"
                id="title"
                name="title"
                type="text"
                label={intl.formatMessage({ id: 'EditListingDescriptionForm.title' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingDescriptionForm.titlePlaceholder',
                })}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingDescriptionForm.titleRequired',
                    })
                  ),
                  maxLength(
                    intl.formatMessage(
                      { id: 'EditListingDescriptionForm.maxLength' },
                      {
                        maxLength: TITLE_MAX_LENGTH,
                      }
                    ),
                    TITLE_MAX_LENGTH
                  )
                )}
                autoFocus
                protip={intl.formatMessage({
                  id: 'EditListingDescriptionForm.titleProtip',
                })}
              />
            </EditListingFieldWrapper>

            <EditListingFieldWrapper>
              <FieldWysiwyg
                id="description"
                name="description"
                label={intl.formatMessage({
                  id: 'EditListingDescriptionForm.description',
                })}
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingDescriptionForm.descriptionRequired',
                    })
                  )
                )}
                protip={intl.formatMessage({
                  id: 'EditListingDescriptionForm.descriptionProtip',
                })}
              />
            </EditListingFieldWrapper>

            <EditListingFieldWrapper>
              <FieldCheckboxList
                heading={intl.formatMessage({
                  id: 'EditListingLocationForm.categoryHeading',
                })}
                subHeading={intl.formatMessage({
                  id: 'EditListingLocationForm.categorySubheading',
                })}
                className={css.categoriesList}
              >
                <FieldCheckboxListItem className={css.categoriesListItem}>
                  <FieldCheckbox
                    id="category-hunt"
                    name="categories"
                    value={HUNT_ACTIVITY}
                    label={intl.formatMessage({
                      id: 'EditListingDescriptionForm.huntLabel',
                    })}
                    validate={composeValidators(requiredFieldArrayCheckbox('one box is required'))}
                  />
                </FieldCheckboxListItem>

                <FieldCheckboxListItem className={css.categoriesListItem}>
                  <FieldCheckbox
                    id="category-outdoor-recreation"
                    name="categories"
                    value={OUTDOOR_RECREATION_ACTIVITY}
                    label={intl.formatMessage({
                      id: 'EditListingDescriptionForm.outdoorRecreationLabel',
                    })}
                    validate={composeValidators(requiredFieldArrayCheckbox('one box is required'))}
                  />
                </FieldCheckboxListItem>
              </FieldCheckboxList>
            </EditListingFieldWrapper>
          </EditListingSection>

          <EditListingSection isFullWidth>
            <EditListingError fetchErrors={fetchErrors} />

            <EditListingFieldWrapper>
              <MultiPhotoUploadField
                form={form}
                requestImageUpload={requestImageUpload}
                intl={intl}
                categories={['add']}
                tags={tags}
                imageCaptions={imageCaptions}
                activeTab={PHOTO_LIBRARY_TABS.PROPERTY}
                description={
                  <FormattedMessage id="EditListingDescriptionForm.descriptionPhotoLibrary" />
                }
                title={<FormattedMessage id="EditListingDescriptionForm.titlePhotoLibrary" />}
              />
            </EditListingFieldWrapper>

            {uploadImageFailed}
          </EditListingSection>

          <EditListingActions>
            {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

            <EditListingActions.Forward>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
            </EditListingActions.Forward>
          </EditListingActions>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  backLink: null,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
  isPublished: bool.isRequired,

  // From mapDispatchToProps
  requestImageUpload: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  requestImageUpload: (file, onUploadProgress) =>
    dispatch(requestImageUploadAction({ file, onUploadProgress })),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(injectIntl(EditListingDescriptionFormComponent));
