import ducksUnlimitedImage from './assets/images/conservation-donation/ducks-unlimited.png';
import nationalWildTurkeyFoundationImage from './assets/images/conservation-donation/national-wild-turkey-foundation.png';
import pheasantsForeverImage from './assets/images/conservation-donation/pheasants-forever.png';
import quailForeverImage from './assets/images/conservation-donation/quail-forever.png';
import nationalDeerAssociation from './assets/images/conservation-donation/national-deer-association.png';
import rockyMountainElkFoundationImage from './assets/images/conservation-donation/rocky-mountain-elk-foundation.png';
import futureFarmersImage from './assets/images/conservation-donation/future-farmers.png';
import colton from './assets/colton.webp';
import stewart from './assets/stewart.webp';
import trevor from './assets/trevor.webp';
// Field Rep Images
import repAustinImage from './assets/images/field-rep/austin.webp';
import repJakeImage from './assets/images/field-rep/jake.webp';
import repJohnImage from './assets/images/field-rep/john.webp';
import repKrisImage from './assets/images/field-rep/kris.webp';
import repSkylarImage from './assets/images/field-rep/skylar.webp';
import repTheoImage from './assets/images/field-rep/theo.webp';
import repTomImage from './assets/images/field-rep/tom.webp';

/*
 * Marketplace specific configuration.
 */
export const ltForegroundColor = '#000000';
export const ltBackgroundColor = '#ffffff';
export const ltBorderColor = '#EEEEEE';
export const ltFocusBorderColor = '#CBCBCB';
export const ltHoverForegroundColor = '#fd3713';
export const ltActiveBackgroundColor = '#fd3713';
export const ltActiveForegroundColor = '#ffffff';

export const searchDesktopLayoutBreakpoint = 768; // --screen-md
export const searchFiltersModalBreakpoint = 640; // --screen-sm

export const speciesFoundations = {
  elk: {
    link: 'https://www.rmef.org/',
    name: 'Rocky Mountain Elk Foundation',
    species: 'elk',
    logo: rockyMountainElkFoundationImage,
  },
  turkey: {
    link: 'http://www.nwtf.org/',
    name: 'National Wild Turkey Federation',
    species: 'turkey',
    logo: nationalWildTurkeyFoundationImage,
  },
  quail: {
    link: 'https://quailforever.org/',
    name: 'Quail Forever',
    species: 'quail',
    logo: quailForeverImage,
  },
  pheasant: {
    link: 'https://www.pheasantsforever.org/',
    name: 'Pheasants Forever',
    species: 'pheasant',
    logo: pheasantsForeverImage,
  },
  duck: {
    link: 'https://www.ducks.org/',
    name: 'Ducks Unlimited',
    species: 'duck',
    logo: ducksUnlimitedImage,
  },
  'mule-deer': {
    link: 'https://www.deerassociation.com/',
    name: 'National Deer Association',
    species: 'mule deer',
    logo: nationalDeerAssociation,
  },
  waterfowl: {
    link: 'https://www.ducks.org/',
    name: 'Ducks Unlimited',
    species: 'waterfowl',
    logo: ducksUnlimitedImage,
  },
  whitetail: {
    link: 'https://www.deerassociation.com/',
    name: 'National Deer Association',
    species: 'whitetail',
    logo: nationalDeerAssociation,
  },
  farmers: {
    link: 'https://www.ffa.org/',
    name: 'Future Farmers of America',
    species: 'farm',
    logo: futureFarmersImage,
  },
};

export const species = {
  fish: {
    black_crappie: { label: 'Black Crappie' },
    blue_gill: { label: 'Bluegill' },
    carp: { label: 'Carp' },
    catfish: { label: 'Catfish' },
    largemouth_bass: { label: 'Largemouth Bass' },
    mountain_whitefish: { label: 'Mountain Whitefish' },
    muskey: { label: 'Muskey' },
    norther_pike: { label: 'Northern Pike' },
    salmon: { label: 'Salmon' },
    smallmouth_bass: { label: 'Smallmouth Bass' },
    steelhead: { label: 'Steelhead' },
    striped_bass: { label: 'Striped Bass' },
    suckers: { label: 'Suckers' },
    trout: { label: 'Trout' },
    walleye: { label: 'Walleye' },
    white_bass: { label: 'White Bass' },
    yellow_perch: { label: 'Yellow Perch' },
    other: { label: 'Other' },
  },
  hunt: {
    alligator: { label: 'Alligator', popular: false },
    antelope: { label: 'Antelope', popular: false },
    bear: { label: 'Bear', popular: false },
    bison: { label: 'Bison', popular: false },
    blacktail: { label: 'Blacktail', popular: false },
    bobcat: { label: 'Bobcat', popular: false },
    buffalo: { label: 'Buffalo', popular: false },
    caribou: { label: 'Caribou', popular: false },
    coyote: { label: 'Coyote', popular: false },
    crane: { label: 'Crane', popular: false },
    dove: { label: 'Dove', popular: false },
    duck: { label: 'Duck', popular: false },
    gopher: { label: 'Gopher', popular: false },
    elk: { label: 'Elk', popular: true },
    fox: { label: 'Fox', popular: false },
    grouse: { label: 'Grouse', popular: false },
    hog: { label: 'Hog', popular: true },
    javelina: { label: 'Javelina', popular: false },
    moose: { label: 'Moose', popular: false },
    'mountain-goat': { label: 'Mountain Goat', popular: false },
    'mountain-lion': { label: 'Mountain Lion', popular: false },
    'mule-deer': { label: 'Mule Deer', popular: true },
    other: { label: 'Other', popular: false },
    partridge: { label: 'Partridge', popular: false },
    prarie_dog: { label: 'Prairie Dog', popular: false },
    pheasant: { label: 'Pheasant', popular: true },
    quail: { label: 'Quail', popular: false },
    rabbit: { label: 'Rabbit', popular: false },
    sheep: { label: 'Sheep', popular: false },
    squirrel: { label: 'Squirrel', popular: false },
    turkey: {
      label: 'Turkey',
      popular: true,
      children: {
        eastern: { label: 'Eastern' },
        osceola: { label: 'Osceola' },
        'rio-grande': { label: 'Rio Grande' },
        merriam: { label: "Merriam's" },
        gould: { label: "Gould's" },
        hybrid: { label: 'Hybrid' },
      },
    },
    wolf: { label: 'Wolf', popular: false },
    waterfowl: { label: 'Waterfowl', popular: true },
    whitetail: { label: 'Whitetail', popular: true },
  },
};

export const speciesSearchGroups = {
  hunt: {
    whitetail: {
      label: 'Whitetails',
      title: 'Whitetail Hunting',
      searchSpecies: ['whitetail'],
    },
    elk: {
      label: 'Elk',
      title: 'Elk Hunting',
      searchSpecies: ['elk'],
    },
    turkey: {
      label: 'Turkeys',
      title: 'Turkey Hunting',
      searchSpecies: ['turkey'],
    },
    'mule-deer': {
      label: 'Mule Deer',
      title: 'Mule Deer Hunting',
      searchSpecies: ['mule-deer'],
    },
    antelope: {
      label: 'Antelope',
      title: 'Antelope Hunting',
      searchSpecies: ['antelope'],
    },
    hog: {
      label: 'Hogs',
      title: 'Hog Hunting',
      searchSpecies: ['hog'],
    },
    pheasant: {
      label: 'Pheasant',
      title: 'Pheasant Hunting',
      searchSpecies: ['pheasant'],
    },
    waterfowl: {
      label: 'Waterfowl',
      title: 'Waterfowl Hunting',
      searchSpecies: ['waterfowl', 'duck', 'crane'],
    },
    'prairie-dog': {
      label: 'Prairie Dog',
      title: 'Prairie Dog Hunting',
      searchSpecies: ['prairie-dog'],
    },
    gopher: {
      label: 'Gopher',
      title: 'Gopher Hunting',
      searchSpecies: ['gopher'],
    },
    squirrel: {
      label: 'Squirrel',
      title: 'Squirrel Hunting',
      searchSpecies: ['squirrel'],
    },
    rabbit: {
      label: 'Rabbit',
      title: 'Rabbit Hunting',
      searchSpecies: ['rabbit'],
    },
    raccoon: {
      label: 'Raccoon',
      title: 'Raccoon Hunting',
      searchSpecies: ['raccoon'],
    },
    coyote: {
      label: 'Coyote',
      title: 'Coyote Hunting',
      searchSpecies: ['coyote'],
    },
  },
};

export const signupSpecies = {
  hunt: {
    alligator: {
      label: 'Alligator',
    },
    antelope: {
      label: 'Antelope',
    },
    coyote: {
      label: 'Coyotes',
    },
    elk: {
      label: 'Elk',
    },
    gopher: {
      label: 'Gopher',
    },
    hog: {
      label: 'Hogs',
    },
    'mule-deer': {
      label: 'Mule Deer',
    },
    pheasant: {
      label: 'Pheasant',
    },
    'prairie-dog': {
      label: 'Prairie Dog',
    },
    rabbit: {
      label: 'Rabbit',
    },
    raccoon: {
      label: 'Raccoon',
    },
    squirrel: {
      label: 'Squirrel',
    },
    turkey: {
      label: 'Turkeys',
    },
    waterfowl: {
      label: 'Waterfowl',
    },
    whitetail: {
      label: 'Whitetails',
    },
  },
};

export const profileHuntingWeapons = {
  rifle: {
    label: 'Rifle',
  },
  muzzleloader: {
    label: 'Muzzleloader',
  },
  shotgun: {
    label: 'Shotgun',
  },
  bow: {
    label: 'Bow & Arrow',
  },
};

export const profileActivities = {
  hunt: {
    label: 'Hunting',
  },
  fish: {
    label: 'Fishing',
  },
  rv: {
    label: 'RV/Camping',
  },
  bird: {
    label: 'Birdwatching',
  },
  farm: {
    label: 'Farm and Ranch Tours',
  },
};

// TODO: stop using this and use species.fish directly
// Array like [{ key: 'black_crappie', label: 'Black Crappie', type: 'fish' }, ...],
export const fishTypeMap = Object.keys(species.fish).map(key => ({
  key,
  label: species.fish[key].label,
  type: 'fish',
}));

// TODO: stop using this and use species.hunt directly
// Array like [{ key: 'alligator', label: 'Alligator', type: 'hunt' }, ...],
export const gameTypeMap = Object.keys(species.hunt).map(key => ({
  key,
  label: species.hunt[key].label,
  type: 'hunt',
  children: Object.keys(species.hunt[key]?.children || {}).map(childrenKey => ({
    key: childrenKey,
    label: species.hunt[key].children[childrenKey].label,
  })),
}));

// TODO: stop using this and use species.hunt directly
// Flat array of popular gameType keys like ['whitetails', ...]
export const popularGameTypes = Object.keys(species.hunt).filter(key => species.hunt[key].popular);

export const featuresTypeMap = [
  { key: 'agriculture', label: 'Agriculture' },
  { key: 'food_plot', label: 'Food Plot' },
  { key: 'mountains', label: 'Mountains' },
  { key: 'river_bottom', label: 'River Bottom' },
  { key: 'fields', label: 'Fields' },
  { key: 'woods', label: 'Woods' },
  { key: 'water_source', label: 'Water Source' },
  { key: 'public_land_access', label: 'Public Land Access' },
];

export const activitiesTypeMap = [
  {
    key: 'backcountry_skiing',
    label: 'Backcountry Skiing',
  },
  {
    key: 'biking',
    label: 'Biking',
  },
  {
    key: 'bird',
    label: 'Birdwatching',
    popular: true,
  },
  {
    key: 'boating',
    label: 'Boating',
  },
  {
    key: 'canoeing',
    label: 'Canoeing',
  },
  {
    key: 'christmas_tree_harvest',
    label: 'Christmas Tree Harvest',
  },
  {
    key: 'climbing',
    label: 'Climbing',
  },
  {
    key: 'dog_training',
    label: 'Dog Training',
  },
  {
    key: 'fish',
    label: 'Fishing',
    popular: true,
  },
  {
    key: 'fossil_hunting',
    label: 'Fossil Hunting',
  },
  {
    key: 'foraging',
    label: 'Foraging',
  },
  {
    key: 'farm',
    label: 'Farm Tours',
    popular: true,
  },
  {
    key: 'hiking',
    label: 'Hiking',
  },
  {
    key: 'horseback_riding',
    label: 'Horseback Riding',
  },
  {
    key: 'kayaking',
    label: 'Kayaking',
  },
  {
    key: 'nature_gathering',
    label: 'Nature Gathering',
  },
  {
    key: 'off_roading',
    label: 'Off-Roading',
  },
  {
    key: 'paddleboarding',
    label: 'Paddleboarding',
  },
  {
    key: 'photography',
    label: 'Photography',
  },
  {
    key: 'sledding',
    label: 'Sledding',
  },
  {
    key: 'snowmobiling',
    label: 'Snowmobiling',
  },
  {
    key: 'snowshoeing',
    label: 'Snowshoeing',
  },
  {
    key: 'stargazing',
    label: 'Stargazing',
  },
  {
    key: 'swimming',
    label: 'Swimming',
  },
];

export const amenitiesTypeMap = [
  { key: 'tree_stand', label: 'Tree Stand' },
  { key: 'ground_blind', label: 'Ground Blind' },
  { key: 'game_cameras', label: 'Game Cameras' },
  { key: 'game_feeder', label: 'Game Feeder' },
  { key: 'lodging', label: 'Lodging' },
  { key: 'property_parking', label: 'Parking on Property' },
];

export const rulesTypeMap = [
  { key: 'camping', label: 'Camping' },
  { key: 'campfires', label: 'Campfires' },
  { key: 'atv', label: 'ATVs' },
  { key: 'rv_hookup', label: 'RV Hookup' },
  { key: 'dogs_allowed', label: 'Dogs Allowed' },
  { key: 'drive-in_access', label: 'Drive-in Access' },
  { key: 'walk-in_access', label: 'Walk-in Access' },
];

export const huntMotMap = [
  { key: 'archery', label: 'Archery' },
  { key: 'shotgun', label: 'Shotgun' },
  { key: 'rifle', label: 'Rifle' },
  { key: 'muzzleloader', label: 'Muzzleloader' },
  { key: 'black_powder', label: 'Black Powder' },
];

export const fishMotMap = [
  { key: 'fly_fishing', label: 'Fly Fishing' },
  { key: 'artificial_lure', label: 'Artificial Lure' },
  { key: 'live_bait', label: 'Live Bait' },
  { key: 'bowfishing', label: 'Bowfishing' },
];

export const motMap = [
  ...huntMotMap.map(item => ({
    ...item,
    type: 'hunt',
  })),
  ...fishMotMap.map(item => ({
    ...item,
    type: 'fish',
  })),
];

export const genericTabsMap = {
  fishTypes: fishTypeMap,
  gameTypes: gameTypeMap,
  amenitiesTypes: amenitiesTypeMap,
  featuresTypes: featuresTypeMap,
  rulesTypes: rulesTypeMap,
  motTypes: motMap,
  activitiesTypes: activitiesTypeMap,
};

export const speciesProperties = ['fishTypes', 'gameTypes'];

export const categories = [
  { key: 'hunt', label: 'Hunt', schemaTitle: 'Hunting' },
  { key: 'fish', label: 'Fish', schemaTitle: 'Fishing' },
  { key: 'bird', label: 'Bird', schemaTitle: 'Birdwatching' },
  { key: 'farm', label: 'Farm/Ranch', schemaTitle: 'Farm/Ranch' },
  { key: 'outdoor_recreation', label: 'Outdoor Recreation', schemaTitle: 'Outdoor Recreation' },
];

export const wildlifeFoodOptions = [
  { key: 'food_plots', label: 'Food Plots' },
  { key: 'agriculture', label: 'Agriculture' },
  { key: 'meadows', label: 'Hardwoods' },
  { key: 'natural_forage', label: 'Natural Forage' },
];

export const wildlifeWaterSourcesOptions = [
  { key: 'ponds', label: 'Ponds' },
  { key: 'lakes', label: 'Lakes' },
  { key: 'streams', label: 'Streams' },
  { key: 'rivers', label: 'Rivers' },
  { key: 'livestock_tanks', label: 'Livestock Tanks' },
  { key: 'creeks', label: 'Creeks' },
  { key: 'springs', label: 'Springs' },
];

export const wildlifeShelterOptions = [
  { key: 'mountains', label: 'Mountains' },
  { key: 'hills', label: 'Hills' },
  { key: 'woods', label: 'Woods' },
  { key: 'draws', label: 'Draws' },
  { key: 'shelter_belt', label: 'Shelter belt' },
  { key: 'grasslands', label: 'Grasslands' },
  { key: 'brush', label: 'Brush' },
];

export const lodgingOptions = [{ key: 'house', label: 'House' }, { key: 'cabin', label: 'Cabin' }];

export const garbageAreaOptions = [
  { key: 'trash_can', label: 'Trash can' },
  { key: 'recycling_bin', label: 'Recycling bin' },
  { key: 'compost', label: 'Compost' },
];

export const houseAmenitiesOptions = [
  { key: 'towels', label: 'Towels' },
  { key: 'bed_sheets', label: 'Bed sheets' },
  { key: 'bath_soap', label: 'Bath soap' },
  { key: 'toilet_paper', label: 'Toilet paper' },
  { key: 'pillows', label: 'Pillows' },
  { key: 'coffee', label: 'Coffee' },
  { key: 'fireplace', label: 'Fireplace' },
  { key: 'air_conditioning', label: 'Air conditioning' },
];

export const houseCookingOptions = [
  { key: 'oven', label: 'Oven' },
  { key: 'stove', label: 'Stove' },
  { key: 'fridge', label: 'Fridge' },
  { key: 'freezer', label: 'Freezer' },
  { key: 'dishwasher', label: 'Dishwasher' },
  { key: 'outdoor_grill', label: 'Outdoor grill' },
  { key: 'game_processing_area', label: 'Game processing area' },
];

export const cabinAmenitiesOptions = [
  { key: 'towels', label: 'Towels' },
  { key: 'bed_sheets', label: 'Bed sheets' },
  { key: 'bath_soap', label: 'Bath soap' },
  { key: 'toilet_paper', label: 'Toilet paper' },
  { key: 'pillows', label: 'Pillows' },
  { key: 'coffee', label: 'Coffee' },
  { key: 'fireplace', label: 'Fireplace' },
  { key: 'air_conditioning', label: 'Air conditioning' },
];

export const cabinCookingOptions = [
  { key: 'oven', label: 'Oven' },
  { key: 'stove', label: 'Stove' },
  { key: 'fridge', label: 'Fridge' },
  { key: 'freezer', label: 'Freezer' },
  { key: 'dishwasher', label: 'Dishwasher' },
  { key: 'outdoor_grill', label: 'Outdoor grill' },
  { key: 'game_processing_area', label: 'Game processing area' },
];

export const cabinSafeDrinkingWaterOptions = [
  { key: 'spigot', label: 'Spigot' },
  { key: 'tap', label: 'Tap' },
  { key: 'hose', label: 'Hose' },
  { key: 'well', label: 'Well' },
];

export const rvParkingOptions = [
  { key: 'concrete_pad', label: 'Concrete pad' },
  { key: 'loose_gravel', label: 'Loose gravel' },
  { key: 'grass', label: 'Grass' },
  { key: 'dirt', label: 'Dirt' },
];

export const rvTypesOptions = [
  { key: 'travel_trailer', label: 'Travel Trailer' },
  { key: 'fifth_wheel', label: 'Fifth Wheel' },
  { key: 'pop_up_camper', label: 'Pop Up Camper' },
  { key: 'class_a', label: 'Class A' },
  { key: 'class_b', label: 'Class B' },
  { key: 'class_c', label: 'Class C' },
  { key: 'campervan', label: 'Campervan' },
  { key: 'car', label: 'Car' },
  { key: 'toy_hauler', label: 'Toy Hauler' },
  { key: 'other', label: 'Other' },
];

export const rvElectricHookupOptions = [
  { key: '30_amps', label: '30 Amps' },
  { key: '50_amps', label: '50 Amps' },
];

export const rvRoadQualityOptions = [
  { key: 'dirt', label: 'Dirt' },
  { key: 'gravel', label: 'Gravel' },
  { key: 'paved', label: 'Paved' },
];

export const rvAccessOptions = [
  { key: 'pull_in', label: 'Pull in' },
  { key: 'pull_through', label: 'Pull through' },
  { key: 'back_in', label: 'Back in' },
];

export const rvSewageHookupOptions = [
  { key: 'on_site_hookup', label: 'On-site hookup' },
  { key: 'gray_water_dump_station', label: 'Gray water dump station' },
];

export const rvAmenitiesOptions = [
  {
    key: 'picnic_table',
    label: 'Picnic Table',
  },
  {
    key: 'wifi',
    label: 'Wifi',
  },
  {
    key: 'trash_cans',
    label: 'Trash Cans',
  },
  {
    key: 'hot_tub',
    label: 'Hot Tub',
  },
  {
    key: 'cornhole',
    label: 'Cornhole',
  },
  {
    key: 'backyard_games',
    label: 'Backyard Games',
  },
  {
    key: 'horseshoes',
    label: 'Horseshoes',
  },
  {
    key: 'propane_allowed',
    label: 'Propane Allowed',
  },
  {
    key: 'firepit',
    label: 'Firepit',
  },
  {
    key: 'grill',
    label: 'Grill',
  },
  {
    key: 'e_bikes',
    label: 'E-Bikes',
  },
  {
    key: 'beautiful_view',
    label: 'Beautiful View',
  },
  {
    key: 'firewood',
    label: 'Firewood',
  },
  {
    key: 'fishing_poles',
    label: 'Fishing Poles',
  },
  {
    key: 'kayak',
    label: 'Kayak',
  },
  {
    key: 'i_have_none_of_these',
    label: 'I have none of these',
  },
];

export const tentSafeDrinkingWaterOptions = [
  { key: 'spigot', label: 'Spigot' },
  { key: 'tap', label: 'Tap' },
  { key: 'hose', label: 'Hose' },
  { key: 'well', label: 'Well' },
];

export const tentCookingAreaOptions = [
  { key: 'firepit', label: 'Firepit' },
  { key: 'outdoor_grill', label: 'Outdoor Grill' },
  { key: 'game_processing_area', label: 'Game processing area' },
];

export const cellServiceOptions = [
  { key: 'verizon', label: 'Verizon' },
  { key: 'at_t', label: 'AT&T' },
  { key: 't_mobile', label: 'T-Mobile' },
  { key: 'sprint', label: 'Sprint' },
  { key: 'us_cellular', label: 'US Cellular' },
];

export const campfireOptions = [
  { key: 'firepit', label: 'Firepit' },
  { key: 'fireplace', label: 'Fireplace' },
];

export const habitatDiversityOptions = [
  { key: 'riparian_woodland', label: 'Riparian Woodland' },
  { key: 'deciduous_woodland', label: 'Deciduous Woodland' },
  { key: 'montane_conifer_woodland', label: 'Montane Conifer Woodland' },
  { key: 'prairie', label: 'Prairie' },
  { key: 'sagebrush_steppe', label: 'Sagebrush Steppe' },
  { key: 'agricultural_cropland', label: 'Agricultural Cropland' },
  { key: 'wetlands', label: 'Wetlands' },
  { key: 'large_lakes', label: 'Large Lakes' },
];

// Party Size
export const partyOptionsMap = [
  { key: 1, label: '1' },
  { key: 2, label: '2' },
  { key: 3, label: '3' },
  { key: 4, label: '4' },
  { key: 5, label: '5' },
  { key: 6, label: '6' },
  { key: 7, label: '7' },
  { key: 8, label: '8' },
  { key: 9, label: '9' },
  { key: 10, label: '10' },
  { key: 11, label: '11' },
  { key: 12, label: '12' },
  { key: 13, label: '13' },
  { key: 14, label: '14' },
  { key: 15, label: '15' },
  { key: 16, label: '16' },
  { key: 17, label: '17' },
  { key: 18, label: '18' },
  { key: 19, label: '19' },
  { key: 20, label: '20' },
];

export const usStateOptions = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const timeOptions = [
  { key: '12:00 AM', label: '12:00 AM' },
  { key: '1:00 AM', label: '1:00 AM' },
  { key: '2:00 AM', label: '2:00 AM' },
  { key: '3:00 AM', label: '3:00 AM' },
  { key: '4:00 AM', label: '4:00 AM' },
  { key: '5:00 AM', label: '5:00 AM' },
  { key: '6:00 AM', label: '6:00 AM' },
  { key: '7:00 AM', label: '7:00 AM' },
  { key: '8:00 AM', label: '8:00 AM' },
  { key: '9:00 AM', label: '9:00 AM' },
  { key: '10:00 AM', label: '10:00 AM' },
  { key: '11:00 AM', label: '11:00 AM' },
  { key: '12:00 PM', label: '12:00 PM' },
  { key: '1:00 PM', label: '1:00 PM' },
  { key: '2:00 PM', label: '2:00 PM' },
  { key: '3:00 PM', label: '3:00 PM' },
  { key: '4:00 PM', label: '4:00 PM' },
  { key: '5:00 PM', label: '5:00 PM' },
  { key: '6:00 PM', label: '6:00 PM' },
  { key: '7:00 PM', label: '7:00 PM' },
  { key: '8:00 PM', label: '8:00 PM' },
  { key: '9:00 PM', label: '9:00 PM' },
  { key: '10:00 PM', label: '10:00 PM' },
  { key: '11:00 PM', label: '11:00 PM' },
];

// Field Rep / LSM Info

export const fieldRepOptions = [
  { key: 'austin-brenner', label: 'Austin Brenner' },
  { key: 'jake-romsa', label: 'Jake Romsa' },
  { key: 'john-hurd', label: 'John Hurd' },
  { key: 'kris-poirier', label: 'Kris Poirier' },
  { key: 'skylar-stenson', label: 'Skylar Stenson' },
  { key: 'theo-stenka', label: 'Theo Stenka' },
  { key: 'tom-dombroski', label: 'Tom Dombroski' },
];

export const fieldRepInfo = {
  'austin-brenner': { key: 'austin-brenner', label: 'Austin Brenner', repPhoto: repAustinImage },
  'jake-romsa': { key: 'jake-romsa', label: 'Jake Romsa', repPhoto: repJakeImage },
  'john-hurd': { key: 'john-hurd', label: 'John Hurd', repPhoto: repJohnImage },
  'kris-poirier': { key: 'kris-poirier', label: 'Kris Poirier', repPhoto: repKrisImage },
  'skylar-stenson': { key: 'skylar-stenson', label: 'Skylar Stenson', repPhoto: repSkylarImage },
  'theo-stenka': { key: 'theo-stenka', label: 'Theo Stenka', repPhoto: repTheoImage },
  'tom-dombroski': { key: 'tom-dombroski', label: 'Tom Dombroski', repPhoto: repTomImage },
};

// FAQ Questions

export const huntingFaqs = [
  {
    title: 'Will there be other hunters on the property for the days I book?',
    content:
      'No, all hunts through LandTrust.com grant you (and your party) exclusive access to the entire property during your selected days.',
  },
  {
    title: 'How is the population this year?',
    content:
      'LandTrust Field Reps provide an updated hunter’s perspective on populations after every property visit. Including species seen, current habitat conditions and more. Look for the green checkmark on the property and package pages!',
  },
  {
    title: 'Will I receive property maps so that I can plan my hunt?',
    content:
      'Once a booking request has been accepted, making your reservation official, you will receive the address, arrival instructions, Huntwise and OnX waypoints, and any other necessary information to enjoy your trip!',
  },
  {
    title: 'Does LandTrust allow a day for scouting pre-hunt?',
    content:
      'Although LandTrust does not permit a scout day, we recommend either booking an additional day to use as a scout day or requesting a special offer to include an additional day for scouting from the landowner.',
  },
  {
    title: 'How do I know every property/available package is authentic?',
    content:
      'LandTrust works hard to ensure every listing accurately depicts what they are offering. Our Onboarding team has in depth conversations with Landowners to identify their property via GPS mapping and our Field Reps visit properties in person to confirm authenticity, add waypoints, and set guests up for an enjoyable experience.',
  },
];

export const outdoorRecFaqs = [
  {
    title: 'Will there be other recreators on the property for the days I book?',
    content:
      'No, all trips through LandTrust.com grant you (and your party) exclusive access to the entire property during your selected days.',
  },
  {
    title: 'Will I receive property maps so that I can plan my trip?',
    content:
      'Once a booking request has been accepted, making your reservation official, you will receive the address, arrival instructions, property waypoints, and any other necessary information to enjoy your trip!',
  },
  {
    title: 'How do I know every property/available package is authentic?',
    content:
      'LandTrust works hard to ensure every listing accurately depicts what they are offering. Our Onboarding team has in-depth conversations with Landowners to identify their property via GPS mapping and our Field Reps visit properties in person to confirm authenticity, add waypoints, and set guests up for an enjoyable experience.',
  },
  {
    title: 'Will the Landowner be onsite during my stay?',
    content:
      'Most activities and experiences offered on LandTrust are Do It Yourself. LandTrust Landowners are working farmers and ranchers, thus, they are typically busy working on their operation. They will often be on-site, but unless stated in the specific package, they will not be interacting with your experience besides checking in/out.',
  },
  {
    title: 'Do my other guests have to register with LandTrust as well?',
    content:
      'Yes, we ask that all guests who participate on a LandTrust booking be i.d. verified (18yrs or older) and accept the LandTrust “Terms of Service”. This is to ensure the safety of all LandTrust guests and protect our Landowner community.',
  },
  {
    title: 'Can I speak with another guest who has visited the property?',
    content:
      'While we refrain from distributing other guest’s contact information, you can visit the property listing page to see recent reviews from other verified guests or reach out to our Customer Service team for more information on your property of interest.',
  },
];

// Reviews Featured on HomePage

export const featuredReviews = [
  {
    listingId: '5d92bd9d-eaba-4b06-adb5-c903391ddee2',
    listingTitle: 'Reedfly Farm',
    acreage: 110,
    location: 'Emigrant, MT',
    content:
      'Had a fantastic hunt at Reedfly Farm. Jeff was a fantastic host who took the time to give me the lay of the land and get me set up for a great hunt. Saw deer, ducks and more while overlooking the stunning Yellowstone valley. Had a few shot opportunities from a well positioned treestand. I will be back!',
    rating: 5,
    image:
      'https://sharetribe.imgix.net/5d136798-d134-44f9-b0ef-c307527b27f8%2F5d92c08d-f070-4141-8e94-dd6b1c19a584?auto=format&crop=edges&fit=crop&h=533&ixlib=java-1.1.1&w=800&s=c2ea16160de9c85e951ac83bf4912efe',
  },
  {
    listingId: '5d8fc942-d42a-468e-aa60-9db6615dfb98',
    listingTitle: 'DunnLand Urban Hunts',
    acreage: 150,
    location: 'Kansas City, MO',
    content: `An excellent host, Super communicative and eager to help you have a great hunt on his property. One of the things that makes him a great Landowner is that he's also a hunter so he's got stands, feeders, food plots, game cams all setup. Finding & accessing the property was very easy. Anyone living in/around KC or who travels to KC like I do for family or work HAS to check out DunnLand, you'll be happy you did!`,
    rating: 5,
    image:
      'https://sharetribe.imgix.net/5d136798-d134-44f9-b0ef-c307527b27f8%2F5ded9d0f-2ccb-4905-941f-72ab0e2847a4?auto=format&crop=edges&fit=crop&h=533&ixlib=java-1.1.1&w=800&s=20ace972b80d2c026a621ac33be20b75',
  },
  {
    listingId: '5db06525-ab64-4b83-afa1-6e03fb417931',
    listingTitle: 'Verlanic Ranch at Deer Lodge',
    acreage: 761,
    location: 'Deer Lodge, MT',
    content: `This is a fantastic property, easy access and loaded with wildlife. I did a single day whitetail hunt and saw elk, whitetail, mule deer and more geese than I could count. I Ended up taking a nice doe. I will definitely be back next season!`,
    rating: 5,
    image:
      'https://sharetribe.imgix.net/5d136798-d134-44f9-b0ef-c307527b27f8%2F5db09a77-822f-4df7-a08e-cc0967deafa0?auto=format&crop=edges&fit=crop&h=533&ixlib=java-1.1.1&w=800&s=0bd225dd88557290df078e2a95b06d97',
  },
  {
    listingId: '5d92408a-f38c-4f64-98c3-97251a8b626f',
    listingTitle: 'Grassmere Farm East',
    acreage: 72,
    location: 'New Berlin, NY',
    content: `Ken is a super nice guy and hunting his property was a great experience. There are lots of deer in this area and if you are able to spend a couple of days hunting here you should get an opportunity to harvest one. The property is easy to navigate and there are plenty of trees to climb with a portable stand.`,
    rating: 5,
    image:
      'https://sharetribe.imgix.net/5d136798-d134-44f9-b0ef-c307527b27f8%2F5d93b077-5bc6-41b4-9431-dac5a10b9bca?auto=format&crop=edges&fit=crop&h=533&ixlib=java-1.1.1&w=800&s=23a1337d3d68b7ca01518991ef6e372e',
  },
  {
    listingId: '5d853e3b-116e-4b5c-99c8-0731c3765ec0',
    listingTitle: 'Shults Cow Camp Elk & Mule Deer Hunts',
    acreage: 1280,
    location: 'Meeker, CO',
    content: `Wow! When thinking about beautiful country, think here! When considering true openness and untamed wilderness, this is the place! What an amazing hunt we had at the Shults' Cow Camp.  Todd and Michelle are absolutely, top-notch people. They run a beautiful ranch on some of the best hunting grounds I've ever seen.`,
    rating: 5,
    image:
      'https://sharetribe.imgix.net/5d136798-d134-44f9-b0ef-c307527b27f8%2F5d854bfb-b15e-471f-a12b-b34019c9a1c5?auto=format&crop=edges&fit=crop&h=533&ixlib=java-1.1.1&w=800&s=3e1477b78417ce48d0747b019c054517',
  },
];

// NOTE: If you want to enable some of these filters see the note in `src/components/SearchFilters/SearchFilters.js`
// which explains how to do this

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
  active: false,
};

export const dateRangeFilterConfig = {
  active: true,
};

export const categoryFilterConfig = {
  active: true,
  options: categories,
};

export const motFilterConfig = {
  active: false,
  motMap,
};

export const gameTypeFilterConfig = {
  active: true,
  options: Object.keys(speciesSearchGroups.hunt).map(key => ({
    key,
    label: speciesSearchGroups.hunt[key].label,
  })),
};

export const getCustomLabel = (customArray, key) => {
  const pair = customArray.find(c => c.key === key);
  return pair ? pair.label : key;
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: false,
};

export const stateRegulationsMap = [
  {
    name: 'Alabama',
    anchor: 'alabama',
    license: 'https://www.outdooralabama.com/license-information',
    regulations:
      'https://www.outdooralabama.com/sites/default/files/Enforcement/2020-2021%20REGULATION%20BOOK.pdf',
    statute:
      'WARNING. “Under Alabama law, an agritourism professional is not liable for injury, sickness, or damage to, or the death of, a participant in an agritourism activity at this location if the injury, sickness, damage, or death results from the inherent risks of theagritourism activity. “Inherent risks of an agritourism activity include risks of injury, sickness, damage, or death inherent to land, equipment, and animals as well as the potential for you to act in a negligent manner that may contribute to your injury, sickness, damage, or death, or for another participant to act in a manner that may cause your injury, sickness, damage, or death. “You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Alaska',
    anchor: 'alaska',
    license: 'https://www.adfg.alaska.gov/index.cfm?adfg=license.main',
    regulations:
      'https://www.adfg.alaska.gov/static/applications/web/nocache/regulations/wildliferegulations/pdfs/regulations_complete.pdfDA97448B37DB3F8A5ABF67248F5721E0/regulations_complete.pdf',
    statute: null,
  },
  {
    name: 'Arizona',
    anchor: 'arizona',
    license: 'https://www.azgfd.com/license/',
    regulations: 'https://www.azgfd.com/hunting/regulations/',
    statute: null,
  },
  {
    name: 'Arkansas',
    anchor: 'arkansas',
    license: 'https://www.agfc.com/en/resources/licensing/',
    regulations: 'https://www.agfc.com/en/resources/regulations/guidebooks/',
    statute:
      'WARNING -- Under Arkansas law, an agritourism activity operator is not liable for the injury or death of a participant in an agritourism activity resulting from the inherent risk of agritourism activities. Inherent risks include without limitation the risk of animals, weather, land conditions, and the potential for you as a participant to act in a negligent way that may contribute to your own injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'California',
    anchor: 'california',
    license: 'https://www.ca.wildlifelicense.com/internetsales/',
    regulations: 'https://wildlife.ca.gov/Regulations',
    statute: null,
  },
  {
    name: 'Colorado',
    anchor: 'colorado',
    license: 'https://www.cpwshop.com/purchaseprivilege.page',
    regulations: 'https://cpw.state.co.us/aboutus/Pages/RegulationsBrochures.aspx',
    statute:
      'WARNING UNDER COLORADO LAW, THERE IS NO LIABILITY FOR THE DEATH OF OR INJURY TO A PARTICIPANT IN AN AGRICULTURAL RECREATION OR AGRITOURISM ACTIVITY RESULTING FROM THE INHERENT RISKS OF THE AGRICULTURAL RECREATION OR AGRITOURISM ACTIVITY, PURSUANT TO SECTION 13-21-121, COLORADO REVISED STATUTES.',
  },
  {
    name: 'Connecticut',
    anchor: 'connecticut',
    license: 'https://ct.aspirafocus.com/internetsales',
    regulations: 'https://portal.ct.gov/DEEP/Hunting/2021-Connecticut-Hunting-and-Trapping-Guide',
    statute: null,
  },
  {
    name: 'Delaware',
    anchor: 'delaware',
    license: 'https://dnrec.alpha.delaware.gov/fish-wildlife/licenses-registrations/',
    regulations: 'https://dnrec.alpha.delaware.gov/fish-wildlife/guides-seasons-maps/',
    statute: null,
  },
  {
    name: 'Florida',
    anchor: 'florida',
    license: 'https://myfwc.com/license/',
    regulations: 'http://www.eregulations.com/florida/hunting/',
    statute:
      'WARNING Under Florida law, an agritourism operator is not liable for injury or death of, or damage or loss to, a participant in an agritourism activity conducted at this agritourism location if such injury, death, damage, or loss results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury, death, damage, or loss. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Georgia',
    anchor: 'georgia',
    license: 'https://georgiawildlife.com/licenses-permits-passes',
    regulations: 'http://www.eregulations.com/wp-content/uploads/2020/07/20GAHD_VLR6.pdf	',
    statute:
      'Warning Under Georgia law, there is no liability for an injury or death of a participant at least 18 years of age in a registered agritourism activity conducted at this registered agritourism location if such injury or death results from the inherent risks of such agritourism activity. Inherent risks of agritourism activities include, but shall not be limited to, the potential of you to act in a negligent manner that may contribute to your injury or death and the potential of another participant to act in a negligent manner that may contribute to your injury or death.',
  },
  {
    name: 'Hawaii',
    anchor: 'hawaii',
    license: 'https://hunting.ehawaii.gov/hunting/license.html',
    regulations: 'https://dlnr.hawaii.gov/recreation/hunting/mammal/',
    statute: null,
  },
  {
    name: 'Idaho',
    anchor: 'idaho',
    license: 'https://idfg.idaho.gov/licenses',
    regulations: 'https://idfg.idaho.gov/rules',
    statute:
      'WARNING Under Idaho law, there are risks associated with agritourism, which could lead to injury or death. You are assuming these risks. Section 6-3004, Idaho Code.',
  },
  {
    name: 'Illinois',
    anchor: 'illinois',
    license: 'https://www2.illinois.gov/dnr/LPR/Pages/default.aspx',
    regulations: 'https://www2.illinois.gov/dnr/hunting/Documents/HuntTrapDigest.pdf',
    statute: null,
  },
  {
    name: 'Indiana',
    anchor: 'indiana',
    license: 'https://www.in.gov/dnr/fish-and-wildlife/licenses-and-permits/',
    regulations: 'http://www.eregulations.com/indiana/hunting/',
    statute:
      'WARNING Under Indiana law, an agritourism provider is not liable for an injury to, or the death of, a participant in agritourism activities at this location if the death or injury results from the inherent risks of agritourism activity. Inherent risks of agritourism activities include risks of injury inherent to land, equipment, and animals as well as the potential for you to act in a negligent manner that may contribute to your injury or death, or for other participants to act in a manner that may cause you injury or cause your death. You are assuming the risk of participating in this agritourism activity',
  },
  {
    name: 'Iowa',
    anchor: 'iowa',
    license: 'https://gooutdoorsiowa.com/',
    regulations: 'https://www.iowadnr.gov/hunting/hunting-licenses-laws',
    statute: null,
  },
  {
    name: 'Kansas',
    anchor: 'kansas',
    license: 'https://ksoutdoors.com/License-Permits',
    regulations: 'https://ksoutdoors.com/Hunting/Hunting-Regulations',
    statute:
      'WARNING Under Kansas law, there is no liability for an injury or death of a participant in a registered agritourism activity conducted at this registered agritourism location if such injury or death results from the inherent risks of such agritourism activity. Inherent risks of agritourism activities include, but shall not be limited to, the potential of you as a participant to act in a negligent manner that may contribute to your injury or death and the potential of another participant to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this registered agritourism activity.',
  },
  {
    name: 'Kentucky',
    anchor: 'kentucky',
    license: 'https://fw.ky.gov/Licenses/Pages/Fees.aspx',
    regulations: 'https://fw.ky.gov/Hunt/Pages/Guides.aspx',
    statute:
      'WARNING Under Kentucky law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if the injury or death results exclusively from the inherent risks of the agritourism activity and in the absence of negligence. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Louisiana',
    anchor: 'louisiana',
    license: 'https://www.wlf.louisiana.gov/page/seasons-and-regulations',
    regulations: 'http://www.eregulations.com/louisiana/hunting/',
    statute:
      'WARNING Under Louisiana law, R.S. 9:2795.5, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if such injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Maine',
    anchor: 'maine',
    license: 'https://www.maine.gov/ifw/hunting-trapping/licenses-permits/index.html',
    regulations: 'https://www.maine.gov/ifw/docs/20-MDIFW-20-Hunting-Lawbook-2020-21.pdf',
    statute:
      'WARNING Under Maine law, there is no liability for injury to a participant in an agritourism activity conducted at this agritourism location if such injury results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment and animals, as well as the potential for injury if you act in a negligent manner. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Maryland',
    anchor: 'maryland',
    license: 'https://compass.dnr.maryland.gov/dnrcompassportal',
    regulations: 'http://www.eregulations.com/maryland/hunting/',
    statute:
      'WARNING:  Pursuant to Natural Resources Article Section 5–1109, each person who uses this property for hunting consents to adhere to every law, to observe every safety precaution and practice, to observe all property  boundaries, to take every precaution against fire, and to assume all responsibility and liability for your safety and your property while hunting on this property.',
  },
  {
    name: 'Massachusetts',
    anchor: 'massachusetts',
    license: 'https://www.mass.gov/massfishhunt-buy-fishing-or-hunting-licenses',
    regulations: 'https://www.mass.gov/doc/2021-massachusetts-fishing-and-hunting-guide/download',
    statute: null,
  },
  {
    name: 'Michigan',
    anchor: 'michigan',
    license: 'https://www.mdnr-elicense.com/',
    regulations: 'https://www.michigan.gov/dnr/0,4570,7-350-79136_79772---,00.html',
    statute: null,
  },
  {
    name: 'Minnesota',
    anchor: 'minnesota',
    license: 'https://www.dnr.state.mn.us/licenses/online-sales.html',
    regulations:
      'https://files.dnr.state.mn.us/rlp/regulations/hunting/full_regs.pdf?updated=20201229',
    statute: null,
  },
  {
    name: 'Mississippi',
    anchor: 'mississippi',
    license: 'https://www.mdwfp.com/license/',
    regulations: 'http://www.eregulations.com/wp-content/uploads/2020/08/20MSAB-LR.pdf',
    statute:
      'WARNING Under Mississippi law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if the injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment and animals, as well as the potential for you or another participant to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Missouri',
    anchor: 'missouri',
    license: 'https://mdc-web.s3licensing.com/',
    regulations: 'https://huntfish.mdc.mo.gov/hunting-trapping/regulations',
    statute:
      'WARNING: Under Missouri law, there is no liability for an injury or death of a participant in a registered agritourism activity conducted at this registered agritourism location if such injury or death results from the inherent risks of such agritourism activity. Inherent risks of agritourism activities include, but are not limited to, the potential of you as a participant to act in a negligent manner that may contribute to your injury or death and the potential of another participant to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this registered agritourism activity.',
  },
  {
    name: 'Montana',
    anchor: 'montana',
    license: 'https://fwp.mt.gov/buyandapply',
    regulations: 'https://fwp.mt.gov/hunt/regulations',
    statute:
      "By signing this document you may be waiving your legal right to a jury trial to hold the provider legally responsible for any injuries or damages resulting from risks inherent in the sport or recreational opportunity or for any injuries or damages you may suffer due to the provider's ordinary negligence that are the result of the provider's failure to exercise reasonable care.",
  },
  {
    name: 'Nebraska',
    anchor: 'nebraska',
    license: 'https://ngpc-home.ne.gov/',
    regulations: 'http://outdoornebraska.gov/huntingseasons/',
    statute:
      "The warning notice shall read as follows: WARNING--Under Nebraska law, an owner of property, including lands and waters, is not liable for the injury to or death of the participant in agritourism activities or damage to the participant's property resulting from the inherent risks of such activities. Inherent risks include, without limitation, the risk of animals and land and water conditions, the ordinary dangers of structures or equipment ordinarily used in farming or ranching operations, and the potential for you or another participant to act in a negligent manner that may contribute to your own injury or death. You are assuming the risk of participating in the agritourism activities for which you are entering the owner's premises.",
  },
  {
    name: 'Nevada',
    anchor: 'nevada',
    license: 'http://www.ndow.org/Forms_and_Resources/General_Licenses/',
    regulations: 'http://www.eregulations.com/nevada/big-game/',
    statute: null,
  },
  {
    name: 'New Hampshire',
    anchor: 'new-hampshire',
    license: 'https://www.wildlife.state.nh.us/licensing/',
    regulations: 'http://www.eregulations.com/newhampshire/hunting/',
    statute: null,
  },
  {
    name: 'New Jersey',
    anchor: 'new-jersey',
    license: 'https://nj.aspirafocus.com/internetsales',
    regulations: 'http://www.eregulations.com/newjersey/hunting/',
    statute: null,
  },
  {
    name: 'New Mexico',
    anchor: 'new-mexico',
    license: 'http://www.wildlife.state.nm.us/hunting/licenses-and-permits/',
    regulations: 'http://www.wildlife.state.nm.us/home/publications/',
    statute: null,
  },
  {
    name: 'New York',
    anchor: 'new-york',
    license: 'https://decals.licensing.east.kalkomey.com/',
    regulations: 'http://www.eregulations.com/newyork/hunting/',
    statute: null,
  },
  {
    name: 'North Carolina',
    anchor: 'north-carolina',
    license: 'https://www.ncwildlife.org/Licensing/Hunting-Fishing-Trapping-Licenses',
    regulations:
      'http://www.eregulations.com/northcarolina/hunting-fishing/general-hunting-regulations/',
    statute:
      'WARNING Under North Carolina law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if such injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'North Dakota',
    anchor: 'north-dakota',
    license: 'https://gf.nd.gov/buy-apply',
    regulations: 'https://gf.nd.gov/hunting/regulations',
    statute:
      'A notice indicating that under the laws of this state, the registered agritourism operator is not liable for any injury to or for the death of a participant if the injury or death results from an inherent risk.',
  },
  {
    name: 'Ohio',
    anchor: 'ohio',
    license: 'https://oh-web.s3licensing.com/Home',
    regulations:
      'https://ohiodnr.gov/static/documents/wildlife/laws-regs-licenses/Ohio%20Hunting%20and%20Trapping%20Regulations%20ENGLISH.pdf',
    statute: null,
  },
  {
    name: 'Oklahoma',
    anchor: 'oklahoma',
    license: 'https://license.gooutdoorsoklahoma.com/Licensing/CustomerLookup.aspx',
    regulations: 'http://www.eregulations.com/oklahoma/hunting/',
    statute:
      'WARNING Under Oklahoma law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if such injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Oregon',
    anchor: 'oregon',
    license: 'https://odfw.huntfishoregon.com/login',
    regulations: 'http://www.eregulations.com/oregon/big-game-hunting/',
    statute:
      'WARNING Under Oregon law, there is no liability for an injury to or the death of a participant in an agri-tourism activity conducted at this agri-tourism location if the injury or death results from the inherent risks of the agri-tourism activity. Inherent risks of agri-tourism activities are risks of injury inherent to land, equipment and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agri-tourism activity.',
  },
  {
    name: 'Pennsylvania',
    anchor: 'pennsylvania',
    license: 'https://huntfish.pa.gov/',
    regulations: 'https://www.pgc.pa.gov/HuntTrap/Law/Pages/HuntingandTrappingDigest.aspx',
    statute: null,
  },
  {
    name: 'Rhode Island',
    anchor: 'rhode-island',
    license: 'https://www.ri.gov/DEM/huntfish',
    regulations: 'http://www.eregulations.com/rhodeisland/hunting/',
    statute: null,
  },
  {
    name: 'South Carolina',
    anchor: 'south-carolina',
    license: 'https://dnrlicensing.sc.gov/dnrlicensingsales/salescategories.aspx',
    regulations: 'http://www.eregulations.com/southcarolina/huntingandfishing/',
    statute:
      'WARNING ! Under South Carolina law, an agritourism professional is not liable for an injury to or the death of a participant in an agritourism activity resulting from an inherent risk associated with the agritourism activity. (Chapter 53, Title 46, Code of Laws of South Carolina, 1976).',
  },
  {
    name: 'South Dakota',
    anchor: 'south-dakota',
    license:
      'https://apps.sd.gov/gf79license/login.aspx?_ga=2.185493737.152471257.1617896428-1787986016.1617896428',
    regulations: 'https://gfp.sd.gov/pages/regulations/',
    statute: null,
  },
  {
    name: 'Tennessee',
    anchor: 'tennessee',
    license: 'https://gooutdoorstennessee.com/',
    regulations: 'http://www.eregulations.com/tennessee/hunting/',
    statute:
      'WARNING Under Tennessee law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location or by this agritourism professional if such injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Texas',
    anchor: 'texas',
    license:
      'https://tpwd.texas.gov/regulations/outdoor-annual/licenses/hunting-licenses-and-permits/hunting-licenses',
    regulations: 'https://tpwd.texas.gov/regulations/outdoor-annual/hunting/',
    statute:
      'WARNING UNDER TEXAS LAW (CHAPTER 75A, CIVIL PRACTICE AND REMEDIES CODE), AN AGRITOURISM ENTITY IS NOT LIABLE FOR ANY INJURY TO OR DEATH OF AN AGRITOURISM PARTICIPANT RESULTING FROM AN AGRITOURISM ACTIVITY.',
  },
  {
    name: 'Utah',
    anchor: 'utah',
    license: 'https://wildlife.utah.gov/licenses/licenses-permits.html',
    regulations: 'https://wildlife.utah.gov/hunting/hunting-regulation.html',
    statute: null,
  },
  {
    name: 'Vermont',
    anchor: 'vermont',
    license: 'https://www.vtfwdsales.com/online/cid_entry.php',
    regulations: 'http://www.eregulations.com/vermont/hunting/',
    statute: null,
  },
  {
    name: 'Virginia',
    anchor: 'virginia',
    license: 'https://gooutdoorsvirginia.com/',
    regulations: 'https://dwr.virginia.gov/hunting/regulations/',
    statute:
      'WARNING Under Virginia law, there is no liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if such injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Washington',
    anchor: 'washington',
    license: 'https://fishhunt.dfw.wa.gov/',
    regulations: 'http://www.eregulations.com/washington/hunting/',
    statute:
      'WARNING Under Washington state law, there is limited liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism location if such an injury or death results exclusively from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to land, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. We are required to ensure that in any activity involving minor children, only age-appropriate access to activities, equipment, and animals is permitted. You are assuming the risk of participating in this agritourism activity."',
  },
  {
    name: 'West Virginia',
    anchor: 'west-virginia',
    license: 'https://www.wvhunt.com',
    regulations: 'https://www.wvdnr.gov/hunting/hunting_regs.shtm',
    statute:
      'NOTICE Under West Virginia law, there may be limited liability for an injury to or death of a participant in an agritourism activity conducted at this agritourism business if the injury or death results from the inherent risks of the agritourism activity. Inherent risks of agritourism activities include, among others, risks of injury inherent to landscape, terrain, equipment, and animals, as well as the potential for you to act in a negligent manner that may contribute to your injury or death. You are assuming the risk of participating in this agritourism activity.',
  },
  {
    name: 'Wisconsin',
    anchor: 'wisconsin',
    license: 'https://gowild.wi.gov/',
    regulations: 'https://widnr.widen.net/s/fhiinpexbw',
    statute:
      'Notice: A person who observes or participates in an agricultural tourism activity on this property assumes the risks inherent in the agricultural tourism activity. Risks inherent in the agricultural tourism activity may include conditions on the land, the unpredictable behavior of farm animals, the ordinary dangers associated with equipment used in farming operations, and the potential that a participant in the agricultural tourism activity may act in a negligent way that may contribute to injury or death. The agricultural tourism provider is not liable for the injury or death of a person involved in an agricultural tourism activity resulting from those inherent risks.',
  },
  {
    name: 'Wyoming',
    anchor: 'wyoming',
    license: 'https://wgfd.wyo.gov/apply-or-buy',
    regulations: 'https://wgfd.wyo.gov/regulations',
    statute: null,
  },
];

export const referralMethodMap = [
  { key: 'influencer_or_partner', label: 'Influencer/LandTrust Partner' },
  { key: 'agricultural_organization', label: 'Agricultural Organization' },
  { key: 'conservation_group', label: 'Conservation Group' },
  { key: 'email', label: 'Email' },
  { key: 'family_friends_or_neighbors', label: 'Family, Friends, or Neighbors' },
  { key: 'in_person_event', label: 'In Person Event' },
  { key: 'landtrust_trip_guest', label: 'LandTrust Trip Guest' },
  { key: 'online_forum', label: 'Online Forum' },
  { key: 'podcast_or_radio', label: 'Podcast or Radio' },
  { key: 'print_media', label: 'Print Media' },
  { key: 'social_media', label: 'Social Media' },
  { key: 'web_ad', label: 'Web Ad' },
  { key: 'other', label: 'Other' },
];

export const stateCodes = [
  { state: 'Alabama', code: 'AL' },
  { state: 'Alaska', code: 'AK' },
  { state: 'American Samoa', code: 'AS' },
  { state: 'Arizona', code: 'AZ' },
  { state: 'Arkansas', code: 'AR' },
  { state: 'California', code: 'CA' },
  { state: 'Colorado', code: 'CO' },
  { state: 'Connecticut', code: 'CT' },
  { state: 'Delaware', code: 'DE' },
  { state: 'District of Columbia', code: 'DC' },
  { state: 'Federated States of Micronesia', code: 'FM' },
  { state: 'Florida', code: 'FL' },
  { state: 'Georgia', code: 'GA' },
  { state: 'Guam', code: 'GU' },
  { state: 'Hawaii', code: 'HI' },
  { state: 'Idaho', code: 'ID' },
  { state: 'Illinois', code: 'IL' },
  { state: 'Indiana', code: 'IN' },
  { state: 'Iowa', code: 'IA' },
  { state: 'Kansas', code: 'KS' },
  { state: 'Kentucky', code: 'KY' },
  { state: 'Louisiana', code: 'LA' },
  { state: 'Maine', code: 'ME' },
  { state: 'Marshall Islands', code: 'MH' },
  { state: 'Maryland', code: 'MD' },
  { state: 'Massachusetts', code: 'MA' },
  { state: 'Michigan', code: 'MI' },
  { state: 'Minnesota', code: 'MN' },
  { state: 'Mississippi', code: 'MS' },
  { state: 'Missouri', code: 'MO' },
  { state: 'Montana', code: 'MT' },
  { state: 'Nebraska', code: 'NE' },
  { state: 'Nevada', code: 'NV' },
  { state: 'New Hampshire', code: 'NH' },
  { state: 'New Jersey', code: 'NJ' },
  { state: 'New Mexico', code: 'NM' },
  { state: 'New York', code: 'NY' },
  { state: 'North Carolina', code: 'NC' },
  { state: 'North Dakota', code: 'ND' },
  { state: 'Northern Mariana Islands', code: 'MP' },
  { state: 'Ohio', code: 'OH' },
  { state: 'Oklahoma', code: 'OK' },
  { state: 'Oregon', code: 'OR' },
  { state: 'Palau', code: 'PW' },
  { state: 'Pennsylvania', code: 'PA' },
  { state: 'Puerto Rico', code: 'PR' },
  { state: 'Rhode Island', code: 'RI' },
  { state: 'South Carolina', code: 'SC' },
  { state: 'South Dakota', code: 'SD' },
  { state: 'Tennessee', code: 'TN' },
  { state: 'Texas', code: 'TX' },
  { state: 'Utah', code: 'UT' },
  { state: 'Vermont', code: 'VT' },
  { state: 'Virgin Islands', code: 'VI' },
  { state: 'Virginia', code: 'VA' },
  { state: 'Washington', code: 'WA' },
  { state: 'West Virginia', code: 'WV' },
  { state: 'Wisconsin', code: 'WI' },
  { state: 'Wyoming', code: 'WY' },
];

export const pricingPackages = [
  {
    title: 'Package 1',
    sportsmanTotal: 4,
    days: 3,
    price: 1100,
    species: 'deer',
    description: 'Come hunt deer',
  },
  {
    title: 'Package 2',
    sportsmanTotal: 2,
    days: 5,
    price: 500,
    species: 'quail',
    description: 'Come hunt quail',
  },
  {
    title: 'Package 3',
    sportsmanTotal: 8,
    days: 3,
    price: 15000,
    species: 'bear',
    description: 'Come hunt Bear',
  },
];

export const HUNT_ACTIVITY = 'hunt';
export const HUNT_DIY_ACTIVITY = 'hunt_diy';
export const FISH_ACTIVITY = 'fish';
export const BIRD_ACTIVITY = 'bird';
export const FARM_RANCH_ACTIVITY = 'farm';
export const OUTDOOR_RECREATION_ACTIVITY = 'outdoor_recreation';

export const lodgingTaxStatesRules = {
  // Lodging tax rules
  MT: {
    basePrice: 7500,
    taxValue: 0.08,
    usState: 'Montana',
  },
  KS: {
    basePrice: 7500,
    taxValue: 0.065,
    usState: 'Kansas',
  },
  NE: {
    basePrice: 7500,
    taxValue: 0.065,
    usState: 'Nebraska',
  },
};

export const commissionRateMap = [
  { key: 'default', label: 'Default • 20% Commission', commission: -20 },
  { key: 'standard', label: 'Farm Bureau Standard • 15% Commission', commission: -15 },
  { key: 'farm_bureau', label: 'Farm Bureau Legacy • 10% Commission', commission: -10 },
];

export const defaultCommission = {
  commissionRate: -20,
  relationship: 'default',
};

export const plusQuotes = {
  items: [
    {
      sportsman: 'Colton D.',

      image: colton,
      memberStatus: 'LandTrust+ Member',
      stars: 5,
      quote:
        'After years of shady deposits and rescheduling reservations, LT+ makes it simple for me to book hunts in draw locations with confidence and peace of mind.',
    },
    {
      sportsman: 'Stewart. L',

      image: stewart,
      memberStatus: 'LandTrust+ Member',
      stars: 5,
      quote:
        "Knowing what is going on at a property at any given moment is important to me and LandTrust+ helps me scout my favorite properties when I can't physically be there.",
    },
    {
      sportsman: 'Trevor W.',

      image: trevor,
      memberStatus: 'LandTrust+ Member',
      stars: 5,
      quote:
        'Getting real-time updates from the Landowners on the properties I have favorited is a gamechanger!  This gives me a stronger pulse on what I may encounter on the property and helps me prepare for my trip.',
    },
  ],
};

export const loginPageReviews = [
  {
    rating: 5,
    content:
      'Maureen and Jay are wonderful people and awesome hosts. The Wildlife is plentiful as I saw several pheasants, deer, and turkeys during my stay. The lodging is comfortable and conveniently located. I will be returning!!',
    author: 'John S',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'Shane was a great guy and an awesome host. He was very helpful in pointing us in the right direction and giving recommendations for our Turkey hunt. We saw tons of wildlife on the property!',
    author: 'Taylor W',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'The place was littered with whitetails, and also saw several antelope among other wildlife. The schoolhouse was a nice place to cook up meals, dry your clothes and catch a hot shower.',
    author: 'Cody S',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'I went to the Lovejoy Ranch on a turkey hunt and Jody and Bob were awesome hosts. Bob showed me where the birds travel through and where they are roosting. The ranch was awesome and had an excellent population of birds.',
    author: 'John S',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "We absolutely loved our stay at the ranch. This was our second time doing it. It is beautiful, remote, wild, and peaceful. A complete Montana ranch experience, like I've hoped and imagined it to be.",
    author: 'Kate O',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "Everything was even better than expected! Darin and the guys are top-notch. The lodge and accommodations are 2nd to none! Plenty of animals (in my case turkeys) and it's worth the trip just for the scenery and hospitality!",
    author: 'Blair M',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'We had a great hunt. Craig and Jo are awesome hosts and their ranch is a game-rich environment. Already looking forward to a return trip!',
    author: 'Mike C',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "One of the best hunts I've ever been on. Beautiful farm with lots of birds. Alan went out of his way to make the trip the best. The hospitality is top-notch. Would recommend the experience to any Hunter. Definitely 5 stars.",
    author: 'Doug J',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "Of all of the places in Montana that I've hunted the Sheehy ranch tops them all. Jon was amazing and the property was not only gorgeous but was full of game. The property was easily accessible and very hunter friendly.",
    author: 'Blake G',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'Amazing people and a great property. They did everything possible to make sure I had a great hunt. Will definitely come back again.',
    author: 'Timothy H',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "The Sheehy ranch is second to none! If you're looking for an unforgettable experience on private land, book this place.",
    author: 'Connor T',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "Betsy and Roger were wonderful hosts and guides for our group's tour. They are warm, funny, and inspirational people with a beautiful property and impressive operation. Thank you to you both!",
    author: 'Claire L',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "Another amazing stay at Larry's with my family! Such a beautiful place and perfect to let the kids play, grill some amazing food, and enjoy some peace & quiet. We'll be back again soon, Larry!",
    author: 'Nic D',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'Earl was very responsive and flexible, easy to talk to. His place is beautiful and there was plenty of wildlife abound. We had an awesome time on our hunt.',
    author: 'Cody S',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'I would like to say the hospitality could not get any better, really nice family had nice conversation with Chester and Rhonda over some grass feed beef. Thank you Meyers family.',
    author: 'Earl C',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      "The landowners and land both greatly exceeded my expectations. Friendly, outgoing, honest, and thoughtful in every way. I can't wait to book my next trip to this location.",
    author: 'Daniel P',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'Great property and Caleb did a wonderful job get me up to speed. Very prompt and detailed in communications. Appreciate all the help!',
    author: 'Randy T',
    isAuthorVerified: true,
  },
  {
    rating: 5,
    content:
      'We had a wonderful stay at Muddy Creek. We camped in our RV on the property. It was quiet and peaceful. We enjoyed dinner at the landowner’s restaurant in town and absolutely loved learning about the ranch and all they do.',
    author: 'Lindsey M',
    isAuthorVerified: true,
  },
];

export const MAX_MOBILE_SCREEN_WIDTH = 768;
