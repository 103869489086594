/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { bool, number, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage, intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import {
  getTabList,
  tabsActive,
  LANDOWNER_STORY,
  SUMMARY,
  tabsCompleted,
} from '../../util/editListing';
import { createResourceLocatorString } from '../../util/routes';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { createStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import {
  EditListingWizard,
  EditListingNamedSkipLink,
  NamedRedirect,
  NamedBackLink,
  Page,
  EditListingProgressBar,
  EditListingSection,
} from '../../components';
import { Container } from '../../components/Layout';
import CustomerExperienceCard from '../../components/CustomerExperienceCard/CustomerExperienceCard';
import { TopbarContainer } from '..';

// TODO: move this to EditListingPage.duck
import { updateProfile, uploadImage } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

import {
  requestFetchBookings,
  requestFetchAvailabilityExceptions,
  requestCreateAvailabilityException,
  requestDeleteAvailabilityException,
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestUpdateListing,
  loadData,
  clearUpdatedTab,
} from './EditListingPage.duck';

import css from './EditListingPage.css';
import { syncEarlyAccessExpiration } from '../../util/package';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

const { UUID } = sdkTypes;

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];

  return { ...params, tab: nextTab };
};

const redirectToRoute = (routeName, params, history) => {
  const routes = routeConfiguration();
  const to = createResourceLocatorString(routeName, routes, params, {});
  history.push(to);
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  redirectToRoute('EditListingPage', nextPathParams, history);
};

// N.B. All the presentational content needs to be extracted to their own components
export const EditListingPageComponent = props => {
  const {
    currentUser,
    currentUserHasListings,
    createStripeAccountError,
    fetchInProgress,
    getOwnListing,
    history,
    intl,
    onFetchAvailabilityExceptions,
    onCreateAvailabilityException,
    onDeleteAvailabilityException,
    onFetchBookings,
    createListingDraft,
    onPublishListingDraft,
    onUpdateListing,
    onManageDisableScrolling,
    onChange,
    page,
    params,
    scrollingDisabled,
    stripeAccount,
    onUpdateProfile,
    onUploadProfileImage,
  } = props;

  const { id, type, returnURLType } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const listingId = page.submittedListingId || (id ? new UUID(id) : null);
  const currentListing = ensureOwnListing(getOwnListing(listingId));
  const { state: currentListingState } = currentListing.attributes;

  const isPastDraft = currentListingState && currentListingState !== LISTING_STATE_DRAFT;
  const shouldRedirect = isNewListingFlow && listingId && isPastDraft;

  const hasStripeOnboardingDataIfNeeded = returnURLType ? !!(currentUser && currentUser.id) : true;
  const showForm = hasStripeOnboardingDataIfNeeded && (isNewURI || currentListing.id);

  if (shouldRedirect) {
    const isPendingApproval =
      currentListing && currentListingState === LISTING_STATE_PENDING_APPROVAL;

    // If page has already listingId (after submit) and current listings exist
    // redirect to listing page
    const listingSlug = currentListing ? createSlug(currentListing.attributes.title) : null;

    const redirectProps = isPendingApproval
      ? {
          name: 'ListingPageVariant',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
          },
        }
      : {
          name: 'ListingPage',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
          },
        };

    return <NamedRedirect {...redirectProps} />;
  }

  if (showForm) {
    const {
      createListingDraftError = null,
      publishListingError = null,
      updateListingError = null,
      showListingsError = null,
      uploadImageError = null,
    } = page;
    const errors = {
      createListingDraftError,
      publishListingError,
      updateListingError,
      showListingsError,
      uploadImageError,
      createStripeAccountError,
    };

    const newListingPublished =
      isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

    const title = isNewListingFlow
      ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
      : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });

    const availableTabs = getTabList(currentListing.attributes.publicData.categories).filter(
      tab => {
        if (tab === LANDOWNER_STORY && (!isNewListingFlow || currentUserHasListings)) return false;
        return true;
      }
    );

    const availableRequiredTabs = availableTabs.filter(tab => tab !== SUMMARY);
    const currentTab = params.tab;
    const currentTabIndex = availableRequiredTabs.indexOf(currentTab);
    const progress = Math.round(((currentTabIndex + 1) / availableRequiredTabs.length) * 100);
    const onFirstPage = currentTabIndex === 0;
    const tabsStatus = tabsActive(availableTabs, isNewListingFlow, currentListing);
    const previousActiveTab = availableTabs
      .slice(0, currentTabIndex)
      .reverse()
      .find(t => tabsStatus[t]);
    const nextTab =
      currentTabIndex + 1 < availableTabs.length ? availableTabs[currentTabIndex + 1] : null;
    const hideProgressBar = [SUMMARY].includes(currentTab);
    const canBePublished = tabsCompleted(currentListing);

    const exit = () => {
      // It was decided that we would not try to save, because of the refactor needed, just exit
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}));
    };

    const backLink = (
      <NamedBackLink name="EditListingPage" params={{ ...params, tab: previousActiveTab }} />
    );

    const skipLink = (
      <EditListingNamedSkipLink name="EditListingPage" params={{ ...params, tab: nextTab }} />
    );

    const exitButton = (
      <button type="button" className={css.topBarExitButton} onClick={exit}>
        {intl.formatMessage({ id: 'EditListingPage.exitButton' })}
      </button>
    );

    const submitButtonText =
      isNewListingFlow && !canBePublished
        ? intl.formatMessage({ id: 'EditListingWizard.saveNew' })
        : intl.formatMessage({ id: 'EditListingWizard.saveEdit' });

    // TODO: (listing edit) I moved all this submit logic to the top level because it made sense
    // that the page controls redirects, it also seems like some of the new buttons in the redesign
    // will need to reuse the saving logic
    const onSubmit = (tab, updateValues) => {
      // Normalize images for API call
      const { images, ...otherValues } = updateValues;
      const maybeImages =
        typeof images !== 'undefined' ? { images: images.map(img => img.id) } : {};
      const updateValuesWithImages = { ...otherValues, ...maybeImages };

      if (isNewListingFlow) {
        const onUpsertListingDraft = isNewURI
          ? (_, values) => createListingDraft(values)
          : onUpdateListing;

        let upsertValues = isNewURI
          ? updateValuesWithImages
          : { ...updateValuesWithImages, id: currentListing.id };

        if (tab === SUMMARY) {
          // If landowner is trying to publish a new listing, we need to sync the expiration date of the early access packages
          const allPackages = get(currentListing, 'attributes.publicData.packages', []);
          const updatedPackages = syncEarlyAccessExpiration(allPackages);

          upsertValues = {
            ...upsertValues,
            publicData: {
              ...upsertValues.publicData,
              packages: updatedPackages,
            },
          };
        }

        onUpsertListingDraft(tab, upsertValues)
          .then(r => {
            if (tab !== availableTabs[availableTabs.length - 1]) {
              if (canBePublished) {
                // Redirect to the last tab
                redirectAfterDraftUpdate(
                  r.data.data.id.uuid,
                  params,
                  availableTabs[availableTabs.length - 1],
                  availableTabs,
                  history
                );
              } else {
                // After successful saving of draft data, user should be redirected to next tab
                redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, availableTabs, history);
              }
            } else {
              onPublishListingDraft(currentListing.id);
            }
          })
          .catch(() => {
            // No need for extra actions
          });
      } else {
        onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id }).then(() => {
          let routeParams = {
            ...params,
            tab: SUMMARY,
          };

          let routeName = 'EditListingPage';

          if (currentTab === SUMMARY) {
            routeParams = {};
            routeName = 'ManageListingsPage';
          }

          redirectToRoute(routeName, routeParams, history);
        });
      }
    };

    return (
      <Page className={css.page} title={title} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileClassName={css.topbarMobile}
          customMobilePrimaryActions={onFirstPage ? <>{/* Intentionally empty */}</> : backLink}
          customMobileSecondaryActions={exitButton}
          customDesktopSecondaryActions={exitButton}
        />

        <Container className={css.container}>
          <div className={css.formContainer}>
            {!hideProgressBar && (
              <EditListingSection className={css.progressBarSection} isFullWidth>
                <EditListingProgressBar
                  pageLabel={intl.formatMessage({
                    id: `EditListingPage.pageLabel.${currentTab}`,
                  })}
                  progress={progress}
                />
              </EditListingSection>
            )}

            <EditListingWizard
              id="EditListingWizard"
              params={params}
              errors={errors}
              fetchInProgress={fetchInProgress}
              newListingPublished={newListingPublished}
              history={history}
              listing={currentListing}
              availability={{
                calendar: page.availabilityCalendar,
                onFetchAvailabilityExceptions,
                onCreateAvailabilityException,
                onDeleteAvailabilityException,
                onFetchBookings,
              }}
              onChange={onChange}
              currentUser={currentUser}
              currentUserHasListings={currentUserHasListings}
              onManageDisableScrolling={onManageDisableScrolling}
              updatedTab={page.updatedTab}
              updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
              stripeAccount={stripeAccount}
              onSubmit={onSubmit}
              onUpdateProfile={onUpdateProfile}
              onUploadProfileImage={onUploadProfileImage}
              backLink={onFirstPage || canBePublished ? null : backLink}
              skipLink={nextTab && !canBePublished ? skipLink : null}
              isNewListingFlow={isNewListingFlow}
              submitButtonText={submitButtonText}
            />
          </div>

          <div className={css.coltonContainer}>
            <CustomerExperienceCard
              className={css.fullColton}
              isHorizontal={false}
              cta={<FormattedHTMLMessage id="LandownerExperienceCard.cta" />}
            />

            <CustomerExperienceCard.mini
              className={css.miniColton}
              cta={<FormattedHTMLMessage id="LandownerExperienceCard.cta" />}
            />
          </div>
        </Container>
      </Page>
    );
  }
  // If user has come to this page through a direct linkto edit existing listing,
  // we need to load it first.
  const loadingPageMsg = {
    id: 'EditListingPage.loadingListingData',
  };
  return <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled} />;
};

EditListingPageComponent.defaultProps = {
  createStripeAccountError: null,
  currentUser: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
};

EditListingPageComponent.propTypes = {
  createStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser,
  currentUserHasOrders: bool,
  listing: object,
  listingDraft: object,
  notificationCount: number,
  sendVerificationEmailError: object,
  fetchInProgress: bool.isRequired,
  getOwnListing: func.isRequired,
  onFetchAvailabilityExceptions: func.isRequired,
  onCreateAvailabilityException: func.isRequired,
  createListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onUpdateListing: func.isRequired,
  onUploadProfileImage: func.isRequired,
  onChange: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: string.isRequired,
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }).isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditListingPage;
  const {
    getAccountLinkInProgress,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
  } = state.stripeConnectAccount;

  const { currentUser, currentUserHasListings } = state.user;

  const fetchInProgress = createStripeAccountInProgress;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };
  return {
    getAccountLinkInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    currentUser,
    currentUserHasListings,
    fetchInProgress,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: (tab, values) => dispatch(requestUpdateListing(tab, values)),
  onFetchBookings: params => dispatch(requestFetchBookings(params)),
  onFetchAvailabilityExceptions: params => dispatch(requestFetchAvailabilityExceptions(params)),
  onCreateAvailabilityException: params => dispatch(requestCreateAvailabilityException(params)),
  onDeleteAvailabilityException: params => dispatch(requestDeleteAvailabilityException(params)),
  createListingDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPayoutDetailsSubmit: values => dispatch(createStripeAccount(values)),
  onChange: () => dispatch(clearUpdatedTab()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onUploadProfileImage: data => dispatch(uploadImage(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(EditListingPageComponent));

EditListingPage.loadData = loadData;

export default EditListingPage;
