import React from 'react';
import { get } from 'lodash';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PrimaryButton } from '../Button/Button';
import { propTypes } from '../../util/types';

import css from './ContactLandownerButton.css';

const ContactLandownerButton = ({ user, onContactUser, isDisabled, isOwnListing, mobileHost }) => {
  const landownerName = get(user, 'attributes.profile.displayName', 'Landowner');

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  return (
    <PrimaryButton
      className={css.contactButton}
      onClick={handleContactUserClick}
      disabled={isOwnListing || isDisabled}
      data-testId="contact-landowner-button"
    >
      {mobileHost ? (
        <FormattedMessage id="ListingPage.contactOnly" />
      ) : (
        <FormattedMessage
          id="ListingPage.contactLandowner"
          values={{ name: landownerName?.split(' ')[0] }}
        />
      )}
    </PrimaryButton>
  );
};

ContactLandownerButton.defaultProps = {
  user: null,
  onContactUser: null,
};

ContactLandownerButton.propTypes = {
  user: propTypes.user,
  onContactUser: func,
};

export default ContactLandownerButton;
