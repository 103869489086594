/* eslint-disable no-param-reassign */
import React, { useMemo, useRef } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { ensureOwnListing } from '../../util/data';
import { FARM_RANCH_ACTIVITY } from '../../marketplace-custom-config';
import { getInitialPackage } from '../../util/package';
import { EditListingPackagesSection, EditListingBaseExperiencePanel } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingFarmExperienceForm, EditListingCreatePackageForm } from '../../forms';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const publicData = get(currentListing, 'attributes.publicData', {});
  const farm = get(publicData, 'activities.farm', {});
  const landDetails = get(farm, 'landDetails', {});
  const amenities = get(farm, 'amenities', {});

  const initialValues = {
    hasActiveWorkingRanch: get(landDetails, 'hasActiveWorkingRanch', false),
    activeWorkingRanchInfo: get(landDetails, 'activeWorkingRanchInfo', null),
    hasCatering: get(amenities, 'hasCatering', false),
    cateringInfo: get(amenities, 'cateringInfo', null),
    hasSwimming: get(amenities, 'hasSwimming', false),
    swimmingInfo: get(amenities, 'swimmingInfo', null),
    hasAtv: get(amenities, 'hasAtv', false),
    atvInfo: get(amenities, 'atvInfo', null),
  };

  return initialValues;
};

const getSubmissionValues = (listing, values) => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentActivities = get(publicData, 'activities', {});

  // Land details
  const landDetails = {
    hasActiveWorkingRanch: values.hasActiveWorkingRanch,
    activeWorkingRanchInfo: values.hasActiveWorkingRanch ? values.activeWorkingRanchInfo : null,
  };

  // Amenities
  const amenities = {
    hasCatering: values.hasCatering,
    cateringInfo: values.hasCatering ? values.cateringInfo : null,
    hasActivities: values.hasActivities,
    activitiesInfo: values.hasActivities ? values.activitiesInfo : null,
    hasSwimming: values.hasSwimming,
    swimmingInfo: values.hasSwimming ? values.swimmingInfo : null,
    hasAtv: values.hasAtv,
    atvInfo: values.hasAtv ? values.atvInfo : null,
  };

  const submissionValues = {
    // sending Base-Price to Flex Console as $0.00
    price: { _sdkType: 'Money', amount: 0, currency: 'USD' },
    publicData: {
      activities: {
        ...currentActivities,
        [FARM_RANCH_ACTIVITY]: {
          ...currentActivities[FARM_RANCH_ACTIVITY],
          landDetails,
          amenities,
        },
      },
    },
  };

  return submissionValues;
};

const EditListingFarmExperiencePanel = props => {
  const {
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    intl,
  } = props;
  const updatedValuesRef = useRef({});
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingFarmExperiencePanel.title" />
  ) : (
    <FormattedMessage id="EditListingFarmExperiencePanel.createListingTitle" />
  );

  const initialValues = useMemo(() => {
    const values = getInitialValues(listing);

    return {
      ...values,
      ...updatedValuesRef.current,
    };
  }, [listing]);

  return (
    <EditListingBaseExperiencePanel
      title={panelTitle}
      listing={currentListing}
      activity={FARM_RANCH_ACTIVITY}
    >
      {baseProps => {
        return (
          <>
            <EditListingFarmExperienceForm
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                onSubmit(getSubmissionValues(currentListing, values, baseProps.packages));
              }}
              onUpdate={values => {
                // Keep the values of the child form. I had to use ref here to avoid too many state updates
                updatedValuesRef.current = values;
              }}
              onChange={onChange}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              backLink={backLink}
              listing={currentListing}
              packages={baseProps.packages}
              packageSection={
                <EditListingPackagesSection
                  onCreatePackageMode={baseProps.handleCreatePackage}
                  onEditPackage={baseProps.handleEditPackage}
                  onTogglePublishPackage={baseProps.handleTogglePublishPackage}
                  onDuplicatePackage={baseProps.handleUpdatePackage}
                  onUpdatePackage={baseProps.handleUpdatePackage}
                  onRemovePackage={baseProps.handleRemovePackage}
                  packages={baseProps.packages}
                  selectedPackage={baseProps.selectedPackage}
                  currentListing={currentListing}
                />
              }
            />

            <EditListingCreatePackageForm
              activity={FARM_RANCH_ACTIVITY}
              initialValues={getInitialPackage(baseProps.selectedPackage, FARM_RANCH_ACTIVITY)}
              onClose={() => {
                baseProps.setCreatePackageMode(false);
              }}
              currentListing={currentListing}
              active={baseProps.createPackageMode}
              onUpdatePackage={async values => {
                await baseProps.handleUpdatePackage(values);
              }}
              titlePlaceholder={intl.formatMessage({
                id: 'EditListingFarmExperiencePanel.packageTitlePlaceholder',
              })}
              descriptionPlaceholder={intl.formatMessage({
                id: 'EditListingFarmExperiencePanel.packageDescriptionPlaceholder',
              })}
              imageCategories={['landscape', 'activities', 'more']}
              intl={intl}
              isOpen={baseProps.createPackageMode}
            />
          </>
        );
      }}
    </EditListingBaseExperiencePanel>
  );
};

EditListingFarmExperiencePanel.defaultProps = {
  listing: null,
  backLink: null,
};

EditListingFarmExperiencePanel.propTypes = {
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default injectIntl(EditListingFarmExperiencePanel);
