import { get } from './api';
import { getStateAbbreviationFromName } from './usStates';

export const getSeasonInfo = (species, hd, listingLocation) => {
  const queryParams = [];

  if (!species) {
    throw new Error('No species provided');
  }

  queryParams.push(`range=${encodeURIComponent(species.join(','))}`);

  if (hd !== undefined) queryParams.push(`hd=${encodeURIComponent(hd)}`);

  let statePath = '';
  if (
    listingLocation &&
    listingLocation.usState &&
    getStateAbbreviationFromName(listingLocation.usState)
  ) {
    statePath = getStateAbbreviationFromName(listingLocation.usState);
  }

  if (!statePath) {
    throw new Error('No state path provided');
  }

  const queryString = queryParams.join('&');
  const url = `/api/season-info/${statePath.toLowerCase()}${queryString ? `?${queryString}` : ''}`;

  // eslint-disable-next-line no-console
  console.log('url', url);

  return get(url)
  .then(data => data)
  .catch(error => {
    console.error('Error fetching season info:', error);
    return { error: true, message: error.message };
  });
};
