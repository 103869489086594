/* eslint-disable no-param-reassign */
import React, { useMemo, useRef } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { ensureOwnListing, overrideGet } from '../../util/data';
import { BIRD_ACTIVITY } from '../../marketplace-custom-config';
import { getInitialPackage } from '../../util/package';
import { EditListingPackagesSection, EditListingBaseExperiencePanel } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingBirdExperienceForm, EditListingCreatePackageForm } from '../../forms';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const publicData = get(currentListing, 'attributes.publicData', {});
  const bird = get(publicData, 'activities.bird', {});
  const landDetails = get(bird, 'landDetails', {});
  const amenities = get(bird, 'amenities', {});

  const initialValues = {
    hasNationalParks: get(landDetails, 'hasNationalParks', false),
    hasWildlifeRefuges: get(landDetails, 'hasWildlifeRefuges', false),
    habitatDiversity: get(landDetails, 'habitatDiversity', []),
    habitatDiversityInfo: get(landDetails, 'habitatDiversityInfo', null),
    birdSpecies: get(landDetails, 'birdSpecies', null),
    hasBirdBlinds: get(amenities, 'hasBirdBlinds', false),
    birdBlinds: overrideGet(amenities, 'birdBlinds', 0),
    hasBirdFeeders: get(amenities, 'hasBirdFeeders', false),
    birdFeeders: overrideGet(amenities, 'birdFeeders', 0),
    hasCatering: get(amenities, 'hasCatering', false),
    cateringInfo: get(amenities, 'cateringInfo', null),
    hasBirdingGuide: get(amenities, 'hasBirdingGuide', false),
    birdingGuideInfo: get(amenities, 'birdingGuideInfo', null),
    hasActivities: get(amenities, 'hasActivities', false),
    activitiesInfo: get(amenities, 'activitiesInfo', null),
    images: [],
  };

  return initialValues;
};

const getSubmissionValues = (listing, values) => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentActivities = get(publicData, 'activities', {});

  // Land details
  const landDetails = {
    birdSpecies: values.birdSpecies || '',
    habitatDiversity: values.habitatDiversity || [],
    habitatDiversityInfo: values.habitatDiversityInfo || '',
    hasNationalParks: values.hasNationalParks,
    hasWildlifeRefuges: values.hasWildlifeRefuges,
  };

  // Amenities
  const amenities = {
    hasBirdBlinds: values.hasBirdBlinds,
    birdBlinds: values.hasBirdBlinds ? values.birdBlinds : 0,
    hasBirdFeeders: values.hasBirdFeeders,
    birdFeeders: values.hasBirdFeeders ? values.birdFeeders : 0,
    hasCatering: values.hasCatering,
    cateringInfo: values.hasCatering ? values.cateringInfo : null,
    hasBirdingGuide: values.hasBirdingGuide,
    birdingGuideInfo: values.hasBirdingGuide ? values.birdingGuideInfo : null,
    hasActivities: values.hasActivities,
    activitiesInfo: values.hasActivities ? values.activitiesInfo : null,
  };

  const submissionValues = {
    // sending Base-Price to Flex Console as $0.00 (if landowner sends hunt/fish/access prices)
    price: { _sdkType: 'Money', amount: 0, currency: 'USD' },
    publicData: {
      activities: {
        ...currentActivities,
        [BIRD_ACTIVITY]: {
          ...currentActivities[BIRD_ACTIVITY],
          landDetails,
          amenities,
        },
      },
    },
  };

  return submissionValues;
};

const EditListingBirdExperiencePanel = props => {
  const {
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    intl,
  } = props;
  const updatedValuesRef = useRef({});
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingBirdExperiencePanel.title" />
  ) : (
    <FormattedMessage id="EditListingBirdExperiencePanel.createListingTitle" />
  );

  const initialValues = useMemo(() => {
    const values = getInitialValues(listing);

    return {
      ...values,
      ...updatedValuesRef.current,
    };
  }, [listing]);

  return (
    <EditListingBaseExperiencePanel
      title={panelTitle}
      listing={currentListing}
      activity={BIRD_ACTIVITY}
    >
      {baseProps => {
        return (
          <>
            <EditListingBirdExperienceForm
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                onSubmit(getSubmissionValues(currentListing, values, baseProps.packages));
              }}
              onUpdate={values => {
                // Keep the values of the child form. I had to use ref here to avoid too many state updates
                updatedValuesRef.current = values;
              }}
              onChange={onChange}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              backLink={backLink}
              listing={currentListing}
              packages={baseProps.packages}
              packageSection={
                <EditListingPackagesSection
                  onCreatePackageMode={baseProps.handleCreatePackage}
                  onEditPackage={baseProps.handleEditPackage}
                  onTogglePublishPackage={baseProps.handleTogglePublishPackage}
                  onDuplicatePackage={baseProps.handleUpdatePackage}
                  onUpdatePackage={baseProps.handleUpdatePackage}
                  onRemovePackage={baseProps.handleRemovePackage}
                  packages={baseProps.packages}
                  selectedPackage={baseProps.selectedPackage}
                  currentListing={currentListing}
                />
              }
            />

            <EditListingCreatePackageForm
              activity={BIRD_ACTIVITY}
              initialValues={getInitialPackage(baseProps.selectedPackage, BIRD_ACTIVITY)}
              onClose={() => {
                baseProps.setCreatePackageMode(false);
              }}
              currentListing={currentListing}
              active={baseProps.createPackageMode}
              onUpdatePackage={async values => {
                await baseProps.handleUpdatePackage(values);
              }}
              titlePlaceholder={intl.formatMessage({
                id: 'EditListingBirdExperiencePanel.packageTitlePlaceholder',
              })}
              descriptionPlaceholder={intl.formatMessage({
                id: 'EditListingBirdExperiencePanel.packageDescriptionPlaceholder',
              })}
              imageCategories={['birds', 'landscape', 'guest', 'more']}
              intl={intl}
              isOpen={baseProps.createPackageMode}
            />
          </>
        );
      }}
    </EditListingBaseExperiencePanel>
  );
};

EditListingBirdExperiencePanel.defaultProps = {
  listing: null,
  backLink: null,
};

EditListingBirdExperiencePanel.propTypes = {
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,
};

export default injectIntl(EditListingBirdExperiencePanel);
