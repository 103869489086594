import React from 'react';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import {
  FieldTextInput,
  EditListingFieldWrapper,
  EditListingSection,
  FieldPillInputGroup,
  FieldCondition,
  FieldNumberInput,
  FieldToggle,
} from '../../components';
import {
  houseAmenitiesOptions,
  houseCookingOptions,
  garbageAreaOptions,
} from '../../marketplace-custom-config';
import EditListingBedFields from './EditListingBedFields';
import { composeValidators, required, atLeast } from '../../util/validators';

const EditListingLodgingHouseFields = ({ intl }) => {
  return (
    <>
      <EditListingSection hasBackground hasBottomMargin>
        <EditListingFieldWrapper>
          <FieldNumberInput
            id="house.sportsmen"
            name="house.sportsmen"
            label={intl.formatMessage({
              id: 'EditListingLodgingHouseFields.sportsmenLabel',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'EditListingLodgingForm.addValue',
                })
              ),
              atLeast(
                intl.formatMessage({
                  id: 'EditListingLodgingForm.valueAtLeastOne',
                }),
                1
              )
            )}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldNumberInput
            id="house.bedrooms"
            name="house.bedrooms"
            label={intl.formatMessage({
              id: 'EditListingLodgingHouseFields.bedroomsLabel',
            })}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <EditListingBedFields namespace="house" />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldTextInput
            id="house.bedroomInfo"
            name="house.bedroomInfo"
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingForms.additionalInfoLabel',
            })}
          />
        </EditListingFieldWrapper>
      </EditListingSection>

      <EditListingSection
        hasBackground
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step2Title' })}
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldNumberInput id="house.bathrooms" name="house.bathrooms" step={0.5} />
        </EditListingFieldWrapper>

        <FieldCondition when="house.bathrooms" operator="gt" is={0}>
          <EditListingFieldWrapper>
            <FieldToggle
              name="house.hasShower"
              label={intl.formatMessage({ id: 'EditListingLodgingHouseFields.hasShower' })}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldToggle
              name="house.hasHotWater"
              label={intl.formatMessage({ id: 'EditListingLodgingHouseFields.hasHotWater' })}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="house.bathroomInfo"
              name="house.bathroomInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step3Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldPillInputGroup
            name="house.amenities"
            id="house.amenities"
            options={houseAmenitiesOptions}
          />
        </EditListingFieldWrapper>

        <EditListingFieldWrapper>
          <FieldTextInput
            id="house.amenitiesInfo"
            name="house.amenitiesInfo"
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingForms.additionalInfoLabel',
            })}
          />
        </EditListingFieldWrapper>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step4Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="house.hasCooking" />
        </EditListingFieldWrapper>

        <FieldCondition when="house.hasCooking" is>
          <EditListingFieldWrapper>
            <FieldPillInputGroup
              name="house.cooking"
              id="house.cooking"
              options={houseCookingOptions}
              minColumnWidth={150}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="house.cookingInfo"
              name="house.cookingInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step5Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="house.hasGarbage" />
        </EditListingFieldWrapper>

        <FieldCondition when="house.hasGarbage" is>
          <EditListingFieldWrapper>
            <FieldPillInputGroup
              name="house.garbage"
              id="house.garbage"
              options={garbageAreaOptions}
            />
          </EditListingFieldWrapper>

          <EditListingFieldWrapper>
            <FieldTextInput
              id="house.garbageInfo"
              name="house.garbageInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step6Title' })}
        hasBackground
        hasBottomMargin
      >
        <EditListingFieldWrapper>
          <FieldToggle name="house.allowsPets" />
        </EditListingFieldWrapper>

        <FieldCondition when="house.allowsPets" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="house.petsInfo"
              name="house.petsInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>

      <EditListingSection
        title={intl.formatMessage({ id: 'EditListingLodgingHouseFields.step8Title' })}
        hasBackground
      >
        <EditListingFieldWrapper>
          <FieldToggle name="house.hasWifi" />
        </EditListingFieldWrapper>

        <FieldCondition when="house.hasWifi" is>
          <EditListingFieldWrapper>
            <FieldTextInput
              id="house.wifiInfo"
              name="house.wifiInfo"
              type="textarea"
              label={intl.formatMessage({
                id: 'EditListingForms.additionalInfoLabel',
              })}
            />
          </EditListingFieldWrapper>
        </FieldCondition>
      </EditListingSection>
    </>
  );
};

EditListingLodgingHouseFields.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingLodgingHouseFields);
