/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form, PrimaryButton } from '../../components';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import css from './IdpSignupForm.css';

const KEY_CODE_ENTER = 13;

const IdpSignupFormComponent = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [allSteps, setAllSteps] = useState(1);
  const [allValues, setAllValues] = useState({
    email: props?.authInfo?.email,
    firstName: props?.authInfo?.firstName,
    lastName: props?.authInfo?.lastName,
    method: props?.idp,
    phoneNumber: null,
    role: [],
    preferredHuntingSpecies: [],
    preferredHuntingStates: [],
    interestedActivities: [],
    usState: null,
    referralMethod: null,
    referralOther: null,
  });

  const handleFormSubmit = async values => {
    const { onSubmit } = props;

    // Make sure we scroll the page on top for mobile users especially
    window.scrollTo(0, 0);

    if (activeStep === allSteps) {
      onSubmit(values);
    } else {
      setAllValues(values);
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <div>
      <FinalForm
        keepDirtyOnReinitialize
        initialValues={allValues}
        {...props}
        mutators={{ ...arrayMutators }}
        onSubmit={handleFormSubmit}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            inProgress,
            invalid,
            onOpenTermsOfService,
            authInfo,
            confirmErrorMessage,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService();
            }
          };
          const termsLink = (
            <span
              className={css.termsLink}
              onClick={onOpenTermsOfService}
              role="button"
              tabIndex="0"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
            </span>
          );

          // If authInfo is not available we should not show the ConfirmForm
          if (!authInfo) {
            return;
          }

          const steps = [
            <Step2 setAllSteps={setAllSteps} {...fieldRenderProps} />,
            <Step3 {...fieldRenderProps} />,
          ];

          const currentStep = steps[activeStep];

          // eslint-disable-next-line consistent-return
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {currentStep}

              {confirmErrorMessage}

              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  isFullWidth
                >
                  {activeStep > 0 ? (
                    <FormattedMessage id="IdpSignupForm.createMyAccount" />
                  ) : (
                    <FormattedMessage id="IdpSignupForm.finalStep" />
                  )}
                </PrimaryButton>

                <p className={css.bottomWrapperText}>
                  {activeStep > 0 ? (
                    <span className={css.termsText}>
                      <FormattedMessage
                        id="SignupForm.termsAndConditionsAcceptText"
                        values={{ termsLink }}
                      />
                    </span>
                  ) : (
                    <span className={css.termsText}>2 of 3</span>
                  )}
                </p>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

IdpSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

IdpSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const IdpSignupForm = compose(injectIntl)(IdpSignupFormComponent);
IdpSignupForm.displayName = 'IdpSignupForm';

export default IdpSignupForm;
