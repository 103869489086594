import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import NamedLink from '../NamedLink/NamedLink';
import css from './SpeciesCard.css';

const SpeciesCard = ({
  src,
  label,
  title,
  routeName,
  routeTo,
  routeParams,
  className,
  imageContainerClassName,
  imageClassName,
  titleClassName,
}) => {
  if (!src) return null;

  return (
    <NamedLink
      className={classnames(css.root, className)}
      name={routeName}
      to={routeTo}
      params={routeParams}
    >
      <div className={css.contentContainer}>
        <div className={imageContainerClassName || css.imageContainer}>
          <picture>
            <source srcSet={src.webp} type="image/webp" />
            <source srcSet={src.png} type="image/png" />
            <img src={src.png} alt={label} className={imageClassName || css.image} />
          </picture>
        </div>
      </div>
      <h3 className={titleClassName || css.title}>{title}</h3>
    </NamedLink>
  );
};

SpeciesCard.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  routeName: PropTypes.string.isRequired,
  routeTo: PropTypes.object,
  routeParams: PropTypes.object,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  imageContainerClassName: PropTypes.string,
};

SpeciesCard.defaultProps = {
  className: '',
  imageClassName: '',
  titleClassName: '',
  imageContainerClassName: '',
  routeTo: {},
  routeParams: {},
};

export default SpeciesCard;
