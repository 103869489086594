import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form, PrimaryButton, FieldTextInput, FieldEmailInput } from '../../components';

import css from './PartyMembersInfoForm.css';

const PartyMembersInfoFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={fieldRenderProps => {
      const { rootClassName, className, handleSubmit, inProgress, intl, values } = fieldRenderProps;
      // email
      const emailPlaceholder = intl.formatMessage({
        id: 'PartyMembersInfoForm.emailPlaceholder',
      });
      const hasAtLeastOneFirstName =
        values.partyMemberDetails &&
        values.partyMemberDetails.some(
          member => member.firstName && member.firstName.trim() !== ''
        );

      const emailLabel = intl.formatMessage({ id: 'PartyMembersInfoForm.emailLabel' });

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'PartyMembersInfoForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'PartyMembersInfoForm.firstNamePlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = !hasAtLeastOneFirstName || inProgress;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.fieldContainer}>
            <FieldArray name="partyMemberDetails">
              {({ fields }) => (
                <div>
                  {fields.map((name, index) => (
                    <div key={name} className={css.indForm}>
                      <div className={css.fieldsContainer}>
                        <FieldTextInput
                          className={css.fieldsWrapper}
                          id={`firstNameInput.${index}`}
                          type="text"
                          name={`${name}.firstName`}
                          autoComplete="given-name"
                          label={firstNameLabel}
                          placeholder={firstNamePlaceholder}
                        />

                        <FieldEmailInput
                          className={css.fieldsWrapper}
                          id={`emailInput.${index}`}
                          name={`${name}.email`}
                          label={emailLabel}
                          placeholder={emailPlaceholder}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <div className={css.buttonContainer}>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                className={css.shareButton}
              >
                <FormattedMessage id="PartyMembersInfoForm.sendInvite" />
              </PrimaryButton>
              <div className={css.requiredContainer}>
                <p className={css.requiredText}>Not required for guests 17 years or younger.</p>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

PartyMembersInfoFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

PartyMembersInfoFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PartyMembersInfoForm = compose(injectIntl)(PartyMembersInfoFormComponent);
PartyMembersInfoForm.displayName = 'PartyMembersInfoForm';

export default PartyMembersInfoForm;
