import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { txRoleIsProvider } from '../../util/transaction';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionExpire = ({ transition, user, provider, ownRole, buttons }) => {
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));
  const authorDisplayName = getFirstName(get(provider, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        txRoleIsProvider(ownRole) ? (
          <FormattedMessage id="BookingStatus.ownTransitionExpire" />
        ) : (
          <FormattedMessage id="BookingStatus.transitionExpire" values={{ displayName }} />
        )
      }
      buttons={buttons}
    >
      {txRoleIsProvider(ownRole) ? null : (
        <FormattedMessage
          id="BookingStatus.transitionExpireSubtext"
          values={{ authorDisplayName, bookNextTrip: '' }}
        />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionExpire;
