import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, FormattedHTMLMessage, intlShape, injectIntl } from 'react-intl';
import { OnChange } from 'react-final-form-listeners';

import { ExternalLink, FieldRadioButton } from '..';

import css from './FieldTripInsuranceToggle.css';

const FieldTripInsuranceToggle = ({
  name,
  onChange,
  insuranceCost,
  insuranceOptionSelected,
  ...rest
}) => {
  const insuranceYesLabel = (
    <span className={css.insuranceToggle}>
      <FormattedHTMLMessage
        id="FieldTripInsuranceToggle.toggleYes"
        values={{ price: insuranceCost }}
      />
    </span>
  );
  const insuranceNoLabel = (
    <span className={css.insuranceToggle}>
      <FormattedHTMLMessage id="FieldTripInsuranceToggle.toggleNo" />
    </span>
  );

  return (
    <div className={css.root}>
      <h3 className={css.formHeading}>
        <FormattedMessage id="FieldTripInsurance.insuranceLabel" />
      </h3>
      <h3 className={css.subHeading}>
        <FormattedHTMLMessage id="FieldTripInsurance.insuranceSubHeading" />
      </h3>
      <div className={css.radioWrapper}>
        <OnChange name={name}>
          {(value, prevValue) => {
            onChange(value, prevValue);
          }}
        </OnChange>
        <div className={insuranceOptionSelected ? css.selected : css.insuranceOptionWrapper}>
          <FieldRadioButton
            name={name}
            id={`${name}Yes`}
            value="true"
            label={insuranceYesLabel}
            parse={() => true}
            format={value => String(value)}
            {...rest}
          />
          <div>
            <ExternalLink
              href="https://www.csatravelprotection.com/certpolicy.do?product=GR330"
              className={css.insuranceLink}
            >
              <FormattedMessage id="FieldTripInsurance.insuranceLink" />
            </ExternalLink>
          </div>
          <h3 className={css.subTextYes}>
            <FormattedHTMLMessage id="FieldTripInsurance.insuranceYesSubText" />
          </h3>
        </div>
        <div
          className={insuranceOptionSelected === false ? css.selected : css.insuranceOptionWrapper}
        >
          <FieldRadioButton
            name={name}
            id={`${name}No`}
            value="false"
            label={insuranceNoLabel}
            parse={() => false}
            format={value => String(value)}
            {...rest}
          />
          <h3 className={css.subTextNo}>
            <FormattedHTMLMessage id="FieldTripInsurance.insuranceNoSubText" />
          </h3>
        </div>
      </div>
    </div>
  );
};

FieldTripInsuranceToggle.propTypes = {
  intl: intlShape.isRequired,
  onChange: func,
  insuranceOptionSelected: bool,
};

FieldTripInsuranceToggle.defaultProps = {
  onChange: () => {},
  insuranceOptionSelected: null,
};

export default compose(injectIntl)(FieldTripInsuranceToggle);
