import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-montana',
    predictionPlace: {
      address: 'Montana',
      origin: new LatLng(46.96526, -109.533691),
      bounds: new LatLngBounds(
        new LatLng(48.29783, -102.25448),
        new LatLng(45.1665891, -116.917532)
      ),
    },
  },
  {
    id: 'default-nebraska',
    predictionPlace: {
      address: 'Nebraska',
      origin: new LatLng(41.5, -100.0),
      bounds: new LatLngBounds(new LatLng(45.36969, -94.0156), new LatLng(37.8418, -105.23266)),
    },
  },
  {
    id: 'default-kansas',
    predictionPlace: {
      address: 'Kansas',
      origin: new LatLng(38.5, -98),
      bounds: new LatLngBounds(new LatLng(40.00307, -94.58865), new LatLng(36.99301, -102.05174)),
    },
  },
  {
    id: 'default-colorado',
    predictionPlace: {
      address: 'Colorado',
      origin: new LatLng(39.113014, -105.358887),
      bounds: new LatLngBounds(new LatLng(42.69766, -99.92864), new LatLng(34.84809, -111.14571)),
    },
  },
  {
    id: 'default-new-jersey',
    predictionPlace: {
      address: 'New Jersey',
      origin: new LatLng(40.0583, -74.4057),
      bounds: new LatLngBounds(new LatLng(41.35736, -73.785608), new LatLng(38.743995, -75.563586)),
    },
  },
  {
    id: 'default-missouri',
    predictionPlace: {
      address: 'Missouri',
      origin: new LatLng(38.5, -92.5),
      bounds: new LatLngBounds(new LatLng(40.61364, -89.09896), new LatLng(35.99568, -95.774704)),
    },
  },
];
