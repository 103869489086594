/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { node } from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as EllipsisIcon } from '../../assets/icons/ellipsis-round.svg';
import css from './MenuTooltip.css';

const MenuTooltip = ({ content, tooltipShown, onTooltipShown }) => {
  return (
    <Tooltip
      tooltipShown={tooltipShown}
      onTooltipShown={onTooltipShown}
      hideCloseButton
      placement="bottom"
      trigger="click"
      tooltip={
        <div>
          <div className={css.tooltipContent}>{content}</div>
        </div>
      }
    >
      <a href="#" className={css.tooltipLink} onClick={e => e.preventDefault()}>
        <EllipsisIcon />
      </a>
    </Tooltip>
  );
};

MenuTooltip.defaultProps = {
  content: null,
};

MenuTooltip.propTypes = {
  content: node,
};

export default MenuTooltip;
