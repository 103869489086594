import React from 'react';
import { array, func, number, string, bool, node, arrayOf } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { FieldCheckbox, FilterPopup, FilterPlain } from '..';

import css from './SelectMultipleFilter.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
// There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox id={fieldId} name={name} label={option.label} value={option.key} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const SelectMultipleFilter = ({
  className,
  rootClassName,
  showAsPopup,
  initialValues: initialValuesRaw,
  id,
  contentPlacementOffset,
  onSubmit,
  urlParam,
  intl,
  onManageDisableScrolling,
  saveFilters,
  resetToSavedFilters,
  resultsCount,
  searchInProgress,
  label,
  name,
  options,
  titles,
  ...rest
}) => {
  const initialValues = initialValuesRaw || [];
  const hasInitialValues = initialValues.length > 0;
  const labelForPopup = hasInitialValues
    ? intl.formatMessage(
        { id: 'SelectMultipleFilter.labelSelected' },
        { labelText: label, count: initialValues.length }
      )
    : label;

  const labelForPlain = hasInitialValues
    ? intl.formatMessage(
        { id: 'SelectMultipleFilterPlainForm.labelSelected' },
        { labelText: label, count: initialValues.length }
      )
    : label;

  // pass the initial values with the name key so that
  // they can be passed to the correct field
  const namedInitialValues = { [name]: initialValues };

  const handleSubmit = (_, values) => {
    const usedValue = values ? values[name] : values;
    onSubmit(urlParam, usedValue);
  };

  const onClear = () => onSubmit(urlParam);
  const onCancel = () => onSubmit(urlParam, initialValues);

  return showAsPopup ? (
    <FilterPopup
      className={className}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      label={labelForPopup}
      titles={titles}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      onClear={onClear}
      onCancel={onCancel}
      initialValues={namedInitialValues}
      urlParam={urlParam}
      onManageDisableScrolling={onManageDisableScrolling}
      saveFilters={saveFilters}
      resetToSavedFilters={resetToSavedFilters}
      resultsCount={resultsCount}
      searchInProgress={searchInProgress}
      keepDirtyOnReinitialize
      {...rest}
    >
      <GroupOfFieldCheckboxes
        className={css.fieldGroup}
        name={name}
        id={`${id}-checkbox-group`}
        options={options}
      />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelForPlain}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      onClear={onClear}
      onCancel={onCancel}
      initialValues={namedInitialValues}
      urlParam={urlParam}
      {...rest}
    >
      <GroupOfFieldCheckboxes
        className={css.fieldGroupPlain}
        name={name}
        id={`${id}-checkbox-group`}
        options={options}
      />
    </FilterPlain>
  );
};

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
  onManageDisableScrolling: null,
  saveFilters: null,
  resetToSavedFilters: null,
  titles: null,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  showAsPopup: bool,
  liveEdit: bool,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: arrayOf(string),
  contentPlacementOffset: number,
  onManageDisableScrolling: func,
  saveFilters: func,
  resetToSavedFilters: func,
  resultsCount: number.isRequired,
  searchInProgress: bool.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  options: array.isRequired,
  titles: node,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
