import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import ListingCard from '../ListingCard/ListingCard';
import css from './SectionFavoriteListings.css';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';
import { NamedLink } from '..';

const SectionFavoriteListings = ({ listings, user }) => {
  const favoriteListings = get(user, 'attributes.profile.publicData.favoriteListings', []);

  const favoritePackages = get(user, 'attributes.profile.publicData.favoritePackages', []);

  const tab = favoriteListings.length <= 0 && favoritePackages.length > 0 ? 'packages' : 'listings';

  return (
    <div className={css.root} data-testid="featured-listings-section">
      <div className={css.header}>
        <h1>
          <FormattedMessage id="SectionFavoriteListings.title" />
        </h1>

        <NamedLink
          name="FavoritesPage"
          params={{ id: user?.id?.uuid, tab }}
          className={css.showAllLink}
        >
          <FormattedMessage id="SectionFeaturedListings.showAll" />
        </NamedLink>
      </div>

      <ScrollingCarousel className={css.carousel}>
        {listings.map(l => (
          <Step className={css.step} key={l.id.uuid}>
            <ListingCard listing={l} />
          </Step>
        ))}
      </ScrollingCarousel>
    </div>
  );
};

const { array } = PropTypes;

SectionFavoriteListings.propTypes = {
  listings: array.isRequired,
};

export default SectionFavoriteListings;
