/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { bool, node, oneOfType, oneOf, string } from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import { SectionConservationDonation } from '..';

import css from './PricingToolTip.css';

const PricingToolTip = ({
  transaction,
  intl,
  title,
  userRole,
  isPackage,
  isSpecialOffer,
  isLodgingTax,
  isWide,
  itemInfo,
  tooltipShown,
  lodgingInfo,
  usState,
  taxRate,
}) => {
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const hasInsurance = get(transaction, 'attributes.metadata.tripInsurance', false);

  const { lodgingFeeLabel } = lodgingInfo;
  const packageItem =
    isPackage &&
    !isSpecialOffer &&
    itemInfo?.map(
      item =>
        item?.packageLabel && (
          <div>
            <h3 className={css.tooltipHeader}>{item?.packageLabel}</h3>

            <div className={css.tooltipContent}>
              <FormattedMessage id="PricingToolTip.packageCostDescription" />
            </div>
          </div>
        )
    );

  const diyItem =
    !isPackage &&
    !isSpecialOffer &&
    itemInfo?.map(
      item =>
        item?.speciesLineItemLabel && (
          <div>
            <h3 className={css.tooltipHeader}>{item?.speciesLineItemLabel}</h3>

            <div className={css.tooltipContent}>
              <FormattedMessage id="PricingToolTip.diyHuntDescription" />
            </div>
          </div>
        )
    );
  return (
    <div className={css.ModalContainer}>
      <Tooltip
        placement={isWide ? 'left' : 'bottom'}
        tooltipShown={tooltipShown}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: ({ placement }) => {
                if (placement === 'bottom') {
                  return [190, -500];
                }
                return [0, 12];
              },
            },
          },
        ]}
        hideArrow
        tooltip={
          <div>
            <h1 className={css.tooltipTitle}>{title}</h1>
            {packageItem}
            {diyItem}
            {isCustomer && isSpecialOffer && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.specialOffer" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.specialOfferDescriptionGuest" />
                </div>
              </div>
            )}
            {lodgingInfo && lodgingFeeLabel && (
              <div>
                <h3 className={css.tooltipHeader}>{lodgingFeeLabel}</h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.lodgingFeeDescription" />
                </div>
              </div>
            )}
            {isCustomer && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.bookingFee" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.bookingFeeDescriptionGuest" />
                </div>
              </div>
            )}
            {isCustomer && isLodgingTax && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.lodging" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage
                    id="PricingToolTip.lodgingDescription"
                    values={{
                      usState,
                      taxRate,
                    }}
                  />
                </div>
              </div>
            )}
            {isCustomer && hasInsurance && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.insurance" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.lodgingInsuranceDescription" />
                </div>
              </div>
            )}
            {isCustomer && !isSpecialOffer && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.conservation" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.conservationDescription" />
                  <SectionConservationDonation transaction={transaction} intl={intl} />
                </div>
              </div>
            )}
            {isProvider && isSpecialOffer && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.specialOffer" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.specialOfferDescriptionLandowner" />
                </div>
              </div>
            )}
            {isProvider && (
              <div>
                <h3 className={css.tooltipHeader}>
                  <FormattedMessage id="PricingToolTip.landtrustCommission" />
                </h3>

                <div className={css.tooltipContent}>
                  <FormattedMessage id="PricingToolTip.bookingFeeDescriptionLandowner" />
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

PricingToolTip.defaultProps = {
  title: <FormattedMessage id="PricingDetails.pricingHeaderCustomer" />,
  isPackage: false,
  isSpecialOffer: false,
  isLodgingTax: false,
  isWide: false,
};

PricingToolTip.propTypes = {
  title: oneOfType([string, node]),
  userRole: oneOf(['provider', 'customer']).isRequired,
  isPackage: bool,
  isSpecialOffer: bool,
  isLodgingTax: bool,
  isWide: bool,
};

export default PricingToolTip;
