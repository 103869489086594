/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { LODGING_INFO } from '../../util/package';
import CabinLodging from './CabinLodging';
import HouseLodging from './HouseLodging';
import TentCampsite from './TentCampsite';
import RVCampsite from './RVCampsite';

import css from './SectionLodgingOptions.css';
import { CAMPSITES_TYPE } from '../../util/campsite';

const SectionLodgingOptions = ({ campsites, lodging, lodgingPricesOptions, intl }) => {
  if (!campsites.length) {
    return null;
  }

  const lodgingTents = lodgingPricesOptions.filter(item => item.type === CAMPSITES_TYPE.TENT);
  const lodgingRVs = lodgingPricesOptions.filter(item => item.type === CAMPSITES_TYPE.RV);

  const isCabinOptional = lodgingPricesOptions.some(
    item => item.type === 'cabin' && item.option === 'optional'
  );

  const isHouseOptional = lodgingPricesOptions.some(
    item => item.type === 'house' && item.option === 'optional'
  );
  const isCabinRequired = lodgingPricesOptions.some(
    item => item.type === 'cabin' && item.option === 'required'
  );

  const isHouseRequired = lodgingPricesOptions.some(
    item => item.type === 'house' && item.option === 'required'
  );

  const hasSingleRVSite = lodgingRVs.length === 1;

  let rvIndex = 1;

  const rvSitesIncluded = [];
  const rvSitesAddons = [];

  const tentsIncluded = [];
  const tentsAddons = [];

  campsites.forEach((campsite, index) => {
    if (campsite.allowsTents && lodgingTents.length > 0) {
      if (lodgingTents[0].option === LODGING_INFO.REQUIRED) {
        tentsIncluded.push(
          <TentCampsite details={campsite} lodgingPrice={lodgingTents[0]} intl={intl} />
        );
      } else if (lodgingTents[0].option === LODGING_INFO.OPTIONAL) {
        tentsAddons.push(
          <TentCampsite details={campsite} lodgingPrice={lodgingTents[0]} intl={intl} />
        );
      }
    }

    if (campsite?.allowsRvs && lodgingRVs.length > 0 && lodgingRVs[index]?.option) {
      if (lodgingRVs[index].option === LODGING_INFO.REQUIRED) {
        rvSitesIncluded.push(
          <RVCampsite
            details={campsite}
            lodgingPrice={lodgingRVs[index]}
            intl={intl}
            index={rvIndex}
            hasSingleSite={hasSingleRVSite}
          />
        );
        rvIndex += 1;
      } else if (lodgingRVs[index].option === LODGING_INFO.OPTIONAL) {
        rvSitesAddons.push(
          <RVCampsite
            details={campsite}
            lodgingPrice={lodgingRVs[index]}
            intl={intl}
            index={rvIndex}
            hasSingleSite={hasSingleRVSite}
          />
        );
        rvIndex += 1;
      }
    }
  });

  const tentsSites = [...tentsIncluded, ...tentsAddons];
  const rvsSites = [...rvSitesIncluded, ...rvSitesAddons];

  if (
    !tentsSites.length &&
    !rvsSites.length &&
    !isCabinOptional &&
    !isHouseOptional &&
    !isCabinRequired &&
    !isHouseRequired
  ) {
    return null;
  }

  return (
    <div className={css.root}>
      {isCabinOptional && (
        <CabinLodging
          overallInfo={lodging.overallInfo}
          intl={intl}
          cabin={lodging.cabin}
          lodging={lodging}
          lodgingPrice={lodging.price}
          showPrice
        />
      )}

      {isHouseOptional && (
        <HouseLodging
          overallInfo={lodging.overallInfo}
          house={lodging.house}
          intl={intl}
          lodging={lodging}
          lodgingPrice={lodging.price}
          showPrice
        />
      )}
      {isCabinRequired && (
        <CabinLodging
          overallInfo={lodging.overallInfo}
          intl={intl}
          cabin={lodging.cabin}
          lodging={lodging}
          lodgingPrice={lodging.price}
          showPrice={false}
        />
      )}

      {isHouseRequired && (
        <HouseLodging
          overallInfo={lodging.overallInfo}
          house={lodging.house}
          intl={intl}
          lodging={lodging}
          lodgingPrice={lodging.price}
          showPrice={false}
        />
      )}

      {rvsSites.length > 0 && <div>{rvsSites}</div>}

      {tentsSites.length > 0 && <div>{tentsSites}</div>}
    </div>
  );
};

SectionLodgingOptions.defaultProps = {
  campsites: null,
};

SectionLodgingOptions.propTypes = {
  campsites: object,
};

export default injectIntl(SectionLodgingOptions);
