/* eslint-disable no-param-reassign */
import React, { useMemo, useRef } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { get, uniq } from 'lodash';
import { ensureOwnListing, overrideGet } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { HUNT_ACTIVITY } from '../../marketplace-custom-config';
import { getInitialPackage } from '../../util/package';
import { createResourceLocatorString } from '../../util/routes';
import { EditListingPackagesSection, EditListingBaseExperiencePanel } from '..';
import { LISTING_STATE_DRAFT } from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import { EditListingHuntExperienceForm, EditListingCreatePackageForm } from '../../forms';

const getInitialValues = listing => {
  const currentListing = ensureOwnListing(listing);

  const publicData = get(currentListing, 'attributes.publicData', {});
  const hunt = get(publicData, 'activities.hunt', {});
  const gameTypes = get(publicData, 'gameTypes', null);
  const landDetails = get(hunt, 'landDetails', {});
  const methods = get(hunt, 'methods', {});
  const amenities = get(hunt, 'amenities', {});

  const huntBucket = {};

  if (gameTypes) {
    Object.keys(gameTypes).forEach(key => {
      if (typeof gameTypes[key] === 'object') {
        huntBucket[key] = {
          isActive: gameTypes[key].isActive,
          price: JSON.parse(gameTypes[key].price, sdkTypes.reviver),
        };
      }
    });
  }

  const initialValues = {
    hasFoodSources: get(landDetails, 'hasFoodSources', false),
    foodSources: get(landDetails, 'foodSources', []),
    foodSourcesInfo: get(landDetails, 'foodSourcesInfo', null),
    hasWaterSources: get(landDetails, 'hasWaterSources', false),
    waterSources: get(landDetails, 'waterSources', []),
    waterSourcesInfo: get(landDetails, 'waterSourcesInfo', null),
    hasShelter: get(landDetails, 'hasShelter', false),
    shelter: get(landDetails, 'shelter', []),
    shelterInfo: get(landDetails, 'shelterInfo', null),
    hasFoodPlots: get(landDetails, 'hasFoodPlots', false),
    allowsFirearms: get(methods, 'allowsFirearms', false),
    firearmsInfo: get(methods, 'firearmsInfo', null),
    allowsArchery: get(methods, 'allowsArchery', false),
    archeryInfo: get(methods, 'archeryInfo', null),
    hasTreeStands: get(amenities, 'hasTreeStands', false),
    treeStands: overrideGet(amenities, 'treeStands', 0),
    treeStandsInfo: get(amenities, 'treeStandsInfo', null),
    hasGroundBlinds: get(amenities, 'hasGroundBlinds', false),
    groundBlinds: overrideGet(amenities, 'groundBlinds', 0),
    groundBlindsInfo: get(amenities, 'groundBlindsInfo', null),
    hasGameFeeders: get(amenities, 'hasGameFeeders', false),
    gameFeeders: overrideGet(amenities, 'gameFeeders', 0),
    gameFeedersInfo: get(amenities, 'gameFeedersInfo', null),
    hasGameCameras: get(amenities, 'hasGameCameras', false),
    gameCameras: overrideGet(amenities, 'gameCameras', 0),
    gameCamerasInfo: get(amenities, 'gameCamerasInfo', null),
    hunt: huntBucket,
    images: [],
  };

  return initialValues;
};

const getSubmissionValues = (listing, values, packages) => {
  const publicData = get(listing, 'attributes.publicData', {});
  const currentActivities = get(publicData, 'activities', {});
  const allPackages = get(publicData, 'packages', []);

  // Land details
  const landDetails = {
    hasFoodSources: values.hasFoodSources,
    foodSources: values.hasFoodSources ? values.foodSources : [],
    foodSourcesInfo: values.hasFoodSources ? values.foodSourcesInfo : null,
    hasWaterSources: values.hasWaterSources,
    waterSources: values.hasWaterSources ? values.waterSources : [],
    waterSourcesInfo: values.hasWaterSources ? values.waterSourcesInfo : null,
    hasShelter: values.hasShelter,
    shelter: values.hasShelter ? values.shelter : [],
    shelterInfo: values.hasShelter ? values.shelterInfo : null,
    hasFoodPlots: values.hasFoodPlots,
  };

  // Amenities
  const amenities = {
    hasTreeStands: values.hasTreeStands,
    treeStands: values.hasTreeStands ? values.treeStands : 0,
    treeStandsInfo: values.hasTreeStands ? values.treeStandsInfo : null,
    hasGroundBlinds: values.hasGroundBlinds,
    groundBlinds: values.hasGroundBlinds ? values.groundBlinds : 0,
    groundBlindsInfo: values.hasGroundBlinds ? values.groundBlindsInfo : null,
    hasGameFeeders: values.hasGameFeeders,
    gameFeeders: values.hasGameFeeders ? values.gameFeeders : 0,
    gameFeedersInfo: values.hasGameFeeders ? values.gameFeedersInfo : null,
    hasGameCameras: values.hasGameCameras,
    gameCameras: values.hasGameCameras ? values.gameCameras : 0,
    gameCamerasInfo: values.hasGameCameras ? values.gameCamerasInfo : null,
  };

  // Pricing
  const pricing = {
    gameTypeKeys: [],
    gameTypes: {},
  };

  // Hunting
  const gameTypes = {
    ...values.hunt,
  };

  if (gameTypes) {
    Object.keys(gameTypes).forEach(key => {
      if (gameTypes[key].isActive) {
        pricing.gameTypes[key] = {
          isActive: gameTypes[key].isActive,
          price: JSON.stringify(gameTypes[key].price, sdkTypes.reviver),
        };
      }
    });

    pricing.gameTypeKeys = Object.keys(gameTypes).filter(key => gameTypes[key].isActive === true);
  }

  // Packages
  const noneHuntingPackages = allPackages.filter(item => item.activity !== HUNT_ACTIVITY);

  // Clean packages from images. We store it against imageTags
  const cleanPackages = packages.map(item => {
    delete item.imageCaptions;

    return item;
  });
  const updatedPackages = [...noneHuntingPackages, ...cleanPackages];

  // Include species from packages into gameType for searches purposes
  const packagesSpecies = updatedPackages
    .filter(item => item.isPublished && item?.species?.length > 0)
    .map(item => item.species)
    .flat();

  pricing.gameTypeKeys = uniq([...packagesSpecies, ...pricing.gameTypeKeys]);

  const packagesSubSpecies = {};

  updatedPackages
    .filter(item => item.isPublished && Object.keys(item?.subSpecies || {}).length > 0)
    .forEach(item => {
      Object.keys(item.subSpecies).forEach(key => {
        if (!packagesSubSpecies[key]) {
          packagesSubSpecies[key] = [];
        }

        packagesSubSpecies[key] = uniq(packagesSubSpecies[key].concat(item.subSpecies[key]));
      });
    });

  const submissionValues = {
    // sending Base-Price to Flex Console as $0.00 (if landowner sends hunt/fish/access prices)
    price: { _sdkType: 'Money', amount: 0, currency: 'USD' },
    publicData: {
      gameTypes: {
        ...values.hunt,
      },
      activities: {
        ...currentActivities,
        hunt: {
          ...currentActivities.hunt,
          game: values.game,
          landDetails,
          amenities,
        },
      },
      ...pricing,
      subSpecies: Object.keys(packagesSubSpecies).length ? packagesSubSpecies : null,
    },
  };

  return submissionValues;
};

const EditListingHuntExperiencePanel = props => {
  const {
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backLink,
    intl,
    params,
    history,
  } = props;
  const updatedValuesRef = useRef({});
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingHuntExperiencePanel.title" />
  ) : (
    <FormattedMessage id="EditListingHuntExperiencePanel.createListingTitle" />
  );

  const initialValues = useMemo(() => {
    const values = getInitialValues(listing);

    return {
      ...values,
      ...updatedValuesRef.current,
    };
  }, [listing]);

  return (
    <EditListingBaseExperiencePanel
      title={panelTitle}
      listing={currentListing}
      activity={HUNT_ACTIVITY}
      params={params}
    >
      {baseProps => {
        return (
          <>
            <EditListingHuntExperienceForm
              initialValues={initialValues}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                onSubmit(getSubmissionValues(currentListing, values, baseProps.packages));
              }}
              onUpdate={values => {
                // Keep the values of the child form. I had to use ref here to avoid too many state updates
                updatedValuesRef.current = values;
              }}
              onChange={onChange}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              backLink={backLink}
              listing={currentListing}
              packages={baseProps.packages}
              packageSection={
                <EditListingPackagesSection
                  onCreatePackageMode={baseProps.handleCreatePackage}
                  onEditPackage={item => {
                    baseProps.handleEditPackage(item);

                    history.push(
                      createResourceLocatorString('EditListingPagePackage', routeConfiguration(), {
                        slug: params.slug,
                        id: params.id,
                        type: params.type,
                        tab: params.tab,
                        packageId: item.id,
                      })
                    );
                  }}
                  onTogglePublishPackage={baseProps.handleTogglePublishPackage}
                  onDuplicatePackage={baseProps.handleUpdatePackage}
                  onUpdatePackage={baseProps.handleUpdatePackage}
                  onRemovePackage={baseProps.handleRemovePackage}
                  packages={baseProps.packages}
                  selectedPackage={baseProps.selectedPackage}
                  currentListing={currentListing}
                />
              }
            />
            <EditListingCreatePackageForm
              activity={HUNT_ACTIVITY}
              initialValues={getInitialPackage(baseProps.selectedPackage, HUNT_ACTIVITY)}
              onClose={() => {
                baseProps.setCreatePackageMode(false);
                history.push(
                  createResourceLocatorString('EditListingPage', routeConfiguration(), {
                    slug: params.slug,
                    id: params.id,
                    type: params.type,
                    tab: params.tab,
                  })
                );
              }}
              currentListing={currentListing}
              active={baseProps.createPackageMode}
              onUpdatePackage={async values => {
                await baseProps.handleUpdatePackage(values);
              }}
              titlePlaceholder={intl.formatMessage({
                id: 'EditListingHuntExperiencePanel.packageTitlePlaceholder',
              })}
              descriptionPlaceholder={intl.formatMessage({
                id: 'EditListingHuntExperiencePanel.packageDescriptionPlaceholder',
              })}
              intl={intl}
              isOpen={baseProps.createPackageMode}
            />
          </>
        );
      }}
    </EditListingBaseExperiencePanel>
  );
};

EditListingHuntExperiencePanel.defaultProps = {
  listing: null,
  backLink: null,
};

EditListingHuntExperiencePanel.propTypes = {
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  backLink: node,

  params: object.isRequired,
};

export default injectIntl(EditListingHuntExperiencePanel);
