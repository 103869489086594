import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionProviderSpecialOffer = ({ transition, ownRole, user, buttons }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage
            id="BookingStatus.ownTransitionSpecialOfferSent"
            values={{ displayName }}
          />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionSpecialOfferSent"
            values={{ displayName }}
          />
        )
      }
      buttons={buttons}
    >
      {isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionSpecialOfferSentSubtext" />
      ) : (
        <FormattedMessage id="BookingStatus.transitionSpecialOfferSentSubtext" />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionProviderSpecialOffer;
