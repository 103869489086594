import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import config from '../../config';
import { ReactComponent as LogoSVG } from '../../assets/logo.svg';
import { ReactComponent as LogoLightSVG } from '../../assets/logo-light.svg';
import { ReactComponent as BadgeSVG } from '../../assets/badge.svg';
import css from './Logo.css';

const Logo = props => {
  const { className, size, format, variant, ...rest } = props;
  const classes = classnames(
    css.root,
    {
      [css.lg]: size === 'lg',
    },
    className
  );
  const logoComponent = variant === 'dark' ? LogoSVG : LogoLightSVG;
  const Component = format === 'desktop' ? logoComponent : BadgeSVG;

  return <Component className={classes} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  size: 'base',
  variant: 'dark',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['mobile', 'desktop']),
  size: oneOf(['lg', 'base']),
  variant: oneOf(['light', 'dark']),
};

export default Logo;
