/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { bool, func } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import css from './FieldPromoCode.css';
import { FieldTextInput, PrimaryButton } from '..';

const FieldPromoCodeComponent = ({
  intl,
  onApply,
  promoCodeApplied,
  usedPromoCode,
  ...restProps
}) => {
  const { value, onChange } = restProps;

  const [isInitialized, setIsInitialized] = useState(false);
  const [isFieldVisible, setIsFieldVisible] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);

  useEffect(() => {
    if (isInitialized && !isApplying) {
      if (!promoCodeApplied) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
        setHasApplied(true);
      }
    }
  }, [promoCodeApplied, isApplying, isInitialized]);

  const handleApplyPromoCode = async promoCode => {
    try {
      setIsInitialized(true);
      setIsApplying(true);
      setHasApplied(false);
      await onApply(promoCode);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsApplying(false);
    }
  };

  const handleChange = event => {
    onChange(event);
    setHasApplied(false);
  };
  const errorMessage = usedPromoCode
    ? intl.formatMessage({ id: 'PromoCode.usedPromoCode' })
    : intl.formatMessage({ id: 'PromoCode.invalidPromoCode' });

  const buttonLabel = hasApplied
    ? intl.formatMessage({
        id: 'PromoCode.applied',
      })
    : intl.formatMessage({
        id: 'PromoCode.apply',
      });

  return (
    <div className={css.root}>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setIsFieldVisible(!isFieldVisible);
        }}
        className={css.headerLink}
      >
        <FormattedMessage id="PromoCode.headerLink" />
      </a>

      {isFieldVisible && (
        <div className={css.inputWrapper}>
          <FieldTextInput
            type="text"
            name="promoCode"
            placeholder="Promotional code"
            customErrorText={isInvalid ? errorMessage : ''}
            customSuccessText={
              hasApplied
                ? intl.formatMessage({
                    id: 'PromoCode.appliedPromoCode',
                  })
                : ''
            }
            onChange={handleChange}
          />

          <PrimaryButton
            disabled={!value}
            inProgress={isApplying}
            isSmall
            className={css.applyButton}
            type="button"
            onClick={() => {
              handleApplyPromoCode(value);
            }}
          >
            {buttonLabel}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

const FieldPromoCode = props => {
  return (
    <Field name="promoCode">
      {({ input }) => <FieldPromoCodeComponent {...input} {...props} />}
    </Field>
  );
};

FieldPromoCode.defaultProps = {};

FieldPromoCode.propTypes = {
  intl: intlShape.isRequired,
  onApply: func.isRequired,
  promoCodeApplied: bool.isRequired,
};

export default injectIntl(FieldPromoCode);
