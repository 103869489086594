import cookies from 'js-cookie';

const COOKIE_KEY = 'aff_lt';

export const MEDIUM = 'utm_medium';
export const CAMPAIGN = 'utm_campaign';
export const SOURCE = 'utm_source';
export const AP = 'ap';

const getUtmParams = params => {
  const newParams = new URLSearchParams();

  params.forEach((value, key) => {
    if ([MEDIUM, CAMPAIGN, SOURCE, AP].indexOf(key) > -1) {
      newParams.set(key, value);
    }
  });

  return newParams;
};

export default {
  loadParams() {
    const affCookie = cookies.get(COOKIE_KEY);

    let params = new URLSearchParams(document.location.search);

    if (affCookie && affCookie.length) {
      params = new URLSearchParams(atob(affCookie));
    }

    params = getUtmParams(params);

    if (!params.toString().length) {
      return params;
    }

    return this.saveParams(params);
  },

  saveParams(params) {
    cookies.set(COOKIE_KEY, btoa(params.toString()), { expires: 7 });

    return params;
  },

  has(key) {
    return this.loadParams().has(key);
  },

  medium(defaultValue = null) {
    return this.get(MEDIUM, defaultValue);
  },

  campaign(defaultValue = null) {
    return this.get(CAMPAIGN, defaultValue);
  },

  source(defaultValue = null) {
    return this.get(SOURCE, defaultValue);
  },

  ap(defaultValue = null) {
    return this.get(AP, defaultValue);
  },

  get(key, defaultValue = null) {
    const value = this.loadParams().get(key);

    return value || defaultValue;
  },

  all() {
    return {
      [MEDIUM]: this.medium(),
      [CAMPAIGN]: this.campaign(),
      [SOURCE]: this.source(),
      [AP]: this.ap(),
    };
  },
};
