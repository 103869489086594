import React from 'react';
import { bool, node, string, number, func, object, oneOfType } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { FieldLabel, ValidationError } from '..';
import { ReactComponent as StepUpIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as StepDownIcon } from '../../assets/icons/minus.svg';

import css from './FieldNumberInput.css';

const FieldNumberInputComponent = ({
  rootClassName,
  className,
  label,
  value,
  name,
  id,
  min,
  max,
  step,
  disabled,
  placeholder,
  asRow,
  onChange,
  onFocus,
  onBlur,
  meta,
  hideButtons,
  unit,
  ...props
}) => {
  const classes = classNames(rootClassName || css.root, { [css.rootAsRow]: asRow }, className);
  const { valid, invalid, touched, error } = meta;
  const hasError = !!(touched && invalid && error);

  const hasMin = () => min !== undefined;
  const hasMax = () => max !== undefined;
  const hasReachedTheLimit = (value, max) => max !== undefined && Number(value) === max;

  const calcValue = val => {
    if (hasMax() && val > max) {
      return max;
    }

    if (hasMin() && val < min) {
      return min;
    }

    return val;
  };

  const changeValue = val => {
    if (value !== '' && value !== '-') {
      onChange(calcValue(Number(value) + val));
    } else if (hasMin() && val < min) {
      onChange(min);
    } else if (hasMax() && val > max) {
      onChange(max);
    } else {
      onChange(val);
    }
  };

  const handleChange = e => {
    e.preventDefault();
    e.stopPropagation();

    const inputVal = e.target.value.replace(/[^.\d]/g, '');

    if (inputVal === '') {
      onChange('');
      return;
    }

    if (inputVal === '-') {
      onChange('-');
      return;
    }

    const val = Number(inputVal);

    if (String(val) !== inputVal) {
      onChange(value);
    } else {
      const calculatedValue = calcValue(val);
      onChange(calculatedValue);
    }
  };

  const handleIncrement = () => {
    changeValue(1 * step);
  };

  const handleDecrement = () => {
    changeValue(-1 * step);
  };

  const handleFocus = e => {
    onFocus(e);
  };

  const handleBlur = e => {
    onBlur(e);
  };

  return (
    <span className={classes}>
      {label ? <FieldLabel htmlFor={id}>{label}</FieldLabel> : null}

      <span>
        <span className={css.inputWrapper}>
          {!hideButtons && (
            <>
              <button
                tabIndex="-1"
                className={classNames(css.inputButton, css.inputButtonDecrement)}
                type="button"
                onClick={handleDecrement}
                disabled={disabled || hasReachedTheLimit(value, min)}
              >
                <StepDownIcon />
              </button>
              <button
                tabIndex="-1"
                className={classNames(css.inputButton, css.inputButtonIncrement)}
                type="button"
                onClick={handleIncrement}
                disabled={disabled || hasReachedTheLimit(value, max)}
              >
                <StepUpIcon />
              </button>
            </>
          )}
          <input
            type="text"
            name={name}
            value={value}
            id={id}
            className={classNames(css.inputRoot, {
              [css.inputError]: hasError,
              [css.inputRootNoButtons]: hideButtons,
            })}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            min={min}
            max={max}
            placeholder={placeholder}
          />

          {unit && <span className={css.unit}>{unit}</span>}
        </span>

        <ValidationError fieldMeta={{ ...meta }} />
      </span>
    </span>
  );
};

FieldNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  label: node,
  asRow: bool,

  id: string.isRequired,
  name: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  min: number,
  max: number,
  step: number,
  placeholder: number,
  disabled: bool,
  onChange: func,
  onFocus: func,
  onBlur: func,
  meta: object.isRequired,
  hideButtons: bool,
  unit: string,
};

FieldNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: '',
  asRow: false,
  min: 0,
  max: 99,
  step: 1,
  placeholder: undefined,
  disabled: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  hideButtons: false,
  unit: '',
};

const FieldNumberInput = props => (
  <Field {...props}>
    {({ input, meta }) => (
      <FieldNumberInputComponent
        onChange={value => input.onChange(value)}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        value={input.value}
        meta={meta}
        {...props}
      />
    )}
  </Field>
);

export default FieldNumberInput;
