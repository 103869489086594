export const PAGE_VIEW = 'Page View';

export const SIGNUP_STARTED = 'Signup Started';
export const SUCCESSFUL_REGISTRATION = 'Successful Registration';
export const SUCCESSFUL_LOGIN = 'Successful Login';
export const LOGOUT = 'Log out';
export const PROFILE_UPDATED = 'Profile Updated';

export const SEARCH = 'Search';

export const LISTING_VIEW = 'Listing View';
export const LISTING_CLICK = 'Listing Click';
export const RESERVE_CLICKED = 'Reserve Clicked';
export const SIGNUP_CLICK = 'Signup Click';
export const ADD_PROPERTY_CLICK = 'Add Property Click';
export const LISTING_FAVORED = 'Listing Favored';
export const LISTING_UNFAVORED = 'Listing Unfavored';

export const NEW_LISTING_STARTED = 'New Listing Started';
export const NEW_LISTING_COMPLETED = 'New Listing Completed';

export const BOOKING_REQUEST_STARTED = 'Booking Request Started';
export const BOOKING_REQUEST_SUBMITTED = 'Booking Request Submitted';
export const BOOKING_REQUEST_RECEIVED = 'Booking Request Received (mirror event)';
export const BOOKING_REQUEST_ACCEPTED = 'Booking Request Accepted';
export const BOOKING_REQUEST_ACCEPTED_BY_LANDOWNER = 'Booking Accepted By Landowner';
export const BOOKING_REQUEST_DECLINED = 'Booking Request Declined';
export const BOOKING_REQUEST_DECLINED_BY_LANDOWNER = 'Booking Request Declined By Landowner';

export const PRICING_VIEW_MOBILE_ONLY = 'Pricing View (Mobile Only)';

export const NEW_QUERY_INITIATED = 'New Query Initiated';
export const INITIAL_QUERY_READ = 'Initial Query Read';
export const INITIAL_QUERY_READ_BY_LANDOWNER = 'Initial Query Read By Landowner';
export const MESSAGES_LOADED = 'Messages Loaded';
export const MESSAGE_SENT = 'New Message Sent';
export const NEW_QUERY_READ_BY_LANDOWNER = 'New Query Read';
export const QUERY_REPLIED_BY_LANDOWNER = 'Query Replied';
export const REPLY_READ_BY_SPORTSMAN = 'Reply Read';
export const SPORTSMAN_REPLIED = 'Sportsman Replied';

export const ID_VERIFICATION_SUCCESS = 'ID Verification Success';

export const PAYMENT_METHOD_SUBMITTED = 'Payment Method Submitted';
export const PAYOUT_DETAILS_SUBMITTED = 'Payout Details Submitted';

export const TRANSACTION_CANCELED = 'Transaction Canceled';
export const TRANSACTION_CANCELED_BY_LANDOWNER = 'Transaction Canceled By Landowner';
export const SPORTSMAN_CANCELED_TRANSACTION = 'Sportsman Canceled Transaction';
export const TRANSACTION_CANCELED_BY_SPORTSMAN = 'Transaction Canceled By Sportsman';

export const SPECIAL_OFFER_SENT = 'Special Offer Sent to Sportsman';
export const SPECIAL_OFFER_ACCEPTED = 'Special Offer Accepted by Sportsman';
export const SPECIAL_OFFER_CANCELED = 'Special Offer Canceled by Landowner';
export const SPECIAL_OFFER_DECLINED = 'Special Offer Declined by Sportsman';

export const SEND_REVIEW_SUCCESS = 'Review Sent Successfully';
export const LANDOWNER_REVIEW_SUCCESS = 'Landowner Review Sent Successfully';
export const SPORTSMAN_REVIEW_SUCCESS = 'Sportsman Review Sent Successfully';

export const PARTY_MEMBERS_SUBMITTED = 'Sportsman Submitted Party Members';

export const TRANSACTION_PROMO_CODE_APPLIED = 'Promo code applied to a transaction';
