/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';

import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { get } from 'lodash';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isPromoCodeApplied } from '../../util/transaction';
import { ensurePaymentMethodCard } from '../../util/data';
import { isVerified } from '../../util/user';
import { composeValidators, required } from '../../util/validators';
import * as log from '../../util/log';

import {
  Form,
  FieldTextInput,
  FieldPromoCode,
  FieldTripInsurance,
  CredovaPlugin,
  FieldCheckbox,
  PrimaryButton,
  SavedCardDetails,
  IDVerification,
  PageSlider,
} from '..';

import css from './StripeCheckoutForm.css';
import { convertMoneyToNumber } from '../../util/currency';

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const PaymentMethodSelector = props => {
  const { changePaymentMethod, defaultPaymentMethod } = props;

  return (
    <>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
    </>
  );
};

export const PaymentForm = ({ intl, label, showSaveCardCheckbox }) => {
  const paymentElementOptions = {
    layout: 'tabs',
  };

  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });

  return (
    <div className={css.paymentContainer}>
      <div>
        <PaymentElement
          id="payment-element"
          className={css.paymentElement}
          options={paymentElementOptions}
        />
      </div>
      {showSaveCardCheckbox && (
        <div className={css.saveForLaterUse}>
          <FieldCheckbox
            className={css.saveForLaterUseCheckbox}
            textClassName={css.saveForLaterUseLabel}
            id="saveAfterOnetimePayment"
            name="saveAfterOnetimePayment"
            label={labelText}
            value="saveAfterOnetimePayment"
            useSuccessColor
          />
        </div>
      )}
    </div>
  );
};

const StripeCheckoutForm = ({
  onSubmit,
  submitClassName,
  showInitialMessageInput,
  intl,
  authorDisplayName,
  transaction,
  onApplyPromoCode,
  onApplyInsurance,
  defaultPaymentMethod,
  clientSecret,
  isSubmitting,
  setIsSubmitting,
  buttonLabel,
  usedPromoCode,
  fetchUser,
  showInsuranceForm,
  listing,
  followSubjectMutation,
  user,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMessage, setPaymentMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod?.id ? 'defaultCard' : '');
  const [showSaveCardCheckbox, setShowSaveCardCheckbox] = useState(true);
  const [showMissingInformationReminder, setShowMissingInformationReminder] = useState(false);
  const [isApplyingInsurance, setIsApplyingInsurance] = useState(false);

  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);

  const handlePaymentMethodChange = event => {
    const { value } = event;

    if (value.type !== 'card') {
      setShowSaveCardCheckbox(false);
    } else {
      setShowSaveCardCheckbox(true);
    }
  };

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);

      if (paymentElement) {
        paymentElement.on('change', handlePaymentMethodChange);
      }
    }
  }, [elements, paymentMethod]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const existingClientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!existingClientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(existingClientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setPaymentMessage('Payment succeeded!');
          break;
        case 'processing':
          setPaymentMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setErrorMessage('Your payment was not successful, please try again.');
          break;
        default:
          setErrorMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleUserVerification = async currentUser => {
    if (!isVerified(currentUser)) {
      setShowMissingInformationReminder(true);
      setIsSubmitting(false);

      return false;
    }

    return true;
  };

  const handleFormSubmit = async values => {
    setIsSubmitting(true);

    const currentUser = await fetchUser();
    const isUserVerified = await handleUserVerification(currentUser);

    if (!isUserVerified) {
      return;
    }

    const stripePaymentMethodId = ensuredDefaultPaymentMethod.id
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsSubmitting(false);
      return;
    }

    setErrorMessage(null);

    const { initialMessage, saveAfterOnetimePayment } = values;

    const profile = get(currentUser, 'attributes.profile', {});
    const email = get(currentUser, 'attributes.email');
    const { firstName, lastName } = profile;
    const billingDetails = {};

    if (email) {
      billingDetails.email = email;
    }

    if (firstName && lastName) {
      billingDetails.name = `${firstName} ${lastName}`;
    }

    try {
      const confirmPayment =
        paymentMethod === 'defaultCard'
          ? stripe.confirmCardPayment(clientSecret, {
              payment_method: stripePaymentMethodId,
            })
          : stripe.confirmPayment({
              elements,
              redirect: 'if_required',
              confirmParams: {
                payment_method_data: {
                  billing_details: billingDetails,
                },
              },
            });

      const { error, paymentIntent } = await confirmPayment;

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('An unexpected error occurred.');
        }

        setIsSubmitting(false);
      } else {
        const params = {
          message: initialMessage ? initialMessage.trim() : null,
          paymentIntent,
          saveAfterOnetimePayment,
          paymentMethod: getPaymentMethod(
            paymentMethod,
            ensurePaymentMethodCard(defaultPaymentMethod).id
          ),
        };

        onSubmit(params);
      }
    } catch (error) {
      console.log('error', error);
      setIsSubmitting(false);
      setErrorMessage('An unexpected error occurred.');
      log.error(error, 'stripe-checkout-form-submit-failed');
    }
  };

  const messagePlaceholder = intl.formatMessage(
    { id: 'StripePaymentForm.messagePlaceholder' },
    { name: authorDisplayName }
  );

  const changePaymentMethod = changedTo => {
    setPaymentMethod(changedTo);
  };

  const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;

  const cardClasses = classNames(css.card);

  const { last4Digits } = defaultPaymentMethod?.attributes?.card || {};
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  const buttonMessage = buttonLabel || intl.formatMessage({ id: 'StripePaymentForm.sendBooking' });
  const totalPrice = get(transaction, 'attributes.payinTotal', null);
  const formattedTotalPriceNumber = totalPrice && convertMoneyToNumber(totalPrice);
  const listingId = listing?.id?.uuid;
  const userId = user?.id?.uuid;
  const followProperty = () => {
    if (userId) {
      followSubjectMutation(userId, listingId, { sms: true, email: true });
    }
  };

  return (
    <>
      <FinalForm
        onSubmit={handleFormSubmit}
        render={fieldRenderProps => {
          const { handleSubmit } = fieldRenderProps;

          return (
            <Form id="payment-form" className={css.paymentForm} onSubmit={handleSubmit}>
              <CredovaPlugin price={formattedTotalPriceNumber} variant="large" />

              <h3 className={css.formHeading}>
                <FormattedMessage id="StripePaymentForm.paymentHeadline" />
              </h3>
              <div className={css.formSubcopy}>
                <FormattedMessage id="StripePaymentForm.paymentSubcopy" />
              </div>
              {showPaymentMethodSelector ? (
                <div>
                  <PaymentMethodSelector
                    cardClasses={cardClasses}
                    formId="StripeCheckoutForm"
                    defaultPaymentMethod={ensuredDefaultPaymentMethod}
                    changePaymentMethod={changePaymentMethod}
                    intl={intl}
                  />

                  {paymentMethod !== 'defaultCard' && (
                    <PaymentForm
                      intl={intl}
                      label={paymentMethod === 'replaceCard' && labelText}
                      showSaveCardCheckbox={showSaveCardCheckbox}
                    />
                  )}
                </div>
              ) : (
                <PaymentForm intl={intl} showSaveCardCheckbox={showSaveCardCheckbox} />
              )}

              {/* Show any error or success messages */}
              {paymentMessage && (
                <div id="payment-message" className={css.paymentMessage}>
                  {paymentMessage}
                </div>
              )}

              {errorMessage && (
                <div id="error-message" className={css.errorMessage}>
                  {errorMessage}
                </div>
              )}

              {onApplyPromoCode && (
                <FieldPromoCode
                  onApply={onApplyPromoCode}
                  usedPromoCode={usedPromoCode}
                  promoCodeApplied={isPromoCodeApplied(transaction)}
                />
              )}

              {onApplyInsurance && showInsuranceForm && (
                <FieldTripInsurance
                  onApply={onApplyInsurance}
                  intl={intl}
                  transaction={transaction}
                  isApplyingInsurance={isApplyingInsurance}
                  setIsApplyingInsurance={setIsApplyingInsurance}
                />
              )}

              {showInitialMessageInput ? (
                <div>
                  <h3 className={css.formHeading}>
                    <FormattedMessage
                      id="StripePaymentForm.messageHeading"
                      values={{ landowner: authorDisplayName }}
                    />
                  </h3>
                  <div className={css.formSubheading}>
                    <FormattedMessage
                      id="StripePaymentForm.messageSubheading"
                      values={{ landowner: authorDisplayName }}
                    />
                  </div>

                  <FieldTextInput
                    type="textarea"
                    id="StripeCheckoutForm-message"
                    name="initialMessage"
                    placeholder={messagePlaceholder}
                    className={css.message}
                    validate={composeValidators(
                      required(intl.formatMessage({ id: 'StripePaymentForm.messageRequired' }))
                    )}
                  />

                  <div className={css.cancelDisclaimer}>
                    <FormattedHTMLMessage id="StripePaymentForm.cancelDisclaimer" />
                  </div>
                </div>
              ) : null}

              <div className={css.submitContainer}>
                <div className={submitClassName}>
                  <PrimaryButton
                    isFullWidth
                    disabled={isSubmitting || !stripe || !elements || isApplyingInsurance}
                    id="submit"
                    className={css.button}
                    onClick={followProperty}
                  >
                    <span id="button-text">
                      {isSubmitting ? <div className={css.spinner} id="spinner" /> : buttonMessage}
                    </span>
                  </PrimaryButton>
                  <div className={css.sendBookingDisclaimer}>
                    <FormattedMessage id="StripePaymentForm.sendBookingDisclaimer" />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />

      <PageSlider
        active={showMissingInformationReminder}
        onClose={() => {
          setShowMissingInformationReminder(false);
        }}
        width="50%"
      >
        <IDVerification
          className={css.innerIDModal}
          onClose={() => {
            setShowMissingInformationReminder(false);
          }}
          showMissingInformationReminder={showMissingInformationReminder}
        />
      </PageSlider>
    </>
  );
};

export default injectIntl(StripeCheckoutForm);
