import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import InquiryAuthenticationPage from '../../InquiryAuthenticationPage/InquiryAuthenticationPage';
import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';

import css from './InquiryWizardStepSignup.css';

const InquirySignup = listing => {
  const autherName = listing?.listing?.author?.attributes?.profile?.displayName;
  const firstName = autherName ? autherName.split(' ')[0] : '';

  return (
    <InquiryWizardSection>
      <div className={css.stepStyles}>Last Step</div>
      <div className={css.titleStylesMobile}>
        <FormattedMessage id="InquiryWizard.mobileSignupTitle" values={{ firstName }} />
      </div>
      <div className={css.titleStylesDesktop}>
        <FormattedMessage id="InquiryWizard.desktopSignupTitle" values={{ firstName }} />
      </div>
      <div>
        <InquiryAuthenticationPage
          fromLTPlusPage
          useRedirects={false}
          inquiryButton
          plusLoginButton={false}
          listing={listing}
        />
      </div>
    </InquiryWizardSection>
  );
};

const InquiryWizardStepSignup = ({ intl, listing, formValues }) => {
  return (
    <InquiryWizardPanel>
      <InquirySignup intl={intl} listing={listing} formValues={formValues} />
    </InquiryWizardPanel>
  );
};

InquiryWizardStepSignup.propTypes = {};

export default injectIntl(InquiryWizardStepSignup);
