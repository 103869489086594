/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ReactComponent as IconGuests } from '../../assets/icons/ListingPage/icon-guest-or-user.svg';
import { ReactComponent as IconBedrooms } from '../../assets/icons/ListingPage/icon-bed-or-sleep.svg';
import { ReactComponent as IconBathrooms } from '../../assets/icons/ListingPage/icon-bath-or-shower.svg';
import { ReactComponent as IconAmenities } from '../../assets/icons/ListingPage/icon-amenitity-extra.svg';
import Modal from '../../components/Modal/Modal';
import { Button } from '../../components';
import { ReactComponent as IconInfo } from '../../assets/icons/ListingPage/icon-info.svg';
import HasTruncation from '../../components/Truncate/HasTruncation';
import css from './SectionLodgingOptions.css';

const CabinLodging = ({ cabin, overallInfo, intl, lodgingPrice, showPrice = false }) => {
  const [showMore, setShowMore] = useState(false);
  const [isHuntModalOpen, setOpenHuntModal] = useState(false);
  if (cabin.sportsmen === 0) {
    return null;
  }
  const price = lodgingPrice ? formatMoney(intl, JSON.parse(lodgingPrice, sdkTypes.reviver)) : null;
  return (
    <div className={css.sectionDetails}>
      <h3 className={css.sectionHeader}>
        <FormattedMessage id="ListingPage.lodgingCabinSite" />
        {showPrice && lodgingPrice && (
          <span>
            {' '}
            | {price} <FormattedMessage id="ListingPage.lodgingPriceFieldPostfix" />
          </span>
        )}
      </h3>
      {/* Sportsman */}
      {cabin.sportsmen > 0 && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconGuests />
            </div>
            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage id="ListingCard.Guests" values={{ count: cabin.sportsmen }} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Bedrooms */}
      {cabin.bedrooms > 0 && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconBedrooms />
            </div>
            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage
                  id="ListingPage.cabinBedroomsTitle"
                  values={{ count: cabin.bedrooms }}
                />
              </div>
            </div>
          </div>
          <div className={css.bedsList}>
            {(() => {
              const bedsArray = [];

              if (cabin.bunkBeds > 0) {
                bedsArray.push(
                  `${cabin.bunkBeds} Bunk${
                    bedsArray.length === 0 ? ` ${cabin.bunkBeds > 1 ? 'Beds' : 'Bed'}` : ''
                  }`
                );
              }
              if (cabin.queenBeds > 0) {
                bedsArray.push(
                  `${cabin.queenBeds} Queen${cabin.queenBeds > 1 ? 's' : ''}${
                    bedsArray.length === 0 ? ` ${cabin.queenBeds > 1 ? 'Beds' : 'Bed'}` : ''
                  }`
                );
              }
              if (cabin.kingBeds > 0) {
                bedsArray.push(
                  `${cabin.kingBeds} King${cabin.kingBeds > 1 ? 's' : ''}${
                    bedsArray.length === 0 ? ` ${cabin.kingBeds > 1 ? 'Beds' : 'Bed'}` : ''
                  }`
                );
              }
              if (cabin.singleBeds > 0) {
                bedsArray.push(
                  `${cabin.singleBeds} Single${cabin.singleBeds > 1 ? 's' : ''}${
                    bedsArray.length === 0 ? ` ${cabin.singleBeds > 1 ? 'Beds' : 'Bed'}` : ''
                  }`
                );
              }
              if (cabin.doubleBeds > 0) {
                bedsArray.push(
                  `${cabin.doubleBeds} Double${cabin.doubleBeds > 1 ? 's' : ''}${
                    bedsArray.length === 0 ? ` ${cabin.doubleBeds > 1 ? 'Beds' : 'Bed'}` : ''
                  }`
                );
              }

              return bedsArray.join(', ');
            })()}
          </div>
        </div>
      )}
      {/* Bathrooms */}
      {cabin.bathrooms > 0 && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconBathrooms className={css.icon} />
            </div>
            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage id="ListingPage.bathrooms" values={{ count: cabin.bathrooms }} />
              </div>
            </div>
          </div>
          <div className={css.bedsList}>
            {(() => {
              const bedsArray = [];
              if (cabin.hasHotWater === true || cabin.hasShower === true) {
                return (
                  <>
                    {cabin.hasHotWater && <FormattedMessage id="ListingPage.hasHotWater" />}
                    {cabin.hasShower && <FormattedMessage id="ListingPage.hasShower" />}
                  </>
                );
              }
              return bedsArray.join(', ');
            })()}
          </div>
        </div>
      )}
      {showMore && (
        <>
          {/* Cabin Amenities */}
          {cabin.amenities && cabin.amenities.length > 0 && (
            <div className={css.sectionDetailsContainer}>
              <div className={css.sectionDetailsWrapper}>
                <div className={css.sectionDetailsIcon}>
                  <IconAmenities className={css.icon} />
                </div>

                <div className={css.sectionDetailsContent}>
                  <div className={css.sectionDetailsHeader}>
                    <FormattedMessage id="ListingPage.houseAmenities" />
                  </div>
                </div>
              </div>
              <div className={css.sectionListWrapper}>
                {cabin.amenities.map((item, amenitiesIndex) => {
                  const capitalizedItem = item
                    .replace(/_/g, ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                  return (
                    <div key={`amenities-${amenitiesIndex}`} className={css.siteListItem}>
                      {capitalizedItem}
                    </div>
                  );
                })}
              </div>
              {cabin.amenitiesInfo && cabin.amenitiesInfo.trim().length > 0 && (
                <div>
                  <Modal
                    id="ListingPage.huntModal"
                    isOpen={isHuntModalOpen}
                    onClose={() => setOpenHuntModal(!isHuntModalOpen)}
                  >
                    <h3 className={css.huntFishDetailsTitle}>
                      <FormattedMessage id="ListingPage.houseAmenities" />
                    </h3>
                    <div>{cabin.amenitiesInfo}</div>
                  </Modal>
                  <div className={css.formFieldWrapper}>
                    <Button
                      isFullWidth
                      type="submit"
                      onClick={() => setOpenHuntModal(!isHuntModalOpen)}
                    >
                      <div className={css.buttonText}>
                        <FormattedMessage id="ListingPage.buttonText" />
                      </div>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* Cabin Description */}
      {overallInfo && overallInfo.length > 0 && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconInfo className={css.icon} />
            </div>

            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage id="ListingPage.houseDescription" />
              </div>
            </div>
          </div>
          <div className={css.sectionInfoWrapper}>
            <HasTruncation onExpand={expanded => setShowMore(expanded)}>
              {overallInfo}
            </HasTruncation>
          </div>
        </div>
      )}
    </div>
  );
};

export default CabinLodging;
