import omit from 'lodash/omit';

export const initialUploadState = {
  progress: {},
  files: {},
  apiIds: {},
  errors: {},
};

export const uploadActions = {
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  PROGRESS: 'PROGRESS',
};

export const uploadReducer = (state, action) => {
  switch (action.type) {
    case uploadActions.STARTED:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.tmpId]: 0,
        },
        files: {
          ...state.files,
          [action.payload.tmpId]: action.payload.file,
        },
      };
    case uploadActions.SUCCESS:
      return {
        ...state,
        progress: omit(state.progress, action.payload.tmpId),
        errors: omit(state.errors, action.payload.tmpId),
        apiIds: {
          ...state.apiIds,
          [action.payload.tmpId]: action.payload.apiId,
        },
      };
    case uploadActions.ERROR:
      return {
        ...state,
        progress: omit(state.progress, action.payload.tmpId),
        errors: {
          ...state.errors,
          [action.payload.tmpId]: action.payload.error,
        },
      };
    case uploadActions.PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.tmpId]: action.payload.progress,
        },
      };
    default:
      throw new Error();
  }
};
