import React from 'react';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

import PartyMembersSection from '../TransactionPanel/PartyMembersSection';

const InboxPartyMember = ({
  currentTransaction,
  currentUser,
  intl,
  onUpdatePartyMemberDetails,
  transactionState,
  isCustomer,
}) => {
  const partyMembers =
    get(currentTransaction, 'attributes.protectedData.packageLineItem.guestSize', 1) - 1;
  const hasPartyMembers = partyMembers > 0;

  const partyMemberDetails = get(
    currentTransaction,
    'attributes.protectedData.partyMemberDetails',
    null
  );

  if (!hasPartyMembers || !isCustomer || !transactionState.showPartyMemberSection) {
    return null;
  }

  return (
    <PartyMembersSection
      currentTransaction={currentTransaction}
      currentUser={currentUser}
      partyMembers={partyMembers}
      hasPartyMembers={hasPartyMembers}
      onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
      formComplete={partyMemberDetails}
      intl={intl}
    />
  );
};

export default injectIntl(InboxPartyMember);
