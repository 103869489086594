import React from 'react';
import './Quotes.css';
import { ReactComponent as StarSolid } from '../../assets/icons/quotes.svg';

const Quotes = ({ className = '', variant = 'light' }) => {
  const variantClass = variant === 'light' ? 'light' : 'dark';

  return (
    <div className={`root ${variantClass} ${className}`}>
      <StarSolid />
    </div>
  );
};

export default Quotes;
