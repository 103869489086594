import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SectionAvailableActivities } from '../..';
import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';

import css from './InquiryWizardStepActivities.css';

const InquiryWizardStepActivities = ({ listing, form, onNext }) => {
  const handleSubmit = values => {
    form.change('activity', values);
    onNext();
  };

  return (
    <InquiryWizardPanel>
      <InquiryWizardSection>
        <div className={css.titleStylesMobile}>
          <FormattedMessage id="NewInquiryPage.chooseActivity" />
        </div>
        <div className={css.sectionContainer}>
          <SectionAvailableActivities
            rootClassName={css.sectionActivities}
            title=""
            listing={listing}
            onSubmit={values => {
              handleSubmit(values.activity);
            }}
          />
        </div>
      </InquiryWizardSection>
    </InquiryWizardPanel>
  );
};

export default InquiryWizardStepActivities;
