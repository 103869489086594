import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '..';
import { propTypes, REVIEW_CUSTOMER, REVIEW_PROVIDER } from '../../util/types';

import css from './Reviews.css';

const Review = props => {
  const { review, intl, type } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.reviewsInfo}>
        <Avatar className={css.avatar} user={review.author} />
        <div className={css.reviewHeadingsWrapper}>
          <div className={css.reviewHeadings}>
            <UserDisplayName user={review.author} intl={intl} className={css.reviewAuthor} />
          </div>

          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
              gold
            />
          </span>
          <ReviewRating
            rating={review.attributes.rating}
            className={css.mobileReviewRating}
            reviewStarClassName={css.reviewRatingStar}
            gold
          />
        </div>
      </div>

      <div className={css.reviewContentWrapper}>
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>

      <div className={css.reviewDateWrapper}>
        <div className={css.reviewAuthorType}>
          {type === REVIEW_CUSTOMER && (
            <FormattedMessage id="Reviews.reviewByGuestDate" values={{ date: dateString }} />
          )}
          {type === REVIEW_PROVIDER && (
            <FormattedMessage id="Reviews.reviewByLandownerDate" values={{ date: dateString }} />
          )}
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, providerFirst } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (!reviews || !reviews.length) {
    return null;
  }

  return (
    <ul className={classes}>
      {reviews.map(r => {
        const author = providerFirst ? r.provider : r.customer;
        const reply = providerFirst ? r.customer : r.provider;

        return (
          <li key={`Review_${author.id.uuid}`} className={css.reviewItem}>
            <Review
              review={author}
              intl={intl}
              type={providerFirst ? REVIEW_PROVIDER : REVIEW_CUSTOMER}
            />

            {reply && (
              <div className={css.sectionLandowner}>
                <Review
                  review={reply}
                  intl={intl}
                  type={providerFirst ? REVIEW_CUSTOMER : REVIEW_PROVIDER}
                />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootClassName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
