/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import difference from 'lodash/difference';
import { PUBLISHED_TAB, UNPUBLISHED_TAB } from '../../util/package';
import { parseMoneyObjString } from '../../util/currency';
import { ReactComponent as PlusCircle } from '../../assets/icons/plus-circle-solid.svg';
import SectionPackageCard, { FETCHING_STATUS } from '../SectionPackageCard/SectionPackageCard';
import { EditListingSection, EditListingSectionHeading, SecondaryButton, Tabs, Tab } from '..';

import css from './EditListingPackagesSection.css';

const EditListingPackagesSection = ({
  packages,
  intl,
  onCreatePackageMode,
  onEditPackage,
  onTogglePublishPackage,
  onDuplicatePackage,
  onRemovePackage,
  currentListing,
}) => {
  const [fetchingPackageId, setFetchingPackageId] = useState(null);
  const [isPackageFetching, setIsPackageFetching] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(null);
  const [localPackages, setLocalPackages] = useState(packages);
  const parsedPackages = localPackages.map(item => {
    return {
      ...item,
      price: parseMoneyObjString(item.price),
    };
  });
  const parsedPublishedPackages = parsedPackages.filter(item => item.isPublished);
  const parsedUnpublishedPackages = parsedPackages.filter(item => !item.isPublished);

  const [activePackageTab, setActivePackageTab] = useState(
    parsedPublishedPackages.length ? PUBLISHED_TAB : UNPUBLISHED_TAB
  );

  useEffect(() => {
    // Decide which packages just changed. Based on that select active tab
    const diffPackages = difference(packages, localPackages);

    if (diffPackages?.length) {
      setActivePackageTab(diffPackages[0].isPublished ? PUBLISHED_TAB : UNPUBLISHED_TAB);
    }
    setLocalPackages(packages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  const handleEditPackage = (e, item) => {
    e.preventDefault();
    onEditPackage(item);
  };

  const handleTogglePublishPackage = (e, packageId, publish) => {
    e.preventDefault();

    setFetchingPackageId(packageId);
    setIsPackageFetching(true);
    setFetchingStatus(publish ? FETCHING_STATUS.PUBLISH : FETCHING_STATUS.UNPUBLISH);

    // Extend the animation by at least 1 sec
    setTimeout(() => {
      onTogglePublishPackage(packageId, publish);
      setFetchingPackageId(null);
      setFetchingStatus(null);
      setIsPackageFetching(false);
      setActivePackageTab(PUBLISHED_TAB);
    }, 1000);
  };

  const handleDuplicatePackage = (e, packageData) => {
    e.preventDefault();
    setFetchingPackageId(packageData.id);
    setIsPackageFetching(true);
    setFetchingStatus(FETCHING_STATUS.DUPLICATE);

    // Extend the animation by at least 1 sec
    setTimeout(() => {
      onDuplicatePackage({
        ...packageData,
        id: null,
      });
      setFetchingPackageId(null);
      setFetchingStatus(null);
      setIsPackageFetching(false);
    }, 1000);
  };

  const handleRemovePackage = async (e, packageId) => {
    e.preventDefault();
    setFetchingPackageId(packageId);
    setIsPackageFetching(true);
    setFetchingStatus(FETCHING_STATUS.DELETE);

    await onRemovePackage(packageId);
    setFetchingPackageId(null);
    setFetchingStatus(null);
    setIsPackageFetching(false);
  };

  return (
    <>
      <EditListingSection>
        <EditListingSectionHeading
          description={intl.formatMessage({
            id: 'EditListingHuntExperienceForm.packagesDescription',
          })}
        >
          <FormattedMessage id="EditListingHuntExperienceForm.packagesLabel" />
        </EditListingSectionHeading>
      </EditListingSection>

      <EditListingSection isFullWidth className={css.packageButtonWrapper}>
        <SecondaryButton type="button" onClick={onCreatePackageMode} className={css.packageButton}>
          <div className={css.buttonFlex}>
            <PlusCircle className={css.plusCircle} />
            <div>
              <FormattedMessage id="EditListingSpeciesPanel.createPackage" />
            </div>
          </div>
        </SecondaryButton>
      </EditListingSection>

      {parsedPackages.length > 0 && (
        <EditListingSection isFullWidth className={css.packagesWrapper}>
          <div className={css.packageTabsWrapper}>
            <Tabs>
              <Tab
                handleClick={() => setActivePackageTab(PUBLISHED_TAB)}
                isSelected={activePackageTab === PUBLISHED_TAB}
              >
                <FormattedMessage id="EditListingSpeciesPanel.publishedPackage" />
              </Tab>

              <Tab
                handleClick={() => setActivePackageTab(UNPUBLISHED_TAB)}
                isSelected={activePackageTab === UNPUBLISHED_TAB}
              >
                <FormattedMessage id="EditListingSpeciesPanel.unpublishedPackage" />
              </Tab>
            </Tabs>
          </div>

          <div className={css.packagesTabsContent}>
            {activePackageTab === PUBLISHED_TAB &&
              parsedPublishedPackages.map((item, index) => (
                <SectionPackageCard
                  editMode
                  key={`package-${index}`}
                  item={item}
                  isPackageFetching={isPackageFetching && fetchingPackageId === item.id}
                  onEdit={handleEditPackage}
                  onDuplicate={handleDuplicatePackage}
                  onRemove={handleRemovePackage}
                  onTogglePublish={handleTogglePublishPackage}
                  fetchingStatus={fetchingStatus}
                  listing={currentListing}
                  packageId={item.id}
                  isResponsive
                />
              ))}
            {activePackageTab === UNPUBLISHED_TAB &&
              parsedUnpublishedPackages.map((item, index) => (
                <SectionPackageCard
                  editMode
                  key={`package-${index}`}
                  item={item}
                  isPackageFetching={isPackageFetching && fetchingPackageId === item.id}
                  onEdit={handleEditPackage}
                  onDuplicate={handleDuplicatePackage}
                  onRemove={handleRemovePackage}
                  onTogglePublish={handleTogglePublishPackage}
                  fetchingStatus={fetchingStatus}
                  listing={currentListing}
                  packageId={item.id}
                  isResponsive
                />
              ))}
          </div>
        </EditListingSection>
      )}
    </>
  );
};

EditListingPackagesSection.defaultTypes = {};

EditListingPackagesSection.propTypes = {
  intl: intlShape.isRequired,
  onEditPackage: func.isRequired,
  onCreatePackageMode: func.isRequired,
};

export default injectIntl(EditListingPackagesSection);
