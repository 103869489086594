import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CountdownWithProgressBar } from '../../components';

import css from './PackagePage.css';

const SectionCountdown = ({ isEarlyAccess, userHasPremiumMembership, earlyAccessExpiration }) => {
  if (!isEarlyAccess) {
    return null;
  }

  return (
    <div className={css.sectionCountdownWrapper}>
      <CountdownWithProgressBar
        label={
          userHasPremiumMembership ? (
            <FormattedMessage id="PackagePage.earlyAccessPremiumShort" />
          ) : (
            <FormattedMessage id="PackagePage.earlyAccessNonPremium" />
          )
        }
        expirationTimestamp={earlyAccessExpiration}
      />
    </div>
  );
};

export default SectionCountdown;
