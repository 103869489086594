//
// Use common polyfill dependencies from package.json
//

// Standard lib polyfills
require('core-js/features/map');
require('core-js/features/set');
require('core-js/features/object');
require('core-js/features/array');
require('core-js/features/string');
require('core-js/features/number');

// Request Animation Frame
require('raf/polyfill');

// Intersection observer
require('intersection-observer');

// Smoothscroll
require('smoothscroll-polyfill').polyfill();

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/parseFloat
if (typeof Number.parseFloat === 'undefined' && typeof window !== 'undefined') {
  Number.parseFloat = window.parseFloat;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/parseInt
if (typeof Number.parseInt === 'undefined' && typeof window !== 'undefined') {
  Number.parseInt = window.parseInt;
}

// NaN is the only value in javascript which is not equal to itself.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
if (typeof Number.isNaN === 'undefined') {
  // eslint-disable-next-line no-self-compare
  Number.isNaN = value => value !== value;
}
