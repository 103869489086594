/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LODGING_INFO } from '../../util/package';
import { ReactComponent as IconWater } from '../../assets/icons/ListingPage/water.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/ListingPage/icon-info.svg';
import HasTruncation from '../../components/Truncate/HasTruncation';
import css from './SectionLodgingOptions.css';

const TentCampsite = ({ details, lodgingPrice, intl }) => {
  const price = lodgingPrice.price
    ? formatMoney(intl, JSON.parse(lodgingPrice.price, sdkTypes.reviver))
    : null;

  return (
    <div className={css.sectionDetails} id={`tent-${lodgingPrice.index}`}>
      <h3 className={css.sectionHeader}>
        <FormattedMessage id="ListingPage.lodgingTentSite" />

        {lodgingPrice.option === LODGING_INFO.OPTIONAL && (
          <span>
            {' '}
            | {price} <FormattedMessage id="ListingPage.lodgingPriceFieldPostfix" />
          </span>
        )}
      </h3>
      {/* Access to water */}
      {details?.tent?.hasDrinkingWater && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconWater className={css.icon} />
            </div>

            <div className={css.sectionDetailsContent}>
              <div className={css.sectionDetailsHeader}>
                <FormattedMessage
                  id="ListingPage.tentWaterAccessTitle"
                  values={{ option: 'Yes' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Additional Details */}
      {details?.tent?.info && (
        <div className={css.sectionDetailsContainer}>
          <div className={css.sectionDetailsWrapper}>
            <div className={css.sectionDetailsIcon}>
              <IconInfo className={css.icon} />
            </div>
            <div className={css.sectionDetailsHeader}>
              <FormattedMessage id="ListingPage.rvDetailsAdditionalTitle" />
            </div>
          </div>

          <div className={css.sectionInfoWrapper}>
            <HasTruncation> {details?.tent?.info}</HasTruncation>
          </div>
        </div>
      )}
    </div>
  );
};

export default TentCampsite;
