import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingPanel.css';

const EditListingPanel = ({ className, rootClassName, children }) => {
  const classes = classNames(rootClassName || css.root, className);

  return <div className={classes}>{children}</div>;
};

EditListingPanel.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingPanel.propTypes = {
  className: string,
  rootClassName: string,
};

export default EditListingPanel;
