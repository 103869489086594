import React from 'react';
import { FormattedMessage } from 'react-intl';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionExpireCustomerReviewPeriodLateCancel = ({ transition, ownRole, buttons }) => {
  const isOwnTransition = transition.by === ownRole;

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.transitionExpireCustomerReviewPeriodLateCancel" />
        ) : (
          <FormattedMessage id="BookingStatus.saleExpireCustomerReviewPeriodLateCancel" />
        )
      }
      buttons={buttons}
    />
  );
};

export default InboxTransitionExpireCustomerReviewPeriodLateCancel;
