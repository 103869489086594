import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';
import { object } from 'prop-types';

import { formatDate } from '../../util/dates';
import { TRANSITION_ENQUIRE_GATED, TRANSITION_ENQUIRE_EXPIRE } from '../../util/transaction';

import { AvatarSmall } from '../Avatar/Avatar';
import css from './BookingStatusInquiryExpired.css';
import BookingStatusInquiry from './BookingStatusInquiry';

const BookingStatusInquiryExpired = ({ intl, ownRole, transaction, otherUsersName }) => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const customer = get(transaction, 'customer', null);
  const provider = get(transaction, 'provider', null);

  const inquiryExpiredTransition = transitions.find(
    item => item.transition === TRANSITION_ENQUIRE_EXPIRE
  );
  const inquiryGatedTransition = transitions.find(
    item => item.transition === TRANSITION_ENQUIRE_GATED
  );
  const isOwnTransition = inquiryGatedTransition.by === ownRole;

  const user = isOwnTransition ? provider : customer;

  const todayString = intl.formatMessage({ id: 'BookingStatus.today' });
  const inquiryDisplayName = get(otherUsersName, 'props.user.attributes.profile.displayName', '');

  return (
    <div>
      <div className={css.inquiryExpiredWrapper}>
        <div className={css.divider} />
        <div className={css.bookingStatus}>
          <div className={css.bookingStatusHeader}>
            {isOwnTransition ? (
              <FormattedMessage id="BookingStatus.transitionOwnEnquiryExpired" />
            ) : (
              <FormattedMessage id="BookingStatus.transitionEnquiryExpired" />
            )}
          </div>
          <div className={css.bookingStatusBody}>
            {isOwnTransition ? (
              <FormattedMessage id="BookingStatus.transitionOwnEnquiryExpiredSubtext" />
            ) : (
              <FormattedMessage
                id="BookingStatus.transitionEnquiryExpiredSubtext"
                values={{
                  guest: inquiryDisplayName,
                }}
              />
            )}
          </div>
          <div className={css.bookingStatusDate}>
            {!isOwnTransition}
            {formatDate(intl, todayString, inquiryExpiredTransition.createdAt)}
          </div>
        </div>
        <div className={css.divider} />
      </div>

      <div
        className={classNames(css.inquiryGatedWrapper, {
          [css.guestInquiryGatedWrapper]: !isOwnTransition,
        })}
      >
        <div
          className={classNames(css.inquiryGated, {
            [css.guestInquiryGated]: !isOwnTransition,
          })}
        >
          <div
            className={classNames(css.inquiryGatedTitle, {
              [css.inquiryGatedTitleWithAuthor]: !isOwnTransition,
            })}
          >
            {!isOwnTransition && (
              <>
                <AvatarSmall className={css.avatar} user={user} />
                <span className={css.inquiryGatedAuthor}>{inquiryDisplayName}</span>
              </>
            )}
            <span>{formatDate(intl, todayString, inquiryGatedTransition.createdAt)}</span>
          </div>

          <BookingStatusInquiry
            isOwnTransition={isOwnTransition}
            transaction={transaction}
            otherUsersName={otherUsersName}
            hasInquiryExpired
          />
        </div>
      </div>
    </div>
  );
};

BookingStatusInquiryExpired.propTypes = {
  transaction: object.isRequired,
};

export default injectIntl(BookingStatusInquiryExpired);
