import React from 'react';
import { node } from 'prop-types';
import css from './LayoutProfile.css';

const LayoutProfile = ({ infoComponent, mainComponent }) => {
  return (
    <div className={css.wrapper}>
      <div className={css.infoContainer}>{infoComponent}</div>
      <div className={css.mainContainer}>{mainComponent}</div>
    </div>
  );
};

LayoutProfile.propTypes = {
  infoComponent: node.isRequired,
  mainComponent: node.isRequired,
};

export default LayoutProfile;
