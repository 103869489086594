import React from 'react';
import { FormattedMessage } from 'react-intl';
import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionOperatorEarlyCancel = ({ transition, buttons }) => {
  return (
    <InboxTransitionBase
      header={<FormattedMessage id="BookingStatus.transitionCancel" />}
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    />
  );
};

export default InboxTransitionOperatorEarlyCancel;
