import { types as sdkTypes } from '../../util/sdkLoader';
import * as props from '../prop-names';

import {
  formatEvent,
  getEventDate,
  getDefaultUserProps,
  getCurrUserId,
  getCurrUserRole,
  isLandowner,
  isSportsman,
} from '../helpers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Tracked when new query is sent by a user
export const trackNewQueryInitiatedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const listing = getMarketplaceEntities(state, [
    { id: new sdkTypes.UUID(payload.listingId), type: 'listing' },
  ]).pop();

  event.mp_properties = {
    ...getDefaultUserProps(state),
    [props.FIRST_NEW_QUERY_INITIATED]: getEventDate(),
    [props.LAST_NEW_QUERY_INITIATED]: getEventDate(),
    [props.NEW_QUERY_INITIATED_COUNT]: 1,
    [props.SPORTSMAN_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: listing.author.id.uuid,
    [props.LISTING_ID]: payload.listingId.uuid,
  };

  return event;
};

// Tracked when initial query is read by the user
export const trackInitialQueryReadEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return null;
  }

  if (payload.totalItems > 1) {
    return null;
  }

  event.mp_properties = {
    [props.FIRST_INITIAL_QUERY_READ_DATE]: getEventDate(),
    [props.LAST_INITIAL_QUERY_READ_DATE]: getEventDate(),
    [props.INITIAL_QUERY_READ_COUNT]: 1,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.LISTING_NAME]: tx.listing.attributes.title,
    [props.BOOKING]: tx.booking ? tx.booking.id.uuid : null,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.USER_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: getCurrUserId(state),
  };

  return event;
};

// Tracked when initial query is read by the landowner (mirror)
export const trackInitialQueryReadByLandownerEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return null;
  }

  if (payload.totalItems > 1) {
    return null;
  }

  event.mp_properties = {
    [props.FIRST_QUERY_READ_BY_LANDOWNER_DATE]: getEventDate(),
    [props.LAST_QUERY_READ_BY_LANDOWNER_DATE]: getEventDate(),
    [props.QUERY_READ_BY_LANDOWNER_COUNT]: 1,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.LISTING_NAME]: tx.listing.attributes.title,
    [props.BOOKING]: tx.booking ? tx.booking.id.uuid : null,
    [props.LANDOWNER_ID]: tx.listing.author.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.USER_ID]: getCurrUserId(state),
  };

  return event;
};

// Tracked when a query is read by Landowner
export const trackNewQueryReadByLandownerEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();
  const isLastMessageFromSportsman = userId =>
    payload.totalItems > 0 && payload.messages[0].sender.id.uuid !== userId;

  if (!isLandowner(state)) {
    return null;
  }

  if (!isLastMessageFromSportsman(getCurrUserId(state))) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: tx.listing.author.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.ROLE]: getCurrUserRole(state),
    [props.FIRST_NEW_QUERY_READ_DATE]: getEventDate(),
    [props.LAST_NEW_QUERY_READ_DATE]: getEventDate(),
    [props.NEW_QUERY_READ_COUNT]: 1,
  };

  return event;
};

// Tracked when Landowner replies to a query
export const trackNewQueryRepliedByLandownerEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isLandowner(state)) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: tx.listing.author.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.ROLE]: getCurrUserRole(state),
    [props.FIRST_NEW_QUERY_REPLIED_DATE]: getEventDate(),
    [props.LAST_NEW_QUERY_REPLIED_DATE]: getEventDate(),
    [props.NEW_QUERY_REPLIED_COUNT]: 1,
  };

  return event;
};

export const trackSportsmanRepliedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();

  if (!isSportsman(state)) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: tx.listing.author.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.ROLE]: getCurrUserRole(state),
    [props.FIRST_REPLY_DATE]: getEventDate(),
    [props.LAST_REPLY_DATE]: getEventDate(),
    [props.REPLY_COUNT]: 1,
  };

  return event;
};

// Tracked when Sportsman reads a query
export const trackReplyReadBySportsmanEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const tx = getMarketplaceEntities(state, [{ id: payload.txId, type: 'transaction' }]).pop();
  const isLastMessageFromLandowner = userId =>
    payload.totalItems > 0 && payload.messages[0].sender.id.uuid !== userId;

  if (!isSportsman(state)) {
    return null;
  }

  if (!isLastMessageFromLandowner(getCurrUserId(state))) {
    return null;
  }

  event.mp_properties = {
    [props.USER_ID]: getCurrUserId(state),
    [props.LANDOWNER_ID]: tx.listing.author.id.uuid,
    [props.SPORTSMAN_ID]: tx.customer.id.uuid,
    [props.LISTING_ID]: tx.listing.id.uuid,
    [props.ROLE]: getCurrUserRole(state),
    [props.FIRST_REPLY_READ_DATE]: getEventDate(),
    [props.LAST_REPLY_READ_DATE]: getEventDate(),
    [props.REPLY_READ_COUNT]: 1,
  };

  return event;
};
