import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { UserCard } from '../../components';

import css from './ListingPage.css';

const SectionHostMaybe = props => {
  const {
    listing,
    onContactUser,
    currentUser,
    fullProfile,
    className,
    isEarlyAccess,
    rootClassName,
    mobileHost,
    showRate,
    isContactDisabled,
    hideExtraSpace,
  } = props;

  if (!listing.author) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div id={fullProfile && 'host'} className={css.sectionHost}>
        {fullProfile && (
          <h3 className={css.sectionHeader}>
            <FormattedMessage id="ListingPage.yourHostHeading" />
          </h3>
        )}
        <UserCard
          landowner={listing.author}
          currentUser={currentUser}
          onContactUser={onContactUser}
          fullProfile={fullProfile}
          mobileHost={mobileHost}
          showRate={showRate}
          isEarlyAccess={isEarlyAccess}
          isContactDisabled={isContactDisabled}
          hideExtraSpace={hideExtraSpace}
        />
      </div>
    </div>
  );
};

SectionHostMaybe.defaultProps = {
  fullProfile: null,
  currentUser: null,
  className: null,
  rootClassName: null,
  mobileHost: false,
  showRate: false,
};

SectionHostMaybe.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  onContactUser: func.isRequired,
  currentUser: propTypes.currentUser,
  fullProfile: bool,
  rootClassName: string,
  mobileHost: bool,
  showRate: bool,
};

export default SectionHostMaybe;
