import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import carolImage from './images/carol.jpg';

import css from './FAQSectionLandownerQuote.css';
import { Container } from '../../components/Layout';

const FAQSectionLandownerQuote = props => {
  return (
    <Container>
      <div className={css.root}>
        <div className={css.imageContainer}>
          <img src={carolImage} className={css.image} />
        </div>

        <div className={css.contentContainer}>
          <div className={css.quotes}>“</div>

          <div className={css.body}>
            <FormattedMessage id="FAQLandownersPage.quote.body" />
          </div>

          <div className={css.author}>
            <FormattedMessage id="FAQLandownersPage.quote.author" />
          </div>

          <div className={css.location}>
            <FormattedMessage id="FAQLandownersPage.quote.location" />
          </div>
        </div>
      </div>
    </Container>
  );
};

FAQSectionLandownerQuote.propTypes = {};

export default FAQSectionLandownerQuote;
