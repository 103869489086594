import { get } from 'lodash';
import affiliate, { AP, MEDIUM, CAMPAIGN, SOURCE } from '../../util/affiliate';
import * as props from '../prop-names';
import {
  formatEvent,
  getEventDate,
  getAbsoluteUrl,
  getDefaultUserProps,
  getCurrUserAttribute,
  getIdpSignupData,
  getCurrUserRole,
  getEmailProps,
} from '../helpers';

// Tracked when the user starts the signup process
export const trackSignupStartedEvent = (type, payload) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    [props.PAGE_URL]: getAbsoluteUrl(payload.path),
    [props.SIGNUP_START_DATE]: getEventDate(),
  };

  return event;
};

// Tracked when the user successfully signs up
export const trackSuccessfulRegistrationEvent = (type, payload) => {
  const event = formatEvent(type, payload);

  const signupState = {
    Auth: { isAuthenticated: true },
    user: { currentUser: payload },
  };

  event.mp_properties = {
    [props.SIGNUP_DATE]: getEventDate(),
    [props.SIGNUP_SOURCE]:
      getIdpSignupData(signupState, 'method') || affiliate.get(SOURCE, 'Direct'),
    ...getDefaultUserProps(signupState),
    ...getEmailProps(signupState),
    [props.SIGNUP_MEDIUM]: affiliate.get(MEDIUM, 'Direct'),
    [props.SIGNUP_CAMPAIGN]: affiliate.get(CAMPAIGN, 'Signup'),
    [props.AFFILIATE_PROGRAM]: affiliate.get(AP, 'None'),
    [props.PHONE]: getCurrUserAttribute(signupState, 'profile.protectedData.phoneNumber'),
    [props.SECONDARY_PHONE]: getCurrUserAttribute(
      signupState,
      'profile.protectedData.secondaryPhoneNumber'
    ),
    [props.FIRST_NAME]: getCurrUserAttribute(signupState, 'profile.firstName'),
    [props.LAST_NAME]: getCurrUserAttribute(signupState, 'profile.lastName'),
    [props.ROLE]: getCurrUserRole(signupState),
  };

  return event;
};

// Tracked whenever user logs in
export const trackLoginEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const { email, createdAt } = payload.attributes;

  event.mp_properties = {
    [props.FIRST_LOGIN_DATE]: getEventDate(createdAt),
    [props.LAST_LOGIN_DATE]: getEventDate(),
    [props.LOGIN_COUNT]: 1,
    [props.EMAIL]: email,
    ...getDefaultUserProps(state),
  };

  return event;
};

// Tracked whenever user logs out
export const trackLogoutEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);

  event.mp_properties = {
    ...getDefaultUserProps(state),
    [props.USER_STATUS]: 'Visitor',
    [props.FIRST_LOG_OUT_DATE]: getEventDate(),
    [props.LAST_LOG_OUT_DATE]: getEventDate(),
    [props.TOTAL_LOG_OUT_COUNT]: 1,
  };

  return event;
};

// Tracked when users update their profile
export const trackProfileUpdatedEvent = (type, payload, state) => {
  const event = formatEvent(type, payload);
  const { attributes, relationships } = payload.data;
  const {
    profile: { publicData },
  } = attributes;

  event.mp_properties = {
    ...getDefaultUserProps(state),
    [props.FIRST_NAME]: attributes.profile.firstName,
    [props.LAST_NAME]: attributes.profile.lastName,
    [props.BIO]: attributes.profile.bio,
    [props.HAS_BIO]: !!attributes.profile.bio,
    [props.HAS_PROFILE_IMAGE]: !!relationships.profileImage.data,
    [props.LIKES_TO_FISH]: get(publicData, 'likeToFish', false),
    [props.HOME_STATE]: get(publicData, 'usState.selectedPlace.address'),
    [props.INTERESTED_ACTIVITIES]: get(publicData, 'interestedActivities', []),
    [props.PREFERRED_HUNT_SPECIES]: get(publicData, 'preferredHuntingSpecies', []),
    [props.PREFERRED_HUNT_WEAPONS]: get(publicData, 'preferredHuntingWeapons', []),
    [props.PREFERRED_HUNT_STATES]: get(publicData, 'preferredHuntingStates', []).map(
      huntingState => huntingState.address
    ),
  };

  return event;
};
