import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { shouldShowErrorForField } from '../../util/forms';
import { ValidationError, FieldLabel, FieldCopy } from '..';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl';

const LocationAutocompleteInputComponent = ({ rootClassName, labelClassName, ...restProps }) => {
  const { input, label, help, meta, valueFromForm, ...otherProps } = restProps;

  const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
  const locationAutocompleteProps = { label, meta, ...otherProps, input: { ...input, value } };
  const fieldHelp = help ? <FieldCopy>{help}</FieldCopy> : null;

  return (
    <div className={rootClassName}>
      {label && (
        <FieldLabel className={labelClassName} htmlFor={input.name}>
          {label}
        </FieldLabel>
      )}
      <LocationAutocompleteInputImpl {...locationAutocompleteProps} />

      {shouldShowErrorForField(meta) ? <ValidationError fieldMeta={meta} /> : fieldHelp}
    </div>
  );
};

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
