import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';

import { composeValidators, requiredFieldArrayCheckbox } from '../../../util/validators';
import { getAddOnFees } from '../../../util/listing';
import { formatMoney } from '../../../util/currency';
import { FieldCheckbox } from '../..';
import InquiryWizardPanel from '../InquiryWizardPanel';
import InquiryWizardSection from '../InquiryWizardSection';

import css from './InquiryWizardStepSpecies.css';

const SpeciesCheckbox = ({ intl, options, fields }) => {
  return (
    <div>
      {options
        .filter(({ isActive }) => isActive === true)
        .map(({ name, price, checkBoxLabel }) => {
          return (
            <div className={css.speciesCheckBoxGroup} key={name}>
              <FieldCheckbox
                label={checkBoxLabel}
                id={`speciesCheckbox_${name}`}
                name={fields.name}
                value={name}
              />
              <span>{formatMoney(intl, price)}</span>
            </div>
          );
        })}
    </div>
  );
};

const SpeciesCheckboxes = props => <FieldArray component={SpeciesCheckbox} {...props} />;

const InquiryWizardStepSpecies = ({ intl, listing }) => {
  const species = getAddOnFees(intl, listing);

  return (
    <InquiryWizardPanel className={css.panelContainer}>
      <InquiryWizardSection>
        <SpeciesCheckboxes
          intl={intl}
          name="species"
          options={species}
          validate={composeValidators(
            requiredFieldArrayCheckbox(intl.formatMessage({ id: 'InquiryWizard.fieldRequired' }))
          )}
        />
      </InquiryWizardSection>
    </InquiryWizardPanel>
  );
};

InquiryWizardStepSpecies.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(InquiryWizardStepSpecies);
