import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { txRoleIsCustomer } from '../../util/transaction';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionRefundPeriodOver = ({ transition, user, ownRole, buttons }) => {
  const isCustomer = txRoleIsCustomer(ownRole);
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  return (
    <InboxTransitionBase
      header={
        isCustomer ? (
          <FormattedMessage id="BookingStatus.transitionAccept" values={{ displayName }} />
        ) : (
          <FormattedMessage id="BookingStatus.ownTransitionAccept" values={{ displayName }} />
        )
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    >
      {isCustomer ? (
        <FormattedMessage id="BookingStatus.transitionAcceptSubtext" />
      ) : (
        <FormattedMessage id="BookingStatus.ownTransitionAcceptSubtext" values={{ displayName }} />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionRefundPeriodOver;
