/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ensureListing, ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { getPackageImages } from '../../util/listing';
import { NamedLink, ResponsiveImage, UserCard } from '..';
import css from './BookingListingBreakdownSection.css';

const MobileListingAvatarSection = ({ listing, packageInfo, currentUser }) => {
  const currentListing = ensureListing(listing);
  const currentAuthor = ensureUser(currentListing.author);
  const listingTitle = currentListing.attributes.title;
  const { title: packageTitle, id: packageId } = packageInfo || {};

  const packagesImages = getPackageImages(currentListing, packageId);
  const headerImages = packagesImages.length ? packagesImages : currentListing.images;

  const backToListing = (
    <NamedLink
      className={css.detailsTitle}
      name="PackagePage"
      params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle), packageId }}
    >
      <h1 className={css.detailsTitle}>{packageTitle}</h1>
    </NamedLink>
  );

  return (
    <div className={css.aspectWrapper}>
      <div className={css.imageWrapper}>
        <ResponsiveImage
          rootClassName={css.image}
          alt={listingTitle}
          image={headerImages[0]}
          variants={['facebook', 'scaled-large']}
        />
      </div>
      <div className={css.listingTitle}>
        <h2 className={css.detailsTitle}>{backToListing}</h2>
      </div>
      <div className={css.authorWrapper}>
        <UserCard landowner={currentAuthor} currentUser={currentUser} fullProfile={false} noBio />
      </div>
    </div>
  );
};

MobileListingAvatarSection.defaultProps = {};

MobileListingAvatarSection.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default MobileListingAvatarSection;
