/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { object, func, bool } from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { ReactComponent as HeartEmpty } from '../../assets/icons/heart-regular.svg';
import { ReactComponent as HeartFull } from '../../assets/icons/heart-solid.svg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './FavoritePackage.css';
import Button, { InlineTextButton } from '../Button/Button';
import NamedLink from '../NamedLink/NamedLink';
import { toast } from '../Toaster/Toaster';
import FavoritePackageTooltip from '../FavoritePackageTooltip/FavoritePackageTooltip';

// Time (in milliseconds) before the tooltip is triggered
const TOOLTIP_TIME_TRIGGER = 60000;
// Duration (in milliseconds) the tooltip will be hidden when the Got It button is clicked.
const TOOLTIP_HIDDEN_DURATION = 1209600000;

export const toastPackageFavorited = currentUser =>
  toast(
    <span>
      <FormattedMessage
        id="Toast.favoritePackage"
        values={{
          favorites: (
            <NamedLink name="FavoritesPage" params={{ id: currentUser.id.uuid, tab: 'packages' }}>
              <FormattedMessage id="Toast.favorites" />
            </NamedLink>
          ),
        }}
      />
    </span>
  );

export const toastPackageUnfavorited = currentUser =>
  toast(
    <span>
      <FormattedMessage
        id="Toast.unfavoritePackage"
        values={{
          favorites: (
            <NamedLink name="FavoritesPage" params={{ id: currentUser.id.uuid, tab: 'packages' }}>
              <FormattedMessage id="Toast.favorites" />
            </NamedLink>
          ),
        }}
      />
    </span>
  );

const FavoritePackage = ({
  currentUser,
  listing,
  package: pkg,
  onUnfavoritePackage,
  onFavoritePackage,
  inlineButton,
  history,
  followSubjectMutation,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [gotItClicked, setGotItClicked] = useState(false);
  const [blockTooltip, setBlockTooltip] = useState(false);
  const [mountTime] = useState(new Date().getTime());
  const [tooltipState, setTooltipState] = useLocalStorage('hideFavoritePackageTooltip', null);

  const elapsedSinceMount = new Date().getTime() - mountTime;
  const scrollListenerTimeoutRef = useRef(null);
  const listingId = get(listing, 'id.uuid');
  const favoriteListings = get(currentUser, 'attributes.profile.publicData.favoritePackages', []);
  const isFavorited = favoriteListings.indexOf(`${listingId}:${pkg.id}`) > -1;
  const userId = currentUser?.id?.uuid;
  const handleRedirect = () => {
    window.open('/login', '_blank');
  };
  const match = useRouteMatch('/l/:slug/:id/package/:packageId');
  const timeoutRef = useRef(null);

  const handleGotItClick = () => {
    const currentTime = new Date().getTime();
    const futureTime = currentTime + TOOLTIP_HIDDEN_DURATION;

    const newTooltipState = {
      value: true,
      expiry: futureTime.toString(),
    };

    setTooltipState(newTooltipState);

    setShowTooltip(false);
    setGotItClicked(true);
  };

  const tooltipDisplayLogic = () => {
    if (!match) {
      setShowTooltip(false);
      return;
    }

    if (favoriteListings.length > 0) {
      setShowTooltip(false);
      return;
    }

    const storedTooltipState = tooltipState;
    const lastGotItClickTimestamp = storedTooltipState
      ? parseInt(storedTooltipState.expiry, 10) - TOOLTIP_HIDDEN_DURATION
      : null;
    const futureTimestamp = storedTooltipState ? parseInt(storedTooltipState.expiry, 10) : null;

    const currentTime = new Date().getTime();
    const timeSinceLastClick = currentTime - lastGotItClickTimestamp;

    const isMobile = window.innerWidth <= 768;

    if (futureTimestamp && currentTime >= futureTimestamp) {
      setGotItClicked(false);
    }
    if (!gotItClicked) {
      if (
        !isFavorited &&
        (isMobile || window.scrollY < 590) &&
        (!lastGotItClickTimestamp || timeSinceLastClick >= TOOLTIP_HIDDEN_DURATION)
      ) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    } else {
      setShowTooltip(false);
    }
  };

  const followProperty = () => {
    if (userId) {
      followSubjectMutation(userId, listingId, { sms: true, email: true });
    } else {
      handleRedirect();
    }
  };

  const handleClick = async e => {
    e.preventDefault();
    setShowTooltip(false);
    setBlockTooltip(true);

    if (currentUser) {
      if (isFavorited) {
        await onUnfavoritePackage(e);
        toastPackageUnfavorited(currentUser);
      } else {
        await onFavoritePackage(e);
        toastPackageFavorited(currentUser);
        followProperty();
      }
    } else {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration()), {
        from: window.location.pathname,
      });
    }
  };

  useEffect(() => {
    const displayTooltipWithDelay = () => {
      timeoutRef.current = setTimeout(tooltipDisplayLogic, TOOLTIP_TIME_TRIGGER);
    };

    if (!blockTooltip) {
      displayTooltipWithDelay();

      // If elapsed time is more than timeTrigger seconds, attach the scroll listener
      if (elapsedSinceMount >= TOOLTIP_TIME_TRIGGER) {
        window.addEventListener('scroll', tooltipDisplayLogic);
      } else {
        // wait the remaining time to attach the scroll listener
        scrollListenerTimeoutRef.current = setTimeout(() => {
          window.addEventListener('scroll', tooltipDisplayLogic);
        }, TOOLTIP_TIME_TRIGGER - elapsedSinceMount);
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(scrollListenerTimeoutRef.current);
      window.removeEventListener('scroll', tooltipDisplayLogic);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorited, match, blockTooltip]);
  const tooltipContent = (
    <div>
      <div className={css.boldHeading}>
        <FormattedMessage id="PackagePage.tooltipHeading" />
      </div>
      <div className={css.tooltipDescription}>
        <FormattedMessage id="PackagePage.tooltipDescription" />
      </div>
      <Button className={css.tooltipButton} onClick={handleGotItClick}>
        <FormattedMessage id="PackagePage.tooltipButton" />
      </Button>
    </div>
  );

  return (
    <>
      {inlineButton && (
        <span className={css.root}>
          <FavoritePackageTooltip
            hideArrow
            hideCloseButton
            placement="bottom"
            tooltipShown={showTooltip}
            tooltip={tooltipContent}
            onGotItClick={handleGotItClick}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: () => {
                    return [-127, 15];
                  },
                },
              },
            ]}
          >
            <InlineTextButton className={css.favButton} onClick={handleClick}>
              <span className={css.buttonBody}>
                {isFavorited ? (
                  <HeartFull className={css.iconFull} />
                ) : (
                  <HeartEmpty className={css.icon} />
                )}
                <span className={css.buttonLabel}>
                  <FormattedMessage id="ListingPage.favoriteButton" />
                </span>
              </span>
            </InlineTextButton>
          </FavoritePackageTooltip>
        </span>
      )}

      {!inlineButton && (
        <>
          <button type="button" className={css.circleFav} onClick={handleClick}>
            {isFavorited ? (
              <HeartFull className={css.iconFull} />
            ) : (
              <HeartEmpty className={css.icon} />
            )}
          </button>

          <FavoritePackageTooltip
            hideArrow
            hideCloseButton
            placement="bottom-start"
            tooltipShown={showTooltip}
            tooltip={tooltipContent}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: () => {
                    return [22, 15];
                  },
                },
              },
            ]}
          />
        </>
      )}
    </>
  );
};

FavoritePackage.defaultProps = {
  currentUser: null,
  package: null,
  onUnfavoritePackage: null,
  onFavoritePackage: null,
  inlineButton: false,
  history: null,
};

FavoritePackage.propTypes = {
  currentUser: object,
  package: object,
  onUnfavoritePackage: func,
  onFavoritePackage: func,
  history: object,
  inlineButton: bool,
};

export default withRouter(FavoritePackage);
