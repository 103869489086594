import { get } from 'lodash';
import moment from 'moment';
import config from '../config';
import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

export const PUBLISHED_TAB = 'PUBLISHED_TAB';
export const UNPUBLISHED_TAB = 'UNPUBLISHED_TAB';

export const LODGING_INFO = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  NOT_OFFERED: 'Not Offered',
};

export const LODGING_TYPE = {
  CABIN: 'cabin',
  HOUSE: 'house',
};

export const getInitialPackage = (values, activity) => {
  const minPrice = new Money(config.listingPackageMinimumPrice, config.currency);
  const availableDateFrom = values?.availableDateFrom;
  const availableDateTo = values?.availableDateTo;
  let dates = null;

  if (availableDateFrom && availableDateTo) {
    dates = {
      startDate: moment(availableDateFrom).toDate(),
      endDate: moment(availableDateTo).toDate(),
    };
  }

  return {
    id: values ? values.id : '',
    title: values ? values.title : '',
    description: values ? values.description : '',
    images: values?.images ? values.images : [],
    days: values?.days || {
      min: 1,
      max: 1,
    },
    guests: values?.guests || {
      min: 1,
      max: 1,
    },
    price: values ? values.price : minPrice,
    isPublished: values ? values.isPublished : true,
    hasLodging: values ? values.hasLodging : 'Not Offered',
    activity,
    activities: values && values.activities ? values.activities : [],
    activitiesInfo: values ? values.activitiesInfo : null,
    species: values ? values.species : [],
    subSpecies: values ? values.subSpecies : null,
    lodgingPrices:
      values && values.lodgingPrices
        ? values.lodgingPrices.map(lodgingPrice => lodgingPrice.option)
        : [],
    species_unit: values?.species_unit ?? null,
    methods: values?.methods || {
      allowsArchery: values?.allowsArchery || false,
      allowsFirearms: values?.allowsFirearms || false,
      showDatesField: values?.showDatesField || false,
      archeryInfo: values?.archeryInfo || null,
      firearmsInfo: values?.firearmsInfo || null,
    },
    hasKidsFriendlyExperience: values?.hasKidsFriendlyExperience || false,
    kidsFriendlyInfo: values?.hasKidsFriendlyExperience ? values?.kidsFriendlyInfo : null,
    interactiveExperience: values?.interactiveExperience || 'N/A',
    interactiveExperienceInfo: values?.interactiveExperienceInfo || null,
    catchAndRelease: values?.catchAndRelease || false,
    fishTypes: values?.fishTypes || [],
    dates,
    hasQuantityRestricted: values?.hasQuantityRestricted || false,
    quantity: values?.quantity || {
      booked: 0,
      total: 0,
    },
  };
};

export const getStartingPrice = packageItem => {
  const { price, guests, days, includedLodging = [] } = packageItem || {};

  const parsedPrice = price ? JSON.parse(price) : null;
  const priceAmount = parsedPrice ? parseInt(parsedPrice.amount, 10) : 0;

  const minGuests = guests?.min || 1;

  let startingPrice = '';

  if (includedLodging.length) {
    const lodgingPriceAmount = includedLodging.reduce((acc, curr) => {
      if (curr.price) {
        const lodgingPrice = JSON.parse(curr.price, sdkTypes.reviver);

        return acc + lodgingPrice.amount;
      }

      return acc;
    }, 0);
    const lodgingFeeMinTotal = lodgingPriceAmount * (days?.min - 1);
    const priceWithLodging = priceAmount * days?.min + lodgingFeeMinTotal / minGuests;
    startingPrice = new Money(priceWithLodging, config.currency);
  } else {
    startingPrice = new Money(priceAmount * days?.min, config.currency);
  }

  return startingPrice;
};

export const getTransactionFromInquiry = (user, packageId) => {
  const pendingInquiries = get(user, 'attributes.profile.metadata.pendingInquiries.items', null);

  if (pendingInquiries && pendingInquiries[packageId]) {
    const inquiry = pendingInquiries[packageId];

    const now = moment();
    const startDateObj = moment(inquiry.startDate);

    if (moment(now).isBefore(startDateObj)) {
      return pendingInquiries[packageId]?.transactionId;
    }
  }

  return null;
};

export const checkPackageAvailabilty = packageItem => {
  const { availableDateFrom, availableDateTo, quantity, hasQuantityRestricted } = packageItem || {};

  // Convert date strings to Moment objects
  const from = availableDateFrom ? moment(availableDateFrom) : null;
  const to = availableDateTo ? moment(availableDateTo) : null;

  // Get current date and time
  const now = moment();

  // Initialize booking range variables
  let bookingRangeIsFuture = false;
  let bookingRangeIsPast = false;
  let bookingRangePresent = false;

  // Check if booking range is currently active
  if (from && to && now.isBetween(from, to, 'day', '[]')) {
    bookingRangePresent = true;
  }

  // Check if booking range is in the future
  else if (to && now.isBefore(to, 'day')) {
    bookingRangeIsFuture = true;
  }

  // Check if booking range is in the past
  else if (from && now.isAfter(from, 'day')) {
    bookingRangeIsPast = true;
  }

  const quantityBooked = quantity?.booked ? quantity.booked : 0;
  const quantityTotal = quantity?.total ? quantity.total : 0;
  const quantityAvailable = quantityTotal - quantityBooked;

  return {
    bookingRangeIsFuture,
    bookingRangeIsPast,
    bookingRangePresent,
    isAvailable: hasQuantityRestricted ? quantityAvailable > 0 : true,
  };
};

export const isPackageOnWaitingList = (user, packageId) => {
  const waitingList = get(user, 'attributes.profile.publicData.waitingList', []);

  return waitingList.some(item => item.packageId === packageId);
};

export const getRVDetails = (listing, packageInfo) => {
  const campsites = get(listing, 'attributes.publicData.campsites', []);
  const lodgingPrices = get(packageInfo, 'lodgingPrices', []);
  const isRvFriendly = campsites.some(campsite => campsite.allowsRvs);

  const requiredRV = lodgingPrices.filter(
    lodgingPrice => lodgingPrice.type === 'rv' && lodgingPrice.option === LODGING_INFO.REQUIRED
  );

  // Check if package has rv sites
  const hasRVSites = campsites.some((campsite, index) => {
    const hasRequiredRv = requiredRV.some(rv => {
      if (index === rv.index && campsite.rv.hasCampsite) {
        return true;
      }

      return false;
    });

    return hasRequiredRv;
  });

  return {
    isRvFriendly,
    hasRVSites,
  };
};

const findLodgingPriceOption = (lodgingPricesOptions, item) =>
  lodgingPricesOptions.find(
    lodgingPrice => lodgingPrice.type === item.type && lodgingPrice.index === item.index
  );

export const getPackageLodgingPrices = (packageInfo, lodgingPricesOptions) => {
  const baseLodgingPrices = get(packageInfo, 'lodgingPrices', []);

  const { optionalLodging, includedLodging } = baseLodgingPrices.reduce(
    (acc, item) => {
      const lodgingPriceOption = findLodgingPriceOption(lodgingPricesOptions, item);

      if (!lodgingPriceOption) return acc;

      const newItem = { ...item, ...lodgingPriceOption };
      if (newItem.option === LODGING_INFO.OPTIONAL) acc.optionalLodging.push(newItem);
      if (newItem.option === LODGING_INFO.REQUIRED) acc.includedLodging.push(newItem);

      return acc;
    },
    { optionalLodging: [], includedLodging: [] }
  );

  const includedLodgingPrice = includedLodging.reduce((acc, item) => {
    if (item.price) {
      return acc + JSON.parse(item.price, sdkTypes.reviver).amount;
    }
    return acc;
  }, 0);

  return {
    optionalLodging,
    includedLodging,
    includedLodgingPrice,
  };
};

export const hasLodgingForPackage = packageInfo => {
  const { lodgingPrices = [] } = packageInfo || {};

  return lodgingPrices.some(
    lodgingPrice =>
      [LODGING_TYPE.HOUSE, LODGING_TYPE.CABIN].includes(lodgingPrice.type) &&
      [LODGING_INFO.REQUIRED].includes(lodgingPrice.option)
  );
};

export const getEarlyAccessExpirationDate = (timestamp, earlyAccessDays, availableDateFrom) => {
  let baseTimestamp = timestamp;

  // If availableDateFrom is provided and is later than timestamp, use it as the base
  if (availableDateFrom) {
    const availableDateFromTimestamp = moment
      .utc(availableDateFrom, 'YYYY-MM-DD')
      .startOf('day')
      .unix();

    if (availableDateFromTimestamp > timestamp) {
      baseTimestamp = availableDateFromTimestamp;
    }
  }

  // Calculate the expiration date based on the base timestamp
  const expirationDate = moment.unix(baseTimestamp).add(earlyAccessDays, 'days');

  return expirationDate.unix();
};

export const isEarlyAccessAvailable = timestamp => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return config.packagesEarlyAccessDays > 0 && timestamp > 0 && timestamp > currentTimestamp;
};

export const syncEarlyAccessExpiration = (packages = []) => {
  return packages.map(item => {
    const { availableDateFrom } = item;

    const timestamp = moment.utc().unix();
    const expiration = getEarlyAccessExpirationDate(
      timestamp,
      config.packagesEarlyAccessDays,
      availableDateFrom
    );

    return {
      ...item,
      earlyAccessExpiration: expiration,
    };
  });
};
