/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { get } from 'lodash';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { parse, format } from 'date-fns';
import { Elements } from '@stripe/react-stripe-js';
import {
  TRANSITION_ENQUIRE_GATED,
  TRANSITION_ENQUIRE_EXPIRE,
  getTxState,
} from '../../util/transaction';
import config from '../../config';
import { ReactComponent as IconOpenLink } from '../../assets/icons/icon-open-link.svg';
import { ReactComponent as IconCancel } from '../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg';
import { propTypes } from '../../util/types';
import { isMobileSafari } from '../../util/userAgent';
import {
  BookingPanel,
  NamedLink,
  UserDisplayName,
  SpecialOfferPanel,
  PageSlider,
  SectionPackages,
  Breadcrumbs,
  IDVerification,
  PackagePricingPanel,
  PrimaryButton,
  StripeCheckoutForm,
  TrustCard,
  UserCard,
  PackageQuantityBadge,
  PackageAvailabilityModal,
  PackageAvailabilityNotifyButton,
  TripInsuranceDetails,
  LinkWithIcon,
  BookingChanges,
  AvatarMedium,
} from '..';
import { PACKAGE_QUANTITY_BADGE_VARIANTS } from '../PackageQuantityBadge/PackageQuantityBadge';
import { SendMessageForm } from '../../forms';
// These are internal components that make this file more readable.
import FeedSection from './FeedSection';
import BookingDetails from './BookingDetails';
import BookingStatusSection from './BookingStatusSection';
import PartyMembersSection from './PartyMembersSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PricingDetails from './PricingDetails';

import css from './TransactionPanel.css';
import TransactionListingHeading, {
  createListingLink,
} from '../TransactionListingHeading/TransactionListingHeading';
import CancelModal from '../CancelModal/CancelModal';

import {
  ensureListing,
  ensureUser,
  ensureTransaction,
  userDisplayNameAsString,
} from '../../util/data';
import { isVerified } from '../../util/user';
import { formatMoney } from '../../util/currency';
import { getPackage, getLodgingPricesOptions } from '../../util/listing';
import {
  LODGING_INFO,
  getStartingPrice,
  checkPackageAvailabilty,
  isPackageOnWaitingList,
  getPackageLodgingPrices,
} from '../../util/package';
import { createSlug } from '../../util/urlHelpers';
import { createPackageCheckoutLink } from './TransactionPanel.helpers';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';

  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const stripePromise = loadStripe(config.stripe.publishableKey);

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReviewModalOpen: false,
      isCancelModalOpen: false,
      isofferPaymentOpen: false,
      submitting: false,
      idIsVerified: false,
      showMissingInformationReminder: false,
      sendMessageFormFocused: false,
      isPackageAvailabilityModalOpen: false,
      showBookingChanges: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.handlePaymentIntention = this.handlePaymentIntention.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.isMobSaf = isMobileSafari();
  }

  // currently unused until saving card payments
  handlePaymentIntention(confirmParams) {
    const { onHandleCardPayment, onConfirmSpecialOfferPayment } = this.props;

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      onHandleCardPayment(confirmParams),
      onConfirmSpecialOfferPayment(confirmParams)
    );

    return handlePaymentIntentCreation(confirmParams);
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(() => {
        form.reset();
      })
      .catch(() => {
        // Ignore, Redux handles the error
      });
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      currentUserInit,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onAcceptSpecialOffer,
      onCancelOffer,
      onCancelSaleCustomerWithRefund,
      onCancelSaleCustomerWithoutRefund,
      onCancelSaleCustomerLate,
      onCancelSaleProviderEarly,
      onCancelSaleProvider,
      onCancelSaleProviderLate,
      onDeclineSale,
      onDeclineSpecialOffer,
      acceptInProgress,
      cancelInProgress,
      declineInProgress,
      acceptSaleError,
      cancelSaleError,
      declineSaleError,
      onSubmitSpecialOffer,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      initiateOrderError,
      onGoToReview,
      onUpdatePartyMemberDetails,
      fetchUser,
      onFetchTransactionsTogether,
      currentUsersHaveOrdersTogether,
      onUpdateWaitingList,
      updatingWaitingListInProgress,
      onRequestChanges,
      requestedChangesInProgress,
      onAcceptChanges,
      acceptChangesInProgress,
      onDeclineChanges,
      declineChangesInProgress,
      followSubjectMutation,
    } = this.props;
    const {
      isCancelModalOpen,
      submitting,
      idIsVerified,
      showMissingInformationReminder,
      isPackageAvailabilityModalOpen,
      showBookingChanges,
    } = this.state;

    const { stripeConnected } = currentUser.attributes;
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const currentTransition = get(transaction, 'attributes.lastTransition', null);
    const packageId =
      get(transaction, 'attributes.protectedData.selectedPackage.packageId', null) ||
      get(transaction, 'attributes.protectedData.packageLineItem.id', null);

    const { tripInsurance } = transaction?.attributes?.metadata || {};
    const insuranceOffered = tripInsurance !== undefined;
    const userIsGuest = currentCustomer?.id?.uuid === currentUser?.id?.uuid;

    const inquiryGated = currentTransition === TRANSITION_ENQUIRE_GATED;
    const inquiryExpired = currentTransition === TRANSITION_ENQUIRE_EXPIRE;
    const isInquiry = inquiryGated || inquiryExpired;
    const clientSecret = get(
      transaction,
      'attributes.protectedData.stripePaymentIntents.default.stripePaymentIntentClientSecret',
      null
    );

    const stripeOptions = {
      clientSecret,
      appearance: config.stripe.appearance,
    };

    const stateData = getTxState(
      intl,
      currentTransaction,
      transactionRole,
      currentCustomer,
      currentProvider,
      nextTransitions
    );

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });
    const { authorDisplayName, otherUserDisplayNameString } = displayNames(
      currentUser,
      currentProvider,
      currentCustomer,
      intl
    );
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const bookingSubTitle = '';

    const handleSpecialOffer = async transactionId => {
      await fetchUser();

      const verifiedUser = isVerified(currentUser);

      if (!verifiedUser) {
        this.setState({
          idIsVerified: false,
          showMissingInformationReminder: true,
          submitting: true,
        });

        return;
      }

      this.setState({
        idIsVerified: true,
        showMissingInformationReminder: false,
      });

      const params = {
        currentUser,
        currentListing,
        currentProvider,
      };

      onAcceptSpecialOffer(params, transactionId);
    };

    const specialOfferCustomerButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showCustomerAcceptSpecialOffer}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => handleSpecialOffer(currentTransaction.id)}
        onDeclineSale={() => onDeclineSpecialOffer(currentTransaction.id)}
        isSpecialOffer
        currentTransaction={currentTransaction}
        intl={intl}
      />
    );
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
        currentTransaction={currentTransaction}
        intl={intl}
      />
    );
    const cancelButton = (
      <>
        <LinkWithIcon
          icon={IconCancel}
          onClick={() => this.setState({ isCancelModalOpen: true })}
          label={intl.formatMessage({
            id: stateData.showCancelOffer
              ? 'TransactionPanel.cancelOfferButton'
              : 'TransactionPanel.cancelTripButton',
          })}
        />
      </>
    );

    const handleOpenChangeBookingDetails = () => {
      this.setState(
        {
          showBookingChanges: true,
        },
        () => {
          document.body.style.overflow = 'hidden';
        }
      );
    };

    const handleCloseChangeBookingDetails = () => {
      this.setState(
        {
          showBookingChanges: false,
        },
        () => {
          const scrollY = document.body.style.top;
          document.body.style.overflow = '';
          window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
      );
    };

    const handleChangeBookingDetails = () => {
      handleOpenChangeBookingDetails();
    };

    const handleRequestChanges = async (params, id) => {
      await onRequestChanges(params, id);

      window.scrollTop = 0;
    };

    const viewRequestButton = (
      <PrimaryButton className={css.bookButton} onClick={handleChangeBookingDetails}>
        <FormattedMessage id="TransactionPanel.viewRequestButton" />
      </PrimaryButton>
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const handleSubmit = async () => {
      const { speculatedTransaction, onConfirmSpecialOfferPayment } = this.props;

      this.setState({ submitting: true });

      try {
        const confirmParams = {
          speculatedTransaction,
          id: transaction.id,
        };

        await onConfirmSpecialOfferPayment(confirmParams);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.setState({ submitting: false });
      }
    };

    const offerStripePayment = stateData.showCustomerPaymentDetails && clientSecret && (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeCheckoutForm
          submitClassName={css.submitClassName}
          followSubjectMutation={followSubjectMutation}
          user={currentUser}
          listing={currentListing}
          onSubmit={handleSubmit}
          isSubmitting={submitting}
          setIsSubmitting={isSubmitting => {
            this.setState({
              submitting: isSubmitting,
            });
          }}
          buttonLabel={intl.formatMessage({ id: 'TransactionPanel.acceptSpecialOfferButton' })}
          currentUser={currentUser}
          fetchUser={fetchUser}
        />
      </Elements>
    );

    const currentTransactionTransition = (
      <BookingStatusSection
        rootClassName={inquiryGated || inquiryExpired ? css.inquiryGated : css.bookingStatus}
        currentTransaction={currentTransaction}
        currentUser={currentUser}
        onGoToReview={onGoToReview}
        stateData={stateData}
        cancelButton={cancelButton}
        viewRequestButton={viewRequestButton}
        specialOfferCustomerButtons={specialOfferCustomerButtons}
        saleButtons={saleButtons}
        inquiryGated={inquiryGated}
        inquiryExpired={inquiryExpired}
        authorDisplayName={authorDisplayName}
      />
    );

    const partyMembers =
      get(currentTransaction, 'attributes.protectedData.packageLineItem.guestSize', 1) - 1;
    const hasPartyMembers = partyMembers > 0;
    const formComplete = get(
      currentTransaction,
      'attributes.protectedData.partyMemberDetails',
      null
    );
    const partyMemberFormMaybe =
      hasPartyMembers && isCustomer && stateData.showPartyMemberSection ? (
        <PartyMembersSection
          rootClassName={!formComplete ? css.partyMembers : css.partyMembersComplete}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          partyMembers={partyMembers}
          hasPartyMembers={hasPartyMembers}
          onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
          formComplete={formComplete}
          intl={intl}
        />
      ) : null;

    const sectionPackagesTitle = <FormattedMessage id="SectionPackages.sectionTitle" />;

    const selectedActivity = get(
      transaction,
      'attributes.protectedData.selectedPackage.packageActivity',
      null
    );
    const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);
    const currentPackage = getPackage(currentListing, packageId);
    const { optionalLodging, includedLodging } = getPackageLodgingPrices(
      currentPackage,
      lodgingPricesOptions
    );
    const { bookingRangeIsFuture, bookingRangeIsPast, isAvailable } = checkPackageAvailabilty(
      currentPackage
    );

    const { availableDateFrom } = currentPackage || {};

    const date = parse(availableDateFrom);
    const formattedPackageAvailableDate = format(date, 'MMMM D, YYYY');

    const outOfBookingRange = bookingRangeIsFuture || bookingRangeIsPast;

    const { days, guests, hasLodging: packageHasLodging } = currentPackage || {};
    const packageTitle = currentPackage && currentPackage.title;

    const title = packageTitle || get(currentListing, 'attributes.title', '');

    const listingLink = createListingLink(
      currentListing,
      title,
      currentPackage,
      {},
      css.listingTitleLink
    );

    const potentialEarnings = stateData.showPotentialEarnings
      ? formatMoney(intl, stateData.potentialEarningsMoney)
      : null;

    const packageCheckoutLink = currentPackage
      ? createPackageCheckoutLink(
          currentListing,
          <PrimaryButton className={css.bookButton}>
            <FormattedMessage id="PackagePage.checkAvailability" />
          </PrimaryButton>,
          currentPackage.id,
          currentTransaction
        )
      : null;

    const startingPrice = getStartingPrice({
      ...currentPackage,
      optionalLodging,
      includedLodging,
    });

    const TransactionPanelBreadcrumbs = () => {
      const breadcrumbs = [
        <NamedLink className={css.breadcrumbLink} name="LandingPage">
          <FormattedMessage id="TransactionPanel.BreadcrumbsHome" />
        </NamedLink>,
        <NamedLink
          className={css.breadcrumbLink}
          name="InboxPage"
          params={{
            tab: 'orders',
          }}
        >
          <FormattedMessage id="TransactionPanel.BreadcrumbsInbox" />
        </NamedLink>,
      ];

      if (currentPackage) {
        breadcrumbs.push(currentPackage.title);
      } else {
        breadcrumbs.push(listingTitle);
      }

      return (
        <div className={css.statusTitle}>
          <Breadcrumbs breadcrumbs={breadcrumbs} key={breadcrumbs} />
        </div>
      );
    };

    const currentUserisAuthor = currentListing?.author?.id?.uuid === currentUser?.id?.uuid;

    const customerName = get(currentCustomer, 'attributes.profile.displayName');
    const isStartingPrice =
      days?.min !== days?.max ||
      guests?.min !== guests?.max ||
      packageHasLodging === LODGING_INFO.OPTIONAL;

    const isOnWaitingList = isPackageOnWaitingList(currentUser, currentPackage?.id);
    const landownerName = get(currentListing, 'author.attributes.profile.displayName', 'Landowner');

    const renderAvailabilityBadges = isShortVersion => {
      let badge;

      if (!isAvailable) {
        badge = {
          variant: PACKAGE_QUANTITY_BADGE_VARIANTS.DANGER,
          text: isShortVersion ? (
            <FormattedMessage
              id="PackagePricingPanel.soldOutSimple"
              values={{ date: formattedPackageAvailableDate }}
            />
          ) : (
            <FormattedMessage
              id="PackagePage.soldOutInquiry"
              values={{ landowner: landownerName }}
            />
          ),
        };
      } else if (outOfBookingRange) {
        badge = {
          variant: PACKAGE_QUANTITY_BADGE_VARIANTS.WARNING,
          text: isShortVersion ? (
            <FormattedMessage
              id="PackagePricingPanel.outOfRange"
              values={{ date: formattedPackageAvailableDate }}
            />
          ) : (
            <FormattedMessage
              id="PackagePage.outOfBookingRange"
              values={{ date: formattedPackageAvailableDate, landowner: landownerName }}
            />
          ),
        };
      }

      if (!badge) {
        return null;
      }

      return (
        <div className={css.badgeWrapper}>
          <PackageQuantityBadge variant={badge.variant} size="large" text={badge.text} />
        </div>
      );
    };

    const showCancelButton =
      stateData.showCancelButtonCustomerWithRefund ||
      stateData.showCancelOffer ||
      stateData.showCancelButtonCustomerWithoutRefund ||
      stateData.showCancelButtonCustomerLateCancel ||
      stateData.showCancelButtonProviderEarlyCancel ||
      stateData.showCancelButtonProviderCancel ||
      stateData.showCancelButtonProviderLateCancel;

    const renderSidebarLinks = () => {
      if (
        !stateData.showViewPackage &&
        !stateData.showBookingChangesLinkCustomer &&
        !showCancelButton
      ) {
        return null;
      }

      return (
        <div className={css.linkIconsWrapper}>
          {stateData.showViewPackage && (
            <LinkWithIcon
              icon={IconOpenLink}
              name="PackagePage"
              params={{
                id: currentListing.id.uuid,
                slug: createSlug(title),
                packageId,
              }}
              label={intl.formatMessage({ id: 'TransactionPanel.viewPackage' })}
            />
          )}

          {stateData.showBookingChangesLinkCustomer && (
            <LinkWithIcon
              icon={IconEdit}
              onClick={handleChangeBookingDetails}
              label={intl.formatMessage({ id: 'TransactionPanel.changeBookingDetails' })}
            />
          )}

          {showCancelButton && <>{cancelButton}</>}
        </div>
      );
    };

    const sidebarLinks = renderSidebarLinks();

    return (
      <div className={classes}>
        <PackageAvailabilityModal
          id="package-pricing-availability-modal"
          isOpen={isPackageAvailabilityModalOpen}
          onClose={() => {
            this.setState({ isPackageAvailabilityModalOpen: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
          isSoldOut={!isAvailable}
          isOutOfBookingRange={outOfBookingRange}
          packageAvailableDate={formattedPackageAvailableDate}
          landownerName={landownerName}
          onNotify={() => {
            onUpdateWaitingList(currentPackage.id, currentListing.id.uuid);
          }}
          isOnWaitingList={isOnWaitingList}
          isFetching={updatingWaitingListInProgress}
          isEnquiry
        />
        <div className={css.container}>
          <div className={css.txInfo}>
            <div className={css.desktopBreadcrumbs}>
              <TransactionPanelBreadcrumbs />
            </div>
            <div className={css.transactionListingHeadingMobile}>
              <div className={css.mobileBreadcrumbs}>
                <TransactionPanelBreadcrumbs />
              </div>
              <TransactionListingHeading
                listing={currentListing}
                currentUser={currentUser}
                provider={currentTransaction?.provider}
                customer={currentTransaction?.customer}
                listingLink={listingLink}
                packageId={packageId}
                currentTransaction={currentTransaction}
              />
            </div>
            {isInquiry && (
              <div className={css.isInquiryInfo}>
                <div className={css.listingTitle}>{listingLink}</div>
                {!currentUserisAuthor ? (
                  <UserCard
                    landowner={currentListing.author}
                    currentUser={currentUser}
                    fullProfile={false}
                    currentUserisAuthor={currentUserisAuthor}
                    noBio
                  />
                ) : (
                  <div className={css.profileFlexContainer}>
                    <AvatarMedium user={currentCustomer} className={css.avatar} />
                    <div className={css.profileInfo}>
                      <div className={css.guestNameStyles}>
                        <FormattedMessage id="TransactionPanel.yourGuest" />
                      </div>
                      <div className={css.profileName}>{customerName}.</div>

                      <div className={css.profileLink}>
                        {currentCustomer.id ? (
                          <NamedLink
                            className={css.profileLink}
                            name="ProfilePage"
                            params={{ id: currentCustomer.id.uuid }}
                          >
                            <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                          </NamedLink>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {currentTransactionTransition}
            {partyMemberFormMaybe}
            {!isInquiry && <div className={css.listingTitle}>{listingLink}</div>}

            {offerStripePayment}

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            {stateData.showBookingDetails && (
              <div className={css.bookingDetailsMobile}>
                <BookingDetails
                  transaction={currentTransaction}
                  stateData={stateData}
                  currentPackage={currentPackage}
                />
              </div>
            )}

            {sidebarLinks && (
              <div className={css.bookingDetailsMobile}>
                <div className={css.divider} />

                {renderSidebarLinks()}
              </div>
            )}

            {stateData.showBookingDetails && (
              <div className={css.bookingDetailsMobile}>
                <div className={css.divider} />
                <TrustCard
                  landowner={currentProvider}
                  sportsman={currentCustomer}
                  currentUser={currentUser}
                  currentListing={currentListing}
                  currentTransaction={currentTransaction}
                  onFetchTransactionsTogether={onFetchTransactionsTogether}
                  currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
                  insuranceOffered={insuranceOffered}
                />
                <div className={css.divider} />
              </div>
            )}
            {stateData.showBookingDetails && insuranceOffered && userIsGuest && (
              <div className={css.bookingDetailsMobile}>
                <TripInsuranceDetails
                  guest={currentCustomer}
                  currentUser={currentUser}
                  transaction={currentTransaction}
                />
                <div className={css.divider} />
              </div>
            )}

            {stateData.showBookingDetails && !isInquiry && (
              <div className={css.pricingDetailsMobile}>
                <PricingDetails transaction={currentTransaction} userRole={transactionRole} />
              </div>
            )}

            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />

            {showSendMessageForm ? (
              <SendMessageForm
                currentUser={currentUser}
                form={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {potentialEarnings && (
              <div className={css.potentialEarnings}>Potential Earnings: {potentialEarnings}</div>
            )}

            <div className={css.packagesContainer}>
              {stateData.showBookingPanel && !currentPackage && (
                <SectionPackages
                  listing={currentListing}
                  sectionPackagesTitle={sectionPackagesTitle}
                  showDefaultPackages={Infinity}
                  currentActivity={selectedActivity}
                />
              )}
            </div>
          </div>

          <div className={css.asideDesktopContainer}>
            <div className={css.asideDesktop}>
              <div className={css.detailCard}>
                <div className={css.transactionListingHeadingDesktop}>
                  <TransactionListingHeading
                    listing={currentListing}
                    currentUser={currentUser}
                    provider={currentTransaction?.provider}
                    customer={currentTransaction?.customer}
                    listingLink={listingLink}
                    packageId={packageId}
                    currentTransaction={currentTransaction}
                  />
                </div>

                {stateData.showBookingDetails && (
                  <div className={css.bookingDetailsDesktop}>
                    <BookingDetails
                      transaction={currentTransaction}
                      stateData={stateData}
                      currentPackage={currentPackage}
                    />
                  </div>
                )}

                {sidebarLinks && (
                  <div className={css.bookingDetailsDesktop}>
                    <div className={css.divider} />

                    {sidebarLinks}
                  </div>
                )}

                {stateData.showBookingDetails && (
                  <div className={css.bookingDetailsDesktop}>
                    <div className={css.divider} />
                    <TrustCard
                      landowner={currentProvider}
                      sportsman={currentCustomer}
                      currentUser={currentUser}
                      currentTransaction={currentTransaction}
                      onFetchTransactionsTogether={onFetchTransactionsTogether}
                      currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
                      insuranceOffered={insuranceOffered}
                    />
                    <div className={css.divider} />
                  </div>
                )}
                {stateData.showBookingDetails && insuranceOffered && userIsGuest && (
                  <div className={css.bookingDetailsDesktop}>
                    <TripInsuranceDetails
                      guest={currentCustomer}
                      currentUser={currentUser}
                      transaction={currentTransaction}
                    />
                    <div className={css.divider} />
                  </div>
                )}

                {stateData.showBookingDetails && !isInquiry && (
                  <div className={css.pricingDetailsDesktop}>
                    <PricingDetails transaction={currentTransaction} userRole={transactionRole} />
                  </div>
                )}
              </div>

              {stateData.showBookingPanel && currentPackage && (
                <>
                  <div className={css.pricingPackagePanel}>
                    <PackagePricingPanel
                      currentUserInit={currentUserInit}
                      rootClassName={css.pricingPanel}
                      packageInfo={currentPackage}
                      listing={currentListing}
                      timeSlots={timeSlots}
                      startingPrice={startingPrice}
                      currentUser={currentUser}
                      transaction={currentTransaction}
                      availabilityPackageBadges={renderAvailabilityBadges(true)}
                      isAvailable={isAvailable}
                      outOfBookingRange={outOfBookingRange}
                      bookingRangeIsFuture={bookingRangeIsFuture}
                      formattedPackageAvailableDate={formattedPackageAvailableDate}
                      onManageDisableScrolling={onManageDisableScrolling}
                      isOnWaitingList={isOnWaitingList}
                      setIsPackageAvailabilityModalOpen={isOpen => {
                        this.setState({ isPackageAvailabilityModalOpen: isOpen });
                      }}
                    />
                  </div>

                  {isAvailable && !outOfBookingRange ? (
                    <div className={css.mobileCheckAvailability}>
                      <div className={css.mobileCheckAvailabilityPrice}>
                        <FormattedMessage
                          id={isStartingPrice ? 'PackagePage.startingPrice' : 'PackagePage.price'}
                        />
                        {startingPrice?.amount && (
                          <FormattedHTMLMessage
                            id="PackageCard.perGuest"
                            values={{ price: formatMoney(intl, startingPrice) }}
                          />
                        )}{' '}
                      </div>

                      {packageCheckoutLink}
                    </div>
                  ) : (
                    <div className={css.notifyMobileContainer}>
                      {isOnWaitingList ? (
                        <div className={css.getNotified}>
                          <b>
                            <FormattedMessage id="PackagePage.waitingListNotified" />
                          </b>
                        </div>
                      ) : (
                        <>
                          <PackageAvailabilityNotifyButton
                            onClick={() => {
                              this.setState({ isPackageAvailabilityModalOpen: true });
                            }}
                          />

                          <div className={css.getNotified}>
                            <FormattedMessage id="PackagePricingPanel.soldOutgetNotified" />
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  <div className={css.bookingDetailsDesktop}>
                    <TrustCard
                      landowner={currentProvider}
                      sportsman={currentCustomer}
                      currentUser={currentUser}
                      currentTransaction={currentTransaction}
                      onFetchTransactionsTogether={onFetchTransactionsTogether}
                      currentUsersHaveOrdersTogether={currentUsersHaveOrdersTogether}
                      insuranceOffered={insuranceOffered}
                    />
                  </div>
                  {insuranceOffered && userIsGuest && (
                    <div className={css.bookingDetailsDesktop}>
                      <TripInsuranceDetails
                        guest={currentCustomer}
                        currentUser={currentUser}
                        transaction={currentTransaction}
                      />
                    </div>
                  )}
                </>
              )}
              {stateData.showBookingPanel && !currentPackage ? (
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={false}
                  currentActivity={selectedActivity}
                />
              ) : null}

              {stateData.showSpecialOffer ? (
                <SpecialOfferPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  timeSlots={timeSlots}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onSubmit={onSubmitSpecialOffer}
                  sportsmanName={otherUserDisplayNameString.split(' ')[0]}
                  formForMessage={this.sendMessageFormName}
                  listing={currentListing}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  subTitle={bookingSubTitle}
                  title={listingTitle}
                  transaction={currentTransaction}
                  messageootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  messageinProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  messageonFocus={this.onSendMessageFormFocus}
                  messageonBlur={this.onSendMessageFormBlur}
                  messageonSubmit={this.onMessageSubmit}
                  initiateOrderError={initiateOrderError}
                  stripeConnected={stripeConnected}
                />
              ) : null}
            </div>
          </div>
        </div>
        {!idIsVerified && (
          <PageSlider
            active={showMissingInformationReminder}
            onClose={() => {
              this.setState({
                showMissingInformationReminder: false,
                submitting: false,
              });
            }}
            width="50%"
          >
            <IDVerification
              className={css.innerIDModal}
              onClose={() => {
                this.setState({
                  showMissingInformationReminder: false,
                  submitting: false,
                });
                fetchUser();
              }}
              showMissingInformationReminder={showMissingInformationReminder}
              isSpecialOffer
            />
          </PageSlider>
        )}
        {showBookingChanges && (
          <PageSlider
            active={showBookingChanges}
            onClose={() => {
              handleCloseChangeBookingDetails();
            }}
            width="100%"
          >
            <BookingChanges
              currentListing={currentListing}
              currentUser={currentUser}
              provider={currentTransaction?.provider}
              customer={currentTransaction?.customer}
              listingLink={listingLink}
              packageId={packageId}
              currentTransaction={currentTransaction}
              timeSlots={timeSlots}
              onSubmit={handleRequestChanges}
              inProgress={requestedChangesInProgress}
              onAccept={onAcceptChanges}
              acceptChangesInProgress={acceptChangesInProgress}
              onDecline={onDeclineChanges}
              declineChangesInProgress={declineChangesInProgress}
              stateData={stateData}
              isProvider={isProvider}
              isCustomer={isCustomer}
              onClose={() => {
                handleCloseChangeBookingDetails();
              }}
            />
          </PageSlider>
        )}

        <CancelModal
          showCancelOffer={stateData.showCancelOffer}
          showCancelButtonCustomerWithRefund={stateData.showCancelButtonCustomerWithRefund}
          showCancelButtonCustomerWithoutRefund={stateData.showCancelButtonCustomerWithoutRefund}
          showCancelButtonProviderEarlyCancel={stateData.showCancelButtonProviderEarlyCancel}
          showCancelButtonProviderCancel={stateData.showCancelButtonProviderCancel}
          showCancelButtonCustomerLateCancel={stateData.showCancelButtonCustomerLateCancel}
          showCancelButtonProviderLateCancel={stateData.showCancelButtonProviderLateCancel}
          cancelInProgress={cancelInProgress}
          cancelSaleError={cancelSaleError}
          onCancelOffer={() => onCancelOffer(currentTransaction.id)}
          onCancelSaleCustomerWithRefund={() =>
            onCancelSaleCustomerWithRefund(currentTransaction.id)
          }
          onCancelSaleCustomerWithoutRefund={() =>
            onCancelSaleCustomerWithoutRefund(currentTransaction.id)
          }
          onCancelSaleCustomerLate={() => onCancelSaleCustomerLate(currentTransaction.id)}
          onCancelSaleProviderEarly={() => onCancelSaleProviderEarly(currentTransaction.id)}
          onCancelSaleProvider={() => onCancelSaleProvider(currentTransaction.id)}
          onCancelSaleProviderLate={() => onCancelSaleProviderLate(currentTransaction.id)}
          id="CancelModal"
          isOpen={isCancelModalOpen}
          onCloseModal={() => this.setState({ isCancelModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  currentUserInit: bool.isRequired,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSubmitSpecialOffer: func.isRequired,
  onUpdatePartyMemberDetails: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props

  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  onDeclineSpecialOffer: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  onRequestChanges: func.isRequired,
  requestedChangesInProgress: bool.isRequired,
  onAcceptChanges: func.isRequired,
  acceptChangesInProgress: bool.isRequired,
  onDeclineChanges: func.isRequired,
  declineChangesInProgress: bool.isRequired,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
