/* eslint-disable no-console */
import React, { useState } from 'react';
import format from 'date-fns/format';
import { string, object, arrayOf, func, bool } from 'prop-types';
import { compose } from 'redux';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { injectIntl, FormattedMessage } from 'react-intl';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import { daysBetween } from '../../util/dates';
import { ensureUser } from '../../util/data';
import {
  getBookingDates,
  calculateBookingChangesPrices,
  formatDatesForTransaction,
  HEADING_CUSTOMER_CHANGES_REQUESTED,
} from '../../util/transaction';
import { formatMoney } from '../../util/currency';
import { getPackage, getListingTimezone } from '../../util/listing';
import { propTypes } from '../../util/types';
import { TransactionListingHeading } from '..';
import Header from './Header';
import BookingDetails from './BookingDetails';

import css from './BookingChanges.css';
import { getFirstName } from '../../util/user';

const { Money } = sdkTypes;

const PricingDetails = ({
  isCustomer,
  formattedCustomerOriginalPrice,
  formattedNewCustomerPrice,
  formattedPriceDifferenceForCustomer,
  isProvider,
  formattedNewProviderPrice,
  formattedProviderOriginalPrice,
  formattedPriceDifferenceForProvider,
}) => {
  return (
    <div className={css.pricingDetails}>
      <div className={css.pricingDetailsHeader}>
        {isCustomer && <FormattedMessage id="BookingChanges.pricingDetails" />}
        {isProvider && <FormattedMessage id="BookingChanges.landownerPayout" />}
      </div>

      <div className={css.pricingDetailsItem}>
        {isCustomer && (
          <>
            <FormattedMessage id="BookingChanges.originalPrice" />
            <span>{formattedCustomerOriginalPrice}</span>
          </>
        )}

        {isProvider && (
          <>
            <FormattedMessage id="BookingChanges.originalPayout" />
            <span>{formattedProviderOriginalPrice}</span>
          </>
        )}
      </div>

      <div className={css.pricingDetailsItem}>
        {isCustomer && (
          <>
            <FormattedMessage id="BookingChanges.newPrice" />
            <span>{formattedNewCustomerPrice}</span>
          </>
        )}

        {isProvider && (
          <>
            <FormattedMessage id="BookingChanges.newPayout" />
            <span>{formattedNewProviderPrice}</span>
          </>
        )}
      </div>

      <div className={css.pricingDetailsItem}>
        <b>
          {isCustomer && <FormattedMessage id="BookingChanges.priceDifferenceCustomer" />}

          {isProvider && <FormattedMessage id="BookingChanges.priceDifferenceProvider" />}
        </b>
        {isCustomer && <b>{formattedPriceDifferenceForCustomer}</b>}
        {isProvider && <b>{formattedPriceDifferenceForProvider}</b>}
      </div>
    </div>
  );
};

const BookingChanges = ({
  currentListing,
  currentUser,
  currentTransaction,
  listingLink,
  packageId,
  timeSlots,
  onSubmit,
  inProgress,
  stateData,
  isProvider,
  isCustomer,
  onClose,
  onAccept,
  acceptChangesInProgress,
  onDecline,
  declineChangesInProgress,
  intl,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { customer } = currentTransaction;
  const { provider } = currentTransaction;

  const providedDisplayName = getFirstName(get(provider, 'attributes.profile.displayName', null));
  const customerDisplayName = getFirstName(get(customer, 'attributes.profile.displayName', null));

  const hasCustomerRequestedChanges = stateData.headingState === HEADING_CUSTOMER_CHANGES_REQUESTED;

  const currentProvider = ensureUser(currentTransaction.provider);
  const currentPackage = getPackage(currentListing, packageId);

  const originalCustomerPrice = currentTransaction.attributes.payinTotal;
  const formattedCustomerOriginalPrice = formatMoney(intl, originalCustomerPrice);
  const originalProviderPrice = currentTransaction.attributes.payoutTotal;
  const formattedProviderOriginalPrice = formatMoney(intl, originalProviderPrice);

  const timezone = getListingTimezone(currentListing);

  const guestSize = get(
    currentTransaction,
    'attributes.protectedData.packageLineItem.guestSize',
    1
  );
  const { bookingStart, bookingEnd } = getBookingDates(currentTransaction);

  const [newGuestSize, setNewGuestSize] = useState(guestSize);
  const [newBookingDates, setNewBookingDates] = useState({
    startDate: bookingStart.toDate(),
    endDate: bookingEnd.toDate(),
  });

  const { newCustomerPrice, newProviderPrice, updatedLineItems } = calculateBookingChangesPrices(
    currentPackage,
    currentTransaction,
    newGuestSize,
    newBookingDates,
    hasCustomerRequestedChanges
  );

  const formattedNewCustomerPrice = formatMoney(intl, new Money(newCustomerPrice, config.currency));
  const formattedNewProviderPrice = formatMoney(intl, new Money(newProviderPrice, config.currency));

  const priceDifferenceForCustomer = new Money(
    newCustomerPrice - originalCustomerPrice.amount,
    config.currency
  );
  const priceDifferenceForProvider = new Money(
    newProviderPrice - originalProviderPrice.amount,
    config.currency
  );

  const formattedPriceDifferenceForCustomer = formatMoney(intl, priceDifferenceForCustomer);
  const formattedPriceDifferenceForProvider = formatMoney(intl, priceDifferenceForProvider);

  const handleSubmit = async (values, initialValues) => {
    setErrorMessage(null);

    const partySizeChanged = values.partySize !== initialValues.partySize;

    const datesChanged =
      moment(values.dates.startDate).format('YYYY-MM-DD') !==
        moment(initialValues.dates.startDate).format('YYYY-MM-DD') ||
      moment(values.dates.endDate).format('YYYY-MM-DD') !==
        moment(initialValues.dates.endDate).format('YYYY-MM-DD');

    const newDatesFormatted = formatDatesForTransaction(values.dates, timezone);

    const hasCustomerPriceChanged = priceDifferenceForCustomer.amount !== 0;

    try {
      const params = {
        currentUser,
        currentListing,
        currentProvider,
        lineItems: updatedLineItems,
        newValues: {
          partySize: parseInt(values.partySize, 10),
          dates: {
            startDate: JSON.stringify(values.dates.startDate),
            endDate: JSON.stringify(values.dates.endDate),
            formattedStartDate: format(values.dates.startDate, 'dddd, MMMM D, YYYY'),
            formattedEndDate: format(values.dates.endDate, 'dddd, MMMM D, YYYY'),
          },
          days: daysBetween(newDatesFormatted.startDate, newDatesFormatted.endDate) + 1,
        },
        originalValues: {
          partySize: initialValues.partySize,
          dates: {
            startDate: JSON.stringify(initialValues.dates.startDate),
            endDate: JSON.stringify(initialValues.dates.endDate),
            formattedStartDate: format(initialValues.dates.startDate, 'dddd, MMMM D, YYYY'),
            formattedEndDate: format(initialValues.dates.endDate, 'dddd, MMMM D, YYYY'),
          },
        },
        partySizeChanged,
        datesChanged,
        createPayment: hasCustomerPriceChanged,
      };

      await onSubmit(params, currentTransaction.id);

      // Scroll to the top of the page after form submission
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
      setErrorMessage(intl.formatMessage({ id: 'BookingChanges.sendRequestError' }));
    }
  };

  const handleAccept = async () => {
    try {
      setErrorMessage(null);
      await onAccept(currentTransaction.id);
      onClose();
    } catch (error) {
      console.log(error);
      setErrorMessage(intl.formatMessage({ id: 'BookingChanges.sendRequestError' }));
    }
  };

  const handleDecline = async () => {
    try {
      setErrorMessage(null);
      await onDecline(currentTransaction.id);
      onClose();
    } catch (error) {
      console.log(error);
      setErrorMessage(intl.formatMessage({ id: 'BookingChanges.sendRequestError' }));
    }
  };

  const pricingDetails = (
    <PricingDetails
      isCustomer={isCustomer}
      formattedCustomerOriginalPrice={formattedCustomerOriginalPrice}
      formattedNewCustomerPrice={formattedNewCustomerPrice}
      formattedPriceDifferenceForCustomer={formattedPriceDifferenceForCustomer}
      isProvider={isProvider}
      formattedNewProviderPrice={formattedNewProviderPrice}
      formattedProviderOriginalPrice={formattedProviderOriginalPrice}
      formattedPriceDifferenceForProvider={formattedPriceDifferenceForProvider}
    />
  );

  return (
    <div className={css.root}>
      <div className={css.formContainer}>
        <div className={css.formContainerHeader}>
          <Header
            hasCustomerRequestedChanges={hasCustomerRequestedChanges}
            isProvider={isProvider}
            isCustomer={isCustomer}
            providedDisplayName={providedDisplayName}
            customerDisplayName={customerDisplayName}
          />
          <div className={css.divider} />

          <BookingDetails
            intl={intl}
            currentPackage={currentPackage}
            currentTransaction={currentTransaction}
            timeSlots={timeSlots}
            setNewGuestSize={setNewGuestSize}
            setNewBookingDates={setNewBookingDates}
            newPrice={newCustomerPrice}
            onSubmit={handleSubmit}
            inProgress={inProgress}
            hasCustomerRequestedChanges={hasCustomerRequestedChanges}
            onClose={onClose}
            onAccept={handleAccept}
            acceptChangesInProgress={acceptChangesInProgress}
            onDecline={handleDecline}
            declineChangesInProgress={declineChangesInProgress}
            isProvider={isProvider}
            errorMessage={errorMessage}
          />
        </div>
      </div>

      <div className={css.pricingDetailsContainer}>
        <div className={css.pricingDetailsMobileHeader}>
          <Header
            hasCustomerRequestedChanges={hasCustomerRequestedChanges}
            isProvider={isProvider}
            isCustomer={isCustomer}
            providedDisplayName={providedDisplayName}
            customerDisplayName={customerDisplayName}
          />
        </div>

        <TransactionListingHeading
          listing={currentListing}
          currentUser={currentUser}
          provider={currentTransaction?.provider}
          customer={currentTransaction?.customer}
          listingLink={listingLink}
          packageId={packageId}
          currentTransaction={currentTransaction}
          showTitleOnMobile
        />

        <div className={css.divider} />

        <div className={css.bookingDetailsMobile}>
          <BookingDetails
            intl={intl}
            currentPackage={currentPackage}
            currentTransaction={currentTransaction}
            timeSlots={timeSlots}
            setNewGuestSize={setNewGuestSize}
            setNewBookingDates={setNewBookingDates}
            newPrice={newCustomerPrice}
            priceDifferenceForCustomer={priceDifferenceForCustomer}
            onSubmit={handleSubmit}
            inProgress={inProgress}
            hasCustomerRequestedChanges={hasCustomerRequestedChanges}
            onClose={onClose}
            onAccept={handleAccept}
            acceptChangesInProgress={acceptChangesInProgress}
            onDecline={handleDecline}
            declineChangesInProgress={declineChangesInProgress}
            isProvider={isProvider}
            pricingDetails={pricingDetails}
            errorMessage={errorMessage}
          />
        </div>

        <div className={css.priceDetailsDesktop}>{pricingDetails}</div>
      </div>
    </div>
  );
};

BookingChanges.defaultProps = {
  timeSlots: null,
};

BookingChanges.propTypes = {
  currentListing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  customer: propTypes.user.isRequired,
  provider: propTypes.user.isRequired,
  packageId: string.isRequired,
  currentTransaction: object.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  onSubmit: func.isRequired,
  inProgress: bool.isRequired,
  stateData: object.isRequired,
  onClose: func.isRequired,
  onAccept: func.isRequired,
  acceptChangesInProgress: bool.isRequired,
  onDecline: func.isRequired,
  declineChangesInProgress: bool.isRequired,
};

export default compose(injectIntl)(BookingChanges);
