import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { string, object, bool } from 'prop-types';
import { getListingImages, getPackageImages, getPackage } from '../../util/listing';
import { propTypes } from '../../util/types';
import { createSlug, stringify } from '../../util/urlHelpers';
import { ResponsiveImage, AvatarMedium, NamedLink } from '..';
import css from './TransactionListingHeading.css';
import TripInsuranceBadges from '../TripInsuranceBadges/TripInsuranceBadges';

export const createListingLink = (
  listing,
  label,
  packageInfo = null,
  searchParams = {},
  className = '',
  hideListingTitle = false
) => {
  const listingLoaded = !!listing.id;
  if (listingLoaded && !listing.attributes.deleted) {
    const { title } = listing.attributes;
    const params = { id: listing.id.uuid, slug: createSlug(title), packageId: packageInfo?.id };

    const to = { search: stringify(searchParams) };

    return packageInfo ? (
      <div className={css.packageTitleWrapper}>
        <NamedLink
          className={className}
          name={packageInfo ? 'PackagePage' : 'ListingPage'}
          params={params}
          to={to}
        >
          {label}
        </NamedLink>

        {!hideListingTitle && (
          <span className={css.packageTitleListing}>
            <FormattedMessage id="PackagePage.atTitle" />{' '}
            <NamedLink className={css.packageTitleListingLink} name="ListingPage" params={params}>
              {title}
            </NamedLink>
          </span>
        )}
      </div>
    ) : (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  }

  return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
};

const TransactionListingHeading = ({
  listing,
  currentUser,
  customer,
  provider,
  packageId,
  isInquiry,
  currentTransaction,
  showTitleOnMobile,
}) => {
  const currentPackage = packageId ? getPackage(listing, packageId) : null;
  const packagesImages = currentPackage ? getPackageImages(listing, currentPackage?.id) : null;
  const listingImage = getListingImages(listing)[0] || null;
  const firstImage = packagesImages && packagesImages.length ? packagesImages[0] : listingImage;

  const packageTitle = currentPackage && currentPackage.title;
  const title = packageTitle || get(listing, 'attributes.title', '');
  const userId = get(currentUser, 'id.uuid');
  const customerId = get(customer, 'id.uuid');
  const providerId = get(provider, 'id.uuid');

  const listingLink = createListingLink(
    listing,
    title,
    currentPackage,
    {},
    css.listingTitleLink,
    isInquiry
  );

  const showCustomer = userId === providerId;
  const showProvider = userId === customerId;
  const customerProfile = get(customer, 'attributes.profile', null);
  const providerProfile = get(provider, 'attributes.profile', null);

  let user;

  if (showProvider) {
    user = provider;
  } else if (showCustomer) {
    user = customer;
  }

  return (
    <div className={css.root}>
      <div className={css.imageWrapper}>
        <ResponsiveImage
          rootClassName={css.image}
          alt={title}
          image={firstImage}
          variants={['facebook', 'scaled-large']}
        />
        {currentTransaction && <TripInsuranceBadges currentTransaction={currentTransaction} />}
      </div>
      {isInquiry ? (
        <>
          {!!currentPackage && <div className={css.listingTitleInquiry}>{listingLink}</div>}
          <div className={css.authorWrapper}>
            <AvatarMedium className={css.avatar} user={user} />

            <div className={css.authorNameWrapper}>
              <div className={css.authorLabel}>
                {showCustomer && <FormattedMessage id="TransactionListingHeading.yourGuest" />}
                {showProvider && <FormattedMessage id="TransactionListingHeading.hostedBy" />}
              </div>
              <div className={css.authorName}>
                {showProvider && providerProfile?.displayName}
                {showCustomer && customerProfile?.displayName}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={showTitleOnMobile ? css.listingTitleMobileVisible : css.listingTitle}>
          {listingLink}
        </div>
      )}
    </div>
  );
};

TransactionListingHeading.defaultProps = {
  packageId: null,
  currentTransaction: null,
  showTitleOnMobile: false,
};

TransactionListingHeading.propTypes = {
  listing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  customer: propTypes.user.isRequired,
  provider: propTypes.user.isRequired,
  packageId: string,
  currentTransaction: object,
  showTitleOnMobile: bool,
};

export default TransactionListingHeading;
