import React from 'react';
import { bool, node, func } from 'prop-types';
import classNames from 'classnames';
import css from './Tabs.css';

const Tab = ({ isSelected, isVertical, children, handleClick }) => {
  const classes = classNames(css.tab, {
    [css.tab__vertical]: isVertical,
    [css.tab__selected]: isSelected && !isVertical,
    [css.tab__selected__vertical]: isSelected && isVertical,
  });

  return (
    <button type="button" className={classes} onClick={() => handleClick()}>
      {children}
    </button>
  );
};

Tab.defaultProps = {
  isSelected: false,
  children: null,
  handleClick: null,
  isVertical: false,
};

Tab.propTypes = {
  isSelected: bool,
  children: node,
  handleClick: func,
  isVertical: bool,
};

const Tabs = ({ children, className }) => {
  return (
    <div className={css.root}>
      <div className={classNames(css.list, className)}>{children}</div>
    </div>
  );
};

Tabs.defaultProps = {
  children: null,
};

Tabs.propTypes = {
  children: node,
};

export { Tabs, Tab };
