import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './EditListingFieldWrapper.css';

const EditListingFieldWrapper = props => {
  const { className, rootClassName, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <div className={classes}>{children}</div>;
};

EditListingFieldWrapper.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingFieldWrapper.propTypes = {
  className: string,
  rootClassName: string,
};

export default EditListingFieldWrapper;
