import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { FormattedMessage } from 'react-intl';
import css from './HasTruncation.css';

const HasTruncation = props => {
  const [expanded, setExpansion] = useState(false);
  const [truncated, setTruncation] = useState(false);
  const {
    children,
    more,
    less,
    lines,
    onExpand,
    items,
    maxInitialDisplay,
    onToggle,
    buttonClassName,
    onShowMore,
  } = props;
  const descriptionRef = useRef(null);

  const handleTruncate = truncation => {
    if (truncated !== truncation) {
      setTruncation(truncated);
    }
  };

  const handleLinesToggle = event => {
    event.preventDefault();
    setExpansion(!expanded);

    if (onExpand) {
      onExpand(!expanded);
    }
  };

  const handleItemsToggle = () => {
    const newExpandedState = !expanded;
    setExpansion(newExpandedState);

    if (onToggle) {
      onToggle(newExpandedState);
    }
  };

  const handleClick = event => {
    event.preventDefault();
    if (onShowMore) {
      onShowMore();
    } else {
      handleLinesToggle(event);
    }
  };

  if (items && items.length) {
    const displayedItems = expanded ? items : items.slice(0, maxInitialDisplay);

    return (
      <div>
        {displayedItems.map(item => (
          <div key={item.key}>{item}</div>
        ))}
        {items.length >= maxInitialDisplay && (
          <button onClick={handleItemsToggle} className={buttonClassName} type="button">
            {expanded ? less : more}
          </button>
        )}
      </div>
    );
  }

  return !lines && children ? (
    <div ref={descriptionRef}>
      {!expanded && !truncated && (
        <span>
          <a href="#description" onClick={handleLinesToggle}>
            <div className={css.readMoreLess}>{more}</div>
          </a>
        </span>
      )}
      {!truncated && expanded && (
        <div>
          {children}
          <span>
            {' '}
            <a href="#description" onClick={handleLinesToggle}>
              <div className={css.readMoreLess}>{less}</div>
            </a>
          </span>
        </div>
      )}
    </div>
  ) : (
    <div ref={descriptionRef}>
      <Truncate
        lines={!expanded && lines}
        ellipsis={
          <span>
            ...{' '}
            <a href="#description" onClick={handleClick}>
              <div className={css.readMoreLess}>{more}</div>
            </a>
          </span>
        }
        onTruncate={handleTruncate}
      >
        {children}
      </Truncate>
      {!truncated && expanded && (
        <div>
          <span>
            {' '}
            <a href="#description" onClick={handleLinesToggle}>
              <div className={css.readMoreLess}>{less}</div>
            </a>
          </span>
        </div>
      )}
    </div>
  );
};
HasTruncation.defaultProps = {
  items: null,
  maxInitialDisplay: 2,
  lines: 5,
  more: <FormattedMessage id="ListingPage.showMore" />,
  less: <FormattedMessage id="ListingPage.showLess" />,
  onToggle: null,
  buttonClassName: '',
};

HasTruncation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  maxInitialDisplay: PropTypes.number,
  onToggle: PropTypes.func,
  buttonClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.node,
  more: PropTypes.node,
};

export default HasTruncation;
