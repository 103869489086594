import pick from 'lodash/pick';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/LandingPage/SET_INITIAL_VALUES';

export const FETCH_FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FETCH_FEATURED_LISTINGS_REQUEST';
export const FETCH_FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_FEATURED_LISTINGS_SUCCESS';
export const FETCH_FEATURED_LISTINGS_ERROR = 'app/LandingPage/FETCH_FEATURED_LISTINGS_ERROR';

export const FETCH_FEATURED_REVIEWS_REQUEST = 'app/LandingPage/FETCH_FEATURED_REVIEWS_REQUEST';
export const FETCH_FEATURED_REVIEWS_SUCCESS = 'app/LandingPage/FETCH_FEATURED_REVIEWS_SUCCESS';
export const FETCH_FEATURED_REVIEWS_ERROR = 'app/LandingPage/FETCH_FEATURED_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchFeaturedListingsError: null,
  fetchFeaturedReviewsError: null,
  listings: [],
  reviewedListings: [],
  reviews: [],
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case FETCH_FEATURED_LISTINGS_REQUEST:
      return { ...state, fetchFeaturedListingsError: null };
    case FETCH_FEATURED_LISTINGS_SUCCESS:
      return { ...state, listings: payload };
    case FETCH_FEATURED_LISTINGS_ERROR:
      return { ...state, fetchFeaturedListingsError: payload };
    case FETCH_FEATURED_REVIEWS_REQUEST:
      return { ...state, fetchFeaturedReviewsError: null };
    case FETCH_FEATURED_REVIEWS_SUCCESS:
      return { ...state, reviewedListings: payload };
    case FETCH_FEATURED_REVIEWS_ERROR:
      return { ...state, fetchFeaturedReviewsError: payload };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchFeaturedListingsRequest = () => ({ type: FETCH_FEATURED_LISTINGS_REQUEST });
export const fetchFeaturedListingsSuccess = listings => ({
  type: FETCH_FEATURED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchFeaturedListingsError = error => ({
  type: FETCH_FEATURED_LISTINGS_ERROR,
  error: true,
  payload: error,
});

export const fetchFeaturedReviewsRequest = () => ({ type: FETCH_FEATURED_REVIEWS_REQUEST });
export const fetchFeaturedReviewsSuccess = reviews => ({
  type: FETCH_FEATURED_REVIEWS_SUCCESS,
  payload: reviews,
});
export const fetchFeaturedReviewsError = error => ({
  type: FETCH_FEATURED_REVIEWS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchFeaturedListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchFeaturedListingsRequest());

  return sdk.listings
    .query({
      include: ['images', 'author'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'imageVariant.landscape-crop': sdkUtil.objectQueryString({
        w: 320,
        h: parseInt(320 * 0.666667, 10),
        fit: 'crop',
      }),
      'imageVariant.landscape-crop2x': sdkUtil.objectQueryString({
        w: 640,
        h: parseInt(640 * 0.666667, 10),
        fit: 'crop',
      }),
      per_page: 20,
      meta_isFeatured: true,
    })
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      dispatch(fetchFeaturedListingsSuccess(listings));
    })
    .catch(e => {
      dispatch(fetchFeaturedListingsError(storableError(e)));
    });
};

// This function is not currently being used

export const fetchFeaturedReviews = () => (dispatch, getState, sdk) => {
  dispatch(fetchFeaturedReviewsRequest());

  return sdk.listings
    .query({
      include: ['images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      per_page: 10,
      meta_isReviewed: true,
    })
    .then(response => {
      const reviewedListings = denormalisedResponseEntities(response);

      dispatch(fetchFeaturedReviewsSuccess(reviewedListings));
    })
    .catch(e => {
      dispatch(fetchFeaturedReviewsError(storableError(e)));
    });
};

export const loadData = () => dispatch => {
  return Promise.all([dispatch(fetchFeaturedListings())]);
};
