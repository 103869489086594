import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { isChangePasswordWrongPassword } from '../../util/errors';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { ReactComponent as ShowPassword } from '../../assets/icons/show-password.svg';
import { ReactComponent as HidePassword } from '../../assets/icons/hide-password.svg';

import css from './ChangePasswordForm.css';

class ChangePasswordFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCurrentPassword: false,
    };
    this.submittedValues = {};
  }

  toggleShowNew() {
    const { showNewPassword } = this.state;

    this.setState({ showNewPassword: !showNewPassword });
  }

  toggleShowCurrent() {
    const { showCurrentPassword } = this.state;

    this.setState({ showCurrentPassword: !showCurrentPassword });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
            touched,
            changePasswordError,
            passwordChanged,
            submitSucceeded,
            pristine,
          } = fieldRenderProps;
          const { showNewPassword, showCurrentPassword } = this.state;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          let passwordChangedInfo = null;

          if (passwordChanged && !changePasswordError) {
            passwordChangedInfo = (
              <span className={css.passwordChangedSuccess}>Password updated successfully.</span>
            );
          }

          // New password
          const newPasswordLabel = intl.formatMessage({
            id: 'PasswordChangeForm.newPasswordLabel',
          });
          const newPasswordPlaceholder = intl.formatMessage({
            id: 'PasswordChangeForm.newPasswordPlaceholder',
          });

          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'PasswordChangeForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          // password
          const passwordLabel = intl.formatMessage({
            id: 'ChangePasswordForm.passwordLabel',
          });
          const passwordDescriptionLabel = intl.formatMessage({
            id: 'PasswordChangeForm.confirmChangesInfo',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'ChangePasswordForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'ChangePasswordForm.passwordRequired',
          });

          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;

          const newPasswordValidationError =
            (values &&
              values.currentPassword &&
              !values.newPassword &&
              touched &&
              touched.newPassword === true) ||
            (values &&
              values.newPassword &&
              Object.keys(values.newPassword).length < 8 &&
              touched &&
              touched.newPassword === true);

          const currentPasswordValidationError =
            (values &&
              values.newPassword &&
              !values.currentPassword &&
              touched &&
              touched.currentPassword === true) ||
            (values &&
              values.currentPassword &&
              Object.keys(values.currentPassword).length < 8 &&
              touched &&
              touched.currentPassword === true);

          const newPasswordValidators =
            values.newPassword && touched.newPassword
              ? validators.composeValidators(passwordRequired, passwordMinLength)
              : null;

          const currentPasswordValidators =
            values.currentPassword && touched.currentPassword
              ? validators.composeValidators(passwordRequired, passwordMinLength)
              : null;

          const passwordFailedMessage = intl.formatMessage({
            id: 'PasswordChangeForm.passwordFailed',
          });

          const passwordErrorText = isChangePasswordWrongPassword(changePasswordError)
            ? passwordFailedMessage
            : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = submitSucceeded && pristine;
          const submitDisabled = invalid || submitReady || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.passwordSection}>
                <div className={css.fieldLabel}>
                  <FormattedMessage id="ChangePasswordForm.changePasswordLabel" />
                </div>

                <div className={css.newPasswordSection}>
                  {showNewPassword ? (
                    <HidePassword
                      className={
                        newPasswordValidationError ? css.passwordIconError : css.passwordIcon
                      }
                      onClick={() => this.toggleShowNew()}
                    />
                  ) : (
                    <ShowPassword
                      className={
                        newPasswordValidationError ? css.passwordIconError : css.passwordIcon
                      }
                      onClick={() => this.toggleShowNew()}
                    />
                  )}
                  <FieldTextInput
                    type={showNewPassword ? 'text' : 'password'}
                    id={formId ? `${formId}.newPassword` : 'newPassword'}
                    name="newPassword"
                    autoComplete="new-password"
                    label={newPasswordLabel}
                    placeholder={newPasswordPlaceholder}
                    validate={newPasswordValidators}
                  />

                  {passwordChangedInfo}
                </div>
              </div>

              <div className={css.confirmChangesSection}>
                <div className={css.inputGroup}>
                  {showCurrentPassword ? (
                    <HidePassword
                      className={
                        currentPasswordValidationError ? css.passwordIconError : css.oldPasswordIcon
                      }
                      onClick={() => this.toggleShowCurrent()}
                    />
                  ) : (
                    <ShowPassword
                      className={
                        currentPasswordValidationError ? css.passwordIconError : css.oldPasswordIcon
                      }
                      onClick={() => this.toggleShowCurrent()}
                    />
                  )}

                  <FieldTextInput
                    className={css.password}
                    type={showCurrentPassword ? 'text' : 'password'}
                    id="currentPassword"
                    name="currentPassword"
                    autoComplete="current-password"
                    label={passwordLabel}
                    labelDescription={passwordDescriptionLabel}
                    placeholder={passwordPlaceholder}
                    validate={currentPasswordValidators}
                    customErrorText={passwordTouched ? null : passwordErrorText}
                  />
                </div>
              </div>

              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={submitReady && !changePasswordError}
                  disabled={submitDisabled}
                  isFullWidth
                >
                  <FormattedMessage id="ChangePasswordForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ChangePasswordFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

ChangePasswordFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
};

const ChangePasswordForm = compose(injectIntl)(ChangePasswordFormComponent);

ChangePasswordForm.displayName = 'ChangePasswordForm';

export default ChangePasswordForm;
